<div class="chart-widget-wrapper">
  <cybexer-widget-header [title]="'ui.nav.userScoring' | translate" [inline]="true">
    <mat-slide-toggle
      class="horizontal-view-toggle"
      [ngModel]="(filter$ | async).horizontalView"
      (ngModelChange)="filterStateService.setFilter('horizontalView', $event)"
      labelPosition="after"
    >
      {{ 'ui.scoring.horizontalView' | translate }}
    </mat-slide-toggle>
    <isa-filtered-select
      [options]="teams"
      [preselectedOption]="(filter$ | async).team"
      [optionKey]="'customName'"
      [optionValue]="'id'"
      [defaultOptionKey]="'ui.allTeams' | translate"
      [label]="'ui.team' | translate"
      (selectionChange)="filterStateService.setFilter('team', $event)"
    ></isa-filtered-select>
    <isa-exercise-duration-slider
      ngDefaultControl
      [ngModel]="filter$ | async"
      (ngModelChange)="
        filterStateService.setFilters({
          sliderDate: $event.sliderDate,
          timePeriod: $event.timePeriod
        })
      "
    ></isa-exercise-duration-slider>
  </cybexer-widget-header>

  <div class="chart-wrapper">
    @if (userScoringData.length) {
      <isa-scoring-charts [scoringData]="userScoringData" [isCTF]="isCTF"></isa-scoring-charts>
    }
    @if (!userScoringData.length && !loading) {
      <cybexer-no-data></cybexer-no-data>
    }
    @if (loading) {
      <cybexer-progress-spinner></cybexer-progress-spinner>
    }
  </div>
</div>
