import { BaseModel } from '../shared/base.model';
import { ScoreType } from './score-type.model';
import { TimelineScore } from './timeline-score.model';

export class TeamTimelineData extends BaseModel {
  category: string;
  scoreType: ScoreType;
  scores: TimelineScore[];

  constructor(data?) {
    super(data);

    if (this.category) {
      this.scoreType = this.category as ScoreType;
      this.category = this.category.replace(new RegExp('_', 'g'), ' ');
    }

    if (data.hasOwnProperty('scores')) {
      this.scores = data.scores.map((score) => new TimelineScore(score));
    }
  }
}
