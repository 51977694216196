import { Exercise, ExerciseType } from '../models/gamenet/exercise.model';
import { RoleCode, ROLES } from './roles';

export class DomainPermission {
  static readonly WILDCARD = '*';
  static readonly PART_DIVIDER = ':';
  static readonly SUBPART_DIVIDER = ',';

  domain: string;
  actions: string[] = [DomainPermission.WILDCARD];
  targets: string[] = [DomainPermission.WILDCARD];

  constructor(permission: string) {
    const parts = permission.split(DomainPermission.PART_DIVIDER);
    const [domain, actions, targets] = parts;
    this.domain = domain;
    if (actions) {
      this.actions = actions.split(DomainPermission.SUBPART_DIVIDER);
    }
    if (targets) {
      this.targets = targets.split(DomainPermission.SUBPART_DIVIDER);
    }
  }

  implies(permission: DomainPermission, targetRequired = true): boolean {
    if (this.domain !== DomainPermission.WILDCARD && this.domain !== permission.domain) {
      return false;
    }
    if (!DomainPermission.containsParts(this.actions, permission.actions)) {
      return false;
    }
    return !targetRequired || DomainPermission.containsParts(this.targets, permission.targets);
  }

  toString(): string {
    return (
      this.domain +
      DomainPermission.PART_DIVIDER +
      this.actions.join(DomainPermission.SUBPART_DIVIDER) +
      DomainPermission.PART_DIVIDER +
      this.targets.join(DomainPermission.SUBPART_DIVIDER)
    );
  }

  withTargets(...targets: string[]): DomainPermission {
    this.targets = targets;
    return this;
  }

  private static containsParts(userPermissionPart: string[], otherPart: string[]) {
    return (
      userPermissionPart.includes(DomainPermission.WILDCARD) ||
      otherPart.every((subPart) => {
        return userPermissionPart.includes(subPart);
      })
    );
  }
}

export const PERMISSION_DOMAINS = {
  ADMIN: 'admin',
  GN_EXERCISE: 'gn_exercise',
  GN_BLUE_TEAM: 'gn_blue_team',
};

export const ADMIN_PERMISSIONS = {
  MANAGE_USERS: PERMISSION_DOMAINS.ADMIN + ':manage_users',
  MANAGE_PRODUCT_KEY: PERMISSION_DOMAINS.ADMIN + ':manage_product_key',
  MANAGE_SETTINGS: PERMISSION_DOMAINS.ADMIN + ':manage_settings',
  MANAGE_INTEGRATIONS: PERMISSION_DOMAINS.ADMIN + ':manage_integrations',
  MANAGE_CUSTOM_VARIABLES: PERMISSION_DOMAINS.ADMIN + ':manage_custom_variables',
  MANAGE_GMA: PERMISSION_DOMAINS.ADMIN + ':manage_gma',
  VIEW_HEALTH_DATA: PERMISSION_DOMAINS.ADMIN + ':view_health_data',
  MANAGE_FILES: PERMISSION_DOMAINS.ADMIN + ':manage_files',
};

export const EXERCISE_PERMISSIONS = {
  ADMIN: PERMISSION_DOMAINS.GN_EXERCISE + ':admin',
  WHITE_TEAM: PERMISSION_DOMAINS.GN_EXERCISE + ':white_team',
  RED_TEAM: PERMISSION_DOMAINS.GN_EXERCISE + ':red_team',
  BLUE_TEAM: PERMISSION_DOMAINS.GN_EXERCISE + ':blue_team',
  WAITING_ROOM: PERMISSION_DOMAINS.GN_EXERCISE + ':waiting_room',
  OBSERVER: PERMISSION_DOMAINS.GN_EXERCISE + ':observer',
};

export const BLUE_TEAM_PERMISSIONS = {
  WAITING_ROOM: PERMISSION_DOMAINS.GN_BLUE_TEAM + ':waiting_room',
};

export const getExercisePermissionFromRole = (role: RoleCode) => {
  switch (role) {
    case ROLES.ADMIN:
      return new DomainPermission(EXERCISE_PERMISSIONS.ADMIN);
    case ROLES.WHITE:
      return new DomainPermission(EXERCISE_PERMISSIONS.WHITE_TEAM);
    case ROLES.RED:
      return new DomainPermission(EXERCISE_PERMISSIONS.RED_TEAM);
    case ROLES.BLUE:
      return new DomainPermission(EXERCISE_PERMISSIONS.BLUE_TEAM);
    default:
      throw Error(`Invalid role: ${role}`);
  }
};

export class PermissionData {
  constructor(
    public permission: string,
    public supportedExerciseTypes: ExerciseType[] = null,
    public additionalExerciseFilter: (exercise?: Exercise) => boolean = void 0
  ) {}
}

export class PermissionConfiguration {
  constructor(
    public permissions: PermissionData[],
    public allRequired: boolean = false,
    public targetRequired: boolean = false
  ) {}

  requireAll(allRequired: boolean): PermissionConfiguration {
    this.allRequired = allRequired;
    return this;
  }

  requireTarget(targetRequired: boolean): PermissionConfiguration {
    this.targetRequired = targetRequired;
    return this;
  }

  static hasGamenetPermissions(permissions: PermissionData[]): boolean {
    return permissions.every((it) => it.supportedExerciseTypes != null);
  }

  static of(...permissions: string[]): PermissionConfiguration {
    return new PermissionConfiguration(permissions.map((it) => new PermissionData(it)));
  }

  static filterPermissionsByExerciseType(
    permissions: PermissionData[],
    exerciseType: ExerciseType
  ): PermissionData[] {
    return permissions
      .filter((it) => it.supportedExerciseTypes != null)
      .filter((it) => it.supportedExerciseTypes.indexOf(exerciseType) !== -1);
  }

  static filterPermissionByAdditionalFields(
    permissions: PermissionData[],
    exercise: Exercise
  ): PermissionData[] {
    return permissions.filter(
      (p) => !p.additionalExerciseFilter || p.additionalExerciseFilter(exercise)
    );
  }
}
