import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { FileType } from '../../../models';
import {
  IsaNotification,
  NewsInjectNotification,
  NotificationType,
} from '../../../models/gamenet/isa-notification.model';
import { Settings } from '../../../models/shared/settings.model';
import {
  ExerciseService,
  IntervalService,
  NewsInjectService,
  SettingsService,
} from '../../../services';
import { IsaNotificationService } from '../../../services/gamenet/isa-notification.service';

@UntilDestroy()
@Component({
  selector: 'isa-notifications',
  templateUrl: './isa-notifications.component.html',
  styleUrls: ['./isa-notifications.component.scss'],
})
export class IsaNotificationsComponent implements OnInit {
  @Input() exerciseId: string;
  @Output() newNotifications = new EventEmitter<number>();
  loading = false;
  fileType = FileType;
  isaNotifications: IsaNotification[] = [];
  NotificationType = NotificationType;

  constructor(
    private router: Router,
    private settingsService: SettingsService,
    private intervalService: IntervalService,
    private exerciseService: ExerciseService,
    private newsInjectService: NewsInjectService,
    private isaNotificationService: IsaNotificationService
  ) {}

  redirectToNewsDetails(notification: IsaNotification) {
    if (notification.type === NotificationType.NEWS_INJECT) {
      this.router.navigate([
        `/app/gamenet/news-injects/${(<NewsInjectNotification>notification).newsInjectId}`,
      ]);
    }
  }

  getMediaUrl(notification: IsaNotification) {
    return this.newsInjectService.getMediaUrl(
      (<NewsInjectNotification>notification).thumbnailId,
      this.exerciseId
    );
  }

  ngOnInit(): void {
    this.getGamenetNotifications();
  }

  private getGamenetNotifications() {
    this.settingsService
      .getSettings()
      .pipe(
        switchMap((settings: Settings) =>
          this.intervalService.getInterval(settings.gamenetSettings.exerciseRefreshInterval)
        ),
        switchMap(() => {
          if (this.exerciseId) {
            return this.exerciseService.getUserBlueTeam(this.exerciseId);
          }
          return of(null);
        }),
        switchMap((teamId: string) => {
          if (teamId) {
            return this.isaNotificationService.find(this.exerciseId, teamId);
          }
          return of([]);
        }),
        untilDestroyed(this)
      )
      .subscribe((isaNotifications: IsaNotification[]) => {
        this.isaNotifications = isaNotifications;
        this.newNotifications.emit(
          isaNotifications.filter((notification) => notification.isNew).length
        );
      });
  }
}
