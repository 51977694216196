import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Integration, IntegrationSaveDTO } from '../../models';
import {
  IntegrationEvent,
  IntegrationEventSaveDTO,
  IntegrationEventSubjectType,
  IntegrationEventType,
} from '../../models/shared/integration-event.model';

@Injectable()
export class IntegrationsService {
  private static INTEGRATION_API_PATH = 'api/integrations';
  private static INTEGRATION_EVENTS_API_PATH = 'api/integrations/events';

  constructor(private http: HttpClient) {}

  getIntegrations(): Observable<Integration[]> {
    return this.http
      .get<Integration[]>(IntegrationsService.INTEGRATION_API_PATH)
      .pipe(map((integrations) => integrations.map((data) => new Integration(data))));
  }

  createIntegration(saveDTO: IntegrationSaveDTO): Observable<Boolean> {
    return this.http
      .post<IntegrationSaveDTO>(IntegrationsService.INTEGRATION_API_PATH, saveDTO)
      .pipe(map(() => true));
  }

  updateIntegration(integrationId: string, saveDTO: IntegrationSaveDTO): Observable<Boolean> {
    return this.http
      .put<IntegrationSaveDTO>(
        `${IntegrationsService.INTEGRATION_API_PATH}/${integrationId}`,
        saveDTO
      )
      .pipe(map(() => true));
  }

  deleteIntegration(integrationId: string): Observable<Boolean> {
    return this.http
      .delete(`${IntegrationsService.INTEGRATION_API_PATH}/${integrationId}`)
      .pipe(map(() => true));
  }

  createIntegrationEvent(saveDTO: IntegrationEventSaveDTO): Observable<IntegrationEvent> {
    return this.http
      .post<IntegrationEventSaveDTO>(IntegrationsService.INTEGRATION_EVENTS_API_PATH, saveDTO)
      .pipe(map((event) => IntegrationEvent.constructFromData(event)));
  }

  updateIntegrationEvent(eventId: string, saveDTO: IntegrationEventSaveDTO): Observable<Boolean> {
    return this.http
      .put<IntegrationEventSaveDTO>(
        `${IntegrationsService.INTEGRATION_EVENTS_API_PATH}/${eventId}`,
        saveDTO
      )
      .pipe(map(() => true));
  }

  getIntegrationEvents(
    subjectId?: string,
    subjectType?: IntegrationEventSubjectType,
    eventTypes?: IntegrationEventType[],
    integrationId?: string
  ): Observable<IntegrationEvent[]> {
    return this.http
      .get<IntegrationEvent[]>(IntegrationsService.INTEGRATION_EVENTS_API_PATH, {
        params: {
          ...(subjectId ? { subjectId } : {}),
          ...(subjectType ? { subjectType } : {}),
          eventTypes,
          ...(integrationId ? { integrationId } : {}),
        },
      })
      .pipe(map((events) => events.map((data) => IntegrationEvent.constructFromData(data))));
  }

  sendIntegrationEvent(subjectId: string): Observable<boolean> {
    return this.http
      .post<Boolean>(`${IntegrationsService.INTEGRATION_EVENTS_API_PATH}/${subjectId}/send`, null)
      .pipe(map(() => true));
  }

  deleteIntegrationEvent(eventId: string): Observable<Boolean> {
    return this.http
      .delete(`${IntegrationsService.INTEGRATION_EVENTS_API_PATH}/${eventId}`)
      .pipe(map(() => true));
  }
}
