import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LanguageUtils } from '../shared/language.utils';

@Injectable()
export class UnAuthorizedInterceptorServiceInterceptor implements HttpInterceptor {
  private static AUTHORIZATION_HEADER = 'Authorization';
  private static ACCESS_TOKEN_AUTHORIZATION_HEADER = 'AccessToken';
  private UNAUTHORIZED = 401;

  constructor(private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const language = LanguageUtils.getLanguage();
    const modifiedReq = req.clone({
      setHeaders: {
        'Accept-Language': language,
      },
    });
    return next.handle(modifiedReq).pipe(
      catchError(
        (err: unknown) =>
          new Observable<HttpEvent<any>>((observer) => {
            if (err instanceof HttpErrorResponse) {
              const errResp = <HttpErrorResponse>err;
              if (
                errResp.status === this.UNAUTHORIZED &&
                !UnAuthorizedInterceptorServiceInterceptor.isAccessTokenLoginAttempt(req)
              ) {
                const path = window.location.pathname;
                this.router.navigate(['/intro/login/'], { queryParams: { fwd: path } });
              }
            }
            observer.error(err);
          })
      )
    );
  }

  private static isAccessTokenLoginAttempt(req: HttpRequest<any>): boolean {
    const authHeader = req.headers.get(
      UnAuthorizedInterceptorServiceInterceptor.AUTHORIZATION_HEADER
    );
    return (
      authHeader != null &&
      authHeader.indexOf(
        UnAuthorizedInterceptorServiceInterceptor.ACCESS_TOKEN_AUTHORIZATION_HEADER
      ) !== -1
    );
  }
}
