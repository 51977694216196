import { BaseModel } from '../..';

export class CTFMissionStatus extends BaseModel {
  endTime: Date;
  missionEndTime: Date;
  missionMaxDurationMs: number;
  leader?: MissionStatusLeader;
  teamScore: number;
  teamPosition: number;
  totalTeams: number;
  tasksSolved: number;
  tasksTotal: number;
  hintsUsed: number;
  hintsTotal: number;
  tasksOpened: number;
  tasksLimit: number;
  individualStartStop?: IndividualStartStop;
  showTeamPosition: boolean;

  constructor(data?) {
    super(data);

    if (data.hasOwnProperty('leader')) {
      this.leader = new MissionStatusLeader(data.leader);
    }
    if (data.hasOwnProperty('individualStartStop')) {
      this.individualStartStop = new IndividualStartStop(data.individualStartStop);
    }
    if (data && data.hasOwnProperty('endTime')) {
      this.endTime = new Date(data.endTime);
    }
    if (data && data.hasOwnProperty('missionEndTime')) {
      this.missionEndTime = new Date(data.missionEndTime);
    }
  }

  get isMissionStartAllowed(): boolean {
    return this.individualStartStop && this.individualStartStop.start == null;
  }

  get isMissionStopAllowed(): boolean {
    return (
      this.individualStartStop &&
      this.individualStartStop.start != null &&
      this.individualStartStop.stop == null
    );
  }

  get isMissionStopped(): boolean {
    return this.individualStartStop && this.individualStartStop.stop != null;
  }

  get isMissionInProgress(): boolean {
    return (
      this.individualStartStop &&
      this.individualStartStop.start != null &&
      this.individualStartStop.stop == null
    );
  }
}

export class MissionStatusLeader extends BaseModel {
  name: string;
  score: number;
  tasksSolved: number;

  constructor(data?) {
    super(data);
  }
}

export class IndividualStartStop extends BaseModel {
  start: Date;
  stop: Date;

  constructor(data?) {
    super(data);
  }
}
