import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Attack, CampaignLive, ReportList, TeamRanking, VersusAttack } from '../../models';

@Injectable()
export class CampaignLiveService {
  constructor(private http: HttpClient) {}

  getCampaignLive(
    exerciseId: string,
    teamId: string,
    timestamp?: string
  ): Observable<CampaignLive> {
    return this.http
      .get<CampaignLive>(`${CampaignLiveService.getBaseUrl(exerciseId)}/team/${teamId}`, {
        params: { ...(timestamp && { timestamp }) },
      })
      .pipe(
        map((data) => {
          return new CampaignLive(data);
        })
      );
  }

  getTeamRankings(exerciseId: string, timestamp?: string): Observable<TeamRanking[]> {
    return this.http
      .get<TeamRanking[]>(CampaignLiveService.getBaseUrl(exerciseId) + `/ranking`, {
        params: { ...(timestamp && { timestamp }) },
      })
      .pipe(
        map((dataArray) => {
          return dataArray.map((data) => new TeamRanking(data));
        })
      );
  }

  getReportList(exerciseId: string, teamId: string, timestamp?: string): Observable<ReportList[]> {
    return this.http
      .get<ReportList[]>(CampaignLiveService.getBaseUrl(exerciseId) + `/team/${teamId}/reports`, {
        params: { ...(timestamp && { timestamp }) },
      })
      .pipe(
        map((dataArray) => {
          return dataArray.map((data) => new ReportList(data));
        })
      );
  }

  getAttacks(exerciseId: string, teamId: string, timestamp?: string): Observable<Attack[]> {
    return this.http
      .get<Attack[]>(CampaignLiveService.getBaseUrl(exerciseId) + `/team/${teamId}/attacks`, {
        params: { ...(timestamp && { timestamp }) },
      })
      .pipe(
        map((dataArray) => {
          return dataArray.map((data) => new Attack(data));
        })
      );
  }

  getTeamAttacks(
    exerciseId: string,
    teamId: string,
    timestamp?: string,
    limit?: number
  ): Observable<VersusAttack[]> {
    return this.http
      .get<VersusAttack[]>(
        CampaignLiveService.getBaseUrl(exerciseId) + `/team/${teamId}/team-attacks`,
        {
          params: { ...(timestamp && { timestamp }), ...(limit && { limit }) },
        }
      )
      .pipe(map((dataArray) => dataArray.map((data) => new VersusAttack(data))));
  }

  getVersusAttacks(
    exerciseId: string,
    teamId: string,
    timestamp?: string,
    limit?: number
  ): Observable<VersusAttack[]> {
    return this.http
      .get<VersusAttack[]>(
        CampaignLiveService.getBaseUrl(exerciseId) + `/team/${teamId}/versus-attacks`,
        {
          params: { ...(timestamp && { timestamp }), ...(limit && { limit }) },
        }
      )
      .pipe(map((dataArray) => dataArray.map((data) => new VersusAttack(data))));
  }

  private static getBaseUrl(exerciseId: string): string {
    return `api/widget/campaign-live/exercise/${exerciseId}`;
  }
}
