import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { BlueTeam, Exercise, ExerciseType } from '../../../models';
import { AuthenticationService, ExerciseService } from '../../../services';
import { FilterStateModel, FilterStateService, ROLES } from '../../../shared';

@UntilDestroy()
@Component({
  selector: 'isa-campaign-live',
  templateUrl: './campaign-live.component.html',
  styleUrls: ['./campaign-live.component.scss'],
})
export class CampaignLiveComponent implements OnInit {
  filter$: Observable<Partial<FilterStateModel>>;

  hasBlueRole: boolean;
  exercise: Exercise;
  teams: BlueTeam[] = [];
  timelineTimestamp: string;
  ExerciseType = ExerciseType;

  constructor(
    private authenticationService: AuthenticationService,
    private exerciseService: ExerciseService,
    private router: Router,
    private cd: ChangeDetectorRef,
    public filterStateService: FilterStateService
  ) {}

  ngOnInit() {
    this.filter$ = this.filterStateService.filter$(
      'team',
      'pendingConfirmationOnly',
      'compactView',
      'sliderDate',
      'timePeriod'
    );
    this.exerciseService.activeExercise
      .pipe(
        filter((exercise: Exercise) => !!exercise),
        switchMap((exercise: Exercise) => {
          this.exercise = exercise;
          this.teams = exercise.blueTeams;
          this.hasBlueRole = this.authenticationService.hasRole(exercise.id, ROLES.BLUE);
          return of(this.hasBlueRole);
        }),
        filter((hasBlueRole: boolean) => !!hasBlueRole),
        switchMap((hasBlueRole: boolean) => {
          return this.exerciseService.getUserBlueTeam(this.exercise.id);
        }),
        untilDestroyed(this)
      )
      .subscribe((userBlueTeamId) => {
        if (this.hasBlueRole) {
          this.validateBlueTeamSelection(userBlueTeamId);
        }
      });

    this.filter$.pipe(untilDestroyed(this)).subscribe(({ sliderDate }) => {
      if (sliderDate) {
        this.timelineTimestamp = sliderDate.toISOString();
        this.cd.detectChanges();
      }
    });
  }

  validateBlueTeamSelection(userBlueTeamId) {
    const { team } = this.filterStateService.snapshot();
    const userBlueTeam = this.teams.find((team) => team.id === userBlueTeamId);
    if (!userBlueTeam || !userBlueTeam.isCampaignLiveEnabled) {
      this.router.navigate(['/app/landing']);
    } else if (team !== userBlueTeamId) {
      this.filterStateService.setFilter('team', userBlueTeamId);
    }
  }
}
