<i
  class="fas fa-lock objective-status"
  [ngClass]="{
    'objective-status--not-compromised': status === OBJECTIVE_STATUS.NOT_COMPROMISED,
    'objective-status--unable-to-compromise': status === OBJECTIVE_STATUS.UNABLE_TO_COMPROMISE,
    'fa-unlock objective-status--compromised': status === OBJECTIVE_STATUS.COMPROMISED,
    'fa-unlock-alt objective-status--pending-compromised':
      status === OBJECTIVE_STATUS.PENDING_COMPROMISED
  }"
></i>
