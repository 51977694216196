import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationsService } from '@cybexer/ngx-commons';
import { Exercise } from '../../../../models';
import { ExerciseService, ProcessStatusService } from '../../../../services';

@UntilDestroy()
@Component({
  selector: 'isa-exercise-reset-dialog',
  templateUrl: './exercise-reset-dialog.component.html',
  styleUrls: ['./exercise-reset-dialog.component.scss'],
})
export class ExerciseResetDialogComponent implements OnInit {
  exercise: Exercise;
  confirmationInput: UntypedFormControl;
  confirmationText = 'ERASE ALL';
  totalSteps = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private exerciseService: ExerciseService,
    private processStatusService: ProcessStatusService,
    private notificationsService: NotificationsService,
    private dialogRef: MatDialogRef<ExerciseResetDialogComponent, boolean>
  ) {}

  ngOnInit() {
    this.confirmationInput = new UntypedFormControl('');
    this.exercise = this.data['exercise'];
  }

  resetExercise(exerciseId: string): void {
    this.dialogRef.disableClose = true;
    this.exerciseService
      .resetExercise(exerciseId)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.notificationsService.success('ui.exercise.exerciseResetting');
        this.dialogRef.close(true);
      });
  }
}
