import { Pipe, PipeTransform } from '@angular/core';
import { ExerciseService } from '../../services';

@Pipe({
  name: 'isaTeamName',
})
export class TeamNamePipe implements PipeTransform {
  constructor(private exerciseService: ExerciseService) {}

  transform(value: string) {
    const foundDisplayName = this.exerciseService.getCurrentExerciseBlueTeamNames()[value];
    return foundDisplayName ? foundDisplayName : '';
  }
}
