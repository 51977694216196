export enum CTFTaskStatus {
  ABANDONED = 'ABANDONED',
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_STARTED = 'NOT_STARTED',
  VALIDATING = 'VALIDATING',
  SOLVED = 'SOLVED',
  PARTLY_SOLVED = 'PARTLY_SOLVED',
  DEPENDENCIES_UNSOLVED = 'DEPENDENCIES_UNSOLVED',
  LOCKED = 'LOCKED',
  ATTACK_IN_PROGRESS = 'ATTACK_IN_PROGRESS',
  ATTACK_ABANDONED = 'ATTACK_ABANDONED',
}

export const NOT_LOCKABLE_STATUSES: CTFTaskStatus[] = [
  CTFTaskStatus.LOCKED,
  CTFTaskStatus.SOLVED,
  CTFTaskStatus.VALIDATING,
];

export const CLOSED_TASK_STATUSES: CTFTaskStatus[] = [
  CTFTaskStatus.NOT_STARTED,
  CTFTaskStatus.ABANDONED,
  CTFTaskStatus.DEPENDENCIES_UNSOLVED,
  CTFTaskStatus.LOCKED,
];

export const HINT_USAGE_STATUSES: CTFTaskStatus[] = [
  CTFTaskStatus.IN_PROGRESS,
  CTFTaskStatus.PARTLY_SOLVED,
  CTFTaskStatus.ATTACK_ABANDONED,
];
