<div class="exercise-recreate-with-image-dialog">
  <h2 mat-dialog-title>{{ 'ui.exercise.keepCurrentExerciseImage' | translate }}</h2>

  <mat-dialog-actions align="end">
    <button
      (click)="recreateWithImage()"
      color="primary"
      mat-raised-button
      data-testid="withImageBtn"
    >
      {{ 'ui.yes' | translate }}
    </button>
    <button (click)="recreateWithoutImage()" mat-raised-button data-testid="withoutImageBtn">
      {{ 'ui.no' | translate }}
    </button>
    <button mat-button [matDialogClose]="undefined">{{ 'ui.cancel' | translate }}</button>
  </mat-dialog-actions>
</div>
