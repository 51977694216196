import { Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  SmartCityBuildingDetails,
  SmartCityBuildingSaveDTO,
  SmartCityDataResult,
  SmartCityEventLog,
} from '../../models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SmartCityService {
  isInfoPanelOpen = signal(false);

  constructor(private http: HttpClient) {}

  getData(exerciseId: string, teamId: string): Observable<SmartCityDataResult> {
    return this.http
      .get<SmartCityDataResult>(SmartCityService.getBaseUrl(exerciseId, teamId))
      .pipe(map((data) => new SmartCityDataResult(data)));
  }

  getBuildingDetails(
    exerciseId: string,
    teamId: string,
    buildingId: string
  ): Observable<SmartCityBuildingDetails> {
    return this.http
      .get<SmartCityBuildingDetails>(
        `${SmartCityService.getBaseUrl(exerciseId, teamId)}/buildings/${buildingId}`
      )
      .pipe(map((it) => new SmartCityBuildingDetails(it)));
  }

  getEventLogs(exerciseId: string, teamId: string): Observable<SmartCityEventLog[]> {
    return this.http
      .get<SmartCityEventLog[]>(`${SmartCityService.getBaseUrl(exerciseId, teamId)}/logs`)
      .pipe(map((array) => array.map((it) => new SmartCityEventLog(it))));
  }

  saveBuildingData(
    exerciseId: string,
    teamId: string,
    buildingId: string,
    buildingSaveDTO: SmartCityBuildingSaveDTO
  ): Observable<boolean> {
    return this.http
      .post<boolean>(
        `${SmartCityService.getBaseUrl(exerciseId, teamId)}/buildings/${buildingId}`,
        buildingSaveDTO
      )
      .pipe(map(() => true));
  }

  private static getBaseUrl(exerciseId: string, teamId: string): string {
    return `api/widget/smart-city/exercises/${exerciseId}/teams/${teamId}`;
  }
}
