import { States } from './states.model';

export class StatusSummary {
  states: States;

  constructor(data?) {
    if (typeof data === 'object') {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          if (key === 'states') {
            this[key] = new States(data[key]);
          } else {
            this[key] = data[key];
          }
        }
      }
    }
  }
}

export class TeamStatusSummary extends StatusSummary {
  teamId: string;
  teamName: string;

  constructor(data?) {
    super(data);
  }
}

export class SegmentStatusSummary extends StatusSummary {
  networkSegmentId: string;
  networkSegmentName: string;

  constructor(data?) {
    super(data);
  }
}
