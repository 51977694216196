import { BaseModel } from '../shared/base.model';
import { Objective } from './objective.model';
import { TargetCheck } from './target-check.model';
import { Target } from './target.model';

export class TargetGroup extends BaseModel {
  id: string;
  name: string;
  objectives: Objective[];
  targets: Target[];

  constructor(data?) {
    super(data);
    if (data && data.hasOwnProperty('objectives') && data.objectives) {
      this.objectives = data.objectives.map((obj) => new Objective(obj));
    }
    if (data && data.hasOwnProperty('targets') && data.targets) {
      this.targets = data.targets.map((target) => new Target(target));
    }
  }

  getTargetChecks(): TargetCheck[] {
    if (!this.targets) return [];
    return this.targets.reduce((result, target) => {
      return result.concat(target.targetChecks);
    }, []);
  }
}
