import { BaseModel } from '../shared/base.model';

export class NetworkSegment extends BaseModel {
  id: string;
  name: string;
  description: string;
  color?: string;

  constructor(data?) {
    super(data);
  }
}
