import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment from 'moment';
import { BaseChartDirective } from 'ng2-charts';
import { of, Subscription } from 'rxjs';
import { filter, first, switchMap, take, tap } from 'rxjs/operators';
import { Exercise, ExerciseStatusInfo } from '../../../../models';
import { ExerciseResetSteps } from '../../../../models/gamenet/exercise.model';
import { ProcessStatus } from '../../../../models/gamenet/process-status.model';
import { QueueStatistics, TeamUser } from '../../../../models/gamenet/queue-stats.model';
import { DatePipe } from '../../../../pipes';
import {
  ExerciseService,
  IntervalService,
  PreferenceService,
  ProcessStatusService,
} from '../../../../services';
import { EXERCISE_PERMISSIONS } from '../../../../shared';
import {
  ASSESSMENT_REPORT_PROGRESS_DOUGHNUT,
  ChartColorConfigurator,
  QUEUE_STATISTICS_TIMELINE_CHART_OPTIONS,
} from '../../shared';
import { ExerciseTeamResetDialogComponent } from './exercise-team-reset-dialog/exercise-team-reset-dialog.component';

const INITIAL_CHART_DATA = [
  {
    data: [],
    label: 'Queue',
    backgroundColor: ChartColorConfigurator.SUMMARY_TIMELINE_CHART_COLORS[0].backgroundColor,
    borderColor: ChartColorConfigurator.SUMMARY_TIMELINE_CHART_COLORS[0].borderColor,
  },
  {
    data: [],
    label: 'Allocated',
    backgroundColor: ChartColorConfigurator.SUMMARY_TIMELINE_CHART_COLORS[1].backgroundColor,
    borderColor: ChartColorConfigurator.SUMMARY_TIMELINE_CHART_COLORS[1].borderColor,
  },
];

@UntilDestroy()
@Component({
  selector: 'isa-queue-statistics',
  templateUrl: './queue-statistics.component.html',
  styleUrls: ['./queue-statistics.component.scss'],
})
export class QueueStatisticsComponent implements OnInit {
  @ViewChild('timelineChart') timelineChart: BaseChartDirective;

  exercise: Exercise;
  exerciseStatusInfo: ExerciseStatusInfo;
  chartLabels: string[] = [];
  chartData: any = INITIAL_CHART_DATA;
  doughnutChartData;
  loading = true;
  lineChartOptions;
  doughnutChartOptions: any;
  chartHeight: number;
  data: QueueStatistics;
  currentWaitTime: number = 0;
  openedPanelIndex: number;
  exerciseTeamResetDialogRef: MatDialogRef<ExerciseTeamResetDialogComponent, boolean>;
  teamResetSteps: Map<String, ExerciseResetSteps> = new Map();
  resettingTeams: String[] = [];
  teamStatusUpdateSubscriptions: Map<String, Subscription> = new Map();

  readonly MAX_STEPS = 7;
  protected readonly EXERCISE_PERMISSIONS = EXERCISE_PERMISSIONS;

  constructor(
    private exerciseService: ExerciseService,
    private intervalService: IntervalService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private activatedRoute: ActivatedRoute,
    private preferenceService: PreferenceService,
    private processStatusService: ProcessStatusService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    const getExercise$ = this.activatedRoute.params.pipe(
      first(),
      switchMap((params) => {
        const decodedExerciseId = params['exerciseId'];
        return decodedExerciseId && decodedExerciseId !== 'undefined'
          ? this.exerciseService.getExercise(decodeURIComponent(decodedExerciseId))
          : this.exerciseService.getActiveExercise();
      })
    );

    getExercise$
      .pipe(
        filter((exercise) => !!exercise),
        tap((exercise) => (this.exercise = exercise)),
        switchMap(() => this.preferenceService.getPreferences()),
        tap((preferences) => {
          this.lineChartOptions = QUEUE_STATISTICS_TIMELINE_CHART_OPTIONS(
            preferences.isLightTheme,
            this.exercise.blueTeams.length
          );
          this.doughnutChartOptions = ASSESSMENT_REPORT_PROGRESS_DOUGHNUT(preferences.isLightTheme);
        }),
        switchMap(() => this.intervalService.getInterval(30000)),
        switchMap(() =>
          this.isExerciseResetInProgress()
            ? of(this.exerciseStatusInfo)
            : this.exerciseService.getExerciseStatus(this.exercise.id)
        ),
        tap((exerciseStatusData) => (this.exerciseStatusInfo = exerciseStatusData)),
        switchMap(() =>
          this.isExerciseResetInProgress()
            ? of(this.data)
            : this.exerciseService.getQueueStats(this.exercise.id)
        ),
        untilDestroyed(this)
      )
      .subscribe((data) => {
        if (!this.isExerciseResetInProgress()) {
          this.setData(data);
        }
        this.loading = false;
      });
  }

  isExerciseResetInProgress() {
    return this.exerciseStatusInfo?.isResetInProgress === true || this.resettingTeams.length > 0;
  }

  private setData(data: QueueStatistics) {
    this.data = data;
    this.setLineChartLabels(data);
    this.setLineChartData(data);
    this.setDoughnutChartData();
    this.currentWaitTime = this.getCurrentWaitTime();
    this.updateChart();
    if (this.loading) {
      this.setOpenedPanel(this.data.queueUsers.length > 0 ? 1 : 0);
      this.initLineChartHeight();
    }
  }

  private refresh() {
    this.exerciseService
      .getExerciseStatus(this.exercise.id)
      .pipe(
        tap((exerciseStatusData) => (this.exerciseStatusInfo = exerciseStatusData)),
        switchMap(() => this.exerciseService.getQueueStats(this.exercise.id)),
        take(1)
      )
      .subscribe((data) => this.setData(data));
  }

  private setDoughnutChartData() {
    const nrOfBlueTeams = this.exercise.blueTeams.length;
    const nrOfAllocatedTeams = this.data.allocatedTeams.length;
    const fillPercentage = Math.round((nrOfAllocatedTeams / nrOfBlueTeams) * 100);
    this.doughnutChartData = [
      {
        data: [fillPercentage, 100 - fillPercentage],
      },
    ];
  }

  initLineChartHeight() {
    const contentElement = <HTMLElement>document.getElementsByClassName('isa-content-body')[0];
    if (contentElement) {
      this.chartHeight = contentElement.offsetHeight * 0.8 - 95;
      this.cdr.detectChanges();
    }
  }

  setLineChartLabels(data: QueueStatistics): void {
    if (data.timelineData.length < this.chartLabels.length) {
      this.chartLabels = [];
    }
    data.timelineData.map((item, index) => {
      if (index < this.chartLabels.length) return;
      this.chartLabels.push(this.datePipe.transform(item.timestamp, 'short'));
    });
  }

  setLineChartData(data: QueueStatistics): void {
    if (data.timelineData.length < this.chartData[0].data.length) {
      this.chartData = INITIAL_CHART_DATA;
    }
    data.timelineData.map((item, index) => {
      if (index < this.chartData[0].data.length) return;
      this.chartData[0].data.push(item.queueUsersCount);
      this.chartData[1].data.push(item.allocatedTeamsCount);
    });
  }

  onResize(event): void {
    this.chartHeight = event.target.innerHeight * 0.8 - 190;
  }

  updateChart(): void {
    if (this.timelineChart && this.timelineChart.chart) {
      this.timelineChart.chart.update();
      this.timelineChart.chart.config.data.labels = this.chartLabels;
    }
  }

  trackByFn = (index) => index;

  setOpenedPanel(i: number) {
    this.openedPanelIndex = i;
  }

  getCurrentWaitTime(): number {
    if (!this.data || !this.data.queueUsers || this.data.queueUsers.length === 0) return 0;
    const firstInQueue = this.data.queueUsers[0];
    const inQueueSince = firstInQueue.timestamp;
    return moment().diff(moment(inQueueSince), 'millisecond');
  }

  getTeamName(teamId: string) {
    const teamName = this.exercise.blueTeams.find((blueTeam) => blueTeam.id === teamId)?.customName;
    return teamName != null ? teamName : 'UNKNOWN';
  }

  openExerciseTeamResetDialog(team: TeamUser): void {
    this.exerciseTeamResetDialogRef = this.dialog.open(ExerciseTeamResetDialogComponent, {
      disableClose: false,
      data: {
        exercise: this.exercise,
        teamId: team.teamId,
        teamName: this.getTeamName(team.teamId),
      },
    });

    this.listenForResetProcessUpdates(team);
    this.exerciseTeamResetDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.resettingTeams.push(team.teamId);
      } else {
        this.clearTeamResetData(team.teamId);
      }

      this.exerciseTeamResetDialogRef = null;
    });
  }

  private listenForResetProcessUpdates(team: TeamUser) {
    this.unsubscribeFromStatusEvents(team.teamId);

    const subscription$ = this.processStatusService
      .listenForProcessStatusUpdateEvent(this.exercise.id, team.teamId)
      .pipe(untilDestroyed(this))
      .subscribe((data) => this.appendResetStep(team.teamId, data));
    this.teamStatusUpdateSubscriptions.set(team.teamId, subscription$);
  }

  private appendResetStep(teamId: string, data: ProcessStatus) {
    if (
      data.secondaryTargetObjectId == null ||
      data.targetObjectId !== this.exercise.id ||
      data.secondaryTargetObjectId !== teamId
    ) {
      return;
    }

    const targetSteps = this.teamResetSteps.get(teamId);

    if (targetSteps == null) {
      this.teamResetSteps.set(teamId, new ExerciseResetSteps(1, data.totalSteps));
      return;
    }

    targetSteps.stepsCompleted++;
    targetSteps.totalSteps = data.totalSteps;

    if (targetSteps.stepsCompleted !== data.totalSteps) return;

    this.clearTeamResetData(teamId);
    this.refresh();
  }

  private clearTeamResetData(teamId: string) {
    this.teamResetSteps.delete(teamId);
    const resettingTeamIndex = this.resettingTeams.indexOf(teamId);
    if (resettingTeamIndex > -1) {
      this.resettingTeams.splice(resettingTeamIndex, 1);
    }
    this.unsubscribeFromStatusEvents(teamId);
  }

  private unsubscribeFromStatusEvents(teamId: string) {
    if (this.teamStatusUpdateSubscriptions.has(teamId)) {
      this.teamStatusUpdateSubscriptions.get(teamId).unsubscribe();
      this.teamStatusUpdateSubscriptions.delete(teamId);
    }
  }
}
