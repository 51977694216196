import { Component, Input } from '@angular/core';
import { NiceIds } from '../../../../../models';

@Component({
  selector: 'isa-nice-container',
  templateUrl: './nice-container.component.html',
  styleUrls: ['./nice-container.component.scss'],
})
export class NiceContainerComponent {
  @Input() niceIds: NiceIds;
  @Input() largeLabel: boolean = false;
}
