import { Pipe, PipeTransform } from '@angular/core';
import { IntegrationEventType } from '../models/shared/integration-event.model';

@Pipe({
  name: 'isaIntegrationEventTypeName',
})
export class IntegrationEventTypeNamePipe implements PipeTransform {
  transform(value: string) {
    switch (value) {
      case IntegrationEventType.TARGET_STATUS_CHANGE:
        return 'ui.exercise.targetStatusChange';
      case IntegrationEventType.EXERCISE_START:
        return 'ui.exercise.exerciseStart';
      case IntegrationEventType.EXERCISE_END:
        return 'ui.exercise.exerciseEnd';
      case IntegrationEventType.EXERCISE_PROGRESS_UPDATE:
        return 'ui.exercise.exerciseProgressUpdate';
      case IntegrationEventType.EXERCISE_TEAM_PROGRESS_UPDATE:
        return 'ui.exercise.exerciseTeamUpdate';
      default:
        return value;
    }
  }
}
