import { BaseModel, CTFTaskStatus } from '../..';
import { CTFExerciseStatus } from './ctf-exercise-status.model';
import { CTFTaskType } from './ctf-mission.model';

export class GameOverview extends BaseModel {
  teams: OverviewTeam[];
  status: CTFExerciseStatus;

  constructor(data?) {
    super(data);
    if (data && data.hasOwnProperty('teams')) {
      this.teams = data.teams.map((team) => new OverviewTeam(team));
    }
    if (data && data.hasOwnProperty('status')) {
      this.status = new CTFExerciseStatus(data['status']);
    }
  }
}

export class OverviewTeam extends BaseModel {
  teamName: string;
  position: number;
  categories: OverviewCategory[];

  constructor(data?) {
    super(data);
    if (data && data.hasOwnProperty('categories')) {
      this.categories = data.categories.map((category) => new OverviewCategory(category));
    }
  }
}

export class OverviewCategory extends BaseModel {
  category: string;
  tasks: OverviewTask[];

  constructor(data?) {
    super(data);
    if (data && data.hasOwnProperty('tasks')) {
      this.tasks = data.tasks.map((task) => new OverviewTask(task));
    }
  }
}

export class OverviewTask extends BaseModel {
  type: CTFTaskType;
  status: CTFTaskStatus;
  title: string;
  score: number;
  usedHints: number;
  maxHints: number;
  timeSpentMs: number;
  hasPossibleCrossUsage: boolean;
  // VERSUS
  totalTeams: number;
  attackedTeams?: number;
  successfulAttacks?: number;
  successfulDefenses?: number;
  totalAttacks?: number;
  totalDefenses?: number;
}
