<h2 matDialogTitle>{{ exercise.name }} - {{ teamName }}</h2>
<mat-dialog-content>
  <div data-testid="confirmation-section" class="reset-dialog-body">
    <span class="exercise-team-reset-dialog-content">
      {{ 'ui.waitingRoom.enter' | translate }} "{{ confirmationText }}"
      {{ 'ui.waitingRoom.confirmReset' | translate }}
    </span>
    <mat-form-field class="confirmation-input">
      <form id="form" (submit)="resetExerciseTeamData()">
        <input autocomplete="off" matInput [formControl]="confirmationInput" isaAutoFocus />
      </form>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <div>
    <button
      type="submit"
      data-testid="confirm-btn"
      mat-raised-button
      color="primary"
      form="form"
      [disabled]="confirmationInput.value !== confirmationText"
    >
      <ng-container> {{ 'ui.waitingRoom.reset' | translate }} </ng-container>
    </button>
    <button mat-button matDialogClose data-testid="cancel-btn">
      {{ 'ui.cancel' | translate }}
    </button>
  </div>
</mat-dialog-actions>
