import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SpecialScoring } from '../../models';

@Injectable()
export class SpecialScoringService {
  constructor(private http: HttpClient) {}

  getSpecialScoringList(exerciseId: string): Observable<SpecialScoring[]> {
    return this.http
      .get<SpecialScoring[]>(`api/widget/special-scoring/exercise/${exerciseId}`)
      .pipe(
        map((dataArray) => {
          return dataArray.map((data) => new SpecialScoring(data));
        })
      );
  }

  createSpecialScoring(exerciseId: string, specialScoring: SpecialScoring): Observable<boolean> {
    return this.http
      .post(`api/widget/special-scoring/exercise/${exerciseId}`, specialScoring)
      .pipe(map(() => true));
  }
}
