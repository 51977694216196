import { ConsoleConnectionType } from '@cybexer/ngx-commons';
import { NameFilter } from '../../shared/name-filter';
import { BaseModel } from '../shared/base.model';
import { TargetCheck } from './target-check.model';

export class Target extends BaseModel implements NameFilter {
  id: string;
  networkSegmentId: string;
  name: string;
  hostname: string;
  targetChecks: TargetCheck[];

  constructor(data?) {
    super(data);
    if (data && data.hasOwnProperty('targetChecks') && data.targetChecks) {
      this.targetChecks = data.targetChecks.map((targetCheck) => new TargetCheck(targetCheck));
    }
  }
}

export class Targets extends BaseModel {
  exerciseId: string;
  teamId: string;
  teamName: string;
  targets: Target[] = [];

  constructor(data?) {
    super(data);

    if (data.hasOwnProperty('targets')) {
      this.targets = data.targets.map((target) => new Target(target));
    }
  }
}

export class TargetConsoleData extends BaseModel {
  hostname?: string;
  protocolAndPortList?: ProtocolAndPort[];
  username?: string;
  password?: string;
  supportedConnectionTypes: ConsoleConnectionType[];

  constructor(data?) {
    super(data);

    if (data && data.hasOwnProperty('protocolAndPortList')) {
      this.protocolAndPortList = data.protocolAndPortList.map(
        (protocolAndPort) => new ProtocolAndPort(protocolAndPort)
      );
    }
  }
}

export class ProtocolAndPort extends BaseModel {
  protocol: string;
  port: number;
}
