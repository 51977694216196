import { BaseModel } from './base.model';

export class CustomVariableSaveDTO extends BaseModel {
  name: string;
  value: any;
  description?: string;

  constructor(data?) {
    super(data);
  }
}

export class CustomVariable extends CustomVariableSaveDTO {
  id: string;
  created: Date;
  exerciseId?: string;

  constructor(data?) {
    super(data);
  }
}
