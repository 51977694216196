import { Pipe, PipeTransform } from '@angular/core';
import { ExerciseService } from '../../services';
import { ExerciseUtil } from '../../shared';

@Pipe({
  name: 'taskCategoryIcon',
})
export class TaskCategoryIconPipe implements PipeTransform {
  constructor(
    private exerciseUtil: ExerciseUtil,
    private exerciseService: ExerciseService
  ) {}

  /**
   * Prepends `fas` class to any icon class that the given category has within an exercise,
   * defaulting to `fa-question` if the icon is undefined for the category in the exercise.
   */
  transform(taskCategory: string): any {
    const activeExercise = this.exerciseService.activeExercise.getValue();
    const icon = this.exerciseUtil.getCTFTaskCategoryIcon(activeExercise, taskCategory);
    return 'fas ' + (icon ? icon : 'fa-question');
  }
}
