import { BaseModel } from '../shared/base.model';

export class ProcessStatus extends BaseModel {
  targetObjectId: string;
  secondaryTargetObjectId?: string;
  totalSteps: number;

  constructor(data?) {
    super(data);
  }
}
