<div class="exercise-reset-dialog" xmlns="http://www.w3.org/1999/html">
  <h2 matDialogTitle>{{ exercise.name }}</h2>
  <mat-dialog-content>
    <div data-testid="confirmation-section" class="reset-dialog-body">
      <span class="exercise-reset-dialog-content">
        {{ 'ui.exercise.enter' | translate }} "{{ confirmationText }}"
        {{ 'ui.exercise.toConfirmReset' | translate }}
      </span>
      <mat-form-field class="confirmation-input">
        <form id="form" (submit)="resetExercise(exercise.id)">
          <input autocomplete="off" matInput [formControl]="confirmationInput" isaAutoFocus />
        </form>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div>
      <button
        type="submit"
        data-testid="confirm-btn"
        mat-raised-button
        color="primary"
        form="form"
        [disabled]="confirmationInput.value !== confirmationText"
      >
        {{ 'ui.exercise.reset' | translate }}
      </button>
      <button mat-button matDialogClose data-testid="cancel-btn">
        {{ 'ui.cancel' | translate }}
      </button>
    </div>
  </mat-dialog-actions>
</div>
