import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { ProcessStatus } from '../../models/gamenet/process-status.model';
import { EventSourceUtil } from '../../shared/eventsource.util';

@Injectable({
  providedIn: 'root',
})
export class ProcessStatusService {
  constructor(
    private http: HttpClient,
    private zone: NgZone
  ) {}

  listenForProcessStatusUpdateEvent(
    exerciseId: string,
    teamId?: string
  ): Observable<ProcessStatus> {
    return EventSourceUtil.create(
      `api/process-status/${exerciseId}/subscribe${teamId != null ? '?teamId=' + teamId : ''}`,
      this.zone,
      ProcessStatus
    );
  }
}
