<div class="target-revert-dialog">
  <h2 matDialogTitle>{{ 'ui.targetStatus.revertTarget' | translate }} {{ target.targetName }}</h2>
  <form [formGroup]="form" (ngSubmit)="revertTarget(target.vmId, target.targetName, teamId)">
    <mat-dialog-content class="target-revert-dialog-content">
      <div class="disable-uppercase dialog-content-txt">
        {{ 'ui.targetStatus.revertTargetConfirm' | translate }}
      </div>
      <div class="disable-uppercase dialog-content-txt">
        {{ 'ui.targetStatus.revertCostsPenalty' | translate }}
        <b>{{ target.targetRevertPenalty }} {{ 'ui.targetStatus.points' | translate }}</b>
      </div>
      <div class="disable-uppercase dialog-content-txt">
        {{ 'ui.targetStatus.revertConfirm' | translate }}
      </div>
      <div class="isa-mat-form-row">
        <mat-form-field class="full-width">
          <input
            autocomplete="off"
            matInput
            required="true"
            type="text"
            formControlName="confirmText"
          />
          @if (form.controls['confirmText'].hasError('required')) {
            <mat-error> {{ 'ui.targetStatus.revertFinalConfirm' | translate }} </mat-error>
          }

          @if (
            form.controls['confirmText'].invalid &&
            form.controls['confirmText'].dirty &&
            form.controls['confirmText'].hasError('invalidConfirm')
          ) {
            <mat-error>
              {{ form.get('confirmText').errors['invalidConfirm'] }}
            </mat-error>
          }
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <div>
        <button
          type="submit"
          data-testid="confirm-btn"
          mat-raised-button
          color="primary"
          [disabled]="revertInProgress"
        >
          {{ 'ui.revert' | translate }}
        </button>
        <button mat-button matDialogClose data-testid="cancel-btn">
          {{ 'ui.cancel' | translate }}
        </button>
      </div>
    </mat-dialog-actions>
  </form>
</div>
