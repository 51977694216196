import { NgModule } from '@angular/core';
import { AutoFocusDirective } from './auto-focus.directive';
import { FlagValidator } from './flag.validator';
import { InfiniteScrollLoadMoreItemsOnStartupDirective } from './infinite-scroll-load-more-items-on-startup.directive';
import { IpAddressValidator } from './ip-address.validator';
import { MacAddressValidator } from './mac-address.validator';
import { RequirePermissionDirective } from './require-permission.directive';
import { SetTitleOnOverflowDirective } from './set-title-on-overflow.directive';

@NgModule({
  declarations: [
    RequirePermissionDirective,
    IpAddressValidator,
    MacAddressValidator,
    SetTitleOnOverflowDirective,
    AutoFocusDirective,
    FlagValidator,
    InfiniteScrollLoadMoreItemsOnStartupDirective,
  ],
  exports: [
    RequirePermissionDirective,
    IpAddressValidator,
    MacAddressValidator,
    SetTitleOnOverflowDirective,
    AutoFocusDirective,
    FlagValidator,
    InfiniteScrollLoadMoreItemsOnStartupDirective,
  ],
})
export class IsaDirectivesModule {}
