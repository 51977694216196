<div class="special-scoring-dialog">
  <form [formGroup]="specialScoreForm" (ngSubmit)="onSubmit(specialScoreForm)">
    <h2 matDialogTitle>{{ 'ui.specialScoring.addSpecialScore' | translate }}</h2>
    <mat-dialog-content class="attack-report-confirm-dialog-content">
      <div class="isa-mat-form-row">
        <mat-form-field>
          <mat-label>{{
            (exercise?.isIndividualAssessment ? 'ui.user' : 'ui.team') | translate
          }}</mat-label>
          <mat-select
            tabindex="-1"
            formControlName="team"
            required="true"
            class="team-filter-dropdown"
          >
            @for (team of teams; track team) {
              <mat-option [value]="team.id"> {{ team.customName }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
        <mat-form-field class="score-field">
          <mat-label> {{ 'ui.specialScoring.score' | translate }} </mat-label>
          <input
            autocomplete="off"
            (keypress)="isNumberOrMinus($event)"
            (paste)="onPaste($event)"
            matInput
            required="true"
            tabindex="-1"
            type="number"
            formControlName="score"
          />
        </mat-form-field>
        <div class="error-wrapper">
          @if (
            specialScoreForm.controls['team'].touched &&
            specialScoreForm.controls['team'].hasError('required')
          ) {
            <mat-error align="end">
              {{ 'ui.specialScoring.addSpecialScore' | translate }}
              {{ (exercise?.isIndividualAssessment ? 'ui.user' : 'ui.team') | translate }}
            </mat-error>
          }
          @if (
            specialScoreForm.controls['score'].touched &&
            specialScoreForm.controls['score'].hasError('required')
          ) {
            <mat-error align="end">
              {{ 'ui.specialScoring.pleaseAddScore' | translate }}
            </mat-error>
          }
          @if (
            specialScoreForm.controls['score'].touched &&
            (specialScoreForm.controls['score'].hasError('min') ||
              specialScoreForm.controls['score'].hasError('max'))
          ) {
            <mat-error align="end">
              {{ 'ui.specialScoring.maximumAllowedScore' | translate
              }}{{ exercise.maxSpecialScore }}
            </mat-error>
          }
        </div>
      </div>
      <mat-form-field class="full-width textarea">
        <mat-label>{{ 'ui.specialScoring.description' | translate }}</mat-label>
        <textarea
          matInput
          [cdkTextareaAutosize]
          formControlName="description"
          tabindex="-1"
          required="true"
        >
        </textarea>
        @if (
          specialScoreForm.controls['description'].touched &&
          specialScoreForm.controls['description'].hasError('required')
        ) {
          <mat-error align="end">
            {{ 'ui.specialScoring.pleaseAddDescription' | translate }}
          </mat-error>
        }
      </mat-form-field>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <div>
        <button
          type="submit"
          class="isa-button-confirm"
          mat-raised-button
          color="primary"
          [disabled]="processing"
        >
          {{ 'ui.add' | translate }}
        </button>
        <button mat-button matDialogClose>{{ 'ui.cancel' | translate }}</button>
      </div>
    </mat-dialog-actions>
  </form>
</div>
