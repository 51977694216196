import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList } from '@angular/material/list';
import { NotificationsService } from '@cybexer/ngx-commons';
import { GroupedUserData, TargetStatusSummary } from '../../../../models';
import { TargetStatusService } from '../../../../services';

@Component({
  selector: 'isa-target-assignment-dialog',
  templateUrl: './target-assignment-dialog.component.html',
  styleUrls: ['./target-assignment-dialog.component.scss'],
})
export class TargetAssignmentDialogComponent implements OnInit {
  @ViewChild('targetChecks') targetChecksList: MatSelectionList;
  target: TargetStatusSummary;
  currentUserName: string;
  availableTargetChecks = [];
  exerciseId: string;
  teamId: string;
  assignAllValue: string = null;
  groupedUsers: GroupedUserData[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TargetAssignmentDialogInput,
    private targetStatusService: TargetStatusService,
    private notificationsService: NotificationsService,
    private dialogRef: MatDialogRef<TargetAssignmentDialogComponent>
  ) {}

  ngOnInit() {
    this.target = this.data.target;
    this.currentUserName = this.data.username;
    this.exerciseId = this.data.exerciseId;
    this.teamId = this.data.teamId;
    this.groupedUsers = this.data.groupedUsers;

    this.availableTargetChecks = this.target
      ? this.target.targetChecks.filter((check) => {
          return check.username !== this.currentUserName;
        })
      : [];
  }

  assignAll(username?: string) {
    if (username == null) return;

    this.target.targetChecks.map((check) => (check.username = username));
    this.assignAllValue = username;
  }

  resetAssignAllValue() {
    this.assignAllValue = null;
  }

  assignTarget() {
    this.targetStatusService.assignTarget(this.exerciseId, this.teamId, this.target).subscribe({
      next: () => {
        this.dialogRef.close();
        this.notificationsService.success('ui.targetStatus.targetsAssignedSuccessfully');
      },
      error: () => {
        this.notificationsService.error('ui.targetStatus.targetAssignError');
      },
    });
  }
}

export class TargetAssignmentDialogInput {
  target: TargetStatusSummary;
  username: string;
  exerciseId: string;
  teamId: string;
  groupedUsers: GroupedUserData[];
}
