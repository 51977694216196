import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GameOverview } from '../../../models/gamenet/ctf/game-overview.model';

@Injectable({
  providedIn: 'root',
})
export class GameOverviewService {
  constructor(private http: HttpClient) {}

  getData(exerciseId: string): Observable<GameOverview> {
    return this.http
      .get<GameOverview>(`${GameOverviewService.getBaseUrl(exerciseId)}`)
      .pipe(map((data) => new GameOverview(data)));
  }

  private static getBaseUrl(exerciseId: string): string {
    return `api/ctf/game-overview/exercises/${exerciseId}`;
  }
}
