import { BaseModel } from '../..';
import { CTFExerciseStatus } from './ctf-exercise-status.model';

export class Leaderboard extends BaseModel {
  teams: LeaderboardTeam[];
  status: CTFExerciseStatus;

  constructor(data?) {
    super(data);
    if (data && data.hasOwnProperty('teams')) {
      this.teams = data.teams.map((team) => new LeaderboardTeam(team));
    }
    if (data && data.hasOwnProperty('status')) {
      this.status = new CTFExerciseStatus(data['status']);
    }
  }
}

export class LeaderboardTeam extends BaseModel {
  teamName: string;
  score: number;
  tasks: LeaderboardTasks;
  progressByCategory: { [category: string]: number };
  totalProgress: number;
  hints: LeaderboardHints;

  constructor(data?) {
    super(data);
  }
}

export class LeaderboardTasks extends BaseModel {
  total: number;
  solved: number;
  inProgress: number;
  abandoned: number;
  wrongSubmissions: number;

  constructor(data?) {
    super(data);
  }
}

export class LeaderboardHints extends BaseModel {
  used: number;
  total: number;

  constructor(data?) {
    super(data);
  }
}
