import { Pipe, PipeTransform } from '@angular/core';
import { ExerciseService } from '../../services';
import { ExerciseUtil } from '../../shared';

@Pipe({
  name: 'taskCategoryName',
  pure: true,
})
export class TaskCategoryNamePipe implements PipeTransform {
  constructor(
    private exerciseUtil: ExerciseUtil,
    private exerciseService: ExerciseService
  ) {}

  /**
   * Defaults to `taskCategory` in case category is missing in exercise.
   */
  transform(taskCategory: string): any {
    const activeExercise = this.exerciseService.activeExercise.getValue();
    const name = this.exerciseUtil.getCTFTaskCategoryName(activeExercise, taskCategory);
    return name ? name : taskCategory;
  }
}
