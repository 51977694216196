import { TargetStatus } from '../gamenet/target-status-summary.model';
import { BaseModel } from './base.model';

export abstract class IntegrationEventSaveDTO extends BaseModel {
  integrationId: string;
  type: IntegrationEventType;
  subjectType: IntegrationEventSubjectType;
  subjectId: string;
  subjectName?: string;

  protected constructor(data?) {
    super(data);
  }

  static constructFromData(data): IntegrationEventSaveDTO {
    switch (data['type']) {
      case IntegrationEventType.TARGET_STATUS_CHANGE:
        return new TargetStatusChangeEventSaveDTO(data);
      case IntegrationEventType.EMAIL:
        return new EmailEventSaveDTO(data);
      default:
        return new ZeroConfigurationEventSaveDTO(data);
    }
  }
}

export class TargetStatusChangeEventSaveDTO extends IntegrationEventSaveDTO {
  statusMapping: Map<TargetStatus, string>;
  url: string;
  body: string;

  constructor(data?) {
    super(data);
  }
}

export class EmailEventSaveDTO extends IntegrationEventSaveDTO {
  mailBody: String;

  constructor(data?) {
    super(data);
  }
}

export class ZeroConfigurationEventSaveDTO extends IntegrationEventSaveDTO {
  constructor(data?) {
    super(data);
  }
}

export abstract class IntegrationEvent extends IntegrationEventSaveDTO {
  id: string;

  protected constructor(data?) {
    super(data);
  }

  getAvailableParameters(): IntegrationEventParameter[] {
    return [];
  }

  get subjectTypeDisplayText(): string {
    switch (this.subjectType) {
      case IntegrationEventSubjectType.TARGET:
        return 'Gamenet exercise target';
      default:
        return '';
    }
  }

  static constructFromData(data): IntegrationEvent {
    switch (data['type']) {
      case IntegrationEventType.TARGET_STATUS_CHANGE:
        return new TargetStatusChangeEvent(data);
      case IntegrationEventType.EMAIL:
        return new EmailMessageEvent(data);
      default:
        return new ZeroConfigurationEvent(data);
    }
  }
}

export class ZeroConfigurationEvent extends IntegrationEvent {
  constructor(data?) {
    super(data);
  }
}

export class TargetStatusChangeEvent extends IntegrationEvent {
  statusMapping: Map<TargetStatus, string>;
  url: string;
  body: string;

  constructor(data?) {
    super(data);
  }

  getAvailableParameters(): IntegrationEventParameter[] {
    return [IntegrationEventParameter.TARGET_STATUS];
  }
}

export class EmailMessageEvent extends IntegrationEvent {
  mailFrom: string;
  mailTo: string;
  mailToMany: string[];
  mailSubject: string;
  mailBody: string;

  constructor(data?) {
    super(data);
  }
}

export enum IntegrationEventType {
  TARGET_STATUS_CHANGE = 'TARGET_STATUS_CHANGE',
  EMAIL = 'EMAIL',
  EXERCISE_START = 'EXERCISE_START',
  EXERCISE_END = 'EXERCISE_END',
  EXERCISE_PROGRESS_UPDATE = 'EXERCISE_PROGRESS_UPDATE',
  EXERCISE_TEAM_PROGRESS_UPDATE = 'EXERCISE_TEAM_PROGRESS_UPDATE',
}

export enum IntegrationEventSubjectType {
  TARGET = 'TARGET',
  EMAIL = 'EMAIL',
  EXERCISE = 'EXERCISE',
}

export enum IntegrationEventParameter {
  TARGET_STATUS = 'TARGET_STATUS',
}

export const EVENT_PARAMETER_DATA = {
  TARGET_STATUS: {
    value: '$ISA_TARGET_STATUS',
    name: 'Target status',
  },
};
