<cybexer-widget-header
  [title]="'ui.nav.scoringSummaryTimeline' | translate"
></cybexer-widget-header>
<div class="chart-container">
  @if (isDataLoaded) {
    <div class="summary-timeline-chart">
      <canvas
        baseChart
        #scoringChart="base-chart"
        [datasets]="chartData"
        [labels]="chartLabels"
        [options]="lineChartOptions"
        [type]="'line'"
      >
      </canvas>
    </div>
  }
  @if (!loading && !chartData.length) {
    <cybexer-no-data></cybexer-no-data>
  }
  @if (loading) {
    <cybexer-progress-spinner></cybexer-progress-spinner>
  }
</div>
