import { Pipe, PipeTransform } from '@angular/core';
import { Exercise } from '../../models';
import { ExerciseUtil } from '../../shared';

@Pipe({
  name: 'isaObjectiveContainerName',
})
export class ObjectiveContainerNamePipe implements PipeTransform {
  constructor(private exerciseUtil: ExerciseUtil) {}

  transform(objectiveContainerId: string, exercise: Exercise) {
    const targetCheck = this.exerciseUtil.getTargetCheckById(exercise, objectiveContainerId);
    if (targetCheck) {
      return targetCheck.name;
    }

    const targetGroup = this.exerciseUtil.getTargetGroupById(exercise, objectiveContainerId);
    if (targetGroup) {
      return targetGroup.name;
    }

    return '';
  }
}
