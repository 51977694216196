import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isaPlaceholder',
})
export class PlaceholderPipe implements PipeTransform {
  transform(text: string, placeholder: string) {
    if (text && text.trim() !== '') {
      return text;
    }

    return placeholder;
  }
}
