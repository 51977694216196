import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationsService } from '@cybexer/ngx-commons';
import { Observable } from 'rxjs';
import { FormUtil } from '../../../shared';
import { PasswordFieldsComponent } from '../../../shared/components/password-fields/password-fields.component';

export interface PasswordChangeDialogInput {
  onPasswordChange: (password: string) => Observable<boolean>;
}

@UntilDestroy()
@Component({
  selector: 'isa-password-change-dialog',
  templateUrl: './password-change-dialog.component.html',
  styleUrls: ['./password-change-dialog.component.scss'],
})
export class PasswordChangeDialogComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    private dialogRef: MatDialogRef<PasswordChangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PasswordChangeDialogInput,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit() {
    this.form = new UntypedFormGroup({
      passwordGroup: PasswordFieldsComponent.createFormGroup(),
    });
  }

  onSubmit() {
    FormUtil.markFormControlAsTouchedRecursively(this.form);
    if (this.form.valid) {
      this.data
        .onPasswordChange(this.form.value.passwordGroup.password)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: () => {
            this.notificationsService.success('ui.users.passwordChanged');
            this.dialogRef.close(true);
          },
          error: () => this.notificationsService.error('ui.users.passwordChangeError'),
        });
    }
  }
}
