import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { first } from 'rxjs/operators';
import {
  CombinedObserverSettings,
  ObservableView,
  ObserverViewType,
} from '../../../models/shared/settings.model';
import { AuthenticationService, PreferenceService, SettingsService } from '../../../services';
import { OBSERVABLE_VIEWS } from '../../../shared/views-config';

@UntilDestroy()
@Component({
  selector: 'isa-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  private static LANDING_PAGE = '/app/landing';
  private static FORWARDING_URL_QUERY_PARAM = 'fwd';

  loginForm: UntypedFormGroup;
  loadingData: boolean;
  wrongCredentials: boolean;
  observerSettings: CombinedObserverSettings;
  enabledObserverViews: ObservableView[];
  forwardingUrl: string = LoginComponent.LANDING_PAGE;
  customLogoSrc: any;

  constructor(
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private settingsService: SettingsService,
    private preferenceService: PreferenceService
  ) {}

  ngOnInit(): void {
    this.setForwardingUrl();

    this.loginForm = new UntypedFormGroup({
      username: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl('', Validators.required),
    });

    this.settingsService
      .getObserverSettings()
      .pipe(untilDestroyed(this))
      .subscribe((observerSettings) => {
        this.observerSettings = observerSettings;
        this.enabledObserverViews = observerSettings.views
          ? OBSERVABLE_VIEWS.filter((it) => observerSettings.views.some((type) => type === it.type))
          : [];
      });

    this.settingsService
      .getCustomLogoUrl()
      .pipe(untilDestroyed(this))
      .subscribe((imageUrl) => {
        this.customLogoSrc = imageUrl;
      });
  }

  getObservableViewData(type: ObserverViewType) {
    return OBSERVABLE_VIEWS.find((it) => it.type === type);
  }

  private setForwardingUrl() {
    const urlFromQueryParams =
      this.activatedRoute.snapshot != null
        ? this.activatedRoute.snapshot.queryParams[LoginComponent.FORWARDING_URL_QUERY_PARAM]
        : null;
    if (urlFromQueryParams != null) {
      this.forwardingUrl = urlFromQueryParams;
    }
  }

  login(form: UntypedFormGroup): void {
    if (form.valid) {
      this.loadingData = true;
      this.authenticationService
        .login(form.value.username.trim(), form.value.password)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: () => {
            this.wrongCredentials = false;
            this.setThemeFromPreferences();
            this.router.navigateByUrl(this.forwardingUrl);
          },
          error: () => {
            this.loadingData = false;
            this.wrongCredentials = true;
          },
        });
    }
  }

  setThemeFromPreferences() {
    this.preferenceService
      .getPreferences()
      .pipe(first())
      .subscribe((pref) => {
        if (pref.isLightTheme !== undefined) {
          this.preferenceService.isLightTheme.next(pref.isLightTheme);
          window.localStorage.setItem('isLightTheme', pref.isLightTheme.toString());
        } else {
          this.settingsService
            .getSettings()
            .pipe(first())
            .subscribe((settings) => {
              this.preferenceService.isLightTheme.next(settings.isLightTheme);
            });
        }
      });
  }
}
