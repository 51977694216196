import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { skip } from 'rxjs/operators';
import { IntervalService } from '../../../services';

@UntilDestroy()
@Component({
  selector: 'isa-user-redirect-dialog',
  templateUrl: './user-redirect-dialog.component.html',
  styleUrls: ['./user-redirect-dialog.component.scss'],
})
export class UserRedirectDialogComponent implements OnInit {
  message: string | undefined | null;
  secondsLeft = this.randomInteger(3, 9);
  onTimeoutEnd = new EventEmitter<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private intervalService: IntervalService
  ) {}

  ngOnInit(): void {
    this.message = this.data['message'];
    const timeLeft = this.data['timeLeftInSeconds'];
    if (timeLeft != null && timeLeft > 0) {
      this.secondsLeft = timeLeft;
    }

    this.intervalService
      .getInterval(1000)
      .pipe(skip(1), untilDestroyed(this))
      .subscribe(() => {
        this.secondsLeft--;
        if (this.secondsLeft == 0) {
          this.onTimeoutEnd.emit();
        }
      });
  }

  private randomInteger(min, max): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
}
