import { Component, forwardRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import debounce from 'lodash-es/debounce';
import moment from 'moment';
import { combineLatestWith, switchMap } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Exercise, ExerciseDuration } from '../../../../models';
import { DatePipe } from '../../../../pipes';
import { ExerciseService, IntervalService } from '../../../../services';

type SliderData = { sliderDate: Date; selectedTimePeriod: number };

@UntilDestroy()
@Component({
  selector: 'isa-exercise-duration-slider',
  templateUrl: './exercise-duration-slider.component.html',
  styleUrls: ['./exercise-duration-slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ExerciseDurationSliderComponent),
      multi: true,
    },
  ],
})
export class ExerciseDurationSliderComponent implements ControlValueAccessor, OnInit {
  step = 3600000;
  sliderData: SliderData = { sliderDate: null, selectedTimePeriod: null };
  exerciseDuration: ExerciseDuration;
  sliderMinDate: number;
  sliderMaxDate: number;
  sliderLoaded = false;
  paused = false;

  changeDate = debounce((timestamp) => {
    this.paused = true;
    this.sliderData.sliderDate =
      this.sliderMaxDate - timestamp < this.step
        ? new Date(this.sliderMaxDate)
        : new Date(timestamp);

    this.onchange(this.sliderData);
  }, 300);

  get sliderLabel(): string {
    return this.datePipe.transform(this.sliderData.sliderDate, 'short');
  }

  constructor(
    private datePipe: DatePipe,
    private intervalService: IntervalService,
    private exerciseService: ExerciseService
  ) {}

  writeValue(value: SliderData) {
    if (!value) return;

    if (value?.sliderDate !== this.sliderData?.sliderDate) {
      this.sliderData.sliderDate = value.sliderDate;
    }

    if (
      (value?.selectedTimePeriod || value?.selectedTimePeriod === 0) &&
      value?.selectedTimePeriod !== this.sliderData?.selectedTimePeriod
    ) {
      this.sliderData.selectedTimePeriod = value.selectedTimePeriod;
    }
  }

  onchange = (val: SliderData) => {};

  registerOnChange(fn: any) {
    this.onchange = fn;
  }

  registerOnTouched(fn: any) {}

  ngOnInit() {
    this.exerciseService.activeExercise
      .pipe(
        filter((exercise: Exercise) => !!exercise),
        combineLatestWith(this.intervalService.getWidgetRefreshInterval()),
        switchMap(([exercise]) => this.exerciseService.getExerciseDuration(exercise.id)),
        untilDestroyed(this)
      )
      .subscribe((duration: ExerciseDuration) => {
        this.exerciseDuration = duration;
        this.setSliderValues();
      });
  }

  setSliderValues(): void {
    if (!this.exerciseDuration) return;

    if (!this.paused) {
      // if not paused we want all data to update to latest
      this.sliderData.selectedTimePeriod = this.getLastTimePeriod();
      this.setSliderMaxDate();
      this.sliderData.sliderDate = new Date(this.sliderMaxDate);
      this.onchange(this.sliderData);
    } else {
      // if paused we don't update sliderDate or period
      this.setSliderMaxDate();
    }

    this.setSliderMinDate();

    if (!this.sliderData.sliderDate) {
      this.sliderData.sliderDate = new Date(this.sliderMaxDate);
      this.onchange(this.sliderData);
    }

    this.sliderLoaded = true;
  }

  private getLastTimePeriod() {
    return this.exerciseDuration.startexEvents.length
      ? this.exerciseDuration.startexEvents.length - 1
      : null;
  }

  changeTimePeriod = (timePeriod) => {
    this.paused = true;
    this.sliderData.selectedTimePeriod = timePeriod;
    this.setSliderValues();
    this.sliderData.sliderDate = new Date(this.sliderMaxDate);
    this.onchange(this.sliderData);
  };

  getSliderStartDate(startex: Date): number {
    return startex.setMinutes(0, 0, 0);
  }

  formatSliderThumbLabel(value: number) {
    return moment(value).format('L LT');
  }

  private setSliderMinDate() {
    const startexEventTimestamp =
      this.exerciseDuration.startexEvents[this.sliderData.selectedTimePeriod];
    this.sliderMinDate = startexEventTimestamp
      ? this.getSliderStartDate(new Date(startexEventTimestamp))
      : this.sliderMaxDate;
  }

  private setSliderMaxDate() {
    const endexEventTimestamp =
      this.exerciseDuration.endexEvents[this.sliderData.selectedTimePeriod];
    this.sliderMaxDate = endexEventTimestamp
      ? new Date(endexEventTimestamp).getTime()
      : new Date().getTime();
  }

  unPause() {
    this.paused = false;
    this.setSliderValues();
  }

  isPeriodSelected(): boolean {
    return this.sliderData?.selectedTimePeriod !== null;
  }
}
