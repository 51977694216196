import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IncidentType } from '../../models';

@Injectable()
export class IncidentTypeService {
  private static BASE_API_URL = 'api/widget/incident-report';

  constructor(private http: HttpClient) {}

  getIncidentTypes(exerciseId: string): Observable<IncidentType[]> {
    return this.http
      .get<string[]>(`${IncidentTypeService.BASE_API_URL}/exercise/${exerciseId}/types`)
      .pipe(
        map((dataArray) => {
          return dataArray.map((ir) => new IncidentType({ name: ir }));
        })
      );
  }
}
