import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { Exercise, VersusAttack } from '../../../../models';
import { CampaignLiveService } from '../../../../services';

@UntilDestroy()
@Component({
  selector: 'isa-versus-attack-widget',
  templateUrl: './versus-attack-widget.component.html',
  styleUrls: ['./versus-attack-widget.component.scss'],
})
export class VersusAttackWidgetComponent implements OnChanges {
  static DEFAULT_LIMIT = 5;

  dataLoaded: boolean;
  attacks: VersusAttack[] = [];
  attacks$: Observable<VersusAttack[]>;
  @Input() exercise: Exercise;
  @Input() teamId: string;
  @Input() timestamp: string;
  @Input() isAttackerView = false;

  constructor(private campaignLiveService: CampaignLiveService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.exercise) {
      this.clearCurrentData();
    } else {
      this.initializeData();
    }
  }

  private initializeData() {
    this.getAttacks();
  }

  private getAttacks(): void {
    if (this.exercise && this.teamId && this.timestamp) {
      if (this.isAttackerView) {
        this.attacks$ = this.campaignLiveService.getTeamAttacks(
          this.exercise.id,
          this.teamId,
          this.timestamp,
          VersusAttackWidgetComponent.DEFAULT_LIMIT
        );
      } else {
        this.attacks$ = this.campaignLiveService.getVersusAttacks(
          this.exercise.id,
          this.teamId,
          this.timestamp,
          VersusAttackWidgetComponent.DEFAULT_LIMIT
        );
      }
      this.attacks$.pipe(untilDestroyed(this)).subscribe((attacks) => {
        this.attacks = attacks;
        this.dataLoaded = true;
      });
    }
  }

  private clearCurrentData() {
    this.dataLoaded = false;
    this.attacks = [];
  }
}
