import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExerciseOverview, ExerciseType, NiceWorkRoles, WorkRole } from '../../../../models';

@Component({
  selector: 'isa-exercise-dialog',
  templateUrl: './exercise-dialog.component.html',
  styleUrls: ['./exercise-dialog.component.scss'],
})
export class ExerciseDialogComponent implements OnInit {
  exercise: ExerciseOverview;
  ExerciseType = ExerciseType;
  workRole?: WorkRole;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.exercise = this.data['exercise'];
    this.workRole = NiceWorkRoles.find((role) => role.id === this.exercise.workRoleId);
  }
}
