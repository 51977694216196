<div class="isa-layout">
  <header class="isa-header">
    <mat-toolbar class="isa-toolbar">
      <div class="language-selection-container">
        <!-- Existing header content -->
        {{ 'ui.header.isa' | translate }}

        <!-- Language Menu Button -->
        @if (enabledLanguages?.length > 1) {
          <button
            class="profile-menu-button"
            mat-button
            [matMenuTriggerFor]="langMenu"
            [disabled]="!filteredLanguages"
            data-testid="langMenuButton"
          >
            {{ selectedLanguage | convertLanguageCode }}
          </button>
        }
      </div>
    </mat-toolbar>

    <!-- Language Menu -->
    <mat-menu xPosition="before" #langMenu="matMenu" [overlapTrigger]="false">
      @for (language of filteredLanguages; track language) {
        <button mat-menu-item (click)="switchLanguage(language)">
          {{ language | convertLanguageCode }}
        </button>
      }
    </mat-menu>
  </header>

  <main class="isa-content">
    <mat-sidenav-container class="isa-content-body">
      <router-outlet></router-outlet>
    </mat-sidenav-container>
  </main>

  <isa-footer class="isa-footer"></isa-footer>
</div>
