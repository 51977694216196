@if (displayData?.length) {
  <div [ngClass]="largeLabel ? 'tags-title' : 'tags-title-small'">Mitre Tags</div>
  <div class="tags-container">
    @for (mitreObject of displayData; track mitreObject) {
      <cybexer-tag
        [label]="mitreObject.tag"
        [title]="mitreObject.name"
        [description]="mitreObject.description"
        [linkText]="mitreObject.tag"
        [linkUrl]="mitreObject.linkUrl"
      ></cybexer-tag>
    }
  </div>
}
