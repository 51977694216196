<div class="image-removal-dialog">
  <!-- todo can be replaced with commons confirm-dialog -->
  <h2 matDialogTitle>{{ 'ui.settings.confirmImageDelete' | translate }}</h2>
  <mat-dialog-actions align="end">
    <div>
      <button (click)="confirmDeleteImage()" mat-raised-button color="primary">
        {{ 'ui.settings.delete' | translate }}
      </button>
      <button mat-button matDialogClose>{{ 'ui.settings.cancel' | translate }}</button>
    </div>
  </mat-dialog-actions>
</div>
