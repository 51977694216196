<div class="isa-date-picker">
  <mat-form-field>
    @if (placeholder) {
      <mat-label>{{ placeholder }}</mat-label>
    }
    <input matInput [matDatetimepicker]="datepicker" [(ngModel)]="value" />
    <mat-datetimepicker-toggle
      matIconSuffix
      [for]="datepicker"
      data-testid="datetimepicker-toggle"
    ></mat-datetimepicker-toggle>
    <mat-datetimepicker
      #datepicker
      [type]="type"
      mode="portrait"
      multiYearSelector="true"
      [twelvehour]="twelveHour"
    ></mat-datetimepicker>
  </mat-form-field>
</div>
