<div class="team-ranking">
  <mat-card>
    <div widgetHandle class="live-card-title">
      <i class="fas fa-list-ol" aria-hidden="true"></i>
      {{ 'ui.widget.teamRanking.teamRankingLive' | translate }}
    </div>
    @if (relativeTeamRankings.length === 0 && dataLoaded) {
      <div class="no-data">{{ 'ui.widget.teamRanking.score' | translate }}</div>
    }
    @for (team of relativeTeamRankings; track team) {
      <div class="ranking-row" [ngClass]="{ 'active-team': team.teamId === teamId }">
        <div class="ranking">{{ team.position }}.</div>
        <div class="team">{{ team.teamId | isaTeamName }}</div>
        <div class="score nowrap">
          [{{
            'ui.widget.teamRanking.scorePoints' | translate: { score: team.score | isaFixedNumber }
          }}]
        </div>
      </div>
    }
  </mat-card>
</div>
