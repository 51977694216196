<cybexer-widget-header [title]="'Modules'" [inline]="true">
  <mat-slide-toggle
    labelPosition="after"
    [ngModel]="(filter$ | async).runningExercisesOnly"
    (ngModelChange)="filterStateService.setFilter('runningExercisesOnly', $event)"
  >
    {{ 'ui.exercise.running' | translate }}
  </mat-slide-toggle>
  <mat-form-field>
    <mat-label><i class="fa fa-search"></i> {{ 'ui.exercise.search' | translate }}</mat-label>
    <input
      autocomplete="off"
      matInput
      [ngModel]="(filter$ | async).exerciseOrGroupName"
      (ngModelChange)="filterStateService.setFilter('exerciseOrGroupName', $event)"
    />
  </mat-form-field>
  <div class="spacer"></div>
  @if (currentUser?.isAdmin) {
    <button mat-raised-button color="primary" (click)="createExerciseGroup()">
      <mat-icon>add_circle</mat-icon>{{ 'ui.exercise.newModuleGroup' | translate }}
    </button>

    <button [routerLink]="['/app/gamenet/exercise-definition']" mat-raised-button color="primary">
      <mat-icon>add_circle</mat-icon>{{ 'ui.exercise.newModule' | translate }}
    </button>
  }
</cybexer-widget-header>
<cybexer-widget-body>
  @if (exerciseGroups) {
    @for (group of filteredGroups | async; track group.id; let i = $index) {
      @if (
        (isModuleGroupEditingEnabled && selectedGroupIndex === i) || !isModuleGroupEditingEnabled
      ) {
        <isa-exercise-group
          [group]="group"
          [openPanel]="(filteredGroups | async).length === 1"
          (removeGroupEvent)="removeExerciseGroup(i)"
          (saveGroupEvent)="saveExerciseGroup(i)"
          (editGroupEvent)="toggleModuleGroupEditing(i, $event)"
          (removeExerciseEvent)="removeExerciseFromGroup($event)"
        ></isa-exercise-group>
      }
    }
  }

  @if (isModuleGroupEditingEnabled) {
    <mat-label>{{ 'ui.exercise.clickModuleToAddGroup' | translate }}</mat-label>
  }

  <cybexer-grid [itemSize]="'small'">
    @for (exercise of filteredExercises | async; track exercise.status; let i = $index) {
      <div class="container">
        <isa-exercise-card
          [exercise]="exercise"
          [isInGroup]="false"
          [isSelected]="exercise?.id === currentExercise?.id"
          [findExerciseOverviewById]="findExerciseOverviewById.bind(this)"
        ></isa-exercise-card>
        @if (isModuleGroupEditingEnabled) {
          <div class="overlay" (click)="addExerciseToGroup(i)">
            <div class="circle">
              <div class="cross"></div>
            </div>
          </div>
        }
      </div>
    }
  </cybexer-grid>

  @if (isModuleGroupEditingEnabled && ungroupedExercises.length === 0 && !loading) {
    <cybexer-no-data [message]="'No more compatible exercises'"></cybexer-no-data>
  }
</cybexer-widget-body>
@if (loading) {
  <cybexer-progress-spinner></cybexer-progress-spinner>
}
