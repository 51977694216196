import { BaseModel } from './base.model';

export class Meta extends BaseModel {
  offset: number;
  limit: number;
  timestamp: Date;

  page: number;

  constructor(data?) {
    super(data);
    this.page = this.limit && this.offset ? this.offset / this.limit + 1 : 0;
  }
}
