import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TeamRanking } from '../../../../models';
import { CampaignLiveService } from '../../../../services';

@UntilDestroy()
@Component({
  selector: 'isa-team-ranking-widget',
  templateUrl: './team-ranking-widget.component.html',
  styleUrls: ['./team-ranking-widget.component.scss'],
})
export class TeamRankingWidgetComponent implements OnChanges {
  dataLoaded: boolean;
  teamRankings: TeamRanking[] = [];
  relativeTeamRankings: any[] = [];
  @Input() exerciseId: string;
  @Input() teamId: string;
  @Input() timestamp: string;

  constructor(private campaignLiveService: CampaignLiveService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.exerciseId || changes.teamId || changes.timestamp) {
      this.getTeamRankings();
    }
  }

  getTeamRankings(): void {
    this.dataLoaded = false;
    this.teamRankings = [];
    if (this.exerciseId && this.timestamp) {
      this.campaignLiveService
        .getTeamRankings(this.exerciseId, this.timestamp)
        .pipe(untilDestroyed(this))
        .subscribe((teamRankings) => {
          this.teamRankings = teamRankings;
          this.teamRankings.map((team) => (team.position = this.teamRankings.indexOf(team) + 1));
          this.setRelativeTeamRankingsList();
          this.dataLoaded = true;
        });
    }
  }

  setRelativeTeamRankingsList(): void {
    if (this.teamId && this.exerciseId) {
      const teamRanking = this.teamRankings.find((team) => team.teamId === this.teamId);
      const selectedTeamRank = this.teamRankings.indexOf(teamRanking) + 1;
      if (selectedTeamRank <= 3) {
        this.relativeTeamRankings = this.teamRankings.slice(0, 5);
      } else if (selectedTeamRank > this.teamRankings.length - 3) {
        this.relativeTeamRankings = this.teamRankings.slice(-5);
      } else {
        const activeTeamIndex = this.teamRankings
          .map(function (x) {
            return x.teamId;
          })
          .indexOf(this.teamId);
        this.relativeTeamRankings = this.teamRankings.slice(
          activeTeamIndex - 2,
          activeTeamIndex + 3
        );
      }
    } else {
      this.relativeTeamRankings = this.teamRankings.slice(0, 5);
    }
  }
}
