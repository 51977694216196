import { Injectable } from '@angular/core';
import { MomentDatetimeAdapter } from '@mat-datetimepicker/moment';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class CustomDateAdapter extends MomentDatetimeAdapter {
  private static readonly EN_LOCALE = 'en-US';

  format(date: moment.Moment, displayFormat: string): string {
    if (displayFormat === 'ddd, DD MMM') {
      return date.locale(CustomDateAdapter.EN_LOCALE).format(displayFormat);
    } else {
      return date.format(displayFormat);
    }
  }

  getMonthNames(style: any): string[] {
    const enLocaleData = moment.localeData(CustomDateAdapter.EN_LOCALE);

    switch (style) {
      case 'short':
      case 'narrow':
        return enLocaleData.monthsShort();
      default:
        return enLocaleData.months();
    }
  }

  getDayOfWeekNames(style: any): string[] {
    const enLocaleData = moment.localeData(CustomDateAdapter.EN_LOCALE);

    switch (style) {
      case 'short':
        return enLocaleData.weekdaysMin();
      case 'narrow':
        return enLocaleData.weekdaysShort();
      default:
        return enLocaleData.weekdays();
    }
  }
}
