<div class="target-status-container splitter">
  <as-split direction="vertical" useTransition="true">
    <as-split-area
      [size]="consoleConnectionType === ConsoleConnectionType.NATIVE ? 50 : 60"
      order="0"
      class="target-status-split-area"
    >
      <div>
        <cybexer-widget-header [title]="'ui.nav.targetChecksStatus' | translate" [inline]="true">
          <div class="spacer"></div>
          @if ((loadData$ | async).team) {
            <span class="last-updated-timestamp">
              {{ 'ui.targetStatus.lastUpdated' | translate }}:
              {{ lastUpdatedTimestamp | isaDate: 'mediumWithTimezoneOffset' }}
            </span>
          }
          <div class="newline"></div>
          <isa-filtered-select
            [options]="teams"
            [preselectedOption]="(loadData$ | async).team"
            [optionKey]="'customName'"
            [optionValue]="'id'"
            [label]="'ui.team' | translate"
            [disabled]="userRole === ROLES.BLUE"
            (selectionChange)="filterStateService.setFilters({ team: $event, user: null })"
          ></isa-filtered-select>
          <mat-form-field>
            <mat-label>{{ 'ui.targetStatus.networkSegment' | translate }}</mat-label>
            <mat-select
              [ngModel]="(loadData$ | async).networkSegment"
              (ngModelChange)="filterStateService.setFilter('networkSegment', $event)"
              class="network-segment-select"
            >
              <mat-option [value]="null">{{
                'ui.targetStatus.noFiltering' | translate
              }}</mat-option>
              @for (networkSegment of networkSegments; track networkSegment) {
                <mat-option [value]="networkSegment.id" class="network-segment-option">
                  {{ networkSegment.name }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'ui.targetStatus.targetCheckStatus' | translate }}</mat-label>
            <mat-select
              multiple
              class="target-check-status-filter-dropdown"
              [ngModel]="(filterData$ | async).targetStatuses"
              (ngModelChange)="filterStateService.setFilter('targetStatuses', $event)"
            >
              @for (status of statuses; track status) {
                <mat-option [value]="status">
                  {{ 'ui.targetStatus.' + (status | lowercase) | translate }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'ui.user' | translate }}</mat-label>
            <mat-select
              [ngModel]="(filterData$ | async).user"
              (ngModelChange)="filterStateService.setFilter('user', $event)"
              data-testid="user-dropdown"
            >
              <mat-option [value]="null">{{
                'ui.targetStatus.noFiltering' | translate
              }}</mat-option>
              <mat-option [value]="unassigned">{{
                'ui.targetStatus.unassigned' | translate
              }}</mat-option>
              @for (groupedUser of groupedUsers; track groupedUser) {
                <mat-option [value]="groupedUser.username">
                  {{ groupedUser.username }}
                  @if (groupedUser.isLocal()) {
                    <i
                      class="fas fa-database user-dropdown-origin-icon"
                      aria-hidden="true"
                      matTooltip="LOCAL"
                    ></i>
                  }
                  @if (groupedUser.isFromLDAP()) {
                    <i
                      class="fas fa-users user-dropdown-origin-icon"
                      aria-hidden="true"
                      matTooltip="LDAP"
                    ></i>
                  }
                  @if (groupedUser.isMissing) {
                    <span class="user-dropdown-missing-label"
                      >({{ 'ui.targetStatus.missing' | translate }})</span
                    >
                  }
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
          <mat-slide-toggle
            class="switch-container show-assigned-to-me-filter"
            labelPosition="after"
            [ngModel]="(filterData$ | async).assignedToMe"
            (ngModelChange)="filterStateService.setFilter('assignedToMe', $event)"
          >
            {{ 'ui.targetStatus.showAssigned' | translate }}
          </mat-slide-toggle>
          <div class="spacer"></div>
          @if (isTargetManagementEnabled) {
            <button
              *isaRequirePermission="[EXERCISE_PERMISSIONS.ADMIN, EXERCISE_PERMISSIONS.WHITE_TEAM]"
              (click)="forceRefreshTargets()"
              data-testid="refresh-targets-btn"
              mat-raised-button
            >
              <i class="fa fa-refresh"></i> {{ 'ui.targetStatus.refreshTargets' | translate }}
            </button>
          }
        </cybexer-widget-header>
        <cybexer-widget-body class="target-status">
          <div class="targets-container">
            @for (targetStatusSummary of filteredTargetStatusDataItems; track targetStatusSummary) {
              <mat-card class="target">
                <div>
                  <div class="target-first-row">
                    @if (isTargetManagementEnabled) {
                      <span>
                        @if (targetStatusSummary.powerState === TargetPowerState.POWERED_ON) {
                          <i
                            class="fas fa-play te power-state-on"
                            aria-hidden="true"
                            [matTooltip]="TargetPowerState.POWERED_ON | isaReplaceUnderScores"
                          ></i>
                        }
                        @if (targetStatusSummary.powerState === TargetPowerState.POWERED_OFF) {
                          <i
                            class="fas fa-stop power-state-off"
                            aria-hidden="true"
                            [matTooltip]="TargetPowerState.POWERED_OFF | isaReplaceUnderScores"
                          ></i>
                        }
                        @if (targetStatusSummary.powerState === TargetPowerState.SUSPENDED) {
                          <i
                            class="fas fa-pause power-state power-state-suspended"
                            aria-hidden="true"
                            [matTooltip]="TargetPowerState.SUSPENDED"
                          ></i>
                        }
                      </span>
                    }
                    <div class="target-name-row">{{ targetStatusSummary.targetName }}</div>
                  </div>
                  @if (userRole === ROLES.ADMIN || (userRole === ROLES.BLUE && isExerciseRunning)) {
                    <button
                      [disabled]="
                        revertInProgressEvent?.revertInProgress &&
                        targetStatusSummary.targetId === revertInProgressEvent?.targetId
                      "
                      mat-icon-button
                      class="more-actions-button"
                      [matMenuTriggerFor]="moreActionsMenu"
                      data-testid="more-actions-button"
                    >
                      @if (
                        (isTargetManagementEnabled && !revertInProgressEvent?.revertInProgress) ||
                        targetStatusSummary.targetId !== revertInProgressEvent?.targetId
                      ) {
                        <i [ngClass]="'fas fa-ellipsis-v'" aria-hidden="true"></i>
                      }
                      @if (
                        revertInProgressEvent?.revertInProgress &&
                        targetStatusSummary.targetId === revertInProgressEvent?.targetId
                      ) {
                        <i class="fas fa-spinner fa-spin"></i>
                      }
                    </button>
                    <mat-menu
                      xPosition="before"
                      #moreActionsMenu="matMenu"
                      [overlapTrigger]="false"
                    >
                      <button
                        mat-menu-item
                        class="assign-btn"
                        (click)="openTargetAssignmentDialog(targetStatusSummary)"
                        [attr.data-testid]="'assign-btn-' + targetStatusSummary.targetId"
                      >
                        <i class="fas fa-user-circle" aria-hidden="true"></i
                        >{{ 'ui.assign' | translate }}
                      </button>
                      @if (isTargetManagementEnabled) {
                        <button
                          [disabled]="!targetStatusSummary.vmId"
                          mat-menu-item
                          (click)="openTargetRevertToSnapshotDialog(targetStatusSummary)"
                          [attr.data-testid]="
                            'revert-to-snapshot-btn-' + targetStatusSummary.targetId
                          "
                        >
                          <i class="fas fa-undo-alt" aria-hidden="true"></i>
                          {{ 'ui.targetStatus.revertSnapshot' | translate }}
                        </button>
                        @if (
                          targetStatusSummary.consoleData?.supportedConnectionTypes?.includes(
                            ConsoleConnectionType.NATIVE
                          )
                        ) {
                          <button
                            [disabled]="!targetStatusSummary.vmId"
                            mat-menu-item
                            (click)="openConsole(targetStatusSummary, ConsoleConnectionType.NATIVE)"
                            [attr.data-testid]="
                              'native-console-btn-' + targetStatusSummary.targetId
                            "
                          >
                            <i class="fas fa-network-wired" aria-hidden="true"></i
                            >{{ 'ui.targetStatus.nativeConsole' | translate }}
                          </button>
                        }
                      }
                      @if (targetStatusSummary.consoleData?.supportedConnectionTypes?.length) {
                        @if (
                          targetStatusSummary.consoleData?.supportedConnectionTypes?.includes(
                            ConsoleConnectionType.SSH
                          )
                        ) {
                          <button
                            [disabled]="isVmTargetExists(targetStatusSummary)"
                            mat-menu-item
                            (click)="openConsole(targetStatusSummary, ConsoleConnectionType.SSH)"
                            [attr.data-testid]="'terminal-btn-' + targetStatusSummary.targetId"
                          >
                            <i class="fas fa-terminal" aria-hidden="true"></i>
                            {{ 'ui.targetStatus.terminal' | translate }}
                          </button>
                        }
                        @if (
                          targetStatusSummary.consoleData?.supportedConnectionTypes?.includes(
                            ConsoleConnectionType.VNC
                          )
                        ) {
                          <button
                            [disabled]="isVmTargetExists(targetStatusSummary)"
                            mat-menu-item
                            (click)="openConsole(targetStatusSummary, ConsoleConnectionType.VNC)"
                            [attr.data-testid]="
                              'remote-desktop-vnc-btn-' + targetStatusSummary.targetId
                            "
                          >
                            <i class="fas fa-desktop" aria-hidden="true"></i>
                            {{ 'ui.targetStatus.remoteDesktopVNC' | translate }}
                          </button>
                        }
                        @if (
                          targetStatusSummary.consoleData?.supportedConnectionTypes?.includes(
                            ConsoleConnectionType.RDP
                          )
                        ) {
                          <button
                            [disabled]="isVmTargetExists(targetStatusSummary)"
                            mat-menu-item
                            (click)="openConsole(targetStatusSummary, ConsoleConnectionType.RDP)"
                            [attr.data-testid]="
                              'remote-desktop-btn-' + targetStatusSummary.targetId
                            "
                          >
                            <i class="fas fa-desktop" aria-hidden="true"></i
                            >{{ 'ui.targetStatus.remoteDesktop' | translate }}
                          </button>
                        }
                      }
                    </mat-menu>
                  }
                  @for (targetCheck of targetStatusSummary.targetChecks; track targetCheck) {
                    <div class="target-checks">
                      <i
                        class="fas assigned-icon"
                        [ngClass]="{
                          'fa-user-check': !!targetCheck.username,
                          'fa-user-times': !targetCheck.username
                        }"
                        [matTooltip]="
                          !!targetCheck.username
                            ? ('ui.targetStatus.assignedTo' | translate) +
                              (currentUser.username === targetCheck.username
                                ? ('ui.targetStatus.me' | translate)
                                : ': ' + targetCheck.username)
                            : ('ui.targetStatus.notAssigned' | translate)
                        "
                      ></i>
                      <span
                        class="target-check-state"
                        [ngClass]="{
                          'target-check-state__good': targetCheck.status === 'GOOD',
                          'target-check-state__compromised': targetCheck.status === 'COMPROMISED',
                          'target-check-state__not-available':
                            targetCheck.status === 'NOT_AVAILABLE'
                        }"
                      >
                        @if (targetCheck.status === 'GOOD') {
                          <i
                            class="fas fa-check-circle"
                            aria-hidden="true"
                            [matTooltip]="'ui.targetStatus.good' | translate"
                          ></i>
                        }
                        @if (targetCheck.status === 'COMPROMISED') {
                          <i
                            class="fas fa-exclamation-circle"
                            aria-hidden="true"
                            (click)="
                              userRole === ROLES.ADMIN || userRole === ROLES.BLUE
                                ? openReportPageOnCompromisedTarget(targetCheck.id)
                                : false
                            "
                            [matTooltip]="'ui.targetStatus.compromised' | translate"
                          ></i>
                        }
                        @if (targetCheck.status === 'NOT_AVAILABLE') {
                          <i
                            class="fas fa-minus-circle"
                            aria-hidden="true"
                            [matTooltip]="'ui.targetStatus.not_available' | translate"
                          ></i>
                        }
                      </span>
                      {{ targetCheck.name }}
                    </div>
                  }
                </div>
              </mat-card>
            }
          </div>
          @if (
            !filteredTargetStatusDataItems?.length && (dataLoaded || !(loadData$ | async).team)
          ) {
            <div class="no-data-wrapper">
              <cybexer-no-data [message]="'ui.noDataError' | translate"></cybexer-no-data>
            </div>
          }
          @if (!dataLoaded && (loadData$ | async).team) {
            <cybexer-progress-spinner></cybexer-progress-spinner>
          }
        </cybexer-widget-body>
      </div>
      <cybexer-guacamole-board
        (openActiveConnection)="restoreConsoleConnection($event)"
        class="guacamole-board"
        [style.visibility]="
          isGuacamoleConsoleOpened || isNativeConsoleOpened ? 'hidden' : 'visible'
        "
      ></cybexer-guacamole-board>
    </as-split-area>
    <as-split-area [visible]="isNativeConsoleOpened" data-testid="native-console-split-area">
      <cybexer-vsphere-console
        data-testid="native-console"
        consoleId="native-console"
        consoleContainerId="native-console-container"
        [vmId]="target?.vmId"
        [targetName]="target?.targetName"
        [openWebConsole]="openNativeConsole"
        (closeWebConsole)="isNativeConsoleOpened = false"
      ></cybexer-vsphere-console>
    </as-split-area>
    <as-split-area
      [visible]="isGuacamoleConsoleOpened"
      size="40"
      minSize="10"
      data-testid="guacamole-console-split-area"
    >
      <cybexer-guacamole-console
        data-testid="guacamole-console"
        [connectionParameters]="consoleConnectionParams"
        [open]="openGuacamoleConsole"
        [isHorizontal]="true"
        [isConsoleLoginEnabled]="isGuacamoleConsoleLoginEnabled"
        (consoleClose)="isGuacamoleConsoleOpened = false"
      ></cybexer-guacamole-console>
    </as-split-area>
  </as-split>
</div>
