import { BaseModel } from '../../shared/base.model';
import { CTFTaskStatus } from './ctf-task-status.model';

export class CTFTaskTeamUnassignmentEventData extends BaseModel {
  id: string;
  taskId: string;
  unassignmentType: TeamUnassignmentType;
  status: CTFTaskStatus;

  constructor(data?) {
    super(data);
  }
}

export enum TeamUnassignmentType {
  MANUAL = 'MANUAL',
  INVALID_FLAG = 'INVALID_FLAG',
  ATTACK_TIMEOUT = 'ATTACK_TIMEOUT',
}
