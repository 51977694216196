import { Pipe, PipeTransform } from '@angular/core';
import { Exercise } from '../../models';
import { ExerciseUtil } from '../../shared';

@Pipe({
  name: 'isaTargetName',
})
export class TargetNamePipe implements PipeTransform {
  constructor(private exerciseUtil: ExerciseUtil) {}

  transform(value: string, exercise: Exercise) {
    const target = this.exerciseUtil.getTargetById(exercise, value);
    return target ? target.name : '';
  }
}
