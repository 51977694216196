import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subscription, timer } from 'rxjs';
import { filter, scan, takeWhile, tap } from 'rxjs/operators';
import { IntervalService } from '../../../services';

@UntilDestroy()
@Component({
  selector: 'isa-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss'],
})
export class CountdownTimerComponent implements OnChanges {
  private static SECOND_INTERVAL_IN_MS = 1000;
  private static HOUR_INTERVAL_IN_MS = 3600000;

  @Input() endTime: Date = null;
  @Output() onFinish = new EventEmitter<void>();
  countdown$: Observable<number>;
  private hasFinished = false;
  private hourlyTimesSubscription: Subscription;

  constructor(private intervalService: IntervalService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.hourlyTimesSubscription = this.intervalService
      .getInterval(CountdownTimerComponent.HOUR_INTERVAL_IN_MS)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.countdown$ = null;
        this.countdown$ = timer(0, CountdownTimerComponent.SECOND_INTERVAL_IN_MS).pipe(
          scan(
            (msUntilEnd) => msUntilEnd - CountdownTimerComponent.SECOND_INTERVAL_IN_MS,
            new Date(this.endTime).getTime() - new Date().getTime()
          ),
          filter((msUntilEnd) => msUntilEnd != null),
          tap((msUntilEnd) => {
            if (msUntilEnd <= 0 && !this.hasFinished) {
              this.hasFinished = true;
              this.onFinish.emit();
            }
          }),
          takeWhile((msUntilEnd) => msUntilEnd >= 0)
        );
      });
  }
}
