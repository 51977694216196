export enum ScoreType {
  TOTAL = 'TOTAL',
  // COMMON
  SPECIAL = 'SPECIAL',
  // CAMPAIGN
  ATTACK_REPORTS = 'ATTACK_REPORTS',
  AVAILABILITY = 'AVAILABILITY',
  INCIDENT_REPORTS = 'INCIDENT_REPORTS',
  RESTORE_FROM_BACKUP = 'RESTORE_FROM_BACKUP',
  SITUATION_REPORTS = 'SITUATION_REPORTS',
  // CTF
  ABANDON_TASK = 'ABANDON_TASK',
  SOLVE_TASK = 'SOLVE_TASK',
  USE_HINT = 'USE_HINT',
  // VERSUS
  ATTACK_VERSUS = 'ATTACK_VERSUS',
  DEFEND_VERSUS = 'DEFEND_VERSUS',
  ABANDON_ATTACK = 'ABANDON_ATTACK',
  INVALID_FLAG = 'INVALID_FLAG',
}
