<cybexer-widget-header [title]="'ui.nav.users' | translate" [inline]="true">
  <div class="spacer"></div>
  <button (click)="openUserCreationDialog()" mat-raised-button color="primary">
    <i class="fas fa-plus-circle" aria-hidden="true"></i>{{ 'ui.users.newUser' | translate }}
  </button>
</cybexer-widget-header>
<isa-users-table [usersChanged$]="usersChanged$">
  <ng-template let-user="user" #singleUserActions>
    <button
      class="user-edit-button"
      mat-raised-button
      [routerLink]="'/app/admin/users/' + user.username"
      [disabled]="user.isSetForRemoval"
    >
      <i class="fas fa-edit" aria-hidden="true"></i>{{ 'ui.edit' | translate }}
    </button>
    <button
      class="user-delete-button"
      mat-raised-button
      color="primary"
      (click)="openUserDeletionDialog(user.username)"
      [disabled]="user.isSetForRemoval || isCurrentUser(user.username)"
    >
      <i class="fas fa-trash-alt" aria-hidden="true"></i
      >{{ (user.isSetForRemoval ? 'ui.users.deleting' : 'ui.delete') | translate }}
    </button>
  </ng-template>
</isa-users-table>
