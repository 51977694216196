import { BaseModel } from './base.model';

export class MitreTactic extends BaseModel {
  id: string;
  readonly type: MitreModelType = MitreModelType.MITRE_TACTIC;
  name: string;
  techniques: MitrePattern[];
  isDeprecated = false;
  shortName: string;

  constructor(data?) {
    super(data);

    if (data && data.hasOwnProperty('techniques')) {
      this.techniques = data.techniques.map((technique) => new MitrePattern(technique));
    }
    this.type = MitreModelType[data.type];
  }
}

export class MitrePattern extends BaseModel {
  id: string;
  readonly type: MitreModelType = MitreModelType.MITRE_PATTERN;
  name: string;
  description: string;
  subTechniques: MitrePattern[];
  externalReferences: MitreReferenceModel[];
  tacticReferences: MitreTacticReference[];
  isDeprecated = false;
  isSubTechnique: boolean;

  constructor(data?) {
    super(data);

    if (data && data.hasOwnProperty('subTechniques')) {
      this.subTechniques = data.subTechniques.map((technique) => new MitrePattern(technique));
    }
    if (data && data.hasOwnProperty('externalReferences')) {
      this.externalReferences = data.externalReferences.map(
        (reference) => new MitreReferenceModel(reference)
      );
    }
    if (data && data.hasOwnProperty('tacticReferences')) {
      this.tacticReferences = data.tacticReferences.map(
        (reference) => new MitreTacticReference(reference)
      );
    }
    this.type = MitreModelType[data.type];
  }
}

export class MitreTacticReference extends BaseModel {
  phaseName: string;
}

export class MitreReferenceModel extends BaseModel {
  externalId: string;
  sourceName: string;
  url: string;
}

export class MitreAttackSelection extends BaseModel {
  mitreTactic: MitreTactic;
  parentTechnique: MitrePattern;
  subTechnique: MitrePattern;
}

export enum MitreModelType {
  MITRE_TACTIC = 'MITRE_TACTIC',
  MITRE_PATTERN = 'MITRE_PATTERN',
  MITRE_RELATIONSHIP = 'MITRE_RELATIONSHIP',
}
