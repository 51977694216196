<cybexer-widget-header [title]="'ui.nav.taskReports' | translate">
  <mat-slide-toggle
    labelPosition="after"
    [ngModel]="(filter$ | async).pendingConfirmationOnly"
    (ngModelChange)="filterStateService.setFilter('pendingConfirmationOnly', $event)"
    *isaRequirePermission="EXERCISE_PERMISSIONS.WHITE_TEAM"
  >
    {{ 'ui.taskReports.pendingConfirmationOnly' | translate }}
  </mat-slide-toggle>
  <isa-filtered-select
    [options]="teams"
    [preselectedOption]="(filter$ | async).team"
    [optionKey]="'customName'"
    [optionValue]="'id'"
    [label]="'ui.team' | translate"
    [defaultOptionKey]="'ui.allTeams' | translate"
    [disabled]="this.userRole === this.ROLES.BLUE && !isAdmin"
    (selectionChange)="filterStateService.setFilter('team', $event)"
  ></isa-filtered-select>
  <mat-form-field>
    <mat-label
      ><i class="fas fa-filter" aria-hidden="true"></i>
      {{ 'ui.taskReports.filter' | translate }}</mat-label
    >
    <input id="textFilter" autocomplete="off" matInput [formControl]="textFilterFormControl" />
  </mat-form-field>
  @if (shouldShowAITools) {
    <button
      mat-raised-button
      [disabled]="selection.isEmpty()"
      (click)="sendToAIAssessment()"
      *isaRequirePermission="EXERCISE_PERMISSIONS.WHITE_TEAM"
    >
      <i class="fas fa-magic-wand-sparkles status-ai-icon" aria-hidden="true"></i>
      {{ 'ui.reports.autoAssessSelected' | translate }}
    </button>
    <button
      mat-raised-button
      [matTooltip]="
        effectivePromptOverride?.id
          ? ('ui.reports.activeOverride' | translate: { promptName: effectivePromptOverride?.name })
          : ''
      "
      (click)="openAIFabricPromptOverrideDialog()"
      *isaRequirePermission="EXERCISE_PERMISSIONS.WHITE_TEAM"
    >
      <mat-icon>android</mat-icon>
      @if (effectivePromptOverride?.id) {
        {{
          'ui.reports.promptOverridesButton'
            | translate: { promptName: effectivePromptOverride?.name }
        }}
      } @else {
        {{ 'ui.reports.promptOverridesButtonDefault' | translate }}
      }
    </button>
  }
</cybexer-widget-header>
<div class="task-reports cybexer-table-wrapper">
  <table mat-table matSort [dataSource]="dataSource" class="isa-table">
    <ng-container [matColumnDef]="COLUMNS.SELECT">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? toggleSelectAll() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [disabled]="getFilteredUnconfirmedReports().length === 0"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let report" (click)="$event.stopPropagation()">
        @if (report.status === CONFIRMATION_STATUS.PENDING_CONFIRMATION) {
          <mat-checkbox
            (change)="$event ? selection.toggle(report) : null"
            [checked]="selection.isSelected(report)"
          >
          </mat-checkbox>
        }
      </td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.TIMESTAMP">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ui.time' | translate }}</th>
      <td mat-cell *matCellDef="let taskData">
        {{ taskData.timestamp | isaDate: 'medium' }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.TITLE">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'ui.taskReports.titleColumn' | translate }}
      </th>
      <td mat-cell *matCellDef="let taskData" [matTooltip]="taskData.title">
        {{ taskData.title }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.CATEGORY">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'ui.taskReports.categoryColumn' | translate }}
      </th>
      <td mat-cell *matCellDef="let taskData">{{ taskData.category }}</td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.TEAM_NAME">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ui.team' | translate }}</th>
      <td mat-cell *matCellDef="let taskData" [matTooltip]="taskData.blueTeamMember">
        {{ taskData.teamId | isaTeamName }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.SCORE">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'ui.taskReports.scoreColumn' | translate }}
      </th>
      <td mat-cell *matCellDef="let taskData">
        {{ taskData.score != null ? (taskData.score | isaFixedNumber) : '-' }}/{{
          taskData.initialScore | isaFixedNumber
        }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.STATUS">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        @if ((filter$ | async).pendingConfirmationOnly) {
          <i
            class="fas fa-filter status-filter-icon"
            aria-hidden="true"
            [matTooltip]="'ui.taskReports.statusFilterTooltip' | translate"
          ></i>
        }
        {{ 'ui.taskReports.statusColumn' | translate }}
      </th>
      <td mat-cell *matCellDef="let taskData">
        {{ 'ui.enums.' + taskData.status | translate }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.WHITE_TEAM_MEMBER">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'ui.taskReports.whiteTeamMemberColumn' | translate }}
      </th>
      <td mat-cell *matCellDef="let taskData">
        @if (taskData.whiteTeamMember) {
          <i class="fas fa-user" aria-hidden="true"></i>
        }
        {{ taskData.whiteTeamMember }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.AI_ASSESSMENT_STATUS">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'ui.taskReports.autoAssessStatus' | translate }}
      </th>
      <td mat-cell *matCellDef="let reportData">
        {{ reportData.aiAssessmentStatus | isaReplaceUnderScores }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.AI_SCORE">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'ui.taskReports.autoRating' | translate }}
      </th>
      <td mat-cell *matCellDef="let reportData">
        {{ reportData.aiScore }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      class="clickable-row"
      *matRowDef="let taskData; columns: displayedColumns"
      (click)="openTaskReportConfirmDialog(taskData)"
    ></tr>
  </table>

  @if ((dataSource.filteredCount$ | async) === 0 && (dataSource.loading$ | async) === false) {
    <cybexer-no-data></cybexer-no-data>
  }
  @if (dataSource.loading$ | async) {
    <cybexer-progress-spinner></cybexer-progress-spinner>
  }
  <mat-paginator
    [length]="dataSource.filteredCount$ | async"
    [pageSizeOptions]="pageSizes"
    showFirstLastButtons
  ></mat-paginator>
</div>
