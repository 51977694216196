<cybexer-widget-header [title]="'ui.nav.teamAwareness' | translate" [inline]="true">
  <cybexer-widget-filter>
    <isa-filtered-select
      [options]="teams"
      [preselectedOption]="(filter$ | async).team"
      [optionKey]="'customName'"
      [optionValue]="'id'"
      [label]="'ui.team' | translate"
      [disabled]="userRole === ROLES.BLUE"
      (selectionChange)="filterStateService.setFilter('team', $event)"
    ></isa-filtered-select>
  </cybexer-widget-filter>
</cybexer-widget-header>
<cybexer-widget-body>
  @if (dataLoaded) {
    <isa-status-summary-chart
      [summaryData]="segmentStatusChartData.data"
      [labels]="segmentStatusChartData.labels"
      [disableClick]="true"
      (barClicked)="barClicked($event)"
    >
    </isa-status-summary-chart>
  }
  @if (!dataLoaded && (filter$ | async).team) {
    <cybexer-progress-spinner></cybexer-progress-spinner>
  }
</cybexer-widget-body>
