import { BaseModel } from '../shared/base.model';

export class States extends BaseModel {
  good: number;
  compromised: number;
  notAvailable: number;
  private _total: number;
  private _roundedGood: number;
  private _roundedCompromised: number;
  private _roundedNotAvailable: number;

  get roundedGood() {
    return this._roundedGood;
  }

  get roundedCompromised() {
    return this._roundedCompromised;
  }

  get roundedNotAvailable() {
    return this._roundedNotAvailable;
  }

  get total() {
    return this._total;
  }

  constructor(data?) {
    super(data);
    if (data) {
      this._total = this.good + this.compromised + this.notAvailable || 0;

      const roundedStates = {
        good: Math.round((this.good / this._total) * 100),
        compromised: Math.round((this.compromised / this._total) * 100),
        notAvailable: Math.round((this.notAvailable / this._total) * 100),
      };
      const sum = roundedStates.good + roundedStates.compromised + roundedStates.notAvailable;
      if (sum < 100 && sum) {
        roundedStates.good++;
      } else if (sum > 100 && sum) {
        if (roundedStates.compromised) {
          roundedStates.compromised--;
        } else if (roundedStates.notAvailable) {
          roundedStates.notAvailable--;
        }
      }

      this._roundedGood = roundedStates.good;
      this._roundedCompromised = roundedStates.compromised;
      this._roundedNotAvailable = roundedStates.notAvailable;
    }
  }
}
