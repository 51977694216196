<div class="user-delete-dialog">
  <h2 matDialogTitle class="center">
    {{ getDeleteConfirmMessage() }}
  </h2>
  <mat-dialog-actions align="end">
    <div>
      <button
        (click)="confirmDeleteUser()"
        mat-raised-button
        color="warn"
        data-testid="usrDeleteBtn"
      >
        <i class="fas fa-trash-alt" aria-hidden="true"></i>{{ 'ui.delete' | translate }}
      </button>
      <button mat-raised-button matDialogClose>{{ 'ui.cancel' | translate }}</button>
    </div>
  </mat-dialog-actions>
</div>
