<cybexer-widget-header [title]="'ui.admin.gma.gmaOverview' | translate"></cybexer-widget-header>
<div class="cybexer-table-wrapper">
  <table class="isa-table">
    <thead>
      <tr>
        <th></th>
        <th>{{ 'ui.admin.gma.registered' | translate }}</th>
        <th>{{ 'ui.admin.gma.gmaId' | translate }}</th>
        <th>{{ 'ui.admin.gma.ipAddress' | translate }}</th>
        <th>{{ 'ui.admin.gma.exerciseUsedIn' | translate }}</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      @for (gmaOverview of gmaOverviews; track gmaOverview) {
        <tr [attr.data-testid]="gmaOverview.id">
          <td data-testid="activity-status-cell">
            <i
              class="gma-status-icon fa"
              [ngClass]="{
                'fa-power-off': gmaOverview.status === GMA_STATUS.POWER_OFF,
                'fa-broadcast-tower': gmaOverview.status === GMA_STATUS.BROADCAST,
                'fa-heartbeat': gmaOverview.status === GMA_STATUS.HEARTBEAT,
                'fa-exclamation': gmaOverview.status === GMA_STATUS.EXCLAMATION,
                'fa-question': gmaOverview.status === GMA_STATUS.UNKNOWN
              }"
              aria-hidden="true"
              [matTooltip]="getMatTooltipText(gmaOverview)"
              matTooltipClass="gma-tooltip"
            ></i>
          </td>
          <td>{{ gmaOverview.registered | isaDate: 'medium' }}</td>
          <td class="disable-uppercase">{{ gmaOverview.id }}</td>
          <td>{{ gmaOverview.ipAddress }}</td>
          <td class="disable-uppercase" data-testid="exercise-info-cell">
            <span
              class="exercise-count"
              [matBadge]="'+' + (gmaOverview.exercises.length - 1).toString()"
              [matBadgeHidden]="gmaOverview.exercises.length <= 1"
              matBadgeOverlap="false"
              matBadgeColor="warn"
            >
              <span class="exercise-name">{{
                gmaOverview.exercises.length > 0 ? gmaOverview.exercises[0].name : '-'
              }}</span>
            </span>
          </td>
          <td>
            <button
              type="button"
              mat-button
              *isaRequirePermission="ADMIN_PERMISSIONS.MANAGE_GMA"
              (click)="openGmaDetailsDialog(gmaOverview)"
            >
              <i class="fas fa-info-circle" aria-hidden="true"></i>
              {{ 'ui.admin.gma.showInfo' | translate }}
            </button>
            <button
              type="button"
              mat-button
              *isaRequirePermission="ADMIN_PERMISSIONS.MANAGE_GMA"
              (click)="openGmaDeleteDialog(gmaOverview.id)"
            >
              <i class="fas fa-trash-alt" aria-hidden="true"></i> {{ 'ui.delete' | translate }}
            </button>
          </td>
        </tr>
      }
    </tbody>
  </table>
  @if (!gmaOverviews?.length && !loading) {
    <cybexer-no-data></cybexer-no-data>
  }
  @if (loading) {
    <cybexer-progress-spinner></cybexer-progress-spinner>
  }
</div>
