import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'isa-avatar-gallery',
  templateUrl: './avatar-gallery.component.html',
  styleUrls: ['./avatar-gallery.component.scss'],
})
export class AvatarGalleryComponent {
  @Output() onAvatarSelect = new EventEmitter<string>();

  avatars = [
    '/assets/img/eagle.jpg',
    '/assets/img/astronaut.jpg',
    '/assets/img/green_text.jpg',
    '/assets/img/peace_usb.jpg',
    '/assets/img/space_invader.jpg',
    '/assets/img/binary_hoodie.png',
    '/assets/img/golden_skull.jpg',
    '/assets/img/chess_horse.jpg',
    '/assets/img/computer_eye.jpg',
    '/assets/img/batman.jpg',
    '/assets/img/retro_computer.jpg',
    '/assets/img/eagle_side.jpg',
    '/assets/img/wolf.jpg',
    '/assets/img/lego_batman.jpeg',
    '/assets/img/pixel_rambo.jpeg',
    '/assets/img/cursor.jpeg',
    '/assets/img/falcon.jpg',
  ].filter((avatar) => !avatar.endsWith('.svg'));
  selectedAvatar: string;

  onAvatarClick(avatarSrc: string) {
    this.selectedAvatar = avatarSrc;
    this.onAvatarSelect.emit(avatarSrc);
  }
}
