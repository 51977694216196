import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReportingStatus, ReportingStatusIncidentReport } from '../../models';

@Injectable()
export class ReportingStatusService {
  constructor(private http: HttpClient) {}

  getReportingStatuses(exerciseId: string, campaignPhaseId: string): Observable<ReportingStatus[]> {
    return this.http
      .get<
        ReportingStatus[]
      >(`api/widget/reporting-status/exercise/${exerciseId}/phase/${campaignPhaseId}`)
      .pipe(
        map((dataArray) => {
          return dataArray.map((data) => new ReportingStatus(data));
        })
      );
  }

  getReportingStatusIncidentReports(
    exerciseId: string,
    objectiveId: string
  ): Observable<ReportingStatusIncidentReport[]> {
    return this.http
      .get<
        ReportingStatusIncidentReport[]
      >(`api/widget/reporting-status/exercise/${exerciseId}/objective/${objectiveId}/incident-report`)
      .pipe(
        map((dataArray) => {
          return dataArray.map((data) => new ReportingStatusIncidentReport(data));
        })
      );
  }
}
