import { Component, Inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SettingsService } from '../../services';

@Component({
  selector: 'isa-observer-key-dialog',
  templateUrl: './observer-key-dialog.component.html',
  styleUrls: ['./observer-key-dialog.component.scss'],
})
export class ObserverKeyDialogComponent {
  observerKeyCtrl = new UntypedFormControl('');

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ObserverKeyDialogComponent>,
    private settingsService: SettingsService
  ) {}

  submitKey() {
    this.settingsService.checkObserverKey(this.observerKeyCtrl.value).subscribe((isCorrect) => {
      if (isCorrect) {
        this.dialogRef.close({ isKeyValidated: true });
      } else {
        this.observerKeyCtrl.setErrors({
          invalidKey: 'Invalid observer key',
        });
      }
    });
  }
}
