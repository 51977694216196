import { BaseModel } from '../shared/base.model';

export class AggregatedChartData extends BaseModel {
  good: number[];
  compromised: number[];
  notAvailable: number[];

  constructor(data?) {
    super(data);
    if (!data) {
      this.good = [];
      this.compromised = [];
      this.notAvailable = [];
    }
  }
}

export class BarChartDataSet {
  label: string;
  data: number[];

  constructor(label) {
    this.label = label;
    this.data = [];
  }
}
