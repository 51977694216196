export const CONFIRMATION_STATUS = {
  PENDING_CONFIRMATION: 'PENDING_CONFIRMATION',
  CONFIRMED: 'CONFIRMED',
  DENIED: 'DENIED',
};

export const ASSESSMENT_STATUS = {
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  ASSESSMENT_FAILED: 'ASSESSMENT_FAILED',
  ASSESSED: 'ASSESSED',
};

export type ObjectiveStatus =
  | 'NOT_COMPROMISED'
  | 'COMPROMISED'
  | 'PENDING_COMPROMISED'
  | 'UNABLE_TO_COMPROMISE';

export const OBJECTIVE_STATUS: { [status: string]: ObjectiveStatus } = {
  NOT_COMPROMISED: 'NOT_COMPROMISED',
  COMPROMISED: 'COMPROMISED',
  PENDING_COMPROMISED: 'PENDING_COMPROMISED',
  UNABLE_TO_COMPROMISE: 'UNABLE_TO_COMPROMISE',
};

export const TARGET_STATUS = {
  COMPROMISED: 'COMPROMISED',
  GOOD: 'GOOD',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  UNDER_ATTACK: 'UNDER_ATTACK',
};

export const INCIDENT_TYPE = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
  COMPROMISED: 'COMPROMISED',
  NOT_COMPROMISED: 'NOT_COMPROMISED',
};

export enum VersusAttackStatus {
  SUCCESSFUL = 'SUCCESSFUL',
  UNDER_ATTACK = 'UNDER_ATTACK',
  FAILED = 'FAILED',
  INTERRUPTED = 'INTERRUPTED',
}
