<div class="user-redirect-dialog">
  @if (message) {
    <div>
      <span>{{ message }}</span>
    </div>
  }
  <div>
    <span>Redirecting in {{ secondsLeft }} seconds...</span>
  </div>
</div>
