<div class="scoring-log-details-dialog">
  <h2 matDialogTitle>{{ 'ui.scoring.details' | translate }}</h2>
  <mat-dialog-content class="coring-log-details-dialog-content">
    <div class="isa-details-box">
      <div class="row">
        <span class="label">{{ 'ui.scoring.category' | translate }}</span>
        {{ scoringLogItem?.category }}
      </div>
      <div class="row">
        <span class="label">{{ 'ui.scoring.time' | translate }}</span>
        {{ scoringLogItem?.timestamp | isaDate: 'medium' }}
      </div>
      <div class="row">
        <span class="label">{{ 'ui.team' | translate }}</span>
        {{ scoringLogItem?.teamId | isaTeamName }}
      </div>
      <div class="row">
        <span class="label">{{ 'ui.scoring.score' | translate }}</span>
        {{ scoringLogItem?.score | isaFixedNumber }}
      </div>
      @if (specialMessageDetails) {
        <div class="row details-container">
          <span class="label">{{ 'ui.scoring.details' | translate }}</span>
          <mat-form-field class="full-width">
            <textarea
              matInput
              [cdkTextareaAutosize]
              tabindex="-1"
              disabled
              [value]="specialMessageDetails.details"
            ></textarea>
          </mat-form-field>
        </div>
      }
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose>{{ 'ui.close' | translate }}</button>
  </mat-dialog-actions>
</div>
