import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationsService } from '@cybexer/ngx-commons';
import { finalize } from 'rxjs/operators';
import { SoundService } from '../../../../services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'isa-upload-sound-dialog',
  templateUrl: './upload-sound-dialog.component.html',
  styleUrls: ['./upload-sound-dialog.component.scss'],
})
export class UploadSoundDialogComponent {
  private static ACCEPTED_AUDIO_EXTENSIONS = ['wav', 'mp3'];
  private static MAX_FILE_SIZE = 3_000_000; // 3MB

  loading = false;
  sound: File;
  error: String;

  constructor(
    private soundService: SoundService,
    private notificationsService: NotificationsService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<UploadSoundDialogComponent>
  ) {}

  get maxFileSize(): number {
    return UploadSoundDialogComponent.MAX_FILE_SIZE;
  }

  setFile(event): void {
    if (event.target.files.length) {
      this.sound = event.target.files[0];
      this.validate();
    }
  }

  private validate(): void {
    if (this.sound.type.indexOf('audio') === -1) {
      this.error = this.translate.instant('ui.exercise.unsupportedFileTypeError');
      return;
    }

    const filename = this.sound.name;
    const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length);
    if (!UploadSoundDialogComponent.ACCEPTED_AUDIO_EXTENSIONS.includes(extension)) {
      this.error = this.translate.instant('ui.exercise.unsupportedFileTypeError');
    } else if (this.sound.size > UploadSoundDialogComponent.MAX_FILE_SIZE) {
      this.error = this.translate.instant('ui.exercise.fileSizeExceedsLimitError');
    } else {
      this.error = null;
    }
  }

  uploadSound(): void {
    if (this.sound == null) {
      this.error = 'ui.fileManager.selectFile';
    }
    if (this.error) {
      return;
    }

    this.loading = true;
    this.soundService
      .uploadSound(this.sound)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((soundInfo) => {
        this.notificationsService.success('ui.settings.audioFileUploaded');
        this.dialogRef.close(soundInfo);
      });
  }
}
