import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SituationReport } from '../../models';

@Injectable()
export class SituationService {
  private static BASE_API_URL = 'api/widget/situation-report';

  constructor(private http: HttpClient) {}

  createSituationReport(
    exerciseId: string,
    teamId: string,
    situationReport: SituationReport
  ): Observable<boolean> {
    return this.http
      .post(
        `${SituationService.BASE_API_URL}/exercise/${exerciseId}/team/${teamId}`,
        situationReport
      )
      .pipe(map(() => true));
  }

  getImageUploadUrl(exerciseId: string, teamId: string): string {
    return `${SituationService.BASE_API_URL}/exercise/${exerciseId}/team/${teamId}/images`;
  }
}
