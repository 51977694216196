import { SegmentStatusSummary, TeamStatusSummary } from '../../../models';

class StatusChartData<T> {
  get labels(): string[] {
    return this._labels;
  }

  get data(): T[] {
    return this._data;
  }

  protected _labels: string[];
  protected _data: T[];

  constructor() {
    this.clearData();
  }

  clearData(): void {
    this._labels = [];
    this._data = [];
  }
}

export class TeamStatusChartData extends StatusChartData<TeamStatusSummary> {
  setData(data: TeamStatusSummary[]): void {
    this._data = data;
    this._labels = data.map((item: TeamStatusSummary) => item.teamName.toUpperCase());
  }
}

export class SegmentStatusChartData extends StatusChartData<SegmentStatusSummary> {
  setData(data: SegmentStatusSummary[]) {
    this._data = data;
    this._labels = data.map((item: SegmentStatusSummary) => item.networkSegmentName.toUpperCase());
  }
}
