import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { PreferenceService } from '../shared/preference.service';

@Injectable()
export class TeamService {
  activeTeam: BehaviorSubject<string>;

  constructor(private preferenceService: PreferenceService) {
    this.activeTeam = new BehaviorSubject<string>(null);
  }

  selectTeam(teamId: string, exerciseId: string): void {
    this.activeTeam.next(teamId);
    this.preferenceService
      .updatePreferences({ defaultExerciseId: exerciseId, defaultTeamId: teamId })
      .subscribe();
  }

  getActiveTeam(): Observable<string> {
    if (this.activeTeam.value) {
      return of(this.activeTeam.value);
    }

    return this.preferenceService.getPreferences().pipe(
      mergeMap((pref) => {
        if (pref.defaultTeamId) {
          this.activeTeam.next(pref.defaultTeamId);
        }
        return of(this.activeTeam.value);
      })
    );
  }
}
