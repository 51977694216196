import moment from 'moment';
import { BaseModel } from './base.model';

export enum GmaStatus {
  POWER_OFF = 'POWER_OFF',
  BROADCAST = 'BROADCAST',
  HEARTBEAT = 'HEARTBEAT',
  EXCLAMATION = 'EXCLAMATION',
  UNKNOWN = 'UNKNOWN',
}

export enum TooltipStatus {
  POWER_OFF = 'No GMA connection',
  BROADCAST = 'Connected to GMA',
  HEARTBEAT = 'Exercise running',
  EXCLAMATION = 'Exercise stopped',
  UNKNOWN = 'Unknown status',
}

export class GmaOverview extends BaseModel {
  static ACTIVITY_TIMEOUT_IN_SECONDS = 120;

  id: string;
  ipAddress: string;
  registered: Date;
  latestActivityTimestamp?: Date;
  latestGmaPollTimestamp?: Date;
  exercises: GmaExerciseOverview[] = [];
  status: GmaStatus;
  duplicateMessages: number;

  constructor(data?) {
    super(data);

    this.calculateStatus();

    if (data && data.hasOwnProperty('exercises')) {
      this.exercises = data.exercises.map((it) => new GmaExerciseOverview(it));
    }
  }

  private calculateStatus(): void {
    const validGmaTimeout = GmaOverview.isValidTimeout(this.latestGmaPollTimestamp);
    const validActivityTimeout = GmaOverview.isValidTimeout(this.latestActivityTimestamp);

    if (!validGmaTimeout) {
      this.status = GmaStatus.POWER_OFF;
    } else if (this.latestActivityTimestamp == null) {
      this.status = GmaStatus.BROADCAST;
    } else if (validActivityTimeout) {
      this.status = GmaStatus.HEARTBEAT;
    } else if (!validActivityTimeout) {
      this.status = GmaStatus.EXCLAMATION;
    } else {
      this.status = GmaStatus.UNKNOWN;
    }
  }

  private static isValidTimeout(inputTime: Date): boolean {
    if (inputTime == null) {
      return false;
    }
    const currentTime = moment.utc();
    const time = moment(inputTime);
    const diff = moment.duration(currentTime.diff(time)).asSeconds();
    return diff <= GmaOverview.ACTIVITY_TIMEOUT_IN_SECONDS;
  }
}

export class GmaExerciseOverview extends BaseModel {
  id: string;
  name: string;
  teams: GmaTeamOverview[] = [];

  constructor(data?) {
    super(data);

    if (data && data.hasOwnProperty('teams')) {
      this.teams = data.teams.map((it) => new GmaTeamOverview(it));
    }
  }
}

export class GmaTeamOverview extends BaseModel {
  id: string;
  name: string;
}
