import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdvancedScoringLogData } from '../../models';

@Injectable()
export class AdvancedScoringLogService {
  constructor(private http: HttpClient) {}

  getLog(
    exerciseId: string,
    teamId: string,
    categories: string[] = [],
    startTime: Date,
    endTime: Date,
    timestamp: Date,
    page: number = 1,
    size: number = 20
  ): Observable<AdvancedScoringLogData> {
    let params = new HttpParams();
    params = params.set('limit', size.toString());
    params = params.set('offset', (size * (page - 1)).toString());
    if (timestamp) {
      params = params.set('timestamp', timestamp.toISOString());
    }
    if (teamId) {
      params = params.set('teamId', teamId.toString());
    }
    if (startTime) {
      params = params.set('startTime', new Date(startTime).toISOString());
    }
    if (endTime) {
      params = params.set('endTime', new Date(endTime).toISOString());
    }
    categories.forEach((c) => {
      params = params.append('category', c.toString());
    });

    return this.http
      .get<AdvancedScoringLogData>(`api/widget/advanced-scoring-log/exercise/${exerciseId}`, {
        params: params,
      })
      .pipe(map((data) => new AdvancedScoringLogData(data)));
  }
}
