<div class="sidenav-expansion-panel">
  <mat-expansion-panel>
    <mat-expansion-panel-header expandedHeight="84px" collapsedHeight="84px">
      <div class="icon-container">
        <i matListItemIcon class="fas fa-{{ icon }}" aria-hidden="true"></i>
      </div>
      <ul>
        <li>
          <span class="panel-header-title">{{ 'ui.nav.' + name | translate }}</span>
        </li>
        @if (description) {
          <li>
            <div class="panel-header-description">{{ description }}</div>
          </li>
        }
      </ul>
    </mat-expansion-panel-header>
    @for (group of groups; track group) {
      <isa-menu-group
        [group]="group"
        [isIndividualAssessment]="isIndividualAssessment"
        [isHybridExercise]="isHybridExercise"
        [isDefaultExerciseLoading]="isDefaultExerciseLoading"
      ></isa-menu-group>
    }
  </mat-expansion-panel>
</div>
