import { BaseModel } from '../shared/base.model';
import { UserListItem } from './user.model';

export class TeamAllocation extends BaseModel {
  usersByTeamId: UsersByTeamId;
  notAllocatedUsers: UserListItem[];
}

export class TeamAllocationSave extends BaseModel {
  usernames: string[];
  previousTeamId?: string;
  teamId: string;
  role: string;
}

interface UsersByTeamId {
  [teamId: string]: UserListItem[];
}

export interface AllocatableTeamWithUsers {
  id?: string;
  name?: string;
  users: UserListItem[];
}
