import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { IsaModule } from '../models/shared/isa-module.model';
import { SettingsService } from '../services';

/**
 * Checks is the given module is enabled in settings.
 */
@Injectable()
export class GamenetModuleGuard {
  constructor(
    private settingsService: SettingsService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.settingsService.isModuleEnabled(IsaModule.GAMENET).pipe(
      tap((isEnabled) => {
        if (!isEnabled) {
          this.router.navigate(['/app']);
        }
      })
    );
  }
}
