import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CalculationProgress } from '../../models';
import { EventSourceUtil } from '../../shared/eventsource.util';

@Injectable()
export class TargetCheckAvailabilityService {
  constructor(
    private http: HttpClient,
    private zone: NgZone
  ) {}

  recalculateData(): Observable<Boolean> {
    return this.http.post('api/target-check-availability/recalculate', null).pipe(map(() => true));
  }

  listenForCalculationProgressEvent(): Observable<CalculationProgress> {
    return EventSourceUtil.create(
      'api/target-check-availability/calculation-progress/subscribe',
      this.zone,
      CalculationProgress
    );
  }
}
