import { Component, Input } from '@angular/core';
import { NavigationMenuGroup } from '../../../../models';

@Component({
  selector: 'isa-menu-section',
  templateUrl: 'menu-section.component.html',
  styleUrls: ['menu-section.component.scss'],
})
export class MenuSectionComponent {
  @Input() name: String;
  @Input() description: String = '';
  @Input() icon: String;
  @Input() groups: NavigationMenuGroup[] = [];
  @Input() isIndividualAssessment: boolean;
  @Input() isHybridExercise: boolean;
  @Input() isDefaultExerciseLoading: boolean;
}
