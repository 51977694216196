import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { OrganizedCTFTaskDetailsDTO } from '../../../models/gamenet/ctf/ctf-mission.model';

@Injectable({
  providedIn: 'root',
})
export class TaskDetailsService {
  reloadTasks$ = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {}

  getTaskDetailsList(exerciseId: string): Observable<OrganizedCTFTaskDetailsDTO[]> {
    return this.http
      .get<OrganizedCTFTaskDetailsDTO[]>(TaskDetailsService.getBaseUrl(exerciseId))
      .pipe(map((data) => data.map((taskDetails) => new OrganizedCTFTaskDetailsDTO(taskDetails))));
  }

  private static getBaseUrl(exerciseId: string): string {
    return `api/ctf-exercises/${exerciseId}/task-details`;
  }
}
