import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class ServiceUtil {
  static createHttpParamsFromObj(obj: any): HttpParams {
    let params = new HttpParams();

    Object.keys(obj).forEach((propName) => {
      const propValue = obj[propName];
      if (propValue != null) {
        params = params.set(propName, propValue.toString());
      }
    });

    return params;
  }
}
