import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'isa-image-removal-dialog',
  templateUrl: './image-removal-dialog.component.html',
  styleUrls: ['./image-removal-dialog.component.scss'],
})
export class ImageRemovalDialogComponent {
  constructor(private dialogRef: MatDialogRef<ImageRemovalDialogComponent>) {}

  confirmDeleteImage() {
    this.dialogRef.close(true);
  }
}
