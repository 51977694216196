import { BaseModel } from './base.model';

export class SentrySettings extends BaseModel {
  dsn: string;
  environment: string;
  version: string;

  constructor(data?) {
    super(data);
  }
}
