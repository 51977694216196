import { BaseModel } from '../shared/base.model';

export class ReportDetails extends BaseModel {
  reportId: string;
  data?: string;

  constructor(data?) {
    super(data);
  }
}
