import { Injectable } from '@angular/core';
import { WindowMock } from 'app/mock/window.mock';

const getWindow = (): Window => (window != null ? window : new WindowMock());

@Injectable()
export class WindowRefService {
  private readonly windowObject: Window;

  constructor() {
    this.windowObject = getWindow();
  }

  get window(): Window {
    return this.windowObject;
  }

  get document(): Document {
    return this.window.document;
  }

  get localStore(): Storage {
    return this.window.localStorage;
  }

  get sessionStorage(): Storage {
    return this.window.sessionStorage;
  }
}
