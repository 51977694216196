import { BaseModel } from '../shared/base.model';
import { FileType } from '../shared/file-info.model';

export class NewsInjection extends BaseModel {
  id?: string;
  exerciseId: string;
  title: string;
  description: string;
  timestamp?: Date;
  mediaId: string;
  thumbnailId: string;
  fileType: FileType;
  teams?: NewsInjectTeam[] = [];
  // FE field only
  status = InjectStatus.UNPUBLISHED;

  constructor(data?) {
    super(data);

    if (data.hasOwnProperty('teams')) {
      this.teams = data.teams.map((team) => new NewsInjectTeam(team));
      const isPublishedToTeams = this.teams.some(
        (team: NewsInjectTeam) => team.injectStatus === InjectStatus.PUBLISHED
      );
      if (isPublishedToTeams) {
        this.status = InjectStatus.PUBLISHED;
      }
    }
  }
}

export class NewsInjectTeam extends BaseModel {
  teamId: string;
  teamName: string;
  injectStatus: InjectStatus;
  injectPublicationId?: string;
  injectPublicationTimestamp?: Date;

  constructor(data?) {
    super(data);

    if (this.injectStatus === undefined) {
      this.injectStatus = InjectStatus.UNPUBLISHED;
    }
  }
}

export class NewsInjectPublication extends BaseModel {
  id?: string;
  exerciseId: string;
  newsInjectId: string;
  teamId: string;
  timestamp?: Date;
  unpublishedTimestamp?: Date;

  constructor(data?) {
    super(data);
  }
}

export class NewsInjectCommentSaveDTO extends BaseModel {
  newsInjectId: string;
  username: string;
  message: string;
  teamId?: string;

  constructor(data?) {
    super(data);
  }
}

export class NewsInjectComment extends BaseModel {
  id: string;
  newsInjectId: string;
  teamId: string;
  username: string;
  message: string;
  timestamp?: Date;

  constructor(data?) {
    super(data);
  }
}

export enum InjectStatus {
  PUBLISHED = 'PUBLISHED',
  UNPUBLISHED = 'UNPUBLISHED',
}
