import { DomainPermission, EXERCISE_PERMISSIONS } from '../../shared';
import { BaseModel } from '../shared/base.model';
import { UserRole } from '../shared/role.model';

export enum LoginOrigin {
  LOCAL = 'LOCAL',
  LDAP = 'LDAP',
  OBSERVER = 'OBSERVER',
  EXTERNAL = 'EXTERNAL',
  UNKNOWN = 'UNKNOWN',
}

export class User extends BaseModel {
  username: string;
  fullName: string;
  loginOrigin: LoginOrigin;
  isAdmin: boolean;
  permissions: DomainPermission[];
  redirectUrl?: string;

  constructor(data?) {
    super(data);

    if (data.hasOwnProperty('permissions')) {
      this.permissions = data.permissions.map((permission) => new DomainPermission(permission));
    }
  }

  isObserver(): boolean {
    return this.loginOrigin === LoginOrigin.OBSERVER;
  }

  isGamenetAdmin(exerciseId?: string): boolean {
    let gamenetAdminPermission = new DomainPermission(EXERCISE_PERMISSIONS.ADMIN);
    if (exerciseId != null) {
      gamenetAdminPermission = gamenetAdminPermission.withTargets(exerciseId);
    }

    return this.permissions.some((permission) =>
      permission.implies(gamenetAdminPermission, exerciseId != null)
    );
  }
}

export class UserDetails extends BaseModel {
  username: string;
  fullName: string;
  roles: UserRole[];
  registered: Date;

  constructor(data?) {
    super(data);
    if (data.hasOwnProperty('roles')) {
      this.roles = data.roles.map((role) => new UserRole(role));
    }
  }
}

export class UserListItem extends BaseModel {
  username: string;
  fullName: string;
  origin?: LoginOrigin;
  isSetForRemoval: boolean;
  selected: boolean = false;
}

export class UserSaveDTO extends BaseModel {
  username: string;
  fullName: string;
  password: string;
}

export class GroupedUserData {
  constructor(
    public username: string,
    public isMissing: boolean,
    public origins: LoginOrigin[] = []
  ) {}

  isFromLDAP(): boolean {
    return this.hasLoginOriginType(LoginOrigin.LDAP);
  }

  isLocal(): boolean {
    return this.hasLoginOriginType(LoginOrigin.LOCAL);
  }

  private hasLoginOriginType(loginOrigin: LoginOrigin): boolean {
    return this.origins.some((it) => it === loginOrigin);
  }
}

export type UserEventAction = 'LOGOUT' | 'TEAM_ASSIGNMENT';
