import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import isEqual from 'lodash-es/isEqual';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { ReplaySubject, Subscription } from 'rxjs';
import { delay, filter } from 'rxjs/operators';

@Directive({
  selector: '[isaInfiniteScrollLoadMoreItemsOnStartup]',
  exportAs: 'isaInfiniteScrollLoadMoreItemsOnStartup',
})
export class InfiniteScrollLoadMoreItemsOnStartupDirective implements OnInit, OnDestroy {
  items$ = new ReplaySubject<any[]>(1);
  itemSub?: Subscription;
  private previousItems: any[];

  @Input() set items(items: any[]) {
    this.items$.next(items);
  }

  constructor(
    private elementRef: ElementRef,
    private infiniteScroll: InfiniteScrollDirective
  ) {}

  ngOnInit() {
    this.itemSub = this.items$
      .pipe(
        // ignore until we've loaded some items
        filter((items) => items?.length > 0 && !isEqual(this.previousItems, items)),
        // wait for container to re-render the new items list
        delay(1)
      )
      .subscribe((items) => {
        this.previousItems = items;
        const htmlElement = this.elementRef.nativeElement as HTMLElement;
        if (htmlElement.clientHeight === htmlElement.scrollHeight) {
          this.infiniteScroll.scrolled.emit();
        }
      });
  }

  ngOnDestroy() {
    this.itemSub?.unsubscribe();
  }
}
