import { BaseModel } from './base.model';

export interface TargetsByDomain {
  [domain: string]: TargetsDTO[];
}

export interface UserTargetsByDomain {
  [domain: string]: string[];
}

export class AssignableRoles extends BaseModel {
  roles: RoleListItem[];
  targetsByDomain: TargetsByDomain;

  constructor(data?) {
    super(data);
  }
}

export class RoleListItem extends BaseModel {
  code: string;
  description: string;
  domains: string[];
}

export class RoleTableItem {
  constructor(
    public code: string,
    public description: string,
    public domains: string[],
    public status: boolean
  ) {}
}

export class TargetsDTO extends BaseModel {
  targets: TargetListItem[];
  parentDomain?: string;
  parentTargetId?: string;
  parentTargetName?: string;

  constructor(data?) {
    super(data);
    if (data.hasOwnProperty('targets')) {
      this.targets = data.targets.map((target) => new TargetListItem(target));
    }
  }
}

export class TargetListItem extends BaseModel {
  id: string;
  name: string;
  customName?: string;
}

export class UserRole extends BaseModel {
  code: string;
  description: string;
  targetsByDomain: UserTargetsByDomain;

  constructor(data?) {
    super(data);
  }
}
