<div class="attack-report-confirm-dialog">
  <h2 matDialogTitle>{{ 'ui.attackCampaign.redObjectiveReport' | translate }}</h2>
  <mat-dialog-content class="attack-report-confirm-dialog-content">
    <div class="isa-details-box">
      @if (aiFeaturesEnabled && attackReport.aiAssessmentStatus) {
        <mat-card class="ai-assessment-results">
          <mat-card-title>
            <mat-icon>android</mat-icon>
            <span
              >{{ 'ui.reports.autoAssessStatus' | translate }}:
              {{ attackReport.aiAssessmentStatus }}</span
            >
          </mat-card-title>
          @if (attackReport.aiScore != null) {
            <div>{{ 'ui.reports.rating' | translate }}: {{ attackReport.aiScore }}</div>
          }
          @if (attackReport.aiFeedback) {
            <div>
              <mat-label>{{ 'ui.reports.aiFeedback' | translate }}</mat-label>
              <div class="report-content disable-uppercase">
                {{ attackReport.aiFeedback }}
              </div>
            </div>
          }
        </mat-card>
      }

      <div class="row row-evidence">
        <span class="label">{{ 'ui.attackCampaign.evidence' | translate }}</span>
        @if (reportDetailsLoaded) {
          <quill-editor
            [ngModel]="reportDetails"
            [readOnly]="true"
            [modules]="{ toolbar: false }"
          ></quill-editor>
        }
        @if (!reportDetailsLoaded) {
          <cybexer-progress-spinner></cybexer-progress-spinner>
        }
      </div>
      <div>
        <div class="row">
          <span class="label">{{ 'ui.attackCampaign.status' | translate }}</span>
          {{ OBJECTIVE_DISPLAY_STATUSES[attackReport?.objectiveStatus] | isaReplaceUnderScores }}
        </div>
        <div class="row">
          <span class="label">{{ 'ui.attackCampaign.redMember' | translate }}</span>
          {{ attackReport?.username }}
        </div>
        <div class="row">
          <span class="label">{{ 'ui.attackCampaign.time' | translate }}</span>
          {{ attackReport?.timestamp | isaDate: 'medium' }}
        </div>
        <div class="row" data-testid="objective-row">
          <span class="label">{{ 'ui.attackCampaign.objective' | translate }}</span>
          @if (attackReport.objectiveReportsCount > 1) {
            <i
              class="far fa-clone report-count"
              [matTooltip]="'Reports for this objective: ' + attackReport.objectiveReportsCount"
              [matBadge]="attackReport.objectiveReportsCount.toString()"
              matBadgeColor="warn"
            ></i>
          }
          <span class="disable-uppercase">
            {{ attackReport?.objectiveId | isaObjectiveName: exercise }}</span
          >
        </div>
        <div class="row">
          <span class="label">{{ 'ui.attackCampaign.targetCheckGroup' | translate }}</span>
          {{ attackReport?.objectiveContainerId | isaObjectiveContainerName: exercise }}
        </div>
        <div class="row">
          <span class="label">{{ 'ui.attackCampaign.campaignPhase' | translate }}</span>
          {{ attackReport?.objectiveId | isaObjectiveCampaignPhase: exercise }}
        </div>
        <div class="row">
          <span class="label">{{ 'ui.attackCampaign.team' | translate }}</span>
          {{ attackReport?.teamId | isaTeamName }}
        </div>
      </div>
    </div>
    @if (showFeedback()) {
      <mat-form-field class="full-width textarea feedback">
        <mat-label>{{ 'ui.attackCampaign.feedback' | translate }}</mat-label>
        <textarea
          [disabled]="
            !hasPermissionToConfirmOrDeny ||
            attackReport.status !== CONFIRMATION_STATUS.PENDING_CONFIRMATION
          "
          [(ngModel)]="feedback"
          matInput
          [cdkTextareaAutosize]
          tabindex="-1"
        ></textarea>
      </mat-form-field>
    }
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    @if (
      hasPermissionToConfirmOrDeny &&
      attackReport.status === CONFIRMATION_STATUS.PENDING_CONFIRMATION
    ) {
      @if (
        aiFeaturesEnabled &&
        (attackReport.aiAssessmentStatus == null ||
          attackReport.aiAssessmentStatus === ASSESSMENT_STATUS.ASSESSMENT_FAILED)
      ) {
        <button mat-raised-button (click)="sendToAIAssessment()">
          <i class="fas fa-magic-wand-sparkles status-ai-icon" aria-hidden="true"></i>
          {{ 'ui.attackCampaign.autoAssess' | translate }}
        </button>
      }
      <cybexer-confirm-action [attr.data-testid]="'confirm-wrapper'">
        <button
          class="isa-button-confirm"
          (click)="submitConfirmation(CONFIRMATION_STATUS.CONFIRMED)"
          mat-raised-button
        >
          <i class="fas fa-check-circle mat-button-fa" aria-hidden="true"></i
          >{{ 'ui.confirm' | translate }}
        </button>
      </cybexer-confirm-action>
      <cybexer-confirm-action [attr.data-testid]="'deny-wrapper'">
        @if (attackReport?.objectiveStatus === OBJECTIVE_STATUS.COMPROMISED) {
          <button mat-raised-button (click)="submitConfirmation(CONFIRMATION_STATUS.DENIED)">
            <i class="fas fa-exclamation-circle mat-button-fa" aria-hidden="true"></i
            >{{ 'ui.deny' | translate }}
          </button>
        }
      </cybexer-confirm-action>
    }
    <button mat-button matDialogClose>{{ 'ui.cancel' | translate }}</button>
  </mat-dialog-actions>
</div>
