<form>
  <h2 matDialogTitle>{{ 'ui.attackCampaign.assignAllTeamObjectives' | translate }}</h2>
  <mat-dialog-content>
    <div class="isa-details-box">
      <div class="row">
        <label class="label" for="objective-name">{{
          'ui.attackCampaign.objective' | translate
        }}</label>
        <div id="objective-name" class="disable-uppercase">{{ data.objective.name }}</div>
      </div>
      <div class="row">
        <label class="label" for="team-name">{{ 'ui.attackCampaign.teams' | translate }}</label>
        @for (team of filteredTeams; track team) {
          <div id="team-name" class="disable-uppercase">
            {{ team.teamId | isaTeamName }}
            @if (team.objectiveUserName && team.objectiveUserName !== data.currentUsername) {
              <span class="disable-uppercase">
                ({{ 'ui.attackCampaign.currentlyAssignedTo' | translate }}:
                {{ team.objectiveUserName }})
              </span>
            }
          </div>
        }
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div>
      <button mat-raised-button color="primary" (click)="assign()">
        {{ 'ui.assign' | translate }}
      </button>
      <button mat-button matDialogClose>{{ 'ui.cancel' | translate }}</button>
    </div>
  </mat-dialog-actions>
</form>
