import { BaseModel } from '../shared/base.model';
import { Objective } from './objective.model';

export class TargetCheck extends BaseModel {
  id: string;
  name: string;
  description: string;
  type: string;
  objectives: Objective[] = [];
  username: string;

  constructor(data?) {
    super(data);
    if (data && data.hasOwnProperty('objectives')) {
      this.objectives = data.objectives.map((obj) => new Objective(obj));
    }
  }
}
