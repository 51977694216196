import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Targets } from '../../models';

@Injectable()
export class TargetService {
  constructor(private http: HttpClient) {}

  getTargets(exerciseId: string, blueTeamId: string): Observable<Targets> {
    return this.http
      .get<Targets>(`api/exercise/${exerciseId}/blueTeam/${blueTeamId}/targets`)
      .pipe(map((data) => new Targets(data)));
  }
}
