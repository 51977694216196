import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HealthCheckData } from '../../models';
import { EventSourceUtil } from '../../shared/eventsource.util';

@Injectable()
export class HealthCheckDataService {
  private static HEALTH_DATA_URL = 'api/health-data';

  constructor(
    private http: HttpClient,
    private zone: NgZone
  ) {}

  getHealthCheckData(): Observable<HealthCheckData> {
    return this.http
      .get<HealthCheckData>(HealthCheckDataService.HEALTH_DATA_URL)
      .pipe(map((data) => new HealthCheckData(data)));
  }

  listenForHealthDataUpdateEvent(): Observable<HealthCheckData> {
    return EventSourceUtil.create<HealthCheckData>(
      `${HealthCheckDataService.HEALTH_DATA_URL}/subscribe`,
      this.zone,
      HealthCheckData
    );
  }
}
