<cybexer-widget-header
  [title]="'ui.nav.reportingStatus' | translate"
  [inline]="true"
  class="reporting-status-header"
>
  <mat-form-field class="campaign-phase-filter-dropdown-field">
    <mat-label>{{ 'ui.reportingStatus.campaignPhase' | translate }}</mat-label>
    <mat-select
      [ngModel]="(filter$ | async).campaignPhase"
      (ngModelChange)="filterStateService.setFilter('campaignPhase', $event)"
      class="campaign-phase-filter-dropdown"
    >
      @for (campaignPhase of exercise?.campaignPhases; track campaignPhase) {
        <mat-option [value]="campaignPhase.id">
          {{ campaignPhase.name }}
        </mat-option>
      }
    </mat-select>
  </mat-form-field>
  <mat-form-field>
    <mat-label
      ><i class="fas fa-filter" aria-hidden="true"></i>
      {{ 'ui.reportingStatus.objective' | translate }}</mat-label
    >
    <input
      autocomplete="off"
      matInput
      [formControl]="filterText"
      (keydown.enter)="$event.preventDefault()"
    />
  </mat-form-field>
  <div class="spacer"></div>
  <cybexer-row>
    <cybexer-meta-item>
      <i
        class="fas fa-users"
        aria-hidden="true"
        [matTooltip]="'ui.reportingStatus.clientSide' | translate | uppercase"
      ></i>
      {{ 'ui.reportingStatus.clientSide' | translate }}
    </cybexer-meta-item>
    <cybexer-meta-item>
      <i
        class="fas fa-sitemap"
        aria-hidden="true"
        [matTooltip]="'ui.reportingStatus.networking' | translate | uppercase"
      ></i>
      {{ 'ui.reportingStatus.networking' | translate }}
    </cybexer-meta-item>
    <cybexer-meta-item>
      <i
        class="fas fa-server"
        aria-hidden="true"
        [matTooltip]="'ui.reportingStatus.web' | translate | uppercase"
      ></i>
      {{ 'ui.reportingStatus.web' | translate }}
    </cybexer-meta-item>
  </cybexer-row>
</cybexer-widget-header>
@for (campaignPhase of reportingStatuses; track campaignPhase) {
  <div class="reporting-status">
    <div class="cybexer-table-wrapper">
      <table class="isa-table sticky-table">
        <tbody>
          @for (
            objective of campaignPhase.objectives | nameFilter: filterText.value;
            track objective
          ) {
            <tr class="reporting-status-row">
              <td>
                <div class="objective-name-target-check">
                  <div class="category-icon">
                    @if (
                      objective?.category === 'CLIENT_SIDE' && objective.type !== 'TARGET_GROUP'
                    ) {
                      <span>
                        <i
                          class="fas fa-users"
                          aria-hidden="true"
                          [matTooltip]="'ui.reportingStatus.clientSide' | translate | uppercase"
                        ></i>
                      </span>
                    }
                    @if (
                      objective?.category === 'CLIENT_SIDE' && objective.type === 'TARGET_GROUP'
                    ) {
                      <span
                        [matTooltip]="'ui.reportingStatus.clientSide' | translate | uppercase"
                        class="target-group-objective"
                      >
                        <img
                          [src]="
                            isLightTheme
                              ? '../../../../assets/svg/client-group-light-theme.svg'
                              : '../../../../assets/svg/client-group.svg'
                          "
                          class="svg-icon"
                        />
                      </span>
                    }
                    @if (
                      objective?.category === 'NETWORKING' && objective.type !== 'TARGET_GROUP'
                    ) {
                      <span>
                        <i
                          class="fas fa-sitemap"
                          aria-hidden="true"
                          [matTooltip]="'ui.reportingStatus.networking' | translate | uppercase"
                        ></i>
                      </span>
                    }
                    @if (
                      objective?.category === 'NETWORKING' && objective.type === 'TARGET_GROUP'
                    ) {
                      <span
                        [matTooltip]="'ui.reportingStatus.networking' | translate | uppercase"
                        class="target-group-objective"
                      >
                        <img
                          [src]="
                            isLightTheme
                              ? '../../../../assets/svg/network-group-light-theme.svg'
                              : '../../../../assets/svg/network-group.svg'
                          "
                          class="svg-icon"
                        />
                      </span>
                    }
                    @if (objective?.category === 'WEB' && objective.type !== 'TARGET_GROUP') {
                      <span>
                        <i
                          class="fas fa-server"
                          aria-hidden="true"
                          [matTooltip]="'ui.reportingStatus.web' | translate | uppercase"
                        ></i>
                      </span>
                    }
                    @if (objective?.category === 'WEB' && objective.type === 'TARGET_GROUP') {
                      <span
                        [matTooltip]="'ui.reportingStatus.web' | translate | uppercase"
                        class="target-group-objective"
                      >
                        <img
                          [src]="
                            isLightTheme
                              ? '../../../../assets/svg/web-group-light-theme.svg'
                              : '../../../../assets/svg/web-group.svg'
                          "
                          class="svg-icon"
                        />
                      </span>
                    }
                  </div>
                  <div class="objective-name">{{ objective.name }}</div>
                </div>
              </td>
              @for (team of objective.teams; track team) {
                <td class="statuses team-cell">
                  @if (team) {
                    <div
                      [ngClass]="
                        team?.incidentReportCount && !isRedTeam
                          ? 'clickable-cell'
                          : 'not-clickable-cell'
                      "
                      (click)="openReportingStatusIncidentReportDialog(team)"
                    >
                      <div class="team-status">
                        @if (team?.objectiveStatus === 'IN_PROGRESS') {
                          <span
                            ><i class="in-progress fas fa-bolt"></i
                            >{{ 'ui.reportingStatus.inProgress' | translate }}</span
                          >
                        }
                        @if (team?.objectiveStatus === 'SUCCESSFUL') {
                          <span
                            ><i class="success fas fa-check-circle"></i
                            >{{ 'ui.reportingStatus.successful' | translate }}</span
                          >
                        }
                        @if (team?.objectiveStatus === 'FAILED') {
                          <span
                            ><i class="failed fas fa-minus-circle"></i
                            >{{ 'ui.reportingStatus.failed' | translate }}</span
                          >
                        }
                        @if (team?.objectiveStatus === 'NOT_STARTED') {
                          <span class="grayed">{{
                            'ui.reportingStatus.notStarted' | translate
                          }}</span>
                        }
                      </div>
                      <div class="report-count">
                        <span
                          [ngClass]="{ grayed: !team?.incidentReportCount }"
                          [matTooltip]="'ui.reportingStatus.incidentReports' | translate"
                          ><i class="fas fa-file"></i>{{ team?.incidentReportCount }}</span
                        >
                        <span
                          [ngClass]="{
                            grayed: !team?.objectiveAttacks?.successfulAttackReportCount
                          }"
                          [matTooltip]="'ui.reportingStatus.successfulAttackReports' | translate"
                          ><i class="fas fa-check-circle"></i
                          >{{ team?.objectiveAttacks?.successfulAttackReportCount }}</span
                        >
                        <span
                          [ngClass]="{ grayed: !team?.objectiveAttacks?.failedAttackReportCount }"
                          [matTooltip]="'ui.reportingStatus.failedAttackReports' | translate"
                          ><i class="fas fa-minus-circle"></i
                          >{{ team.objectiveAttacks?.failedAttackReportCount }}</span
                        >
                      </div>
                    </div>
                  }
                </td>
              }
            </tr>
          }
        </tbody>
        <thead>
          <tr>
            <th class="phase-name sticky">
              {{ campaignPhase.campaignPhaseName }}
            </th>
            @for (blueTeam of exercise?.blueTeams; track blueTeam) {
              <th class="team-cell sticky">
                {{ blueTeam.customName }}
              </th>
            }
          </tr>
        </thead>
      </table>
      @if (!reportingStatuses?.length) {
        <cybexer-no-data></cybexer-no-data>
      }
    </div>
  </div>
}
@if (!loaded && !reportingStatuses?.length) {
  <cybexer-progress-spinner></cybexer-progress-spinner>
}
