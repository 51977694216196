import { BaseModel } from '../../shared/base.model';
import { CTFTaskStatus } from './ctf-task-status.model';

export class CTFTaskEventSaveResult extends BaseModel {
  status: CTFTaskStatus;
  score: number;

  constructor(data?) {
    super(data);
  }
}
