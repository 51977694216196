<div class="smart-city">
  <mat-card class="city-select">
    <mat-form-field>
      <mat-label>Map</mat-label>
      <mat-select [(ngModel)]="selectedCity" (ngModelChange)="saveSelectedCityToLocalStorage()">
        @for (city of cities; track city) {
          <mat-option [value]="city">{{ city.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </mat-card>
  @if (teams.length) {
    <mat-card class="team-select">
      <isa-filtered-select
        [options]="teams"
        [preselectedOption]="(filter$ | async).team"
        [optionKey]="'customName'"
        [optionValue]="'id'"
        [label]="'Team'"
        (selectionChange)="filterStateService.setFilter('team', $event)"
      ></isa-filtered-select>
    </mat-card>
  }
  <isa-smart-city-visualization
    [cityState]="cityState"
    [selectedBuildingDetails]="selectedBuildingDetails"
    [selectedCity]="selectedCity"
    [teamId]="teamId"
    (showPanel)="showInfoPanel($event)"
  ></isa-smart-city-visualization>
  @if (isLogOpen) {
    <mat-card class="log-container">
      <div class="logs-header">
        <div>Event log</div>
        <div>
          <button (click)="isLogOpen = false" mat-icon-button aria-label="minimize">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
      <div class="logs-wrapper">
        @for (log of eventLogs; track eventLogs) {
          <div class="logs">
            <div>{{ log.timestamp | isaDate: 'mediumTime' }}</div>
            <div>{{ log.status | isaReplaceUnderScores }}</div>
            <div>
              @if (log.metadata) {
                {{ log.name }} |
                {{
                  log.metadata.target?.name ||
                    log.metadata.task?.score + ' ' + ('ui.smartCity.points' | translate)
                }}
                {{ concatBuildingTypes(log) }}
              } @else {
                <span class="disable-uppercase tone-down">{{ log.name }}</span>
              }
            </div>
          </div>
        }
      </div>
    </mat-card>
  } @else {
    <button
      (click)="isLogOpen = true"
      class="logs-button"
      mat-raised-button
      aria-label="event logs"
    >
      <mat-icon>article</mat-icon>
      Event log
    </button>
  }

  @if (isInfoPanelOpen()) {
    <isa-info-panel
      [exerciseId]="exercise.id"
      [teamId]="teamId"
      [selectedBuildingDetails]="selectedBuildingDetails"
      [selectedTarget]="selectedTarget"
      [selectedTask]="selectedTask"
      [selectedCity]="selectedCity.name"
      [selectedMeshId]="selectedMeshId"
      [targets]="targets"
      [ctfTasks]="ctfTasks"
      [filesMetaData]="filesMetaData$ | async"
      (buildingDataChanged)="loadData()"
    ></isa-info-panel>
  }
</div>
