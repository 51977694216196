import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CampaignLiveTarget } from '../../../../../../models';
import {
  IntegrationEventSubjectType,
  IntegrationEventType,
} from '../../../../../../models/shared/integration-event.model';

@Component({
  selector: 'isa-campaign-live-target-dialog',
  templateUrl: './campaign-live-target-dialog.component.html',
  styleUrls: ['./campaign-live-target-dialog.component.scss'],
})
export class CampaignLiveTargetDialogComponent implements OnInit {
  target: CampaignLiveTarget;
  readonly IntegrationEventType = IntegrationEventType;
  readonly IntegrationEventSubjectType = IntegrationEventSubjectType;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.target = this.data.target;
  }
}
