import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GmaOverview } from '../../models';

@Injectable()
export class GmaService {
  private static BASE_URL = 'api/widget/gma';

  constructor(private http: HttpClient) {}

  getGmaOverviews(): Observable<GmaOverview[]> {
    return this.http
      .get<GmaOverview[]>(GmaService.BASE_URL)
      .pipe(map((dataArray) => dataArray.map((data) => new GmaOverview(data))));
  }

  deleteGma(gmaId): Observable<boolean> {
    return this.http.delete(`${GmaService.BASE_URL}/${gmaId}`).pipe(map(() => true));
  }
}
