import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TargetCheckAssignment, TargetStatusSummary } from '../../models';

@Injectable()
export class TargetStatusService {
  constructor(private http: HttpClient) {}

  getTargetStatusData(
    exerciseId: string,
    teamId: string,
    networkSegmentId: string
  ): Observable<TargetStatusSummary[]> {
    let params = new HttpParams();
    if (networkSegmentId) {
      params = params.set('networkSegmentId', networkSegmentId.toString());
    }

    return this.http
      .get<TargetStatusSummary[]>(TargetStatusService.getBaseUrl(exerciseId, teamId), {
        params: params,
      })
      .pipe(
        map((dataArray: any[]) => {
          return dataArray.map((data) => new TargetStatusSummary(data));
        })
      );
  }

  assignTarget(exerciseId: string, teamId: string, target: TargetStatusSummary) {
    return this.http
      .post(
        `${TargetStatusService.getBaseUrl(exerciseId, teamId)}/target/${
          target.targetId
        }/assign-checks`,
        target.targetChecks.map((it) => new TargetCheckAssignment(it.id, it.username))
      )
      .pipe(map(() => true));
  }

  private static getBaseUrl(exerciseId: string, teamId: string): string {
    return `api/widget/target-status/exercise/${exerciseId}/team/${teamId}`;
  }
}
