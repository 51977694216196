import { BaseModel } from '../shared/base.model';

export class IncidentReport extends BaseModel {
  targetId: string;
  targetCheckId: string;
  networkSegmentId: string;
  incidentType: string;
  username: string;
  details: string;

  constructor(data?) {
    super(data);
  }
}
