import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationsService } from '@cybexer/ngx-commons';
import { filter } from 'rxjs/operators';
import { BlueTeam, Exercise, SituationReport } from '../../../models';
import { AuthenticationService, ExerciseService, SituationService } from '../../../services';
import { QuillEditorUtil, RoleCode, ROLES } from '../../../shared';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'isa-situation-report',
  templateUrl: 'situation-report.component.html',
  styleUrls: ['situation-report.component.scss'],
})
export class SituationReportComponent implements OnInit {
  exercise: Exercise;
  teams: BlueTeam[] = [];
  reportForm: UntypedFormGroup;
  ROLES = ROLES;
  userRole: RoleCode;
  userBlueTeamId: string;
  isMessageChanged = false;
  quillEditorInstance: any;
  template: string;
  isAdmin: boolean;

  get imageUploadUrl(): string {
    return this.situationService.getImageUploadUrl(
      this.exercise.id,
      this.reportForm.controls['team'].value
    );
  }

  constructor(
    private exerciseService: ExerciseService,
    private authenticationService: AuthenticationService,
    private notificationsService: NotificationsService,
    private situationService: SituationService,
    private translate: TranslateService
  ) {
    this.isAdmin = authenticationService.currentUser.isAdmin;
  }

  ngOnInit() {
    this.reportForm = new UntypedFormGroup({
      team: new UntypedFormControl('', Validators.required),
      message: new UntypedFormControl('', Validators.required),
    });

    this.reportForm.controls['message'].valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.isMessageChanged = true;
    });

    this.exerciseService.activeExercise
      .pipe(
        filter((exercise: Exercise) => !!exercise),
        untilDestroyed(this)
      )
      .subscribe((exercise) => {
        this.userRole = this.authenticationService.getRole(exercise.id);
        this.exercise = exercise;
        this.reportForm.reset();
        this.teams = exercise ? exercise.blueTeams : [];
        if (!this.isAdmin) {
          this.isAdmin = this.authenticationService.currentUser.isGamenetAdmin(this.exercise.id);
        }
        if (this.userRole === this.ROLES.BLUE) {
          this.exerciseService
            .getUserBlueTeam(exercise.id)
            .pipe(untilDestroyed(this))
            .subscribe((userBlueTeamId) => {
              this.userBlueTeamId = userBlueTeamId;
              this.reportForm.controls['team'].setValue(userBlueTeamId);
            });
          if (!this.isAdmin) {
            this.reportForm.controls['team'].disable();
          }
        }

        this.template =
          exercise.situationReportTemplate || this.translate.instant('ui.situationReport.template');
        this.reportForm.controls['message'].setValue(this.template);

        this.isMessageChanged = false;
      });
  }

  onSubmit(form: UntypedFormGroup): void {
    if (form.valid) {
      const teamId =
        !this.isAdmin && this.userRole === this.ROLES.BLUE ? this.userBlueTeamId : form.value.team;
      this.situationService
        .createSituationReport(
          this.exercise.id,
          teamId,
          new SituationReport({
            username: this.authenticationService.currentUser.username,
            reportText: form.value.message,
          })
        )
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          if (this.userRole === this.ROLES.BLUE) {
            this.reportForm.controls['message'].reset();
            this.reportForm.controls['message'].setValue(this.template);
            this.isMessageChanged = false;
          } else {
            this.reportForm.reset();
            this.reportForm.controls['message'].setValue(this.template);
            this.isMessageChanged = false;
          }
          this.notificationsService.success(
            this.translate.instant('ui.situationReport.blueSituationReportSent')
          );
        });
    } else {
      Object.keys(form.controls).forEach((c) => form.controls[c].markAsTouched());
    }
  }

  addQuillImageHandler(editorInstance: any) {
    this.quillEditorInstance = editorInstance;
    QuillEditorUtil.addImageHandler(editorInstance, this.imageUploadUrl);
  }

  updateImageUploadUrl() {
    if (this.quillEditorInstance == null) return;

    QuillEditorUtil.updateImageUrl(this.quillEditorInstance, this.imageUploadUrl);
  }
}
