import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ExerciseService } from '../../../services';

@UntilDestroy()
@Component({
  selector: 'isa-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent implements OnChanges {
  @Input() exerciseId: string;
  @Input() teamId: string;
  @Input() src: string;
  @Input() styleClass: string;
  @Input() size = 75;
  srcUrl: string;
  isError = false;
  avatarLoaded = false;

  constructor(private exerciseService: ExerciseService) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.isError = false;
    this.updateSrcUrl();
  }

  updateSrcUrl() {
    if (this.src) {
      this.srcUrl = this.src;
      return;
    }

    if (this.exerciseId && this.teamId) {
      this.exerciseService
        .getTeamAvatar(this.exerciseId, this.teamId)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (url) => (this.srcUrl = url),
          error: () => this.setError(),
        });
      return;
    }

    this.srcUrl = null;
  }

  completeLoading() {
    this.avatarLoaded = true;
  }

  setError() {
    this.isError = true;
  }
}
