import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  isSidebarOpen = new BehaviorSubject<boolean>(true);
  sidenavState$ = this.isSidebarOpen.asObservable();

  setSidebarStatus(isOpen: boolean) {
    this.isSidebarOpen.next(isOpen);
  }

  getSidebarStatus() {
    return this.isSidebarOpen.getValue();
  }
}
