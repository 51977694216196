import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationsService } from '@cybexer/ngx-commons';
import { finalize } from 'rxjs/operators';
import { TargetStatusSummary } from '../../../../models';
import { VirtualMachineService } from '../../../../services';

@UntilDestroy()
@Component({
  selector: 'isa-target-revert-to-snapshot-dialog',
  templateUrl: './target-revert-to-snapshot-dialog.component.html',
  styleUrls: ['./target-revert-to-snapshot-dialog.component.scss'],
})
export class TargetRevertToSnapshotDialogComponent implements OnInit {
  private static EXPRESSION = /^(revert)$/i;

  target: TargetStatusSummary;
  exerciseId: string;
  teamId: string;
  revertInProgress = false;
  form: UntypedFormGroup;
  isTargetManagementEnabled = false;

  @Output() revertInProgressEvent = new EventEmitter<{
    revertInProgress: boolean;
    targetId: string;
  }>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private notificationsService: NotificationsService,
    private dialogRef: MatDialogRef<TargetRevertToSnapshotDialogComponent, boolean>,
    private virtualMachineService: VirtualMachineService
  ) {}

  ngOnInit() {
    this.target = this.data['target'];
    this.exerciseId = this.data['exerciseId'];
    this.teamId = this.data['teamId'];

    this.form = new UntypedFormGroup({
      confirmText: new UntypedFormControl(null, [
        Validators.required,
        TargetRevertToSnapshotDialogComponent.confirmationTextValidator(),
      ]),
    });
  }

  revertTarget(vmId: string, targetName: string, teamId: string): void {
    if (this.form.valid) {
      this.revertInProgress = true;
      this.revertInProgressEvent.emit({ revertInProgress: true, targetId: this.target.targetId });

      this.virtualMachineService
        .revertToCurrentSnapshot(vmId, this.exerciseId, targetName, teamId)
        .pipe(
          finalize(() => {
            this.revertInProgress = false;
          }),
          untilDestroyed(this)
        )
        .subscribe(() => {
          this.notificationsService.success('ui.targetStatus.targetReverted');
          this.revertInProgressEvent.emit({
            revertInProgress: false,
            targetId: this.target.targetId,
          });
        });
      this.dialogRef.close();
    }
  }

  private static confirmationTextValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (this.EXPRESSION.test(control.value)) {
        return null;
      }

      return { invalidConfirm: 'Should contain exactly "REVERT" word' };
    };
  }
}
