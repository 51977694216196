import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IncidentReport } from '../../models';

@Injectable()
export class IncidentService {
  private static BASE_API_URL = 'api/widget/incident-report';

  constructor(private http: HttpClient) {}

  createIncidentReport(
    exerciseId: string,
    teamId: string,
    incidentReports: IncidentReport[]
  ): Observable<boolean> {
    return this.http
      .post(
        `${IncidentService.BASE_API_URL}/exercise/${exerciseId}/team/${teamId}`,
        incidentReports
      )
      .pipe(map(() => true));
  }
}
