import { BaseModel } from '../..';

export class CTFExerciseStatus extends BaseModel {
  endTime: Date;
  leader?: ExerciseStatusLeader;
  totalTeams: number;
  tasksTotal: number;
  hintsUsed: number;
  hintsTotal: number;
  progressByCategory: { [category: string]: number };

  constructor(data?) {
    super(data);

    if (data.hasOwnProperty('endTime')) {
      this.endTime = new Date(data.endTime);
    }
    if (data.hasOwnProperty('leader')) {
      this.leader = new ExerciseStatusLeader(data.leader);
    }
  }
}

export class ExerciseStatusLeader extends BaseModel {
  name: string;
  score: number;
  tasksSolved: number;

  constructor(data?) {
    super(data);
  }
}
