import { Injectable } from '@angular/core';

@Injectable()
export class LocaleService {
  constructor() {}

  getLocale(): string {
    // Only support en-US for now - any additional locales need to be separately imported and registered:
    // https://angular.io/guide/i18n#i18n-pipes
    return 'en-US';
  }
}
