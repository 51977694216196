import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ScoringLogItem, ScoringLogSpecialMessageDetailsDTO } from '../../../../models';
import { ScoringLogService } from '../../../../services';

@Component({
  selector: 'isa-scoring-log-details-dialog',
  templateUrl: './scoring-log-details-dialog.component.html',
  styleUrls: ['./scoring-log-details-dialog.component.scss'],
})
export class ScoringLogDetailsDialogComponent implements OnInit {
  exerciseId: string;
  scoringLogItem: ScoringLogItem;
  specialMessageDetails: ScoringLogSpecialMessageDetailsDTO;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private scoringLogService: ScoringLogService
  ) {}

  ngOnInit() {
    this.exerciseId = this.data['exerciseId'];
    this.scoringLogItem = this.data['scoringLogItem'];
    if (
      this.exerciseId &&
      this.scoringLogItem &&
      this.scoringLogItem.teamId &&
      this.scoringLogItem.id
    ) {
      this.scoringLogService
        .getSpecialScoringMessageDetails(
          this.exerciseId,
          this.scoringLogItem.teamId,
          this.scoringLogItem.id
        )
        .subscribe((result) => (this.specialMessageDetails = result));
    }
  }
}
