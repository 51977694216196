import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ReportDetails,
  ReportFilter,
  ReportAIAssessment,
  SituationReportConfirmation,
  SituationReportFilterResult,
  SituationReportsData,
} from '../../models';
import { ServiceUtil } from '../../shared';
import { ReportsService } from './reports.service';

@Injectable()
export class SituationReportsService implements ReportsService<SituationReportsData> {
  constructor(private http: HttpClient) {}

  getData(exerciseId: string, filter: ReportFilter): Observable<SituationReportFilterResult> {
    return this.http
      .get<SituationReportFilterResult>(`api/widget/situation-reports/exercise/${exerciseId}`, {
        params: ServiceUtil.createHttpParamsFromObj(filter),
      })
      .pipe(map((data) => new SituationReportFilterResult(data)));
  }

  getReportDetails(
    exerciseId: string,
    teamId: string,
    reportId: string
  ): Observable<ReportDetails> {
    return this.http
      .get<ReportDetails>(
        `api/widget/situation-reports/exercise/${exerciseId}/team/${teamId}/report/${reportId}/details`
      )
      .pipe(map((data) => new ReportDetails(data)));
  }

  createSituationReportConfirmation(
    situationReportConfirmation: SituationReportConfirmation
  ): Observable<boolean> {
    const exerciseId = situationReportConfirmation.exerciseId;
    return this.http
      .post(
        `api/widget/situation-reports/exercise/${exerciseId}/confirmation`,
        situationReportConfirmation
      )
      .pipe(map(() => true));
  }

  createMultipleSituationReportAIAssessments(
    exerciseId: string,
    assessments: ReportAIAssessment[]
  ): Observable<boolean> {
    return this.http
      .post(`api/widget/situation-reports/exercise/${exerciseId}/ai-assessment`, assessments)
      .pipe(map(() => true));
  }
}
