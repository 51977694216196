import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationsService } from '@cybexer/ngx-commons';
import { UsersService } from '../../../../services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'isa-user-delete-dialog',
  templateUrl: './user-delete-dialog.component.html',
  styleUrls: ['./user-delete-dialog.component.scss'],
})
export class UserDeleteDialogComponent {
  username: string;
  isAdmin: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private usersService: UsersService,
    private notificationsService: NotificationsService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<UserDeleteDialogComponent>
  ) {
    this.username = this.data.username;
    this.isAdmin = false; // TODO: Will be fixed in ISA-2722
  }

  confirmDeleteUser() {
    this.usersService.removeUser(this.username).subscribe({
      next: () => {
        this.notificationsService.success('ui.users.userDeleted');
        this.dialogRef.close(true);
      },
      error: () => {
        this.notificationsService.error('ui.users.deleteUserError');
        this.dialogRef.close(true);
      },
    });
  }

  getDeleteConfirmMessage() {
    if (this.isAdmin) {
      return `${this.translate.instant('ui.users.deleteUserWarning')}\n
      ${this.translate.instant('ui.users.deleteUserConfirmation')} ${this.username} ?`;
    }
    return `${this.translate.instant('ui.users.deleteUserConfirmation')} ${this.username} ?`;
  }
}
