import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TeamScoringData } from '../../models';
import { SoundService } from '../shared/sound.service';

@Injectable()
export class TeamScoringService {
  constructor(
    private http: HttpClient,
    private soundService: SoundService
  ) {}

  getScoringData(exerciseId: string, timestamp: string): Observable<TeamScoringData[]> {
    let params = new HttpParams();
    if (timestamp) {
      params = params.set('timestamp', timestamp);
    }
    return this.http
      .get<TeamScoringData[]>(`api/widget/team-scoring/exercise/${exerciseId}`, { params: params })
      .pipe(
        map((dataArray) => {
          return dataArray.map((data) => new TeamScoringData(data));
        })
      );
  }

  getSoundDownloadUrl(exerciseId: String, soundId?: String): Observable<string> {
    return this.soundService.getSoundDownloadUrl(
      `api/widget/team-scoring/exercise/${exerciseId}/sounds`,
      soundId
    );
  }
}
