<div class="cybexer-table-wrapper">
  <table mat-table matSort [dataSource]="usersDatasource" class="isa-table">
    @if (multiUserActions) {
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
    }

    <ng-container matColumnDef="username">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'ui.users.username' | translate }}
      </th>
      <td mat-cell *matCellDef="let user">{{ user.username }}</td>
    </ng-container>

    <ng-container matColumnDef="fullName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'ui.users.fullName' | translate }}
      </th>
      <td mat-cell *matCellDef="let user">{{ user.fullName }}</td>
    </ng-container>

    @if (multiUserActions || singleUserActions) {
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
          <ng-container
            *ngTemplateOutlet="multiUserActions; context: { users: selection.selected }"
          >
          </ng-container>
        </th>
        <td mat-cell *matCellDef="let user">
          <ng-container
            *ngTemplateOutlet="singleUserActions; context: { user: user }"
          ></ng-container>
        </td>
      </ng-container>
    }

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let user; columns: displayedColumns"></tr>
  </table>

  @if (!usersDatasource.data.length && !isLoadingResults) {
    <cybexer-no-data></cybexer-no-data>
  }

  @if (isLoadingResults) {
    <cybexer-progress-spinner></cybexer-progress-spinner>
  }

  <mat-paginator [pageSizeOptions]="pageSizes"></mat-paginator>
</div>
