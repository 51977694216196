import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationsService } from '@cybexer/ngx-commons';
import {
  AttackCampaignDataObjective,
  AttackCampaignDataObjectiveTeam,
  UserAssignment,
} from '../../../../models';
import { AttackCampaignService } from '../../../../services';

@UntilDestroy()
@Component({
  selector: 'isa-user-assignment-dialog',
  templateUrl: './user-assignment-dialog.component.html',
  styleUrls: ['./user-assignment-dialog.component.scss'],
})
export class UserAssignmentDialogComponent implements OnInit {
  assignForm: UntypedFormGroup;
  submitted: boolean;
  team: AttackCampaignDataObjectiveTeam;
  objective: AttackCampaignDataObjective;
  exerciseId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<UserAssignmentDialogComponent, AssignmentResult>,
    private attackCampaignService: AttackCampaignService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit() {
    this.team = this.data['team'];
    this.objective = this.data['objective'];
    this.exerciseId = this.data['exerciseId'];
    this.assignForm = new UntypedFormGroup({});
  }

  onSubmit(form: UntypedFormGroup): void {
    this.submitted = true;
    if (form.valid) {
      const assignUserToObjective: UserAssignment = new UserAssignment({
        exerciseId: this.exerciseId,
        teamId: this.team.teamId,
        objectiveContainerId: this.team.objectiveContainerId,
        objectiveId: this.team.objectiveId,
      });
      this.attackCampaignService
        .createUserAssignment(assignUserToObjective)
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          assignUserToObjective.id = res.assignmentId;
          this.notificationsService.success('ui.exercise.reassigned');
          this.dialogRef.close({ assignment: assignUserToObjective });
        });
    }
  }
}

export interface AssignmentResult {
  assignment: UserAssignment;
}
