import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable()
export class PatternMatcher {
  static match(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const val = control.value;
      if (!val) {
        return null;
      }
      return regex.test(val) ? null : error;
    };
  }
}
