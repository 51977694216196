import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatestWith, Observable } from 'rxjs';
import { filter, finalize, first, tap } from 'rxjs/operators';
import { Exercise, ReportingStatus, ReportingStatusTeam } from '../../../models';
import {
  AuthenticationService,
  ExerciseService,
  IntervalService,
  PreferenceService,
  ReportingStatusService,
} from '../../../services';
import { FilterStateModel, FilterStateService, ROLES } from '../../../shared';
import { ReportingStatusIncidentReportsDialogComponent } from './reporting-status-incident-reports-dialog/reporting-status-incident-reports-dialog.component';

@UntilDestroy()
@Component({
  selector: 'isa-reporting-status',
  templateUrl: './reporting-status.component.html',
  styleUrls: ['./reporting-status.component.scss'],
})
export class ReportingStatusComponent implements OnInit {
  filter$: Observable<Partial<FilterStateModel>>;

  exercise: Exercise;
  reportingStatuses: ReportingStatus[];
  filterText: UntypedFormControl;
  reportingStatusIncidentReportRef: MatDialogRef<ReportingStatusIncidentReportsDialogComponent>;
  loaded: boolean;
  isLightTheme: boolean;
  isRedTeam = true;

  constructor(
    private dialog: MatDialog,
    private reportingStatusService: ReportingStatusService,
    private exerciseService: ExerciseService,
    private intervalService: IntervalService,
    private preferenceService: PreferenceService,
    private authenticationService: AuthenticationService,
    public filterStateService: FilterStateService
  ) {}

  ngOnInit() {
    this.filter$ = this.filterStateService.filter$('campaignPhase');
    this.filterText = new UntypedFormControl('', Validators.maxLength(100));
    this.exerciseService.activeExercise
      .pipe(
        tap((exercise: Exercise) => {
          this.exercise = exercise;
          this.reportingStatuses = [];
        }),
        filter((exercise: Exercise) => !!exercise),
        tap((exercise: Exercise) => {
          this.isRedTeam = this.authenticationService.getRole(exercise.id) === ROLES.RED;

          if (exercise.campaignPhases && exercise.campaignPhases.length) {
            this.filterStateService.setFilterIfEmptyOrDefault(
              'campaignPhase',
              exercise.campaignPhases[0].id
            );
          }
        }),
        combineLatestWith(this.intervalService.getWidgetRefreshInterval()),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.processData();
      });

    this.filter$.pipe(untilDestroyed(this)).subscribe(() => {
      this.processData();
    });

    this.preferenceService
      .getPreferences()
      .pipe(first())
      .subscribe((pref) => (this.isLightTheme = pref.isLightTheme));
  }

  processData() {
    const { campaignPhase } = this.filterStateService.snapshot();

    if (!this.exercise || !campaignPhase) return;

    this.loaded = false;
    const teamsCount = this.exercise.blueTeams.length;
    this.reportingStatusService
      .getReportingStatuses(this.exercise.id, campaignPhase)
      .pipe(
        finalize(() => (this.loaded = true)),
        untilDestroyed(this)
      )
      .subscribe((res) => {
        this.reportingStatuses = res.map((campaignPhase) => {
          campaignPhase.objectives.map((objective) => {
            objective.teams.length = teamsCount;
            return objective;
          });
          return campaignPhase;
        });
      });
  }

  openReportingStatusIncidentReportDialog(team: ReportingStatusTeam): void {
    if (team.incidentReportCount === 0 || this.isRedTeam) return;
    this.reportingStatusIncidentReportRef = this.dialog.open(
      ReportingStatusIncidentReportsDialogComponent,
      {
        disableClose: false,
        data: {
          exercise: this.exercise,
          objectiveId: team.objectiveId,
        },
      }
    );

    this.reportingStatusIncidentReportRef.afterClosed().subscribe(() => {
      this.reportingStatusIncidentReportRef = null;
    });
  }
}
