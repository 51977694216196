import { BaseModel } from './base.model';

export class FileInfo extends BaseModel {
  id?: string;
  name: string;
  size: number;

  constructor(data?) {
    super(data);
  }
}

export class SoundInfo extends FileInfo {
  playing = false;

  constructor(data?) {
    super(data);
  }

  isDefault(): boolean {
    return this.id == null;
  }
}

export class MediaInfo extends FileInfo {
  fileType: FileType;
  thumbnailObjectId: string;

  constructor(data?) {
    super(data);
  }
}

export class FileMetaInfo extends FileInfo {
  isFolder: boolean;
  parentId: string;
  path: string;
  fileType: FileType;

  constructor(data?) {
    super(data);
  }
}

export enum FileType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  OTHER = 'OTHER',
}
