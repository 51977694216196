import { BaseModel } from '../shared/base.model';
import { ReportData, ReportFilterResult } from './report-data.model';

export class SituationReportsData extends BaseModel implements ReportData {
  reportId: string;
  teamId: string;
  teamName: string;
  status: string;
  grade?: number;
  timestamp: Date;
  blueTeamMember: string;
  whiteTeamMember: string;
  feedback: string;
  aiFeedback?: string;
  aiGrade?: number;
  aiAssessmentStatus?: string;
  checked: boolean = false;
}

export class SituationReportConfirmation extends BaseModel {
  reportId: string;
  exerciseId: string;
  grade: number;
  feedback: string;
}

export class ReportAIAssessment extends BaseModel {
  reportId: string;
  teamId: string;
  promptOverrideId?: string;
}

export class SituationReportFilterResult extends ReportFilterResult<SituationReportsData> {
  constructor(data) {
    super(data, SituationReportsData);
  }
}
