import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isaFileSize',
})
export class FileSizePipe implements PipeTransform {
  private units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

  transform(value: number, precision: number = 1) {
    if (value === 0) {
      return '0 bytes';
    }

    const number = Math.floor(Math.log(value) / Math.log(1000));
    return (
      (value / Math.pow(1000, Math.floor(number))).toFixed(precision) + ' ' + this.units[number]
    );
  }
}
