import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { CombinedObserverSettings, ObserverViewType } from '../models/shared/settings.model';
import { AuthenticationService, SettingsService } from '../services';

/**
 * Check whether the current route is an observer route and
 * perform automatic login to observer user in that case.
 */
@Injectable({
  providedIn: 'root',
})
export class ObserverAuthGuard {
  constructor(
    private settingsService: SettingsService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const lastChild = this.getLastChild(next);
    if (!lastChild.data.isObserverRoute) {
      return true;
    }
    return this.settingsService.getObserverSettings().pipe(
      switchMap((observerSettings: CombinedObserverSettings) => {
        if (!observerSettings.isEnabled) {
          return throwError(() => Error('Observer mode is disabled'));
        } else if (!this.isViewEnabled(observerSettings.views, lastChild.data.type)) {
          return throwError(() => Error('Incorrect url'));
        }

        return this.authenticationService.getCurrentUser(true);
      }),
      switchMap(
        (loggedInUser) =>
          !loggedInUser || !loggedInUser.username
            ? this.authenticationService.loginAsObserver()
            : of(true) // User already logged in => allow access
      ),
      switchMap((isLoggedIn) =>
        !isLoggedIn ? throwError(() => Error('Failed to log in as observer')) : of(true)
      ),
      catchError(() => of(this.router.parseUrl('/')))
    );
  }

  getLastChild(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (!route.firstChild) {
      return route;
    }
    return this.getLastChild(route.firstChild);
  }

  private isViewEnabled(enabledViews: ObserverViewType[], type?: ObserverViewType) {
    if (type == null || !enabledViews || enabledViews.length === 0) {
      return false;
    }

    return enabledViews.indexOf(type) !== -1;
  }
}
