<div class="observer-key-dialog">
  <mat-dialog-content>
    <mat-form-field class="full-width">
      <mat-label>Enter observer key</mat-label>
      <input required autocomplete="off" type="text" matInput [formControl]="observerKeyCtrl" />
      @if (observerKeyCtrl.errors?.invalidKey) {
        <mat-error>{{ observerKeyCtrl.errors?.invalidKey }}</mat-error>
      }
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div>
      <button (click)="submitKey()" mat-raised-button color="primary">Submit</button>
      <button mat-button matDialogClose>Cancel</button>
    </div>
  </mat-dialog-actions>
</div>
