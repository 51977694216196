<form [formGroup]="assignForm" (ngSubmit)="onSubmit(assignForm)">
  <h2 matDialogTitle>{{ 'ui.attackCampaign.reassignObjective' | translate }}</h2>
  <mat-dialog-content>
    <div class="isa-details-box">
      <div class="row">
        <label class="label" for="objective-name">{{
          'ui.attackCampaign.objective' | translate
        }}</label>
        <div id="objective-name" class="disable-uppercase">{{ objective.name }}</div>
      </div>
      <div class="row">
        <label class="label" for="team-name">{{ 'ui.team' | translate }}</label>
        <div id="team-name" class="disable-uppercase">{{ team.teamId | isaTeamName }}</div>
      </div>
      <div class="row">
        <label class="label" for="assignee">{{ 'ui.attackCampaign.assignee' | translate }}</label>
        <div id="assignee" class="disable-uppercase">{{ team.objectiveUserName }}</div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div>
      <button mat-raised-button color="primary" type="submit">
        {{ 'ui.attackCampaign.reassign' | translate }}
      </button>
      <button mat-button matDialogClose>{{ 'ui.cancel' | translate }}</button>
    </div>
  </mat-dialog-actions>
</form>
