import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AppErrorHandler } from '../app.error-handler';
import { AccessTokenUrlValidator, User } from '../models';
import { AuthenticationService, PreferenceService } from '../services';
import { EXERCISE_PERMISSIONS } from '../shared';

@Injectable({
  providedIn: 'root',
})
export class AccessTokenAuthGuard {
  private static LOGIN_PAGE = '/intro/login';
  private static DEFAULT_ERROR_MSG = 'COULD_NOT_ACCESS_EXERCISE';

  constructor(
    private authenticationService: AuthenticationService,
    private preferencesService: PreferenceService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const accessToken = AccessTokenUrlValidator.getAccessToken(state.url);
    if (accessToken == null) {
      return true;
    }

    return this.authenticationService.isUserLoggedIn().pipe(
      switchMap((isLoggedIn) => (isLoggedIn ? this.authenticationService.logout() : of(true))),
      switchMap(() => this.authenticationService.loginWithAccessToken(accessToken)),
      switchMap((loggedInUser: User) =>
        !loggedInUser || !loggedInUser.username
          ? throwError(() => Error('Failed to log in with access token'))
          : this.preferencesService.getPreferences(true).pipe(map(() => true))
      ),
      tap(() => {
        this.authenticationService
          .hasPermission(EXERCISE_PERMISSIONS.WAITING_ROOM, false)
          .subscribe((hasWaitingRoomPermission) => {
            const redirectUrl = hasWaitingRoomPermission
              ? `/app/gamenet/waiting-room`
              : state.url.split('?')[0];

            return this.router.navigate([redirectUrl], { queryParams: { sideNavHidden: true } });
          });
      }),
      catchError((error: unknown) => this.navigateOnError(state.url, error))
    );
  }

  private navigateOnError(url: string, error: any): Observable<boolean> {
    const redirectUrl = AccessTokenUrlValidator.getRedirectUrl(url);
    if (redirectUrl == null) {
      return this.navigateToLoginPage(url);
    }

    return this.navigateToExternalUrl(redirectUrl, error);
  }

  navigateToExternalUrl(redirectUrl: string, error: any): Observable<boolean> {
    const isaErrorMessage =
      error instanceof HttpErrorResponse ? AppErrorHandler.getIsaError(error) : null;
    let errorMsg = AccessTokenAuthGuard.DEFAULT_ERROR_MSG;
    if (isaErrorMessage != null) {
      errorMsg = isaErrorMessage.type;
    }
    this.redirectToExternalUrl(`${redirectUrl}?error=${errorMsg}`);
    return of(true);
  }

  redirectToExternalUrl(url: string) {
    window.location.href = url;
  }

  private navigateToLoginPage(urlWithAccessToken: string): Observable<boolean> {
    return this.navigateToPage(AccessTokenAuthGuard.LOGIN_PAGE, {
      fwd: urlWithAccessToken.split('?')[0],
    });
  }

  private navigateToPage(page: string, queryParams: any): Observable<boolean> {
    return from(this.router.navigate([page], { queryParams: queryParams }));
  }
}
