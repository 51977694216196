import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  IntegrationEventSubjectType,
  IntegrationEventType,
} from 'app/models/shared/integration-event.model';
import { ExerciseOverview, ExerciseType } from '../../../../models/gamenet/exercise.model';

@Component({
  selector: 'isa-exercise-integrations-dialog',
  templateUrl: './exercise-integrations-dialog.component.html',
  styleUrls: ['./exercise-integrations-dialog.component.scss'],
})
export class ExerciseIntegrationsDialogComponent implements OnInit {
  exercise: ExerciseOverview;
  ExerciseType = ExerciseType;
  readonly IntegrationEventType = IntegrationEventType;
  readonly IntegrationEventSubjectType = IntegrationEventSubjectType;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.exercise = this.data['exercise'];
  }
}
