import { BaseModel } from '../shared/base.model';

export class SpecialScoring extends BaseModel {
  teamId: string;
  username: string;
  score: number;
  timestamp: Date;
  description?: string;

  constructor(data?) {
    super(data);
  }
}
