import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Attack, Exercise, TargetCheck } from '../../../../models';
import { CampaignLiveService } from '../../../../services';
import { ExerciseUtil } from '../../../../shared';

@UntilDestroy()
@Component({
  selector: 'isa-attack-widget',
  templateUrl: './attack-widget.component.html',
  styleUrls: ['./attack-widget.component.scss'],
})
export class AttackWidgetComponent implements OnChanges {
  dataLoaded: boolean;
  attacks: Attack[] = [];
  targetChecks: TargetCheck[] = [];
  @Input() exercise: Exercise;
  @Input() teamId: string;
  @Input() timestamp: string;

  constructor(
    private campaignLiveService: CampaignLiveService,
    private exerciseUtil: ExerciseUtil
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.exercise) {
      this.clearCurrentData();
    } else if (changes.teamId || changes.timestamp) {
      this.initializeData();
    }
  }

  private initializeData() {
    this.targetChecks = this.exerciseUtil.getTargetChecksForExercise(this.exercise);
    this.getRedAttacks();
  }

  getRedAttacks(): void {
    if (this.exercise && this.teamId && this.timestamp) {
      this.campaignLiveService
        .getAttacks(this.exercise.id, this.teamId, this.timestamp)
        .pipe(untilDestroyed(this))
        .subscribe((redAttacks) => {
          this.attacks = redAttacks.slice(0, 5);
          this.dataLoaded = true;
        });
    }
  }

  private clearCurrentData() {
    this.dataLoaded = false;
    this.attacks = [];
  }
}
