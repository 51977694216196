import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxCommonsModule } from '@cybexer/ngx-commons';
import { MatDatetimepickerModule } from '@mat-datetimepicker/core';
import { MatMomentDatetimeModule } from '@mat-datetimepicker/moment';
import { QuillModule } from 'ngx-quill';
import { IsaPipesModule } from '../../pipes';
import { SharedModule } from '../../views/gamenet/shared/shared.module';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { FooterComponent } from './footer/footer.component';
import { FilteredSelectComponent } from './filtered-select/filtered-select.component';
import { IntegrationEventFormComponent } from './integrations/integration-event-form/integration-event-form.component';
import { IntegrationEventListComponent } from './integrations/integration-event-list/integration-event-list.component';
import { TargetStatusChangeEventFormComponent } from './integrations/target-status-change-event-form/target-status-change-event-form.component';
import { ZeroConfigurationEventFormComponent } from './integrations/zero-configuration-event-form/zero-configuration-event-form.component';
import { PasswordFieldsComponent } from './password-fields/password-fields.component';
import { UserSettingsComponent } from './user-settings/user-settings.component';
import { WarningIconComponent } from './warning-icon/warning-icon.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    MatTooltipModule,
    MatButtonModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatProgressBarModule,
    MatSelectModule,
    MatListModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatDialogModule,
    ReactiveFormsModule,
    IsaPipesModule,
    FormsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSliderModule,
    MatBadgeModule,
    QuillModule,
    MatChipsModule,
    MatButtonToggleModule,
    MatGridListModule,
    SharedModule,
    NgxCommonsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDatetimepickerModule,
    MatMomentDatetimeModule,
    MatIconModule,
    TranslateModule,
  ],
  declarations: [
    DatePickerComponent,
    FooterComponent,
    WarningIconComponent,
    PasswordFieldsComponent,
    UserSettingsComponent,
    FilteredSelectComponent,
    IntegrationEventListComponent,
    IntegrationEventFormComponent,
    TargetStatusChangeEventFormComponent,
    ZeroConfigurationEventFormComponent,
    ConfirmDialogComponent,
  ],
  exports: [
    DatePickerComponent,
    FooterComponent,
    WarningIconComponent,
    PasswordFieldsComponent,
    FilteredSelectComponent,
    IntegrationEventListComponent,
    IntegrationEventFormComponent,
    TargetStatusChangeEventFormComponent,
    ZeroConfigurationEventFormComponent,
    ConfirmDialogComponent,
  ],
})
export class SharedComponentsModule {}
