import { Pipe, PipeTransform } from '@angular/core';
import { Exercise } from '../../models';
import { ExerciseUtil } from '../../shared';

@Pipe({
  name: 'isaObjectiveCampaignPhase',
})
export class ObjectiveCampaignPhasePipe implements PipeTransform {
  constructor(private exerciseUtil: ExerciseUtil) {}

  transform(value: string, exercise: Exercise) {
    const objective = this.exerciseUtil.getObjectiveById(exercise, value);
    let campaignPhaseName = '';
    if (objective) {
      // prettier-ignore
      campaignPhaseName = exercise.campaignPhases.find(cp => cp.id === objective.campaignPhaseId).name;
    }
    return campaignPhaseName;
  }
}
