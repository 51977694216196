import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { Exercise, ReportingStatusIncidentReport } from '../../../../models';
import { ReportingStatusService } from '../../../../services';

@Component({
  selector: 'isa-incident-report-confirm-dialog',
  templateUrl: './reporting-status-incident-reports-dialog.component.html',
  styleUrls: ['./reporting-status-incident-reports-dialog.component.scss'],
})
export class ReportingStatusIncidentReportsDialogComponent implements OnInit {
  reportingStatusIncidentReports: ReportingStatusIncidentReport[];
  exercise: Exercise;
  objectiveId: string;
  loaded: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private reportingStatusService: ReportingStatusService
  ) {}

  ngOnInit(): void {
    this.exercise = this.data['exercise'];
    this.objectiveId = this.data['objectiveId'];
    this.reportingStatusService
      .getReportingStatusIncidentReports(this.exercise.id, this.objectiveId)
      .pipe(finalize(() => (this.loaded = true)))
      .subscribe((res) => (this.reportingStatusIncidentReports = res));
  }
}
