<div class="upload-sound-dialog">
  <h2 matDialogTitle>{{ 'ui.settings.uploadAudio' | translate }}</h2>
  @if (!loading) {
    <div>
      <mat-dialog-content>
        <form>
          <label class="sound-label" for="soundInput">
            <div class="attachment-btn"><i class="fas fa-paperclip" aria-hidden="true"></i></div>
            @if (sound && error == null) {
              <i class="fas fa-check approved-icon" aria-hidden="true"></i>
            }
            @if (sound == null) {
              <span>{{ 'ui.settings.chooseFile' | translate }}</span>
            }
            @if (sound) {
              <span>{{ sound.name }} ({{ sound.size | isaFileSize }})</span>
            }
          </label>
          <input
            (change)="setFile($event)"
            class="soundInput"
            id="soundInput"
            type="file"
            accept=".wav,.mp3"
          />
          @if (error) {
            <div class="row error-container">
              <mat-error class="disable-uppercase">{{ error }}</mat-error>
            </div>
          }
          <div class="restriction-info">
            <div>{{ 'ui.settings.maxSize' | translate }} {{ maxFileSize | isaFileSize: 0 }}</div>
            <div>
              {{ 'ui.settings.supportedType' | translate }}
              <span class="disable-uppercase">{{ 'ui.settings.audioTypes' | translate }}</span>
            </div>
          </div>
        </form>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button
          mat-raised-button
          color="primary"
          (click)="uploadSound()"
          [disabled]="!sound || error"
        >
          <i class="fas fa-upload" aria-hidden="true"></i> {{ 'ui.settings.upload' | translate }}
        </button>
        <button mat-button matDialogClose>{{ 'ui.settings.cancel' | translate }}</button>
      </mat-dialog-actions>
    </div>
  }
  @if (loading) {
    <cybexer-progress-spinner></cybexer-progress-spinner>
  }
</div>
