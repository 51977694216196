import QuillNamespace from 'quill';

const Quill: any = QuillNamespace;

export const Parchment = Quill.import('parchment');
const pixelLevels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

export class IndentAttributor extends (Parchment.Attributor.Style as {
  new (formatName: any, styleProperty: any, attributorOptions: any);
}) {
  add(node: HTMLElement, value: number): boolean {
    if (value === 0) {
      this.remove(node);
      return true;
    } else {
      return super.add(node, `${value}em`);
    }
  }
}

export const IndentStyle = new IndentAttributor('indent', 'text-indent', {
  scope: Parchment.Scope.BLOCK,
  whitelist: pixelLevels.map((value) => `${value}em`),
});
