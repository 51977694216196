import { PermissionData } from '../../shared';
import { BaseModel } from './base.model';
import { IsaModule } from './isa-module.model';
import { ObserverViewType } from './settings.model';

export class Prerequisites {
  constructor(
    public modulesEnabled: IsaModule[] = [],
    public isObserver: boolean = false,
    public observableViewType: ObserverViewType = null,
    public isCampaignLive: boolean = false,
    public isTeamScoring: boolean = false,
    public fromExperimentalFeatures: boolean = false,
    public requireTeamAssessment: boolean = false
  ) {}
}

export class NavigationMenuLink extends BaseModel {
  name: string;
  individualAssessmentName?: string;
  hybridExerciseName?: string;
  uri: string;
  permissions: PermissionData[];
  prerequisites?: Prerequisites;
  isDefault = false;

  constructor(data?) {
    super(data);

    if (data.hasOwnProperty('isDefault')) {
      this.isDefault = data.isDefault;
    }
  }
}

export enum NavigationMenuGroupType {
  TASKS = 'TASKS',
  EMPTY = 'EMPTY',
  JUDGING = 'JUDGING',
  OVERVIEW = 'OVERVIEW',
  REPORTING = 'REPORTING',
  SCORING = 'SCORING',
  SITUATIONAL_AWARENESS = 'SITUATIONAL_AWARENESS',
}

export class NavigationMenuGroup extends BaseModel {
  type: NavigationMenuGroupType;
  links: NavigationMenuLink[];

  constructor(data?) {
    super(data);
  }
}
