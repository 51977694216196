<div class="chart">
  <canvas
    baseChart
    [datasets]="barChartData"
    [labels]="labels"
    [options]="barChartOptions"
    (chartClick)="!disableClick && chartClicked($event)"
    [style.height.px]="chartHeight"
    (window:resize)="onResize($event)"
  >
  </canvas>
</div>
