import { Pipe, PipeTransform } from '@angular/core';
import { NiceDictionary } from '../../models';
import { TranslateService } from '@ngx-translate/core';
import { NiceDictionaryJA } from '../../models/gamenet/ctf/ctf-nice-dictionary-JA';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Pipe({
  name: 'isaNiceDescription',
})
export class NiceDescriptionPipe implements PipeTransform {
  niceDictionary = this.setDictionary();

  constructor(private translate: TranslateService) {
    this.translate.onLangChange.pipe(untilDestroyed(this)).subscribe(() => {
      this.niceDictionary = this.setDictionary();
    });
  }

  private setDictionary() {
    const currentLang = this.translate.currentLang;
    switch (currentLang) {
      case 'JA':
        return NiceDictionaryJA;
      default:
        return NiceDictionary;
    }
  }

  transform(value: string, categoryName: string) {
    return this.niceDictionary.categories
      .find((category) => category.name === categoryName)
      .items.find((it) => it.id.toLowerCase() === value.toLowerCase()).value;
  }
}
