import { Injectable } from '@angular/core';
import { BlueTeam, Exercise, Objective, Target, TargetCheck, TargetGroup } from '../models';

@Injectable()
export class ExerciseUtil {
  getTargetsForExercise(exercise: Exercise, teamId: string = null): Target[] {
    if (exercise && exercise.blueTeams) {
      return exercise.blueTeams
        .filter((t) => !teamId || t.id === teamId)
        .reduce((total: Target[], item: BlueTeam) => {
          const targets: Target[] = item.targetGroups.reduce(
            (flattenedTargets: Target[], targetGroup: TargetGroup) => {
              return flattenedTargets.concat(targetGroup.targets);
            },
            []
          );
          return total.concat(targets);
        }, []);
    }
    return [];
  }

  getTargetGroupsForExercise(exercise: Exercise, teamId: string = null): TargetGroup[] {
    if (exercise && exercise.blueTeams) {
      return exercise.blueTeams
        .filter((team) => !teamId || team.id === teamId)
        .reduce((total: TargetGroup[], team: BlueTeam) => {
          return total.concat(team.targetGroups);
        }, []);
    }
    return [];
  }

  getTargetChecksForExercise(exercise: Exercise, teamId: string = null): TargetCheck[] {
    if (exercise && exercise.blueTeams) {
      return this.getTargetsForExercise(exercise, teamId).reduce(
        (total: TargetCheck[], item: Target) => {
          return total.concat(item.targetChecks);
        },
        []
      );
    }
    return [];
  }

  getObjectivesForExercise(exercise: Exercise, teamId: string = null): Objective[] {
    if (exercise && exercise.blueTeams) {
      const targetCheckObjectives = this.getTargetChecksForExercise(exercise, teamId).reduce(
        (total: Objective[], targetCheck: TargetCheck) => {
          return total.concat(targetCheck.objectives);
        },
        []
      );
      const targetGroupObjectives = this.getTargetGroupsForExercise(exercise, teamId).reduce(
        (total: Objective[], targetGroup: TargetGroup) => {
          return total.concat(targetGroup.objectives);
        },
        []
      );

      return targetCheckObjectives.concat(targetGroupObjectives);
    }
    return [];
  }

  getTargetCheckById(exercise: Exercise, targetCheckId: string): TargetCheck {
    return this.getTargetChecksForExercise(exercise).find(
      (targetCheck) => targetCheck.id === targetCheckId
    );
  }

  getTargetGroupById(exercise: Exercise, targetGroupId: string): TargetGroup {
    return this.getTargetGroupsForExercise(exercise).find(
      (targetGroup) => targetGroup.id === targetGroupId
    );
  }

  getTargetById(exercise: Exercise, targetId: string): Target {
    return this.getTargetsForExercise(exercise).find((target) => target.id === targetId);
  }

  getObjectiveById(exercise: Exercise, objectiveId: string): Objective {
    return this.getObjectivesForExercise(exercise).find(
      (objective) => objective.id === objectiveId
    );
  }

  getCTFTaskCategoryIcon(exercise: Exercise, taskCategory: string): string {
    if (!exercise || !exercise.taskCategories) return null;
    const categoryDetails = exercise.taskCategories.find((it) => it.code === taskCategory);
    return categoryDetails ? categoryDetails.icon : null;
  }

  getCTFTaskCategoryName(exercise: Exercise, taskCategory: string): string {
    if (!exercise || !exercise.taskCategories) return null;
    const categoryDetails = exercise.taskCategories.find((it) => it.code === taskCategory);
    return categoryDetails ? categoryDetails.name : null;
  }
}
