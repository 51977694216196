export class CTFTaskBulkEvent {
  constructor(
    public type: CTFTaskBulkEventType,
    public entityType: CTFTaskBulkEventEntityType,
    public teamId: string = null,
    public taskId: string = null,
    public category: string = null
  ) {}
}

export enum CTFTaskBulkEventType {
  LOCK = 'LOCK',
  UNLOCK = 'UNLOCK',
}

export enum CTFTaskBulkEventEntityType {
  TEAM = 'TEAM',
  CATEGORY = 'CATEGORY',
}
