import { TranslateService } from '@ngx-translate/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

@Injectable()
export class PaginatorI18n extends MatPaginatorIntl {
  constructor(private readonly translate: TranslateService) {
    super();
    this.initPaginator();
  }

  private initPaginator(): void {
    this.translate.onLangChange.subscribe(() => {
      this.updatePaginatorLabels();
    });
    this.updatePaginatorLabels();
  }

  private updatePaginatorLabels(): void {
    this.itemsPerPageLabel = this.translate.instant('ui.itemsPerPage');
    this.nextPageLabel = this.translate.instant('ui.nextPageLabel');
    this.previousPageLabel = this.translate.instant('ui.prevPageLabel');
    this.firstPageLabel = this.translate.instant('ui.firstPageLabel');
    this.lastPageLabel = this.translate.instant('ui.lastPageLabel');
    this.getRangeLabel = this.getRangeLabelFn();
    this.changes.next();
  }

  private getRangeLabelFn(): (page: number, pageSize: number, length: number) => string {
    return (page: number, pageSize: number, length: number) => {
      if (length === 0 || pageSize === 0) {
        return this.translate.instant('ui.rangeOf0', { length });
      }
      const startIndex = page * pageSize;
      const endIndex =
        startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return this.translate.instant('ui.rangeOf', { startIndex: startIndex + 1, endIndex, length });
    };
  }
}
