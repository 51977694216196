import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ConsoleAuthData,
  ConsoleConnectionParameters,
  GuacamoleManagerBaseService,
  GuacamoleTunnelType,
  TunnelParams,
} from '@cybexer/ngx-commons';
import * as Sentry from '@sentry/angular-ivy';
import { combineLatest, firstValueFrom, iif, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SettingsService } from '../shared/settings.service';
import { ExerciseService } from './exercise.service';
import { VirtualMachineService } from './virtual-machine.service';

@Injectable({
  providedIn: 'root',
})
export class GuacamoleManagerService extends GuacamoleManagerBaseService {
  constructor(
    private settingsService: SettingsService,
    private virtualMachineService: VirtualMachineService,
    private exerciseService: ExerciseService,
    protected router: Router
  ) {
    super(router);
  }

  getTunnelParams(): Observable<TunnelParams> {
    return combineLatest([
      this.exerciseService.getActiveExercise(),
      this.settingsService.getSettings(true),
    ]).pipe(
      switchMap(([exercise, { vlmSettings }]) =>
        combineLatest([
          of(vlmSettings),
          iif(
            () => vlmSettings.guacamoleTunnelType == GuacamoleTunnelType.HTTP,
            this.virtualMachineService.getGuacamoleHttpTunnelUrl(exercise.id),
            this.virtualMachineService.getGuacamoleWebsocketTunnelUrl(exercise.id)
          ),
        ])
      ),
      switchMap(([vlmSettings, url]) => {
        const tunnel = new TunnelParams({
          url: url,
          type: vlmSettings.guacamoleTunnelType,
          crossDomain:
            vlmSettings.guacamoleTunnelType === GuacamoleTunnelType.HTTP
              ? !vlmSettings.guacamoleTunnelProxyEnabled
              : undefined,
        });
        return of(tunnel);
      })
    );
  }

  getConnectionId(consoleAuthData: ConsoleAuthData): Promise<string> {
    return firstValueFrom(
      this.exerciseService
        .getActiveExercise()
        .pipe(
          switchMap((exercise) =>
            this.virtualMachineService.getGuacamoleConnectionId(exercise.id, consoleAuthData)
          )
        )
    );
  }

  createNewWindowUrl(connectionParams: ConsoleConnectionParameters): string {
    return this.router.serializeUrl(
      this.router.createUrlTree(['app/gamenet/gconsole'], {
        queryParams: {
          sideNavHidden: true,
          headerHidden: true,
          footerHidden: true,
          id: connectionParams.id,
          name: connectionParams.name,
          connectionType: connectionParams.connectionType,
          source: connectionParams.source,
        },
      })
    );
  }

  notifySentry(error: any) {
    try {
      Sentry.setTag('id', error.connectionId);
      Sentry.setTag('name', error.connectionName);
      Sentry.setTag('protocol', error.connectionType);
      Sentry.captureException(Error(error.errorMsg));
    } catch (e) {
      console.error(e);
    }
  }

  getAdditionalUrlParams(): URLSearchParams {
    return new URLSearchParams();
  }
}
