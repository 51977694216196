import { BaseModel } from '../shared/base.model';

export class TeamRanking extends BaseModel {
  teamId: string;
  teamName: string;
  score: number;
  position?: number;

  constructor(data?) {
    super(data);
  }
}
