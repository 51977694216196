import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'isa-filtered-select',
  templateUrl: 'filtered-select.component.html',
  styleUrls: ['filtered-select.component.scss'],
})
export class FilteredSelectComponent implements OnInit, OnChanges {
  @Input() label: string;
  @Input() options: any[] = [];
  @Input() preselectedOptions: any = [];
  @Input() optionKey?: string;
  @Input() optionValue?: string;
  @Input() defaultOptionKey?: string;
  @Input() defaultOptionValue?: any = null;
  @Input() selectedAppearFirst: boolean = true;
  @Input() disabled: boolean = false;
  @Input() multiple: boolean = false;
  @Input() required: boolean = false;
  @Output() selectionChange = new EventEmitter<any>();

  @ViewChild('searchInput') input!: ElementRef<HTMLInputElement>;
  @ViewChild('matSelect') matSelect!: MatSelect;
  selectControl: FormControl = new FormControl();
  searchControl: FormControl = new FormControl();
  selectedOptions: any[] = [];
  filteredOptions: any[] = [];
  defaultOption?: any;

  constructor() {}

  @Input() set preselectedOption(option: any) {
    this.preselectedOptions = [option];
  }

  ngOnInit(): void {
    if (this.disabled) this.selectControl.disable();
    if (this.defaultOptionKey) {
      this.defaultOption = {
        [this.optionKey]: this.defaultOptionKey,
        [this.optionValue]: this.defaultOptionValue,
      };

      if (!this.selectedOptions.length) {
        this.selectedOptions = [this.defaultOption];
        this.multiple
          ? this.selectControl.setValue([this.defaultOption])
          : this.selectControl.setValue(this.defaultOption);
      }
    }
    this.filteredOptions = this.options.slice();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.disabled && this.disabled) this.selectControl.disable();

    const selectedOptions = changes.preselectedOptions?.currentValue || [];
    const selectedOption = changes.preselectedOption?.currentValue;
    if (selectedOption) selectedOptions.push(selectedOption);

    if (
      this.selectedOptions.length === selectedOptions.length &&
      !this.selectedOptions.find(
        (option) => !selectedOptions.includes(this.optionValue ? option[this.optionValue] : option)
      )
    ) {
      return;
    }
    if (selectedOptions.length) {
      this.optionValue
        ? (this.selectedOptions = this.options.filter((option) =>
            selectedOptions.includes(option[this.optionValue])
          ))
        : (this.selectedOptions = selectedOptions);

      this.multiple
        ? this.selectControl.setValue(this.selectedOptions)
        : this.selectControl.setValue(this.selectedOptions[0]);

      if (this.selectedAppearFirst && this.selectedOptions.length) {
        this.options = this.sortSelectedOptionsFirst(this.options);
      }
    }
  }

  displayFn(option: any) {
    if (!option) return '';

    return (this.optionKey ? option[this.optionKey] : option) || '';
  }

  select(selection: any) {
    if (this.multiple) {
      this.selectedOptions = selection.slice();

      this.selectControl.setValue(this.selectedOptions);
      this.optionValue
        ? this.selectionChange.emit(this.selectedOptions.map((option) => option[this.optionValue]))
        : this.selectionChange.emit(this.selectedOptions);
    } else {
      this.selectedOptions = [selection];

      this.selectControl.setValue(selection);
      this.optionValue
        ? this.selectionChange.emit(selection[this.optionValue])
        : this.selectionChange.emit(selection);
    }
  }

  toggleAllFiltered() {
    if (!this.filteredOptions.length) return;

    if (this.isAllFilteredSelected()) {
      this.select(this.selectedOptions.filter((option) => !this.filteredOptions.includes(option)));
    } else {
      this.select(
        this.selectedOptions
          .filter((option) => !this.filteredOptions.includes(option))
          .concat(this.filteredOptions)
      );
    }
  }

  isAllFilteredSelected() {
    return !!(
      this.filteredOptions.length &&
      !this.filteredOptions.find((option) => !this.selectedOptions.includes(option))
    );
  }

  onDropdownChange(isOpened: boolean) {
    if (isOpened) {
      this.input?.nativeElement?.focus();
    }

    this.filteredOptions = this.options.slice();
    this.searchControl.setValue('');

    if (!isOpened && this.selectedAppearFirst && this.selectedOptions.length) {
      this.options = this.sortSelectedOptionsFirst(this.options);
    }
  }

  private sortSelectedOptionsFirst(result: any[]) {
    const selected = result
      .filter((o) => this.selectedOptions.includes(o))
      .sort((o1, o2) => this.displayFn(o1).localeCompare(this.displayFn(o2)));
    const notSelected = result
      .filter((o) => !this.selectedOptions.includes(o))
      .sort((o1, o2) => this.displayFn(o1).localeCompare(this.displayFn(o2)));
    return [...selected, ...notSelected];
  }

  filterOptions(search?: string) {
    this.filteredOptions = search
      ? this.options.filter((option) =>
          this.displayFn(option).toLowerCase().includes(search.toLowerCase())
        )
      : this.options.slice();

    this.makeFirstFilteredOptionActive();
  }

  private makeFirstFilteredOptionActive() {
    if (this.filteredOptions.length) {
      const firstFilteredOption = this.matSelect.options.find((option) => {
        return this.optionValue
          ? option.value[this.optionValue] === this.filteredOptions[0][this.optionValue]
          : option.value === this.filteredOptions[0];
      });

      this.matSelect._keyManager.setActiveItem(firstFilteredOption);
    }
  }
}
