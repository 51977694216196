import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormGroupDirective,
  NgForm,
  UntypedFormControl,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { PatternMatcher } from './PatternMatcher';

export enum PasswordErrors {
  NO_NUMBER = 'noNumber',
  NO_UPPERCASE = 'noUppercase',
  NO_LOWERCASE = 'noLowercase',
  NO_SPECIAL = 'noSpecial',
  INVALID_CONFIRM = 'invalidConfirm',
}

@Injectable()
export class CustomValidators {
  static passwordValidators = Validators.compose([
    Validators.required,
    PatternMatcher.match(/\d/, { [PasswordErrors.NO_NUMBER]: true }),
    PatternMatcher.match(/[A-Z]/, { [PasswordErrors.NO_UPPERCASE]: true }),
    PatternMatcher.match(/[a-z]/, { [PasswordErrors.NO_LOWERCASE]: true }),
    PatternMatcher.match(/[ !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/, {
      [PasswordErrors.NO_SPECIAL]: true,
    }),
    Validators.minLength(8),
  ]);

  integer(prms: { min?: number; max?: number }): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const val = control.value;

      if (isNaN(val) || (val && !/^-?\d*$/.test(val.toString()))) {
        return { integer: true };
      } else if (!isNaN(prms.min) && !isNaN(prms.max)) {
        return val < prms.min || val > prms.max ? { integer: true } : null;
      } else if (!isNaN(prms.min)) {
        return val < prms.min ? { integer: true } : null;
      } else if (!isNaN(prms.max)) {
        return val > prms.max ? { integer: true } : null;
      } else {
        return null;
      }
    };
  }

  static notBlank(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const isBlank = (control.value || '').trim().length === 0;
      return !isBlank ? null : { isBlank: true };
    };
  }

  static getPasswordErrorMsg(pswControl: UntypedFormControl) {
    if (pswControl.hasError('minlength') || pswControl.hasError('required')) {
      return 'Password must be at least 8 characters long';
    }
    if (pswControl.hasError(PasswordErrors.NO_NUMBER)) {
      return 'Password must contain at least 1 number';
    }
    if (pswControl.hasError(PasswordErrors.NO_UPPERCASE)) {
      return 'Password must contain at least 1 uppercase character';
    }
    if (pswControl.hasError(PasswordErrors.NO_LOWERCASE)) {
      return 'Password must contain at least 1 lowercase character';
    }
    if (pswControl.hasError(PasswordErrors.NO_SPECIAL)) {
      return 'Password must contain at least 1 special character';
    }
    return '';
  }

  static passwordsMatch(
    passwordControlName = 'password',
    confirmControlName = 'confirmPassword'
  ): ValidatorFn {
    return (formGroup: UntypedFormGroup): { [key: string]: any } => {
      const pass = formGroup.get(passwordControlName).value;
      const confirmPass = formGroup.get(confirmControlName).value;
      return pass === confirmPass ? null : { [PasswordErrors.INVALID_CONFIRM]: true };
    };
  }
}

export class PasswordErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(
      control &&
      control.parent &&
      control.parent.invalid &&
      control.parent.dirty
    );
    return invalidCtrl || invalidParent;
  }
}
