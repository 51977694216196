import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent } from './error-page';
import { BaseLayoutComponent, IntroLayoutComponent } from './layout';
import {
  AccessTokenAuthGuard,
  AuthGuard,
  GamenetModuleGuard,
  ObserverAuthGuard,
} from './resolvers';
import { UserSettingsComponent } from './shared/components/user-settings/user-settings.component';
import { LandingComponent, LoginComponent } from './views';
import { SmartCityComponent } from './views/gamenet/smart-city/smart-city.component';

const APP_ROUTES: Routes = [
  {
    path: 'intro',
    component: IntroLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
    ],
  },
  {
    path: 'app',
    component: BaseLayoutComponent,
    canActivate: [ObserverAuthGuard, AccessTokenAuthGuard, AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'landing',
        pathMatch: 'full',
      },
      {
        path: 'landing',
        component: LandingComponent,
      },
      {
        path: 'gamenet',
        canActivate: [GamenetModuleGuard],
        loadChildren: () =>
          import('./views/gamenet/gamenet.module').then((mod) => mod.GamenetModule),
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('./views/administration/administration.module').then(
            (mod) => mod.AdministrationModule
          ),
      },
      {
        path: 'user/settings',
        component: UserSettingsComponent,
      },
    ],
  },
  { path: 'error/:code', component: ErrorPageComponent },
  { path: 'smart-city', component: SmartCityComponent },
  { path: '**', redirectTo: 'intro/login' },
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
