import { Component, OnInit } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { CommitMeta } from '../../../models/shared/commit-meta.model';
import {
  AuthenticationService,
  PreferenceService,
  SettingsService,
  SystemService,
} from '../../../services';

@Component({
  selector: 'isa-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  version: string;
  buildCommitMeta: CommitMeta;
  isAdmin = false;
  customLabelSrc: string;
  isLightTheme = this.preferenceService.isLightThemeSignal;

  constructor(
    private systemService: SystemService,
    private settingsService: SettingsService,
    private authenticationService: AuthenticationService,
    private preferenceService: PreferenceService
  ) {}

  ngOnInit() {
    this.authenticationService.currentUser$
      .pipe(
        tap((user) => (this.isAdmin = user ? user.isAdmin : false)),
        switchMap(() =>
          forkJoin([
            this.systemService.getSystemVersion(),
            this.isAdmin ? this.systemService.getBuildCommitMeta() : of(null),
          ])
        )
      )
      .subscribe(([version, commitMeta]) => {
        this.version = version;
        this.buildCommitMeta = commitMeta;
      });

    this.settingsService.getCustomLabelUrl().subscribe((customLabelUrl) => {
      this.customLabelSrc = customLabelUrl;
    });
  }
}
