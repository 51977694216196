<div class="attacks">
  <mat-card>
    <div widgetHandle class="live-card-title">
      <i class="fas fa-bolt" aria-hidden="true"></i
      >{{ 'ui.widget.attack.redAttacksLive' | translate }}
    </div>
    @if ((attacks.length === 0 && dataLoaded) || !teamId) {
      <div class="no-data">{{ 'ui.noDataError' | translate }}</div>
    }
    @for (redAttack of attacks; track redAttack) {
      <div class="red-attack-row">
        <div class="timestamp">[{{ redAttack.timestamp | isaDate: 'mediumTime' }}]</div>
        <div class="nowrap">{{ 'ui.enums.' + redAttack.status | translate }}</div>
        <div class="truncate">{{ redAttack.objectiveContainerName }}</div>
      </div>
    }
  </mat-card>
</div>
