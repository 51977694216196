<div class="task-report-confirm-dialog">
  <h2 matDialogTitle>{{ 'ui.taskReports.taskReport' | translate }}</h2>
  @if (!loading) {
    <form [formGroup]="taskConfirmationReportForm">
      <mat-dialog-content class="task-report-confirm-dialog-content">
        <div class="isa-details-box">
          @if (aiFeaturesEnabled && taskReportsData.aiAssessmentStatus) {
            <mat-card class="ai-assessment-results">
              <mat-card-title>
                <mat-icon>android</mat-icon>
                <span
                  >{{ 'ui.reports.autoAssessStatus' | translate }}:
                  {{ taskReportsData.aiAssessmentStatus }}</span
                >
              </mat-card-title>
              @if (taskReportsData.aiScore != null) {
                <div>{{ 'ui.reports.aiGrade' | translate }}: {{ taskReportsData.aiScore }}</div>
              }
              @if (taskReportsData.aiFeedback) {
                <div>
                  <mat-label>{{ 'ui.reports.aiFeedback' | translate }}</mat-label>
                  <div class="report-content disable-uppercase">
                    {{ taskReportsData.aiFeedback }}
                  </div>
                </div>
              }
            </mat-card>
          }

          <div class="row score-percentage">
            <span class="label">{{ 'ui.taskReports.scoreColumn' | translate }}</span>
            @if (hasPermissionToConfirmOrDeny) {
              <mat-slider
                [disabled]="
                  !isExerciseRunning ||
                  taskReportsData.status !== CONFIRMATION_STATUS.PENDING_CONFIRMATION
                "
                #ngSlider
              >
                <input
                  matSliderThumb
                  [value]="scorePercentageControl.value"
                  (input)="
                    scorePercentageControl.setValue(
                      { source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value }.value
                    )
                  "
                  #ngSliderThumb="matSliderThumb"
                />
              </mat-slider>
            }
            <br />
            <span> {{ score }} / {{ taskReportsData.initialScore }} </span>
          </div>
          <div class="row">
            <span class="label">{{ 'ui.taskReports.taskReport' | translate }}</span>
            <quill-editor
              [ngModel]="taskReportDetails?.answer"
              [ngModelOptions]="{ standalone: true }"
              [readOnly]="true"
              [modules]="{ toolbar: false }"
              [preserveWhitespace]="true"
            ></quill-editor>
          </div>
          <div class="row">
            <span class="label">{{ 'ui.time' | translate }}</span>
            {{ taskReportDetails?.timestamp | isaDate: 'medium' }}
          </div>
          <div class="row">
            <span class="label">{{ 'ui.taskReports.titleColumn' | translate }}</span>
            {{ taskReportsData.title }}
          </div>
          <div class="row">
            <span class="label">{{ 'ui.taskReports.categoryColumn' | translate }}</span>
            {{ taskReportsData.category }}
          </div>
          <div class="row">
            <span class="label">{{ 'ui.team' | translate }}</span>
            {{ taskReportDetails?.teamId | isaTeamName }}
          </div>
        </div>
        @if (showFeedback()) {
          <mat-form-field class="full-width textarea feedback">
            <mat-label>{{ 'ui.taskReports.feedback' | translate }}</mat-label>
            <textarea
              formControlName="feedback"
              matInput
              [cdkTextareaAutosize]
              tabindex="-1"
            ></textarea>
          </mat-form-field>
        }
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        @if (
          hasPermissionToConfirmOrDeny &&
          taskReportsData?.status === CONFIRMATION_STATUS.PENDING_CONFIRMATION
        ) {
          @if (
            aiFeaturesEnabled &&
            (taskReportsData.aiAssessmentStatus == null ||
              taskReportsData.aiAssessmentStatus === ASSESSMENT_STATUS.ASSESSMENT_FAILED)
          ) {
            <button mat-raised-button (click)="sendToAIAssessment()">
              <i class="fas fa-magic-wand-sparkles status-ai-icon" aria-hidden="true"></i>
              {{ 'ui.reports.autoAssess' | translate }}
            </button>
          }
          <cybexer-confirm-action [disabled]="!isExerciseRunning">
            <button
              class="isa-button-confirm"
              mat-raised-button
              color="primary"
              (click)="onSubmit(CONFIRMATION_STATUS.CONFIRMED)"
              [disabled]="!isExerciseRunning || submitted"
            >
              <i class="fas fa-check-circle mat-button-fa" aria-hidden="true"></i
              >{{ 'ui.approve' | translate }}
            </button>
          </cybexer-confirm-action>
          <cybexer-confirm-action [disabled]="!isExerciseRunning">
            <button
              class="isa-button-deny"
              mat-raised-button
              (click)="onSubmit(CONFIRMATION_STATUS.DENIED)"
              [disabled]="!isExerciseRunning || submitted"
            >
              <i class="fas fa-exclamation-circle mat-button-fa" aria-hidden="true"></i
              >{{ 'ui.reject' | translate }}
            </button>
          </cybexer-confirm-action>
        }
        <button mat-button matDialogClose>{{ 'ui.cancel' | translate }}</button>
      </mat-dialog-actions>
    </form>
  }
  @if (loading) {
    <cybexer-progress-spinner></cybexer-progress-spinner>
  }
</div>
