import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { BlueTeam, Exercise, SpecialScoring } from '../../../models';
import { ExerciseService, IntervalService, SpecialScoringService } from '../../../services';
import { FilterStateModel, FilterStateService } from '../../../shared';
import { SpecialScoringDialogComponent } from './special-scoring-dialog/special-scoring-dialog.component';

@UntilDestroy()
@Component({
  selector: 'isa-special-scoring',
  templateUrl: './special-scoring.component.html',
  styleUrls: ['./special-scoring.component.scss'],
})
export class SpecialScoringComponent implements OnInit {
  filter$: Observable<Partial<FilterStateModel>>;

  exercise: Exercise;
  teams: BlueTeam[] = [];
  specialScoringList: SpecialScoring[] = [];
  filteredSpecialScorings: SpecialScoring[] = [];
  specialScoringDialogRef: MatDialogRef<SpecialScoringDialogComponent>;
  loading = true;

  constructor(
    private exerciseService: ExerciseService,
    private intervalService: IntervalService,
    private specialScoringService: SpecialScoringService,
    private dialog: MatDialog,
    public filterStateService: FilterStateService
  ) {}

  ngOnInit() {
    this.filter$ = this.filterStateService.filter$('team');
    this.exerciseService.activeExercise
      .pipe(untilDestroyed(this))
      .subscribe((exercise: Exercise) => {
        this.exercise = exercise;
        if (exercise) {
          this.teams = exercise.blueTeams;
          this.intervalService
            .getWidgetRefreshInterval()
            .pipe(untilDestroyed(this))
            .subscribe(() => {
              this.loadSpecialScoringList();
            });
        }
      });

    this.filter$.pipe(untilDestroyed(this)).subscribe(() => this.filterSpecialScorings());
  }

  loadSpecialScoringList(): void {
    if (this.exercise) {
      this.specialScoringService
        .getSpecialScoringList(this.exercise.id)
        .pipe(untilDestroyed(this))
        .subscribe((res) => {
          this.specialScoringList = res;
          this.filterSpecialScorings();
          this.loading = false;
        });
    }
  }

  filterSpecialScorings(): void {
    const { team } = this.filterStateService.snapshot();
    this.filteredSpecialScorings = this.specialScoringList.filter((specialScoring) =>
      team ? specialScoring.teamId === team : true
    );
  }

  openSpecialScoringDialog(): void {
    this.specialScoringDialogRef = this.dialog.open(SpecialScoringDialogComponent, {
      disableClose: false,
      data: {
        exercise: this.exercise,
        teams: this.teams,
      },
    });

    this.specialScoringDialogRef.afterClosed().subscribe(() => {
      this.loadSpecialScoringList();
      this.specialScoringDialogRef = null;
    });
  }
}
