import { Component, EventEmitter } from '@angular/core';
import { ConsoleConnectionParameters, ConsoleConnectionType } from '@cybexer/ngx-commons';
import { CTFTargetData, TargetStatusSummary } from '../../../models';
import { VirtualMachineService } from '../../../services';

@Component({
  template: '',
})
export class ConsoleBaseComponent {
  isTargetManagementEnabled = false;
  isNativeConsoleOpened = false;
  isGuacamoleConsoleOpened = false;
  openNativeConsole: EventEmitter<boolean> = new EventEmitter();
  openGuacamoleConsole: EventEmitter<boolean> = new EventEmitter();
  consoleConnectionParams: ConsoleConnectionParameters;

  constructor(protected virtualMachineService: VirtualMachineService) {}

  openConsole(
    target: CTFTargetData | TargetStatusSummary,
    connectionType: ConsoleConnectionType,
    exerciseId: string,
    teamId: string
  ) {
    if (connectionType === ConsoleConnectionType.NATIVE) {
      this.isGuacamoleConsoleOpened = false;
      this.isNativeConsoleOpened = true;
      this.virtualMachineService.validateVmAccess(target.vmId, exerciseId, teamId).subscribe({
        next: () => {
          this.openNativeConsole.emit(true);
        },
        error: () => {
          this.isNativeConsoleOpened = false;
        },
      });
    } else {
      this.isNativeConsoleOpened = false;
      this.consoleConnectionParams = this.virtualMachineService.getGuacamoleConnectionParameters(
        target,
        connectionType,
        this.isTargetManagementEnabled
      );

      this.isGuacamoleConsoleOpened = true;
      this.openGuacamoleConsole.emit(true);
    }
  }
}
