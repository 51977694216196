import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationsService } from '@cybexer/ngx-commons';
import { finalize } from 'rxjs/operators';
import {
  AttackCampaignDataObjective,
  AttackCampaignDataObjectiveTeam,
  AttackCampaignReport,
  AttackFlagRequest,
  Exercise,
} from '../../../../models';
import { AttackCampaignService } from '../../../../services';
import { OBJECTIVE_STATUS, QuillEditorUtil, TagValidator } from '../../../../shared';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'isa-objective-status-report',
  templateUrl: './objective-status-report.component.html',
  styleUrls: ['./objective-status-report.component.scss'],
})
export class ObjectiveStatusReportComponent implements OnInit {
  statusReportForm: UntypedFormGroup;
  attackFlagForm: UntypedFormGroup;
  triedToSubmit: boolean;
  flagRequested: boolean;
  flagProcessed: boolean;
  flagPath: string;
  flagError: string;
  team: AttackCampaignDataObjectiveTeam;
  objective: AttackCampaignDataObjective;
  exercise: Exercise;
  objective_status = OBJECTIVE_STATUS;
  isSubmitting = false;
  OBJECTIVE_STATUS = OBJECTIVE_STATUS;

  get imageUploadUrl(): string {
    return this.attackCampaignService.getImageUploadUrl(this.exercise.id);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ObjectiveStatusReportComponent>,
    private attackCampaignService: AttackCampaignService,
    private notificationsService: NotificationsService,
    private translate: TranslateService,
    private tagValidator: TagValidator
  ) {}

  ngOnInit(): void {
    this.flagRequested = false;
    this.team = this.data['team'];
    this.objective = this.data['objective'];
    this.exercise = this.data['exercise'];
    this.statusReportForm = new UntypedFormGroup({
      status: new UntypedFormControl('', Validators.required),
      evidence: new UntypedFormControl(
        this.objective.reportTemplate ? this.objective.reportTemplate : ''
      ),
    });
    this.attackFlagForm = new UntypedFormGroup({
      flagContent: new UntypedFormControl('', Validators.required),
    });
  }

  onSubmit(statusReportForm: UntypedFormGroup, attackFlagForm: UntypedFormGroup): void {
    if (this.isSubmitting || !this.areAllTagsProcessed()) return;
    this.triedToSubmit = true;
    if (statusReportForm.valid || attackFlagForm.valid) {
      this.isSubmitting = true;
      this.flagProcessed = false;
      const report: AttackCampaignReport = new AttackCampaignReport({
        exerciseId: this.exercise.id,
        teamId: this.team.teamId,
        objectiveId: this.team.objectiveId,
        objectiveContainerId: this.team.objectiveContainerId,
        status:
          statusReportForm.value.status?.trim() !== '' ? statusReportForm.value.status : undefined,
        evidence: statusReportForm.value.evidence ? statusReportForm.value.evidence : undefined,
        flagFileFullPath: this.flagPath,
        flagFileContent: attackFlagForm.value.flagContent,
      });
      this.attackCampaignService
        .createReport(report)
        .pipe(
          finalize(() => (this.flagProcessed = true)),
          untilDestroyed(this)
        )
        .subscribe({
          next: () => {
            this.notificationsService.success(
              this.translate.instant('ui.attackCampaign.statusReportSent')
            );
            this.dialogRef.close(report);
          },
          error: (err: unknown) => {
            this.isSubmitting = false;
            if (!(err instanceof HttpErrorResponse)) throw err;
            if (err.status === 400) {
              this.flagError = err.error.error;
            } else {
              throw new Error(err.statusText);
            }
          },
        });
    }
  }

  areAllTagsProcessed(): boolean {
    const value = this.statusReportForm.value.evidence;
    const notProcessedTags = this.tagValidator.getNotProcessedTags(value);
    if (notProcessedTags == null) return true;

    this.notificationsService.warn(
      this.translate.instant(TagValidator.NOT_ALL_TAGS_PROCESSED_MSG),
      notProcessedTags
    );

    return false;
  }

  requestFlag(isAdminPermissions): void {
    this.flagProcessed = false;
    this.flagRequested = true;
    const attackFlagRequest: AttackFlagRequest = new AttackFlagRequest({
      exerciseId: this.exercise.id,
      objectiveId: this.team.objectiveId,
      isAdminPermissions: isAdminPermissions,
    });
    this.attackCampaignService
      .requestFlag(attackFlagRequest)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (res) => {
          this.flagPath = res.flagFileFullPath;
          this.flagProcessed = true;
        },
        error: (err: unknown) => {
          this.flagRequested = false;
          if (!(err instanceof HttpErrorResponse)) throw err;
          throw new Error(err.statusText);
        },
      });
  }

  addQuillImageHandler(editorInstance: any) {
    QuillEditorUtil.addImageHandler(editorInstance, this.imageUploadUrl);
  }
}
