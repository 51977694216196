import {
  TaskReportFilterResult,
  TaskReportsData,
} from '../../../../models/gamenet/ctf/task-reports-data.model';
import { ReportsDatasource } from '../../reports.datasource';

export class TaskReportsDatasource extends ReportsDatasource<
  TaskReportsData,
  TaskReportFilterResult
> {}
