import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationsService } from '@cybexer/ngx-commons';
import { Exercise } from '../../../../models';
import { SystemEvent } from '../../../../models/gamenet/exercise.model';
import { ExerciseService } from '../../../../services';
import { FormUtil } from '../../../../shared';
import { ExerciseGroup } from '../../../../models/gamenet/exercise-group.model';
import { ExerciseGroupService } from '../../../../services/gamenet/exercise-group.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'isa-exercise-confirm-dialog',
  templateUrl: './exercise-confirm-dialog.component.html',
  styleUrls: ['./exercise-confirm-dialog.component.scss'],
})
export class ExerciseConfirmDialogComponent implements OnInit {
  exercise?: Exercise;
  exerciseGroup: ExerciseGroup;
  eventType: string;
  message: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private exerciseService: ExerciseService,
    private exerciseGroupService: ExerciseGroupService,
    private notificationsService: NotificationsService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<ExerciseConfirmDialogComponent>
  ) {}

  ngOnInit() {
    this.exercise = this.data['exercise'];
    this.exerciseGroup = this.data['exerciseGroup'];
    this.eventType = this.data['eventType'];
  }

  confirmEvent() {
    const event: SystemEvent = new SystemEvent({
      eventType: this.eventType,
      message: ExerciseConfirmDialogComponent.formatMessage(this.message),
    });
    (this.exerciseGroup == null
      ? this.exerciseService.createSystemEvent(this.exercise.id, event)
      : this.exerciseGroupService.createSystemEvents(
          this.exerciseGroup.exercises.map((exercise) => exercise.id),
          event
        )
    ).subscribe({
      next: () => {
        const message =
          (this.exerciseGroup == null
            ? `${this.translate.instant('ui.exercise.exercise')} '${this.exercise.name}' `
            : `${this.translate.instant('ui.exercise.exerciseGroup')} '${this.exerciseGroup.name}' `) +
          `${this.translate.instant(event.eventType === 'STARTEX' ? 'ui.exercise.exerciseStarted' : 'ui.exercise.exerciseStopped')}`;
        this.notificationsService.success(message);
        this.dialogRef.close(event.eventType);
      },
      error: (err: unknown) => {
        if (err instanceof HttpErrorResponse) {
          this.notificationsService.error(err.error.error);
        } else {
          throw err;
        }
        this.dialogRef.close('');
      },
    });
  }

  private static formatMessage(feedback?: string): string {
    if (feedback != null && FormUtil.getStringCharacterCount(feedback.trim()) > 0) {
      return FormUtil.replaceNewlines(feedback);
    }
    return '';
  }
}
