<div class="exercise-definition widget-header">
  <div class="widget-title">
    <span>{{ 'ui.exercise.moduleDefinition' | translate }}</span>
  </div>
  @if (exercise) {
    <mat-tab-group
      animationDuration="0ms"
      [(selectedIndex)]="selectedTabIndex"
      (selectedIndexChange)="tabSelectionChange($event)"
    >
      <mat-tab [label]="'ui.edit' | translate"></mat-tab>
      <mat-tab [label]="'ui.exercise.recreateFromTemplate' | translate"></mat-tab>
    </mat-tab-group>
  }
  <form class="isa-form" (ngSubmit)="onSubmit()">
    <div>
      @if (processing) {
        <cybexer-progress-spinner></cybexer-progress-spinner>
      }
      @if (!processing) {
        <div>
          <div
            [ngClass]="{
              'editor-container': exercise,
              'editor-container-full': !exercise
            }"
          >
            <cybexer-json-monaco-editor
              [(ngModel)]="exerciseJSON"
              [jsonSchema]="jsonSchema"
              [ngModelOptions]="{ standalone: true }"
              (editorInitialized)="editorInitialized($event)"
              ngDefaultControl
            >
            </cybexer-json-monaco-editor>
          </div>
          <div class="form-button-line">
            @if (exerciseJsonType === null) {
              <button
                mat-raised-button
                class="submit-button"
                type="submit"
                color="primary"
                [disabled]="isSubmitButtonDisabled()"
                data-testid="submit-button"
              >
                <span [matTooltip]="getSubmitButtonTooltip()">{{
                  (selectedTabIndex === 0 ? 'ui.submit' : 'ui.exercise.recreate') | translate
                }}</span>
              </button>
            }
            @if (exerciseJsonType !== null) {
              <button
                mat-raised-button
                type="button"
                (click)="loadNewSchema()"
                [disabled]="isSubmitButtonDisabled()"
                data-testid="load-schema-button"
              >
                {{ 'ui.exercise.loadSchema' | translate: { jsonType: exerciseJsonType } }}
              </button>
            }
            <button type="button" mat-button (click)="downloadExerciseJsonSchema()">
              <mat-icon [matTooltip]="'ui.exercise.downloadSchema' | translate"
                >file_download</mat-icon
              >
              {{ 'ui.exercise.downloadSchema' | translate }}
            </button>
          </div>
        </div>
      }
    </div>
  </form>
</div>
