import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BlueTeam, Exercise, ExerciseType } from '../../../models';
import { ExerciseService, UserScoringService } from '../../../services';
import { FilterStateModel, FilterStateService } from '../../../shared';

@UntilDestroy()
@Component({
  selector: 'isa-user-scoring',
  templateUrl: 'user-scoring.component.html',
  styleUrls: ['user-scoring.component.scss'],
})
export class UserScoringComponent implements OnInit {
  filter$: Observable<Partial<FilterStateModel>>;
  exerciseId: string;
  teams: BlueTeam[] = [];
  userScoringData: any[] = [];
  loading = true;
  isCTF: boolean;

  constructor(
    private exerciseService: ExerciseService,
    private userScoringService: UserScoringService,
    public filterStateService: FilterStateService
  ) {}

  ngOnInit(): void {
    this.filter$ = this.filterStateService.filter$(
      'team',
      'horizontalView',
      'sliderDate',
      'timePeriod'
    );
    this.exerciseService.activeExercise
      .pipe(
        filter((exercise: Exercise) => !!exercise),
        untilDestroyed(this)
      )
      .subscribe((exercise: Exercise) => {
        this.exerciseId = exercise.id;
        this.isCTF = exercise.type === ExerciseType.CTF;
        this.teams = exercise.blueTeams;
      });

    this.filter$.pipe(untilDestroyed(this)).subscribe((filter) => {
      this.processData(filter);
    });
  }

  processData({ sliderDate, team }: Partial<FilterStateModel>): void {
    if (this.exerciseId && sliderDate) {
      this.userScoringService
        .getScoringData(this.exerciseId, team, sliderDate.toISOString())
        .pipe(untilDestroyed(this))
        .subscribe((data) => {
          this.userScoringData = data;
          this.loading = false;
        });
    }
  }
}
