import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  RoleListItem,
  RoleTableItem,
  TargetListItem,
  TargetsByDomain,
  TargetsDTO,
  UserRole,
  UserTargetsByDomain,
} from '../../../../models/shared/role.model';
import { PERMISSION_DOMAINS } from '../../../../shared';

export interface RoleAssignmentDialogData {
  role: RoleTableItem;
  targetsByDomain: TargetsByDomain;
  userRole?: UserRole;
}

export interface RoleAssignmentResult {
  code: string;
  isEnabled: boolean;
  targetsByDomain: UserTargetsByDomain;
}

@UntilDestroy()
@Component({
  selector: 'isa-role-assignment-dialog',
  templateUrl: './role-assignment-dialog.component.html',
  styleUrls: ['./role-assignment-dialog.component.scss'],
})
export class RoleAssignmentDialogComponent implements OnInit {
  private static GN_ADMIN_ROLE = 'GN_ADMIN';
  private static GN_EXERCISE_DOMAIN = 'GN_EXERCISE';

  form: UntypedFormGroup;
  role: RoleListItem;
  targetsByDomain: TargetsByDomain;
  filteredTargetsByDomain: TargetsByDomain = {};
  isTargetFilterEnabled: boolean;
  cachedEnabledTargets: UserTargetsByDomain;
  private userRole?: UserRole;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RoleAssignmentDialogData,
    private dialogRef: MatDialogRef<RoleAssignmentDialogComponent>
  ) {}

  ngOnInit() {
    this.role = this.data.role;
    this.targetsByDomain = this.data.targetsByDomain;
    this.userRole = this.data.userRole;
    this.initFormControls();
  }

  private initFormControls() {
    const domainControls = this.role.domains.reduce((result, domain) => {
      result[domain] = new UntypedFormGroup({
        targets: new UntypedFormControl([]),
        hasWildcard: new UntypedFormControl(false),
      });
      return result;
    }, {});
    this.form = new UntypedFormGroup({
      domains: new UntypedFormGroup(domainControls),
      isEnabled: new UntypedFormControl(false),
    });
    this.initFormValues();
    this.onFormChanges();
  }

  private initFormValues() {
    this.isTargetFilterEnabled = false;
    setTimeout(() => {
      this.form.get('isEnabled').setValue(Boolean(this.userRole));
    });
    this.role.domains.forEach((domain) => {
      const initialTargets = this.getInitialTargets(domain);
      if (!initialTargets.length) {
        return;
      }
      setTimeout(() => {
        this.form
          .get(`domains.${domain}.targets`)
          .setValue(initialTargets.filter((target) => target !== '*'));
        this.form
          .get(`domains.${domain}.hasWildcard`)
          .setValue(initialTargets.some((target) => target === '*'));
      });
    });
  }

  private onFormChanges() {
    this.form
      .get('isEnabled')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((isEnabled) => this.onIsEnabledChange(isEnabled));
    this.role.domains.forEach((domain) => {
      this.form
        .get(`domains.${domain}.hasWildcard`)
        .valueChanges.pipe(untilDestroyed(this))
        .subscribe((hasWildcard) => this.onWildcardChange(domain, hasWildcard));
    });
  }

  private onIsEnabledChange(isEnabled: boolean) {
    const domainsControl = this.form.get('domains');
    isEnabled ? domainsControl.enable() : domainsControl.disable();
    this.role.domains.forEach((domain) => {
      // This should already be done by domainsControl toggling - not sure why it isn't.
      const hasWildcard = this.form.get(`domains.${domain}.hasWildcard`).value;
      const targetsControl = this.form.get(`domains.${domain}.targets`);
      if (!hasWildcard && isEnabled) {
        targetsControl.enable();
      } else if (!isEnabled) {
        targetsControl.disable();
      }
    });
  }

  private onWildcardChange(domain: string, hasWildcard: boolean) {
    const targetsControl = this.form.get(`domains.${domain}.targets`);
    hasWildcard ? targetsControl.disable() : targetsControl.enable();
  }

  private getInitialTargets(domain: string): string[] {
    if (
      !this.userRole ||
      !this.userRole.targetsByDomain ||
      !this.userRole.targetsByDomain[domain]
    ) {
      return [];
    }
    return this.userRole.targetsByDomain[domain];
  }

  filterEnabledTargets(domain: string): TargetsDTO[] {
    const listOfEnabledIds = this.userRole.targetsByDomain[domain];
    if (!listOfEnabledIds) {
      return [];
    }

    return this.targetsByDomain[domain]
      .map((targetsDTO) => {
        const newTargetsDTO = new TargetsDTO(targetsDTO);
        newTargetsDTO.targets = newTargetsDTO.targets.filter((target) =>
          listOfEnabledIds.includes(target.id)
        );

        return newTargetsDTO;
      })
      .filter((it) => it.targets && it.targets.length > 0);
  }

  targetFilterSwitch(): void {
    if (!this.hasAnyEnabledTargets()) {
      return;
    }

    this.isTargetFilterEnabled = !this.isTargetFilterEnabled;

    this.setCachedTargetValues();
    this.applyCachedTargetValues();

    if (!this.isTargetFilterEnabled) {
      this.filteredTargetsByDomain = this.targetsByDomain;

      return;
    }

    this.filteredTargetsByDomain = {};
    this.role.domains.forEach((domain) => {
      this.filteredTargetsByDomain[domain] = this.filterEnabledTargets(domain);
    });
  }

  onSubmit(): void {
    this.dialogRef.close({
      code: this.role.code,
      isEnabled: this.form.get('isEnabled').value,
      targetsByDomain: this.getSelectedTargets(),
    } as RoleAssignmentResult);
  }

  getVisibleDomains(): string[] {
    return this.role.domains.filter((domain) => {
      if (
        this.role.code === RoleAssignmentDialogComponent.GN_ADMIN_ROLE &&
        domain === RoleAssignmentDialogComponent.GN_EXERCISE_DOMAIN
      ) {
        return true;
      }

      const domainHasTargets =
        Boolean(this.targetsByDomain[domain]) &&
        Boolean(this.targetsByDomain[domain].length) &&
        this.targetsByDomain[domain].some((targetsDTO) => Boolean(targetsDTO.targets.length));
      const domainIsParent = this.isParentDomain(domain);
      return domainHasTargets && !domainIsParent;
    });
  }

  private isParentDomain(domain: string) {
    return this.role.domains.some((roleDomain) => {
      return this.targetsByDomain[roleDomain].some((targets) => targets.parentDomain === domain);
    });
  }

  private getSelectedTargets(): UserTargetsByDomain {
    return this.role.domains.reduce((result, domain) => {
      const domainValue = this.form.get(`domains.${domain}`).value;
      if (domainValue.hasWildcard) {
        result[domain] = ['*'];
      } else if (this.isParentDomain(domain)) {
        result[domain] = this.getSelectedParentTargets(domain);
      } else {
        result[domain] = domainValue.targets;
      }
      return result;
    }, {});
  }

  private getSelectedParentTargets(domain: string): string[] {
    const result = [];
    for (const roleDomain of this.role.domains) {
      if (roleDomain !== domain) {
        const childDomainValue = this.form.get(`domains.${roleDomain}`).value;
        if (childDomainValue.hasWildcard) {
          // Return parent as wildcard immediately if child domain is wildcard
          return ['*'];
        }
        this.targetsByDomain[roleDomain]
          .filter((targetsDTO) => targetsDTO.parentDomain === domain)
          .forEach((childTargetsDTO) => {
            const isChildSelected = childTargetsDTO.targets.some((target) => {
              return childDomainValue.targets.some((selectedTargetId) => {
                return selectedTargetId === target.id;
              });
            });
            if (isChildSelected) {
              result.push(childTargetsDTO.parentTargetId);
            }
          });
      }
    }
    return result;
  }

  getDomainName(domain: string): string {
    switch (domain.toLowerCase()) {
      case PERMISSION_DOMAINS.GN_EXERCISE:
        return 'ui.roleAssignment.gameNetExercises';
      case PERMISSION_DOMAINS.GN_BLUE_TEAM:
        return 'ui.roleAssignment.blueTeams';
      default:
        return 'ui.roleAssignment.targets';
    }
  }

  getTargetName(target: TargetListItem): string {
    if (target.customName == null || target.name == target.customName) return target.name;

    return `${target.name} (${target.customName})`;
  }

  isBlueDomain(domain: string): boolean {
    return domain.toLowerCase() === PERMISSION_DOMAINS.GN_BLUE_TEAM;
  }

  getTargetsByDomain(domain: string) {
    return this.isTargetFilterEnabled
      ? this.filteredTargetsByDomain[domain]
      : this.targetsByDomain[domain];
  }

  hasAnyEnabledTargets(): boolean {
    let hasEnabledTarget = false;
    if (this.userRole) {
      this.role.domains.forEach((domain) => {
        if (
          this.userRole.targetsByDomain[domain] &&
          this.userRole.targetsByDomain[domain].length > 0
        ) {
          hasEnabledTarget = true;
        }
      });
    }
    return hasEnabledTarget;
  }

  setCachedTargetValues(): void {
    const currentTargets = this.getSelectedTargets();

    if (this.isTargetFilterEnabled) {
      this.cachedEnabledTargets = currentTargets;
    } else {
      this.role.domains.forEach((domain) => {
        const cachedTargetsByDomain = this.cachedEnabledTargets[domain];
        const currentTargetsByDomain = currentTargets[domain];

        if (!cachedTargetsByDomain) {
          return;
        }

        const targetsToDelete = this.getTargetsToDelete(
          cachedTargetsByDomain,
          currentTargetsByDomain,
          domain
        );

        currentTargetsByDomain.forEach((id) => {
          if (!cachedTargetsByDomain.find((x) => x === id)) {
            cachedTargetsByDomain.push(id);
          }
        });

        if (targetsToDelete.length > 0) {
          this.deleteTargetsFromCache(domain, targetsToDelete);
        }
      });
    }
  }

  private getTargetsToDelete(cachedTargetsByDomain, currentTargetsByDomain, domain): any[] {
    const targetsToDelete = [];

    cachedTargetsByDomain.forEach((id) => {
      if (
        this.filteredTargetsByDomain[domain].find(
          (it) =>
            it.targets.find((target) => target.id === id) &&
            !currentTargetsByDomain.find((x) => x === id)
        )
      ) {
        targetsToDelete.push(id);
      }
    });

    return targetsToDelete;
  }

  private deleteTargetsFromCache(domain: string, targetsToDelete): void {
    for (let index = 0; index < targetsToDelete.length; index++) {
      const enabledTargets = this.cachedEnabledTargets[domain];
      const targetToDeleteIndex = enabledTargets.indexOf(targetsToDelete[index]);
      enabledTargets.splice(targetToDeleteIndex, 1);
    }
  }

  applyCachedTargetValues(): void {
    this.role.domains.forEach((domain) => {
      const cachedTargets = this.cachedEnabledTargets[domain];

      if (!cachedTargets) {
        return;
      }

      this.form
        .get(`domains.${domain}.targets`)
        .setValue(cachedTargets.filter((target) => target !== '*'));
    });
  }
}
