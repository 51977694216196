import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NotificationsService } from '@cybexer/ngx-commons';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../../../services';
import { UserDeleteDialogComponent } from './user-delete-dialog/user-delete-dialog.component';
import { UserDialogComponent } from './user-dialog/user-dialog.component';

@Component({
  selector: 'isa-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent {
  dialogRef: MatDialogRef<UserDialogComponent>;
  deleteDialogRef: MatDialogRef<UserDeleteDialogComponent>;
  usersChanged$ = new Subject<boolean>();
  currentUser: string;

  constructor(
    private notificationsService: NotificationsService,
    private dialog: MatDialog,
    private authenticationService: AuthenticationService
  ) {
    this.currentUser = this.authenticationService.currentUser.username;
  }

  isCurrentUser(username: string): boolean {
    return username == this.currentUser;
  }

  openUserCreationDialog() {
    this.dialogRef = this.dialog.open(UserDialogComponent, {
      disableClose: false,
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.usersChanged$.next(true);
      }
      this.dialogRef = null;
    });
  }

  openUserDeletionDialog(username: string) {
    if (this.isCurrentUser(username)) {
      return;
    }

    this.deleteDialogRef = this.dialog.open(UserDeleteDialogComponent, {
      disableClose: false,
      data: { username },
    });

    this.deleteDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.usersChanged$.next(true);
      }
      this.deleteDialogRef = null;
    });
  }
}
