<div
  #infiniteScrollContainer
  class="advanced-scoring-log"
  infiniteScroll
  isaInfiniteScrollLoadMoreItemsOnStartup
  [items]="advancedScoringLogItems"
  [scrollWindow]="false"
  [infiniteScrollContainer]="infiniteScrollContainer"
  [infiniteScrollDistance]="2"
  [infiniteScrollThrottle]="200"
  [infiniteScrollDisabled]="infiniteScrollDisabled"
  (scrolled)="onScroll()"
>
  <cybexer-widget-header [title]="'ui.advancedScoring.advancedScoringLog' | translate">
    <isa-filtered-select
      [options]="teams"
      [preselectedOption]="(filter$ | async).team"
      [optionKey]="'customName'"
      [optionValue]="'id'"
      [defaultOptionKey]="'ui.allTeams' | translate"
      [label]="(exercise?.isIndividualAssessment ? 'ui.user' : 'ui.team') | translate"
      (selectionChange)="filterStateService.setFilter('team', $event)"
    ></isa-filtered-select>
    <mat-form-field>
      <mat-label>{{ 'ui.advancedScoring.category' | translate }}</mat-label>
      <mat-select
        ngModel
        multiple
        ngDefaultControl
        [ngModel]="(filter$ | async).scoreCategories"
        (ngModelChange)="filterStateService.setFilter('scoreCategories', $event)"
        class="category-filter-dropdown"
      >
        @for (category of categories; track category) {
          <mat-option [value]="category">
            {{ category | isaReplaceUnderScores }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
    <isa-date-picker
      ngDefaultControl
      [placeholder]="'ui.startDate' | translate"
      type="datetime"
      [ngModel]="(filter$ | async).startDate"
      (ngModelChange)="filterStateService.setFilter('startDate', $event)"
    ></isa-date-picker>
    <isa-date-picker
      ngDefaultControl
      [placeholder]="'ui.endDate' | translate"
      type="datetime"
      [ngModel]="(filter$ | async).endDate"
      (ngModelChange)="filterStateService.setFilter('endDate', $event)"
    ></isa-date-picker>
    <div class="spacer"></div>
    <button mat-mini-fab (click)="refresh()">
      <i class="fas fa-sync" aria-hidden="true"></i>
    </button>
  </cybexer-widget-header>
  <cybexer-simple-table [tableData]="advancedScoringLogItems" [columns]="advancedScoringLogColumns">
  </cybexer-simple-table>
  <div>
    @if (loading) {
      <cybexer-progress-spinner></cybexer-progress-spinner>
    }
    @if (!advancedScoringLogItems.length && !loading) {
      <cybexer-no-data></cybexer-no-data>
    }
  </div>
</div>
