import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { of, Subscription } from 'rxjs';
import { filter, finalize, map, mergeMap, take } from 'rxjs/operators';
import {
  Exercise,
  ExerciseType,
  NavigationMenuGroup,
  NavigationMenuLink,
  User,
} from '../../../models';
import { IsaModule } from '../../../models/shared/isa-module.model';
import { ObserverViewType, Settings } from '../../../models/shared/settings.model';
import {
  AuthenticationService,
  ExerciseService,
  PreferenceService,
  SettingsService,
} from '../../../services';
import {
  DomainPermission,
  EXERCISE_PERMISSIONS,
  PermissionConfiguration,
  PermissionData,
  ROLES,
} from '../../../shared';
import { NAVIGATION_MENU_CONFIG } from '../../../shared/navigation-menu-config';

@UntilDestroy()
@Component({
  selector: 'isa-navigation-menu',
  templateUrl: 'navigation-menu.component.html',
})
export class NavigationMenuComponent implements OnInit {
  settings: Settings;
  currentUser: User;
  exercise: Exercise;
  ExerciseType = ExerciseType;
  isDefaultExerciseLoading: boolean;

  gamenetMenuGroups: NavigationMenuGroup[] = [];
  adminMenuGroups: NavigationMenuGroup[] = [];

  private subscriptions: Subscription[] = [];
  private userBlueTeamId = undefined;

  constructor(
    private authenticationService: AuthenticationService,
    private exerciseService: ExerciseService,
    private settingsService: SettingsService,
    private preferenceService: PreferenceService
  ) {}

  ngOnInit(): void {
    this.authenticationService.currentUser$.subscribe((user) => {
      this.currentUser = user;

      this.settingsService
        .getSettings()
        .pipe(take(1)) // will unsubscribe automatically after first execution
        .subscribe((settings) => {
          this.settings = settings;
          this.updateMenu();
        });

      this.settingsService.settingsChanged.pipe(untilDestroyed(this)).subscribe((settings) => {
        this.settings = settings;
        this.unsubscribe();

        this.updateMenu();
      });
    });
  }

  loadDefaultExercise() {
    if (!this.exerciseService.activeExercise.value && !this.isDefaultExerciseLoading) {
      this.preferenceService
        .getPreferences(true)
        .pipe(map((preferences) => preferences.defaultExerciseId))
        .pipe(filter((value) => value !== undefined))
        .subscribe((defaultExerciseId) => this.setExerciseActive(defaultExerciseId));
    }
  }

  private setExerciseActive(defaultExerciseId: string) {
    this.isDefaultExerciseLoading = true;
    this.exerciseService
      .fetchAndSetExerciseActive(defaultExerciseId)
      .pipe(finalize(() => (this.isDefaultExerciseLoading = false)))
      .subscribe();
  }

  private updateMenu() {
    this.gamenetMenuGroups = [];
    this.adminMenuGroups = [];

    this.updateGNMenu();
    this.updateAdminMenu();
  }

  private updateGNMenu(): void {
    if (this.settings.isModuleEnabled(IsaModule.GAMENET)) {
      const activeExerciseSubscription = this.exerciseService.activeExercise
        .pipe(
          mergeMap((activeExercise) => {
            this.exercise = activeExercise;
            return this.exercise && this.authenticationService.hasRole(this.exercise.id, ROLES.BLUE)
              ? this.exerciseService.getUserBlueTeam(this.exercise.id)
              : of(undefined);
          }),
          untilDestroyed(this)
        )
        .subscribe((userBlueTeamId) => {
          this.userBlueTeamId = userBlueTeamId;
          this.gamenetMenuGroups = this.filterMenuGroups(
            NAVIGATION_MENU_CONFIG.gnMenuGroups,
            this.exercise == null
          );
        });
      this.subscriptions.push(activeExerciseSubscription);
    }
  }

  private updateAdminMenu(): void {
    this.adminMenuGroups = this.filterMenuGroups(NAVIGATION_MENU_CONFIG.adminMenuGroups, false);
  }

  filterMenuGroups(
    menuGroups: NavigationMenuGroup[],
    showDefaultOnly: boolean
  ): NavigationMenuGroup[] {
    if (showDefaultOnly) {
      menuGroups = NavigationMenuComponent.filterMenuGroups(
        menuGroups,
        NavigationMenuComponent.byDefaultLinkFilter()
      );
    }

    menuGroups = NavigationMenuComponent.filterMenuGroups(
      menuGroups,
      this.byPrerequisitesLinkFilter()
    );

    return this.filterMenuGroupsByPermissions(menuGroups);
  }

  filterMenuGroupsByPermissions(menuGroups: NavigationMenuGroup[]): NavigationMenuGroup[] {
    return NavigationMenuComponent.filterMenuGroups(menuGroups, this.byPermissionsLinkFilter());
  }

  private byPermissionsLinkFilter() {
    return (link: NavigationMenuLink) =>
      this.authenticationService.userHasPermissions(
        this.currentUser,
        new PermissionConfiguration(this._getFilteredPermissions(link.permissions))
      );
  }

  private _getFilteredPermissions(permissions: PermissionData[]): PermissionData[] {
    let linkPermissions = permissions;
    if (
      PermissionConfiguration.hasGamenetPermissions(linkPermissions) &&
      this.exercise &&
      this.exercise.type
    ) {
      linkPermissions = PermissionConfiguration.filterPermissionsByExerciseType(
        linkPermissions,
        this.exercise.type
      );
      linkPermissions = PermissionConfiguration.filterPermissionByAdditionalFields(
        linkPermissions,
        this.exercise
      );
    }

    return linkPermissions;
  }

  private byPrerequisitesLinkFilter() {
    return (link: NavigationMenuLink) => {
      if (link.prerequisites == null) return true;
      const isModulesEnabled =
        link.prerequisites.modulesEnabled.length === 0 ||
        link.prerequisites.modulesEnabled.every((it) => this.settings.isModuleEnabled(it));
      const isObserver = !link.prerequisites.isObserver
        ? true
        : this.settings.observerEnabled &&
          this.getUserPermissions().some((it) =>
            it.toString().includes(EXERCISE_PERMISSIONS.OBSERVER)
          ) &&
          this.isObserverViewEnabled(link.prerequisites.observableViewType);

      const areExperimentalFeaturesAllowed = link.prerequisites.fromExperimentalFeatures
        ? this.settings.experimentalFeaturesEnabled
        : true;

      const isTeamAssessment = link.prerequisites.requireTeamAssessment
        ? !this.exercise.isIndividualAssessment
        : true;

      return (
        isModulesEnabled &&
        isObserver &&
        isTeamAssessment &&
        this.isCampaignLiveEnabled(link) &&
        this.isTeamScoringEnabled(link) &&
        areExperimentalFeaturesAllowed
      );
    };
  }

  private isCampaignLiveEnabled(link: NavigationMenuLink): boolean {
    if (this.userBlueTeamId == null) return true;

    const userBlueTeam = this.exercise
      ? this.exercise.blueTeams.find((team) => team.id === this.userBlueTeamId)
      : undefined;

    return (
      !link.prerequisites.isCampaignLive ||
      !this.getUserPermissions().some((it) =>
        it.toString().includes(EXERCISE_PERMISSIONS.BLUE_TEAM)
      ) ||
      (userBlueTeam && userBlueTeam.isCampaignLiveEnabled)
    );
  }

  private isTeamScoringEnabled(link: NavigationMenuLink): boolean {
    if (
      !link.prerequisites.isTeamScoring ||
      this.exercise.showTeamScoring ||
      this.userBlueTeamId == null
    ) {
      return true;
    }

    return false;
  }

  private getUserPermissions(): DomainPermission[] {
    return this.currentUser ? this.currentUser.permissions : [];
  }

  private isObserverViewEnabled(observerViewType?: ObserverViewType): boolean {
    if (observerViewType == null) {
      return false;
    }

    return (
      this.settings.observerSettings &&
      this.settings.observerSettings.enabledViews &&
      this.settings.observerSettings.enabledViews.some((it) => it == observerViewType)
    );
  }

  private unsubscribe(): void {
    this.subscriptions.forEach((it) => it.unsubscribe());
  }

  private static byDefaultLinkFilter() {
    return (link: NavigationMenuLink) => link.isDefault;
  }

  private static filterMenuGroups<T extends NavigationMenuLink>(
    menuGroups: NavigationMenuGroup[],
    filterFunc: (link: T) => boolean
  ): NavigationMenuGroup[] {
    return menuGroups
      .map((group) => NavigationMenuComponent.filterMenuLinks(group, filterFunc))
      .filter((group) => group.links.length > 0);
  }

  private static filterMenuLinks<T extends NavigationMenuLink>(
    menuGroup: NavigationMenuGroup,
    filterFunc: (link: T) => boolean
  ): NavigationMenuGroup {
    return new NavigationMenuGroup({
      type: menuGroup.type,
      links: menuGroup.links.filter(filterFunc),
    });
  }
}
