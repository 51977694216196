<div
  class="avatar-container"
  [ngClass]="styleClass"
  [style.width.px]="size"
  [style.height.px]="size"
  [style.font-size.px]="size / 2"
  [style.line-height.px]="size"
>
  @if (!isError && srcUrl) {
    <img
      class="avatar"
      alt="Avatar"
      [hidden]="!avatarLoaded"
      [src]="srcUrl | isaSafeUrl"
      (load)="completeLoading()"
      (error)="setError()"
    />
  }
  @if (isError) {
    <i class="fas fa-users" aria-hidden="true"></i>
  }
</div>
