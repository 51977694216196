import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SegmentStatusSummary, TeamStatusSummary } from '../../models';

@Injectable()
export class StatusSummaryService {
  constructor(private http: HttpClient) {}

  getTeamStatusData(exerciseId: string): Observable<TeamStatusSummary[]> {
    return this.http.get<TeamStatusSummary[]>(`api/widget/team-status/exercise/${exerciseId}`).pipe(
      map((dataArray) => {
        return dataArray.map((data) => new TeamStatusSummary(data));
      })
    );
  }

  getSegmentStatusData(exerciseId: string, teamId: string): Observable<SegmentStatusSummary[]> {
    return this.http
      .get<
        SegmentStatusSummary[]
      >(`api/widget/segment-status/exercise/${exerciseId}/team/${teamId}`)
      .pipe(
        map((dataArray) => {
          return dataArray.map((data) => new SegmentStatusSummary(data));
        })
      );
  }

  getTeamAwarenessData(exerciseId: string, teamId: string): Observable<SegmentStatusSummary[]> {
    return this.http
      .get<
        SegmentStatusSummary[]
      >(`api/widget/team-awareness/exercise/${exerciseId}/team/${teamId}`)
      .pipe(
        map((dataArray) => {
          return dataArray.map((data) => new SegmentStatusSummary(data));
        })
      );
  }
}
