import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationsService } from '@cybexer/ngx-commons';
import { finalize } from 'rxjs/operators';
import { ExerciseService, FileService } from '../../../../services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'isa-team-settings-dialog',
  templateUrl: './team-settings-dialog.component.html',
  styleUrls: ['./team-settings-dialog.component.scss'],
})
export class TeamSettingsDialogComponent implements OnInit {
  private static ACCEPTED_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif'];
  private static MAX_FILE_SIZE = 1_000_000; // 1MB
  private static ERR_UNSUPPORTED_FILE_TYPE = 'Unsupported file type';

  exerciseId: string;
  teamId: string;
  teamSettings: any;
  form: UntypedFormGroup;
  loading = false;
  avatarFile?: File;
  avatarSrc: any;
  error: String;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private exerciseService: ExerciseService,
    private fileService: FileService,
    private notificationsService: NotificationsService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<TeamSettingsDialogComponent, boolean>
  ) {}

  ngOnInit() {
    this.exerciseId = this.data['exerciseId'];
    this.teamId = this.data['teamId'];
    this.exerciseService.getTeamSettings(this.exerciseId, this.teamId).subscribe((teamSettings) => {
      this.teamSettings = teamSettings;
      this.form = new UntypedFormGroup({
        teamName: new UntypedFormControl(this.teamSettings.teamName, Validators.required),
      });
    });
  }

  onStaticAvatarSelect(avatarSrc: string) {
    this.avatarSrc = avatarSrc;
    this.error = null;
    this.readStaticAvatarFile(avatarSrc);
  }

  private readStaticAvatarFile(avatarSrc: string) {
    return this.fileService.getBlob(avatarSrc).subscribe((fileBlob) => {
      const filename = avatarSrc.substring(avatarSrc.lastIndexOf('/') + 1);
      this.avatarFile = new File([fileBlob], filename, {
        lastModified: new Date().getTime(),
        type: fileBlob.type,
      });
    });
  }

  getFileInputAcceptAttr() {
    return TeamSettingsDialogComponent.ACCEPTED_EXTENSIONS.join(',');
  }

  get maxFileSize(): number {
    return TeamSettingsDialogComponent.MAX_FILE_SIZE;
  }

  setFile(event): void {
    if (event.target.files.length) {
      this.avatarFile = event.target.files[0];
      this.validate();
      if (!this.error) {
        this.readFile();
      }
    }
  }

  private readFile() {
    if (FileReader && this.avatarFile) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        this.avatarSrc = fileReader.result;
      };
      fileReader.readAsDataURL(this.avatarFile);
    }
  }

  private validate(): void {
    if (this.avatarFile.type.indexOf('image') === -1) {
      this.error = this.translate.instant('ui.exercise.unsupportedFileTypeError');
      return;
    }

    const filename = this.avatarFile.name;
    const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length);
    if (!TeamSettingsDialogComponent.ACCEPTED_EXTENSIONS.includes(extension.toLowerCase())) {
      this.error = this.translate.instant('ui.exercise.unsupportedFileTypeError');
    } else if (this.avatarFile.size > TeamSettingsDialogComponent.MAX_FILE_SIZE) {
      this.error = this.translate.instant('ui.exercise.fileSizeExceedsLimitError');
    } else {
      this.error = null;
    }
  }

  save(): void {
    const teamName = this.form.get('teamName').value;

    if (!teamName || teamName.trim() === '' || this.error) return;

    this.loading = true;

    this.exerciseService
      .saveTeamSettings(this.exerciseId, this.teamId, teamName, this.avatarFile)
      .pipe(finalize(() => (this.loading = false)))
      .subscribe(() => {
        const activeExercise = this.exerciseService.activeExercise.value;
        if (activeExercise != null && activeExercise.id === this.exerciseId) {
          this.exerciseService.selectExercise(this.exerciseId, true);
        }

        this.notificationsService.success('ui.exercise.teamSettingsSaved');
        this.dialogRef.close();
      });
  }
}
