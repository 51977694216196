import { BaseModel } from './base.model';
import { NamedEntity } from '@cybexer/ngx-commons';

export class Preference extends BaseModel implements IPreference {
  defaultExerciseId: string;
  defaultTeamId?: string;
  dateFormat?: string;
  defaultListSize: number;
  isLightTheme: boolean;
  promptOverride?: NamedEntity;

  constructor(data?) {
    super(data);

    if (data && data.hasOwnProperty('promptOverride') && data.promptOverride != null) {
      this.promptOverride = new NamedEntity(data.promptOverride);
    }
  }
}

export interface IPreference {
  defaultExerciseId?: string;
  defaultTeamId?: string;
  dateFormat?: string;
  defaultListSize?: number;
  isLightTheme?: boolean;
  promptOverride?: NamedEntity;
}
