import { BaseModel } from '../shared/base.model';
import { States } from './states.model';
import { VersusAttack } from './versus-attack.model';

export class CampaignLive extends BaseModel {
  teamId: string;
  teamName: string;
  score: number;
  ranking: number;
  states: States;
  targetGroups: CampaignLiveTargetGroup[];
  networkSegments: CampaignLiveNetworkSegment[];

  constructor(data?) {
    super(data);
    if (data.hasOwnProperty('targetGroups')) {
      this.targetGroups = data.targetGroups.map(
        (targetGroup) => new CampaignLiveTargetGroup(targetGroup)
      );
    }

    if (data.hasOwnProperty('networkSegments')) {
      this.networkSegments = data.networkSegments.map(
        (networkSegment) => new CampaignLiveNetworkSegment(networkSegment)
      );
    }

    if (data.hasOwnProperty('states')) {
      this.states = new States(data.states);
    }
  }
}

export class CampaignLiveTargetGroup extends BaseModel {
  name: string;
  isUnderAttack: boolean;
  targets: CampaignLiveTarget[];

  constructor(data?) {
    super(data);
    if (data.hasOwnProperty('targets')) {
      this.targets = data.targets.map((target) => new CampaignLiveTarget(target));
    }
  }
}

export class CampaignLiveNetworkSegment extends BaseModel {
  id: string;
  name: string;
  states: States;
  isUnderAttack: boolean;
  color?: string;

  constructor(data?) {
    super(data);

    if (data.hasOwnProperty('states')) {
      this.states = new States(data.states);
    }
  }
}

export class CampaignLiveTarget extends BaseModel {
  id: string;
  name: string;
  isUnderAttack: boolean;
  activeAttack?: VersusAttack;
  successfulAttacks?: number;
  successfulDefenses?: number;
  isDeployed: boolean;
  networkSegmentId: string;
  targetChecks: CampaignLiveTargetCheck[];

  constructor(data?) {
    super(data);
    if (data.hasOwnProperty('targetChecks')) {
      this.targetChecks = data.targetChecks.map(
        (targetCheck) => new CampaignLiveTargetCheck(targetCheck)
      );
    }
  }
}

export class CampaignLiveTargetCheck extends BaseModel {
  id: string;
  name: string;
  status: string;

  constructor(data?) {
    super(data);
  }
}
