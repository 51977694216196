<div class="notifications">
  <div class="notifications-title">{{ 'ui.nav.gamenetNews' | translate }}</div>
  <div>
    @for (notification of isaNotifications; track notification) {
      <div (click)="redirectToNewsDetails(notification)" class="notification-item">
        @if (notification.type === NotificationType.NEWS_INJECT) {
          <img class="media-thumb" [src]="getMediaUrl(notification)" />
        }
        @if (notification.type !== NotificationType.NEWS_INJECT) {
          <div class="media-thumb">
            <i class="fas fa-exclamation-triangle fa-3x"></i>
          </div>
        }
        <div style="width: 100%">
          <div class="meta-data">
            {{ notification.title }}
            <div class="timestamp">{{ notification.timestamp | isaDate: 'short' }}</div>
          </div>
          <div class="disable-uppercase description truncate">
            {{ notification.description }}
          </div>
        </div>
      </div>
    }
  </div>
  @if (!loading && !isaNotifications?.length) {
    <cybexer-no-data [message]="'ui.nav.noNotifications' | translate"></cybexer-no-data>
  }
</div>
