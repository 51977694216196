import { Directive, ElementRef, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '../services';

@Directive({
  selector: '[isaRequirePermission]',
})
export class RequirePermissionDirective implements OnInit {
  @Input('isaRequirePermission') permission: string | string[];

  /**
   * Generally we do not care for specific targets in this permission check, therefore default is false.
   *
   * User might have a permission for a specific exercise for example, and if we try
   * to check if the user has that permission without specifying a target it would mean that
   * the user needs to have a wildcard for that permission which generally would not be true.
   *
   * This input can be used if we want to check if the user has the permission for the target(s)
   * specified in the input permission(s).
   *
   * Usage: <pre>
   *   <button *isaRequirePermission="permission; withTarget: true">Action</button>
   * </pre>
   */
  // tslint:disable-next-line:no-input-rename
  @Input('isaRequirePermissionWithTarget') withTarget = false;

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    const hasPermission$ =
      this.permission instanceof Array
        ? this.hasAnyPermission()
        : this.authenticationService.hasPermission(this.permission, this.withTarget);

    hasPermission$.subscribe((hasPermission) => {
      if (hasPermission) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }

  private hasAnyPermission(): Observable<boolean> {
    return combineLatest([
      ...(this.permission as string[]).map((perm) => {
        return this.authenticationService.hasPermission(perm, this.withTarget);
      }),
    ]).pipe(map((permissions) => permissions.some((hasPermission) => hasPermission)));
  }
}
