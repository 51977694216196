import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  CampaignLive,
  CampaignLiveNetworkSegment,
  CampaignLiveTargetGroup,
  Exercise,
  ExerciseType,
  User,
} from '../../../../models';
import { AuthenticationService, CampaignLiveService, IntervalService } from '../../../../services';
import { NetworkSegmentDialogComponent } from './network-segment-dialog/network-segment-dialog.component';

@Component({
  selector: 'isa-campaign-live-widget',
  templateUrl: './campaign-live-widget.component.html',
  styleUrls: ['./campaign-live-widget.component.scss'],
})
export class CampaignLiveWidgetComponent implements OnInit, OnChanges {
  dataLoaded;
  campaignLive: CampaignLive;
  isUnderAttack = false;
  attackArrowSize: number;
  targetGroupsUIState: CampaignLiveTargetGroup[] = [];
  currentUser: User;
  networkSegmentDialogRef: MatDialogRef<NetworkSegmentDialogComponent>;
  borderColors = ['#4B5267', '#8661C1', '#BE97C6', '#2E294E', '#EFBCD5', '#635CC1'];
  targetGroupsExpansionStates: boolean[] = [];

  readonly ExerciseType = ExerciseType;

  @Input() exercise: Exercise;
  @Input() teamId: string;
  @Input() isCompactView: boolean;
  @Input() date: Date;

  constructor(
    private campaignLiveService: CampaignLiveService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private intervalService: IntervalService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUser;
    if (this.exercise && this.exercise.type === ExerciseType.UNPUBLISHED) {
      this.intervalService.getWidgetRefreshInterval().subscribe(() => {
        this.getCampaignLive();
      });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.exercise) {
      this.clearCurrentData();
    } else {
      this.getCampaignLive();
    }
  }

  targetGroupsTrackByFn = (index) => index;

  targetsTrackByFn = (index) => index;

  getCampaignLive(): void {
    if (
      this.exercise &&
      this.teamId &&
      (this.exercise.type === ExerciseType.UNPUBLISHED || this.date)
    ) {
      this.campaignLiveService
        .getCampaignLive(this.exercise.id, this.teamId, this.getTimestamp())
        .subscribe({
          next: (campaignLive) => {
            this.campaignLive = campaignLive;
            this.isUnderAttack = campaignLive.targetGroups.some(
              (targetGroup) => targetGroup.isUnderAttack
            );
            this.attackArrowSize = this.calculateAttackArrowSize(this.campaignLive);
            this.updateTargetGroupsUiState(this.campaignLive.targetGroups);
            this.dataLoaded = true;
          },
          error: (error: unknown) => {
            if (!(error instanceof HttpErrorResponse) || error.status !== 404) {
              throw error;
            }
          },
        });
    }
  }

  private getTimestamp() {
    return this.exercise.type === ExerciseType.UNPUBLISHED ? undefined : this.date.toISOString();
  }

  private clearCurrentData() {
    this.dataLoaded = false;
    this.campaignLive = null;
    this.isUnderAttack = false;
    this.targetGroupsExpansionStates = [];
  }

  updateTargetGroupsUiState(targetGroups: CampaignLiveTargetGroup[]): void {
    // Set expansion state for new target groups
    for (let i = this.targetGroupsUIState.length; i < targetGroups.length; i++) {
      this.targetGroupsExpansionStates[i] = targetGroups[i].targets.length > 0;
    }
    this.targetGroupsUIState = targetGroups;
  }

  calculateAttackArrowSize(campaignLive: CampaignLive): number {
    let targetTotal = 0;
    let targetUnderAttack = 0;
    const minArrowSize = 10;
    const arrowConst = 40;
    campaignLive.targetGroups.forEach((targetGroup) => {
      targetTotal += targetGroup.targets.length;
      targetUnderAttack += targetGroup.targets.filter(
        (target) => target.isUnderAttack === true
      ).length;
    });

    return (targetUnderAttack / targetTotal) * arrowConst + minArrowSize;
  }

  isEmpty(campaignLive: CampaignLive): boolean {
    return Object.keys(campaignLive).length === 0;
  }

  getBorderColor(networkSegmentId: string): string {
    const networkSegment = this.campaignLive.networkSegments.filter(
      (ns) => ns.id === networkSegmentId
    )[0];
    return networkSegment.color
      ? networkSegment.color
      : this.borderColors[this.campaignLive.networkSegments.indexOf(networkSegment)];
  }

  openNetworkSegmentDialog(networkSegment: CampaignLiveNetworkSegment): void {
    this.networkSegmentDialogRef = this.dialog.open(NetworkSegmentDialogComponent, {
      disableClose: false,
      data: {
        networkSegment: networkSegment,
        exerciseId: this.exercise.id,
        segmentIndex: this.campaignLive.networkSegments.indexOf(networkSegment),
      },
    });

    this.networkSegmentDialogRef.afterClosed().subscribe((color: string) => {
      if (color) {
        networkSegment.color = color;
      }
      this.networkSegmentDialogRef = null;
    });
  }

  getNumberOfTargets(): number {
    return this.campaignLive.targetGroups
      .map((targetGroup) => targetGroup.targets.length)
      .reduce((sum, numberOfTargets) => sum + numberOfTargets);
  }

  getTargetChecksPercentageStatus(percentage: number) {
    return Number.isNaN(percentage) ? '-' : percentage + '%';
  }
}
