export abstract class AccessTokenUrlValidator {
  private static ACCESS_TOKEN = 'accessToken';
  private static REDIRECT_URL = 'redirectUrl';

  static getAccessToken(url: string): string {
    return AccessTokenUrlValidator.getQueryParameter(url, AccessTokenUrlValidator.ACCESS_TOKEN);
  }

  static getRedirectUrl(url: string): string {
    const base64EncodedRedirectUrl = AccessTokenUrlValidator.getQueryParameter(
      url,
      AccessTokenUrlValidator.REDIRECT_URL
    );
    if (base64EncodedRedirectUrl == null) {
      return null;
    }

    return decodeURIComponent(escape(atob(base64EncodedRedirectUrl)));
  }

  static hasAccessToken(url: string): boolean {
    return AccessTokenUrlValidator.getAccessToken(url) != null;
  }

  private static getQueryParameter(url: string, queryParameterName: string): string {
    const splitByQuestionMarkUrl = decodeURIComponent(url).split('?');
    if (splitByQuestionMarkUrl.length != 2) {
      return null;
    }

    const queryParamPairs = splitByQuestionMarkUrl[1].split('&');
    const queryParamPair = queryParamPairs.find((pair) => pair.includes(queryParameterName));
    if (queryParamPair == null) {
      return null;
    }

    return queryParamPair.split(`${queryParameterName}=`)[1];
  }
}
