import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseChartDirective } from 'ng2-charts';
import { Observable, Subscription } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
import { BlueTeam, Exercise, ExerciseType, TeamTimelineData, TimelineScore } from '../../../models';
import { Settings } from '../../../models/shared/settings.model';
import { DatePipe } from '../../../pipes';
import {
  AuthenticationService,
  ExerciseService,
  IntervalService,
  PreferenceService,
  SettingsService,
  TeamTimelineService,
} from '../../../services';
import { FilterStateModel, FilterStateService, RoleCode, ROLES } from '../../../shared';
import {
  ChartBorderColorConfig,
  ChartColorConfigurator,
  TEAM_TIMELINE_CHART_OPTIONS,
} from '../shared';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'isa-team-timeline',
  templateUrl: './team-timeline.component.html',
  styleUrls: ['./team-timeline.component.scss'],
})
export class TeamTimelineComponent implements OnInit, AfterViewChecked {
  @ViewChild('scoringChart')
  scoringChart: BaseChartDirective;

  filter$: Observable<Partial<FilterStateModel>>;

  exerciseId: string;
  chartLabels: string[] = [];
  chartData: any = [];
  isDataLoaded = false;
  loading = true;
  lineChartOptions;
  lineChartColors: Array<ChartBorderColorConfig> = [];
  chartHeight: number;
  teams: BlueTeam[] = [];
  ROLES = ROLES;
  userRole: RoleCode;
  isIndividualAssessment: boolean;
  isCTF: boolean;
  isBeta: boolean = false;
  private timer: Subscription;

  constructor(
    private teamTimelineService: TeamTimelineService,
    private exerciseService: ExerciseService,
    private settingsService: SettingsService,
    private intervalService: IntervalService,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private preferenceService: PreferenceService,
    public filterStateService: FilterStateService,
    public router: Router
  ) {}

  ngOnInit() {
    this.filter$ = this.filterStateService.filter$('team');
    this.isBeta = this.router.url.endsWith('-beta');
    this.exerciseService.activeExercise
      .pipe(
        filter((exercise: Exercise) => !!exercise),
        untilDestroyed(this)
      )
      .subscribe((exercise: Exercise) => {
        this.exerciseId = exercise.id;
        this.isCTF = exercise.type === ExerciseType.CTF;
        this.isIndividualAssessment = exercise.isIndividualAssessment;
        this.userRole = this.authenticationService.getRole(exercise.id);
        this.teams = exercise.blueTeams;
        if (this.userRole === this.ROLES.BLUE) {
          this.exerciseService
            .getUserBlueTeam(exercise.id)
            .pipe(untilDestroyed(this))
            .subscribe((userBlueTeamId) => {
              this.filterStateService.setFilterIfEmptyOrDefault('team', userBlueTeamId);
            });
        }
      });

    this.filter$.pipe(untilDestroyed(this)).subscribe(() => {
      this.processData();
    });

    this.preferenceService
      .getPreferences()
      .pipe(first())
      .subscribe((pref) => {
        this.lineChartOptions = TEAM_TIMELINE_CHART_OPTIONS(pref.isLightTheme);
      });

    this.translate.onLangChange.pipe(untilDestroyed(this)).subscribe(() => {
      this.processData();
    });
  }

  ngAfterViewChecked() {
    const contentElement = <HTMLElement>document.getElementsByClassName('isa-content-body')[0];
    if (contentElement) {
      this.chartHeight = contentElement.offsetHeight - 170;
      this.cdr.detectChanges();
    }
  }

  processData(): void {
    const teamId = this.filterStateService.snapshot().team;
    this.isDataLoaded = false;
    if (this.exerciseId && teamId) {
      this.teamTimelineService
        .getTeamTimelineData(this.exerciseId, teamId, this.isBeta)
        .pipe(untilDestroyed(this))
        .subscribe((data) => {
          if (data.length) {
            this.setLineChartLabels(data);
            this.setLineChartData(data);
            this.updateChart();
            this.isDataLoaded = true;
          }
          this.loading = false;
          this.getLatestScoringSummaryTimeLineDataItem(this.exerciseId, teamId);
        });
    }
  }

  getLatestScoringSummaryTimeLineDataItem(exerciseId: string, teamId: string): void {
    if (this.timer) {
      this.timer.unsubscribe();
    }

    this.timer = this.settingsService
      .getSettings(true)
      .pipe(
        switchMap((settings: Settings) => {
          return this.intervalService.getIntervalWithDelay(
            settings.gamenetSettings.scoringTimelineWidgetRefreshInterval
          );
        }),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.teamTimelineService
          .getTeamTimelineLatestData(exerciseId, teamId, this.isBeta)
          .pipe(untilDestroyed(this))
          .subscribe((data) => {
            if (data.length && data[0].scores.length && !this.dataItemAlreadyExists(data)) {
              this.addLatestTimeLabel(data);
              this.addLatestChartValue(data);
              this.updateChart();
            } else if (
              data.length &&
              data[0].scores.length &&
              this.isFirstChartLabelWithEndexWord()
            ) {
              this.updateLatestEndexScoreIfChanged(data);
            }
          });
      });
  }

  dataItemAlreadyExists(data: TeamTimelineData[]): boolean {
    if (data[0].scores[0].isEndex) {
      return this.isFirstChartLabelWithEndexWord();
    }

    return (
      this.chartLabels.length > 0 &&
      this.chartLabels[this.chartLabels.length - 1] ===
        this.datePipe.transform(data[0].scores[0].timestamp, 'short')
    );
  }

  private isFirstChartLabelWithEndexWord(): boolean {
    return (
      this.chartLabels.length > 0 && this.chartLabels[this.chartLabels.length - 1].includes('ENDEX')
    );
  }

  updateLatestEndexScoreIfChanged(data: TeamTimelineData[]): void {
    data.map((item) => {
      this.chartData.map((teamItem) => {
        if (
          item.scoreType === teamItem.scoreType &&
          item.scores[0].value !== teamItem.data[teamItem.data.length - 2]
        ) {
          teamItem.data[teamItem.data.length - 2] = item.scores[0].value;
          this.updateChart();
        }
      });
    });
  }

  setLineChartLabels(data: TeamTimelineData[]): void {
    this.chartLabels = [];
    data[0].scores.forEach((score) => {
      this.chartLabels.push(this.datePipe.transform(score.timestamp, 'short'));
      if (score.isEndex) {
        this.chartLabels.push('ENDEX ' + this.datePipe.transform(score.timestamp, 'shortTime'));
      }
    });
  }

  addLatestTimeLabel(data: TeamTimelineData[]): void {
    if (data) {
      this.chartLabels.push(this.datePipe.transform(data[0].scores[0].timestamp, 'short'));
      if (data[0].scores[0].isEndex) {
        this.chartLabels.push(
          'ENDEX ' + this.datePipe.transform(data[0].scores[0].timestamp, 'shortTime')
        );
      }
    }
  }

  addLatestChartValue(data: TeamTimelineData[]): void {
    if (data) {
      data.map((item) => {
        this.chartData.map((teamItem) => {
          if (item.scoreType === teamItem.scoreType) {
            teamItem.data.push(item.scores[0].value);
            if (item.scores[0].isEndex) {
              teamItem.data.push(null);
            }
          }
        });
      });
    }
  }

  setLineChartData(data: TeamTimelineData[]): void {
    const colorsSet = this.lineChartColors.length > 0;
    this.chartData = [];
    data.map((item, index) => {
      if (!colorsSet) {
        this.lineChartColors.push(
          ChartColorConfigurator.createBorderColorConfig(item.scoreType, this.isCTF)
        );
      }

      this.chartData.push({
        data: this.addChartDataValues(item.scores),
        scoreType: item.scoreType,
        label: this.translate.instant('ui.enums.' + item.scoreType),
        backgroundColor: this.lineChartColors[index]?.backgroundColor,
        borderColor: this.lineChartColors[index]?.borderColor,
      });
    });
  }

  addChartDataValues(scores: TimelineScore[]): any {
    const result = [];
    scores.forEach((score) => {
      result.push(score.value);
      if (score.isEndex) {
        result.push(null);
      }
    });

    return result;
  }

  onResize(event): void {
    this.chartHeight = event.target.innerHeight - 294;
  }

  updateChart(): void {
    if (this.scoringChart && this.scoringChart.chart) {
      this.scoringChart.chart.config.data.labels = this.chartLabels;
      this.scoringChart.chart.update();
    }
  }
}
