import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AccessTokenUrlValidator } from '../models';
import { AuthenticationService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  private static LANDING_PAGE = '/app/landing';
  private static LOGIN_PAGE = '/intro/login';
  private static OBSERVE_CAMPAIGN_LIVE = 'campaign-live/observe';
  private static OBSERVE_PODIUM = 'podium/observe';

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (
      AccessTokenUrlValidator.hasAccessToken(state.url) ||
      this.isNavigatingToObservablePages(state.url)
    ) {
      return of(true);
    }

    const goingToLoginPage = state.url.indexOf(AuthGuard.LOGIN_PAGE) !== -1;
    return this.authenticationService.isUserLoggedIn().pipe(
      tap((isLoggedIn: boolean) => {
        if (!isLoggedIn && !goingToLoginPage) {
          this.navigateToLoginPage(state.url);
        } else if (isLoggedIn && goingToLoginPage) {
          this.navigateToLandingPage();
        }
      }),
      map((isUserAuthenticated: boolean) => (goingToLoginPage ? true : isUserAuthenticated))
    );
  }

  private isNavigatingToObservablePages(url: string) {
    return url.includes(AuthGuard.OBSERVE_CAMPAIGN_LIVE) || url.includes(AuthGuard.OBSERVE_PODIUM);
  }

  private navigateToLoginPage(returnUrl: string) {
    this.router.navigate([AuthGuard.LOGIN_PAGE], { queryParams: { fwd: returnUrl } });
  }

  private navigateToLandingPage() {
    this.router.navigateByUrl(AuthGuard.LANDING_PAGE);
  }
}
