<div class="incident-report-confirm-dialog">
  <h2 matDialogTitle>Incident reports</h2>
  <mat-dialog-content class="incident-report-confirm-dialog-content">
    @if (!loaded) {
      <cybexer-progress-spinner></cybexer-progress-spinner>
    }
    <table class="isa-table">
      <thead>
        <tr>
          <th>Time</th>
          <th>Target</th>
          <th>Incident Type</th>
          <th>Details</th>
        </tr>
      </thead>
      <tbody>
        @for (incidentReport of reportingStatusIncidentReports; track incidentReport) {
          <tr>
            <td>{{ incidentReport.timestamp | isaDate: 'medium' }}</td>
            <td>{{ incidentReport.targetId | isaTargetName: exercise }}</td>
            <td>{{ incidentReport.incidentType | isaIncidentTypeName }}</td>
            <td>
              <quill-editor
                [ngModel]="incidentReport.details"
                [readOnly]="true"
                [modules]="{ toolbar: false }"
                ngDefaultControl
              ></quill-editor>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button matDialogClose>Close</button>
  </mat-dialog-actions>
</div>
