import { Component, Input, OnInit } from '@angular/core';
import { MitreDisplayObject, MitreTags } from '../../../../models';
import { MitreIntegrationService } from '../../../../services';

@Component({
  selector: 'isa-mitre-container',
  templateUrl: './mitre-container.component.html',
  styleUrls: ['./mitre-container.component.scss'],
})
export class MitreContainerComponent implements OnInit {
  @Input() tags: MitreTags;
  @Input() largeLabel: boolean = false;
  displayData: MitreDisplayObject[];

  constructor(private mitreService: MitreIntegrationService) {}

  ngOnInit(): void {
    this.mitreService.getMitreDisplayData(this.tags).subscribe((data) => {
      this.displayData = data;
    });
  }
}
