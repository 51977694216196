import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ExerciseGroup, ExerciseGroupSave } from '../../models/gamenet/exercise-group.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SystemEvent } from '../../models/gamenet/exercise.model';

@Injectable({ providedIn: 'root' })
export class ExerciseGroupService {
  constructor(private http: HttpClient) {}

  getGroups(): Observable<ExerciseGroup[]> {
    return this.http
      .get<ExerciseGroup[]>('api/exercise-groups')
      .pipe(map((dataArray) => dataArray.map((data) => new ExerciseGroup(data))));
  }

  createGroup(group: ExerciseGroupSave): Observable<ExerciseGroup> {
    return this.http
      .post('api/exercise-groups', group)
      .pipe(map((data) => new ExerciseGroup(data)));
  }

  updateGroup(group: ExerciseGroupSave): Observable<ExerciseGroup> {
    return this.http.put('api/exercise-groups', group).pipe(map((data) => new ExerciseGroup(data)));
  }

  deleteGroup(groupId: string): Observable<boolean> {
    return this.http.delete('api/exercise-groups/' + groupId).pipe(map(() => true));
  }

  createSystemEvents(exerciseIds: string[], event: SystemEvent): Observable<boolean> {
    return this.http
      .post('api/system-event/exercise-group', {
        exerciseIds: exerciseIds,
        ...event,
      })
      .pipe(map(() => true));
  }
}
