import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { RouterModule } from '@angular/router';
import { NgxCommonsModule } from '@cybexer/ngx-commons';
import { QuillModule } from 'ngx-quill';
import { IsaPipesModule } from '../pipes';
import { SharedComponentsModule } from '../shared/components/shared-components.module';
import { LandingComponent, LoginComponent } from './';
import { PasswordChangeDialogComponent } from './shared/password-change-dialog/password-change-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatProgressBarModule,
    MatDialogModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    FormsModule,
    SharedComponentsModule,
    NgxCommonsModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          ['code-block'],

          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

          [{ size: ['10px', false, '18px', '32px'] }], // custom dropdown

          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ align: [] }],

          ['link', 'image'], // link and image, video
          ['clean'],
        ],
      },
    }),
    MatMenuModule,
    RouterModule,
    IsaPipesModule,
    MatSelectModule,
    TranslateModule,
  ],
  providers: [],
  declarations: [LoginComponent, LandingComponent, PasswordChangeDialogComponent],
})
export class ViewsModule {}
