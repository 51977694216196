import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ExerciseGroup } from '../../../../models/gamenet/exercise-group.model';
import { MatDialog } from '@angular/material/dialog';
import { ExerciseConfirmDialogComponent } from '../exercise-confirm-dialog/exercise-confirm-dialog.component';
import { ExerciseGroupService } from '../../../../services/gamenet/exercise-group.service';
import { NotificationsService } from '@cybexer/ngx-commons';
import { MatExpansionPanel } from '@angular/material/expansion';
import { ExerciseOverview, ExerciseStatus, ExerciseType } from '../../../../models';
import { EXERCISE_PERMISSIONS } from '../../../../shared';

@UntilDestroy()
@Component({
  selector: 'isa-exercise-group',
  templateUrl: './exercise-group.component.html',
  styleUrls: ['./exercise-group.component.scss'],
})
export class ExerciseGroupComponent implements OnChanges {
  isEditing = false;
  initialGroup?: ExerciseGroup;

  @Input() group: ExerciseGroup;
  @Input() openPanel = false;
  @Output() removeGroupEvent = new EventEmitter();
  @Output() saveGroupEvent = new EventEmitter();
  @Output() editGroupEvent = new EventEmitter();
  @Output() removeExerciseEvent = new EventEmitter<string>();

  @ViewChild('groupPanel') exercisePanel: MatExpansionPanel;

  nameForm = new UntypedFormControl(undefined, Validators.required);

  protected readonly ExerciseType = ExerciseType;
  protected readonly EXERCISE_PERMISSIONS = EXERCISE_PERMISSIONS;
  protected readonly ExerciseStatus = ExerciseStatus;

  constructor(
    private dialog: MatDialog,
    private exerciseGroupService: ExerciseGroupService,
    private notificationsService: NotificationsService
  ) {}

  ngOnChanges() {
    if (this.exercisePanel) {
      this.openPanel ? this.exercisePanel.open() : this.exercisePanel.close();
    }
  }

  createItem() {
    const nameValue = this.nameForm.value;
    if (nameValue.trim().length > 0) {
      this.group.name = nameValue;
      this.saveGroupEvent.emit();
    } else {
      this.notificationsService.error('ui.exercise.groupNoNameError');
    }
  }

  cancelEdit() {
    this.isEditing = false;
    this.group = this.initialGroup;
    this.editGroupEvent.emit(true);
  }

  removeItem() {
    this.removeGroupEvent.emit();
  }

  openExerciseConfirmDialog(exerciseGroup: ExerciseGroup, eventType: string, $event: any) {
    $event.stopPropagation();
    const exerciseDialog = this.dialog.open(ExerciseConfirmDialogComponent, {
      disableClose: false,
      data: {
        exerciseGroup,
        eventType,
      },
    });

    exerciseDialog.afterClosed().subscribe((type: string) => {
      const status = type === 'STARTEX' ? 'RUNNING' : type === 'ENDEX' ? 'STOPPED' : null;
      if (status == null) return;
      this.group.exercises.forEach((exercise) => (exercise.status = status));
    });
  }

  editGroup() {
    this.isEditing ? this.exercisePanel.open() : this.exercisePanel.close();
    this.isEditing = !this.isEditing;
    if (this.isEditing) {
      this.initialGroup = this.group;
    }
    this.editGroupEvent.emit();
  }

  deleteGroup() {
    if (this.group.id == null) {
      this.removeGroupEvent.emit();
      return;
    }
    this.exerciseGroupService
      .deleteGroup(this.group.id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (ungrouped) => {
          this.notificationsService.success('ui.exercise.groupDeletedSuccessfully');
          this.removeGroupEvent.emit(ungrouped);
        },
        error: () => {
          this.notificationsService.error('ui.exercise.groupDeleteFailed');
        },
      });
  }

  removeExerciseFromGroup(exerciseId: string) {
    this.removeExerciseEvent.emit(exerciseId);
  }

  findExerciseOverviewById(id: string): ExerciseOverview {
    return this.group.exercises.find((exercise) => exercise.id === id);
  }

  hasStatus(status: string): boolean {
    return this.group.exercises.some((exercise) => exercise.status == status);
  }
}
