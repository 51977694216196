import { BaseModel } from '../shared/base.model';

export class TimelineScore extends BaseModel {
  timestamp: string;
  value: number;
  isEndex: boolean;

  constructor(data?) {
    super(data);
  }
}
