<cybexer-widget-header [title]="'ui.userSettings.settings' | translate"></cybexer-widget-header>
<cybexer-widget-body>
  <div>
    <mat-form-field class="date-format-dropdown">
      <mat-label>{{ 'ui.userSettings.datetimeFormat' | translate }}</mat-label>
      <mat-select [(ngModel)]="dateFormat" (ngModelChange)="changeDateFormat($event)">
        <mat-option value="de"> dd.mm.yyyy 24H</mat-option>
        <mat-option value="en-US"> mm/dd/yyyy AM/PM</mat-option>
        <mat-option value="zh-cn"> yyyy/mm/dd 24H</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="number-format-dropdown">
      <mat-label>{{ 'ui.userSettings.defaultListSize' | translate }}</mat-label>
      <mat-select
        [(value)]="defaultListSize"
        [(ngModel)]="defaultListSize"
        (ngModelChange)="changeDefaultListSize($event)"
      >
        @for (size of pageSizes; track size) {
          <div>
            <mat-option [value]="size"> {{ size }}</mat-option>
          </div>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <label class="radio-group-label">{{ 'ui.userSettings.selectTheme' | translate }}</label>
    <mat-radio-group
      label="Select theme"
      [(ngModel)]="isLightTheme"
      (ngModelChange)="switchTheme()"
    >
      <mat-radio-button [checked]="!isLightTheme" [value]="false">{{
        'ui.userSettings.darkTheme' | translate
      }}</mat-radio-button>
      <mat-radio-button [checked]="isLightTheme" [value]="true">{{
        'ui.userSettings.lightTheme' | translate
      }}</mat-radio-button>
    </mat-radio-group>
  </div>
</cybexer-widget-body>
