import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isaFixedNumber',
})
export class FixedNumberPipe implements PipeTransform {
  constructor() {}

  transform(value: number, fractionDigits?: number) {
    if (typeof value === 'number') {
      switch (fractionDigits) {
        case 4:
          return value.toFixed(4);
        default:
          return value.toFixed(0);
      }
    }
  }
}
