import { Component } from '@angular/core';
import { ControlContainer, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { CustomValidators, PasswordErrorStateMatcher } from '../../index';

@Component({
  selector: 'isa-password-fields',
  templateUrl: './password-fields.component.html',
  styleUrls: ['./password-fields.component.scss'],
})
export class PasswordFieldsComponent {
  hidePassword = true;
  passwordErrorStateMatcher = new PasswordErrorStateMatcher();
  passwordGroup: UntypedFormGroup;

  constructor(public controlContainer: ControlContainer) {}

  getPasswordErrorMessage() {
    const pswControl = this.controlContainer.control.get('password') as UntypedFormControl;
    return CustomValidators.getPasswordErrorMsg(pswControl);
  }

  togglePasswordVisibility(e) {
    e.stopPropagation();
    this.hidePassword = !this.hidePassword;
  }

  static createFormGroup(): UntypedFormGroup {
    return new UntypedFormGroup(
      {
        password: new UntypedFormControl('', CustomValidators.passwordValidators),
        confirmPassword: new UntypedFormControl(''),
      },
      {
        validators: CustomValidators.passwordsMatch(),
      }
    );
  }
}
