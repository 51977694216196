import { BaseModel } from '../shared/base.model';
import { ScoreType } from './score-type.model';

export class UserScoringData extends BaseModel {
  username: string;
  teamName: string;
  scores: UserScore[];

  constructor(data?) {
    super(data);

    if (data.hasOwnProperty('scores')) {
      this.scores = data.scores.map((score) => new UserScore(score));
    }
  }
}

export class UserScore extends BaseModel {
  category: ScoreType;
  value: number;

  constructor(data?) {
    super(data);
  }
}
