import { BaseModel } from '../shared/base.model';
import { Meta } from '../shared/meta.model';

export class AdvancedScoringLogData extends BaseModel {
  meta: Meta;
  data: AdvancedScoringLogItem[];

  constructor(data?) {
    super(data);

    if (data.hasOwnProperty('meta')) {
      this.meta = new Meta(data.meta);
    }

    if (data.hasOwnProperty('data')) {
      this.data = data.data.map((scoringLogItem) => new AdvancedScoringLogItem(scoringLogItem));
    }
  }
}

export class AdvancedScoringLogItem extends BaseModel {
  id: string;
  category: string;
  teamId: string;
  teamName: string;
  targetObjectType: string;
  targetObjectName: string;
  score: number;
  timestamp: Date;

  constructor(data?) {
    super(data);
  }
}
