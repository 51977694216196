@if (
  niceIds?.taskIds?.length ||
  niceIds?.knowledgeIds?.length ||
  niceIds?.skillIds?.length ||
  niceIds?.abilityIds?.length
) {
  @if (!largeLabel) {
    <div class="tags-title-small">NICE IDs</div>
  }
  @if (largeLabel) {
    <div class="tags-title">NICE IDs</div>
  }
  <div class="tags-container">
    @if (largeLabel && niceIds.taskIds?.length) {
      <div class="tags-title-small">Tasks</div>
    }
    @for (id of niceIds.taskIds; track id) {
      <cybexer-tag
        [label]="id"
        [title]="id"
        [description]="id | isaNiceDescription: 'tasks'"
      ></cybexer-tag>
    }
    @if (largeLabel && niceIds.taskIds?.length) {
      <div class="tags-title-small">Knowledge</div>
    }
    @for (id of niceIds.knowledgeIds; track id) {
      <cybexer-tag
        [label]="id"
        [title]="id"
        [description]="id | isaNiceDescription: 'knowledge'"
      ></cybexer-tag>
    }
    @if (largeLabel && niceIds.taskIds?.length) {
      <div class="tags-title-small">Skills</div>
    }
    @for (id of niceIds.skillIds; track id) {
      <cybexer-tag
        [label]="id"
        [title]="id"
        [description]="id | isaNiceDescription: 'skills'"
      ></cybexer-tag>
    }
    @if (largeLabel && niceIds.taskIds?.length) {
      <div class="tags-title-small">Abilities</div>
    }
    @for (id of niceIds.abilityIds; track id) {
      <cybexer-tag
        [label]="id"
        [title]="id"
        [description]="id | isaNiceDescription: 'abilities'"
      ></cybexer-tag>
    }
  </div>
}
