<div [formGroup]="controlContainer.control" class="password-fields-container">
  <div class="isa-mat-form-row">
    <mat-form-field class="full-width">
      <mat-label>{{ 'ui.password.password' | translate }}</mat-label>
      <input
        matInput
        [type]="hidePassword ? 'password' : 'text'"
        formControlName="password"
        required
        data-testid="passwordField"
      />
      @if (
        controlContainer.control.get('password').invalid &&
        controlContainer.control.get('password').dirty
      ) {
        <span matSuffix matTooltip="'ui.password.passwordTooltip' | translate">
          <i [ngClass]="'fas fa-exclamation-triangle'" aria-hidden="true"></i>
        </span>
      }
      <button
        type="button"
        matSuffix
        mat-icon-button
        (click)="togglePasswordVisibility($event)"
        [disableRipple]="true"
        tabindex="-1"
        data-testid="visibilityButton"
      >
        <i [ngClass]="'far fa-eye' + (hidePassword ? '-slash' : '')" aria-hidden="true"></i>
      </button>

      @if (controlContainer.control.get('password').invalid) {
        <mat-error>
          {{ getPasswordErrorMessage() }}
        </mat-error>
      }
    </mat-form-field>
  </div>
  <div class="isa-mat-form-row">
    <mat-form-field class="full-width">
      <mat-label>{{ 'ui.password.confirmPassword' | translate }}</mat-label>
      <input
        matInput
        type="password"
        formControlName="confirmPassword"
        [errorStateMatcher]="passwordErrorStateMatcher"
      />
      @if (controlContainer.control.hasError('invalidConfirm')) {
        <mat-error> {{ 'ui.password.passwordMismatch' | translate }} </mat-error>
      }
    </mat-form-field>
  </div>
</div>
