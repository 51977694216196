import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge, Observable, of, Subject } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { Preference, UserListItem } from '../../../models';
import { PreferenceService, UsersService } from '../../../services';
import { DEFAULT_PAGE_SIZE, PAGE_SIZES } from '../../../shared';

enum Columns {
  SELECT = 'select',
  USERNAME = 'username',
  FULLNAME = 'fullName',
  ACTIONS = 'actions',
}

@UntilDestroy()
@Component({
  selector: 'isa-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss'],
})
export class UsersTableComponent implements OnInit {
  @Input() users: Observable<UserListItem[]>;
  @Input() usersChanged$ = new Subject<boolean>();

  @ContentChild('singleUserActions', { static: true }) singleUserActions?: TemplateRef<ElementRef>;
  @ContentChild('multiUserActions', { static: true }) multiUserActions?: TemplateRef<ElementRef>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  usersDatasource = new MatTableDataSource<UserListItem>();
  selection = new SelectionModel<UserListItem>(true, []);
  displayedColumns: string[] = [];
  isLoadingResults = true;

  readonly DEFAULT_COLUMNS = [Columns.SELECT, Columns.USERNAME, Columns.FULLNAME, Columns.ACTIONS];

  constructor(
    private usersService: UsersService,
    private preferenceService: PreferenceService
  ) {}

  ngOnInit() {
    this.initDatasource();
    this.initDisplayedColumns();

    this.preferenceService
      .getPreferences()
      .pipe(untilDestroyed(this))
      .subscribe((preference: Preference) => {
        this.paginator.pageSize = preference.defaultListSize || DEFAULT_PAGE_SIZE;
      });

    this.usersDatasource.paginator = this.paginator;
    this.usersDatasource.sort = this.sort;
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
  }

  initDisplayedColumns() {
    this.displayedColumns = this.DEFAULT_COLUMNS.filter((col) => {
      switch (col) {
        case Columns.SELECT:
          return !!this.multiUserActions;
        case Columns.ACTIONS:
          return !!this.multiUserActions || !!this.singleUserActions;
        default:
          return true;
      }
    });
  }

  initDatasource() {
    // TODO Provide sorting/paging information to service call
    const users$ = this.users ? this.users : this.usersService.getUsers();
    merge(this.sort.sortChange, this.paginator.page, this.usersChanged$)
      .pipe(
        startWith([]),
        switchMap(() => {
          this.isLoadingResults = true;
          return users$;
        }),
        map((users) => {
          this.isLoadingResults = false;
          return users;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          return of([]);
        }),
        untilDestroyed(this)
      )
      .subscribe((users) => (this.usersDatasource.data = users));
  }

  isAllSelected() {
    return this.getCurrentPageData().every((user) => this.selection.isSelected(user));
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.getCurrentPageData().forEach((row) => this.selection.select(row));
  }

  private getCurrentPageData(): UserListItem[] {
    const { pageIndex, pageSize } = this.usersDatasource.paginator;
    const firstItemIndex = pageIndex * pageSize;
    const lastItemIndex = firstItemIndex + pageSize;
    return this.usersDatasource.data.slice(firstItemIndex, lastItemIndex);
  }

  get pageSizes(): number[] {
    return PAGE_SIZES;
  }
}
