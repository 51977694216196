<cybexer-widget-header
  [title]="
    (isIndividualAssessment ? 'ui.scoring.userScoringTimeline' : 'ui.scoring.teamScoringTimeline')
      | translate
  "
  [inline]="true"
>
  <isa-filtered-select
    [options]="teams"
    [preselectedOptions]="(filter$ | async).team"
    [optionKey]="'customName'"
    [optionValue]="'id'"
    [label]="(isIndividualAssessment ? 'ui.user' : 'ui.team') | translate"
    [disabled]="userRole === ROLES.BLUE"
    (selectionChange)="filterStateService.setFilter('team', $event)"
  ></isa-filtered-select>
</cybexer-widget-header>

<div class="chart-container">
  @if (isDataLoaded) {
    <div class="team-timeline-chart">
      <canvas
        baseChart
        #scoringChart="base-chart"
        [datasets]="chartData"
        [labels]="chartLabels"
        [options]="lineChartOptions"
        [type]="'line'"
        [style.height.px]="chartHeight"
        (window:resize)="onResize($event)"
      >
      </canvas>
    </div>
  }
  @if (!loading && !chartData.length) {
    <cybexer-no-data></cybexer-no-data>
  }
  @if (!(filter$ | async).team) {
    <div class="team-select-info">{{ 'ui.scoring.selectTeam' | translate }}</div>
  }
  @if (loading && (filter$ | async).team) {
    <cybexer-progress-spinner></cybexer-progress-spinner>
  }
</div>
