import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ReportData, ReportFilter, ReportFilterResult } from '../../models';
import { ReportsService } from '../../services';

export abstract class ReportsDatasource<T extends ReportData, R extends ReportFilterResult<T>>
  implements DataSource<T>
{
  /* eslint-disable  @typescript-eslint/member-ordering */
  private reportsSubject = new BehaviorSubject<T[]>([]);
  private totalCountSubject = new BehaviorSubject<number>(0);
  private filteredCountSubject = new BehaviorSubject<number>(0);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  totalCount$ = this.totalCountSubject.asObservable();
  filteredCount$ = this.filteredCountSubject.asObservable();
  loading$ = this.loadingSubject.asObservable();

  get data(): T[] {
    return this.reportsSubject.getValue();
  }

  constructor(private reportsService: ReportsService<T>) {}

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return this.reportsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.reportsSubject.complete();
    this.totalCountSubject.complete();
    this.filteredCountSubject.complete();
    this.loadingSubject.complete();
  }

  loadReports(exerciseId, filter: ReportFilter, loadSilently: boolean = false) {
    if (!loadSilently) {
      this.loadingSubject.next(true);
    }

    this.reportsService
      .getData(exerciseId, filter)
      .pipe(
        catchError(() => {
          console.error('Could not fetch reports');
          return of({ totalCount: -1, filteredCount: -1, reports: null } as R);
        }),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe((data: ReportFilterResult<T>) => {
        const totalCount = this.calculateNewCountValue(
          this.totalCountSubject.getValue(),
          data.totalCount
        );
        const filteredCount = this.calculateNewCountValue(
          this.filteredCountSubject.getValue(),
          data.filteredCount
        );
        const reports = this.calculateNewReportsData(this.reportsSubject.getValue(), data.reports);

        this.totalCountSubject.next(totalCount);
        this.filteredCountSubject.next(filteredCount);
        this.reportsSubject.next(reports);
      });
  }

  private calculateNewCountValue(previousValue: number, newValue: number): number {
    if (newValue !== -1) {
      return newValue;
    }

    if (previousValue > 0) {
      return previousValue;
    }

    return 0;
  }

  private calculateNewReportsData(previousValue: T[], newValue?: T[]): T[] {
    if (newValue != null) {
      return newValue;
    }

    if (previousValue.length > 0) {
      return previousValue;
    }

    return [];
  }
}
