import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductKey, ProductKeySaveDTO } from '../../models/shared/product-key.model';

@Injectable()
export class ProductKeyService {
  constructor(private http: HttpClient) {}

  getProductKey(): Observable<ProductKey> {
    return this.http.get<ProductKey>('api/product-keys').pipe(map((data) => new ProductKey(data)));
  }

  saveProductKey(saveDTO: ProductKeySaveDTO): Observable<ProductKey> {
    return this.http
      .put<ProductKey>('api/product-keys', saveDTO)
      .pipe(map((data) => new ProductKey(data)));
  }
}
