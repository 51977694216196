import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { BlueTeam, Exercise } from '../../../../models';
import {
  AuthenticationService,
  ExerciseService,
  IntervalService,
  StatusSummaryService,
} from '../../../../services';
import { FilterStateModel, FilterStateService, RoleCode, ROLES } from '../../../../shared';
import { SegmentStatusChartData } from '../status-chart-data';

@UntilDestroy()
@Component({
  selector: 'isa-team-awareness',
  templateUrl: './team-awareness.component.html',
  styleUrls: ['./team-awareness.component.scss'],
})
export class TeamAwarenessComponent implements OnInit {
  filter$: Observable<Partial<FilterStateModel>>;

  dataLoaded: boolean;
  segmentStatusChartData: SegmentStatusChartData = new SegmentStatusChartData();
  exerciseId: string;
  teams: BlueTeam[] = [];
  ROLES = ROLES;
  userRole: RoleCode;

  constructor(
    private statusSummaryService: StatusSummaryService,
    private exerciseService: ExerciseService,
    private intervalService: IntervalService,
    private authenticationService: AuthenticationService,
    public filterStateService: FilterStateService
  ) {}

  ngOnInit(): void {
    this.filter$ = this.filterStateService.filter$('team');
    this.exerciseService.activeExercise
      .pipe(
        filter((exercise: Exercise) => !!exercise),
        untilDestroyed(this)
      )
      .subscribe((exercise: Exercise) => {
        this.dataLoaded = false;
        this.segmentStatusChartData.clearData();
        this.exerciseId = exercise.id;
        this.teams = exercise.blueTeams;
        this.userRole = this.authenticationService.getRole(exercise.id);
        if (this.userRole === this.ROLES.BLUE) {
          this.exerciseService
            .getUserBlueTeam(exercise.id)
            .pipe(first())
            .subscribe((userBlueTeamId) => {
              this.filterStateService.setFilterIfEmptyOrDefault('team', userBlueTeamId);
            });
        }
        this.intervalService
          .getWidgetRefreshInterval()
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            this.processData();
          });
      });

    this.filter$.pipe(untilDestroyed(this)).subscribe(() => this.processData());
  }

  processData(): void {
    const { team } = this.filterStateService.snapshot();
    if (this.exerciseId && team) {
      this.statusSummaryService
        .getTeamAwarenessData(this.exerciseId, team)
        .pipe(
          filter((data) => !!(data && data.length)),
          untilDestroyed(this)
        )
        .subscribe((data) => {
          this.segmentStatusChartData.setData(data);
          this.dataLoaded = true;
        });
    }
  }

  barClicked(barIndex: number): void {
    // TODO router.navigate 'Situation Summary Team Reality'
  }
}
