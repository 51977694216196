<div class="exercise-confirm-dialog">
  <mat-dialog-content class="exercise-confirm-dialog-content">
    <div>
      {{ 'ui.exercise.areYouSure' | translate }}
      @if (eventType === 'STARTEX') {
        <span> {{ 'ui.exercise.start' | translate }} </span>
      }
      @if (eventType === 'ENDEX') {
        <span> {{ 'ui.exercise.stop' | translate }} </span>
      }
      {{ exercise?.name }}
      <mat-form-field class="full-width textarea message">
        <mat-label>{{ 'ui.exercise.message' | translate }}</mat-label>
        <textarea [(ngModel)]="message" matInput [cdkTextareaAutosize] tabindex="-1"></textarea>
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div>
      @if (eventType === 'STARTEX') {
        <button (click)="confirmEvent()" mat-raised-button color="primary">
          {{ 'ui.start' | translate }}
        </button>
      }
      @if (eventType === 'ENDEX') {
        <button (click)="confirmEvent()" mat-raised-button color="primary">
          {{ 'ui.stop' | translate }}
        </button>
      }
      <button mat-button matDialogClose>{{ 'ui.cancel' | translate }}</button>
    </div>
  </mat-dialog-actions>
</div>
