import { Injectable } from '@angular/core';
import moment from 'moment';
import { ExerciseDuration } from '../models';

export function round(
  date: moment.Moment,
  duration: moment.Duration,
  method: string
): moment.Moment {
  return moment(Math[method](+date / +duration) * +duration);
}

export function formatDuration(durationMs: number, separator: string = ' ') {
  if (!durationMs) {
    return '-';
  }
  const duration = moment.duration(durationMs);
  const d = Math.trunc(duration.asDays());
  const hours = Math.trunc(duration.asHours());
  const h = d ? hours - d * 24 : hours;
  const m = duration.minutes();
  const s = duration.seconds();
  const durationParts = [];
  if (d) durationParts.push(`${d} day${d > 1 ? 's' : ''}`);
  if (h) durationParts.push(`${h}h`);
  if (m) durationParts.push(`${m}m`);
  if (s) durationParts.push(`${s}s`);
  return durationParts.join(separator);
}

@Injectable()
export class DateUtil {
  round(date: moment.Moment, duration: moment.Duration, method: string): moment.Moment {
    return round(date, duration, method);
  }

  formatDuration(durationMs: number, separator: string = ' ') {
    return formatDuration(durationMs, separator);
  }

  getStartingTimeOrDefault(duration: ExerciseDuration) {
    if (duration?.startexEvents?.length) {
      return moment(duration.startexEvents[0]).toISOString();
    }

    return round(
      moment().subtract(1, 'hour'),
      moment.duration(5, 'minutes'),
      'floor'
    ).toISOString();
  }
}
