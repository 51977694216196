import { Component, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationsService } from '@cybexer/ngx-commons';
import { finalize } from 'rxjs/operators';
import { SettingsService } from '../../../../services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'isa-upload-image-dialog',
  templateUrl: './upload-image-dialog.component.html',
  styleUrls: ['./upload-image-dialog.component.scss'],
})
export class UploadImageDialogComponent {
  private static ACCEPTED_EXTENSIONS = ['.jpg', '.jpeg', '.png', '.gif'];
  private static MAX_FILE_SIZE = 1_000_000; // 1MB

  @Input() uniqueId?: string;

  loading = false;
  image: File;
  error: String;

  constructor(
    private settingsService: SettingsService,
    private notificationsService: NotificationsService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<UploadImageDialogComponent>
  ) {}

  get maxFileSize(): number {
    return UploadImageDialogComponent.MAX_FILE_SIZE;
  }

  getFileInputAcceptAttr() {
    return UploadImageDialogComponent.ACCEPTED_EXTENSIONS.join(', ');
  }

  setFile(event): void {
    if (event.target.files.length) {
      this.image = event.target.files[0];
      this.validate();
      this.uploadImage();
    }
  }

  private validate(): void {
    if (this.image.type.indexOf('image') === -1) {
      this.error = this.translate.instant('ui.exercise.unsupportedFileTypeError');
      return;
    }

    const filename = this.image.name;
    const extension = filename.substring(filename.lastIndexOf('.'), filename.length);
    if (!UploadImageDialogComponent.ACCEPTED_EXTENSIONS.includes(extension.toLowerCase())) {
      this.error = this.translate.instant('ui.exercise.unsupportedFileTypeError');
    } else if (this.image.size > UploadImageDialogComponent.MAX_FILE_SIZE) {
      this.error = this.translate.instant('ui.fileManager.errExceededFileSize');
    } else {
      this.error = null;
    }
  }

  uploadImage() {
    if (this.image == null) {
      this.error = this.translate.instant('ui.fileManager.selectFile');
    }
    if (this.error) {
      return;
    }

    this.loading = true;
    this.settingsService
      .uploadImage(this.image)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((imageInfo) => {
        this.notificationsService.success('ui.settings.imageUploaded');
        this.dialogRef.close(imageInfo);
      });
  }
}
