import { FilterStateModel, FilterStateProperty, FilterStateValue } from './filter.state';

export class SetFilterProperty {
  static type = '[Filter] Set property';

  constructor(
    public name: FilterStateProperty,
    public value: FilterStateValue
  ) {}
}

export class SetFilterProperties {
  static type = '[Filter] Set properties';

  constructor(public filter: Partial<FilterStateModel>) {}
}

export class SetFilterPropertyIfEmptyOrDefault {
  static type = '[Filter] Set property if empty or has default value';

  constructor(
    public name: FilterStateProperty,
    public value: FilterStateValue
  ) {}
}

export class ResetFilter {
  static type = '[Filter] Reset';

  constructor() {}
}
