import { Component, Input } from '@angular/core';
import {
  NavigationMenuGroup,
  NavigationMenuGroupType,
  NavigationMenuLink,
} from '../../../../models';

@Component({
  selector: 'isa-menu-group',
  templateUrl: 'menu-group.component.html',
  styleUrls: ['menu-group.component.scss'],
})
export class MenuGroupComponent {
  @Input() group: NavigationMenuGroup;
  @Input() isIndividualAssessment: boolean;
  @Input() isHybridExercise: boolean;
  @Input() isDefaultExerciseLoading: boolean;

  GROUP_TYPE = NavigationMenuGroupType;

  getLinkName(link: NavigationMenuLink): string {
    if (link.individualAssessmentName && this.isIndividualAssessment)
      return link.individualAssessmentName;
    if (link.hybridExerciseName && this.isHybridExercise) return link.hybridExerciseName;
    return link.name;
  }
}
