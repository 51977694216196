import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Exercise, ReportList, Target } from '../../../../models';
import { CampaignLiveService } from '../../../../services';
import { ExerciseUtil } from '../../../../shared';

@UntilDestroy()
@Component({
  selector: 'isa-report-list-widget',
  templateUrl: './report-list-widget.component.html',
  styleUrls: ['./report-list-widget.component.scss'],
})
export class ReportListWidgetComponent implements OnChanges {
  dataLoaded: boolean;
  reportList: ReportList[] = [];
  targets: Target[] = [];
  @Input() exercise: Exercise;
  @Input() teamId: string;
  @Input() timestamp: string;

  constructor(
    private campaignLiveService: CampaignLiveService,
    private exerciseUtil: ExerciseUtil
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.exercise) {
      this.clearCurrentData();
    } else {
      this.initializeData();
    }
  }

  private initializeData() {
    this.targets = this.exerciseUtil.getTargetsForExercise(this.exercise);
    this.getReportList();
  }

  getReportList(): void {
    if (this.exercise && this.teamId && this.timestamp) {
      this.campaignLiveService
        .getReportList(this.exercise.id, this.teamId, this.timestamp)
        .pipe(untilDestroyed(this))
        .subscribe((reportList) => {
          this.reportList = reportList.slice(0, 5);
          this.addTargetCheckNames();
          this.dataLoaded = true;
        });
    }
  }

  private clearCurrentData() {
    this.dataLoaded = false;
    this.reportList = [];
  }

  addTargetCheckNames(): void {
    this.reportList.forEach((report) => {
      if (report.targetId != null) {
        report.targetName = this.targets.find((target) => target.id === report.targetId).name;
      }
    });
  }
}
