import { Pipe, PipeTransform } from '@angular/core';
import { CTFTaskStatus } from '../../models';

@Pipe({
  name: 'taskStatusText',
})
export class TaskStatusTextPipe implements PipeTransform {
  transform(status: CTFTaskStatus) {
    switch (status) {
      case CTFTaskStatus.IN_PROGRESS:
      case CTFTaskStatus.ATTACK_ABANDONED:
        return 'ui.enums.OPEN';
      case CTFTaskStatus.NOT_STARTED:
        return 'ui.enums.NOT_STARTED';
      case CTFTaskStatus.ABANDONED:
        return 'ui.enums.ON_HOLD';
      case CTFTaskStatus.SOLVED:
        return 'ui.enums.SOLVED';
      case CTFTaskStatus.PARTLY_SOLVED:
        return 'ui.enums.PARTLY_SOLVED';
      case CTFTaskStatus.DEPENDENCIES_UNSOLVED:
      case CTFTaskStatus.LOCKED:
        return 'ui.enums.LOCKED';
      case CTFTaskStatus.VALIDATING:
        return 'ui.enums.VALIDATING';
      case CTFTaskStatus.ATTACK_IN_PROGRESS:
        return 'ui.enums.ACTIVE_ATTACK';
      default:
        return 'ui.enums.UNKNOWN';
    }
  }
}
