import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationsService } from '@cybexer/ngx-commons';
import {
  AttackReport,
  AttackReportConfirmation,
  Exercise,
  ReportAIAssessment,
} from '../../../../models';
import { AttackReportsService, AuthenticationService } from '../../../../services';
import {
  ASSESSMENT_STATUS,
  CONFIRMATION_STATUS,
  FormUtil,
  OBJECTIVE_STATUS,
  ROLES,
} from '../../../../shared';
import { first } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'isa-attack-report-confirm-dialog',
  templateUrl: 'attack-report-confirm-dialog.component.html',
  styleUrls: ['attack-report-confirm-dialog.component.scss'],
})
export class AttackReportConfirmDialogComponent implements OnInit {
  exercise: Exercise;
  attackReport: AttackReport;
  reportDetails?: string;
  reportDetailsLoaded = false;
  CONFIRMATION_STATUS = CONFIRMATION_STATUS;
  OBJECTIVE_STATUS = OBJECTIVE_STATUS;
  OBJECTIVE_DISPLAY_STATUSES = {
    COMPROMISED: 'SUCCESSFUL',
    UNABLE_TO_COMPROMISE: 'UNSUCCESSFUL',
  };
  feedback: string;
  hasPermissionToConfirmOrDeny = false;
  aiFeaturesEnabled: boolean = false;
  promptOverrideId?: string;

  protected readonly ASSESSMENT_STATUS = ASSESSMENT_STATUS;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AttackReportConfirmDialogInput,
    private dialogRef: MatDialogRef<AttackReportConfirmDialogComponent>,
    private attackReportsService: AttackReportsService,
    private notificationsService: NotificationsService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.aiFeaturesEnabled = this.data.aiFeaturesEnabled;
    this.attackReport = this.data.attackReport;
    this.feedback = this.aiFeaturesEnabled
      ? this.attackReport.feedback || this.attackReport.aiFeedback
      : this.attackReport.feedback;
    this.exercise = this.data.exercise;
    this.promptOverrideId = this.data.promptOverrideId;

    this.attackReportsService
      .getReportDetails(this.exercise.id, this.attackReport.reportId)
      .pipe(untilDestroyed(this))
      .subscribe((reportDetails) => {
        this.reportDetails = reportDetails.data;
        this.reportDetailsLoaded = true;
      });

    this.authenticationService
      .hasGamenetPermission([ROLES.ADMIN, ROLES.WHITE], true, false)
      .pipe(untilDestroyed(this))
      .subscribe((x: boolean) => {
        return (this.hasPermissionToConfirmOrDeny = x);
      });
  }

  submitConfirmation(status: string): void {
    if (status) {
      const attackReportConfirmation: AttackReportConfirmation = new AttackReportConfirmation({
        teamId: this.attackReport.teamId,
        objectiveContainerId: this.attackReport.objectiveContainerId,
        objectiveId: this.attackReport.objectiveId,
        reportId: this.attackReport.reportId,
        feedback: AttackReportConfirmDialogComponent.formatFeedback(this.feedback),
        status,
      });
      this.attackReportsService
        .createAttackReportConfirmation(this.exercise.id, attackReportConfirmation)
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.notificationsService.success(
            status === CONFIRMATION_STATUS.CONFIRMED
              ? 'ui.situationReport.reportConfirmed'
              : 'ui.situationReport.reportDenied'
          );
          this.dialogRef.close(attackReportConfirmation);
        });
    }
  }

  showFeedback(): boolean {
    return (
      (this.hasPermissionToConfirmOrDeny &&
        this.attackReport.status === CONFIRMATION_STATUS.PENDING_CONFIRMATION) ||
      (this.attackReport.status !== CONFIRMATION_STATUS.PENDING_CONFIRMATION && !!this.feedback)
    );
  }

  sendToAIAssessment() {
    if (this.attackReport.status !== CONFIRMATION_STATUS.PENDING_CONFIRMATION) return;

    const assessments = [
      new ReportAIAssessment({
        reportId: this.attackReport.reportId,
        teamId: this.attackReport.teamId,
        promptOverrideId: this.promptOverrideId,
      }),
    ];
    this.attackReportsService
      .createMultipleAttackReportAIAssessments(this.exercise.id, assessments)
      .pipe(first())
      .subscribe(() => {
        this.notificationsService.success('ui.reports.reportSent');
        this.dialogRef.close();
      });
  }

  private static formatFeedback(feedback?: string): string {
    if (feedback != null && FormUtil.getStringCharacterCount(feedback.trim()) > 0) {
      return FormUtil.replaceNewlines(feedback, 5, 4);
    }
    return '';
  }
}

export type AttackReportConfirmDialogInput = {
  attackReport: AttackReport;
  exercise: Exercise;
  aiFeaturesEnabled: boolean;
  promptOverrideId?: string;
};
