import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IntervalService } from '../services';

@UntilDestroy()
@Component({
  selector: 'isa-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page-component.scss'],
})
export class ErrorPageComponent implements OnInit {
  errorCode = '';
  private REFRESH_INTERVAL = 10000;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private intervalService: IntervalService
  ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((params) => {
      if (params && params['code']) {
        this.errorCode = params['code'];
      }
    });
    this.intervalService
      .getIntervalWithDelay(this.REFRESH_INTERVAL)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.navigateToLandingPage();
      });
  }

  navigateToLandingPage() {
    this.router.navigate(['/app/landing']);
  }
}
