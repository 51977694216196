@if (sliderLoaded) {
  <div class="exercise-duration-slider">
    <div class="phase-selection-container">
      @if (isPeriodSelected()) {
        <mat-form-field>
          <mat-label>{{ 'ui.shared.timePeriod' | translate }}</mat-label>
          <mat-select
            [ngModel]="sliderData.selectedTimePeriod"
            (ngModelChange)="changeTimePeriod($event)"
          >
            @for (startex of exerciseDuration?.startexEvents; track startex; let i = $index) {
              <mat-option [value]="i"> {{ 'ui.shared.period' | translate }} {{ i + 1 }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
    </div>
    <div class="slider-container">
      <mat-slider
        color="accent"
        [step]="step"
        [min]="sliderMinDate"
        [max]="sliderMaxDate"
        [displayWith]="formatSliderThumbLabel"
      >
        <input
          matSliderThumb
          [ngModel]="sliderData?.sliderDate?.getTime()"
          (ngModelChange)="changeDate($event)"
        />
      </mat-slider>
    </div>
    <div class="slider-right">
      <div class="slider-label">
        <cybexer-meta-item class="slider-label-meta">{{
          'ui.shared.upTo' | translate
        }}</cybexer-meta-item>
        {{ sliderLabel }}
      </div>
      <div
        [matTooltip]="'ui.shared.exerciseNotStarted' | translate"
        [matTooltipDisabled]="isPeriodSelected()"
      >
        @if (paused || !isPeriodSelected()) {
          <button
            data-testid="resume-btn"
            mat-icon-button
            (click)="unPause()"
            [disabled]="!isPeriodSelected()"
            [matTooltip]="'ui.shared.resumeUpdate' | translate"
          >
            <mat-icon id="pause">pause</mat-icon>
            <mat-icon id="play">play_arrow</mat-icon>
          </button>
        }
      </div>
    </div>
  </div>
}
