<div class="campaign-live" style="clear: both">
  <div class="campaign-live__head">
    <mat-slide-toggle
      align="end"
      class="compact-view-toggle"
      [ngModel]="(filter$ | async).compactView"
      (ngModelChange)="filterStateService.setFilter('compactView', $event)"
      labelPosition="after"
    >
      {{ 'ui.attackCampaign.compactView' | translate }}
    </mat-slide-toggle>
    @if (!hasBlueRole) {
      <isa-filtered-select
        [options]="teams"
        [preselectedOption]="(filter$ | async).team"
        [optionKey]="'customName'"
        [optionValue]="'id'"
        [label]="'ui.attackCampaign.team' | translate"
        (selectionChange)="filterStateService.setFilter('team', $event)"
      ></isa-filtered-select>
    }
    @if (exercise && exercise.type !== ExerciseType.UNPUBLISHED) {
      <isa-exercise-duration-slider
        ngDefaultControl
        [ngModel]="filter$ | async"
        (ngModelChange)="
          filterStateService.setFilters({
            sliderDate: $event.sliderDate,
            timePeriod: $event.timePeriod
          })
        "
      ></isa-exercise-duration-slider>
    }
  </div>
  @if (exercise) {
    <div class="campaign-live__content">
      <isa-campaign-live-widget
        [exercise]="exercise"
        [teamId]="(filter$ | async).team"
        [isCompactView]="(filter$ | async).compactView"
        [date]="(filter$ | async).sliderDate"
        class="campaign-live"
      ></isa-campaign-live-widget>
      @if (exercise.type !== ExerciseType.UNPUBLISHED) {
        <isa-team-ranking-widget
          [exerciseId]="exercise.id"
          [teamId]="(filter$ | async).team"
          [timestamp]="timelineTimestamp"
          class="team-ranking"
        ></isa-team-ranking-widget>
      }
      @if (!(exercise.type === ExerciseType.UNPUBLISHED || exercise.type === ExerciseType.VERSUS)) {
        <isa-report-list-widget
          [exercise]="exercise"
          [teamId]="(filter$ | async).team"
          [timestamp]="timelineTimestamp"
          class="report-list"
        ></isa-report-list-widget>
      }
      @if (exercise.type === ExerciseType.VERSUS) {
        <isa-versus-attack-widget
          [exercise]="exercise"
          [teamId]="(filter$ | async).team"
          [timestamp]="timelineTimestamp"
          [isAttackerView]="true"
          class="team-attack"
        ></isa-versus-attack-widget>
      }
      @if (!(exercise.type === ExerciseType.UNPUBLISHED || exercise.type === ExerciseType.VERSUS)) {
        <isa-attack-widget
          [exercise]="exercise"
          [teamId]="(filter$ | async).team"
          [timestamp]="timelineTimestamp"
          class="red-attack"
        ></isa-attack-widget>
      }
      @if (exercise.type === ExerciseType.VERSUS) {
        <isa-versus-attack-widget
          [exercise]="exercise"
          [teamId]="(filter$ | async).team"
          [timestamp]="timelineTimestamp"
          [isAttackerView]="false"
          class="versus-attack"
        ></isa-versus-attack-widget>
      }
    </div>
  }
</div>
