import { BaseModel } from './base.model';
import { IntegrationEvent, IntegrationEventType } from './integration-event.model';

export class IntegrationSaveDTO extends BaseModel {
  name: string;
  baseUrl?: string;
  type: IntegrationType;
  authentication: IntegrationAuthentication;
  configuration: IntegrationConfiguration;

  constructor(data?) {
    super(data);

    if (data && data.hasOwnProperty('authentication')) {
      this.authentication = IntegrationAuthentication.constructFromData(data.authentication);
    } else {
      this.authentication = new NoopAuthentication();
    }
    if (data && data.hasOwnProperty('configuration')) {
      this.configuration = IntegrationConfiguration.constructFromData(data.configuration);
    }
    if (!data || !data.hasOwnProperty('type')) {
      this.type = IntegrationType.REST;
    }
  }
}

export class Integration extends IntegrationSaveDTO {
  id: string;
  events: IntegrationEvent[];
  eventTypes: Set<IntegrationEventType>;

  constructor(data?) {
    super(data);
    if (data && data.hasOwnProperty('events')) {
      this.events = data.events.map((event) => IntegrationEvent.constructFromData(event));
    } else {
      this.events = [];
    }
    if (data && data.hasOwnProperty('eventTypes')) {
      this.eventTypes = new Set(
        data.eventTypes.map((eventType) => IntegrationEventType[eventType])
      );
    }
  }
}

export abstract class IntegrationAuthentication extends BaseModel {
  type: AuthenticationType = AuthenticationType.SESSION_TOKEN;

  static constructFromData(data: object): IntegrationAuthentication {
    switch (data['type']) {
      case AuthenticationType.SESSION_TOKEN:
        return new SessionTokenAuthentication(data);
      case AuthenticationType.API_KEY:
        return new ApiKeyAuthentication(data);
      case AuthenticationType.NOOP:
        return new NoopAuthentication(data);
      case AuthenticationType.ACTIVEMQ:
        return new ActiveMqAuthentication(data);
    }
  }
}

export class SessionTokenAuthentication extends IntegrationAuthentication {
  type = AuthenticationType.SESSION_TOKEN;
  url: string;
  username: string;
  password: string;
}

export class ApiKeyAuthentication extends IntegrationAuthentication {
  type = AuthenticationType.API_KEY;
  apiKey: string;
}

export class NoopAuthentication extends IntegrationAuthentication {
  type = AuthenticationType.NOOP;
}

export class ActiveMqAuthentication extends IntegrationAuthentication {
  type = AuthenticationType.ACTIVEMQ;
  brokerUrl: string;
  username: string;
  password: string;
}

export enum AuthenticationType {
  SESSION_TOKEN = 'SESSION_TOKEN',
  API_KEY = 'API_KEY',
  NOOP = 'NOOP',
  ACTIVEMQ = 'ACTIVEMQ',
}

export abstract class IntegrationConfiguration extends BaseModel {
  integrationType: IntegrationType;

  static constructFromData(data: object): IntegrationConfiguration {
    switch (data['integrationType']) {
      case IntegrationType.REST:
        return new RestIntegrationConfiguration(data);
      case IntegrationType.SENDGRID:
        return new SendGridIntegrationConfiguration(data);
      case IntegrationType.SLACK_WEBHOOK:
        return new SlackWebhookIntegrationConfiguration(data);
      case IntegrationType.DELRAP:
        return new DelRapIntegrationConfiguration(data);
    }
  }
}

export class RestIntegrationConfiguration extends IntegrationConfiguration {
  integrationType = IntegrationType.REST;
  baseUrl: string;
}

export class SendGridIntegrationConfiguration extends IntegrationConfiguration {
  integrationType = IntegrationType.SENDGRID;
  from: string;
  to: string;
  toMany: string[];

  constructor(data?) {
    super(data);
    this.to = this.toMany ? this.toMany[0] : undefined;
  }
}

export class SlackWebhookIntegrationConfiguration extends IntegrationConfiguration {
  integrationType = IntegrationType.SLACK_WEBHOOK;
  webhookUrl: string;
}

export class DelRapIntegrationConfiguration extends IntegrationConfiguration {
  integrationType = IntegrationType.DELRAP;
}

export enum IntegrationType {
  REST = 'REST',
  SENDGRID = 'SENDGRID',
  SLACK_WEBHOOK = 'SLACK_WEBHOOK',
  DELRAP = 'DELRAP',
}
