<div class="exercise-delete-dialog">
  <h2 matDialogTitle>{{ exercise.name }}</h2>
  <mat-dialog-content class="exercise-delete-dialog-content">
    {{ 'ui.exercise.deleteExerciseConfirm' | translate }}
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div>
      <button
        data-testid="confirm-btn"
        mat-raised-button
        color="primary"
        (click)="deleteExercise(exercise.id)"
      >
        {{ 'ui.delete' | translate }}
      </button>
      <button mat-button matDialogClose>{{ 'ui.cancel' | translate }}</button>
    </div>
  </mat-dialog-actions>
</div>
