import { AfterContentInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[isaAutoFocus]',
})
export class AutoFocusDirective implements AfterContentInit {
  constructor(private el: ElementRef) {}

  ngAfterContentInit() {
    setTimeout(() => this.el.nativeElement.focus(), 100);
  }
}
