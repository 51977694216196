import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AssignableRoles } from '../../models/shared/role.model';

@Injectable()
export class RoleService {
  constructor(private http: HttpClient) {}

  getRoles(): Observable<AssignableRoles> {
    return this.http
      .get<AssignableRoles>('api/roles')
      .pipe(map((assignableRoles) => new AssignableRoles(assignableRoles)));
  }
}
