<mat-form-field class="isa-filtered-select">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    #matSelect
    [required]="required"
    [formControl]="selectControl"
    [multiple]="multiple"
    (selectionChange)="select($event.value)"
    (openedChange)="onDropdownChange($event)"
  >
    <mat-select-trigger>
      {{ displayFn(selectedOptions?.[0]) || '' }}
      @if (selectedOptions.length > 1) {
        <span> (+{{ selectedOptions.length - 1 }} {{ 'ui.more' | translate }}) </span>
      }
    </mat-select-trigger>
    <div class="search-box">
      @if (!multiple) {
        <mat-icon matPrefix class="filtered-select-search-icon">search</mat-icon>
      }
      @if (multiple) {
        <mat-checkbox
          [ngModel]="isAllFilteredSelected()"
          (change)="toggleAllFiltered()"
          class="toggle-checkbox"
        ></mat-checkbox>
      }
      <input
        matInput
        #searchInput
        type="text"
        class="search-input"
        [formControl]="searchControl"
        (input)="filterOptions(searchInput.value)"
        [ngClass]="{ 'search-input-for-multiple-selection': multiple }"
      />
    </div>
    @if (defaultOption) {
      <mat-option
        [value]="defaultOption"
        [ngClass]="{ hidden: !!searchInput.value }"
        [disabled]="!!searchInput.value"
      >
        {{ displayFn(defaultOption) }}
      </mat-option>
    }
    @for (option of options; track option) {
      <mat-option
        [value]="option"
        [ngClass]="{ hidden: !filteredOptions.includes(option) }"
        [disabled]="!filteredOptions.includes(option)"
      >
        {{ displayFn(option) }}
      </mat-option>
    }
  </mat-select>
</mat-form-field>
