import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject, switchMap } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { SoundInfo } from '../../models';

export enum DefaultSound {
  BEEP = '../../../assets/audio/beep.wav',
  BASS_BEEP = '../../../assets/audio/bass_beep.wav',
}

@Injectable()
export class SoundService {
  private sounds$: Observable<SoundInfo[]>;
  private refresh$ = new BehaviorSubject<void>(null);
  private stopSounds$ = new Subject<void>();

  constructor(private http: HttpClient) {}

  getSoundDownloadUrl(
    url: string = 'api/settings/files',
    soundId?: String,
    defaultSound: DefaultSound = DefaultSound.BEEP
  ): Observable<string> {
    if (soundId) {
      return this.downloadSound(`${url}/${soundId}`).pipe(map((blob) => URL.createObjectURL(blob)));
    }

    return of(defaultSound);
  }

  getSettingsSoundUrl(
    soundId?: String,
    defaultSound: DefaultSound = DefaultSound.BEEP
  ): Observable<string> {
    return this.getSoundDownloadUrl('api/settings/files', soundId, defaultSound);
  }

  downloadSound(url: string): Observable<Blob> {
    return this.http.get(url, { responseType: 'blob' });
  }

  /**
   * @return A hot observable, which only makes an HTTP request once and then replays the result
   * to any subsequent subscriptions. Will make another HTTP request and emit new result on
   * operations that mutate the sound list though (like uploading or deleting sounds).
   */
  getSoundInfoList(): Observable<SoundInfo[]> {
    if (!this.sounds$) {
      this.sounds$ = this.refresh$.pipe(
        switchMap(() => this._getSoundInfoList()),
        shareReplay({ bufferSize: 1, refCount: true })
      );
    }
    return this.sounds$;
  }

  private _getSoundInfoList(): Observable<SoundInfo[]> {
    return this.http
      .get<SoundInfo[]>('api/settings/sounds')
      .pipe(map((array) => array.map((soundInfo) => new SoundInfo(soundInfo))));
  }

  uploadSound(sound: File): Observable<SoundInfo> {
    const formData = new FormData();
    formData.append('file', sound);
    return this.http.post('api/settings/sounds', formData).pipe(
      tap(() => this.refresh$.next(null)),
      map((data) => new SoundInfo(data))
    );
  }

  deleteSound(soundId: String): Observable<boolean> {
    return this.http.delete(`api/settings/files/${soundId}`).pipe(
      tap(() => this.refresh$.next(null)),
      map(() => true)
    );
  }

  stopAllSounds() {
    this.stopSounds$.next();
  }

  onSoundStop(): Observable<void> {
    return this.stopSounds$.asObservable();
  }

  static playSound(url: string) {
    const audio = new Audio(url);
    audio.load();
    audio.play();
  }
}
