<div class="gma-details-dialog">
  <h2 matDialogTitle>{{ 'ui.admin.gma.gmaDetails' | translate }}</h2>
  <mat-dialog-content>
    @if (gmaOverview) {
      <div class="isa-details-box">
        <div class="row">
          <span class="label">{{ 'ui.admin.gma.gmaId' | translate }}</span>
          <span class="disable-uppercase">{{ gmaOverview.id }}</span>
        </div>
        <div class="row">
          <span class="label">{{ 'ui.admin.gma.ipAddress' | translate }}</span
          >{{ gmaOverview.ipAddress }}
        </div>
        <div class="row">
          <span class="label">{{ 'ui.admin.gma.registered' | translate }}</span
          >{{ gmaOverview.registered | isaDate: 'medium' }}
        </div>
        <div class="row" data-testid="gma-poll-status-container">
          <span class="label">
            {{ 'ui.admin.gma.lastGmaPoll' | translate }}
            @if (gmaOverview.status === 'POWER_OFF') {
              <i
                class="warning-icon fas fa-exclamation"
                matTooltip="'ui.admin.gma.noMessagesReceived' | translate"
              ></i>
            }</span
          >{{
            gmaOverview.latestGmaPollTimestamp != null
              ? (gmaOverview.latestGmaPollTimestamp | isaDate: 'medium')
              : '-'
          }}
        </div>
        <div class="row" data-testid="activity-status-container">
          <span class="label">
            {{ 'ui.admin.gma.lastMessage' | translate }}
            @if (gmaOverview.status === 'EXCLAMATION') {
              <i
                class="warning-icon fas fa-exclamation"
                matTooltip="'ui.admin.gma.noMessagesReceived' | translate"
              ></i>
            }</span
          >{{
            gmaOverview.latestActivityTimestamp != null
              ? (gmaOverview.latestActivityTimestamp | isaDate: 'medium')
              : '-'
          }}
        </div>
        @if (gmaOverview.duplicateMessages > 0) {
          <div class="row" data-testid="duplicate-messages-row">
            <span class="label">{{ 'ui.admin.gma.duplicateMessagesReceived' | translate }}:</span>
            <span>{{ gmaOverview.duplicateMessages }}</span>
          </div>
        }
        @if (gmaOverview.exercises.length > 0) {
          <div class="row">
            <span class="label">{{ 'ui.admin.gma.usedIn' | translate }}</span>
            <div class="exercise-container">
              <table class="isa-table exercises">
                <thead>
                  <th>{{ 'ui.admin.gma.exercise' | translate }}</th>
                  <th>{{ 'ui.admin.gma.teams' | translate }}</th>
                </thead>
                <tbody>
                  @for (exercise of gmaOverview.exercises; track exercise) {
                    <tr>
                      <td class="clickable-row" (click)="redirectToExerciseEditForm(exercise.id)">
                        {{ exercise.name }}
                      </td>
                      <td class="team-info-cell">
                        @for (team of exercise.teams; track team) {
                          <p>{{ team.name }}</p>
                        }
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        }
      </div>
    }
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div>
      <button mat-button matDialogClose>{{ 'ui.cancel' | translate }}</button>
    </div>
  </mat-dialog-actions>
</div>
