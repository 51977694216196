import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationsService } from '@cybexer/ngx-commons';
import { Exercise } from '../../../../../models';
import { ExerciseService, ProcessStatusService } from '../../../../../services';

@UntilDestroy()
@Component({
  selector: 'isa-exercise-team-reset-dialog',
  templateUrl: './exercise-team-reset-dialog.component.html',
  styleUrls: ['./exercise-team-reset-dialog.component.scss'],
})
export class ExerciseTeamResetDialogComponent implements OnInit {
  exercise: Exercise;
  teamId: string;
  teamName: string;
  confirmationInput: FormControl;
  confirmationText = 'ERASE ALL';
  totalSteps = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private exerciseService: ExerciseService,
    private processStatusService: ProcessStatusService,
    private notificationsService: NotificationsService,
    private dialogRef: MatDialogRef<ExerciseTeamResetDialogComponent, boolean>
  ) {}

  ngOnInit() {
    this.confirmationInput = new FormControl('');
    this.exercise = this.data['exercise'];
    this.teamId = this.data['teamId'];
    this.teamName = this.data['teamName'];
  }

  resetExerciseTeamData(): void {
    this.dialogRef.disableClose = true;
    this.exerciseService
      .resetExerciseTeamData(this.exercise.id, this.teamId)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.notificationsService.success('ui.exercise.teamDataResetting');
        this.dialogRef.close(true);
      });
  }
}
