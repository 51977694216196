import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import {
  IntegrationEvent,
  IntegrationEventSubjectType,
  IntegrationEventType,
} from '../../../../models/shared/integration-event.model';
import { IntegrationsService } from '../../../../services';

@Component({
  selector: 'isa-integration-event-list',
  templateUrl: './integration-event-list.component.html',
  styleUrls: ['./integration-event-list.component.scss'],
})
export class IntegrationEventListComponent implements OnInit {
  @Input() eventTypes?: IntegrationEventType[];
  @Input() subjectId?: string;
  @Input() subjectType?: IntegrationEventSubjectType;
  @Input() integrationId?: string;
  events$: Observable<IntegrationEvent[]>;
  refreshEvents$ = new BehaviorSubject(null);
  isNewEventOpen = false;
  expandedEventId: string;

  constructor(private integrationsService: IntegrationsService) {}

  ngOnInit() {
    this.events$ = this.refreshEvents$.pipe(
      switchMap(() => {
        return this.integrationsService.getIntegrationEvents(
          this.subjectId,
          this.subjectType,
          this.eventTypes ? this.eventTypes : [],
          this.integrationId
        );
      })
    );
  }

  eventsTrackByFn = (index, event) => event.id;

  addEvent() {
    this.expandedEventId = null;
    this.isNewEventOpen = true;
  }

  onSave(event: IntegrationEvent, isNew: boolean) {
    this.refreshEvents();
    this.expandedEventId = event.id;
    if (isNew) {
      this.isNewEventOpen = false;
    }
  }

  refreshEvents() {
    this.refreshEvents$.next(null);
  }

  setExpandedEvent(eventId: string) {
    this.expandedEventId = eventId;
  }
}
