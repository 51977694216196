import { BaseModel } from '../../shared/base.model';
import { CTFTaskStatus } from './ctf-task-status.model';

export class CTFTaskBulkEventSaveResult extends BaseModel {
  teamInfo: CTFLatestTeamInfo[];

  constructor(data?) {
    super(data);

    if (data && data.hasOwnProperty('teamInfo')) {
      this.teamInfo = data.teamInfo.map((it) => new CTFLatestTeamInfo(it));
    }
  }

  toFormattedString(): string {
    return this.teamInfo.map((it) => it.toFormattedString()).join('\r\n');
  }

  toDetailedString(): string {
    const teamInfo = this.teamInfo.map((it) => it.toFormattedString(true, true)).join('\r\n');
    return `=========================\r\n${teamInfo}`;
  }
}

export class CTFLatestTeamInfo extends BaseModel {
  teamId: string;
  teamName: string;
  saved: number;
  total: number;
  latestTaskInfo: CTFLatestTaskInfo[];

  constructor(data?) {
    super(data);

    if (data && data.hasOwnProperty('latestTaskInfo')) {
      this.latestTaskInfo = data.latestTaskInfo.map((it) => new CTFLatestTaskInfo(it));
    }
  }

  toFormattedString(withIds: boolean = false, withTaskInfo = false): string {
    const tasksInfo = withTaskInfo
      ? ` Tasks info: [${this.latestTaskInfo
          .map((it) => it.toFormattedString(withIds))
          .join('; ')}]`
      : '';
    return `Team '${this.teamName}${withIds ? `(id='${this.teamId}')` : ''}': ${this.saved}/${
      this.total
    }.${tasksInfo}`;
  }
}

export class CTFLatestTaskInfo extends BaseModel {
  taskId: string;
  taskName: string;
  status: CTFTaskStatus;

  constructor(data?) {
    super(data);
  }

  toFormattedString(withIds: boolean = false): string {
    return `Task '${this.taskName}'${withIds ? `(id='${this.taskId}')` : ''}: ${this.status}`;
  }
}
