<div class="campaign-live-target">
  <div
    class="target"
    [ngClass]="getTargetClasses(target)"
    [style.border-top-color]="borderColor"
    (click)="target?.isUnderAttack && (isTargetExpanded = !isTargetExpanded)"
  >
    <div class="target-attack-container">
      @if (target?.isUnderAttack && isTargetExpanded) {
        <div class="red-team">
          <div class="red-team-icon">
            <i class="fas fa-user-secret" aria-hidden="true"></i>
          </div>
          @if (target?.isUnderAttack) {
            <div class="red-team-arrows-small" [style.height.px]="10" [style.width.px]="10"></div>
          }
          <span class="red-team-name">{{
            target.activeAttack?.attackerTeamName | isaTeamName
          }}</span>
        </div>
      }
      <div class="target-container">
        <div
          (click)="$event.stopPropagation(); openDialog(target)"
          *isaRequirePermission="ADMIN_PERMISSIONS.MANAGE_INTEGRATIONS"
          class="edit-target target-icon"
        >
          <i class="fas fa-edit"></i>
        </div>
        @if (!isCompromised(target) && !isUnavailable(target)) {
          <i class="fas fa-server" aria-hidden="true"></i>
        }
        @if (target?.isUnderAttack && !isTargetExpanded) {
          <i class="fas fa-bolt under-attack" aria-hidden="true"></i>
        }
        @if (isCompromised(target) || isUnavailable(target)) {
          <div class="svg-icon">
            <svg
              version="1.1"
              id="Layer_2"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              style="enable-background: new 0 0 100 100"
              xml:space="preserve"
            >
              <style type="text/css">
                .broken-server {
                  fill: #e3e3e3;
                }
              </style>
              <path
                class="broken-server"
                d="M97.8,59.7l-61-15.8l12.5-19.3l-11.5-7.4L2.2,72.1l11.5,7.4l5.1-7.9V78h65.4v-7.7l10.1,2.6L97.8,59.7z M38,49
        l35.8,9.3l-1.1,4.3l-35.8-9.3L38,49z M36.6,25.2c1-1.5,3.1-2,4.6-1c1.5,1,2,3.1,1,4.6c-1,1.5-3.1,2-4.6,1
        C36.1,28.8,35.6,26.8,36.6,25.2z M12.5,72.9l-3.7-2.4l20.1-31.1l3.7,2.4L12.5,72.9z M32.7,50.2l-1.7,6.4l30,7.7H23.5L32.7,50.2z
         M60.7,73.4h-37v-4.4h37V73.4z M80.2,71c0,1.8-1.5,3.3-3.3,3.3c-1.8,0-3.3-1.5-3.3-3.3c0-1.3,0.8-2.5,1.9-3l4,1
        C79.9,69.6,80.2,70.3,80.2,71z M92.2,65.2c-0.5,1.8-2.3,2.8-4,2.4c-1.8-0.5-2.8-2.3-2.4-4c0.5-1.8,2.3-2.8,4-2.4
        C91.6,61.6,92.6,63.4,92.2,65.2z"
              />
            </svg>
          </div>
        }
        <div class="target-name" [ngClass]="{ 'target-name-versus': isVersusExercise }">
          {{ target?.name }}
        </div>
        @if (isVersusExercise) {
          <div class="row versus-icons">
            <div class="target-icon">
              <i class="fas fa-crosshairs"></i>{{ target.successfulAttacks }}
            </div>
            <div class="target-icon">
              <i class="fas fa-shield-alt"></i>{{ target.successfulDefenses }}
            </div>
          </div>
        }
      </div>
    </div>
  </div>
</div>
