@if (user$ | async; as user) {
  <cybexer-widget-header [title]="'ui.userDetails.userDetails' | translate"></cybexer-widget-header>
  <cybexer-widget-body>
    <mat-card id="user-details">
      <div class="isa-details-box">
        <div class="row">
          <span class="label">{{ 'ui.userDetails.username' | translate }}</span> {{ user.username }}
        </div>
        <div class="row">
          <span class="label">{{ 'ui.userDetails.fullName' | translate }}</span> {{ user.fullName }}
        </div>
        <div class="row">
          <span class="label">{{ 'ui.userDetails.roles' | translate }}</span>
          {{ displayRoles(user) }}
        </div>
        <div class="row">
          <span class="label">{{ 'ui.userDetails.registered' | translate }}</span>
          {{ user.registered | isaDate: 'medium' }}
        </div>
        <div class="row">
          <span class="label">{{ 'ui.userDetails.password' | translate }}</span>
          <button
            mat-button
            (click)="openPasswordChangeDialog(user.username)"
            data-testid="pswChangeBtn"
          >
            <i class="fas fa-key mat-button-fa" aria-hidden="true"></i
            >{{ 'ui.userDetails.change' | translate }}
          </button>
        </div>
      </div>
    </mat-card>
    <div class="roles-title">{{ 'ui.userDetails.rolesTitle' | translate }}</div>
  </cybexer-widget-body>
  <isa-role-assignment-table
    [selectedRoles]="user.roles"
    (onAssign)="onRoleAssign($event, user.username)"
  >
  </isa-role-assignment-table>
}
