import { Component, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CampaignLiveTarget } from '../../../../../models';
import { ADMIN_PERMISSIONS, TARGET_STATUS } from '../../../../../shared';
import { CampaignLiveTargetDialogComponent } from './campaign-live-target-dialog/campaign-live-target-dialog.component';

@Component({
  selector: 'isa-campaign-live-target',
  templateUrl: './campaign-live-target.component.html',
  styleUrls: ['./campaign-live-target.component.scss'],
})
export class CampaignLiveTargetComponent {
  @Input() target: CampaignLiveTarget;
  @Input() borderColor: string;
  @Input() isVersusExercise: boolean;
  @Input() isTargetExpanded = false;

  dialogRef: MatDialogRef<CampaignLiveTargetDialogComponent>;

  readonly ADMIN_PERMISSIONS = ADMIN_PERMISSIONS;

  constructor(private dialog: MatDialog) {}

  getTargetClasses(target: CampaignLiveTarget): string[] {
    const targetClasses: string[] = [];
    if (target && !target.isDeployed) {
      targetClasses.push('target--not-deployed');
    }
    if (this.isUnavailable(target)) {
      targetClasses.push('target--not-available');
    }
    if (this.isCompromised(target)) {
      targetClasses.push('target--compromised');
    }
    if (this.isVersusExercise) {
      targetClasses.push('target-versus');
    }
    if (this.isTargetExpanded) {
      targetClasses.push('target-versus-expanded');
    }
    return targetClasses;
  }

  isCompromised(target: CampaignLiveTarget): boolean {
    if (target && target.targetChecks) {
      return target.targetChecks.some(
        (targetCheck) => targetCheck.status === TARGET_STATUS.COMPROMISED
      );
    }
  }

  isUnavailable(target: CampaignLiveTarget): boolean {
    if (target && target.targetChecks) {
      return (
        target.targetChecks.some(
          (targetCheck) => targetCheck.status === TARGET_STATUS.NOT_AVAILABLE
        ) && !this.isCompromised(target)
      );
    }
  }

  openDialog(target: CampaignLiveTarget): void {
    this.dialogRef = this.dialog.open(CampaignLiveTargetDialogComponent, {
      disableClose: true,
      data: { target },
    });

    this.dialogRef.afterClosed().subscribe(() => {
      this.dialogRef = null;
    });
  }
}
