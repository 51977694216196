import { Component, forwardRef, Inject, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { DatetimeAdapter } from '@mat-datetimepicker/core';
import { MatDatetimepickerType } from '@mat-datetimepicker/core/datetimepicker/datetimepicker-type';
import moment from 'moment';
import { PreferenceService } from '../../../services';
import { CustomDateAdapter } from '../../custom-date-adapter';
import { LocaleService } from '../../index';

const noop = () => {};

@Component({
  selector: 'isa-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true,
    },
    {
      provide: DatetimeAdapter,
      useClass: CustomDateAdapter,
    },
  ],
})
export class DatePickerComponent implements ControlValueAccessor {
  @Input() placeholder: string;
  @Input() type: MatDatetimepickerType;

  twelveHour: boolean = false;

  private innerValue: any = '';
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  constructor(
    private localeService: LocaleService,
    private preferenceService: PreferenceService,
    private adapter: DateAdapter<any>,
    @Inject(MAT_DATE_LOCALE) private readonly locale: string
  ) {
    const dateFormatLocale = this.preferenceService.currentPreferences.dateFormat || 'de';
    moment.locale(dateFormatLocale);
    this.locale = dateFormatLocale;
    this.adapter.setLocale(dateFormatLocale);
    this.twelveHour = this.is12HourFormat();
  }

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  private is12HourFormat(): boolean {
    const localeData = moment.localeData();

    // Check if the locale's longDateFormat has an 'A' or 'a' (which denotes AM/PM)
    // in the format for LT (which is the time format)
    return /[aA]/.test(localeData.longDateFormat('LT'));
  }
}
