<div id="password-change-dialog">
  <h2 matDialogTitle>{{ 'ui.users.changePassword' | translate }}</h2>
  <form [formGroup]="form" (ngSubmit)="onSubmit()" class="isa-form">
    <mat-dialog-content>
      <isa-password-fields [formGroup]="form.get('passwordGroup')"></isa-password-fields>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button type="submit" mat-raised-button color="primary">{{ 'ui.save' | translate }}</button>
      <button mat-button matDialogClose>{{ 'ui.cancel' | translate }}</button>
    </mat-dialog-actions>
  </form>
</div>
