import { BaseModel } from '../../shared/base.model';
import { CTFTaskStatus } from './ctf-task-status.model';

export class NiceItemsCategory extends BaseModel {
  name: string;
  solved: number;
  unsolved: number;
  total: number;
  items: NiceItem[];

  constructor(data?) {
    super(data);
    if (data && data.hasOwnProperty('items')) {
      this.items = data.items.map((item) => new NiceItem(item));
    }
  }
}

export class NiceItem extends BaseModel {
  id: string;
  isSolved: boolean;
  tasks: NiceItemTask[];

  constructor(data?) {
    super(data);
    if (data && data.hasOwnProperty('tasks')) {
      this.tasks = data.tasks.map((task) => new NiceItemTask(task));
    }
  }
}

export class NiceItemTask extends BaseModel {
  title: string;
  status: CTFTaskStatus;
  category: string;

  constructor(data?) {
    super(data);
  }
}
