export const NiceDictionaryJA = {
  // Category 'name' fields should not be translated here, but in ja/messages.properties
  categories: [
    {
      name: 'tasks',
      items: [
        {
          id: 'T0001',
          value:
            '情報技術（IT）のセキュリティ目標と目的をサポートし、組織全体のリスクを削減するために、リーダーシップ・サポート、財務リソース、主要セキュリティ担当者などの必要なリソースを取得して管理する。',
          softSkill: false,
        },
        {
          id: 'T0002',
          value:
            '業務プログラムの効果的な企業継続を行うために、財源を含む必要なリソースを取得する。',
          softSkill: false,
        },
        {
          id: 'T0003',
          value: 'リスクレベルとセキュリティの姿勢について上級管理職（例：CIO）に助言する。',
          softSkill: false,
        },
        {
          id: 'T0004',
          value:
            '情報セキュリティプログラム、ポリシー、プロセス、システム、および要素のコスト/便益分析について上級管理職（例：CIO）にアドバイスする。',
          softSkill: false,
        },
        {
          id: 'T0005',
          value:
            '組織のサイバーセキュリティの姿勢に影響を与える変更を適切な経営幹部または承認担当者に通知する。',
          softSkill: false,
        },
        {
          id: 'T0006',
          value: '法律および立法手続における組織の公式立場を支持する。',
          softSkill: false,
        },
        {
          id: 'T0007',
          value: 'データ要件と仕様を分析し、定義する。',
          softSkill: false,
        },
        {
          id: 'T0008',
          value: 'データ容量要件の予測される変化を分析し、計画する。',
          softSkill: false,
        },
        {
          id: 'T0009',
          value:
            '情報を分析して、新しいアプリケーションの開発や既存のアプリケーションの変更を決定、推奨、計画する。',
          softSkill: false,
        },
        {
          id: 'T0010',
          value:
            '組織のサイバー防衛のポリシーと構成を分析し、規制や組織の指令に準拠しているかどうかを評価する。',
          softSkill: false,
        },
        {
          id: 'T0011',
          value:
            'ユーザーニーズとソフトウェア要件を分析して、時間とコストの制約内で設計の実現可能性を判断する。',
          softSkill: false,
        },
        {
          id: 'T0012',
          value:
            '設計制約を分析し、トレードオフと詳細なシステムとセキュリティ設計を分析し、ライフサイクルサポートを検討する。',
          softSkill: false,
        },
        {
          id: 'T0013',
          value:
            'コーディングとテストの標準を適用し、静的解析コードスキャンツール「fuzzing」を含むセキュリティテストツールを適用し、コードレビューを実施する。',
          softSkill: false,
        },
        {
          id: 'T0014',
          value: 'セキュアなコードのドキュメントを適用する。',
          softSkill: false,
        },
        {
          id: 'T0015',
          value:
            'B2B（Business-to-Business）アプリケーションなど、相互にインターフェイスするアプリケーションにセキュリティポリシーを適用する。',
          softSkill: false,
        },
        {
          id: 'T0016',
          value: 'セキュリティポリシーを適用して、システムのセキュリティ目標を達成する。',
          softSkill: false,
        },
        {
          id: 'T0017',
          value:
            '組織の機密性、整合性、および可用性要件を満たすサービス指向セキュリティアーキテクチャの原則を適用する。',
          softSkill: false,
        },
        {
          id: 'T0018',
          value: 'システムによって利用されるサイバーセキュリティ対策の有効性を評価する。',
          softSkill: false,
        },
        {
          id: 'T0019',
          value:
            'コンピュータシステムの脅威と脆弱性を評価し、セキュリティリスクプロファイルを作成する。',
          softSkill: false,
        },
        {
          id: 'T0020',
          value: 'サイバー防衛ツールのコンテンツを開発する。',
          softSkill: false,
        },
        {
          id: 'T0021',
          value:
            '作業モデルまたは理論モデルを使用して製品プロトタイプを構築、テスト、および変更する。',
          softSkill: false,
        },
        {
          id: 'T0022',
          value:
            '要件フェーズで使用されるセキュリティ管理策をキャプチャして、プロセス内のセキュリティを統合し、主要なセキュリティ目標を特定し、計画とスケジュールの中断を最小限に抑えながらソフトウェアのセキュリティを最大化する。',
          softSkill: false,
        },
        {
          id: 'T0023',
          value:
            'ネットワークトラフィックを特徴付け、分析して、異常な活動やネットワークリソースに対する潜在的な脅威を特定する。',
          softSkill: false,
        },
        {
          id: 'T0024',
          value: 'システムのサイバーセキュリティ報告を満たすために必要なデータを収集し、維持する。',
          softSkill: false,
        },
        {
          id: 'T0025',
          value:
            '組織のステークホルダーのあらゆるレベルで情報セキュリティ（IT）セキュリティの価値を伝える。',
          softSkill: false,
        },
        {
          id: 'T0026',
          value:
            'プログラムの開発とその後の改訂に関する文書をコンパイルして記述し、コード化された命令にコメントを挿入して、他の人がプログラムを理解できるようにする。',
          softSkill: false,
        },
        {
          id: 'T0027',
          value:
            'ログファイル、証拠、およびその他の情報の分析を行い、ネットワーク侵入の犯人を特定するための最良の方法を決定する。',
          softSkill: false,
        },
        {
          id: 'T0028',
          value:
            '企業のネットワーク資産に対する、あらかじめ許可されたペネトレーションテストを実施および/またはサポートする。',
          softSkill: false,
        },
        {
          id: 'T0029',
          value: '機能性と接続性のテストを実施し、継続的な操作性を確保する。',
          softSkill: false,
        },
        {
          id: 'T0030',
          value: '効果的な学習環境を作り出すためのインタラクティブなトレーニング演習を実施する。',
          softSkill: false,
        },
        {
          id: 'T0031',
          value: '被害者や証人へのインタビュー、容疑者のインタビューや尋問を行う。',
          softSkill: false,
        },
        {
          id: 'T0032',
          value:
            '個人識別情報（PII）の機密性と完全性を保護する、適切なセキュリティ管理のためのアプリケーションのセキュリティ設計のプライバシー影響評価（PIA）を実施する。',
          softSkill: false,
        },
        {
          id: 'T0033',
          value:
            'リスク分析、実現可能性調査、および/またはトレードオフ分析を行い、機能要件と仕様を開発、文書化、洗練する。',
          softSkill: false,
        },
        {
          id: 'T0034',
          value:
            'システムアナリスト、エンジニア、プログラマー、その他の人々と協力して、アプリケーションを設計し、プロジェクトの制限と機能、パフォーマンス要件、およびインターフェイスに関する情報を入手する。',
          softSkill: false,
        },
        {
          id: 'T0035',
          value:
            'ネットワークハブ、ルータ、およびスイッチ（たとえば、上位プロトコル、トンネリング）を設定および最適化する。',
          softSkill: false,
        },
        {
          id: 'T0036',
          value:
            '可能であれば、動的分析による侵入を特定した後、侵入に関する既知の情報を確認し、可能であれば新しい情報を発見する。',
          softSkill: false,
        },
        {
          id: 'T0037',
          value:
            'エンドユーザーによるアクセスを容易にするために、情報のスイート（リンクページなど）へのアクセスパスを構築する。',
          softSkill: false,
        },
        {
          id: 'T0038',
          value: '顧客インタビューと要件に基づいて脅威モデルを開発する。',
          softSkill: false,
        },
        {
          id: 'T0039',
          value: '機能要件を評価するために、顧客と協議する。',
          softSkill: false,
        },
        {
          id: 'T0040',
          value:
            'ハードウェアとソフトウェアのインタフェースを評価するために、技術スタッフと協議する。',
          softSkill: false,
        },
        {
          id: 'T0041',
          value:
            'サイバー防衛事件を解決するために、企業全体のサイバー防衛技術者を調整し、専門家の技術サポートを提供する。',
          softSkill: false,
        },
        {
          id: 'T0042',
          value:
            'サイバー防衛アナリストと連携して、特別なサイバー防衛アプリケーションのためのルールとシグネチャの更新（侵入検知/保護システム、ウイルス対策、コンテンツブラックリストなど）を管理する。',
          softSkill: false,
        },
        {
          id: 'T0043',
          value: '企業全体のサイバー防衛職員と調整してネットワークアラートを検証する。',
          softSkill: false,
        },
        {
          id: 'T0044',
          value:
            'ステークホルダーと協力して、業務プログラム、戦略、およびミッションアシュアランスの企業継続性を確立する。',
          softSkill: false,
        },
        {
          id: 'T0045',
          value: '必要に応じてシステム設計者や開発者と調整し、設計ソリューションの開発を監視する。',
          softSkill: false,
        },
        {
          id: 'T0046',
          value:
            '適切な変更を行い、プログラムを再確認してエラーを修正し、望ましい結果が得られるようにする。',
          softSkill: false,
        },
        {
          id: 'T0047',
          value:
            'インシデントデータを相関させて特定の脆弱性を特定し、迅速な修復を可能にする推奨事項を作成する。',
          softSkill: false,
        },
        {
          id: 'T0048',
          value:
            '元の証拠が意図せず修正されていないことを保証する証拠（すなわち、法医学的画像）の法医学的に健全な複製を作成し、データ回復および分析プロセスに使用する。これには、ハードドライブ、フロッピーディスク、CD、PDA、携帯電話、GPS、およびすべてのテープ形式が含むが、これらに限定されない。',
          softSkill: false,
        },
        {
          id: 'T0049',
          value: '押収されたデータを技術的手段を用いて解読する。',
          softSkill: false,
        },
        {
          id: 'T0050',
          value:
            '致命的な障害イベントが発生した後、システムの一部または全部を復旧するために必要なシステム機能またはビジネス機能を定義し、優先順位を付ける。',
          softSkill: false,
        },
        {
          id: 'T0051',
          value:
            '重要なシステム機能に基づいて適切なレベルのシステム可用性を定義し、適切なフェールオーバー/代替サイト要件、バックアップ要件、システム復旧/復元のためのマテリアルサポート性要件を含む適切な災害復旧と運用要件の継続性を、システム要件が確実に識別するようにする。',
          softSkill: false,
        },
        {
          id: 'T0052',
          value: '顧客の要求に基づいてプロジェクトの範囲と目標を定義する。',
          softSkill: false,
        },
        {
          id: 'T0053',
          value: 'サイバーセキュリティまたはサイバーセキュリティー対応製品の設計と開発を行う。',
          softSkill: false,
        },
        {
          id: 'T0054',
          value:
            '組織の標準、ビジネスルール、ニーズとの互換性を確保するためのグループポリシーとアクセスコントロールリストを設計する。',
          softSkill: false,
        },
        {
          id: 'T0055',
          value:
            'サイバーセキュリティ要件に適切に対処するためのハードウェア、オペレーティングシステム、およびソフトウェアアプリケーションを設計する。',
          softSkill: false,
        },
        {
          id: 'T0056',
          value:
            'システム全体の設計に適切なデータバックアップ機能を設計または統合し、安全なシステムバックアップとバックアップデータの保護されたストレージのための適切な技術的および手続き的プロセスが確実に存在するようにする。',
          softSkill: false,
        },
        {
          id: 'T0057',
          value:
            '科学的分析と数学的モデルを使用して、設計の結果と結果を予測し測定するソフトウェアシステムの設計、開発、および変更を行う。',
          softSkill: false,
        },
        {
          id: 'T0058',
          value: 'テスト結果に基づいて開発された能力の保証のレベルを決定する。',
          softSkill: false,
        },
        {
          id: 'T0059',
          value:
            'コンピュータやインターネットを利用して犯罪、違法行為、疑わしい行為を調査する計画を策定する。',
          softSkill: false,
        },
        {
          id: 'T0060',
          value: '情報エンドユーザーのニーズと要件を理解する。',
          softSkill: false,
        },
        {
          id: 'T0061',
          value: 'システムのテストと検証の手順とドキュメントを作成し、指導する。',
          softSkill: false,
        },
        {
          id: 'T0062',
          value: '設計手順とプロセスの要件、機能、および制約を開発し、文書化する。',
          softSkill: false,
        },
        {
          id: 'T0063',
          value: 'システム管理標準操作手順を作成し、文書化する。',
          softSkill: false,
        },
        {
          id: 'T0064',
          value:
            'データマイニングとデータウェアハウスのプログラム、プロセス、および要件を確認し、検証する。',
          softSkill: false,
        },
        {
          id: 'T0065',
          value: 'ネットワークのバックアップと復元の手順を開発して実装する。',
          softSkill: false,
        },
        {
          id: 'T0066',
          value: '戦略的計画を策定し、維持する。',
          softSkill: false,
        },
        {
          id: 'T0067',
          value: '技術仕様に沿ったアーキテクチャまたはシステムコンポーネントを開発する。',
          softSkill: false,
        },
        {
          id: 'T0068',
          value: 'データ標準、ポリシー、および手順を開発する。',
          softSkill: false,
        },
        {
          id: 'T0069',
          value:
            'システム設計と開発をサポートするためのコンポーネントおよびインタフェース仕様の詳細なセキュリティ設計文書を作成する。',
          softSkill: false,
        },
        {
          id: 'T0070',
          value:
            '開発中のシステムの災害復旧と運用継続計画を作成し、運用環境に入る前にテストを確実に行う。',
          softSkill: false,
        },
        {
          id: 'T0071',
          value:
            '主に政府組織に適用される複数の分類レベルのデータ（UNCLASSIFIED、SECRET、およびTOP SECRETなど）の処理のための、複数レベルのセキュリティ要件または要件を備えたシステムおよびネットワークのサイバーセキュリティ設計の開発/統合。',
          softSkill: false,
        },
        {
          id: 'T0072',
          value: 'リスク、コンプライアンス、および保証の努力を監視および測定する方法を開発する。',
          softSkill: false,
        },
        {
          id: 'T0073',
          value: '意図された視聴者に適した既存の意識啓発資料を新たに開発するか、それを特定する。',
          softSkill: false,
        },
        {
          id: 'T0074',
          value: '実施のための方針、プログラム、ガイドラインを作成する。',
          softSkill: false,
        },
        {
          id: 'T0075',
          value: '確立された報告手続に従って調査結果の技術概要を提供する。',
          softSkill: false,
        },
        {
          id: 'T0076',
          value:
            '脆弱性を解決し、必要に応じてシステムまたはシステムコンポーネントのセキュリティ変更を推奨するためのリスク軽減戦略を策定する。',
          softSkill: false,
        },
        {
          id: 'T0077',
          value: '安全なコードとエラー処理を開発する。',
          softSkill: false,
        },
        {
          id: 'T0078',
          value:
            'システムやアプリケーションの特定のサイバーセキュリティ対策とリスク軽減戦略を策定する。',
          softSkill: false,
        },
        {
          id: 'T0079',
          value:
            'リスク、コンプライアンス、および保証の取り組みがソフトウェアアプリケーション、システム、およびネットワーク環境レベルでのセキュリティ、レジリエンス、および信頼性の要件に適合するように仕様を開発する。',
          softSkill: false,
        },
        {
          id: 'T0080',
          value: '仕様と要件に対応するためのテスト計画を作成する。',
          softSkill: false,
        },
        {
          id: 'T0081',
          value: 'ネットワーク接続の問題を診断する。',
          softSkill: false,
        },
        {
          id: 'T0082',
          value:
            '取得ライフサイクル全体にわたる組織の情報セキュリティ、サイバーセキュリティアーキテクチャ、およびシステムセキュリティエンジニアリング要件の文書化と処理を行う。',
          softSkill: false,
        },
        {
          id: 'T0083',
          value: 'システム運用における予備的または残存するセキュリティリスクの声明案を作成する。',
          softSkill: false,
        },
        {
          id: 'T0084',
          value: '安全な構成管理プロセスを採用する。',
          softSkill: false,
        },
        {
          id: 'T0085',
          value:
            '必要に応じて、すべてのシステムセキュリティ操作およびメンテナンス作業が適切に文書化され、更新されていることを確認する。',
          softSkill: false,
        },
        {
          id: 'T0086',
          value:
            'システム設計に統合された商用製品のセキュリティパッチの適用が、意図された運用環境の管理当局の指示するタイムラインを満たしていることを確認する。',
          softSkill: false,
        },
        {
          id: 'T0087',
          value:
            '連邦証拠書類に基づいて取得されたすべてのデジタルメディアでは、保管の連鎖が確実に守られるようにする。',
          softSkill: false,
        },
        {
          id: 'T0088',
          value:
            'サイバーセキュリティを有効にした製品やその他の補償的なセキュリティ管理策技術が、特定されたリスクを許容レベルまで確実に低下させるようにする。',
          softSkill: false,
        },
        {
          id: 'T0089',
          value:
            '必要に応じて、セキュリティ改善活動が評価され、検証され、実施されることを確保する。',
          softSkill: false,
        },
        {
          id: 'T0090',
          value:
            '取得または開発されたシステムとアーキテクチャが、組織のサイバーセキュリティアーキテクチャガイドラインと一貫していることを確認する。',
          softSkill: false,
        },
        {
          id: 'T0091',
          value:
            'サイバーセキュリティ検査、テスト、およびレビューがネットワーク環境に合わせて調整されていることを確認する。',
          softSkill: false,
        },
        {
          id: 'T0092',
          value:
            'サイバーセキュリティ要件がそのシステムおよび/または組織の継続計画に統合されていることを確認する。',
          softSkill: false,
        },
        {
          id: 'T0093',
          value:
            'ISセキュリティエンジニアリングアプローチを使用して保護機能と検出機能を取得または開発し、組織レベルのサイバーセキュリティアーキテクチャと一貫していることを確認する。',
          softSkill: false,
        },
        {
          id: 'T0094',
          value: 'ステークホルダーとのコミュニケーションチャネルを確立し、維持する。',
          softSkill: false,
        },
        {
          id: 'T0095',
          value:
            '組織の全体的なセキュリティ戦略により、全体的なエンタープライズ情報セキュリティアーキテクチャ（EISA）を確立する。',
          softSkill: false,
        },
        {
          id: 'T0096',
          value:
            'インシデント対応チームと他のグループ（社内（法務部門など）と外部（法執行機関、ベンダー、広報担当者など）の両方との関係を、可能であれば確立する。',
          softSkill: false,
        },
        {
          id: 'T0097',
          value:
            'ベースラインのセキュリティ対策が適切にインストールされていることを確認するための開発努力を評価し、承認する。',
          softSkill: false,
        },
        {
          id: 'T0098',
          value: '資金調達、法律、およびプログラムの要件を確実に遵守するために、契約を評価する。',
          softSkill: false,
        },
        {
          id: 'T0099',
          value: '意思決定プロセスにおける費用対効果、経済性、リスク分析を評価する。',
          softSkill: false,
        },
        {
          id: 'T0100',
          value:
            'ハードウェア構成を決定するために必要なレポート形式、コスト制約、セキュリティ制限の必要性などの要素を評価する。',
          softSkill: false,
        },
        {
          id: 'T0101',
          value: '既存のトレーニングプログラムの有効性と包括性を評価する。',
          softSkill: false,
        },
        {
          id: 'T0102',
          value: '法律、規制、方針、基準、または手順の有効性を評価する。',
          softSkill: false,
        },
        {
          id: 'T0103',
          value: '手元にある問題との関連性に関する情報について、回復したデータを調べる。',
          softSkill: false,
        },
        {
          id: 'T0104',
          value: 'コンピュータネットワーク攻撃の分析を犯罪および対諜報の調査と運用と融合させる。',
          softSkill: false,
        },
        {
          id: 'T0105',
          value:
            'コンポーネントまたは要素を識別し、それらの要素にセキュリティ機能を割り当て、要素間の関係を記述する。',
          softSkill: false,
        },
        {
          id: 'T0106',
          value: '組織のセキュリティ目標に対処するための代替情報セキュリティ戦略を特定する。',
          softSkill: false,
        },
        {
          id: 'T0107',
          value:
            '新しいシステムのテストと実装で遭遇する技術的問題の修復を特定し、指示する（例えば、相互運用可能でない通信プロトコルの回避策を特定して見つける）。',
          softSkill: false,
        },
        {
          id: 'T0108',
          value: '組織のステークホルダーと連携して重要なビジネス機能を特定し、優先順位を付ける。',
          softSkill: false,
        },
        {
          id: 'T0109',
          value:
            '継続性と可用性に関するシステム全体の要件に基づいて、システム障害後またはシステム回復イベント中の復元または回復のために不可欠な機能またはビジネス機能をサポートするために必要な必須のシステム機能またはサブシステムを特定し、優先順位を付ける。',
          softSkill: false,
        },
        {
          id: 'T0110',
          value:
            'セキュリティ事件が特定の法的措置を必要とする法律違反の兆候であるかどうかを特定および/または判断する。',
          softSkill: false,
        },
        {
          id: 'T0111',
          value: '高水準で基本的な共通のコーディングの欠陥を特定する。',
          softSkill: false,
        },
        {
          id: 'T0112',
          value: '対人諜報と犯罪捜査を支援するための証拠価値のデータまたは知識を特定する。',
          softSkill: false,
        },
        {
          id: 'T0113',
          value: '誤って改ざんされないように、検査と分析のためのデジタル証拠を特定する。',
          softSkill: false,
        },
        {
          id: 'T0114',
          value: '犯罪の証拠の要素を特定する。',
          softSkill: false,
        },
        {
          id: 'T0115',
          value:
            '新しいテクノロジやテクノロジのアップグレードによる情報テクノロジ（IT）セキュリティプログラムの影響を特定する。',
          softSkill: false,
        },
        {
          id: 'T0116',
          value: '組織の政策関係者を特定する。',
          softSkill: false,
        },
        {
          id: 'T0117',
          value:
            'ソフトウェア開発における企業のコンピュータシステム全体にわたる集中化された分散環境内でのセキュリティへの影響を特定し、方法論を適用する。',
          softSkill: false,
        },
        {
          id: 'T0118',
          value:
            'ソフトウェアの安定した運用と管理に関するセキュリティ上の問題を特定し、製品の寿命が終わったときに行わなければならないセキュリティ対策を組み込む。',
          softSkill: false,
        },
        {
          id: 'T0119',
          value:
            'サイバーセキュリティまたはサイバーセキュリティ対応製品をシステム内で使用するために特定、評価、推奨し、推奨製品が組織の評価および検証要件に準拠していることを確認する。',
          softSkill: false,
        },
        {
          id: 'T0120',
          value:
            'サイバー侵入事件、調査、および操作に関連するデジタルメディアとログを含めるために、ドキュメンタリーまたは物理的証拠を特定、収集、および捕捉する。',
          softSkill: false,
        },
        {
          id: 'T0121',
          value: '新しいシステム設計手順、テスト手順、および品質基準を実装する。',
          softSkill: false,
        },
        {
          id: 'T0122',
          value: '新規または既存のシステムのセキュリティ設計を実装する。',
          softSkill: false,
        },
        {
          id: 'T0123',
          value: 'システムやアプリケーションに特定のサイバーセキュリティ対策を実装する。',
          softSkill: false,
        },
        {
          id: 'T0124',
          value:
            'サイバーセキュリティ脆弱性ソリューションをシステム設計（サイバーセキュリティ脆弱性アラートなど）に組み込む。',
          softSkill: false,
        },
        {
          id: 'T0125',
          value:
            'ネットワークインフラストラクチャデバイスのオペレーティングシステムソフトウェア（IOS、ファームウェアなど）をインストールし、維持する。',
          softSkill: false,
        },
        {
          id: 'T0126',
          value: 'ネットワークハブ、ルータ、およびスイッチの取り付けまたは交換する。',
          softSkill: false,
        },
        {
          id: 'T0127',
          value:
            '情報セキュリティおよび/またはサイバーセキュリティポリシーを統合して整列させ、システム分析がセキュリティ要件を満たすことを保証する。',
          softSkill: false,
        },
        {
          id: 'T0128',
          value:
            '現場でのシステムソフトウェアの更新やパッチ適用のための自動化された機能を統合し、システムの運用環境の現在および将来のパッチのタイムライン要件に基づいて、システムソフトウェアの手動更新とパッチ適用のプロセスと手順を開発する。',
          softSkill: false,
        },
        {
          id: 'T0129',
          value: '新しいシステムを既存のネットワークアーキテクチャに統合する。',
          softSkill: false,
        },
        {
          id: 'T0130',
          value:
            'インシデントやその他のコンピュータネットワーク防衛情報の適切かつ正確な配布を確保するために、外部組織（例：広報、法執行機関、コマンドまたはコンポーネントインスペクタ）と連携する。',
          softSkill: false,
        },
        {
          id: 'T0131',
          value: '法律、規則、方針、基準、または手順を解釈して特定の問題に適用する。',
          softSkill: false,
        },
        {
          id: 'T0132',
          value: '新しい情報技術の能力に関連するセキュリティ要件を解釈および/または承認する。',
          softSkill: false,
        },
        {
          id: 'T0133',
          value:
            '違反のパターンを解釈して、企業のサイバーセキュリティプログラムのリスクレベルや全体的な有効性への影響を判断する。',
          softSkill: false,
        },
        {
          id: 'T0134',
          value: '情報技術（IT）のセキュリティ優先順位をセキュリティ戦略と結びつける。',
          softSkill: false,
        },
        {
          id: 'T0135',
          value: '情報セキュリティ予算、人材派遣、契約をリードし監督する。',
          softSkill: false,
        },
        {
          id: 'T0136',
          value: '組織ポリシーに従って、ベースラインのシステムセキュリティを維持する。',
          softSkill: false,
        },
        {
          id: 'T0137',
          value: 'データベース管理システムソフトウェアを保守する。',
          softSkill: false,
        },
        {
          id: 'T0138',
          value:
            'サイバー防衛に関する監査の任務を支援するために、展開可能なサイバー防衛監査のツールキット（例えば、専門のサイバー防衛ソフトウェアとハ​​ードウェア）を維持する。',
          softSkill: false,
        },
        {
          id: 'T0139',
          value:
            '最適化されたルーティングを使用して、情報がリアサーバから転送ユニットに自動的に複製されるようにするディレクトリ複製サービスを維持する。',
          softSkill: false,
        },
        {
          id: 'T0140',
          value:
            'ユーザが必要に応じて重要な情報を送受信できるようにする、パブリッシュ、サブスクライブ、アラート機能を通じて情報交換を維持する。',
          softSkill: false,
        },
        {
          id: 'T0141',
          value: '情報システムの保証および認定資料を維持する。',
          softSkill: false,
        },
        {
          id: 'T0142',
          value:
            'サイバー防衛の監査に関連する適用可能なサイバー防衛政策、規制、コンプライアンス文書の知識を維持する。',
          softSkill: false,
        },
        {
          id: 'T0143',
          value: 'テスト結果に基づいて勧告を行う。',
          softSkill: false,
        },
        {
          id: 'T0144',
          value: 'アカウント、ネットワークの権利、システムや機器へのアクセスを管理する。',
          softSkill: false,
        },
        {
          id: 'T0145',
          value: '認定パッケージ（ISO/IEC 15026-2など）の管理と承認を行う。',
          softSkill: false,
        },
        {
          id: 'T0146',
          value: 'コンパイル、カタログ作成、キャッシュ、配布、およびデータの取得を管理する。',
          softSkill: false,
        },
        {
          id: 'T0147',
          value: '情報セキュリティデータソースの監視を管理し、組織の状況認識を維持する。',
          softSkill: false,
        },
        {
          id: 'T0148',
          value:
            'エンタープライズ構成員のためのコンピュータネットワーク防衛ガイダンス（TCNO、運用コンセプト、Net Analystレポート、NTSM、MTOなど）の発行を管理する。',
          softSkill: false,
        },
        {
          id: 'T0149',
          value: 'サイバー防衛情報の脅威または標的分析、および企業内の脅威情報の作成を管理する。',
          softSkill: false,
        },
        {
          id: 'T0150',
          value:
            'システムのIT(情報技術)セキュリティ、レジリエンス、および信頼性要件との適合性を監視および評価する。',
          softSkill: false,
        },
        {
          id: 'T0151',
          value:
            '企業のサイバーセキュリティ保障措置の有効性を監視し、評価し、意図したレベルの保護を確実に提供する。',
          softSkill: false,
        },
        {
          id: 'T0152',
          value: '最適なパフォーマンスを確保するためにデータベースを監視および維持する。',
          softSkill: false,
        },
        {
          id: 'T0153',
          value: 'ネットワーク容量とパフォーマンスを監視する。',
          softSkill: false,
        },
        {
          id: 'T0154',
          value: 'ナレッジマネジメント資産およびリソースの使用状況を監視および報告する。',
          softSkill: false,
        },
        {
          id: 'T0155',
          value:
            '環境に継続的かつ即座に影響を与える可能性のあるインシデント（イベントの履歴、ステータス、および今後のアクションの潜在的影響を含む）を文書化してエスカレートする。',
          softSkill: false,
        },
        {
          id: 'T0156',
          value: '構成管理に関する監督と勧告を行う。',
          softSkill: false,
        },
        {
          id: 'T0157',
          value: '情報セキュリティの訓練と意識向上プログラムを監督する。',
          softSkill: false,
        },
        {
          id: 'T0158',
          value:
            'セキュリティ評価および承認プロセス中の情報セキュリティリスクアセスメントに参加する。',
          softSkill: false,
        },
        {
          id: 'T0159',
          value:
            'コンピュータ環境のサイバーセキュリティプログラムの計画と要件の開発または変更に参加する。',
          softSkill: false,
        },
        {
          id: 'T0160',
          value:
            'ネットワークの脆弱性にパッチを当てて、情報が外部の当事者に対して確実に保護されるようにする。',
          softSkill: false,
        },
        {
          id: 'T0161',
          value:
            'さまざまなソース（たとえば、個々のホストログ、ネットワークトラフィックログ、ファイアウォールログ、侵入検知システム[IDS]ログ）からのログファイルの分析を実行して、ネットワークセキュリティの可能性のある脅威を特定する。',
          softSkill: false,
        },
        {
          id: 'T0162',
          value: 'データベースのバックアップとリカバリを実行して、データの整合性を確保する。',
          softSkill: false,
        },
        {
          id: 'T0163',
          value:
            '範囲、緊急性、潜在的な影響の特定、特定の脆弱性の特定、迅速な修復を可能にする推奨事項の作成を含む、サイバー防衛インシデントトリアージを実行する。',
          softSkill: false,
        },
        {
          id: 'T0164',
          value: 'サイバー防衛トレンドの分析と報告を行う。',
          softSkill: false,
        },
        {
          id: 'T0165',
          value:
            '動的解析を実行して、ドライブの「イメージ」を起動して（必ずしもオリジナルのドライブがなくても）、ネイティブ環境でユーザーが見た場合の侵入を確認する。',
          softSkill: false,
        },
        {
          id: 'T0166',
          value:
            '企業内のさまざまなソースから収集された情報を使用してイベント相関を実行し、状況認識を取得し、観察された攻撃の有効性を判断する。',
          softSkill: false,
        },
        {
          id: 'T0167',
          value: 'ファイルのシグネチャ分析を実行する。',
          softSkill: false,
        },
        {
          id: 'T0168',
          value: '確立されたデータベースに対してハッシュ比較を実行する。',
          softSkill: false,
        },
        {
          id: 'T0169',
          value: '開発されたアプリケーションやシステムのサイバーセキュリティテストを実施する。',
          softSkill: false,
        },
        {
          id: 'T0170',
          value:
            'イメージの最初の、法廷での健全な収集を行い、エンタープライズシステム上で起こり得る緩和/修復を識別するために検査する。',
          softSkill: false,
        },
        {
          id: 'T0171',
          value: 'セキュリティ機能と復元力に対する攻撃のための統合された品質保証テストを実行する。',
          softSkill: false,
        },
        {
          id: 'T0172',
          value:
            'リアルタイムのフォレンジック分析を実行する（例えば、HelixとLiveViewを併用する）。',
          softSkill: false,
        },
        {
          id: 'T0173',
          value: 'タイムライン分析を実行する。',
          softSkill: false,
        },
        {
          id: 'T0174',
          value:
            '新しく改善されたビジネスプロセスソリューションの機会を判断するために、ニーズ分析を実行する。',
          softSkill: false,
        },
        {
          id: 'T0175',
          value:
            '配備可能なインシデント対応チーム（IRT）をサポートするためのリアルタイムのサイバー防衛インシデント処理（フォレンジックコレクション、侵入相関および追跡、脅威分析、および直接システム修復）を実行する。',
          softSkill: false,
        },
        {
          id: 'T0176',
          value: '安全なプログラミングを実践し、コードの潜在的な欠陥を特定して脆弱性を緩和する。',
          softSkill: false,
        },
        {
          id: 'T0177',
          value:
            'セキュリティレビューを実行し、セキュリティアーキテクチャのギャップを特定し、セキュリティリスク管理計画を策定する。',
          softSkill: false,
        },
        {
          id: 'T0178',
          value:
            'セキュリティレビューを実行し、セキュリティアーキテクチャのセキュリティギャップを特定し、リスク軽減戦略に含めることを推奨する。',
          softSkill: false,
        },
        {
          id: 'T0179',
          value: '静的メディア解析を実行する。',
          softSkill: false,
        },
        {
          id: 'T0180',
          value:
            'インストール、構成、保守、バックアップ、および復元を含めるために、特別なサイバー防衛アプリケーションおよびシステム（ウイルス対策、監査および修復など）または仮想プライベートネットワーク（VPN）デバイスでシステム管理を実行する。',
          softSkill: false,
        },
        {
          id: 'T0181',
          value:
            'アプリケーションやシステムに重大な変更が加えられるたびに、リスク分析（脅威、脆弱性、発生確率など）を実行する。',
          softSkill: false,
        },
        {
          id: 'T0182',
          value: '階層1、2、および3のマルウェア分析を実行する。',
          softSkill: false,
        },
        {
          id: 'T0183',
          value:
            '実際の結果と期待される結果を比較する検証ステップを実行し、違いを分析して影響とリスクを特定する。',
          softSkill: false,
        },
        {
          id: 'T0184',
          value:
            'システムおよびネットワークの初期インストールのためのセキュリティ認証の審査と保証ケースの開発を計画し、実施する。',
          softSkill: false,
        },
        {
          id: 'T0185',
          value: '知識管理プロジェクトの提供を計画し、管理する。',
          softSkill: false,
        },
        {
          id: 'T0186',
          value: 'データの冗長性とシステム回復手順を計画、実行、検証する。',
          softSkill: false,
        },
        {
          id: 'T0187',
          value: '運動結果やシステム環境に基づいて修正や調整を計画し、推奨する。',
          softSkill: false,
        },
        {
          id: 'T0188',
          value:
            '技術的および手順上の知見を特定する監査レポートを準備し、推奨される改善事項に関する戦略/ソリューションを提供する。',
          softSkill: false,
        },
        {
          id: 'T0189',
          value:
            '入力、出力、および論理操作を記述する詳細なワークフローチャートと図を準備し、それらをコンピュータ言語でコーディングされた一連の命令に変換する。',
          softSkill: false,
        },
        {
          id: 'T0190',
          value:
            'データの完全性を保証することにより、イメージング用のデジタルメディアを準備する（標準的な操作手順に従ってブロッカーを書き込むなど）。',
          softSkill: false,
        },
        {
          id: 'T0191',
          value:
            '特定のIT（情報技術）ソリューションの必要性を正当化するためのユースケースを準備する。',
          softSkill: false,
        },
        {
          id: 'T0192',
          value:
            'ネットワークシステムの運用の安全性に関する計画、指示、指針、標準的な運用手順を準備し、配布し、維持する。',
          softSkill: false,
        },
        {
          id: 'T0193',
          value: '犯罪シーンを処理する。',
          softSkill: false,
        },
        {
          id: 'T0194',
          value:
            '必要に応じて、すべてのシステムセキュリティの実装、運用、およびメンテナンス活動を適切に文書化し、更新する。',
          softSkill: false,
        },
        {
          id: 'T0195',
          value:
            'ミッション要件に基づいて関連情報の管理された流れを（ウェブベースのポータルまたは他の手段を介して）提供する。',
          softSkill: false,
        },
        {
          id: 'T0196',
          value: 'プロジェクト費用、設計コンセプト、または設計変更に関するアドバイスを提供する。',
          softSkill: false,
        },
        {
          id: 'T0197',
          value:
            'ソフトウェアアプリケーション、システム、またはネットワークの正確な技術評価を提供し、関連するサイバーセキュリティコンプライアンスに対するセキュリティの姿勢、能力、および脆弱性を文書化する。',
          softSkill: false,
        },
        {
          id: 'T0198',
          value:
            'サイバー防衛慣行に関連するネットワークイベントおよび活動の日次要約レポートを提供する。',
          softSkill: false,
        },
        {
          id: 'T0199',
          value:
            '業務継続計画の開発のための企業のサイバーセキュリティとサプライチェーンリスクマネジメントのガイダンスを提供する。',
          softSkill: false,
        },
        {
          id: 'T0200',
          value:
            'ネットワークアーキテクチャやインフラストラクチャなどのネットワーク要件に関するフィードバックを提供する。',
          softSkill: false,
        },
        {
          id: 'T0201',
          value: '開発されたシステムを顧客または導入チームに実装するためのガイドラインを提供する。',
          softSkill: false,
        },
        {
          id: 'T0202',
          value: 'リーダーシップにサイバーセキュリティの指針を提供する。',
          softSkill: false,
        },
        {
          id: 'T0203',
          value:
            '業務声明やその他の適切な調達文書に含めるべきセキュリティ要件に関する情報を提供する。',
          softSkill: false,
        },
        {
          id: 'T0204',
          value: '導入計画と標準的な運用手順を入力する。',
          softSkill: false,
        },
        {
          id: 'T0205',
          value:
            'リスク管理フレームワークのプロセス活動および関連する文書（例えば、システムライフサイクルサポート計画、運用の概念、運用手順、および保守トレーニング資料）を入力する。',
          softSkill: false,
        },
        {
          id: 'T0206',
          value:
            '職務遂行に責任を負うサイバーセキュリティ意識、基本、識字能力、トレーニングを確実に提供することによって、情報技術（IT）要員のリーダーシップと方向性を提供する。',
          softSkill: false,
        },
        {
          id: 'T0207',
          value: '進行中の最適化と問題解決のサポートを提供する。',
          softSkill: false,
        },
        {
          id: 'T0208',
          value: '改善とアップグレードの可能性に関する推奨事項を提供する。',
          softSkill: false,
        },
        {
          id: 'T0209',
          value:
            'レポート/管理情報の正確かつ高品質な作成を保証するデータ構造およびデータベースに関する推奨を提供する。',
          softSkill: false,
        },
        {
          id: 'T0210',
          value: '新しいデータベーステクノロジとアーキテクチャに関する推奨事項を提供する。',
          softSkill: false,
        },
        {
          id: 'T0211',
          value:
            '仕事の声明やその他の適切な調達文書に含めるべきサイバーセキュリティ要件に関するシステム関連の情報を提供する。',
          softSkill: false,
        },
        {
          id: 'T0212',
          value: 'デジタル証拠に関する技術援助を適切な要員に提供する。',
          softSkill: false,
        },
        {
          id: 'T0213',
          value:
            'テクニカルドキュメント、インシデントレポート、コンピュータ検査の結果、要約、およびその他の状況認識情報を上位本部に提供する。',
          softSkill: false,
        },
        {
          id: 'T0214',
          value:
            '企業内のさまざまなソースからのネットワークアラートを受信して分析し、そのようなアラートの原因を特定する。',
          softSkill: false,
        },
        {
          id: 'T0215',
          value: 'セキュリティ侵害の可能性を認識し、必要に応じて適切な措置を講じて事件を報告する。',
          softSkill: false,
        },
        {
          id: 'T0216',
          value:
            '特定のオペレーティングシステムを示すフォレンジックアーチファクトを認識し、正確に報告する。',
          softSkill: false,
        },
        {
          id: 'T0217',
          value:
            '完了基準、リスク受け入れおよび文書化、共通基準、および独立したテストの方法を含むソフトウェア受諾フェーズにおけるセキュリティの影響を扱う。',
          softSkill: false,
        },
        {
          id: 'T0218',
          value:
            'レビューの結果に基づいて、新しいまたは改訂されたセキュリティ、レジリエンス、信頼性の対策を推奨します。',
          softSkill: false,
        },
        {
          id: 'T0219',
          value:
            '組織のサイバーセキュリティ要件を安全に運用および維持するために必要なリソース配分を推奨する。',
          softSkill: false,
        },
        {
          id: 'T0220',
          value: '法律、規則、方針、基準、または手続における紛争を解決する。',
          softSkill: false,
        },
        {
          id: 'T0221',
          value:
            '承認レベルと保証ドキュメントを確認して、ソフトウェアアプリケーション、システム、およびネットワークごとにリスクレベルが許容範囲内にあることを確認する。',
          softSkill: false,
        },
        {
          id: 'T0222',
          value: 'ステークホルダーとの既存のポリシーおよび提案されたポリシーを確認する。',
          softSkill: false,
        },
        {
          id: 'T0223',
          value: '情報技術（IT）プログラムおよびプロジェクトの監査をレビューまたは実施する。',
          softSkill: false,
        },
        {
          id: 'T0224',
          value:
            'トレーニング文書（例：コース内容文書[CCD]、レッスン計画、学生テキスト、試験、指導スケジュール[SOI]、およびコースの説明）を確認する。',
          softSkill: false,
        },
        {
          id: 'T0225',
          value: '電子機器または情報源を保護する。',
          softSkill: false,
        },
        {
          id: 'T0226',
          value: '代理店や省庁の政策委員会に出席する。',
          softSkill: false,
        },
        {
          id: 'T0227',
          value: 'ポリシーを推奨し、レビューと承認を調整する。',
          softSkill: false,
        },
        {
          id: 'T0228',
          value: 'システムの機能と要件を分析するためのデータの格納、取得、および操作を行う。',
          softSkill: false,
        },
        {
          id: 'T0229',
          value:
            'サイバーセキュリティのインシデントや脆弱性が発見されたときの保護対策または是正措置を監督または管理する。',
          softSkill: false,
        },
        {
          id: 'T0230',
          value: '運動シナリオの設計と実行をサポートする。',
          softSkill: false,
        },
        {
          id: 'T0231',
          value: 'セキュリティ/認証テストおよび評価活動へのサポートを提供する。',
          softSkill: false,
        },
        {
          id: 'T0232',
          value:
            'ソフトウェアおよびハードウェアデバイスを含むネットワークインフラストラクチャをテストし、維持する。',
          softSkill: false,
        },
        {
          id: 'T0233',
          value: 'サイバー防衛インシデントを最初の検出から最終的な解決まで追跡し、文書化する。',
          softSkill: false,
        },
        {
          id: 'T0234',
          value: '監査の結果と推奨事項を追跡して、適切な軽減措置を取る。',
          softSkill: false,
        },
        {
          id: 'T0235',
          value: '機能要件を技術的ソリューションに変換する。',
          softSkill: false,
        },
        {
          id: 'T0236',
          value:
            'セキュリティ要件を、ソフトウェア攻撃の要素の文書化、脅威モデルの作成、特定のセキュリティ基準の定義など、アプリケーション設計要素に変換する。',
          softSkill: false,
        },
        {
          id: 'T0237',
          value: 'システムハードウェアとソフトウェアのトラブルシューティングを行う。',
          softSkill: false,
        },
        {
          id: 'T0238',
          value:
            'データ彫刻技術（例えば、Forensic Tool Kit [FTK]、Foremost）を使用してデータを抽出する。',
          softSkill: false,
        },
        {
          id: 'T0239',
          value:
            '連邦および組織固有の公開文書を使用して、コンピューティング環境システムの運用を管理する。',
          softSkill: false,
        },
        {
          id: 'T0240',
          value:
            'ネットワーク監視ツールを使用して、悪意のあるアクティビティに関連するネットワークトラフィックをキャプチャして分析する。',
          softSkill: false,
        },
        {
          id: 'T0241',
          value:
            'デジタル証拠のカタログ化、文書化、抽出、収集、パッケージング、および保存には、特殊な機器と技術を使用する。',
          softSkill: false,
        },
        {
          id: 'T0242',
          value:
            'モデルとシミュレーションを利用して、さまざまな動作条件下でシステム性能を分析または予測する。',
          softSkill: false,
        },
        {
          id: 'T0243',
          value:
            'アプリケーション/システムのセキュリティ設計機能を反映したセキュリティ文書を検証し、更新する。',
          softSkill: false,
        },
        {
          id: 'T0244',
          value:
            'アプリケーションソフトウェア/ネットワーク/システムセキュリティの姿勢が前述のように実装されていること、文書の逸脱を確認し、それらの逸脱を修正するために必要な措置を推奨する。',
          softSkill: false,
        },
        {
          id: 'T0245',
          value:
            'ソフトウェアアプリケーション/ネットワーク/システムの認証および保証ドキュメントが最新であることを確認する。',
          softSkill: false,
        },
        {
          id: 'T0246',
          value:
            'インシデント発見に関するサイバー防衛のテクニック、ガイダンス、レポートを適切な取引先に提出する。',
          softSkill: false,
        },
        {
          id: 'T0247',
          value:
            '労働者の関連部分に詳細な指針を提供するための指導教材（標準的な操作手順、製造マニュアルなど）を作成する。',
          softSkill: false,
        },
        {
          id: 'T0248',
          value:
            'マネジメントの間のセキュリティ問題の認識を促進し、健全なセキュリティ原則が組織のビジョンと目標に反映されるようにする。',
          softSkill: false,
        },
        {
          id: 'T0249',
          value: '必要なシステムまたはネットワークの機能を理解するための現在の技術を研究する。',
          softSkill: false,
        },
        {
          id: 'T0250',
          value:
            'ミッション要件に基づいてカスタムハードウェアおよびソフトウェア開発のサイバー機能戦略を特定する。',
          softSkill: false,
        },
        {
          id: 'T0251',
          value:
            'セキュリティコンプライアンスプロセスおよび/または外部サービス（クラウドサービスプロバイダ、データセンターなど）の監査を開発する。',
          softSkill: false,
        },
        {
          id: 'T0252',
          value:
            '環境に応じた適切性として、必要となるレビューを実施する（例：技術サーベイランス、対策レビュー[TSCM]、TEMPEST対策レビュー）。',
          softSkill: false,
        },
        {
          id: 'T0253',
          value: '簡単なバイナリ分析を実行する。',
          softSkill: false,
        },
        {
          id: 'T0254',
          value:
            '手順とガイドラインがサイバーセキュリティポリシーに準拠していることを保証するための政策基準と実施戦略を監督する。',
          softSkill: false,
        },
        {
          id: 'T0255',
          value:
            'リスク・ガバナンス・プロセスに参加して、セキュリティリスク、緩和、および他の技術リスクに関する情報を提供する。',
          softSkill: false,
        },
        {
          id: 'T0256',
          value:
            '調達活動を通じて情報セキュリティ要件およびサプライチェーンリスクに対処する際の調達機能の有効性を評価し、改善を推奨する。',
          softSkill: false,
        },
        {
          id: 'T0257',
          value:
            'スコープ、インフラストラクチャ、リソース、およびデータサンプルサイズを決定し、システム要件が適切に実証されるようにする。',
          softSkill: false,
        },
        {
          id: 'T0258',
          value:
            '潜在的な攻撃/侵入、異常な活動、および悪用の活動の適時の検出、識別、警告を提供し、これらの事件や出来事を良性の活動と区別する。',
          softSkill: false,
        },
        {
          id: 'T0259',
          value:
            'サイバー防衛ツールを使用して、システムの活動を継続的に監視および分析して悪意のある活動を識別する。',
          softSkill: false,
        },
        {
          id: 'T0260',
          value:
            '特定された悪意のある行為を分析して、悪用された弱点、搾取方法、システムおよび情報への影響を判断する。',
          softSkill: false,
        },
        {
          id: 'T0261',
          value:
            '重要なサイバー防衛基盤と主要リソースの保護を特定、優先順位付け、調整することを支援する。',
          softSkill: false,
        },
        {
          id: 'T0262',
          value:
            '承認された徹底的な防衛原則と実践（例えば、複数の場所での防衛、多層防御、セキュリティ堅牢性）を採用する。',
          softSkill: false,
        },
        {
          id: 'T0263',
          value:
            'システムライフサイクルのすべてのフェーズで情報技術（IT）システムに固有のセキュリティ要件を特定する。',
          softSkill: false,
        },
        {
          id: 'T0264',
          value:
            'リスクアセスメント、監査、検査などで特定された脆弱性のための行動計画とマイルストーンまたは改善計画が確実に作成されていることを確認する。',
          softSkill: false,
        },
        {
          id: 'T0265',
          value:
            '組織の使命と目標に合致したセキュリティ要件と適切なIT（情報技術）ポリシーと手順の実装と機能が正常に実行されることを保証する。',
          softSkill: false,
        },
        {
          id: 'T0266',
          value:
            '新規または更新されたアプリケーションの必要に応じてペネトレーションテストを実行する。',
          softSkill: false,
        },
        {
          id: 'T0267',
          value:
            'システムと要素のプログラミング言語の弱点や脆弱性の潜在的な利用に対する対策と対策を設計する。',
          softSkill: false,
        },
        {
          id: 'T0268',
          value:
            '新しいシステムの実装またはシステム間の新しいインターフェイスが現在の環境のセキュリティの姿勢にどのように影響するかを定義し、文書化する。',
          softSkill: false,
        },
        {
          id: 'T0269',
          value: 'キー管理機能の設計と開発（サイバーセキュリティに関連して）を行う。',
          softSkill: false,
        },
        {
          id: 'T0270',
          value: 'システムのセキュリティ開発の計画と実施に必要なユーザーのニーズと要件を分析する。',
          softSkill: false,
        },
        {
          id: 'T0271',
          value:
            '特定の運用ニーズや環境要因（例えば、アクセス制御、自動化アプリケーション、ネットワーク運用、高い整合性と可用性要件、複数レベルのセキュリティ/複数の分類レベルの処理、機密情報の処理）に対応するサイバーセキュリティ設計を開発する。',
          softSkill: false,
        },
        {
          id: 'T0272',
          value:
            'セキュリティ設計とサイバーセキュリティ開発活動が適切に文書化され（セキュリティ実装の機能記述を提供する）、必要に応じて更新されるようにする。',
          softSkill: false,
        },
        {
          id: 'T0273',
          value: '必要に応じて、重要なシステム要素のサプライチェーンリスクを開発し、文書化する。',
          softSkill: false,
        },
        {
          id: 'T0274',
          value: 'セキュリティ対策の監査可能な証拠を作成する。',
          softSkill: false,
        },
        {
          id: 'T0275',
          value:
            '必要なコンプライアンス活動を支援する（例えば、システムセキュリティ設定ガイドラインに従うこと、コンプライアンスモニタリングが行われることを保証するなど）。',
          softSkill: false,
        },
        {
          id: 'T0276',
          value:
            '適切なサプライチェーン・リスク管理の慣行に従って、必要に応じて取得プロセスに参加する。',
          softSkill: false,
        },
        {
          id: 'T0277',
          value:
            'すべての買収、調達、およびアウトソーシングの努力が、組織の目標に沿った情報セキュリティ要件に対応することを確実にする。',
          softSkill: false,
        },
        {
          id: 'T0278',
          value:
            '侵入成果物（ソースコード、マルウェア、トロイの木馬など）を収集し、発見されたデータを使用して、企業内の潜在的なサイバー防衛事件の軽減を可能にする。',
          softSkill: false,
        },
        {
          id: 'T0279',
          value:
            'テクニカルエキスパートとして働き、法執行要員に連絡し、必要に応じてインシデントの詳細を説明する。',
          softSkill: false,
        },
        {
          id: 'T0280',
          value:
            'コンプライアンスを確実にするため、ポリシー/ガイドライン/手順/規制/法律から組織を継続的に検証する。',
          softSkill: false,
        },
        {
          id: 'T0281',
          value: '進行中のサービス要求を予測し、必要に応じてセキュリティの前提条件を確認する。',
          softSkill: false,
        },
        {
          id: 'T0282',
          value:
            '重要なインフラストラクチャを適切に保護するためのポリシーと手順を定義および/または実装する。',
          softSkill: false,
        },
        {
          id: 'T0283',
          value:
            'ステークホルダーと連携して、適切なソリューション技術を特定および/または開発する。',
          softSkill: false,
        },
        {
          id: 'T0284',
          value: 'サイバーセキュリティに関連する新しいツール/テクノロジーを設計し、開発する。',
          softSkill: false,
        },
        {
          id: 'T0285',
          value: 'デジタルメディアでウイルススキャンを実行する。',
          softSkill: false,
        },
        {
          id: 'T0286',
          value: 'ファイルシステムのフォレンジック分析を実行する。',
          softSkill: false,
        },
        {
          id: 'T0287',
          value:
            '静的解析を実行して、ドライブの「イメージ」をマウントする（必ずしも元のドライブを使用する必要はない）。',
          softSkill: false,
        },
        {
          id: 'T0288',
          value: '静的マルウェア分析を実行する。',
          softSkill: false,
        },
        {
          id: 'T0289',
          value: '展開可能なフォレンジックツールキットを活用して、必要に応じて操作をサポートする。',
          softSkill: false,
        },
        {
          id: 'T0290',
          value: '侵入セットのための戦術、技術、および手順（TTP）を決定する。',
          softSkill: false,
        },
        {
          id: 'T0291',
          value: 'ネットワークトポロジを調べて、ネットワークを介したデータフローを理解する。',
          softSkill: false,
        },
        {
          id: 'T0292',
          value: 'コンピューティング環境の脆弱性の修正を推奨する。',
          softSkill: false,
        },
        {
          id: 'T0293',
          value: 'メタデータを使用してネットワークトラフィックの異常を特定し、分析する。',
          softSkill: false,
        },
        {
          id: 'T0294',
          value:
            'さまざまなソースデータセット（表示と警告）の幅広い種類にわたる調査、分析、相関を実行する。',
          softSkill: false,
        },
        {
          id: 'T0295',
          value:
            'パケット解析ツールを使用して、ネットワークトラフィックに対する侵入検知システム（IDS）のアラートを検証する。',
          softSkill: false,
        },
        {
          id: 'T0296',
          value: 'マルウェアを隔離して削除する。',
          softSkill: false,
        },
        {
          id: 'T0297',
          value:
            'ネットワークトラフィックに基づいて、ネットワークデバイスのアプリケーションとオペレーティングシステムを特定する。',
          softSkill: false,
        },
        {
          id: 'T0298',
          value: 'ネットワークトラフィックに基づいて悪意のある攻撃や活動を再構築する。',
          softSkill: false,
        },
        {
          id: 'T0299',
          value:
            'ネットワークマッピングとオペレーティングシステム（OS）のフィンガープリンティングアクティビティーを識別する。',
          softSkill: false,
        },
        {
          id: 'T0300',
          value:
            '情報アーキテクチャとユーザーインターフェイスの要件を含むユーザーエクスペリエンス（UX）の要件を開発し、文書化する。',
          softSkill: false,
        },
        {
          id: 'T0301',
          value:
            'アプリケーションソフトウェア/ネットワーク/システムのためのサイバーセキュリティ独立監査プロセスを開発・実施し、運用および研究開発（R&D）プロセスと手順が組織の義務的なサイバーセキュリティ要件に準拠し、システム管理者および他のサイバーセキュリティスタッフが日常活動を行う際に正確に従っていることを確認するために、継続的な独立監査を監督する。',
          softSkill: false,
        },
        {
          id: 'T0302',
          value:
            'サプライチェーン、システム、ネットワーク、および運用上のセキュリティが確実に満たされるように契約言語を開発する。',
          softSkill: false,
        },
        {
          id: 'T0303',
          value:
            'セキュアなアプリケーションの設計と開発を行う際に、エンタープライズ全体のバージョン管理システムを特定して活用する。',
          softSkill: false,
        },
        {
          id: 'T0304',
          value:
            'システム開発ライフサイクル（SDLC）メソドロジー（例えば、IBM Rational Unified Process）を開発環境に実装し、統合する。',
          softSkill: false,
        },
        {
          id: 'T0305',
          value:
            'データベースおよびデータ管理システムの構成管理、問題管理、容量管理、財務管理を実行する。',
          softSkill: false,
        },
        {
          id: 'T0306',
          value:
            'インシデント管理、サービスレベル管理、変更管理、リリース管理、継続性管理、およびデータベースとデータ管理システムの可用性管理をサポートする。',
          softSkill: false,
        },
        {
          id: 'T0307',
          value:
            '候補アーキテクチャの分析、セキュリティサービスの割り当て、セキュリティメカニズムの選択を行う。',
          softSkill: false,
        },
        {
          id: 'T0308',
          value: '新たな傾向についてインシデントデータを分析する。',
          softSkill: false,
        },
        {
          id: 'T0309',
          value: 'セキュリティ管理の有効性を評価する。',
          softSkill: false,
        },
        {
          id: 'T0310',
          value:
            'ネットワーク環境またはエンクレーブ内の新規または観察された脅威に対応してサイバー防衛ネットワークツールに実装できるシグネチャの構築を支援する。',
          softSkill: false,
        },
        {
          id: 'T0311',
          value: 'ソフトウェアシステムの設計と保守について、顧客と協議する。',
          softSkill: false,
        },
        {
          id: 'T0312',
          value: 'インテリジェンスアナリストと調整して、脅威評価データを相関させる。',
          softSkill: false,
        },
        {
          id: 'T0313',
          value: '品質基準を設計し文書化する。',
          softSkill: false,
        },
        {
          id: 'T0314',
          value:
            'システムセキュリティコンテキスト、予備システムセキュリティコンセプト（CONOPS）を開発し、適用可能なサイバーセキュリティ要件に従ってベースラインシステムセキュリティ要件を定義する。',
          softSkill: false,
        },
        {
          id: 'T0315',
          value:
            '他の人を教育したり、顧客のニーズを満たすための技術トレーニングを開発し、提供する。',
          softSkill: false,
        },
        {
          id: 'T0316',
          value: 'コンピュータベースのトレーニングモジュールまたはクラスの開発を支援する。',
          softSkill: false,
        },
        {
          id: 'T0317',
          value: 'コースの課題の開発を支援する。',
          softSkill: false,
        },
        {
          id: 'T0318',
          value: 'コース評価の開発を支援する。',
          softSkill: false,
        },
        {
          id: 'T0319',
          value: 'グレーディングと熟達基準の開発を支援する。',
          softSkill: false,
        },
        {
          id: 'T0320',
          value: '個別/共同開発、訓練、修復計画の開発を支援する。',
          softSkill: false,
        },
        {
          id: 'T0321',
          value: '学習目標と目標の開発を支援する。',
          softSkill: false,
        },
        {
          id: 'T0322',
          value: '職場でのトレーニング教材やプログラムの開発を支援する。',
          softSkill: false,
        },
        {
          id: 'T0323',
          value: '学習者の熟練度を測定し評価するための筆記試験の開発を支援する。',
          softSkill: false,
        },
        {
          id: 'T0324',
          value: 'ソフトウェアプログラミングとドキュメンテーションの開発を指導する。',
          softSkill: false,
        },
        {
          id: 'T0325',
          value: 'システムの目的と運用の予備的なシステムセキュリティの概念を文書化する。',
          softSkill: false,
        },
        {
          id: 'T0326',
          value: '構成管理プロセスを採用する。',
          softSkill: false,
        },
        {
          id: 'T0327',
          value: 'ネットワークインフラストラクチャの脆弱性を評価し、開発中の機能を強化する。',
          softSkill: false,
        },
        {
          id: 'T0328',
          value:
            'セキュリティアーキテクチャと設計を評価して、取得文書に含まれる要件に応じて提案または提供されるセキュリティ設計とアーキテクチャの妥当性を判断する。',
          softSkill: false,
        },
        {
          id: 'T0329',
          value: 'ソフトウェアおよびシステムエンジニアリングライフサイクルの標準とプロセスに従う。',
          softSkill: false,
        },
        {
          id: 'T0330',
          value: '保証されたメッセージ配信システムを維持する。',
          softSkill: false,
        },
        {
          id: 'T0331',
          value: 'インシデントトラッキングおよびソリューションデータベースを保守する。',
          softSkill: false,
        },
        {
          id: 'T0332',
          value:
            '指名されたマネージャー、サイバーインシデントレスポンダー、サイバーセキュリティサービスプロバイダーのチームメンバーに疑わしいサイバーインシデントを通知し、組織のサイバーインシデント対応計画に従ってイベントの履歴、ステータス、さらにアクションの潜在的な影響を明確にする。',
          softSkill: false,
        },
        {
          id: 'T0334',
          value:
            'すべてのシステムコンポーネントを統合して整列させることができることを確認する（例えば、プロシージャ、データベース、ポリシー、ソフトウェア、およびハードウェア）。',
          softSkill: false,
        },
        {
          id: 'T0335',
          value: '専用サイバー防衛ハードウェアの構築、インストール、構成、テストを行う。',
          softSkill: false,
        },
        {
          id: 'T0336',
          value: '撤回 - T0228と統合',
          softSkill: false,
        },
        {
          id: 'T0337',
          value:
            'プログラマー、デザイナー、技術者、技術者、その他のエンジニアリングおよび科学者に仕事を監督し、割り当てる。',
          softSkill: false,
        },
        {
          id: 'T0338',
          value: 'アーキテクチャ開発プロセスを記述する詳細な機能仕様を記述する。',
          softSkill: false,
        },
        {
          id: 'T0339',
          value: '組織の知識管理と情報共有の促進を促進する努力をリードする。',
          softSkill: false,
        },
        {
          id: 'T0340',
          value:
            'サービスをサポートする基盤となる情報技術（IT）運用プロセスと機能の主要なステークホルダーとして行動し、すべての重要な活動を監督し、監視し、サービスが正常に提供されるようにする。',
          softSkill: false,
        },
        {
          id: 'T0341',
          value:
            'サイバートレーニングリソースのための適切な資金調達のために、社内および業界で提供されたコース、インストラクター、および関連資料を含めるようアドボケートする。',
          softSkill: false,
        },
        {
          id: 'T0342',
          value: '実行可能な推奨事項を提供するためにデータソースを分析する。',
          softSkill: false,
        },
        {
          id: 'T0343',
          value: '危機を分析して、パブリック、個人、およびリソースの保護を確保する。',
          softSkill: false,
        },
        {
          id: 'T0344',
          value: 'すべての構成管理（構成/リリース管理の変更）プロセスを評価する。',
          softSkill: false,
        },
        {
          id: 'T0345',
          value:
            '指導技術の使いやすさと学生の学習、知識の伝達、満足度に応じて、指導の有効性と効率を評価する。',
          softSkill: false,
        },
        {
          id: 'T0346',
          value: '被害者、証人、または容疑者が調査に関連しているときの行動を評価する。',
          softSkill: false,
        },
        {
          id: 'T0347',
          value: 'ソースデータとそれに続く結果の妥当性を評価する。',
          softSkill: false,
        },
        {
          id: 'T0348',
          value: '専用のサイバー防衛基盤の実施と維持の影響の評価を支援する。',
          softSkill: false,
        },
        {
          id: 'T0349',
          value: 'メトリックと傾向データを収集する。',
          softSkill: false,
        },
        {
          id: 'T0350',
          value:
            'システム内で使用するための商用の既製品、およびオープンソース製品を特定、評価、推奨する市場分析を実施し、推奨製品が組織の評価および検証要件に準拠していることを確認する。',
          softSkill: false,
        },
        {
          id: 'T0351',
          value: '統計的プロセスを用いて仮説検定を行う。',
          softSkill: false,
        },
        {
          id: 'T0352',
          value: '学習ニーズ評価を実施し、要件を特定する。',
          softSkill: false,
        },
        {
          id: 'T0353',
          value:
            'システムアナリスト、エンジニア、プログラマー、その他の人々とアプリケーションを設計するための相談を行う。',
          softSkill: false,
        },
        {
          id: 'T0354',
          value: 'エンドツーエンドの顧客に提供されるサービス全体を調整し、管理する。',
          softSkill: false,
        },
        {
          id: 'T0355',
          value:
            '既存の資格基準が組織の機能要件を反映し、業界標準を満たしていることを保証するために、内部および外部の主題専門家と調整する。',
          softSkill: false,
        },
        {
          id: 'T0356',
          value: '組織の人材ステークホルダーと調整し、人的資本資産の適切な配分と配分を確保する。',
          softSkill: false,
        },
        {
          id: 'T0357',
          value: 'インタラクティブな学習演習を作成し、効果的な学習環境を作る。',
          softSkill: false,
        },
        {
          id: 'T0358',
          value: '特権アクセスユーザーのためのシステム管理と管理機能を設計、開発する。',
          softSkill: false,
        },
        {
          id: 'T0359',
          value:
            '情報システム、物理システム、および/または組み込み技術間の安全なインタフェースを設計、実装、テスト、および評価する。',
          softSkill: false,
        },
        {
          id: 'T0360',
          value: '脅威の程度を判断し、リスク軽減のための行動や対策を推奨する。',
          softSkill: false,
        },
        {
          id: 'T0361',
          value: 'データ収集方法を開発し、容易にする。',
          softSkill: false,
        },
        {
          id: 'T0362',
          value: '確立されたサイバー作業の役割に基づいて標準化された位置記述を開発し実装する。',
          softSkill: false,
        },
        {
          id: 'T0363',
          value: '現行の人事施策に従って、人材採用、雇用、人材確保の手順を開発し、レビューする。',
          softSkill: false,
        },
        {
          id: 'T0364',
          value:
            'サイバーキャリアフィールド分類構造を開発して、キャリアフィールドエントリー要件およびコードおよび識別子などの他の命名法を確立することを含む。',
          softSkill: false,
        },
        {
          id: 'T0365',
          value: 'サイバートレーニングのためのトレーニング方針とプロトコルの開発を支援する。',
          softSkill: false,
        },
        {
          id: 'T0366',
          value: '大規模なデータセットから戦略的な洞察を作成する。',
          softSkill: false,
        },
        {
          id: 'T0367',
          value: 'サイバーカリキュラムの目標と目標を策定する。',
          softSkill: false,
        },
        {
          id: 'T0368',
          value:
            'サイバーキャリアの分野は、組織の人事方針と指令に従って確実に管理されるようにする。',
          softSkill: false,
        },
        {
          id: 'T0369',
          value:
            'サイバー人材管理の方針とプロセスは、機会均等、多様性、公正な雇用/雇用の実践に関して、法的および組織的要件を遵守することを確実にする。',
          softSkill: false,
        },
        {
          id: 'T0370',
          value:
            '顧客にサービスの説明とサービスを監視する手段を明確に記載した適切なサービスレベル契約（SLA）と基盤契約が定義されていることを確認する。',
          softSkill: false,
        },
        {
          id: 'T0371',
          value: 'ソフトウェアアプリケーション、ネットワーク、またはシステムの許容限度を設定する。',
          softSkill: false,
        },
        {
          id: 'T0372',
          value:
            'サイバーの人事データの分析を含め、サイバー人材の準備状況を監視および検証するための指標を確立して収集し、認定された職種の身分を特定し、充足し、満たしているかどうかを評価する。',
          softSkill: false,
        },
        {
          id: 'T0373',
          value:
            'サイバーキャリアフィールドの入力とトレーニング資格要件のための免除プロセスを確立し監督する。',
          softSkill: false,
        },
        {
          id: 'T0374',
          value:
            'サイバーキャリアの進路を確立し、サイバーキャリアの分野におけるキャリアの進展、熟考された開発、成長を可能にする。',
          softSkill: false,
        },
        {
          id: 'T0375',
          value:
            'サイバー人材管理と報告要件をサポートする人材、人材、資格データ要素の基準を確立する。',
          softSkill: false,
        },
        {
          id: 'T0376',
          value: '組織の要求に応じてサイバー人材管理プログラムを確立、リソース、実装、評価する。',
          softSkill: false,
        },
        {
          id: 'T0377',
          value:
            '継続的な改善を促進するために、顧客満足度と内部サービスパフォーマンスに関するフィードバックを収集する。',
          softSkill: false,
        },
        {
          id: 'T0378',
          value:
            'リスクのあるシステムの保守更新プロセスを組み込んで、システムの不具合に対処する（定期的かつ定期的に）。',
          softSkill: false,
        },
        {
          id: 'T0379',
          value:
            'オペレーションレベルアグリーメント（OLA）の定義と合意を支援して、サービスをサポートする情報技術（IT）プロセスオーナーとの内部関係を管理する。',
          softSkill: false,
        },
        {
          id: 'T0380',
          value:
            '講師、デモンストレーション、インタラクティブ演習、マルチメディアプレゼンテーション、ビデオコース、教育者やトレーナーと協力して最も効果的な学習環境のためのWebベースの講座などの指導戦略を計画する。',
          softSkill: false,
        },
        {
          id: 'T0381',
          value: 'テクニカルおよびノンテクニカル視聴者に技術情報を提示する。',
          softSkill: false,
        },
        {
          id: 'T0382',
          value: 'クリエイティブな形式でデータを表示する。',
          softSkill: false,
        },
        {
          id: 'T0383',
          value: 'カスタムアルゴリズムをプログラムする。',
          softSkill: false,
        },
        {
          id: 'T0384',
          value:
            'マネジメントの間で適切なサイバー政策と戦略の意識を促進し、健全な原則が組織の使命、ビジョン、目標に反映されるようにする。',
          softSkill: false,
        },
        {
          id: 'T0385',
          value:
            '重要なステークホルダーに対して、データ分析と調査結果に基づいて実行可能な推奨事項を提供する。',
          softSkill: false,
        },
        {
          id: 'T0386',
          value: '司法プロセス中に裁判の弁護人に犯罪捜査支援を提供する。',
          softSkill: false,
        },
        {
          id: 'T0387',
          value: 'サイバーキャリアフィールドの資格基準を見直し適用する。',
          softSkill: false,
        },
        {
          id: 'T0388',
          value:
            'サイバー従業員に関連する、またはサイバー従業員に影響を及ぼす組織方針を検討して適用する。',
          softSkill: false,
        },
        {
          id: 'T0389',
          value:
            '重大な問題と分散を特定し、必要に応じて是正措置を開始し、未解決の問題がすべて追跡されることを確認するサービスのパフォーマンスレポートを確認する。',
          softSkill: false,
        },
        {
          id: 'T0390',
          value:
            'スキルおよび/または資格基準を調整するためにサイバー人材の有効性をレビュー/評価する。',
          softSkill: false,
        },
        {
          id: 'T0391',
          value:
            '情報システムのライフサイクル開発プロセスに適格なサイバー人材の人材の統合をサポートする。',
          softSkill: false,
        },
        {
          id: 'T0392',
          value:
            'テクニカルドキュメントやリソースを活用して、新しい数学、データサイエンス、またはコンピュータサイエンスの方法を実装する。',
          softSkill: false,
        },
        {
          id: 'T0393',
          value: 'テスト容易性のための仕様と要件を検証する。',
          softSkill: false,
        },
        {
          id: 'T0394',
          value:
            '他のサービス・マネージャーや製品所有者と協力して、サービス全体のバランスと優先順位付けを行い、顧客の全般的な要件、制約、および目標を達成する。',
          softSkill: false,
        },
        {
          id: 'T0395',
          value: 'アクションレビュー後に書き込み、公開する。',
          softSkill: false,
        },
        {
          id: 'T0396',
          value: 'アナリストの目標に応じて適切なツールでイメージを処理する。',
          softSkill: false,
        },
        {
          id: 'T0397',
          value: 'Windowsレジストリ分析を実行する。',
          softSkill: false,
        },
        {
          id: 'T0398',
          value:
            '動的分析による侵入を特定した後、実行中のシステムでファイルとレジストリの監視を実行する。',
          softSkill: false,
        },
        {
          id: 'T0399',
          value:
            '取得したデジタルメディアのトラッキングデータベース（製品トラッカーツールなど）にメディア情報を入力する。',
          softSkill: false,
        },
        {
          id: 'T0400',
          value: 'インシデントデータを相関させ、サイバー防衛の報告を行う。',
          softSkill: false,
        },
        {
          id: 'T0401',
          value:
            'インシデントレスポンスチームミッションを支援するために、展開可能なサイバー防衛ツールキット（例えば、サイバー防衛ソフトウェア/ハードウェア）を維持する。',
          softSkill: false,
        },
        {
          id: 'T0402',
          value: 'データ管理システムの設計にストレージ容量を効果的に割り当てる。',
          softSkill: false,
        },
        {
          id: 'T0403',
          value:
            'WindowsやUNIXシステム（例えば、大きなデータファイルの解析、手動タスクの自動化、リモートデータの取得/処理などのタスクを実行するシステム）で、単純なスクリプト（Perl、VBScriptなど）の読み取り、解釈、書き込み、変更及び実行を行う。',
          softSkill: false,
        },
        {
          id: 'T0404',
          value:
            'さまざまなプログラミング言語を使用してコードを作成し、ファイルを開き、ファイルを読み込んで、異なるファイルに出力を書き出す。',
          softSkill: false,
        },
        {
          id: 'T0405',
          value:
            'Rなどのオープンソース言語を利用して、定量的手法（記述的および推論的統計、サンプリング、実験設計、差のパラメトリックおよびノンパラメトリック検定、一般最小二乗回帰、一般線）を適用する。',
          softSkill: false,
        },
        {
          id: 'T0406',
          value:
            '設計および開発活動が適切に文書化されていること（実装の機能記述を提供する）、必要に応じて更新されることを確認する。',
          softSkill: false,
        },
        {
          id: 'T0407',
          value: '必要に応じて取得プロセスに参加する。',
          softSkill: false,
        },
        {
          id: 'T0408',
          value: '適用法、法令、規制文書を解釈して適用し、ポリシーに統合する。',
          softSkill: false,
        },
        {
          id: 'T0409',
          value:
            '製品の設計、開発、および発売前のフェーズで、プロトタイプの設計およびプロセスの問題のトラブルシューティングを行う。',
          softSkill: false,
        },
        {
          id: 'T0410',
          value:
            '機能やセキュリティに関連する機能を特定し、脆弱性を悪用したり緩和する新しい能力開発の機会を見極める。',
          softSkill: false,
        },
        {
          id: 'T0411',
          value:
            '機能を強化し脆弱性を検出するためのリバースエンジニアリングツールを特定および/または開発する。',
          softSkill: false,
        },
        {
          id: 'T0412',
          value:
            'システムとソフトウェアを取得するためのインポート/エクスポートレビューを実行する。',
          softSkill: false,
        },
        {
          id: 'T0413',
          value:
            'モバイル従業員へのサポートを含めるためのデータ管理機能（クラウドベース、集中暗号化キー管理など）を開発する。',
          softSkill: false,
        },
        {
          id: 'T0416',
          value:
            '既存の公開鍵インフラストラクチャ（PKI）ライブラリを活用し、必要に応じて証明書管理機能と暗号化機能を組み込むことで、公開キーイングでアプリケーションを有効にする。',
          softSkill: false,
        },
        {
          id: 'T0417',
          value:
            '必要に応じて、セキュリティ保護されたアプリケーション（エンタープライズPKI、フェデレーションIDサーバー、エンタープライズアンチウイルスソリューションなど）を設計および開発しながら、エンタープライズ全体のセキュリティサービスを特定および活用する。',
          softSkill: false,
        },
        {
          id: 'T0418',
          value: 'システム/サーバーのインストール、更新、およびトラブルシューティングを行う。',
          softSkill: false,
        },
        {
          id: 'T0419',
          value:
            '関連する法律、規則、方針、合意、基準、手順、またはその他の発行において生じる憲法上の問題に関する実務知識を取得し、維持する。',
          softSkill: false,
        },
        {
          id: 'T0420',
          value:
            'テストベッドを管理し、アプリケーションプロバイダ、ハードウェアインフラストラクチャ、ルール/シグネチャ、アクセスコントロール、およびサービスプロバイダが管理するプラットフォームの構成をテストして評価する。',
          softSkill: false,
        },
        {
          id: 'T0421',
          value:
            '明示的な組織知識（例えば、ハードコピー文書、デジタルファイル）の索引付け/カタログ化、記憶域、アクセスを管理する。',
          softSkill: false,
        },
        {
          id: 'T0422',
          value: 'データ管理標準、要件、および仕様を実装する。',
          softSkill: false,
        },
        {
          id: 'T0423',
          value: 'カウンターインテリジェンスや犯罪行為のコンピュータ生成の脅威を分析する。',
          softSkill: false,
        },
        {
          id: 'T0424',
          value:
            'セキュリティアプリケーションの開発や既存のセキュリティアプリケーションの変更をサポートする情報を分析し、関係者に提供する。',
          softSkill: false,
        },
        {
          id: 'T0425',
          value: '組織のサイバーポリシーを分析する。',
          softSkill: false,
        },
        {
          id: 'T0426',
          value: 'ソフトウェア、ハードウェア、または相互運用性テストの結果を分析する。',
          softSkill: false,
        },
        {
          id: 'T0427',
          value: 'アーキテクチャを計画するためのユーザーのニーズと要件を分析する。',
          softSkill: false,
        },
        {
          id: 'T0428',
          value:
            'セキュリティニーズとソフトウェア要件を分析して、時間とコストの制約とセキュリティ要件の範囲内で設計の実現可能性を判断する。',
          softSkill: false,
        },
        {
          id: 'T0429',
          value:
            'サイバー活動を管理するポリシーを策定するために、ポリシーニーズを評価し、ステークホルダーと協力する。',
          softSkill: false,
        },
        {
          id: 'T0430',
          value: 'コンピュータ犯罪の訴追に使用された証拠を収集し、保存する。',
          softSkill: false,
        },
        {
          id: 'T0431',
          value: 'システムハードウェアの可用性、機能、整合性、および効率性をチェックする。',
          softSkill: false,
        },
        {
          id: 'T0432',
          value:
            '侵入成果物（ソースコード、マルウェア、システム構成など）を収集して分析し、発見されたデータを使用して、企業内の潜在的なサイバー防衛インシデントの軽減を可能にする。',
          softSkill: false,
        },
        {
          id: 'T0433',
          value:
            'ログファイル、エビデンス、その他の情報を分析して、ネットワーク侵入やその他の犯罪の加害者を特定するための最良の方法を決定する。',
          softSkill: false,
        },
        {
          id: 'T0434',
          value:
            '法律、規則、または政策/ガイダンスの違反と思われるものを適切に特定するための弁論の枠組みを実施する。',
          softSkill: false,
        },
        {
          id: 'T0435',
          value:
            'クリーニング（物理的および電子的）、ディスクチェック、ルーチン再起動、データダンプ、およびテストを含む定期的なシステムメンテナンスを実行する。',
          softSkill: false,
        },
        {
          id: 'T0436',
          value:
            '必要な情報が作成され、指示とセキュリティレベルが正しいことを確認するために、プログラムやソフトウェアアプリケーションの試行を行う。',
          softSkill: false,
        },
        {
          id: 'T0437',
          value: 'トレーニングや学習とビジネスやミッションの要件を関連付ける。',
          softSkill: false,
        },
        {
          id: 'T0438',
          value:
            '専用のサイバー防衛システム（ファイアウォールや侵入防止システムなど）上のネットワークアクセス制御リストの作成、編集、管理。',
          softSkill: false,
        },
        {
          id: 'T0439',
          value:
            '暗号化されたデータ、隠語、代替データストリーム、およびその他の形式の隠されたデータを検出して分析する。',
          softSkill: false,
        },
        {
          id: 'T0440',
          value:
            '致命的な障害イベントが発生した後、システムの一部または全部を復旧するために必要なシステム機能やビジネス機能をキャプチャして統合する。',
          softSkill: false,
        },
        {
          id: 'T0441',
          value: '現在および将来のミッション環境を定義し、統合する。',
          softSkill: false,
        },
        {
          id: 'T0442',
          value: 'オーディエンスや物理環境に合わせたトレーニングコースを作成する。',
          softSkill: false,
        },
        {
          id: 'T0443',
          value: 'オーディエンスや物理/仮想環境に合わせたトレーニングコースを提供する。',
          softSkill: false,
        },
        {
          id: 'T0444',
          value:
            '概念、手順、ソフトウェア、機器、および/またはテクノロジーアプリケーションを学生に適用する。',
          softSkill: false,
        },
        {
          id: 'T0445',
          value:
            '組織の戦略計画と一致するビジョン、ミッション、目標を概説するサイバー戦略を設計/統合する。',
          softSkill: false,
        },
        {
          id: 'T0446',
          value:
            '機密性、完全性、可用性、認証、および否認防止を提供するシステムセキュリティ対策を設計、開発、統合、および更新する。',
          softSkill: false,
        },
        {
          id: 'T0447',
          value:
            '要件に適切に対処するためのハードウェア、オペレーティングシステム、およびソフトウェアアプリケーションの設計。',
          softSkill: false,
        },
        {
          id: 'T0448',
          value:
            'ユーザーのニーズを満たすために必要なエンタープライズアーキテクチャまたはシステムコンポーネントを開発する。',
          softSkill: false,
        },
        {
          id: 'T0449',
          value:
            'すべてのシステムおよび/またはアプリケーションに対して要件が満たされていることを保証するためのセキュリティ要件への設計。',
          softSkill: false,
        },
        {
          id: 'T0450',
          value: '要件に基づいてトレーニングカリキュラムとコースコンテンツを設計する。',
          softSkill: false,
        },
        {
          id: 'T0451',
          value: 'トレーニングカリキュラムとコースコンテンツの開発に参加する。',
          softSkill: false,
        },
        {
          id: 'T0452',
          value:
            '組織の知的財産へのエンドユーザーアクセスを提供する知識管理フレームワークの設計、構築、実装、および維持を行う。',
          softSkill: false,
        },
        {
          id: 'T0453',
          value:
            'リードを決定し、開発し、情報源を特定して、責任当事者を侵入またはその他の犯罪に特定および/または訴追する。',
          softSkill: false,
        },
        {
          id: 'T0454',
          value: '適用可能なガイドラインに従って、ベースラインのセキュリティ要件を定義する。',
          softSkill: false,
        },
        {
          id: 'T0455',
          value:
            'ソフトウェアシステムのテストと検証手順、プログラミング、およびドキュメントを作成する。',
          softSkill: false,
        },
        {
          id: 'T0456',
          value: '安全なソフトウェアのテストと検証手順を開発する。',
          softSkill: false,
        },
        {
          id: 'T0457',
          value: 'システムテストと検証手順、プログラミング、およびドキュメントを作成する。',
          softSkill: false,
        },
        {
          id: 'T0458',
          value: '組織のシステム管理標準操作手順に従う。',
          softSkill: false,
        },
        {
          id: 'T0459',
          value:
            'データマイニングアプリケーションとデータウェアハウジングアプリケーションを実装する。',
          softSkill: false,
        },
        {
          id: 'T0460',
          value: 'データマイニングおよびデータウェアハウジングプログラムの開発と実装を行う。',
          softSkill: false,
        },
        {
          id: 'T0461',
          value: 'ローカルネットワークの使用ポリシーと手順を実装し、実施する。',
          softSkill: false,
        },
        {
          id: 'T0462',
          value:
            '手順を開発し、システム運用のフェールオーバーをテストして、システムの可用性要件に基づいて代替サイトに移行する。',
          softSkill: false,
        },
        {
          id: 'T0463',
          value: '新規または変更されたシステムのコスト見積もりを開発する。',
          softSkill: false,
        },
        {
          id: 'T0464',
          value:
            'システム設計および開発をサポートするためのコンポーネントおよびインタフェース仕様の詳細な設計ドキュメントを作成する。',
          softSkill: false,
        },
        {
          id: 'T0465',
          value: '実装のためのガイドラインを作成する。',
          softSkill: false,
        },
        {
          id: 'T0466',
          value:
            'コスト、スケジュール、パフォーマンス、およびセキュリティリスクに対処する緩和戦略を策定する。',
          softSkill: false,
        },
        {
          id: 'T0467',
          value:
            'トレーニングは、サイバーセキュリティのトレーニング、教育、または意識向上の目標と目的を満たしていることを確認する。',
          softSkill: false,
        },
        {
          id: 'T0468',
          value: '顧客から報告されたシステムインシデント、問題、およびイベントを診断して解決する。',
          softSkill: false,
        },
        {
          id: 'T0469',
          value: '組織のセキュリティの姿勢の傾向を分析して報告する。',
          softSkill: false,
        },
        {
          id: 'T0470',
          value: 'システムセキュリティのポスチャトレンドを分析して報告する。',
          softSkill: false,
        },
        {
          id: 'T0471',
          value:
            'デジタルおよび/または関連する証拠（例えば、デジタル写真、書面による報告書、ハッシュ機能のチェックなど）の原状を文書化する。',
          softSkill: false,
        },
        {
          id: 'T0472',
          value: 'ドラフト、スタッフ、サイバー政策を発表する。',
          softSkill: false,
        },
        {
          id: 'T0473',
          value: '必要に応じてすべての定義およびアーキテクチャ活動を文書化して更新する。',
          softSkill: false,
        },
        {
          id: 'T0474',
          value:
            'サイバーセキュリティポリシーの遵守と関連する法律および規制上の要件について、検察総長、プライバシー担当者、監督者およびコンプライアンス担当者に法的な分析と決定を提供する。',
          softSkill: false,
        },
        {
          id: 'T0475',
          value: '最小限の特権と知る必要性の原則に基づいて適切なアクセス制御を評価する。',
          softSkill: false,
        },
        {
          id: 'T0476',
          value: '法律、規制、方針、基準、または手順への変更の影響を評価する。',
          softSkill: false,
        },
        {
          id: 'T0477',
          value: '災害復旧の実行と運用の継続性を確保する。',
          softSkill: false,
        },
        {
          id: 'T0478',
          value:
            '法律、規則、方針、基準、または手順に関する指導を管理者、人員、または顧客に提供する。',
          softSkill: false,
        },
        {
          id: 'T0479',
          value:
            '人々や財産に対するサイバー犯罪や詐欺を解決、調査、遂行するためにIT（情報技術）システムとデジタル記憶媒体を採用する。',
          softSkill: false,
        },
        {
          id: 'T0480',
          value:
            'コンポーネントまたは要素を特定し、包括的な機能コンポーネントを割り当ててセキュリティ機能を組み込み、要素間の関係を記述する。',
          softSkill: false,
        },
        {
          id: 'T0481',
          value: 'サイバー人材計画と管理の問題（例えば、募集、保持、訓練）を特定し、対処する。',
          softSkill: false,
        },
        {
          id: 'T0482',
          value:
            'トレンド分析に基づく推奨事項を作成して、ソフトウェアとハードウェアソリューションの強化を図り、顧客体験を強化する。',
          softSkill: false,
        },
        {
          id: 'T0483',
          value:
            'サイバー防御ツールの実装との潜在的な競合を特定する（ツールやシグネチャのテストや最適化など）。',
          softSkill: false,
        },
        {
          id: 'T0484',
          value:
            '情報システムとネットワークおよび文書の保護ニーズ（すなわち、セキュリティ管理策）を適切に決定する。',
          softSkill: false,
        },
        {
          id: 'T0485',
          value:
            '脆弱性を解決し、リスクを軽減し、必要に応じてシステムまたはシステムコンポーネントのセキュリティ変更を推奨するセキュリティ対策を実装する。',
          softSkill: false,
        },
        {
          id: 'T0486',
          value:
            '企業内の専用のサイバー防衛システムに対するリスク管理フレームワーク（RMF）/セキュリティ評価と承認（SA＆A）要件を実装し、それらのための記録を文書化し、維持する。',
          softSkill: false,
        },
        {
          id: 'T0487',
          value:
            '新規または改正された法律、規制、執行命令、方針、基準、または手順の実施を促進する。',
          softSkill: false,
        },
        {
          id: 'T0488',
          value: '新しいシステムまたは既存のシステムの設計を実装する。',
          softSkill: false,
        },
        {
          id: 'T0489',
          value:
            '守秘義務、完全性、可用性、認証、否認防止を確実にするための確立された手順に従って、システムセキュリティ対策を実施する。',
          softSkill: false,
        },
        {
          id: 'T0490',
          value: 'データベース管理システムとソフトウェアをインストールして構成する。',
          softSkill: false,
        },
        {
          id: 'T0491',
          value:
            '組織の標準に従って、システムユーザー用のハードウェア、ソフトウェア、および周辺機器をインストールして構成する。',
          softSkill: false,
        },
        {
          id: 'T0492',
          value: '安全な環境でのクロスドメインソリューション（CDS）の統合と実装を確実にする。',
          softSkill: false,
        },
        {
          id: 'T0493',
          value: '予算、人員配置、契約をリードし、監督する。',
          softSkill: false,
        },
        {
          id: 'T0494',
          value: 'アカウント、ネットワーク権限、およびシステムと機器へのアクセスを管理する。',
          softSkill: false,
        },
        {
          id: 'T0495',
          value: '認定パッケージを管理する（例：ISO / IEC 15026-2）。',
          softSkill: false,
        },
        {
          id: 'T0496',
          value: '資産管理/情報技術（IT）リソースのインベントリを実行する。',
          softSkill: false,
        },
        {
          id: 'T0497',
          value:
            '開発されたソリューションが顧客の要件を確実に満たすために、情報技術（IT）計画プロセスを管理する。',
          softSkill: false,
        },
        {
          id: 'T0498',
          value:
            'パフォーマンス、容量、可用性、保守容易性、およびリカバリ性を含むシステム/サーバーのリソースを管理する。',
          softSkill: false,
        },
        {
          id: 'T0499',
          value:
            'セキュリティ/認証テスト中に特定されたセキュリティ欠陥を軽減/訂正し、適切な上級指導者または権限を有する者に対しリスクの受容を推奨する。',
          softSkill: false,
        },
        {
          id: 'T0500',
          value:
            '既存のソフトウェアを修正して維持し、エラーを修正したり、新しいハードウェアに適合させたり、インターフェースをアップグレードしたり、パフォーマンスを向上させたりする。',
          softSkill: false,
        },
        {
          id: 'T0501',
          value: 'システム/サーバー構成の監視と維持。',
          softSkill: false,
        },
        {
          id: 'T0502',
          value: 'クライアントレベルのコンピュータシステムのパフォーマンスを監視および報告する。',
          softSkill: false,
        },
        {
          id: 'T0503',
          value:
            'サイバー防衛ベンダーサイト、コンピュータ緊急対応チーム、セキュリティフォーカスなどの外部データソースを監視して、サイバー防衛脅威状態の通貨を維持し、どのセキュリティ問題が企業に影響を与えるかを判断する。',
          softSkill: false,
        },
        {
          id: 'T0504',
          value: 'システム実装とテストの実践に関連するサイバーセキュリティを評価し、監視する。',
          softSkill: false,
        },
        {
          id: 'T0505',
          value:
            '計画と管理サービスの提供におけるサイバーポリシー、原則、慣行の厳格な適用を監視する。',
          softSkill: false,
        },
        {
          id: 'T0506',
          value: 'ステークホルダーからの提案された政策変更に関するコンセンサスを求める。',
          softSkill: false,
        },
        {
          id: 'T0507',
          value: 'システムコンポーネントのインストール、実装、構成、およびサポートを監督する。',
          softSkill: false,
        },
        {
          id: 'T0508',
          value:
            'すべてのアプリケーションで最低限のセキュリティ要件が満たされていることを確認する。',
          softSkill: false,
        },
        {
          id: 'T0509',
          value: '情報セキュリティリスク評価を実施する。',
          softSkill: false,
        },
        {
          id: 'T0510',
          value: 'インシデント対応機能を調整する。',
          softSkill: false,
        },
        {
          id: 'T0511',
          value: '開発中のシステムで開発テストを実施する。',
          softSkill: false,
        },
        {
          id: 'T0512',
          value: '他のシステムと電子情報を交換するシステムで相互運用性テストを実施する。',
          softSkill: false,
        },
        {
          id: 'T0513',
          value: '運用テストを実施する。',
          softSkill: false,
        },
        {
          id: 'T0514',
          value: '障害のあるシステム/サーバーハードウェアを診断する。',
          softSkill: false,
        },
        {
          id: 'T0515',
          value: '障害のあるシステム/サーバーハードウェアで修復を実行する。',
          softSkill: false,
        },
        {
          id: 'T0516',
          value:
            'コード内の潜在的な欠陥を特定し、脆弱性を軽減するために、安全なプログラムのテスト、レビュー、および/または評価を実行する。',
          softSkill: false,
        },
        {
          id: 'T0517',
          value: 'セキュリティアーキテクチャのギャップの特定に関する結果を統合する。',
          softSkill: false,
        },
        {
          id: 'T0518',
          value: 'セキュリティレビューを実行し、アーキテクチャのセキュリティギャップを特定する。',
          softSkill: false,
        },
        {
          id: 'T0519',
          value:
            '最も効果的な学習環境のために、教室のテクニックやフォーマット（講義、デモ、インタラクティブ練習、マルチメディアプレゼンテーションなど）の提供を計画し、調整する。',
          softSkill: false,
        },
        {
          id: 'T0520',
          value:
            '非教室の教育技術やフォーマット（例えば、ビデオコース、メンタリング、ウェブベースのコース）を計画する。',
          softSkill: false,
        },
        {
          id: 'T0521',
          value: '企業のコンポーネントを統合して整列させるための実装戦略を立てる。',
          softSkill: false,
        },
        {
          id: 'T0522',
          value:
            '法律文書およびその他の関連文書（例：預言書、要約書、宣誓供述書、宣言書、控訴書、嘆願書、発見書）を準備する。',
          softSkill: false,
        },
        {
          id: 'T0523',
          value: '法的基準と要件に従って調査を文書化するためのレポートを準備する。',
          softSkill: false,
        },
        {
          id: 'T0524',
          value: '組織の業務プロセスとシステムを通じて情報所有者/ユーザー間の知識共有を促進する。',
          softSkill: false,
        },
        {
          id: 'T0525',
          value: '企業のサイバーセキュリティとサプライチェーンのリスク管理ガイダンスを提供する。',
          softSkill: false,
        },
        {
          id: 'T0526',
          value: '重要な脅威と脆弱性に基づいて指導者にサイバーセキュリティの推奨事項を提供する。',
          softSkill: false,
        },
        {
          id: 'T0527',
          value:
            '情報システムのセキュリティに関連するインプリメンテーション計画と標準的な運用手順を入力する。',
          softSkill: false,
        },
        {
          id: 'T0528',
          value:
            '実施計画、標準的な運用手順、メンテナンス文書、およびメンテナンストレーニング資料に情報を提供する。',
          softSkill: false,
        },
        {
          id: 'T0529',
          value: 'サイバーマネジメント、スタッフ、ユーザーにポリシーガイダンスを提供する。',
          softSkill: false,
        },
        {
          id: 'T0530',
          value: 'トレンド分析とインパクトレポートを作成する。',
          softSkill: false,
        },
        {
          id: 'T0531',
          value:
            'ハードウェア/ソフトウェアのインターフェイスと相互運用性の問題のトラブルシューティング。',
          softSkill: false,
        },
        {
          id: 'T0532',
          value:
            '潜在的に関連性のある情報を回復するために、法医学的画像や他のデータソース（例えば、揮発性データ）を検討する。',
          softSkill: false,
        },
        {
          id: 'T0533',
          value: 'サイバープログラムやプロジェクトの審査、実施、または監査に参加する。',
          softSkill: false,
        },
        {
          id: 'T0534',
          value:
            'コースの内容、コース内容のドキュメント、レッスンプラン、学生のテキスト、試験、指導スケジュール、コースの説明など、定期的なレビュー/コース内容の改訂を行う。',
          softSkill: false,
        },
        {
          id: 'T0535',
          value:
            '以前の訓練セッションからのフィードバックに基づいて、カリキュラムやコース内容の改訂を推奨する。',
          softSkill: false,
        },
        {
          id: 'T0536',
          value:
            '独自の専門分野（技術、著作権、印刷媒体、電子媒体など）の内部コンサルタントおよびアドバイザーとして働く。',
          softSkill: false,
        },
        {
          id: 'T0537',
          value: 'サイバー関連政策の策定においてCIOを支援する。',
          softSkill: false,
        },
        {
          id: 'T0538',
          value: 'テストおよび評価活動への支援を提供する。',
          softSkill: false,
        },
        {
          id: 'T0539',
          value:
            'ハードウェアおよび/またはソフトウェアのテスト、評価、検証を行い、定義された仕様および要件への準拠を判断する。',
          softSkill: false,
        },
        {
          id: 'T0540',
          value: 'テストデータを記録及び管理する。',
          softSkill: false,
        },
        {
          id: 'T0541',
          value: 'コンポーネントを設計し、ギャップ分析を実行するためのシステム要件をトレースする。',
          softSkill: false,
        },
        {
          id: 'T0542',
          value: '提案された機能を技術要件に変換する。',
          softSkill: false,
        },
        {
          id: 'T0544',
          value: 'システムアーキテクチャの安定性、相互運用性、移植性、スケーラビリティを検証する。',
          softSkill: false,
        },
        {
          id: 'T0545',
          value:
            'ステークホルダーと協力して、コンピュータのセキュリティインシデントと脆弱性のコンプライアンスを解決する。',
          softSkill: false,
        },
        {
          id: 'T0546',
          value:
            'インシデント調査結果に関するサイバー防衛勧告、報告書、ホワイトペーパーを作成し、適切な支援者に発行する。',
          softSkill: false,
        },
        {
          id: 'T0547',
          value: '顧客の要求を満たすために利用可能な技術と基準を研究し、評価する。',
          softSkill: false,
        },
        {
          id: 'T0548',
          value: '災害復旧、緊急事態、および事業継続計画のための助言とインプットを提供する。',
          softSkill: false,
        },
        {
          id: 'T0549',
          value:
            '関連する技術分野（例えば、ローカルコンピューティング環境、ネットワークおよびインフラストラクチャー、エンクレーブ境界、サポートインフラストラクチャー、およびアプリケーション）に関する技術（技術評価）および技術的ではない（人および業務の評価）リスクおよび脆弱性評価を実施する。',
          softSkill: false,
        },
        {
          id: 'T0550',
          value:
            'リスクを軽減するための費用対効果の高いセキュリティ管理策の選択に関する推奨（情報、システム、プロセスの保護など）を行う。',
          softSkill: false,
        },
        {
          id: 'T0551',
          value: 'サプライチェーンのセキュリティとリスク管理文書を作成し公表する。',
          softSkill: false,
        },
        {
          id: 'T0552',
          value: 'サプライチェーンのセキュリティ/リスク管理方針を見直し、承認する。',
          softSkill: false,
        },
        {
          id: 'T0553',
          value:
            '搾取の機会を減らすために、サイバーセキュリティ機能（暗号化、アクセス制御、アイデンティティ管理など）を適用する。',
          softSkill: false,
        },
        {
          id: 'T0554',
          value:
            'ソフトウェアパッチやソフトウェアの脆弱性を残すリリースの程度を判断し、文書化する。',
          softSkill: false,
        },
        {
          id: 'T0555',
          value:
            'システム間の新しいシステムまたは新しいインターフェースの実装が、セキュリティーの姿勢を含むがこれに限定されない現在の環境およびターゲット環境にどのように影響するかを文書化する。',
          softSkill: false,
        },
        {
          id: 'T0556',
          value: 'サイバースペースに関連するセキュリティ管理機能を評価し、設計する。',
          softSkill: false,
        },
        {
          id: 'T0557',
          value: 'サイバースペースに関連するキー管理機能を統合する。',
          softSkill: false,
        },
        {
          id: 'T0558',
          value: 'システム開発の計画と実施に必要なユーザーのニーズと要件を分析する。',
          softSkill: false,
        },
        {
          id: 'T0559',
          value:
            '具体的な運用上のニーズや環境要因（例えば、アクセス制御、自動化アプリケーション、ネットワーク化された運用）を満たすための設計を開発する。',
          softSkill: false,
        },
        {
          id: 'T0560',
          value:
            '特定の運用ニーズと環境要因（アクセス制御、自動化アプリケーション、ネットワーク運用、高い整合性と可用性要件、複数レベルのセキュリティ/複数の分類レベルの処理、機密情報の処理など）に対応するためのサイバーセキュリティ設計に協力する。',
          softSkill: false,
        },
        {
          id: 'T0561',
          value: 'ターゲットを正確に特定する。',
          softSkill: false,
        },
        {
          id: 'T0562',
          value:
            '特定された問題/課題に対応するために収集操作または収集計画を調整し、収集を全体的な運用要件と同期させる。',
          softSkill: false,
        },
        {
          id: 'T0563',
          value:
            '目的を達成するために使用される機能の分析、設計、開発、または取得へのインプットを提供する。',
          softSkill: false,
        },
        {
          id: 'T0564',
          value:
            'コレクションの製品とサービスが要件を満たす範囲を判断するためにフィードバックを分析する。',
          softSkill: false,
        },
        {
          id: 'T0565',
          value: '到着した収集要求を分析する。',
          softSkill: false,
        },
        {
          id: 'T0566',
          value:
            'パフォーマンスを向上させる方法について、内部の運用アーキテクチャ、ツール、および手順を分析する。',
          softSkill: false,
        },
        {
          id: 'T0567',
          value: 'アクセスを得るためのターゲット運用アーキテクチャを分析する。',
          softSkill: false,
        },
        {
          id: 'T0568',
          value:
            '収集管理の運用構造や要件（期間、範囲、通信要件、省庁間/国際契約など）に影響を与える要因について、計画、指示、ガイダンス、ポリシーを分析する。',
          softSkill: false,
        },
        {
          id: 'T0569',
          value: '情報の要求に答える。',
          softSkill: false,
        },
        {
          id: 'T0570',
          value:
            '承認されたサイバー機能を適用して利用し、対象となるネットワークへのアクセスを可能にする。',
          softSkill: false,
        },
        {
          id: 'T0571',
          value:
            '契約の計画や覚書の作成、交渉、内部スタッフの雇用を促進するための政策とプロセスの専門知識を適用する。',
          softSkill: false,
        },
        {
          id: 'T0572',
          value:
            'サイバーコレクション、環境準備、エンゲージメントの専門知識を活用して、新たな開発や収集の継続、または顧客の要求をサポートする。',
          softSkill: false,
        },
        {
          id: 'T0573',
          value: '収集管理プロセスに運用環境要因とリスクを評価し、適用する。',
          softSkill: false,
        },
        {
          id: 'T0574',
          value: '適用される法令、法律、規則および方針を適用し、従う。',
          softSkill: false,
        },
        {
          id: 'T0575',
          value: '運用計画活動への知的支援を調整する。',
          softSkill: false,
        },
        {
          id: 'T0576',
          value:
            'サイバーオペレーションの目的をサポートするために、全ソースインテリジェンスを評価し、目標を推薦する。',
          softSkill: false,
        },
        {
          id: 'T0577',
          value: '既存の情報交換および管理システムの効率を評価する。',
          softSkill: false,
        },
        {
          id: 'T0578',
          value: '指定された仕様に対するコレクション資産のパフォーマンスを評価する。',
          softSkill: false,
        },
        {
          id: 'T0579',
          value: '目標の脆弱性および/または運用能力を評価し、行動の方向性を判断する。',
          softSkill: false,
        },
        {
          id: 'T0580',
          value:
            '利用可能な能力と方法を用いて、優先順位の情報ギャップを満たすことにおけるコレクションの有効性を評価し、それに応じて収集戦略と収集要件を調整する。',
          softSkill: false,
        },
        {
          id: 'T0581',
          value:
            '組織間の目標達成のための業務支援を促進するためのベストプラクティスを特定し、開発することを援助し、仲裁パートナーに助言する。',
          softSkill: false,
        },
        {
          id: 'T0582',
          value: '行動開発コースに専門知識を提供する。',
          softSkill: false,
        },
        {
          id: 'T0583',
          value: '共通の操作画面の開発に、主題の専門知識を提供する。',
          softSkill: false,
        },
        {
          id: 'T0584',
          value: '共通の知性の画像を維持する。',
          softSkill: false,
        },
        {
          id: 'T0585',
          value: 'サイバーオペレーションの特定の指標の開発に主題専門知識を提供する。',
          softSkill: false,
        },
        {
          id: 'T0586',
          value: '全ソース収集要件、計画、および/または活動の調整、検証、管理を支援する。',
          softSkill: false,
        },
        {
          id: 'T0587',
          value: '優先情報要件の開発と改良を支援する。',
          softSkill: false,
        },
        {
          id: 'T0588',
          value: '効果の尺度とパフォーマンスの尺度の開発に専門知識を提供する。',
          softSkill: false,
        },
        {
          id: 'T0589',
          value: '情報収集の不足の識別を支援する。',
          softSkill: false,
        },
        {
          id: 'T0590',
          value:
            '必要に応じて、パートナー組織間でインテリジェンスサポートプランの同期を有効にする。',
          softSkill: false,
        },
        {
          id: 'T0591',
          value: 'ターゲットインフラストラクチャの開発活動の分析を実行する。',
          softSkill: false,
        },
        {
          id: 'T0592',
          value: 'サイバー関連の成功基準の識別情報を入力する。',
          softSkill: false,
        },
        {
          id: 'T0593',
          value: '短期的な脅威および/または目標の現在の状況。',
          softSkill: false,
        },
        {
          id: 'T0594',
          value: '電子ターゲットフォルダを構築し、維持する。',
          softSkill: false,
        },
        {
          id: 'T0595',
          value: '分類ガイドラインに従って文書を分類する。',
          softSkill: false,
        },
        {
          id: 'T0596',
          value: '一度充足された情報に対する要求をとりやめる。',
          softSkill: false,
        },
        {
          id: 'T0597',
          value: '関連分野に携わるインテリジェンスアナリスト/ターゲット組織と協力する。',
          softSkill: false,
        },
        {
          id: 'T0598',
          value: '開発組織と協力して、目的を達成するために必要なツールを作成し、展開する。',
          softSkill: false,
        },
        {
          id: 'T0599',
          value:
            '関連するサイバー領域に関わる他の顧客、インテリジェンス、ターゲット組織と協力する。',
          softSkill: false,
        },
        {
          id: 'T0600',
          value:
            'ターゲットのアクセスと運用上の問題について、他の社内外のパートナー組織と協力する。',
          softSkill: false,
        },
        {
          id: 'T0601',
          value:
            '他のチームメンバーやパートナー組織と協力して、さまざまな情報資料（Webページ、ブリーフィング、印刷物など）を開発する。',
          softSkill: false,
        },
        {
          id: 'T0602',
          value: '顧客と協力して情報要件を定義する。',
          softSkill: false,
        },
        {
          id: 'T0603',
          value:
            '新しい開発、ブレークスルー、課題、リーダーシップに教訓を教え、社内外の顧客に伝える。',
          softSkill: false,
        },
        {
          id: 'T0604',
          value:
            '割り当てられたアセットと利用可能なアセットを、要件によって表現されたコレクションの需要と比較する。',
          softSkill: false,
        },
        {
          id: 'T0605',
          value: '収集管理活動の組織収集目的の実行から学んだ教訓を集める。',
          softSkill: false,
        },
        {
          id: 'T0606',
          value:
            '特定のターゲットに関するインテリジェンスまたは脆弱性の値について、すべてのソースデータをコンパイル、統合、および/または解釈する。',
          softSkill: false,
        },
        {
          id: 'T0607',
          value: '対象通信の識別と実施を行い、運用をサポートするために不可欠な情報を特定する。',
          softSkill: false,
        },
        {
          id: 'T0608',
          value:
            '潜在的なアクセス手段を特定するために物理的および論理的デジタル技術（例：ワイヤレス、SCADA、テレコム）の分析を行う。',
          softSkill: false,
        },
        {
          id: 'T0609',
          value: 'ワイヤレスコンピュータとデジタルネットワークのアクセスを可能にする。',
          softSkill: false,
        },
        {
          id: 'T0610',
          value: 'ワイヤレスコンピュータとデジタルネットワークの収集と処理を行う。',
          softSkill: false,
        },
        {
          id: 'T0611',
          value: '作業終了評価を実施する。',
          softSkill: false,
        },
        {
          id: 'T0612',
          value: 'ワイヤレスコンピュータとデジタルネットワークの利用を実施する。',
          softSkill: false,
        },
        {
          id: 'T0613',
          value:
            '確立されたガイドラインと手順に従って、収集要件の正式および非公式の調整を実施する。',
          softSkill: false,
        },
        {
          id: 'T0614',
          value:
            'アクセスにつながるターゲット固有の情報（文化的、組織的、政治的など）を含め、独立した徹底的な目標と技術的分析を実施する。',
          softSkill: false,
        },
        {
          id: 'T0615',
          value: '詳細な調査と分析を行う。',
          softSkill: false,
        },
        {
          id: 'T0616',
          value: 'ネットワーク内のシステムのネットワークスカウトや脆弱性分析を実行する。',
          softSkill: false,
        },
        {
          id: 'T0617',
          value: '節点解析を行う。',
          softSkill: false,
        },
        {
          id: 'T0618',
          value: '配備されたテクノロジーからのデータを制御および抽出するネット上の活動を実施する。',
          softSkill: false,
        },
        {
          id: 'T0619',
          value:
            'デプロイされた自動化されたテクノロジーからデータを制御し、抽出するためのオンネットおよびオフネットの活動を実施する。',
          softSkill: false,
        },
        {
          id: 'T0620',
          value: 'さまざまなオンラインツールを使用してオープンソースのデータ収集を行う。',
          softSkill: false,
        },
        {
          id: 'T0621',
          value:
            'ネットワークに関して収集された情報の妥当性と関連性を判断するための品質管理を実施する。',
          softSkill: false,
        },
        {
          id: 'T0622',
          value:
            'サイバーオペレーションをサポートするためのあらゆるレベルの計画ガイダンスを作成、レビュー、実装する。',
          softSkill: false,
        },
        {
          id: 'T0623',
          value: 'コンピュータとデジタルネットワークの調査を行う。',
          softSkill: false,
        },
        {
          id: 'T0624',
          value: 'ターゲットの調査と分析を行う。',
          softSkill: false,
        },
        {
          id: 'T0625',
          value:
            '重要な情報要件に対して適用される場合、収集資産およびリソースの効率性と有効性を考慮する。',
          softSkill: false,
        },
        {
          id: 'T0626',
          value: '確立されたガイダンスと手順を用いて収集計画とマトリックスを構築する。',
          softSkill: false,
        },
        {
          id: 'T0627',
          value: 'サイバー事業の危機対応計画に貢献する。',
          softSkill: false,
        },
        {
          id: 'T0628',
          value: '必要に応じて、組織の意思決定支援ツールの開発に貢献する。',
          softSkill: false,
        },
        {
          id: 'T0629',
          value:
            '適切な内部および/または外部の意思決定者とサイバー事業の方針、業績基準、計画および承認パッケージの開発、人員配置、調整に貢献する。',
          softSkill: false,
        },
        {
          id: 'T0630',
          value: 'インテリジェンスの株式をサイバー事業計画全体に組み込む。',
          softSkill: false,
        },
        {
          id: 'T0631',
          value:
            '優先順位付けされた収集要件に対する収集資産のリソース割り当てを、収集規律のリードと調整する。',
          softSkill: false,
        },
        {
          id: 'T0632',
          value: '適切な文書に収集計画を組み込むように調整する。',
          softSkill: false,
        },
        {
          id: 'T0633',
          value: '適切なパートナーと目標の査定を調整する。',
          softSkill: false,
        },
        {
          id: 'T0634',
          value: 'コレクションの資産とリソースを再タスクまたはリダイレクトする。',
          softSkill: false,
        },
        {
          id: 'T0635',
          value: '情報とサイバー防衛のパートナーと連携し、関連する重要な情報を入手する。',
          softSkill: false,
        },
        {
          id: 'T0636',
          value:
            'インテリジェンスプランナーと連携して、コレクションマネージャーが情報要件を確実に取得できるようにする。',
          softSkill: false,
        },
        {
          id: 'T0637',
          value:
            'インテリジェンス計画チームと調整し、割り当てられたインテリジェンスタスクを満たす能力を評価する。',
          softSkill: false,
        },
        {
          id: 'T0638',
          value: 'インテリジェンスの要件を調整、生成、追跡する。',
          softSkill: false,
        },
        {
          id: 'T0639',
          value:
            'サイバーオペレーション計画の適用可能なインテリジェンスセクションの調整、同期化、草案作成を行う。',
          softSkill: false,
        },
        {
          id: 'T0640',
          value: '潜在的なターゲットアクションに対抗するためにインテリジェンス推定値を使用する。',
          softSkill: false,
        },
        {
          id: 'T0641',
          value: '悪用可能な技術的または運用上の脆弱性を特定する包括的な開発戦略を作成する。',
          softSkill: false,
        },
        {
          id: 'T0642',
          value:
            '内部と外部のサイバー組織の構造、強み、スタッフと技術の雇用に対する意識を維持する。',
          softSkill: false,
        },
        {
          id: 'T0643',
          value: 'ターゲットにツールを展開し、展開後に利用する（バックドア、スニファなど）。',
          softSkill: false,
        },
        {
          id: 'T0644',
          value: 'ターゲットとするネットワークやホストに対する攻撃を検出し、それに応じて対応する。',
          softSkill: false,
        },
        {
          id: 'T0645',
          value:
            '目的、ガイダンス、および運用環境の変更に対処するためのアクション・コースを決定する。',
          softSkill: false,
        },
        {
          id: 'T0646',
          value: '既存のコレクション管理Webページデータベース、ライブラリ、および倉庫を決定する。',
          softSkill: false,
        },
        {
          id: 'T0647',
          value:
            '特定された要因がタスク、収集、処理、開発、普及のアーキテクチャの形式と機能にどのように影響するかを決定する。',
          softSkill: false,
        },
        {
          id: 'T0648',
          value: '特定のサイバー運用目標に最も適した指標（例えば、有効性の指標）を決定する。',
          softSkill: false,
        },
        {
          id: 'T0649',
          value:
            'すべてのアクセス可能なコレクションアセットに対するコレクション権限を持つ組織および/または階層を決定する。',
          softSkill: false,
        },
        {
          id: 'T0650',
          value: '特定のターゲットがどの技術を使用しているかを判断する。',
          softSkill: false,
        },
        {
          id: 'T0651',
          value: '収集報告書と未解決の要件とを比較して情報格差を特定する方法を開発する。',
          softSkill: false,
        },
        {
          id: 'T0652',
          value: 'マテリアルをターゲットとした全ソースインテリジェンスを開発する。',
          softSkill: false,
        },
        {
          id: 'T0653',
          value: '分析技術を適用してより多くのターゲット情報を取得する。',
          softSkill: false,
        },
        {
          id: 'T0654',
          value: '故意および/または危機計画を作成し、維持する。',
          softSkill: false,
        },
        {
          id: 'T0655',
          value:
            'より幅広い計画活動に統合するための特定のサイバーオペレーションガイダンスを作成し、レビューする。',
          softSkill: false,
        },
        {
          id: 'T0656',
          value:
            'サイバーオペレーションの計画と実行をサポートするためのインテリジェンスガイダンスの作成とレビューを行う。',
          softSkill: false,
        },
        {
          id: 'T0657',
          value: '操作の各フェーズの収集規律による調整指示を作成する。',
          softSkill: false,
        },
        {
          id: 'T0658',
          value:
            '実行とリソース割り当ての意思決定が組織の目標と確実に合致するように、サイバー運用計画とガイダンスを作成する。',
          softSkill: false,
        },
        {
          id: 'T0659',
          value: 'サイバーオペレーション要件に対する詳細な情報サポートを開発する。',
          softSkill: false,
        },
        {
          id: 'T0660',
          value: '優先情報要求に答えるために必要な情報要件を開発する。',
          softSkill: false,
        },
        {
          id: 'T0661',
          value: '効果の尺度とパフォーマンスの尺度を開発する。',
          softSkill: false,
        },
        {
          id: 'T0662',
          value:
            '指導者の指導、優先事項、および/または運用重視に基づいて、コレクション資産を配分する。',
          softSkill: false,
        },
        {
          id: 'T0663',
          value: '軍需品有効性評価または運用評価資料を開発する。',
          softSkill: false,
        },
        {
          id: 'T0664',
          value: 'ターゲットシステムへのアクセスを獲得し、維持するための新しい手法を開発する。',
          softSkill: false,
        },
        {
          id: 'T0665',
          value:
            'サイバー・オペレーションを同期させるために、外部パートナーからのサポートを提供、要求、および/または取得するための標準の開発を開発または参加する。',
          softSkill: false,
        },
        {
          id: 'T0666',
          value:
            '組織の目標を達成するための国際的なサイバーエンゲージメント戦略、ポリシー、および活動を開発または形成する。',
          softSkill: false,
        },
        {
          id: 'T0667',
          value: '潜在的な行動コースを開発する。',
          softSkill: false,
        },
        {
          id: 'T0668',
          value:
            '収集管理者、資産管理者、処理、開発、普及センターにフィードバックを提供する手順を開発する。',
          softSkill: false,
        },
        {
          id: 'T0669',
          value: 'パートナーの計画、運用、能力開発のための戦略とプロセスを開発する。',
          softSkill: false,
        },
        {
          id: 'T0670',
          value: '適切な計画手続きや方針への変更を開発、実施、推薦する。',
          softSkill: false,
        },
        {
          id: 'T0671',
          value: '外部パートナーとのサイバー協力セキュリティ契約を作成、維持、評価する。',
          softSkill: false,
        },
        {
          id: 'T0672',
          value: 'サイバー運用の戦略と計画の文書を作成し、文書化し、検証する。',
          softSkill: false,
        },
        {
          id: 'T0673',
          value: '意思決定者に収集に関する問題を知らせるためにレポートを配布する。',
          softSkill: false,
        },
        {
          id: 'T0674',
          value: 'タスクメッセージと収集計画を配布する。',
          softSkill: false,
        },
        {
          id: 'T0675',
          value: '確立された手順を使用して収集結果の評価を実施し、文書化する。',
          softSkill: false,
        },
        {
          id: 'T0676',
          value: 'サイバーインテリジェンスの収集と生産要件のドラフト。',
          softSkill: false,
        },
        {
          id: 'T0677',
          value:
            'WindowsおよびUNIXシステムで、単純なスクリプト（Perl、VBScriptなど）を編集または実行する。',
          softSkill: false,
        },
        {
          id: 'T0678',
          value: '顧客のインテリジェンスのニーズと必要性を理解するために顧客を関与させる。',
          softSkill: false,
        },
        {
          id: 'T0679',
          value: '運用計画の努力が現行の運用に効果的に移行していることを確認する。',
          softSkill: false,
        },
        {
          id: 'T0680',
          value:
            'インテリジェンス計画の活動が統合され、運用計画のタイムラインと同期されるようにする。',
          softSkill: false,
        },
        {
          id: 'T0681',
          value: '特定された問題や問題に対処するための代替処理、搾取、普及の道筋を確立する。',
          softSkill: false,
        },
        {
          id: 'T0682',
          value:
            '収集要求と重要な情報要件とリーダーシップの優先度情報要件との間のリンクを検証する。',
          softSkill: false,
        },
        {
          id: 'T0683',
          value:
            '承認されたガイダンスおよび/または手順を用いて、処理、搾取および普及管理活動を確立する。',
          softSkill: false,
        },
        {
          id: 'T0684',
          value: 'サイバー活動を通じてもたらされる運用上の影響を予測する。',
          softSkill: false,
        },
        {
          id: 'T0685',
          value: '脅威の意思決定プロセスを評価する。',
          softSkill: false,
        },
        {
          id: 'T0686',
          value: '脅威の脆弱性を特定する。',
          softSkill: false,
        },
        {
          id: 'T0687',
          value: 'Blue Forceの脆弱性に対する脅威を特定する。',
          softSkill: false,
        },
        {
          id: 'T0688',
          value: '有効なソリューションを推奨するために、使用可能な機能を評価する。',
          softSkill: false,
        },
        {
          id: 'T0689',
          value:
            '収集された情報および/または生産されたインテリジェンスが情報要求を満たす程度を評価する。',
          softSkill: false,
        },
        {
          id: 'T0690',
          value: 'インテリジェンスの見積もりを評価し、計画サイクルをサポートする。',
          softSkill: false,
        },
        {
          id: 'T0691',
          value: '利用可能なサイバー諜報能力の雇用に影響を与える条件を評価する。',
          softSkill: false,
        },
        {
          id: 'T0692',
          value: 'ネットワーク分析戦略の効果を生み出し、評価する。',
          softSkill: false,
        },
        {
          id: 'T0693',
          value: '収集操作が操作上の要件と同期する範囲を評価する。',
          softSkill: false,
        },
        {
          id: 'T0694',
          value: '回収計画に対する回収作業の有効性を評価する。',
          softSkill: false,
        },
        {
          id: 'T0695',
          value: '重要性を理解することで、傍受関連のメタデータとコンテンツを調べる。',
          softSkill: false,
        },
        {
          id: 'T0696',
          value:
            'さまざまな方法やツールを使用して、ネットワークデバイス、セキュリティデバイス、端末、または環境に対するエクスプロイトを適用する。',
          softSkill: false,
        },
        {
          id: 'T0697',
          value: '物理的および/または無線手段によるアクセスを可能にする。',
          softSkill: false,
        },
        {
          id: 'T0698',
          value:
            'インテリジェンス、サーベイランス、および一般的な運用画像管理者への視覚化入力を継続的に更新できるようにする。',
          softSkill: false,
        },
        {
          id: 'T0699',
          value:
            '内部と外部のパートナーの意思決定者とのやりとりを促進し、目的を支援するために行動綱領を同期させ、統合する。',
          softSkill: false,
        },
        {
          id: 'T0700',
          value:
            'サイバーオペレーションコミュニティ全体で「ベストプラクティス」と「教訓」の共有を促進する。',
          softSkill: false,
        },
        {
          id: 'T0701',
          value:
            'ツールの開発を強化するために、ツール要件提出のターゲットおよび技術知識を伝える、開発者とのコラボレーションを行う。',
          softSkill: false,
        },
        {
          id: 'T0702',
          value:
            '利用可能な情報規律能力の知識に基づいて収集戦略を策定し、複数の規律の収集機能とターゲットとそのオブザーバブルへのアクセスを調整する方法を収集する。',
          softSkill: false,
        },
        {
          id: 'T0703',
          value:
            '有効性を判断するためのデータ（有効性の指標など）を収集して分析し、後続活動の報告を提供する。',
          softSkill: false,
        },
        {
          id: 'T0704',
          value:
            'サイバーオペレーションとコミュニケーションセキュリティサポートプランを組織の目標に組み込む。',
          softSkill: false,
        },
        {
          id: 'T0705',
          value:
            'インテリジェンスとカウンターインテリジェンスを組み込んでプラン開発をサポートする。',
          softSkill: false,
        },
        {
          id: 'T0706',
          value:
            '伝統的で代替的な技術（ソーシャルネットワーク分析、コールチェーニング、トラフィック分析など）を通じてネットワークに関する情報を収集する。',
          softSkill: false,
        },
        {
          id: 'T0707',
          value: '情報の要求を生成する。',
          softSkill: false,
        },
        {
          id: 'T0708',
          value: '脅威の戦術と方法論を特定する。',
          softSkill: false,
        },
        {
          id: 'T0709',
          value:
            'サイバー・オペレーションをサポートするすべての利用可能なパートナー・インテリジェンス機能と制限を特定する。',
          softSkill: false,
        },
        {
          id: 'T0710',
          value: '脅威の重要な機能、要件、および脆弱性を特定し、評価する。',
          softSkill: false,
        },
        {
          id: 'T0711',
          value: '関連する情報や情報の要件を特定、草案作成、評価、優先順位付けする。',
          softSkill: false,
        },
        {
          id: 'T0712',
          value: '外部パートナーとのセキュリティ協力の優先順位を特定し、管理する。',
          softSkill: false,
        },
        {
          id: 'T0713',
          value: '優先情報要件を指定するためのインテリジェンス要件を特定して提出する。',
          softSkill: false,
        },
        {
          id: 'T0714',
          value:
            '特定の組織や機能グループとプロセス、機能、成果を調整するためのメカニズムとして機能するコラボレーション・フォーラムを特定する。',
          softSkill: false,
        },
        {
          id: 'T0715',
          value: '収集ギャップと標的に対する潜在的収集戦略を特定する。',
          softSkill: false,
        },
        {
          id: 'T0716',
          value: '指定された収集当局との協調要件と手順を特定する。',
          softSkill: false,
        },
        {
          id: 'T0717',
          value: '重要なターゲット要素を特定する。',
          softSkill: false,
        },
        {
          id: 'T0718',
          value: '情報のギャップと不足を特定する。',
          softSkill: false,
        },
        {
          id: 'T0719',
          value: 'サイバーインテリジェンスのギャップやサイバー運用計画の不備を特定する。',
          softSkill: false,
        },
        {
          id: 'T0720',
          value: 'ターゲット技術の理解と革新的なコレクション手法の開発におけるギャップを特定する。',
          softSkill: false,
        },
        {
          id: 'T0721',
          value:
            '処理、開発、普及のアーキテクチャーの有効性を混乱させたり低下させたりする可能性のある問題や問題を特定する。',
          softSkill: false,
        },
        {
          id: 'T0722',
          value: '分析とターゲット開発を可能にするネットワークコンポーネントとその機能を特定する。',
          softSkill: false,
        },
        {
          id: 'T0723',
          value: '優先情報要件に対するアプリケーションの潜在的収集規則を特定する。',
          softSkill: false,
        },
        {
          id: 'T0724',
          value: 'ネットワーク内の潜在的な脆弱性と脆弱性を特定する。',
          softSkill: false,
        },
        {
          id: 'T0725',
          value: '計画、運用、目標サイクルをサポートするためのリスクを特定し、軽減する。',
          softSkill: false,
        },
        {
          id: 'T0726',
          value:
            '適用されたインテリジェンス環境の準備から派生する生産の必要性、範囲、および時間枠を特定する。',
          softSkill: false,
        },
        {
          id: 'T0727',
          value: '地理空間分析手法を使用してターゲットを特定、検出、追跡する。',
          softSkill: false,
        },
        {
          id: 'T0728',
          value: '脅威要因に基づいた行動の入力や発達を促す。',
          softSkill: false,
        },
        {
          id: 'T0729',
          value:
            '外部パートナーにサイバー・オペレーションのパートナーシップ活動に関する新規または改訂された方針およびガイダンスの潜在的影響を知らせる。',
          softSkill: false,
        },
        {
          id: 'T0730',
          value:
            '確立された手順を用いて評価結果を利害関係者（例えば、回収管理者、資産管理者、処理、搾取および普及センター）に伝える。',
          softSkill: false,
        },
        {
          id: 'T0731',
          value: 'タスク指導の要請を開始し、収集管理を支援する。',
          softSkill: false,
        },
        {
          id: 'T0732',
          value: 'サイバープランニング/ターゲティングの取り組みを他の組織と統合する。',
          softSkill: false,
        },
        {
          id: 'T0733',
          value: '環境準備の評価を解釈して、行動の経過を判断する。',
          softSkill: false,
        },
        {
          id: 'T0734',
          value: '情報の要求を出す。',
          softSkill: false,
        },
        {
          id: 'T0735',
          value: '運用計画へのインテリジェンスのサポートを主導し、調整する。',
          softSkill: false,
        },
        {
          id: 'T0736',
          value: '組織の目標と目標要件をサポートするための搾取操作を導くか、可能にする。',
          softSkill: false,
        },
        {
          id: 'T0737',
          value: '優先順位の収集要件を最適な資産とリソースにリンクする。',
          softSkill: false,
        },
        {
          id: 'T0738',
          value:
            'ハードウェアおよびソフトウェア技術の進歩（例えば、研修や会議に出席し、読んでいること）とその潜在的な影響に関する認識を維持する。',
          softSkill: false,
        },
        {
          id: 'T0739',
          value: 'サイバー計画や関連分野に関わる社内外のパートナーとの関係を維持する。',
          softSkill: false,
        },
        {
          id: 'T0740',
          value: '有機的な運用インフラストラクチャの状況認識と機能性を維持する。',
          softSkill: false,
        },
        {
          id: 'T0741',
          value: 'サイバー関連のインテリジェンス要件と関連するタスクの状況認識を維持する。',
          softSkill: false,
        },
        {
          id: 'T0742',
          value: 'パートナーの能力と活動の状況認識を維持する。',
          softSkill: false,
        },
        {
          id: 'T0743',
          value: '状況認識を維持して、事業環境の変化に計画の見直しが必要かどうかを判断する。',
          softSkill: false,
        },
        {
          id: 'T0744',
          value: 'ターゲットリスト（RTL、JTL、CTLなど）を更新する。',
          softSkill: false,
        },
        {
          id: 'T0745',
          value: '顧客の要求をサポートするためのガイドラインを勧める。',
          softSkill: false,
        },
        {
          id: 'T0746',
          value: '必要に応じて収集要件を変更する。',
          softSkill: false,
        },
        {
          id: 'T0747',
          value: '統合されたサイバー運用を監視し、評価し、組織の目標を達成する機会を特定する。',
          softSkill: false,
        },
        {
          id: 'T0748',
          value:
            '指定されたサイバーオペレーションに関連する脅威の処分、活動、戦術、能力、目的などの変化を監視し、報告する。',
          softSkill: false,
        },
        {
          id: 'T0749',
          value: '検証された脅威活動に関する監視と報告を行う。',
          softSkill: false,
        },
        {
          id: 'T0750',
          value: '再割り当てされた収集作業の完了を監視する。',
          softSkill: false,
        },
        {
          id: 'T0751',
          value:
            '組織やパートナーの関心に向けられた敵対的なコンテンツのオープンソースWebサイトを監視する。',
          softSkill: false,
        },
        {
          id: 'T0752',
          value:
            'リーダーシップの優先情報要件を満たす行動環境を監視し、敵対的活動について報告する。',
          softSkill: false,
        },
        {
          id: 'T0753',
          value: '処理、搾取、普及のアーキテクチャの運用状況と有効性を監視する。',
          softSkill: false,
        },
        {
          id: 'T0754',
          value:
            'ターゲットのネットワークを監視して、ターゲットの通信の変更または処理の失敗の通知と警告を提供する。',
          softSkill: false,
        },
        {
          id: 'T0755',
          value: '収集運用管理プロセスの潜在的な要因とリスクについて運用環境を監視する。',
          softSkill: false,
        },
        {
          id: 'T0756',
          value:
            'ターゲットシステムへのアクセスを獲得し、維持するための自動化されたシステムを運用し維持する。',
          softSkill: false,
        },
        {
          id: 'T0757',
          value:
            '収集資産とリソースの組み合わせを最適化して、優先度の高い情報要件に関連する重要な情報に対する有効性と効率を向上させる。',
          softSkill: false,
        },
        {
          id: 'T0758',
          value:
            '適時に、融合した、すべてのソースのサイバーオペレーションインテリジェンスおよび/または兆候と警告インテリジェンス製品（脅威評価、ブリーフィング、インテリジェンス調査、国別調査など）を作成する。',
          softSkill: false,
        },
        {
          id: 'T0759',
          value:
            'そのような方針を支持するか否かの結果の評価を含めるために、政策の見直しと改善に貢献する。',
          softSkill: false,
        },
        {
          id: 'T0760',
          value:
            '必要に応じて、計画チーム、コーディネーショングループ、タスクフォースに対象の専門知識を提供する。',
          softSkill: false,
        },
        {
          id: 'T0761',
          value:
            '必要に応じて、主題に関する専門知識を提供し、計画/開発フォーラムやワーキンググループに支援する。',
          softSkill: false,
        },
        {
          id: 'T0763',
          value: 'サイバー活動における社内外のパートナーとの長期的かつ戦略的な計画作業を実施する。',
          softSkill: false,
        },
        {
          id: 'T0764',
          value:
            '内外のサイバーオペレーションパートナーとの企画活動に、専門分野の専門知識を提供する。',
          softSkill: false,
        },
        {
          id: 'T0765',
          value: '課題の専門知識を練習問題の開発に提供する。',
          softSkill: false,
        },
        {
          id: 'T0766',
          value: '外部調整グループとのやり取りを管理するポリシーを提案する。',
          softSkill: false,
        },
        {
          id: 'T0767',
          value: '組織の目標を達成するためのコンテンツおよび/またはメタデータ分析を実行する。',
          softSkill: false,
        },
        {
          id: 'T0768',
          value:
            'サイバー活動を実施して、コンピュータやコンピュータネットワークに存在する情報を劣化/除去する。',
          softSkill: false,
        },
        {
          id: 'T0769',
          value: 'ターゲットとする自動化アクティビティを実行する。',
          softSkill: false,
        },
        {
          id: 'T0770',
          value: 'ウェブサイトを特徴付ける。',
          softSkill: false,
        },
        {
          id: 'T0771',
          value: 'ウェブサイトの特徴付けに主題専門知識を提供する。',
          softSkill: false,
        },
        {
          id: 'T0772',
          value: '主題の専門知識を準備し、演習に提供する。',
          softSkill: false,
        },
        {
          id: 'T0773',
          value: 'プラットフォーム機能に基づいて収集プラットフォームの収集要件を優先させる。',
          softSkill: false,
        },
        {
          id: 'T0774',
          value: '顧客への分析および/または配布のために抽出されたデータを処理する。',
          softSkill: false,
        },
        {
          id: 'T0775',
          value: 'ネットワーク再構成を作成する。',
          softSkill: false,
        },
        {
          id: 'T0776',
          value: 'ターゲットシステム分析製品を作成する。',
          softSkill: false,
        },
        {
          id: 'T0777',
          value: 'ネットワークまたはシステム管理者とその活動をプロファイルする。',
          softSkill: false,
        },
        {
          id: 'T0778',
          value: 'ターゲットとその活動をプロファイルする。',
          softSkill: false,
        },
        {
          id: 'T0779',
          value:
            '動的な運用状況に対応して、収集資産とリソースを再割り当てして、運用およびインテリジェンスの意思決定者に助言/支援を提供する。',
          softSkill: false,
        },
        {
          id: 'T0780',
          value:
            '戦略的なキャンペーン計画やその他の適応計画の統合コンポーネントとして、収集計画を促進するための助言と擁護のサポートを提供する。',
          softSkill: false,
        },
        {
          id: 'T0781',
          value: '目標点とリエンゲージメントの推奨を提供する。',
          softSkill: false,
        },
        {
          id: 'T0782',
          value: '効果評価の分析とサポートを提供する。',
          softSkill: false,
        },
        {
          id: 'T0783',
          value: '重要な内外のステークホルダーに適切な情報を提供する。',
          softSkill: false,
        },
        {
          id: 'T0784',
          value:
            'インテリジェンス支援計画のインプットに関するサイバー指向のガイダンスとアドバイスを提供する。',
          softSkill: false,
        },
        {
          id: 'T0785',
          value:
            'インテリジェンスの生産、インテリジェンスの報告、収集の要件、および運用の改善に必要な評価とフィードバックを提供する。',
          softSkill: false,
        },
        {
          id: 'T0786',
          value:
            'リーダーシップと顧客に通知するための情報と評価を提供する。目的の開発と改良。運用計画と実行をサポートする。オペレーションの影響を評価する。',
          softSkill: false,
        },
        {
          id: 'T0787',
          value:
            'サイバーオペレーションの目的、優先順位、戦略、計画、およびプログラムの開発と改良のための情報を提供する。',
          softSkill: false,
        },
        {
          id: 'T0788',
          value: 'インプットを提供し、行動後の効果評価を支援する。',
          softSkill: false,
        },
        {
          id: 'T0789',
          value: '計画と指針の作成に助力を提供し、支援する。',
          softSkill: false,
        },
        {
          id: 'T0790',
          value:
            'リーダーシップの受け入れのための効果評価をターゲットにするためのインプットを提供する。',
          softSkill: false,
        },
        {
          id: 'T0791',
          value: '運営支援計画の管理およびロジスティック要素に情報を提供する。',
          softSkill: false,
        },
        {
          id: 'T0792',
          value: '指定された演習、計画活動、および時間に敏感な作業に情報分析とサポートを提供する。',
          softSkill: false,
        },
        {
          id: 'T0793',
          value: '指定された演習や時間に敏感な作業に効果的なサポートを提供する。',
          softSkill: false,
        },
        {
          id: 'T0794',
          value: '運営および再交渉の勧告を提供する。',
          softSkill: false,
        },
        {
          id: 'T0795',
          value: '社内外のパートナーとの間の計画サポートを提供する。',
          softSkill: false,
        },
        {
          id: 'T0796',
          value: 'リアルタイムで実行可能なジオロケーション情報を提供する。',
          softSkill: false,
        },
        {
          id: 'T0797',
          value: 'リーダーシップの目標を達成する目標の推奨事項を提供する。',
          softSkill: false,
        },
        {
          id: 'T0798',
          value: 'ターゲティング商品とターゲティングサポートを指定通りに提供する。',
          softSkill: false,
        },
        {
          id: 'T0799',
          value: '時間の影響を受けやすいターゲット設定をサポートする。',
          softSkill: false,
        },
        {
          id: 'T0800',
          value:
            '組織の目標、リソース、または能力に影響を与える可能性のある、差し迫った敵意や活動の適時の通知を提供する。',
          softSkill: false,
        },
        {
          id: 'T0801',
          value: '必要に応じて、細分化、適応、終了、運用計画の実行を推奨する。',
          softSkill: false,
        },
        {
          id: 'T0802',
          value: '適切な情報源を見直し、収集された情報の妥当性および関連性を判断する。',
          softSkill: false,
        },
        {
          id: 'T0803',
          value: 'ダイアグラムまたはレポート形式でネットワークを再構築する。',
          softSkill: false,
        },
        {
          id: 'T0804',
          value:
            'サイバー効果を達成するために設計された運用中の目標に対する情報収集および/または環境準備活動を記録する。',
          softSkill: false,
        },
        {
          id: 'T0805',
          value: 'インテリジェンスに起因する重要なネットワークイベントと侵入を報告する。',
          softSkill: false,
        },
        {
          id: 'T0806',
          value:
            '承認されたガイダンスおよび/または手続きに従って規律の収集資産およびリソースを使用して収集された、規律特有の処理、搾取、および情報の配布を要求する。',
          softSkill: false,
        },
        {
          id: 'T0807',
          value:
            'オープンソースと分類されたソースの両方で新興テクノロジー（コンピュータやテレフォニーネットワーク、衛星、ケーブル、ワイヤレス）の通信動向を調査する。',
          softSkill: false,
        },
        {
          id: 'T0808',
          value: '組織のリーダーシップ目標と計画の指針をレビューし、理解する。',
          softSkill: false,
        },
        {
          id: 'T0809',
          value: '割り当てられたコレクション資産の機能を確認する。',
          softSkill: false,
        },
        {
          id: 'T0810',
          value: '精度/適用性に関する情報収集のガイダンスを確認する。',
          softSkill: false,
        },
        {
          id: 'T0811',
          value: '優先順位付けされた収集要件と必須情報のリストを確認する。',
          softSkill: false,
        },
        {
          id: 'T0812',
          value: '必要に応じて、重要なコレクションプランを確認して更新する。',
          softSkill: false,
        },
        {
          id: 'T0813',
          value:
            'サイバー機能の研究、開発、および/または取得のための運用要件をレビュー、承認、優先順位付け、提出する。',
          softSkill: false,
        },
        {
          id: 'T0814',
          value: '最適な資産とリソースの可用性に基づいて収集マトリックスを改訂する。',
          softSkill: false,
        },
        {
          id: 'T0815',
          value: 'ソースとメソッドを保護するために情報をサニタイズし、最小化する。',
          softSkill: false,
        },
        {
          id: 'T0816',
          value: 'サイバーインテリジェンス計画の取り組みを対象とする。',
          softSkill: false,
        },
        {
          id: 'T0817',
          value:
            '複雑で珍しい状況の調査を支援できるような主題の専門家を識別することによって、パートナーチームからの情報の送り手としての役割を担う。',
          softSkill: false,
        },
        {
          id: 'T0818',
          value: '外部のパートナーとの連絡役として働く。',
          softSkill: false,
        },
        {
          id: 'T0819',
          value:
            '質、適時性、回収要件に対する回収の有効性に関するリクエスタからのフィードバックを求めて、管理する。',
          softSkill: false,
        },
        {
          id: 'T0820',
          value:
            '収集資産およびリソースの再タスクまたはリダイレクトを必要とする、収集計画および/または運用環境への変更を指定する。',
          softSkill: false,
        },
        {
          id: 'T0821',
          value: '近い将来に実行されなければならない分野別のコレクションやタスクを指定する。',
          softSkill: false,
        },
        {
          id: 'T0822',
          value: '収集要求として処理するために収集要求管理セクションに情報要求を提出する。',
          softSkill: false,
        },
        {
          id: 'T0823',
          value: 'サイバーオペレーションの衝突解消の要求を提出または応答する。',
          softSkill: false,
        },
        {
          id: 'T0824',
          value: '担保効果の識別および文書化をサポートする。',
          softSkill: false,
        },
        {
          id: 'T0825',
          value: '適切なサイバー国際協力活動とそれに関連するリソース要件を同期させる。',
          softSkill: false,
        },
        {
          id: 'T0826',
          value: 'セキュリティ協力計画のサイバー部分を同期させる。',
          softSkill: false,
        },
        {
          id: 'T0827',
          value:
            '利用可能なコラボレーション機能とテクニックを使用して、利用可能なすべてのオーガニックおよびパートナーインテリジェンスコレクション資産の統合雇用を同期させる。',
          softSkill: false,
        },
        {
          id: 'T0828',
          value: 'ローカルで開発された操作上のツールをテストし、評価する。',
          softSkill: false,
        },
        {
          id: 'T0829',
          value: 'ターゲットツールに対する内部開発ツールとテクニックをテストする。',
          softSkill: false,
        },
        {
          id: 'T0830',
          value:
            '確立された手順を使用して、収集要求および生産要件として処理された情報要求のステータスを追跡する。',
          softSkill: false,
        },
        {
          id: 'T0831',
          value: '収集要求を適用可能な規律固有の収集要件に変換する。',
          softSkill: false,
        },
        {
          id: 'T0832',
          value:
            '収集結果の管理（例えば、習得した教訓）を使用して、収集管理の効率と有効性を向上させる機会を特定する。',
          softSkill: false,
        },
        {
          id: 'T0833',
          value: '確立された基準に従って情報の要求を検証する。',
          softSkill: false,
        },
        {
          id: 'T0834',
          value:
            'プランナー、インテリジェンスアナリスト、およびコレクションマネージャーと緊密に連携して、インテリジェンスの要件と収集計画が正確で最新であることを確認する。',
          softSkill: false,
        },
        {
          id: 'T0835',
          value:
            '企画者、アナリスト、および収集マネージャーと密接に連携して、情報のギャップを特定し、インテリジェンス要件が正確で最新であることを確認する。',
          softSkill: false,
        },
        {
          id: 'T0836',
          value: 'イベントや演習の結果を伝える教訓を文書化する。',
          softSkill: false,
        },
        {
          id: 'T0837',
          value:
            '組織の目的に影響を与える言語や文化の問題について、マネージャーやオペレーターにアドバイスする。',
          softSkill: false,
        },
        {
          id: 'T0838',
          value: '言語および/または文化的専門知識を使用して情報を分析および処理する。',
          softSkill: false,
        },
        {
          id: 'T0839',
          value:
            '分析、ターゲティング、および収集の機会を促進するために、ターゲットのモチベーションおよび/または参照フレームを評価、文書化および適用する。',
          softSkill: false,
        },
        {
          id: 'T0840',
          value:
            '収集、分析、配布を強化するために、内部および/または外部の組織ラインを介してコラボレーションする。',
          softSkill: false,
        },
        {
          id: 'T0841',
          value:
            'すべてのソースのターゲット調査を行い、オープンソース資料をターゲット言語で使用するようにする。',
          softSkill: false,
        },
        {
          id: 'T0842',
          value:
            'ターゲットとするコミュニケーションの分析を行い、組織の目的をサポートするための重要な情報を特定する。',
          softSkill: false,
        },
        {
          id: 'T0843',
          value: '質の高いレビューを行い、転写されたか翻訳された資料にフィードバックを提供する。',
          softSkill: false,
        },
        {
          id: 'T0844',
          value:
            'メタデータを評価し解釈してパターン、異常、またはイベントを検索し、ターゲット設定、分析、および処理を最適化する。',
          softSkill: false,
        },
        {
          id: 'T0845',
          value: 'サイバー脅威の戦術と方法論を特定する。',
          softSkill: false,
        },
        {
          id: 'T0846',
          value: 'グローバルネットワーク内のターゲット通信を特定する。',
          softSkill: false,
        },
        {
          id: 'T0847',
          value:
            'ターゲット通信ツール、技術、ターゲット通信ネットワーク（キャパシティ、機能、パス、クリティカルノードなど）の特性、ターゲット設定、収集、分析に対する潜在的な影響を認識する。',
          softSkill: false,
        },
        {
          id: 'T0848',
          value: 'コレクションマネージャにフィードバックを提供し、将来の収集と分析を強化する。',
          softSkill: false,
        },
        {
          id: 'T0849',
          value: '最初のソースデータで外国語と方言の識別を行う。',
          softSkill: false,
        },
        {
          id: 'T0850',
          value: 'テクニカルネットワークの分析とマッピングを実行またはサポートする。',
          softSkill: false,
        },
        {
          id: 'T0851',
          value: '言語処理ツールの開発を最適化するための要件とフィードバックを提供する。',
          softSkill: false,
        },
        {
          id: 'T0852',
          value: '適切なソーシャルネットワーク分析と文書化を実行する。',
          softSkill: false,
        },
        {
          id: 'T0853',
          value:
            'ターゲットグラフィック（マシン間通信を含む）および/または音声言語資料をスキャンし、識別し、優先順位を付ける。',
          softSkill: false,
        },
        {
          id: 'T0854',
          value: '適切な顧客に対し、重要な情報や時間の影響を受けやすい情報を提供する。',
          softSkill: false,
        },
        {
          id: 'T0855',
          value: 'ターゲット言語のターゲット音声素材をターゲット言語に変換する。',
          softSkill: false,
        },
        {
          id: 'T0856',
          value:
            'ターゲットのグラフィック素材を翻訳する（例えば、逐語的、要旨、および/または要約）。',
          softSkill: false,
        },
        {
          id: 'T0857',
          value: '音声データ（例えば、逐語的、要旨、および/または要約）を翻訳する。',
          softSkill: false,
        },
        {
          id: 'T0858',
          value: 'コンピュータプログラム内の外国語の用語（例：コメント、変数名）を特定する。',
          softSkill: false,
        },
        {
          id: 'T0859',
          value: 'ほぼリアルタイムの言語解析サポート（例えば、ライブ操作）を提供する。',
          softSkill: false,
        },
        {
          id: 'T0860',
          value: 'ターゲット言語でサイバー/テクノロジーに関連する用語を特定する。',
          softSkill: false,
        },
        {
          id: 'T0861',
          value:
            '既存のサービスと新しいサービスの両方がプライバシーとデータセキュリティの義務を遵守するように、一般顧問、外交、ビジネスと協力する。',
          softSkill: false,
        },
        {
          id: 'T0862',
          value:
            '法律顧問、経営陣、主要部署、委員会と協力して、現行の組織と法律上の慣行と要件を反映した適切なプライバシーと機密保持の同意、認証書、情報通知と資料を保持し、維持する。',
          softSkill: false,
        },
        {
          id: 'T0863',
          value:
            '市民の権利、市民の自由およびプライバシーの考慮事項を含むプログラム、方針および手続きが、総合的かつ包括的な方法で確実に行われるように、適切な規制機関と調整する。',
          softSkill: false,
        },
        {
          id: 'T0864',
          value: '規制機関と認定機関との連絡。',
          softSkill: false,
        },
        {
          id: 'T0865',
          value:
            '外務と協力して、規制当局およびプライバシーとデータセキュリティの問題を担当する他の政府関係者との関係を構築する。',
          softSkill: false,
        },
        {
          id: 'T0866',
          value:
            '適用される連邦および州のプライバシー法および認定基準に関する最新の知識を維持し、組織の適応と遵守を確実にするために情報プライバシー技術の進歩を監視する。',
          softSkill: false,
        },
        {
          id: 'T0867',
          value:
            'すべての処理および/またはデータベースが、必要に応じてローカルプライバシー/データ保護機関に登録されていることを確認する。',
          softSkill: false,
        },
        {
          id: 'T0868',
          value:
            'ビジネスチームや上級管理職と協力して、プライバシーとデータセキュリティに関する「ベストプラクティス」の認識を確実にする。',
          softSkill: false,
        },
        {
          id: 'T0869',
          value: '組織の上級管理職と協力して、組織全体のプライバシー監視委員会を設立する。',
          softSkill: false,
        },
        {
          id: 'T0870',
          value: 'プライバシー監視委員会の活動のリーダーシップの役割を果たす。',
          softSkill: false,
        },
        {
          id: 'T0871',
          value: 'サイバーのプライバシーとセキュリティの方針と手順に関するコラボレーションを行う。',
          softSkill: false,
        },
        {
          id: 'T0872',
          value:
            'プライバシーの遵守とリスク軽減に取り組むために、セキュリティリスクアセスメントプロセスに関するサイバーセキュリティ担当者とのコラボレーションを行う。',
          softSkill: false,
        },
        {
          id: 'T0873',
          value:
            'シニアマネジメントとの連携を図り、適用されるプライバシー規制を遵守しながら価値を最大限に活用する方法で情報の収集、使用、共有に関する戦略計画を立てる。',
          softSkill: false,
        },
        {
          id: 'T0874',
          value: '情報資源と技術に関する執行役員への戦略的指導を提供する。',
          softSkill: false,
        },
        {
          id: 'T0875',
          value: '情報インフラストラクチャの開発と実装に関してセキュリティ担当者を支援する。',
          softSkill: false,
        },
        {
          id: 'T0876',
          value:
            '企業のコンプライアンスオフィサーと、プライバシー侵害の兆候に関する自己開示の文書化と報告の手順について協議する。',
          softSkill: false,
        },
        {
          id: 'T0877',
          value: '消費者情報のアクセス権を監督するために、適切な組織単位と協力して作業する。',
          softSkill: false,
        },
        {
          id: 'T0878',
          value: '技術システムのユーザーのための情報プライバシーの連絡役として働く。',
          softSkill: false,
        },
        {
          id: 'T0879',
          value: '情報システム部門との連絡役を担う。',
          softSkill: false,
        },
        {
          id: 'T0880',
          value:
            '企業のプライバシーポリシー、データ処理の実践と手続き、法的義務の理解を深めるためのプライバシー教材やその他のコミュニケーションの開発を行う。',
          softSkill: false,
        },
        {
          id: 'T0881',
          value:
            'すべての従業員、ボランティア、請負業者、提携会社、ビジネスアソシエート、およびその他の適切な第三者に初期のプライバシートレーニングとオリエンテーションの監督、指示、配信、または確実な提供を行う。',
          softSkill: false,
        },
        {
          id: 'T0882',
          value: '進行中のプライバシートレーニングと意識啓発活動を実施する。',
          softSkill: false,
        },
        {
          id: 'T0883',
          value:
            'プライバシーとデータセキュリティの問題に関心を持つ消費者団体や他のNGOとの関係を発展させ、プライバシーとデータセキュリティに関連する公開イベントへの企業参加を管理するために外交と協力する。',
          softSkill: false,
        },
        {
          id: 'T0884',
          value:
            '組織の管理、法律顧問、その他の関連当事者と協力して、プライバシーに関する法律、規則または基準を採用または改正する政府機関を含む外部当事者との組織の情報プライバシー利益を表明する。',
          softSkill: false,
        },
        {
          id: 'T0885',
          value:
            '理事会、CEOまたはその他の責任ある個人または委員会へのプライバシープログラムのステータスに関する定期的な報告を行う。',
          softSkill: false,
        },
        {
          id: 'T0886',
          value:
            '消費者と従業員のデータに対する懸念に関するプレスやその他のお問い合わせに対応するために、外部からの作業を行う。',
          softSkill: false,
        },
        {
          id: 'T0887',
          value: '組織のプライバシープログラムのリーダーシップを提供する。',
          softSkill: false,
        },
        {
          id: 'T0888',
          value:
            'プライバシーの専門家を指揮監督し、世界中の上級管理職とプライバシーとデータセキュリティプログラムを調整し、組織全体の一貫性を確保する。',
          softSkill: false,
        },
        {
          id: 'T0889',
          value:
            '人事部、情報セキュリティ責任者、管理および法律顧問と協力して、プライバシーの慣行の遵守と、組織の従業員、長時間の労働力およびすべてのビジネスアソシエートの個人に対するプライバシーポリシーの遵守の怠慢に対する一貫した適用を確実にする。',
          softSkill: false,
        },
        {
          id: 'T0890',
          value:
            '企業のプライバシーポリシーおよび手続きを遵守しなかった場合の適切な制裁を策定する。',
          softSkill: false,
        },
        {
          id: 'T0891',
          value: '企業のプライバシーポリシーや情報の慣行の不履行を解決する。',
          softSkill: false,
        },
        {
          id: 'T0892',
          value:
            'プライバシーのためのリスク管理とコンプライアンスのフレームワークの策定と調整を行う。',
          softSkill: false,
        },
        {
          id: 'T0893',
          value:
            '同社のデータおよびプライバシープロジェクトを包括的にレビューし、企業のプライバシーとデータセキュリティの目標とポリシーに一貫性があることを確認する。',
          softSkill: false,
        },
        {
          id: 'T0894',
          value:
            '新製品および新サービスの開発が企業のプライバシーポリシーおよび法的義務と一貫していることを保証するために企業全体の手順を開発および管理する。',
          softSkill: false,
        },
        {
          id: 'T0895',
          value:
            '組織のプライバシーポリシーと手順に関するすべての苦情を受領し、文書化し、追跡し、調査し、行動するプロセスを確立する。',
          softSkill: false,
        },
        {
          id: 'T0896',
          value:
            '組織の範囲内で、法律で要求される保護された健康情報へのアクセスを追跡し、資格のある個人がそのような活動をレビューしたり、報告を受けたりすることを可能にするメカニズムをその管理と運用とともに構築する。',
          softSkill: false,
        },
        {
          id: 'T0897',
          value:
            'プライバシーとセキュリティ関連プロジェクトの計画、設計、評価においてリーダーシップを提供する。',
          softSkill: false,
        },
        {
          id: 'T0898',
          value: '内部のプライバシー監査プログラムを確立する。',
          softSkill: false,
        },
        {
          id: 'T0899',
          value:
            '法律、規制または会社の方針の変更を考慮してプライバシープログラムを定期的に改訂する。',
          softSkill: false,
        },
        {
          id: 'T0900',
          value:
            '組織の管理と管理および法律顧問と連携して、開発ガイダンスを提供し、組織情報プライバシーポリシーと手順の特定、実装、および維持を支援する。',
          softSkill: false,
        },
        {
          id: 'T0901',
          value:
            '技術の使用が個人情報の使用、収集、開示に関するプライバシー保護を維持し、侵食しないことを保証する。',
          softSkill: false,
        },
        {
          id: 'T0902',
          value:
            'セキュリティとプライバシーのコンプライアンスのためのシステム開発と運用の監視を行う。',
          softSkill: false,
        },
        {
          id: 'T0903',
          value:
            '収集された個人情報の種類や被害者の人数など、個人情報のプライバシーに関する提案された規則のプライバシー影響評価を実施する。',
          softSkill: false,
        },
        {
          id: 'T0904',
          value:
            '組織の他のコンプライアンスおよび運用評価機能と連携して、定期的な情報プライバシー影響評価と継続的なコンプライアンス監視活動を実施する。',
          softSkill: false,
        },
        {
          id: 'T0905',
          value:
            'すべてのシステム関連の情報セキュリティ計画を確認して、セキュリティとプライバシーの実践の整合性を確保する。',
          softSkill: false,
        },
        {
          id: 'T0906',
          value:
            '組織の方針、手順、法的要件との調整を確実にするために、保護された情報のリリースに関するあらゆる組織関係者と協力する。',
          softSkill: false,
        },
        {
          id: 'T0907',
          value:
            '個人情報および/または保護された情報の公開または開示のための個々の要求を考慮し管理する。',
          softSkill: false,
        },
        {
          id: 'T0908',
          value:
            'プライバシーおよびデータセキュリティポリシーおよび法的要件への準拠を検証および検証するための手順を開発および管理する。',
          softSkill: false,
        },
        {
          id: 'T0909',
          value:
            'すべてのトレーディングパートナとビジネスアソシエイツの契約の実装と継続的なコンプライアンスモニタリングに参加し、プライバシーに関するすべての懸念事項、要件、責任が確実に対処されるようにする。',
          softSkill: false,
        },
        {
          id: 'T0910',
          value: 'ビジネスパートナ契約に関連する弁護士として働く、または相談する。',
          softSkill: false,
        },
        {
          id: 'T0911',
          value: '従業員またはビジネスパートナーによる個人情報の使用または開示の影響を緩和する。',
          softSkill: false,
        },
        {
          id: 'T0912',
          value: '是正措置手続きを策定及び適用する。',
          softSkill: false,
        },
        {
          id: 'T0913',
          value:
            '組織のプライバシーポリシーおよび手続に関するすべての苦情について、他の同様の機能、必要に応じて法律顧問と協調して調整する活動を管理する。',
          softSkill: false,
        },
        {
          id: 'T0914',
          value:
            '個人情報保護責任者、最高情報セキュリティ責任者、および他のビジネスリーダーと緊密に協力して、連邦および州のプライバシーに関する法律および規制 に関するコンプライアンスを確実にするための組織のコンプライアンスプログラムをサポートする。',
          softSkill: false,
        },
        {
          id: 'T0915',
          value:
            '潜在的な企業コンプライアンスのギャップおよび/またはリスク領域を特定して修正し、プライバシー規制を完全に遵守するようにする。',
          softSkill: false,
        },
        {
          id: 'T0916',
          value:
            'プライバシー事故および違反の管理は、プライバシー担当者、最高情報セキュリティ責任者、弁護士および事業部門と連携して行う。',
          softSkill: false,
        },
        {
          id: 'T0917',
          value:
            '最高情報セキュリティ責任者と調整して、セキュリティとプライバシーの実践を調和させる。',
          softSkill: false,
        },
        {
          id: 'T0918',
          value: 'プライバシー規定を遵守するための組織全体のポリシーと手順を確立、実施、維持する。',
          softSkill: false,
        },
        {
          id: 'T0919',
          value:
            '会社が適切なプライバシーと守秘義務通知、同意書と許可書式、および資料を保持していることを確認する。',
          softSkill: false,
        },
        {
          id: 'T0920',
          value:
            '適切なコミュニケーションとトレーニングを開発・維持し、すべての労働者および取締役会のメンバーにプライバシー遵守の問題と要件、および非遵守の結果について啓発・教育する。',
          softSkill: false,
        },
        {
          id: 'T0921',
          value: '組織のプライバシープログラムに関連するビジネスパートナー要件を特定する。',
          softSkill: false,
        },
        {
          id: 'T0922',
          value:
            '会社のプライバシーポリシーおよび手順に関する苦情を受け付け、文書化・追跡・調査し、適切に是正措置を講じるプロセスを確立し、管理する。',
          softSkill: false,
        },
        {
          id: 'T0923',
          value:
            '関連する規制機関および他の法人、および組織の役員と協力し、コンプライアンスのレビューまたは調査に関与する。',
          softSkill: false,
        },
        {
          id: 'T0924',
          value: '継続的なプライバシー遵守監視活動を実施する。',
          softSkill: false,
        },
        {
          id: 'T0925',
          value: '情報プライバシー技術の進歩を監視し、組織の採用とコンプライアンスを確保する。',
          softSkill: false,
        },
        {
          id: 'T0926',
          value:
            'プライバシーの訓練資料およびその他のコミュニケーションの開発を支援し、企業のプライバシーポリシー、データ処理の実践および手順、法的義務の理解を向上させる。',
          softSkill: false,
        },
        {
          id: 'T0927',
          value: 'ITセキュリティ専門家チームを任命し、指導する。',
          softSkill: false,
        },
        {
          id: 'T0928',
          value: '主要な利害関係者と協力して、サイバーセキュリティリスク管理プログラムを確立する。',
          softSkill: false,
        },
        {
          id: 'T0929',
          value: 'リスク管理フレームワークの実行に関連する特定の役割に個人を特定し、割り当てる。',
          softSkill: false,
        },
        {
          id: 'T0930',
          value: 'リスク許容度の決定を含む組織のためのリスク管理戦略を確立する。',
          softSkill: false,
        },
        {
          id: 'T0931',
          value:
            'システムがサポートするミッション、ビジネス機能、およびミッション/ビジネスプロセスを特定する。',
          softSkill: false,
        },
        {
          id: 'T0932',
          value:
            'システムの開発、実装、運用、または維持にセキュリティ関心を持つ利害関係者を特定する。',
          softSkill: false,
        },
        {
          id: 'T0933',
          value:
            'システムの開発、実装、運用、または維持にセキュリティ関心を持つ利害関係者を特定する。',
          softSkill: false,
        },
        {
          id: 'T0934',
          value: '利害関係者の資産を特定し、保護する。',
          softSkill: false,
        },
        {
          id: 'T0935',
          value: '利害関係者の資産に対する初期リスク評価を実施し、リスク評価を継続的に更新する。',
          softSkill: false,
        },
        {
          id: 'T0936',
          value: '利害関係者の保護ニーズと利害関係者のセキュリティ要件を定義する。',
          softSkill: false,
        },
        {
          id: 'T0937',
          value: 'エンタープライズアーキテクチャ内でのシステムの配置を決定する。',
          softSkill: false,
        },
        {
          id: 'T0938',
          value: '組織システムで継承可能な組織全体に共通する統制を特定する。',
          softSkill: false,
        },
        {
          id: 'T0939',
          value: '同じ影響レベルの組織システムに対して、第2レベルのセキュリティ分類を実施する。',
          softSkill: false,
        },
        {
          id: 'T0940',
          value: 'システムの境界を決定する。',
          softSkill: false,
        },
        {
          id: 'T0941',
          value: 'システムと組織に割り当てられたセキュリティ要件を特定する。',
          softSkill: false,
        },
        {
          id: 'T0942',
          value: 'システムによって処理、保存、または送信される情報の種類を特定する。',
          softSkill: false,
        },
        {
          id: 'T0943',
          value: 'システムを分類し、システム要件の一部としてセキュリティ分類結果を文書化する。',
          softSkill: false,
        },
        {
          id: 'T0944',
          value: 'システムの特性を記述する。',
          softSkill: false,
        },
        {
          id: 'T0945',
          value: '適切な組織プログラム/管理部門にシステムを登録する。',
          softSkill: false,
        },
        {
          id: 'T0946',
          value:
            'システムのセキュリティ管理策を選択し、計画された制御実装の機能的説明をセキュリティ計画に文書化する。',
          softSkill: false,
        },
        {
          id: 'T0947',
          value:
            'セキュリティ管理策の有効性を監視する戦略を開発し、システムレベルの戦略を組織およびミッション/ビジネスプロセスレベルの監視戦略と調整する。',
          softSkill: false,
        },
        {
          id: 'T0948',
          value: 'セキュリティ計画をレビューして承認する。',
          softSkill: false,
        },
        {
          id: 'T0949',
          value:
            'セキュリティ計画またはその他のシステム文書で指定されたセキュリティ管理策を実装する。',
          softSkill: false,
        },
        {
          id: 'T0950',
          value:
            '計画されたセキュリティ管理策の実装に対する変更を文書化し、システムの構成ベースラインを確立する。',
          softSkill: false,
        },
        {
          id: 'T0951',
          value: 'システムおよび組織のセキュリティ管理策を評価する計画を開発、レビュー、承認する。',
          softSkill: false,
        },
        {
          id: 'T0952',
          value: 'セキュリティ評価計画で定義された評価手順に従って、セキュリティ管理を評価する。',
          softSkill: false,
        },
        {
          id: 'T0953',
          value:
            'セキュリティ管理策評価からの問題点、調査結果、および推奨事項を文書化するセキュリティ評価レポートを準備する。',
          softSkill: false,
        },
        {
          id: 'T0954',
          value:
            'セキュリティ評価レポートの所見および推奨事項に基づき、セキュリティ管理策の初期是正処置を実施する。是正処置を施したセキュリティ管理策を再評価する。',
          softSkill: false,
        },
        {
          id: 'T0955',
          value:
            'セキュリティ評価レポートの調査結果と推奨事項に基づき、是正措置を除外した行動計画およびマイルストーンを作成する。',
          softSkill: false,
        },
        {
          id: 'T0956',
          value: '承認パッケージを組み立て、承認担当官に提出して裁定を仰ぐ。',
          softSkill: false,
        },
        {
          id: 'T0957',
          value:
            'システム運用または使用、または共通制御の提供または使用から生じるリスクを判断する。',
          softSkill: false,
        },
        {
          id: 'T0958',
          value: 'リスクの判断に基づいて、好ましい行動方針を特定し、実施する。',
          softSkill: false,
        },
        {
          id: 'T0959',
          value:
            'システムの運用、使用、あるいは共通制御の提供または使用から生じるリスクが受容可能であるかどうかを判断する。',
          softSkill: false,
        },
        {
          id: 'T0960',
          value: 'システムとその運用環境の変更を監視する。',
          softSkill: false,
        },
        {
          id: 'T0961',
          value:
            '組織が定義した監視戦略に従って、システム内で使用され、システムが継承するセキュリティ管理策を評価する。',
          softSkill: false,
        },
        {
          id: 'T0962',
          value:
            '継続的な監視活動の結果、リスク評価、および行動計画とマイルストーンの未解決項目に基づいてリスクに対応する。',
          softSkill: false,
        },
        {
          id: 'T0963',
          value:
            '継続的監視プロセスの結果に基づいて、セキュリティ計画、セキュリティ評価レポート、および行動計画とマイルストーンを更新する。',
          softSkill: false,
        },
        {
          id: 'T0964',
          value:
            'システムのセキュリティ状態（セキュリティ管理策の有効性を含む）を、監視戦略に従って継続的に承認担当者に報告する。',
          softSkill: false,
        },
        {
          id: 'T0965',
          value:
            'システムのセキュリティ状態（セキュリティ対策の有効性を含む）を継続的にレビューし、リスクが許容範囲内に留まっているかどうかを判断する。',
          softSkill: false,
        },
        {
          id: 'T0966',
          value:
            'システムがサービスから削除されたときに必要なアクションを実行するシステム廃棄戦略を実装する。',
          softSkill: false,
        },
        {
          id: 'T0967',
          value: '組織内で継続的な監視を支援し、推進する。',
          softSkill: false,
        },
        {
          id: 'T0968',
          value: '必要に応じて、継続的監視ワーキンググループに必要なスタッフを配置する。',
          softSkill: false,
        },
        {
          id: 'T0969',
          value: '継続的な監視活動を支援するための報告要件を特定する。',
          softSkill: false,
        },
        {
          id: 'T0970',
          value: '継続的監視プログラムの有効性を測定するための評価基準を策定する。',
          softSkill: false,
        },
        {
          id: 'T0971',
          value:
            '継続的な監視プログラムを組織のより広範な情報セキュリティのガバナンス構造およびポリシーに統合する方法を決定する。',
          softSkill: false,
        },
        {
          id: 'T0972',
          value:
            '継続的な監視採点と評価指標を使用して、持続的な問題に対処するための情報セキュリティ投資の意思決定を行う。',
          softSkill: false,
        },
        {
          id: 'T0973',
          value:
            '継続的な監視担当者が、割り当てられた任務を遂行するために必要な訓練とリソース（例えば、スタッフと予算）を確保する。',
          softSkill: false,
        },
        {
          id: 'T0974',
          value:
            '組織のリスク分析アナリストと協力して、継続的なモニタリングレポートが組織の適切なレベルを網羅していることを確認する。',
          softSkill: false,
        },
        {
          id: 'T0975',
          value:
            '組織のリスクアナリストと協力して、継続的監視を支援するためにリスク評価基準が現実的に定義されるようにする。',
          softSkill: false,
        },
        {
          id: 'T0976',
          value:
            '組織の関係者と協力して、継続的な監視ツールのデータがリスクレベルの状況認識を提供することを確認する。',
          softSkill: false,
        },
        {
          id: 'T0977',
          value: '継続的な監視データの許容できないリスク閾値のトリガーを設定する。',
          softSkill: false,
        },
        {
          id: 'T0978',
          value: '継続的監視を支援するためにリスク評価基準が現実的に定義されるようにする。',
          softSkill: false,
        },
        {
          id: 'T0980',
          value: '継続的監視プログラムの管理と実施を担当する適任者を指名する。',
          softSkill: false,
        },
        {
          id: 'T0981',
          value:
            '継続的監視の利害関係者を特定し、プログラムに関する情報を提供するプロセスを確立する。',
          softSkill: false,
        },
        {
          id: 'T0982',
          value:
            '継続的監視プログラムによって満たされる、セキュリティ指向の組織の報告要件を特定する。',
          softSkill: false,
        },
        {
          id: 'T0983',
          value:
            '継続的な監視データを使用して、持続的な問題に対処するための情報セキュリティ投資の意思決定を行う。',
          softSkill: false,
        },
        {
          id: 'T0984',
          value:
            '容認できないリスクを定義し、解決のための行動を起こすために使用できる継続的な監視プログラム内のトリガーを定義する。',
          softSkill: false,
        },
        {
          id: 'T0985',
          value: '継続的監視プログラムの効果を測定するための採点および評価基準を確立する。',
          softSkill: false,
        },
        {
          id: 'T0986',
          value:
            'セキュリティ管理者と協力して、システムレベルで適切な継続的な監視の報告要件を確立する。',
          softSkill: false,
        },
        {
          id: 'T0987',
          value: '継続的な監視ツールとテクノロジーを使用して、リスクを継続的に評価する。',
          softSkill: false,
        },
        {
          id: 'T0988',
          value:
            '自動制御評価で使用するために、継続的監視プログラムで特定された基準に準拠した、適切な報告要件を確立する。',
          softSkill: false,
        },
        {
          id: 'T0989',
          value:
            '継続的な監視ツールとテクノロジーからのデータがまだ十分でない、または適切な品質に達していない場合は、非自動化評価方法を使用する。',
          softSkill: false,
        },
        {
          id: 'T0990',
          value:
            '継続的なモニタリングプログラムおよびセキュリティ管理策評価への影響に関する情報を共有する方法について、外部監査グループとプロセスを策定する。',
          softSkill: false,
        },
        {
          id: 'T0991',
          value: '自動制御評価に使用する報告要件を特定し、継続的な監視をサポートする。',
          softSkill: false,
        },
        {
          id: 'T0992',
          value: '継続的な監視の結果が進行中の承認プロセスにどのように使用されるかを決定する。',
          softSkill: false,
        },
        {
          id: 'T0993',
          value: '継続的な監視ツールと技術アクセス制御プロセスおよび手順を確立する。',
          softSkill: false,
        },
        {
          id: 'T0994',
          value: '継続的な監視ツールおよびテクノロジーのアクセス管理プロセスおよび手順を確立する。',
          softSkill: false,
        },
        {
          id: 'T0995',
          value: '継続的な監視の緩和策に技術的な支援を提供するプロセスを確立する。',
          softSkill: false,
        },
        {
          id: 'T0996',
          value: 'さまざまなユーザー間で連続的な監視報告要件を調整する。',
          softSkill: false,
        },
        {
          id: 'T0997',
          value: '各継続的監視ツールまたは技術の導入をサポートする責任を明確にする。',
          softSkill: false,
        },
        {
          id: 'T0998',
          value:
            '継続的監視をサポートするために、採点および測定基準作業グループとの連携を確立する。',
          softSkill: false,
        },
        {
          id: 'T0999',
          value:
            '継続的監視をサポートするために、新たなリスクの導入を管理するプロセスを確立し、運用する。',
          softSkill: false,
        },
        {
          id: 'T1000',
          value: '継続的監視の設定に関する問題および調整のサブグループを確立する。',
          softSkill: false,
        },
        {
          id: 'T1001',
          value: '継続的な監視ツールおよびテクノロジーのパフォーマンス測定や管理要件を確立する。',
          softSkill: false,
        },
        {
          id: 'T1002',
          value:
            'スコアおよびグレードを使用して、動機付けとパフォーマンス評価を行い、懸念事項に対処しながら継続的監視をサポートする。',
          softSkill: false,
        },
        {
          id: 'T1003',
          value:
            'セキュリティ管理者(すなわち、システム所有者、情報システムセキュリティ管理者、情報システムセキュリティ責任者など)と協力し、システムレベルでの継続的な監視のための適切な報告要件を確立する。',
          softSkill: false,
        },
        {
          id: 'T1004',
          value: '継続的な監視ツールを使用して、リスクを継続的に評価する。',
          softSkill: false,
        },
        {
          id: 'T1005',
          value:
            '継続的な監視データを利用して、継続的な問題に対処するための情報セキュリティ投資の決定を行う。',
          softSkill: false,
        },
        {
          id: 'T1006',
          value: '継続的監視中に検出された問題に対応し、エスカレーションを行い、対応を調整する。',
          softSkill: false,
        },
        {
          id: 'T1007',
          value: '継続的監視プログラムからのレビュー結果を検証し、リスクを適時に軽減する。',
          softSkill: false,
        },
      ],
    },
    {
      name: 'knowledge',
      items: [
        {
          id: 'K0001',
          value:
            'コンピュータネットワーキングの概念とプロトコル及びネットワークセキュリティの方法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0002',
          value: 'リスク管理プロセスの知識（例:リスクの評価と緩和のための方法）。',
          softSkill: true,
        },
        {
          id: 'K0003',
          value: 'サイバーセキュリティとプライバシーに関する法律、規制、政策及び倫理に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0004',
          value: 'サイバーセキュリティとプライバシーの原則に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0005',
          value: 'サイバー環境の脅威と脆弱性に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0006',
          value: 'サイバーセキュリティの喪失による特定の運用上の影響に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0007',
          value: '認証、承認及びアクセス制御手法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0008',
          value: '顧客組織の適用可能なビジネスプロセスと運用に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0009',
          value: 'アプリケーションの脆弱性に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0010',
          value: 'ネットワークインフラをサポートする通信手法、原理及びコンセプトに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0011',
          value:
            'ルータ、スイッチ、ブリッジ、サーバ、伝送媒体及び関連ハードウェアを含むネットワーク設備の能力とアプリケーションに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0012',
          value: '容量と要件に関する分析に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0013',
          value: 'サイバー防衛と脆弱性評価ツール及びその能力に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0014',
          value: '複雑なデータ構造に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0015',
          value: '計算機アルゴリズムに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0016',
          value: 'コンピュータプログラミングの原則に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0017',
          value: 'デジタルフォレンジックスに関するデータを扱う際の概念とプラクティスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0018',
          value: '暗号化アルゴリズムに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0019',
          value: '暗号と暗号鍵管理の概念に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0020',
          value: 'データ管理とデータ標準化のポリシーに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0021',
          value: 'データのバックアップと復元に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0022',
          value: 'データマイニングとデータウェアハウスの原理に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0023',
          value: 'データベース管理システム、問い合わせ言語、テーブル関係及びビューに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0024',
          value: 'データベースシステムに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0025',
          value: 'ディジタル著作権管理に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0026',
          value: '業務継続性と運用計画の災害復旧継続性に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0027',
          value: '組織の企業情報セキュリティアーキテクチャに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0028',
          value: '組織の評価と検証に関する要件に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0029',
          value: '組織のLAN/WANの接続に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0030',
          value:
            'コンピュータアーキテクチャ（例：回路基板、プロセッサ、チップ及びコンピュータハードウェア）に適用される電気工学に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0031',
          value: '企業内メッセージングシステムと関連するソフトウェアに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0032',
          value: 'レジリエンスと冗長性に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0033',
          value:
            'ホスト/ネットワークのアクセス制御メカニズム（アクセス制御リスト、機能リストなど）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0034',
          value:
            'ネットワーク通信を提供するネットワークサービスとプロトコルの相互作用に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0035',
          value: 'システムコンポーネントのインストール、統合、最適化に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0036',
          value: 'マンマシンインタラクションの原理に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0037',
          value: 'セキュリティアセスメントと認証プロセスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0038',
          value:
            '情報やデータの使用、処理、保管、送信に関連するリスクを管理するために使用されるサイバーセキュリティとプライバシーの原則に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0039',
          value:
            'ソフトウェア開発に適用されるサイバーセキュリティとプライバシーの原則と方法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0040',
          value: '脆弱性情報の発信源（例：アラート、アドバイザリ、正誤表、報告書）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0041',
          value:
            'インシデントのカテゴリ、インシデントレスポンス及び応答のタイムラインに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0042',
          value: 'インシデントレスポンスとハンドリングの方法論に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0043',
          value: '業界標準かつ組織的に受け入れられる解析原理と手法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0044',
          value:
            '（機密性、完全性、可用性、認証、否認防止に関連する）サイバーセキュリティとプライバシーの原則と組織の要件に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0045',
          value: '情報セキュリティシステム工学の原則に関する知識（NIST SP 800-160）。',
          softSkill: false,
        },
        {
          id: 'K0046',
          value:
            '侵入検知手法とホスト及びネットワークベースの侵入を検出するための技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0047',
          value: '情報技術（IT）アーキテクチャの概念とフレームワークに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0048',
          value: 'リスクマネジメントフレームワークの要件に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0049',
          value:
            'ＩＴセキュリティの原理と手法（例：ファイアウォール、非武装地域、暗号化）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0050',
          value: 'LANとWANの原理と（帯域管理を含む）概念に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0051',
          value: '低レベル言語（例：アセンブラ言語）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0052',
          value: '数学に関する知識（例：対数、三角法、線形代数、微積分、統計、操作解析）。',
          softSkill: false,
        },
        {
          id: 'K0053',
          value: 'システムのパフォーマンスと可用性の測定方法や指標に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0054',
          value:
            '標準に基づいた概念と機能を活用したIT（情報技術）セキュリティ評価、監視、検出、修復ツールと手順の評価、実装、普及のための現在の産業界における手法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0055',
          value: 'マイクロプロセッサに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0056',
          value:
            'ネットワークアクセス、識別及びアクセス管理（例：PKI、Oauth、OpenID、SAML、SPML）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0057',
          value: 'ネットワークハードウェアデバイスと機能に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0058',
          value: 'ネットワークトラフィック解析手法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0059',
          value: '新興の情報技術とサイバーセキュリティ技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0060',
          value: 'オペレーティングシステムに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0061',
          value:
            'ネットワーク上でトラフィックがどのように流れるか（例： TCP/IP、OSI、ITIL現行版）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0062',
          value: 'パケットレベル解析に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0063',
          value: '並列及び分散コンピューティングのコンセプトに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0064',
          value: 'パフォーマンスチューニングツールと技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0065',
          value: 'ポリシーベースとリスクに適応できるアクセス制御に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0066',
          value: 'プライバシー影響評価に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0067',
          value: 'プロセス工学のコンセプトに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0068',
          value: 'プログラミング言語の構造とロジックに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0069',
          value: 'SQLなどの問合せ言語に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0070',
          value:
            'システムとアプリケーションのセキュリティ上の脅威と脆弱性に関する知識（例：バッファオーバーフロー、モバイルコード、クロスサイトスクリプティング、PL/SQL及びインジェクション、競合状態、隠れチャネル、リプレイ、リターン指向型攻撃、悪質なコード）。',
          softSkill: false,
        },
        {
          id: 'K0071',
          value: 'リモートアクセス技術のコンセプトに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0072',
          value: 'リソースマネジメントの原理と技法に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0073',
          value:
            'セキュアな構成管理技術に関する知識 （例：セキュリティテクニカル実装ガイド（STIG）、シスコセキュリティに関するサイバーセキュリティのベストプラクティス）。',
          softSkill: false,
        },
        {
          id: 'K0074',
          value: 'セキュリティ管理における重要な概念に関する知識（例：リリース管理、パッチ管理）。',
          softSkill: false,
        },
        {
          id: 'K0075',
          value: 'セキュリティシステムの設計ツール、手法及び技法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0076',
          value: 'サーバ管理とシステム工学の理論、コンセプト及び手法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0077',
          value: 'サーバとクライアントのOSに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0078',
          value: 'サーバ診断ツールと障害識別技法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0079',
          value: 'ソフトウェアのデバッグ原理に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0080',
          value: 'ソフトウェア設計ツール、手法及び技法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0081',
          value:
            'ソフトウェア開発手法（例： ウォータフォールモデル、スパイラルモデル）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0082',
          value: 'ソフトウェア工学に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0083',
          value: '組織のデータ資産のソース、特徴及び利用に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0084',
          value: '構造解析の原理と手法に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0086',
          value:
            'システム設計ツール、手法及び技術（自動システム分析及び設計ツールを含む）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0087',
          value:
            'システム設計に関係するシステムソフトウェアならびに組織設計標準、ポリシーおよび承認されたアプローチ（例： ISOガイドライン）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0088',
          value: 'システム管理のコンセプトに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0089',
          value: 'システム診断ツールと障害識別技法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0090',
          value:
            'ソフトウェアセキュリティとユーザビリティを含むシステムライフサイクル管理の原理に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0091',
          value: 'システムの検査と評価の手法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0092',
          value: '技術の統合プロセスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0093',
          value:
            '通信の概念に関する知識（例：通信チャネル、システムリンクバジェット、スペクトル効率、多重化）。',
          softSkill: false,
        },
        {
          id: 'K0094',
          value:
            'コンテンツ作成技術に関連する機能に関する知識（例：Wiki、ソーシャルネットワーキング、コンテンツ管理システム、ブログ）。',
          softSkill: false,
        },
        {
          id: 'K0095',
          value:
            '情報の組織化と管理のための多様な技術に関する能力と機能（例： データベース、ブックマークエンジン）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0096',
          value:
            '多様な協働技術に関する能力と機能（例： グループウェア、SharepPoint）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0097',
          value: '物理的及び仮想的なデータストレージメディアの特徴に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0098',
          value:
            '自組織内部の構造とプロセスについて報告するサイバー防衛サービスの提供者に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0100',
          value: 'エンタープライズITアーキテクチャに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0101',
          value: '組織のエンタープライズITのゴールと目的に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0102',
          value: 'システムエンジニアリングプロセスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0103',
          value: '日常のハードウェア保守の種類と頻度に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0104',
          value: 'VPNセキュリティに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0105',
          value:
            'ウェブサービスに関する知識（例：サービス指向アーキテクチャー、SOAP、ウェブサービス記述言語）。',
          softSkill: false,
        },
        {
          id: 'K0106',
          value:
            'ネットワーク攻撃及びネットワーク攻撃に関する脅威と脆弱性の関連性を構成するものに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0107',
          value: '内部不正の検出、報告、検出ツール及び法規制に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0108',
          value:
            '通信メディアの基本概念、用語及び幅広い範囲での運用に関する知識（コンピュータと電話のネットワーク、衛星、ファイバ、無線）。',
          softSkill: false,
        },
        {
          id: 'K0109',
          value:
            '多様な構成要素と周辺機器の機能を含む、物理的なコンピュータの構成要素とアーキテクチャに関する知識（例：CPU、ネットワークインターフェースカード、データストレージ）の機能を含む、物理的なコンピュータコンポーネントとアーキテクチャに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0110',
          value: '敵対的な戦術、技法及び手順に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0111',
          value: 'ネットワークツールに関する知識（例：ping、traceroute、nslookup）。',
          softSkill: false,
        },
        {
          id: 'K0112',
          value: '深層防御の原理とネットワークセキュリティアーキテクチャに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0113',
          value:
            'さまざまな種類のネットワーク通信に関する知識（例：LAN、WAN、MAN、W​​LAN、WWAN）。',
          softSkill: false,
        },
        {
          id: 'K0114',
          value:
            '電子デバイスに関する知識（例：コンピュータシステム/コンポーネント、アクセス制御デバイス、デジタルカメラ、デジタルスキャナ、電子オーガナイザ、ハードドライブ、メモリカード、モデム、ネットワークコンポーネント、ネットワークアプライアンス、ネットワークホームコントロールデバイス、プリンタ、リムーバブルストレージデバイス、電話機、複写機、ファクシミリなど）。',
          softSkill: false,
        },
        {
          id: 'K0115',
          value: '悪用される可能性のある技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0116',
          value: 'ファイル拡張子（例： dll, .bat, .zip, .pcap, .gzip）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0117',
          value: 'ファイルシステムの実装（例： NTFS、FAT、EXT）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0118',
          value: 'デジタルエビデンスの押収と保存のプロセスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0119',
          value: 'ハッキング手法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0120',
          value:
            '情報へのニーズと収集要件が、拡張した企業活動の全体にわたってどのように変換され、追跡され、優先順位付けされるかに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0121',
          value: '情報セキュリティプログラム管理とプロジェクト管理の原理と技法に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0122',
          value: 'ハードウェア、OS及びネットワーク技術を調査することの意味に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0123',
          value: '証拠としての有効性についての法的ガバナンスに関する知識（例： 証拠規則）。',
          softSkill: true,
        },
        {
          id: 'K0124',
          value:
            '複数の認知領域についての知識と、それぞれの領域を学習するための教育ツールと手法に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0125',
          value:
            '「Chain of custody」を維持したまま、電子的な証拠を収集し、パッケージングし、移送し、そして保管するためのプロセスに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0126',
          value: 'サプライチェーンリスク管理プラクティスに関する知識（NIST SP 800-161）。',
          softSkill: true,
        },
        {
          id: 'K0127',
          value: '関連する情報構造の状態と機能に関する知識（例：国家情報基盤）。',
          softSkill: true,
        },
        {
          id: 'K0128',
          value: '不揮発のデータの種類と収集に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0129',
          value: 'コマンドラインツールに関する知識（例：mkdir、mv、ls、passwd、grep）。',
          softSkill: false,
        },
        {
          id: 'K0130',
          value: '仮想化技術と仮想マシンの開発と保守に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0131',
          value: 'ウェブメールの収集、検索／分析技術、ツール及びCookieに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0132',
          value:
            '関連する情報を含むシステムファイル（例： ログファイル、レジストリファイル、設定ファイル）が何であり、そのシステムファイルはどこにあるかに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0133',
          value: 'デジタルフォレンジクスに関するデータの種類とその認識方法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0134',
          value: 'デジタルフォレンジクスの展開に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0135',
          value: 'ウェブフィルタリング技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0136',
          value:
            'さまざまな電子通信システムと方法の機能に関する知識（例：電子メール、VOIP、IM、ウェブフォーラム、ダイレクトビデオブロードキャスト）。',
          softSkill: false,
        },
        {
          id: 'K0137',
          value: '既存のネットワークの範囲に関する知識（例：PBX、LAN、WAN、WIFI、SCADA）。',
          softSkill: false,
        },
        {
          id: 'K0138',
          value: 'Wi-Fiに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0139',
          value: '計算機言語のインタープリタとコンパイルに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0140',
          value: 'セキュアコーディング技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0141',
          value: '撤回 - K0420に統合',
          softSkill: true,
        },
        {
          id: 'K0142',
          value: '収集管理のプロセス、性能、限界に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0143',
          value:
            'トラフィックの収集、フィルタリング及び選択を含むフロントエンドの収集システムに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0144',
          value: 'グローバル環境におけるコンピュータ攻撃者のソーシャルダイナミクスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0145',
          value: 'セキュリティイベント（事象）の相関ツールに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0146',
          value: '組織のコアビジネスとミッションの原理に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0147',
          value: '新たに出現したセキュリティ問題、リスク及び脆弱性に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0148',
          value: 'サプライチェーンリスクの軽減のための輸出入管理規制と責任機関に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0149',
          value: '組織のリスク受容および/またはリスク管理のアプローチに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0150',
          value: '企業のインシデントレスポンスプログラム、役割及び責任に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0151',
          value: '現在及び新興の脅威/攻撃手法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0152',
          value:
            'ソフトウェア関連の情報技術のセキュリティに関する原理と手法（例： モジュール化、階層化、抽象化、データ隠蔽、単純/最小化）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0153',
          value: 'ソフトウェアの品質保証プロセスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0154',
          value: 'サプライチェーンリスクマネジメントの標準、プロセス及びプラクティスに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0155',
          value: '電子証拠法に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0156',
          value: '証拠と裁判手続の法規則に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0157',
          value: 'サイバー防衛及び情報セキュリティのポリシー、手続き及び規制に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0158',
          value:
            '組織上の情報技術ユーザのセキュリティポリシー（例： アカウント作成、パスワードルール、アクセス制御）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0159',
          value: 'Voice over IP（VoIP）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0160',
          value: 'ネットワーク層における一般的な攻撃手法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0161',
          value: '攻撃クラスの相違（例： 受動的、能動的、内部、近接、分散）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0162',
          value:
            'サイバー攻撃者に関する知識（例：スクリプトキディー、内部脅威、非政府機関による支援、政府機関による支援）。',
          softSkill: false,
        },
        {
          id: 'K0163',
          value: '重要ITの調達要件に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0164',
          value:
            '機能性、品質及びセキュリティ上の要求事項とこれらをどのように個別の供給品に適用するか（すなわち要素とプロセス）に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0165',
          value: 'リスク／脅威の評価に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0167',
          value:
            'システム管理、ネットワーク及びオペレーティングシステムのハードニング技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0168',
          value:
            '適用法令、制定法（例：米国法典のタイトル10,18,32,50）、大統領令、行政機関のガイドライン、行政/刑事法のガイドラインおよび手続きに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0169',
          value:
            '情報技術（IT）サプライチェーンのセキュリティとサプライチェーンのリスク管理ポリシー、要件及び手続きに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0170',
          value:
            'システムセキュリティを考慮することなく設計された情報通信技術を備えた重要なインフラストラクチャシステムに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0171',
          value: 'ハードウェアのリバースエンジニアリング技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0172',
          value:
            'ミドルウェアに関する知識（例：エンタープライズサービスバス、メッセージキューイング）。',
          softSkill: false,
        },
        {
          id: 'K0174',
          value: 'ネットワークプロトコルに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0175',
          value: 'ソフトウェアのリバースエンジニアリング技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0176',
          value: 'XMLスキーマに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0177',
          value:
            'サイバー攻撃のステージに関する知識（例：偵察、スキャン、列挙、アクセスの獲得、特権の昇格、アクセスの維持、ネットワークの悪用、トラックのカバー）。',
          softSkill: false,
        },
        {
          id: 'K0178',
          value: 'セキュアなソフトウェア開発の手法、ツール及び実践に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0179',
          value:
            'トポロジー、プロトコル、構成要素及び原理を含むネットワークセキュリティアーキテクチャのコンセプト（例：深層防御のアプリケーション）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0180',
          value:
            'ネットワークシステム管理の原理、モデル、手法（例： エンドツーエンドのシステムパフォーマンスモニタリング）およびツールに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0182',
          value: 'データカービングツールと技術（例：Foremost）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0183',
          value: 'リバースエンジニアリングのコンセプトに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0184',
          value: 'アンチフォレンジクスの戦術、技術および手続きに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0185',
          value:
            'フォレンジック施設の設計構成とサポートアプリケーションに関する知識（例：VMWare、Wireshark）。',
          softSkill: false,
        },
        {
          id: 'K0186',
          value: 'デバッグ手続きとツールに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0187',
          value: '異常な振る舞いを意図した攻撃者によるファイルタイプの悪用に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0188',
          value: 'マルウェア解析ツール（例： Olly Debug, Ida Pro）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0189',
          value:
            '仮想マシンを検出するマルウェアに関する知識（例：仮想認識マルウェア、デバッガ認識マルウェア、コンピュータのディスプレイデバイス内のVM関連文字列を探すアンパックマルウェア）。',
          softSkill: false,
        },
        {
          id: 'K0190',
          value: '暗号化手法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0191',
          value: 'ウイルス、マルウェア及び攻撃に対するシグネチャ実装の影響に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0192',
          value: 'Windows/Unixのポートとサービスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0193',
          value: 'データベースにおける高度なデータ修復セキュリティ機能に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0194',
          value:
            'クラウドベースのナレッジマネジメント技術とセキュリティ、ガバナンス、調達及び管理に関連する概念に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0195',
          value: '感度やその他のリスク要因に基づくデータ分類基準と方法論に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0196',
          value: '暗号及びその他のセキュリティ技術に関連する輸入/輸出規制に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0197',
          value:
            'データベースにアクセスするアプリケーションプログラミングのインタフェースに関する知識（例：Java Database Connectivity（JDBC））。',
          softSkill: false,
        },
        {
          id: 'K0198',
          value:
            '組織のプロセス改善の概念とプロセス成熟度モデルに関する知識（例：開発のための能力成熟度モデル統合（CMMI）、サービスのためのCMMI及び取得のためのCMMI）。',
          softSkill: true,
        },
        {
          id: 'K0199',
          value:
            'セキュリティアーキテクチャの概念とエンタープライズアーキテクチャの参照モデルに関する知識（例：Zachman、Federal Enterprise Architecture [FEA]）。',
          softSkill: false,
        },
        {
          id: 'K0200',
          value:
            'ネットワークおよび関連標準のためのサービス管理の概念に関する知識（例：ITILの現行バージョン）。',
          softSkill: false,
        },
        {
          id: 'K0201',
          value: '対称鍵のローテーションについての技術と概念に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0202',
          value:
            'アプリケーションファイアウォールの概念と機能に関する知識（例：単一認証ポイント/監査/ポリシー実施、悪意のあるコンテンツのメッセージスキャン、PCIおよびPII準拠のデータ匿名化、データ損失保護スキャン、暗号化処理の高速化、SSLセキュリティ、REST / JSON処理）。',
          softSkill: false,
        },
        {
          id: 'K0203',
          value:
            'セキュリティモデルに関する知識（例：Bell-LaPadulaモデル、Biba完全性モデル、Clark-Wilson完全性モデル）。',
          softSkill: false,
        },
        {
          id: 'K0204',
          value: '学習評価技術に関する知識（ルービック、評価計画、テスト、クイズ）。',
          softSkill: true,
        },
        {
          id: 'K0205',
          value: '基本的なシステム、ネットワーク及びOSのハードニング技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0206',
          value: '倫理的なハッキングの原理と技法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0207',
          value: '回路解析に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0208',
          value: 'コンピュータベースのトレーニングとeラーニングサービスに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0209',
          value: 'コバート通信技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0210',
          value: 'データのバックアップと復元の概念に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0211',
          value: '機密性、完全性及び可用性の要求事項に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0212',
          value: 'サイバーセキュリティ対応ソフトウェア製品に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0213',
          value:
            'インストラクショナルデザインと評価モデルに関する知識（例：ADDIE、Smith / Raganモデル、Gagneのインストラクションに関するイベント、Kirkpatrickの評価モデル）。',
          softSkill: true,
        },
        {
          id: 'K0214',
          value: 'リスク管理フレームワークの評価手法に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0215',
          value: '組織のトレーニングポリシーに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0216',
          value: '学習レベル（すなわち、ブルームの学習分類）に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0217',
          value: '学習管理システムならびに学習管理におけるその利用に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0218',
          value: '学習スタイル（例：同化、聴覚、運動感覚）に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0220',
          value: '学習のモードに関する知識（例：丸暗記、観察）。',
          softSkill: true,
        },
        {
          id: 'K0221',
          value: 'OSIモデルと基礎となるネットワークプロトコル（例：TCP / IP）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0222',
          value: 'サイバー防衛活動についての関連法、法的権限、制限及び規制に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0223',
          value: '撤回 - K0073に統合',
          softSkill: true,
        },
        {
          id: 'K0224',
          value:
            'Unix / Linux、IOS、Android、Windowsオペレーティングシステムなどの（ただしそれに限らない）オペレーティングシステムのシステム管理の概念に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0226',
          value: '組織のトレーニングシステムに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0227',
          value: 'さまざまなタイプのコンピュータアーキテクチャに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0228',
          value: 'タクソノミとセマンティックオントロジ理論に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0229',
          value:
            'エラー、例外、アプリケーションの障害を記録できるアプリケーションとロギングに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0230',
          value:
            'クラウドサービスモデルとそのモデルがインシデント対応をどのように制限するかに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0231',
          value: '危機管理のプロトコル、プロセス及び技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0233',
          value:
            '政府のサイバーセキュリティ人材フレームワーク、仕事上のロール、関連するタスク、知識、スキル、能力に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0234',
          value: '全範囲にわたるサイバー能力（例：防衛、攻撃、エクスプロイト）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0235',
          value:
            '研究開発センター、シンクタンク、学術研究機関、産業システムを活用する方法に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0236',
          value: 'Hadoop、Java、Python、SQL、Hive、Pigを利用してデータを探索する方法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0237',
          value: 'サービスデスクの業界におけるベストプラクティスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0238',
          value: '機械学習の理論と原理に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0239',
          value:
            '書面、口頭及び視覚メディアを介して通知する代替方法を含む、メディア制作、コミュニケーション及び普及の手法および方法に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0240',
          value: 'マルチレベルのセキュリティシステムとクロスドメインソリューションに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0241',
          value: '組織の人事方針、プロセス及び手続きに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0242',
          value: '組織のセキュリティポリシーに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0243',
          value: '組織のトレーニング、教育についてのポリシー、プロセス、手続きに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0244',
          value: '疑わしい活動や異常な活動を示す可能性のある身体的および生理的行動に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0245',
          value: 'トレーニングと教育のニーズを評価するための原則とプロセスに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0246',
          value: '関連する概念、手順、ソフトウェア、機器、技術アプリケーションに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0247',
          value: '顧客サポートに関連するリモートアクセスプロセス、ツール及び機能に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0248',
          value: '戦略的な理論と実践に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0249',
          value: '持続可能な技術、プロセス、戦略に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0250',
          value: '学習者のためのテスト及び評価プロセスに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0251',
          value: '事実と証拠の提示を含む、司法プロセスに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0252',
          value:
            'カリキュラムデザイン、個人やグループの教育、指導、ならびに訓練と教育の効果についての測定のための、トレーニングと教育の原則と方法に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0253',
          value: '撤回 - K0227に統合',
          softSkill: true,
        },
        {
          id: 'K0254',
          value: 'バイナリ分析に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0255',
          value:
            'トポロジ、プロトコル及びコンポーネントを含むネットワークアーキテクチャの概念に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0257',
          value: '情報技術（IT）の取得/調達要件に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0258',
          value: 'テスト手順、原則、方法論に関する知識（例：CMMI）。',
          softSkill: true,
        },
        {
          id: 'K0259',
          value: 'マルウェア解析の概念と手法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0260',
          value: '個人識別情報（PII）データセキュリティ基準に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0261',
          value: 'PCI（Payment Card Industry）データセキュリティ基準に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0262',
          value: '個人健康情報（PHI）データセキュリティ基準に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0263',
          value: '情報技術（IT）のリスク管理についての方針、要件及び手順に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0264',
          value:
            'プログラムの保護計画（例：情報技術（IT）サプライチェーンのセキュリティ/リスク管理ポリシー、改ざん防止技術、要件）に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0265',
          value:
            '安全性、パフォーマンス及び信頼性の観点での情報技術（IT）をサポートするインフラストラクチャに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0266',
          value: 'サプライヤーおよび/または製品の信頼性を評価する方法に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0267',
          value:
            '重要なインフラストラクチャのサイバーセキュリティに関連する法律、ポリシー、手続きまたはガバナンスに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0268',
          value: 'デジタルフォレンジクスにおけるフットプリントの識別に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0269',
          value: '移動体通信アーキテクチャに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0270',
          value: '取得/調達におけるライフサイクルプロセスに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0271',
          value:
            'OSの構造と内部に関する知識（例：プロセス管理、ディレクトリ構造、インストールされたアプリケーション）。',
          softSkill: false,
        },
        {
          id: 'K0272',
          value:
            'ソフトウェアの通信上の脆弱性を識別するために使用されるネットワーク解析ツールに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0274',
          value:
            '伝送レコード（例：Bluetooth、RFID、赤外線ネットワーキング（IR）、Wi-Fi、ページング、携帯電話、衛星放送、VoIP）や、望ましくない情報の送信を可能にしたり、インストールされたシステムが正しく動作するのを防ぐジャミング技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0275',
          value: '構成管理技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0276',
          value: 'セキュリティマネジメントに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0277',
          value:
            'データベースにおける現在および最新のデータ暗号化（例：列と表領域の暗号化、ファイルとディスクの暗号化など）によるセキュリティ機能（例：組込みの暗号鍵管理機能）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0278',
          value: 'データベースにおける現在及び最新のデータ修復セキュリティ機能に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0280',
          value: 'システム工学の理論、概念、方法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0281',
          value: '情報技術（IT）サービスカタログに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0282',
          value: '撤回 - K0200に統合',
          softSkill: true,
        },
        {
          id: 'K0283',
          value:
            'プラットフォーム間でのコラボレーションとコンテンツ同期についてのユースケースに関する知識（例：モバイル、PC、クラウドなど）。',
          softSkill: false,
        },
        {
          id: 'K0284',
          value: 'ユーザーのクレデンシャル情報管理システムの開発と適用に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0285',
          value:
            '保存されているデータの暗号化をサポートするための、企業におけるキーエスクロシステムの実装に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0286',
          value:
            'N階層型に関する知識（例：サーバーとクライアントのオペレーティングシステムを含む）。',
          softSkill: false,
        },
        {
          id: 'K0287',
          value: '組織の情報分類プログラムと情報漏洩の流れに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0288',
          value: '業界標準のセキュリティモデルに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0289',
          value: 'システム/サーバの診断ツールと障害識別技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0290',
          value: 'システムのセキュリティテストと評価方法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0291',
          value:
            'エンタープライズ情報技術（IT）のアーキテクチャの概念とパターン（例：ベースライン、検証済み設計、ターゲットアーキテクチャ）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0292',
          value: 'インシデント、問題及びイベント管理のための操作とプロセスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0293',
          value: '組織の目標と目的のアーキテクチャへの統合に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0294',
          value:
            '機器の正常な動作を維持するために必要なITシステムの運用、保守及びセキュリティに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0295',
          value: '機密性、完全性、可用性の原則についての知識。',
          softSkill: false,
        },
        {
          id: 'K0296',
          value:
            'ハブ、ルータ、スイッチ、ブリッジ、サーバ、伝送媒体及び関連ハードウェアを含むネットワーク機器の能力、アプリケーション及び潜在的な脆弱性に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0297',
          value: '特定されたセキュリティリスクに対する対策の設計に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0298',
          value: '特定されたセキュリティリスクに対する対策に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0299',
          value:
            'セキュリティシステムがどのように機能すべきか（そのレジリエンスと信頼性についての機能を含む）、及び条件、運用、環境の変化がこれらの結果にどのような影響を与えるかに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0300',
          value: 'ネットワークのマッピングとネットワークトポロジの再生成に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0301',
          value: '適切なツール（例：Wireshark、tcpdump）を使用したパケットレベル分析に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0302',
          value: 'コンピュータの基本的な操作に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0303',
          value: 'サブネットツールの使用に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0304',
          value: 'デジタルフォレンジクス用データの処理についての概念とプラクティスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0305',
          value: 'データ秘匿（例：暗号化アルゴリズム、ステガノグラフィ）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0308',
          value: '暗号に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0309',
          value: 'エクスプロイトの可能性がある新興技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0310',
          value: 'ハッキング手法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0311',
          value: '技術トレンドを識別するために有用な業界の指標に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0312',
          value: '法的権限や制限を含む情報収集の原則、方針、手続きに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0313',
          value:
            'サイバーセキュリティにフォーカスした外部組織及び学術機関に関する知識（例：サイバーセキュリティのカリキュラム/トレーニング、研究開発）。',
          softSkill: true,
        },
        {
          id: 'K0314',
          value: '業界の技術における潜在的なサイバーセキュリティ脆弱性に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0315',
          value:
            '情報収集ならびに情報を生成、報告及び共有するための主要な方法、手順及び技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0316',
          value:
            'ビジネスまたは軍事上の作戦計画、概念的な運用計画、命令、方針及び交戦時の立脚規定に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0317',
          value:
            '報告されたインシデント、問題及びイベントの文書化ならびに照会に使用される手続きに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0318',
          value: 'オペレーティングシステムのコマンドラインツールに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0319',
          value: 'テクニカルデリバリーの機能とその限界に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0320',
          value: '組織の評価基準及び検証基準に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0321',
          value:
            'コンピュータアーキテクチャおよび関連するコンピュータハードウェア/ソフトウェアに適用される工学的概念に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0322',
          value: '組込システムに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0323',
          value: 'システムフォールトトレランス手法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0324',
          value: 'IDS/IPSツールとアプリケーションに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0325',
          value:
            '情報理論に関する知識（例：ソースコーディング、チャネルコーディング、アルゴリズム複雑性理論、データ圧縮）。',
          softSkill: false,
        },
        {
          id: 'K0326',
          value: '非武装地帯（DMZ）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0330',
          value:
            'あまり一般的ではなく、より複雑なシステムの問題への解決策を識別するために有効な能力に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0332',
          value:
            'TCP/IP、動的ホスト構成、DNS、ディレクトリサービスなどのネットワークプロトコルに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0333',
          value:
            'セキュリティ目標、運用目標及びトレードオフへの理解を含む、ネットワーク設計プロセスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0334',
          value: 'ネットワークトラフィック分析（ツール、方法論、プロセス）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0335',
          value: '現在及び新興のサイバーセキュリティ技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0336',
          value: 'アクセス時の認証手法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0337',
          value: '撤回 - K0007に統合',
          softSkill: true,
        },
        {
          id: 'K0338',
          value: 'データマイニング技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0339',
          value: 'ネットワーク分析ツールを使用して脆弱性を識別する方法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0341',
          value:
            'サイバーセキュリティに関連する海外へのディスクロージャーポリシーと輸出入規制に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0342',
          value: 'ペネトレーションテストの原理、ツール及び技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0343',
          value: '根本的な原因の分析技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0344',
          value: '組織の脅威環境に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0346',
          value: 'システムコンポーネントの統合に関する原理と手法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0347',
          value: '運用設計に関する知識と理解。',
          softSkill: false,
        },
        {
          id: 'K0349',
          value: 'ウェブサイトの種類、管理、機能及びコンテンツ管理システム（CMS）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0350',
          value: '承認された組織の計画体系に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0351',
          value:
            'サイバーセキュリティ上の標的とエクスプロイトを管理する適用法令、法律、規則、ポリシーに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0352',
          value:
            'インテリジェンスによるサポートのニーズ、トピック及びフォーカス領域の形態に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0353',
          value: '収集管理機関の変更に帰結する可能性のある状況に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0354',
          value: '関連する報告および普及の手続きに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0355',
          value: 'すべての情報源についての報告および普及の手続きに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0356',
          value: '言語、音声及び/またはグラフィック素材のための分析ツールと技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0357',
          value: '分析構造とそれらが運用環境を評価するために使用される方法の知識。',
          softSkill: false,
        },
        {
          id: 'K0358',
          value: '分析の基準とインテリジェンスの信頼レベルの目的に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0359',
          value: '承認されたインテリジェンスのの普及プロセスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0361',
          value: '資産の可用性、能力、限界に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0362',
          value: '攻撃方法と技術（DDoS、ブルートフォース、なりすましなど）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0363',
          value: '監査およびログ記録の手順（サーバーベースのログを含む）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0364',
          value: '収集作業の適切性を評価するのために利用可能なデータベースとツールに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0367',
          value: 'ペネトレーションテストに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0368',
          value:
            'サイバーセキュリティに関する情報収集および/または準備活動を可能にするインプラントに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0371',
          value:
            '収集展開プロセスの原則についての知識（例：ダイヤル番号認識、ソーシャルネットワーク分析）。',
          softSkill: false,
        },
        {
          id: 'K0372',
          value:
            'プログラミングの概念（例：レベル、構造、コンパイルとインタプリタ言語）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0373',
          value:
            '基本的なソフトウェアアプリケーション（例：データストレージやバックアップ、データベースアプリケーション）及びそれらのアプリケーションで検出された脆弱性の種類に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0375',
          value: '無線アプリケーションの脆弱性に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0376',
          value:
            '情報のニーズ、目的、構造、能力などを含む、内部および外部の顧客およびパートナー組織に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0377',
          value: '標準、ポリシー、手続きを形成する分類と管理策に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0379',
          value: '情報のニーズ、目的、構造、能力などを含むクライアント組織に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0380',
          value: 'コラボレーションツールと環境に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0381',
          value: '付随的損害と影響の評価に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0382',
          value: '収集の能力と限界に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0383',
          value:
            '収集計画を満たすために利用される収集活動の能力、アクセス、パフォーマンス仕様及び制約に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0384',
          value: '収集管理機能（例：位置、機能、責任、製品、報告要件）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0385',
          value: '撤回 - K0142に統合',
          softSkill: true,
        },
        {
          id: 'K0386',
          value: '収集管理ツールに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0387',
          value: '収集計画プロセスと収集計画に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0388',
          value:
            'チャット/バディリスト、新興の技術、VOIP、IP経由のメディア、VPN、VSAT /ワイヤレス、ウェブメール、クッキーのためのコレクション検索/分析技術及びツールに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0389',
          value: '従来の情報源と非従来の情報源を含む収集の情報源に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0390',
          value: '収集戦略に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0391',
          value: '収集システム、能力及びプロセスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0392',
          value:
            '一般的なコンピュータ/ネットワーク感染（ウイルス、トロイの木馬など）と感染方法（ポート、添付ファイルなど）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0393',
          value: '一般的なネットワークデバイスとその設定に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0394',
          value: '一般的なレポートデータベースとツールに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0395',
          value:
            'コンピュータネットワークの基礎に関する知識（ネットワークの基本的なコンピュータコンポーネント、ネットワークの種類など）。',
          softSkill: false,
        },
        {
          id: 'K0396',
          value:
            'コンピュータ言語、プログラミング、テスト、デバッグ、ファイルタイプを含む、コンピュータプログラミングの概念に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0397',
          value: 'オペレーティングシステム（例：Linux、Unix）のセキュリティ概念に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0398',
          value:
            'ウェブサイトに関連する概念（例：ウェブサーバ/ページ、ホスティング、DNS、レジストレーション、HTMLなどのウェブ言語）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0399',
          value: '危機発生時の行動計画と時間的制約の厳しい計画の手順に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0400',
          value: 'サイバー作戦行動における危機発生時の行動計画に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0401',
          value: '収集のための製品の評価基準に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0402',
          value:
            'サイバーセキュリティ領域に対するターゲット選定と適用性における重要性と脆弱性をとりまく要因（例：価値、回復、緩和、対策）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0403',
          value: 'サイバー作戦行動における暗号についての機能、制限及び寄与に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0404',
          value: '現状における収集の要件に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0405',
          value: '現状におけるコンピュータベースの侵入セットに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0406',
          value:
            '能動的防御とシステムのハードニングのための、現状におけるソフトウェアと方法論に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0407',
          value: '顧客情報のニーズに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0408',
          value:
            'サイバーセキュリティ行動（例：サイバー防衛、情報収集、環境準備、サイバー攻撃）の原則、能力、限界及び効果に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0409',
          value: 'サイバーインテリジェンス/情報収集能力とリポジトリに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0410',
          value: 'サイバー法とそのサイバーセキュリティ計画への影響に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0411',
          value: 'サイバー法及び法的考察ならびにそのサイバーセキュリティ計画への影響に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0412',
          value: 'サイバーセキュリティについての辞書/用語に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0413',
          value: 'サイバー作戦行動の目的、方針、合法性に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0414',
          value: 'サイバー作戦行動のサポートまたはプロセスの有効化に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0415',
          value: 'サイバーセキュリティ作戦行動の用語/辞書に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0416',
          value: 'サイバー作戦行動に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0417',
          value:
            'データ通信用語（例：ネットワークプロトコル、イーサネット、IP、暗号化、光デバイス、リムーバブルメディアなど）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0418',
          value: '端末または環境の情報収集のためのデータフロープロセスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0419',
          value: 'データベースの管理と保守に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0420',
          value: 'データベース理論に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0421',
          value: 'データベース、ポータル及び関連する宣伝手段に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0422',
          value: '解読プロセスと手続きに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0423',
          value: '外部組織とのやりとりを含む解読の報告に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0424',
          value: '拒否と詐欺の技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0425',
          value: '下位、側面、上位など、あらゆるレベルにわたる異なる組織目標に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0426',
          value: 'ダイナミックで意図的なターゲティングに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0427',
          value:
            '暗号化アルゴリズムとサイバーセキュリティ機能/ツール（例：SSL、PGP）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0428',
          value: '無線LAN用の暗号化アルゴリズムとツールに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0429',
          value: '企業全体の情報管理に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0430',
          value: '回避戦略とテクニックに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0431',
          value: '進化する/新興の通信技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0432',
          value:
            'サイバー作戦行動の戦略、ポリシー、組織に関連する、既存の、新興の、そして長期にわたる課題に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0433',
          value: 'OSの構造と運用のフォレンジック的影響に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0435',
          value: '基本的なサイバーセキュリティの概念、原則、制限及び効果に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0436',
          value:
            '基本的なサイバー作戦行動の概念、用語/辞書（すなわち、環境準備、サイバー攻撃、サイバー防衛）、原則、能力、限界及び効果に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0437',
          value: 'SCADAシステムコンポーネントに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0438',
          value:
            '携帯電話の通信アーキテクチャに関する知識（例：LTE、CDMA、GSM / EDGE、UMTS / HSPA）。',
          softSkill: false,
        },
        {
          id: 'K0439',
          value: 'ターゲットについての管理当局に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0440',
          value:
            'ホストベースのセキュリティ製品及びその製品がエクスプロイトに作用し、脆弱性を軽減するかに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0442',
          value:
            '統合された技術がサイバー作戦行動（例：デジタル、テレフォニー、無線）にどのように影響するかに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0443',
          value:
            'ネットワークの設計において、ハブ、スイッチ、ルーターがどのように連携して機能するかの知識。',
          softSkill: false,
        },
        {
          id: 'K0444',
          value:
            'インターネットアプリケーションの仕組みに関する知識（SMTPメール、Webベースの電子メール、チャットクライアント、VOIP）。',
          softSkill: false,
        },
        {
          id: 'K0445',
          value:
            '現代のデジタルおよびテレフォニーネットワークがサイバー作戦行動にどのように影響するかに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0446',
          value: '現代の無線通信システムがサイバー作戦行動にどのように影響するかに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0447',
          value:
            'メタデータ（例：電子メール、http）から関心対象の基本的な情報を収集し、表示し、識別する方法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0448',
          value: 'リソースの優先順位を確立する方法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0449',
          value: 'メタデータの抽出、分析及び使用方法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0450',
          value: '撤回 - K0036に統合',
          softSkill: true,
        },
        {
          id: 'K0451',
          value: '識別と報告プロセスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0452',
          value:
            'radius認証とロギング、DNS、メール、Webサービス、FTPサーバー、DHCP、ファイアウォール、SNMPを提供するUnixとWindowsシステムの実装に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0453',
          value: '指示と警告に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0454',
          value: '情報ニーズに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0455',
          value: '情報セキュリティの概念、技術と手法のファシリテーションに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0456',
          value: 'インテリジェンスの能力と限界に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0457',
          value: 'インテリジェンスの信頼レベルに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0458',
          value: 'インテリジェンス領域に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0459',
          value:
            'インテリジェンスの採用要件（すなわち、ロジスティックス、通信支援、機動性、法的規制など）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0460',
          value: '環境に関するインテリジェンスの準備と類似のプロセスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0461',
          value: 'インテリジェンスの生産プロセスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0462',
          value:
            'レポートの形式、報告可能性の基準（要件と優先度）、普及しているプラクティス、法的権限と規制を含む、インテリジェンスの報告についての原則、方針、手順及び媒体に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0463',
          value: 'インテリジェンスの要件についてのタスクの体系に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0464',
          value: '計画、実行、評価へのインテリジェンスによるサポートに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0465',
          value: '内外のパートナーによるサイバーセキュリティ運用の能力とツールに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0466',
          value:
            '内外のパートナーによるインテリジェンスのプロセスならびに情報に求められる要件と必須情報の策定に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0467',
          value:
            '内外のパートナー組織の能力と限界（タスク、収集、処理、エクスプロイト及び普及についての責任者）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0468',
          value: '内外のパートナーによる報告に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0469',
          value:
            '脅威の能力と行動を想定し、かつ/またはエミュレートするための内部の戦術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0470',
          value: 'インターネットとルーティングプロトコルに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0471',
          value:
            'インターネットネットワークアドレスに関する知識（IPアドレス、クラスレスインタードメインルーティング、TCP / UDPポート番号付け）。',
          softSkill: false,
        },
        {
          id: 'K0472',
          value: '侵入検知システムとシグネチャ開発に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0473',
          value: '侵入セットに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0474',
          value: '重要なサイバー脅威の主体とその能力に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0475',
          value: '運用環境と脅威における重要な要素に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0476',
          value: '言語処理ツールと技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0477',
          value: 'リーダーシップの意図と目的に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0478',
          value: 'ターゲット設定における法的な考慮事項に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0479',
          value: 'マルウェアの分析と特性に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0480',
          value: 'マルウェアに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0481',
          value:
            'さまざまなエクスプロイト活動を検出するために使用される方法やテクニックに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0482',
          value: '収集した情報資産の状況と可用性を確認する方法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0483',
          value: '潜在的な情報源からの情報を統合及び要約する方法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0484',
          value: '中間点での収集（プロセス、目的、組織、目標など）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0485',
          value: 'ネットワーク管理に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0486',
          value: 'ネットワーク構築とトポロジーに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0487',
          value:
            'ネットワークセキュリティに関する知識（例：暗号化、ファイアウォール、認証、ハニーポット、境界防衛）。',
          softSkill: false,
        },
        {
          id: 'K0488',
          value:
            '機能やネットワーク内での配置などを含む、ネットワークセキュリティの実装（例：ホストベースのIDS、IPS、アクセス制御リスト）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0489',
          value: 'ネットワークトポロジーに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0490',
          value: '撤回 - K0058に統合',
          softSkill: true,
        },
        {
          id: 'K0491',
          value:
            'ネットワークとインターネット通信に関する知識（すなわち、デバイス、デバイス構成、ハードウェア、ソフトウェア、アプリケーション、ポート/プロトコル、アドレッシング、ネットワークアーキテクチャとインフラストラクチャ、ルーティング、オペレーティングシステムなど）。',
          softSkill: false,
        },
        {
          id: 'K0492',
          value: '非伝統的な収集方法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0493',
          value: '難読化技術（例：TOR /オニオン/アノニマイザ、VPN / VPS、暗号化）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0494',
          value:
            '目的、状況、作戦環境、及び計画をサポートするために利用できる内外のパートナーによる収集能力の状況と意向に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0495',
          value: '進行中および将来の作戦に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0496',
          value: '作戦における資産的制約に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0497',
          value: '作戦上の効率の評価に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0498',
          value: '作戦上の計画プロセスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0499',
          value: '作戦上のセキュリティに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0500',
          value:
            '組織やパートナーの情報収集システム、機能、プロセス（例：収集やプロトコルプロセッサ）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0501',
          value: '組織におけるサイバー作戦行動プログラム、戦略及びリソースに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0502',
          value: '組織の意思決定支援ツールおよび/または方法に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0503',
          value:
            'リソースと資産準備状況報告について組織で定めた様式、その作戦行動上の関連性及びインテリジェンスの収集による影響に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0504',
          value:
            'サイバー作戦を統制する規制と政策上の指示のほか、サイバーセキュリティについての組織の問題、目的及び運用に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0505',
          value: '情報収集の管理における組織の目的と関連した要望に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0506',
          value: '組織の目標、リーダーシップの優先度、意思決定のリスクに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0507',
          value: 'デジタルネットワークの組織またはパートナーによる活用に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0508',
          value:
            '内部および/または外部組織とのパートナーシップのための組織の方針および計画についての概念に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0509',
          value: '目標達成に向けた組織やパートナーの権限、責任及び貢献に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0510',
          value: '組織及びパートナーのポリシー、ツール、機能及び手順に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0511',
          value: '組織の階層とサイバーセキュリティ上の意思決定プロセスに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0512',
          value: '組織の計画についての概念に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0513',
          value: '組織の優先順位、法的権限、要件提示プロセスに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0514',
          value: '組織の構造と関連するインテリジェンス能力に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0516',
          value:
            'ハブ、スイッチ、ルータ、ファイアウォールなどを含む物理的および論理的なネットワークデバイスおよびインフラストラクチャに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0517',
          value: '事後実装レビュー（PIR）の承認プロセスに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0518',
          value: '活動開始の計画に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0519',
          value:
            '適応性、危機管理のための行動、時間に配慮した計画などのタイムラインの計画に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0520',
          value:
            'ターゲットとなる知識、連携、コミュニケーションシステム、インフラストラクチャなど、ターゲットの開発に関連する原則とプラクティスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0521',
          value:
            '優先度についての情報、それがどのように導かれるか、それがどこで発せられるか、どのようにアクセスするのかなどに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0522',
          value: '製品のエクスプロイトと普及に関するニーズ及びアーキテクチャに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0523',
          value:
            '主要ベンダの製品と用語（例： セキュリティスイート： トレンドマイクロ、シマンテック、マカフィー、アウトポスト、パンダ）ならびにそれらの製品がどのようにエクスプロイトに作用し、脆弱性を軽減するかに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0524',
          value: '関連する法律、規制及びポリシーに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0525',
          value: 'サイバー作戦行動計画に関連する必要なインテリジェンス計画製品に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0526',
          value: '研究戦略と知識管理に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0527',
          value: 'リスク管理と緩和戦略に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0528',
          value: '衛星通信システムに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0529',
          value: 'スクリプティングの知識。',
          softSkill: false,
        },
        {
          id: 'K0530',
          value:
            'セキュリティハードウェアとソフトウェアのオプションについての知識（誘発されるネットワーク上の成果物とエクスプロイトによる影響を含む）。',
          softSkill: false,
        },
        {
          id: 'K0531',
          value: 'ソフトウェア構成のセキュリティへの影響に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0532',
          value: '専門用語に関する知識（例：略語、専門用語、技術用語、コードワード）。',
          softSkill: false,
        },
        {
          id: 'K0533',
          value: '特定のターゲットについての識別子とその使用法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0534',
          value: 'スタッフの管理、割り当て、割り当てプロセスに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0535',
          value: 'ターゲット研究のための戦略とツールに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0536',
          value:
            'エクスプロイトツール（例：スニファ、キーロガー）の構造、アプローチ及び戦略と技術（例：バックドアアクセスの取得、データの収集/抽出、ネットワーク内の他のシステムの脆弱性分析の実施）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0538',
          value: 'ターゲットと脅威についての組織の構造、重要な機能及び致命的な脆弱性に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0539',
          value:
            'ターゲット通信プロファイルとその主要要素（例：ターゲットの組織、活動、通信インフラ）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0540',
          value: 'ターゲットとなるコミュニケーションツールとテクニックに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0541',
          value: 'ターゲットとなる文化的な参考情報、方言、表現、イディオム、略語に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0542',
          value: 'ターゲット開発（すなわち、概念、役割、責任、製品など）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0543',
          value: 'ターゲットにおける推測される修理時間と回復時間に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0544',
          value: 'ターゲットのインテリジェンスの収集と作戦準備技術とライフサイクルに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0545',
          value: 'ターゲット言語に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0546',
          value: 'ターゲットリスト開発に関する知識（すなわち、制限付き、共同、候補者など）。',
          softSkill: false,
        },
        {
          id: 'K0547',
          value: 'ターゲットの方法と手順に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0548',
          value: 'ターゲットまたは脅威のサイバーセキュリティ上の行為者と手順に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0549',
          value: 'ターゲットの検査と検証手続きに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0550',
          value:
            '関連する現在の出来事、コミュニケーションのプロフィール、行為者、歴史（言語、文化）及び/または参照の枠組みを含むターゲットに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0551',
          value: 'ターゲティングサイクルに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0552',
          value: 'タスクの仕組みに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0553',
          value: 'オーガニックおよび下部の資産についてのタスク処理に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0554',
          value: 'タスク、収集、処理、エクスプロイト、普及に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0555',
          value: 'TCP/IPネットワーキングプロトコルに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0556',
          value: '通信の基礎に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0557',
          value: '端末または環境についての収集（プロセス、目的、組織、目標など）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0558',
          value:
            '収集要件および収集管理に関連して利用可能なツールおよびアプリケーションに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0559',
          value: '統合されたアプリケーションの基本的な構造、アーキテクチャ及び設計に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0560',
          value: '現代の通信ネットワークの基本的な構造、アーキテクチャ及び設計に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0561',
          value:
            'ネットワークセキュリティの基礎に関する知識（例：暗号化、ファイアウォール、認証、ハニーポット、境界防御など）。',
          softSkill: false,
        },
        {
          id: 'K0562',
          value: '新興の収集機能、アクセスおよび/またはプロセスの能力と限界に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0563',
          value:
            '計画されたサイバー活動に適用される内外の収集機能、制限及びタスク手法に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0564',
          value:
            'ターゲットとする通信ネットワークの特性（例：容量、機能、パス、クリティカルノード）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0565',
          value:
            '一般的なネットワーキングおよびルーティングプロトコル（例：TCP/IP）、サービス（例：Web、メール、DNS）及びそれらがネットワーク通信を提供するためにどのように相互に作用するかに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0566',
          value: '重要な情報の要件と、それがどのように計画に使用されているかに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0567',
          value: '収集元からリポジトリおよびツールへのデータフローに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0568',
          value: '収集管理と収集管理権限の定義に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0569',
          value: '既存のタスク、収集、処理、エクスプロイト、普及のアーキテクチャに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0570',
          value: '収集作業に影響を与える脅威の要因に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0571',
          value: '収集プロセスにおけるフィードバックサイクルに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0572',
          value: '組織に便益をもたらす脅威活動を模倣する内部チームの機能と能力に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0573',
          value:
            '実践的なインテリジェンスを抽出するためのデジタルフォレンジックの基礎に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0574',
          value: 'ネット上の作戦機能における言語解析の影響に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0575',
          value: '内部および外部のパートナスタッフの目算による影響に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0576',
          value: '情報環境に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0577',
          value: 'インテリジェンスのフレームワーク、プロセス及び関連するシステムに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0578',
          value: 'インテリジェンス要件の開発と情報プロセスの要求に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0579',
          value: '上位、下位及び隣接する部分要素についての組織、役割、責任に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0580',
          value: '収集計画のための組織の確立された様式に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0581',
          value: '組織の計画、作戦行動、ターゲット設定のサイクルに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0582',
          value: '組織計画と人員配置プロセスに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0583',
          value: '目的を記述する組織計画/指令/ガイダンスに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0584',
          value: '収集権限を一時的に移転するための組織の方針/手順に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0585',
          value:
            '機能、責任、明確な内部要素間の相互関係を含む、全範囲のサイバー作戦に関係する組織構造に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0586',
          value: '行動経過と影響行使分析の結果に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0587',
          value:
            '環境準備および監視製品を設置するために必要なPOC、データベース、ツールおよびアプリケーションに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0588',
          value: '組織の下位、側方及び上位レベルからの優先情報の要件に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0589',
          value: '作戦行動のパフォーマンスと影響を評価するために使用されるプロセスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0590',
          value: '重要な情報要件プロセスと作戦行動の評価手順を同期させるプロセスに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0591',
          value: '生産責任、オーガニック分析及び生産能力に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0592',
          value: 'ターゲットについてのテンプレートの目的と寄与に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0593',
          value:
            'サイバー作戦行動とその基礎となるインテリジェンスのサポートについてのニーズ、トピック及び焦点領域の範囲に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0594',
          value: '作戦行動の終了状態、目的、効果、戦線などの間の関係に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0595',
          value: '作戦目標、インテリジェンス要件、インテリジェンスの生成タスクの関係に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0596',
          value: '情報プロセスのための要求に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0597',
          value: '他の組織の作戦行動を支援し促進するためのネットワーク運営の役割に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0598',
          value: '組織の具体的な計画、指針、権限の構造と意図に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0599',
          value:
            '最新のデジタルおよびテレフォニーネットワークの構造、アーキテクチャ及び設計に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0600',
          value: '現代の無線通信システムの構造、アーキテクチャ及び設計に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0601',
          value: '調整に使用されるシステム/アーキテクチャ/コミュニケーションに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0602',
          value: '収集の分野と能力に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0603',
          value: 'ターゲットや脅威がインターネットをどのように利用するかに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0604',
          value: '脅威および/またはターゲットシステムに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0605',
          value: 'ティッピング、キューイング、ミキシング及び冗長性に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0606',
          value: '複写物の生成過程と技術に関する知識（例：逐語的、要旨、要約）。',
          softSkill: true,
        },
        {
          id: 'K0607',
          value: '翻訳プロセスと技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0608',
          value:
            'Unix/LinuxおよびWindowsオペレーティングシステムの構造および内部（例：プロセス管理、ディレクトリ構造、インストールされたアプリケーション）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0609',
          value: '仮想計算機技術に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0610',
          value: '仮想化製品に関する知識（VMware、Virtual PC）。',
          softSkill: false,
        },
        {
          id: 'K0611',
          value: '撤回 - K0131に統合',
          softSkill: true,
        },
        {
          id: 'K0612',
          value: 'ネットワークへの「脅威」を構成するものに関する知識。',
          softSkill: false,
        },
        {
          id: 'K0613',
          value:
            '組織の作戦プランナーが誰であるか、どのようにどこに連絡できるのか、そして彼らの期待は何かに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0614',
          value:
            '現代の無線通信システムの基本構造、アーキテクチャ及び設計を含む無線技術（例：携帯電話、衛星通信、GSM）に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0615',
          value: '現行の法律に基づくプライバシー開示声明に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0616',
          value:
            '継続的なモニタリングとそのプロセス、継続的診断および緩和（CDM）プログラムの活動に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0617',
          value: '自動セキュリティ制御評価に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0618',
          value:
            'ハードウェア資産管理、ならびに部門、場所、施設、及び潜在的にサポートするビジネス機能をまたがるネットワークデバイスとソフトウェアの場所と構成を追跡する価値に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0619',
          value:
            'ソフトウェア資産管理、ならびに部門、場所、施設、及び潜在的にサポートするビジネス機能をまたがるネットワークデバイスとソフトウェアの場所と構成を追跡する価値に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0620',
          value: '継続的な監視技術とツールに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0621',
          value: 'リスクスコアリングに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0622',
          value: 'データの使用、処理、保存及び送信に関連する管理策に関する知識。',
          softSkill: false,
        },
        {
          id: 'K0623',
          value: 'リスクアセスメント手法に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0624',
          value:
            'アプリケーションセキュリティリスクに関する知識（例：オープンウェブアプリケーションセキュリティプロジェクトにおけるトップ10リスト）。',
          softSkill: false,
        },
        {
          id: 'K0625',
          value:
            'パッチ適用やソフトウェアの更新は、一部のネットワークデバイスでは実用的ではないことに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0626',
          value: '安全な更新メカニズムに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0627',
          value:
            '詐称されたネットワークアドレスに依存する自動化された脅威から保護するための入力フィルタリングの重要性に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0628',
          value:
            'シミュレートされた現実の状況で実践的な体験を提供することによる、スキルを開発する方法としてのサイバー競技に関する知識。',
          softSkill: true,
        },
        {
          id: 'K0629',
          value: 'ホワイトリスト／ブラックリストに関する知識。',
          softSkill: true,
        },
        {
          id: 'K0630',
          value: '最新の侵入技術、手法、組織外での文書化された侵入事例に関する知識。',
          softSkill: true,
        },
      ],
    },
    {
      name: 'skills',
      items: [
        {
          id: 'S0001',
          value: 'セキュリティシステムの脆弱性スキャンを実施し、脆弱性を認識するスキル。',
          softSkill: false,
        },
        {
          id: 'S0002',
          value: 'データ管理システムの設計におけるストレージ容量の割り当てスキル。',
          softSkill: false,
        },
        {
          id: 'S0003',
          value: 'マルウェアの識別、捕捉、封じ込め、報告のスキル。',
          softSkill: false,
        },
        {
          id: 'S0004',
          value: 'ネットワークトラフィックの容量とパフォーマンス特性を分析するスキル。',
          softSkill: false,
        },
        {
          id: 'S0005',
          value: '提案されたソリューションに情報技術を適用し組み込むスキル。',
          softSkill: false,
        },
        {
          id: 'S0006',
          value: '機密性、完全性、および可用性の原則を適用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0007',
          value: 'ホスト/ネットワークアクセス制御の適用スキル（例：アクセス制御リスト）。',
          softSkill: false,
        },
        {
          id: 'S0008',
          value: '組織固有のシステム分析の原則と技術を適用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0009',
          value: 'セキュリティシステムと設計の堅牢性を評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0010',
          value: '機能と要件の分析を行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0011',
          value: '情報検索を行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0012',
          value: '知識マッピング（例：知識リポジトリのマップ）のスキル。',
          softSkill: false,
        },
        {
          id: 'S0013',
          value: 'データ構造を分析するためのクエリ実行とアルゴリズム開発のスキル。',
          softSkill: false,
        },
        {
          id: 'S0014',
          value: 'ソフトウェアデバッグを行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0015',
          value: 'テストイベントの実施スキル。',
          softSkill: false,
        },
        {
          id: 'S0016',
          value: 'ソフトウェアの設定と最適化のスキル。',
          softSkill: false,
        },
        {
          id: 'S0017',
          value: '数学的または統計的モデルを作成し、活用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0018',
          value: 'システムのセキュリティ目標を反映したポリシーを作成するスキル。',
          softSkill: true,
        },
        {
          id: 'S0019',
          value:
            'コマンドライン引数、環境変数、および入力ストリームを含む複数の入力を検証および処理するプログラムを作成するスキル。',
          softSkill: false,
        },
        {
          id: 'S0020',
          value: '署名の開発と展開のスキル。',
          softSkill: false,
        },
        {
          id: 'S0021',
          value:
            'データ分析構造（つまり、テストが生成する必要があるデータの種類とそのデータの分析方法）を設計するスキル。',
          softSkill: false,
        },
        {
          id: 'S0022',
          value: '特定されたセキュリティリスクに対する対策を設計するスキル。',
          softSkill: false,
        },
        {
          id: 'S0023',
          value: 'サイバーセキュリティの原則と信条に基づいたセキュリティ制御の設計スキル。',
          softSkill: false,
        },
        {
          id: 'S0024',
          value: 'ハードウェアとソフトウェアの統合ソリューション設計のスキル。',
          softSkill: false,
        },
        {
          id: 'S0025',
          value: '侵入検知技術（例：Snort）によるホストおよびネットワークベースの侵入検知スキル。',
          softSkill: false,
        },
        {
          id: 'S0026',
          value: '与えられたシステムに対するテストの厳格さの適切なレベルを決定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0027',
          value:
            'セキュリティ・システムがどのように機能すべきか（弾力性と信頼性能力を含む）、また、条件、運用、環境の変化がこれらの結果にどのように影響するかを判断するスキル。',
          softSkill: false,
        },
        {
          id: 'S0028',
          value: 'データ辞書の開発スキル。',
          softSkill: false,
        },
        {
          id: 'S0029',
          value: 'データモデルを開発するスキル。',
          softSkill: false,
        },
        {
          id: 'S0030',
          value: '運用ベースのテストシナリオを開発するスキル。',
          softSkill: false,
        },
        {
          id: 'S0031',
          value: 'セキュリティシステムのアクセス制御を開発し適用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0032',
          value: 'ネットワークインフラの緊急対応および回復計画の開発、テスト、実施スキル。',
          softSkill: true,
        },
        {
          id: 'S0033',
          value: '接続の問題の診断スキル。',
          softSkill: false,
        },
        {
          id: 'S0034',
          value:
            '情報システムやネットワークの保護ニーズ（すなわちセキュリティ管理）を見極めるスキル。',
          softSkill: false,
        },
        {
          id: 'S0035',
          value: 'ルーティングスキーマを確立するスキル。',
          softSkill: false,
        },
        {
          id: 'S0036',
          value: 'セキュリティ設計の適切性を評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0037',
          value: 'クエリとレポートの生成スキル。',
          softSkill: false,
        },
        {
          id: 'S0038',
          value:
            'システムの目標に照らして、システム・パフォーマンスの尺度または指標を特定し、パフォーマンスを改善または修正するために必要な行動を特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0039',
          value:
            'システムのパフォーマンスまたは可用性の低下の原因を特定し、その低下を軽減するために必要な措置を開始するスキル。',
          softSkill: false,
        },
        {
          id: 'S0040',
          value: '確立されたネットワークセキュリティ慣行を実行、維持、および改善するスキル。',
          softSkill: false,
        },
        {
          id: 'S0041',
          value:
            'ルーター、ハブ、スイッチなどのLANおよびWANコンポーネントのインストール、設定、トラブルシューティングのスキル。',
          softSkill: false,
        },
        {
          id: 'S0042',
          value:
            'データベースのメンテナンススキル（例：バックアップ、リストア、データ削除、トランザクションログファイルなど）。',
          softSkill: false,
        },
        {
          id: 'S0043',
          value:
            'ディレクトリサービスの維持に関するスキル（例：Microsoft Active Directory、LDAPなど）。',
          softSkill: false,
        },
        {
          id: 'S0044',
          value: '脅威行動を模倣するスキル。',
          softSkill: false,
        },
        {
          id: 'S0045',
          value: 'データベースのパフォーマンスを最適化するスキル。',
          softSkill: false,
        },
        {
          id: 'S0046',
          value: '適切なツール（例：Wireshark、tcpdump）を使用したパケットレベルでの分析スキル。',
          softSkill: false,
        },
        {
          id: 'S0047',
          value: '標準運用手順または国家基準に従った証拠の完全性を保持するスキル。',
          softSkill: false,
        },
        {
          id: 'S0048',
          value: 'システム統合テストのスキル。',
          softSkill: false,
        },
        {
          id: 'S0049',
          value: '知的資本の測定と報告のスキル。',
          softSkill: false,
        },
        {
          id: 'S0050',
          value: 'デザインモデリングとユースケース構築のスキル（例：統一モデリング言語）。',
          softSkill: false,
        },
        {
          id: 'S0051',
          value: '侵入テストツールと技術の使用スキル。',
          softSkill: false,
        },
        {
          id: 'S0052',
          value:
            'ソーシャルエンジニアリング技術の使用スキル（例：フィッシング、ベイティング、テールゲーティングなど）。',
          softSkill: false,
        },
        {
          id: 'S0053',
          value: 'センサーを調整するスキル。',
          softSkill: false,
        },
        {
          id: 'S0054',
          value: 'インシデント対応の方法論を使用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0055',
          value: '知識管理技術の使用スキル。',
          softSkill: false,
        },
        {
          id: 'S0056',
          value:
            'ネットワーク管理ツールを使用してネットワークトラフィックパターンを分析するスキル（例：SNMP）。',
          softSkill: false,
        },
        {
          id: 'S0057',
          value: 'プロトコルアナライザーを使用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0058',
          value:
            'システムのソフトウェア、ハードウェア、および周辺機器の修理に適切なツールを使用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0059',
          value: '仮想プライベートネットワーク（VPN）デバイスと暗号化のスキル。',
          softSkill: false,
        },
        {
          id: 'S0060',
          value: '現在サポートされているプログラミング言語（例：Java、C++）でコードを書くスキル。',
          softSkill: false,
        },
        {
          id: 'S0061',
          value: 'テストプランを作成するスキル。',
          softSkill: false,
        },
        {
          id: 'S0062',
          value: 'メモリダンプを分析して情報を抽出するスキル。',
          softSkill: false,
        },
        {
          id: 'S0063',
          value: 'さまざまなサイバーディフェンスリソースからデータを収集するスキル。',
          softSkill: false,
        },
        {
          id: 'S0064',
          value: '技術研修プログラムおよびカリキュラムの開発・実施スキル。',
          softSkill: true,
        },
        {
          id: 'S0065',
          value:
            '多様なメディア内のフォレンジックに重要なデータを特定、抽出するスキル（メディアフォレンジック）。',
          softSkill: false,
        },
        {
          id: 'S0066',
          value: '技術能力におけるギャップを特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0067',
          value:
            'Windows、Unix、Linux内の該当するシステムコンポーネント（パスワード、ユーザーアカウント、ファイルなど）を特定、修正、操作するスキル。',
          softSkill: false,
        },
        {
          id: 'S0068',
          value:
            'データの改ざん、紛失、物理的損傷、または破壊を避けるために、電子証拠を収集、処理、梱包、輸送、保管するスキル。',
          softSkill: false,
        },
        {
          id: 'S0069',
          value: 'フォレンジックワークステーションの設定スキル。',
          softSkill: false,
        },
        {
          id: 'S0070',
          value: '他人に情報を効果的に伝えるための会話スキル。',
          softSkill: true,
        },
        {
          id: 'S0071',
          value: 'フォレンジックツールスイート（EnCase、Sleuthkit、FTKなど）の使用スキル。',
          softSkill: false,
        },
        {
          id: 'S0072',
          value: '問題を解決するための科学的な規則や方法を使うスキル。',
          softSkill: false,
        },
        {
          id: 'S0073',
          value:
            '仮想マシンの使用スキル（例：Microsoft Hyper-V、VMWare vSphere、Citrix XenDesktop/Server、Amazon Elastic Compute Cloud など）。',
          softSkill: false,
        },
        {
          id: 'S0074',
          value: 'PCを物理的に分解するスキル。',
          softSkill: false,
        },
        {
          id: 'S0075',
          value:
            '複数のオペレーティングシステム環境でのフォレンジック分析を行うスキル（例：モバイルデバイスシステム）。',
          softSkill: false,
        },
        {
          id: 'S0076',
          value:
            'ソフトウェアベースのコンピュータ保護ツール（ソフトウェアファイアウォール、アンチウイルスソフトウェア、アンチスパイウェアなど）の設定と利用に関するスキル。',
          softSkill: false,
        },
        {
          id: 'S0077',
          value: 'ネットワーク通信の保護に関するスキル。',
          softSkill: false,
        },
        {
          id: 'S0078',
          value: '脆弱性の種類と関連する攻撃を認識し、分類するスキル。',
          softSkill: false,
        },
        {
          id: 'S0079',
          value:
            'マルウェアからネットワークを保護するスキル（例：NIPS、アンチマルウェア、外部デバイスの制限/防止、スパムフィルター）。',
          softSkill: false,
        },
        {
          id: 'S0080',
          value: '損害査定のスキル。',
          softSkill: false,
        },
        {
          id: 'S0081',
          value:
            'ネットワーク分析ツールを使用して脆弱性を特定するスキル（例：ファジング、nmapなど）。',
          softSkill: false,
        },
        {
          id: 'S0082',
          value: '適用性と完全性のテスト計画を評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0083',
          value:
            'ソフトウェアリリースの品質保証プロセスにブラックボックスセキュリティテストツールを統合するスキル。',
          softSkill: false,
        },
        {
          id: 'S0084',
          value:
            'ネットワーク保護コンポーネント（例：ファイアウォール、VPN、ネットワーク侵入検知システム）の設定と利用に関するスキル。',
          softSkill: false,
        },
        {
          id: 'S0085',
          value: '技術システムの監査またはレビューを行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0086',
          value: 'サプライヤーや製品の信頼性を評価するスキル。',
          softSkill: true,
        },
        {
          id: 'S0087',
          value: '取得した悪意のあるコードを深く分析するスキル（例：マルウェアフォレンジック）。',
          softSkill: false,
        },
        {
          id: 'S0088',
          value: 'バイナリ解析ツールを使用するスキル（例：Hexedit、コマンドコードxxd、hexdump）。',
          softSkill: false,
        },
        {
          id: 'S0089',
          value:
            '一方向ハッシュ関数（例：セキュアハッシュアルゴリズム[SHA]、メッセージダイジェストアルゴリズム[MD5]）のスキル。',
          softSkill: false,
        },
        {
          id: 'S0090',
          value: '異常なコードを悪意のあるものか無害か分析するスキル。',
          softSkill: false,
        },
        {
          id: 'S0091',
          value: '不安定なデータを分析するスキル。',
          softSkill: false,
        },
        {
          id: 'S0092',
          value: '難読化技術を識別するスキル。',
          softSkill: false,
        },
        {
          id: 'S0093',
          value: '戦術、技術、および手順を確認するためにデバッガーの結果を理解するスキル。',
          softSkill: false,
        },
        {
          id: 'S0094',
          value: '16進数データの読み取りスキル。',
          softSkill: false,
        },
        {
          id: 'S0095',
          value:
            '一般的なエンコーディング技術（例：排他的論理和[XOR]、アメリカ標準情報交換コード[ASCII]、ユニコード、Base64、Uuencode、URLエンコード）を識別するスキル。',
          softSkill: false,
        },
        {
          id: 'S0096',
          value: 'シグネチャ（スノートなど）の読み取りと解釈のスキル。',
          softSkill: false,
        },
        {
          id: 'S0097',
          value: 'セキュリティコントロールの適用スキル。',
          softSkill: false,
        },
        {
          id: 'S0100',
          value:
            '学習活動（シナリオ、教育ゲーム、インタラクティブな演習など）を活用または開発するスキル。',
          softSkill: true,
        },
        {
          id: 'S0101',
          value:
            '技術（スマートボード、ウェブサイト、コンピューター、プロジェクターなど）を教育目的で活用するスキル。',
          softSkill: true,
        },
        {
          id: 'S0102',
          value: '技術的な配信能力の適用スキル。',
          softSkill: true,
        },
        {
          id: 'S0103',
          value: 'モデルの予測力とその後の一般化可能性を評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0104',
          value: 'テスト準備レビューの実施スキル。',
          softSkill: false,
        },
        {
          id: 'S0106',
          value:
            'データ前処理のスキル（例：代入、次元削減、正規化、変換、抽出、フィルタリング、平滑化）。',
          softSkill: false,
        },
        {
          id: 'S0107',
          value: 'プログラム全体のテストと評価戦略の設計と文書化のスキル。',
          softSkill: false,
        },
        {
          id: 'S0108',
          value: '労働力と職位の資格基準策定スキル。',
          softSkill: true,
        },
        {
          id: 'S0109',
          value: '隠されたパターンや関連性を識別するスキル。',
          softSkill: false,
        },
        {
          id: 'S0110',
          value: 'テスト＆評価インフラストラクチャ（人々、範囲、ツール、計測器）要件の特定スキル。',
          softSkill: false,
        },
        {
          id: 'S0111',
          value: '顧客と接するスキル。',
          softSkill: true,
        },
        {
          id: 'S0112',
          value:
            'テストイベントを効果的に完了させるために、テスト資産、テストリソース、テスト要員を管理するスキル。',
          softSkill: false,
        },
        {
          id: 'S0113',
          value: 'データの標準的な表現を作成するためのフォーマット変換スキル。',
          softSkill: false,
        },
        {
          id: 'S0114',
          value: '感度解析を行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0115',
          value: 'テスト＆評価レポートの作成スキル。',
          softSkill: false,
        },
        {
          id: 'S0116',
          value: 'マルチレベルセキュリティ/クロスドメインソリューションの設計スキル。',
          softSkill: false,
        },
        {
          id: 'S0117',
          value: 'テスト＆評価リソース見積もりのスキル。',
          softSkill: false,
        },
        {
          id: 'S0118',
          value: '機械が理解可能なセマンティックオントロジーを開発するスキル。',
          softSkill: false,
        },
        {
          id: 'S0119',
          value:
            '回帰分析のスキル（例：階層的ステップワイズ、一般化線形モデル、最小二乗法、ツリーベース、ロジスティック）。',
          softSkill: false,
        },
        {
          id: 'S0120',
          value: 'ログをレビューして過去の侵入の証拠を特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0121',
          value:
            'システム、ネットワーク、OSの強化技術に関するスキル（例：不要なサービスの削除、パスワードポリシー、ネットワークセグメンテーション、ログの有効化、最小権限など）。',
          softSkill: false,
        },
        {
          id: 'S0122',
          value: 'デザイン手法の使用スキル。',
          softSkill: false,
        },
        {
          id: 'S0123',
          value: '変換分析のスキル（例：集約、エンリッチメント、処理）。',
          softSkill: false,
        },
        {
          id: 'S0124',
          value:
            'サイバーディフェンスインフラの異常をトラブルシューティングし、診断し、解決策を導くスキル。',
          softSkill: false,
        },
        {
          id: 'S0125',
          value: '基本的な記述統計と技術を使用するスキル（例：正規性、モデル分布、散布図）。',
          softSkill: false,
        },
        {
          id: 'S0126',
          value: 'データ分析ツールの使用スキル（例：Excel、STATA SAS、SPSS）。',
          softSkill: false,
        },
        {
          id: 'S0127',
          value: 'データマッピングツールの使用スキル。',
          softSkill: false,
        },
        {
          id: 'S0128',
          value: '人材および人事ITシステムの使用スキル。',
          softSkill: true,
        },
        {
          id: 'S0129',
          value: '外れ値の特定と除去技術の使用スキル。',
          softSkill: false,
        },
        {
          id: 'S0130',
          value: 'R、Python、PIG、HIVE、SQLなどのスクリプト作成スキル。',
          softSkill: false,
        },
        {
          id: 'S0131',
          value: 'マルウェアの分析スキル。',
          softSkill: false,
        },
        {
          id: 'S0132',
          value: 'ビットレベルでの解析スキル。',
          softSkill: false,
        },
        {
          id: 'S0133',
          value: '証拠の保護や法的に問題のないコピーの作成など、デジタル証拠を処理するスキル。',
          softSkill: false,
        },
        {
          id: 'S0134',
          value: 'システムのレビューを行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0135',
          value:
            '安全なテストプランの設計スキル（例：ユニット、インテグレーション、システム、アクセプタンス）。',
          softSkill: false,
        },
        {
          id: 'S0136',
          value:
            'ネットワークシステム管理の原則、モデル、方法（例：エンドツーエンドのシステムパフォーマンスモニタリング）、およびツールに関するスキル。',
          softSkill: false,
        },
        {
          id: 'S0137',
          value: 'アプリケーションの脆弱性評価を行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0138',
          value:
            '公開鍵インフラ（PKI）暗号化およびデジタル署名機能をアプリケーションに組み込むスキル（例：S/MIMEメール、SSLトラフィック）。',
          softSkill: false,
        },
        {
          id: 'S0139',
          value:
            'セキュリティモデルの適用スキル（例：ベル・ラパドゥーラモデル、ビバ整合性モデル、クラークウィルソン整合性モデル）。',
          softSkill: false,
        },
        {
          id: 'S0140',
          value: 'システムエンジニアリングプロセスを適用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0141',
          value: 'セキュリティシステムの設計を評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0142',
          value: '顧客レベルの新規問題のトラブルシューティングのための調査スキル。',
          softSkill: false,
        },
        {
          id: 'S0143',
          value: 'システム/サーバーの計画、管理、保守のスキル。',
          softSkill: false,
        },
        {
          id: 'S0144',
          value:
            'システム/サーバーのパフォーマンスに影響を与える物理的および技術的な問題を修正するスキル。',
          softSkill: false,
        },
        {
          id: 'S0145',
          value: 'システムのセキュリティ目標を満たすポリシーを統合し、適用するスキル。',
          softSkill: true,
        },
        {
          id: 'S0146',
          value:
            'システムがパフォーマンス目標（トラフィックルーティング、SLA、CPU仕様など）を達成するためのポリシーを作成するスキル。',
          softSkill: false,
        },
        {
          id: 'S0147',
          value:
            'サイバーセキュリティの原則と信条（CIS CSC、NIST SP 800-53、サイバーセキュリティフレームワークなど）に基づいてセキュリティコントロールを評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0148',
          value:
            '古いシステムや最新のプログラミング言語を含む、技術プロセスとソリューションの統合設計を行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0149',
          value: 'エラー、例外、障害を記録および処理できるアプリケーションの開発スキル。',
          softSkill: false,
        },
        {
          id: 'S0150',
          value: 'ネットワークインフラの緊急回復計画と復旧計画の実装とテストのスキル。',
          softSkill: true,
        },
        {
          id: 'S0151',
          value: 'システムコンポーネント（例：サーバー）のトラブルシューティングのスキル。',
          softSkill: false,
        },
        {
          id: 'S0152',
          value: '運用要件を保護ニーズ（すなわち、セキュリティコントロール）に翻訳するスキル。',
          softSkill: false,
        },
        {
          id: 'S0153',
          value:
            'システム/サーバーのパフォーマンス、可用性、キャパシティ、構成の問題を特定し、予測するスキル。',
          softSkill: false,
        },
        {
          id: 'S0154',
          value:
            'システムおよびコンポーネントのアップグレードのインストールスキル（例：サーバー、アプライアンス、ネットワークデバイス）。',
          softSkill: false,
        },
        {
          id: 'S0155',
          value: 'システム/サーバーのパフォーマンスを監視し、最適化するスキル。',
          softSkill: false,
        },
        {
          id: 'S0156',
          value: 'パケットレベル分析のスキル。',
          softSkill: false,
        },
        {
          id: 'S0157',
          value:
            'システム/サーバーの障害回復スキル（例：リカバリーソフトウェア、フェイルオーバークラスター、レプリケーションなど）。',
          softSkill: false,
        },
        {
          id: 'S0158',
          value:
            'オペレーティングシステム管理のスキル（例：アカウントメンテナンス、データバックアップ、システムパフォーマンスの維持、新しいハードウェア/ソフトウェアのインストールと設定）。',
          softSkill: false,
        },
        {
          id: 'S0159',
          value:
            '承認された基準および/または仕様に従ってネットワークワークステーションおよび周辺機器を設定し、検証するスキル。',
          softSkill: false,
        },
        {
          id: 'S0160',
          value: '設計モデリングの使用スキル（例：統一モデリング言語）。',
          softSkill: false,
        },
        {
          id: 'S0161',
          value: '撤回 – S0160に統合',
          softSkill: true,
        },
        {
          id: 'S0162',
          value: 'サブネッティングのスキル。',
          softSkill: false,
        },
        {
          id: 'S0163',
          value: '撤回 - S0060に統合',
          softSkill: true,
        },
        {
          id: 'S0164',
          value: '暗号標準の適用を評価するスキル。',
          softSkill: true,
        },
        {
          id: 'S0166',
          value: '技術提供能力におけるギャップを特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0167',
          value:
            'セキュリティシステムの脆弱性を認識するスキル（例：脆弱性スキャンとコンプライアンススキャン）。',
          softSkill: false,
        },
        {
          id: 'S0168',
          value:
            '内部ローカルエリアネットワーク（LAN）を他の信頼できないネットワークから分離する物理的または論理的なサブネットワークを設定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0169',
          value: 'トレンド分析を行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0170',
          value:
            'コンピュータ保護コンポーネント（例：ハードウェアファイアウォール、サーバー、ルーターなど）の設定と活用のスキル。',
          softSkill: false,
        },
        {
          id: 'S0171',
          value: '影響/リスク評価のスキル。',
          softSkill: true,
        },
        {
          id: 'S0172',
          value: 'セキュアコーディング技術を適用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0173',
          value: 'セキュリティイベント相関ツールの使用スキル。',
          softSkill: false,
        },
        {
          id: 'S0174',
          value: 'コード解析ツールの使用スキル。',
          softSkill: false,
        },
        {
          id: 'S0175',
          value: '原因分析を行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0176',
          value:
            '機能的かつ具体的な支援計画の作成、通信文の作成と管理、人員配置の手順など、管理計画活動のスキル。',
          softSkill: true,
        },
        {
          id: 'S0177',
          value: 'ターゲットのコミュニケーションネットワークを分析するスキル。',
          softSkill: false,
        },
        {
          id: 'S0178',
          value:
            '重要なネットワークデータ（例：ルータ設定ファイル、ルーティングプロトコル）の分析スキル。',
          softSkill: false,
        },
        {
          id: 'S0179',
          value:
            '言語処理ツールを分析し、ツール開発を強化するためのフィードバックを提供するスキル。',
          softSkill: false,
        },
        {
          id: 'S0180',
          value: '撤回 - S0062に統合',
          softSkill: true,
        },
        {
          id: 'S0181',
          value: '中間点収集データの分析スキル。',
          softSkill: false,
        },
        {
          id: 'S0182',
          value: '無線LANから収集されたターゲット通信の内部および外部を分析するスキル。',
          softSkill: false,
        },
        {
          id: 'S0183',
          value: '端末または環境収集データの分析スキル。',
          softSkill: false,
        },
        {
          id: 'S0184',
          value: 'ネットワークデバイスを特定するためのトラフィック分析スキル。',
          softSkill: false,
        },
        {
          id: 'S0185',
          value:
            '計画を支援し、推奨される戦略や行動方針を正当化するために通常使用される分析方法を適用するスキル。',
          softSkill: true,
        },
        {
          id: 'S0186',
          value: '危機計画手順を適用するスキル。',
          softSkill: true,
        },
        {
          id: 'S0187',
          value:
            'さまざまな分析手法、ツール、技術（例：競合仮説、推論の連鎖、シナリオ手法、否認と欺瞞の検出、高影響-低確率、ネットワーク/関連またはリンク分析、ベイズ、デルファイ、パターン分析）を適用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0188',
          value: '対象の参照枠（動機、技術力、組織構造、感受性など）を評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0189',
          value: 'サイバー作戦中および後に発生する効果を評価および/または推定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0190',
          value: '現在のツールを評価して必要な改善点を特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0191',
          value: 'さまざまな状況に対し、利用可能な分析ツールの適用可能性を評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0192',
          value: 'ファイアウォール、ペリメータ、ルーター、侵入検知システムの監査スキル。',
          softSkill: false,
        },
        {
          id: 'S0193',
          value: '対象となる情報の法規制の遵守スキル。',
          softSkill: true,
        },
        {
          id: 'S0194',
          value: '無属性調査を行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0195',
          value: 'すべての利用可能な情報源を使用して調査を行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0196',
          value: 'ディープウェブを利用した調査スキル。',
          softSkill: false,
        },
        {
          id: 'S0197',
          value:
            'ソーシャルネットワーク分析、バディリスト分析、および/またはクッキー分析を行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0198',
          value: 'ソーシャルネットワーク分析を行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0199',
          value: 'パケットキャプチャから重要な情報を作成、抽出するスキル。',
          softSkill: false,
        },
        {
          id: 'S0200',
          value: 'データ取得活動を支援するための収集要件の作成スキル。',
          softSkill: false,
        },
        {
          id: 'S0201',
          value:
            '遠隔操作を支援する計画を作成するスキル（例：ホット/ウォーム/コールド/代替サイト、災害復旧）。',
          softSkill: true,
        },
        {
          id: 'S0202',
          value: 'データマイニング技術（例：ファイルシステムの検索）および分析スキル。',
          softSkill: false,
        },
        {
          id: 'S0203',
          value: '運用環境のすべての関連する側面を定義し、特徴づけるスキル。',
          softSkill: false,
        },
        {
          id: 'S0204',
          value: 'ネットワークマップ上でのソースまたは関連データの描写スキル。',
          softSkill: false,
        },
        {
          id: 'S0205',
          value:
            '希望する効果に対する利用可能な能力の評価を通じて、適切な標的オプションを決定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0206',
          value:
            'さまざまなオペレーティングシステムにインストールされているパッチを特定し、パッチのシグネチャを識別するスキル。',
          softSkill: false,
        },
        {
          id: 'S0207',
          value:
            'ルーターやファイアウォールの設定がLANおよびWAN環境でのトラフィックパターンとネットワークパフォーマンスに与える影響を判断するスキル。',
          softSkill: false,
        },
        {
          id: 'S0208',
          value: 'ネットワークデバイスの物理的な位置を特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0209',
          value:
            '運用のパフォーマンス特性を評価・検証するための包括的なサイバー運用評価プログラムを開発・実行するスキル。',
          softSkill: false,
        },
        {
          id: 'S0210',
          value: 'インテリジェンスレポートを作成するスキル。',
          softSkill: false,
        },
        {
          id: 'S0211',
          value:
            '情報が不完全、または前例の無い問題や状況に対し、分析的アプローチや解決策を開発または推奨するスキル。',
          softSkill: false,
        },
        {
          id: 'S0212',
          value: '最も価値ある情報をタイムリーに発信するスキル。',
          softSkill: false,
        },
        {
          id: 'S0213',
          value: '複雑な技術的およびプログラム的情報を文書化し、伝達するスキル。',
          softSkill: true,
        },
        {
          id: 'S0214',
          value: 'インテリジェンスの価値からアクセスを評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0215',
          value: 'メタデータの評価と解釈スキル。',
          softSkill: false,
        },
        {
          id: 'S0216',
          value:
            '効果的な行動方針を提供するために、望ましい効果に対して利用可能な能力を評価するスキル。',
          softSkill: true,
        },
        {
          id: 'S0217',
          value: 'データソースの関連性、信頼性、および客観性を評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0218',
          value: '情報の信頼性、妥当性、関連性を評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0219',
          value: '関連性、優先順位などを認識するために情報を評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0220',
          value: '組織および/またはパートナーの収集データベースを利用/照会するスキル。',
          softSkill: false,
        },
        {
          id: 'S0221',
          value: 'パケットキャプチャから情報を抽出するスキル。',
          softSkill: false,
        },
        {
          id: 'S0222',
          value: '融合分析のスキル。',
          softSkill: false,
        },
        {
          id: 'S0223',
          value: 'ミッションとターゲット要件をサポートするための作戦計画を立てるスキル。',
          softSkill: false,
        },
        {
          id: 'S0224',
          value: '対象コミュニケーションの要約スキル。',
          softSkill: false,
        },
        {
          id: 'S0225',
          value: 'ターゲットの通信ネットワークを特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0226',
          value: 'ターゲットのネットワーク特性を識別するスキル。',
          softSkill: false,
        },
        {
          id: 'S0227',
          value: '予期しない結果を最小限に抑えるために、代替的な分析解釈を特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0228',
          value:
            'サイバー領域の重要なターゲット要素を含めるために、重要なターゲット要素を特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0229',
          value: '組織やパートナーの利益を脅かす可能性のあるサイバー脅威を特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0230',
          value: '撤回 - S0066に統合',
          softSkill: true,
        },
        {
          id: 'S0231',
          value: 'ターゲットがどのようにコミュニケーションを取るかを識別するスキル。',
          softSkill: false,
        },
        {
          id: 'S0232',
          value: '情報のギャップと制限を特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0233',
          value: '組織の目標に影響を与える可能性のある言語問題を特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0234',
          value: 'ターゲット開発のための手がかりを特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0235',
          value: '対象地域外の言語や方言を識別するスキル。',
          softSkill: false,
        },
        {
          id: 'S0236',
          value: 'プロトコルモデルの各レベルで動作するデバイスを識別するスキル。',
          softSkill: false,
        },
        {
          id: 'S0237',
          value: '地理空間分析技術を用いた、ターゲットの識別、位置特定、追跡を行うスキル。',
          softSkill: false,
        },
        {
          id: 'S0238',
          value: '作戦に関連する情報の優先順位付けのスキル。',
          softSkill: false,
        },
        {
          id: 'S0239',
          value: 'コンパイル言語とインタープリタ言語の解釈スキル。',
          softSkill: false,
        },
        {
          id: 'S0240',
          value: '情報収集システムによって適用されるメタデータとコンテンツの解釈スキル。',
          softSkill: false,
        },
        {
          id: 'S0241',
          value: 'ネットワーク分析および再構築に適用されるtraceroute結果の解釈スキル。',
          softSkill: false,
        },
        {
          id: 'S0242',
          value: '脆弱性を特定するために、脆弱性スキャナーの結果を解釈するスキル。',
          softSkill: false,
        },
        {
          id: 'S0243',
          value: '技術文書作成技術（Wikiページなど）を含む知識管理のスキル。',
          softSkill: false,
        },
        {
          id: 'S0244',
          value:
            'クライアントのニーズ／要件を判断し、クライアントの期待を管理し、質の高い結果を提供することへのコミットメントを示すなど、クライアントとの関係を管理するスキル。',
          softSkill: true,
        },
        {
          id: 'S0245',
          value: 'ネットワーク可視化ソフトウェアを操作するスキル。',
          softSkill: false,
        },
        {
          id: 'S0246',
          value: '数値正規化のスキル。',
          softSkill: false,
        },
        {
          id: 'S0247',
          value:
            '新たな情報収集と継続的な情報収集を可能にするために、既存のインテリジェンスからデータフュージョンを実行するスキル。',
          softSkill: false,
        },
        {
          id: 'S0248',
          value: 'ターゲットシステム分析のスキル。',
          softSkill: false,
        },
        {
          id: 'S0249',
          value: 'ブリーフィングの準備と発表のスキル。',
          softSkill: true,
        },
        {
          id: 'S0250',
          value: '計画と関連する文書の準備に関するスキル。',
          softSkill: true,
        },
        {
          id: 'S0251',
          value: 'ターゲット言語教材の優先順位付けスキル。',
          softSkill: false,
        },
        {
          id: 'S0252',
          value: '収集したデータを続く分析のために処理するスキル。',
          softSkill: false,
        },
        {
          id: 'S0253',
          value: '対象関連事項（例：言語、文化、コミュニケーション）に関する分析を提供するスキル。',
          softSkill: false,
        },
        {
          id: 'S0254',
          value: '段階的なアフターアクションレポート作成を支援するための分析提供スキル。',
          softSkill: true,
        },
        {
          id: 'S0255',
          value: 'ターゲットインフラを利用したリアルタイムで実用的な位置情報提供スキル。',
          softSkill: false,
        },
        {
          id: 'S0256',
          value:
            '物理的、機能的、または行動的関係の特定とリンク分析を通じて、ターゲットまたは脅威システムの理解を提供するスキル。',
          softSkill: false,
        },
        {
          id: 'S0257',
          value:
            'WindowsおよびUnixシステムでのシンプルなスクリプト（例：PERL、VBS）の読み取り、解釈、記述、修正、実行ができるスキル（大量のデータファイルのパース、手動タスクの自動化、リモートデータの取得・処理などのタスクを実行するものなど）。',
          softSkill: false,
        },
        {
          id: 'S0258',
          value: 'トラフィック内の悪意のあるネットワーク活動を認識し解釈するスキル。',
          softSkill: false,
        },
        {
          id: 'S0259',
          value: '対象の否認および欺瞞技術を認識するスキル。',
          softSkill: false,
        },
        {
          id: 'S0260',
          value: '中間点の機会と重要な情報を認識するスキル。',
          softSkill: false,
        },
        {
          id: 'S0261',
          value: '情報の関連性を認識するスキル。',
          softSkill: false,
        },
        {
          id: 'S0262',
          value: 'ターゲットのコミュニケーションパターンの重要な変化を認識するスキル。',
          softSkill: false,
        },
        {
          id: 'S0263',
          value: 'メタデータ分析のための手がかりとなりうる技術情報を認識するスキル。',
          softSkill: false,
        },
        {
          id: 'S0264',
          value:
            'リモート操作を可能にするための手がかりとなりうる技術情報を認識するスキル（データにはユーザー、パスワード、メールアドレス、対象のIP範囲、DNI行動の頻度、メールサーバー、ドメインサーバー、SMTPヘッダー情報が含まれます）。',
          softSkill: false,
        },
        {
          id: 'S0265',
          value:
            'ターゲット開発やインテリジェンス開発に使用される可能性のある技術情報を認識するスキル。',
          softSkill: false,
        },
        {
          id: 'S0266',
          value: '関連するプログラミング言語（例：C++、Pythonなど）のスキル。',
          softSkill: false,
        },
        {
          id: 'S0267',
          value:
            'リモートコマンドラインおよびグラフィックユーザーインターフェース（GUI）ツールの使用スキル。',
          softSkill: false,
        },
        {
          id: 'S0268',
          value: '重要な情報を調査するスキル。',
          softSkill: false,
        },
        {
          id: 'S0269',
          value: 'トラフィックで利用される脆弱性とエクスプロイトの調査スキル。',
          softSkill: false,
        },
        {
          id: 'S0270',
          value:
            'リモートツールの機能と所有権を特定するリバースエンジニアリング（ヘックス編集、バイナリパッケージングユーティリティ、デバッグ、文字列解析など）のスキル。',
          softSkill: false,
        },
        {
          id: 'S0271',
          value: '評価製品のレビューと編集のスキル。',
          softSkill: false,
        },
        {
          id: 'S0272',
          value:
            'サイバー作戦のための様々な情報源からのインテリジェンス製品のレビューおよび編集スキル。',
          softSkill: false,
        },
        {
          id: 'S0273',
          value: '計画のレビューと編集のスキル。',
          softSkill: true,
        },
        {
          id: 'S0274',
          value: '対象の資料のレビューと編集のスキル。',
          softSkill: true,
        },
        {
          id: 'S0275',
          value: 'サーバー管理スキル。',
          softSkill: false,
        },
        {
          id: 'S0276',
          value: '無線LANメタデータの調査、収集、および分析スキル。',
          softSkill: false,
        },
        {
          id: 'S0277',
          value: 'データセット全体での意味の合成、分析、優先順位付けのスキル。',
          softSkill: false,
        },
        {
          id: 'S0278',
          value: '必要なレベル（例：分類および組織化）に合わせた分析のスキル。',
          softSkill: false,
        },
        {
          id: 'S0279',
          value: '情報収集作戦を直接支援するためのターゲット開発のスキル。',
          softSkill: false,
        },
        {
          id: 'S0280',
          value:
            'ターゲットネットワークの異常検出スキル（例：侵入、データフローや処理、新技術のターゲット実装）。',
          softSkill: false,
        },
        {
          id: 'S0281',
          value: '技術的なライティングのスキル。',
          softSkill: true,
        },
        {
          id: 'S0282',
          value: '実装のためのツールをテストし評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0283',
          value: '対象言語のコミュニケーションを書き起こすスキル。',
          softSkill: false,
        },
        {
          id: 'S0284',
          value: '対象グラフィックおよび/または音声言語資料の翻訳スキル。',
          softSkill: false,
        },
        {
          id: 'S0285',
          value: 'シンプルで複雑なクエリを作成するためにブール演算子を使用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0286',
          value: 'ターゲットに関連する情報を特定するためにデータベースを使用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0287',
          value: '地理空間データを使用し、地理空間リソースを適用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0288',
          value:
            '複数の分析ツール、データベース、技術（例：アナリストのノートブック、A-Space、Anchory、M3、発散的/収束的思考、リンクチャート、行列など）を使用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0289',
          value:
            '複数の検索エンジン（例：Google、Yahoo、LexisNexis、DataStar）やオープンソース検索を行うためのツールを使用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0290',
          value: '非帰属ネットワークの使用スキル。',
          softSkill: false,
        },
        {
          id: 'S0291',
          value:
            'ターゲットネットワークを再構築するために、複数の異なるソースを含む研究方法を使用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0292',
          value: 'ターゲティングデータベースおよびソフトウェアパッケージの使用スキル。',
          softSkill: false,
        },
        {
          id: 'S0293',
          value:
            'リモートからターゲットを攻撃し、永続性を確立するためのツール、テクニック、手順を使用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0294',
          value:
            'トレースルートツールを使用し、ネットワーク分析および再構築に適用する結果を解釈するスキル。',
          softSkill: false,
        },
        {
          id: 'S0295',
          value:
            'さまざまなオープンソースのデータ収集ツール（オンライントレード、DNS、メールなど）の使用スキル。',
          softSkill: false,
        },
        {
          id: 'S0296',
          value: 'プロセス、製品、サービスを改善するためにフィードバックを活用するスキル。',
          softSkill: true,
        },
        {
          id: 'S0297',
          value:
            '仮想コラボレーションワークスペースおよび/またはツール（例：IWS、VTC、チャットルーム、SharePoint）の利用スキル。',
          softSkill: true,
        },
        {
          id: 'S0298',
          value:
            'すべてのファイルの整合性を検証するスキル（例：チェックサム、排他的論理和、セキュアハッシュ、チェック制約など）。',
          softSkill: false,
        },
        {
          id: 'S0299',
          value:
            '無線ネットワークのターゲット分析、テンプレーティング、およびジオロケーションのスキル。',
          softSkill: false,
        },
        {
          id: 'S0300',
          value: '技術能力のギャップを満たすための要件の記述（および提出）スキル。',
          softSkill: true,
        },
        {
          id: 'S0301',
          value: '事実やアイデアについて、明確で説得力がある整理された文章を書くスキル。',
          softSkill: true,
        },
        {
          id: 'S0302',
          value: '効果報告書の作成スキル。',
          softSkill: true,
        },
        {
          id: 'S0303',
          value:
            '複数の情報源からのサイバー関連のインテリジェンス/評価製品の執筆、レビュー、編集のスキル。',
          softSkill: true,
        },
        {
          id: 'S0304',
          value: '現在の資産の利用可能性と使用法に関する情報を取得するスキル。',
          softSkill: false,
        },
        {
          id: 'S0305',
          value: '計画/指示/ガイダンスが管理されているデータベースにアクセスするスキル。',
          softSkill: false,
        },
        {
          id: 'S0306',
          value: '問題の明確化や追加の指導が必要な場合の戦略的指導を分析するスキル。',
          softSkill: true,
        },
        {
          id: 'S0307',
          value: '対象または脅威の、強さと士気の源を分析するスキル。',
          softSkill: false,
        },
        {
          id: 'S0308',
          value: 'インテリジェンス能力の採用要件を予測するスキル。',
          softSkill: false,
        },
        {
          id: 'S0309',
          value:
            'リーダーシップの決定を促す可能性が高い主要なターゲットまたは脅威の活動を予測するスキル。',
          softSkill: true,
        },
        {
          id: 'S0310',
          value: 'インテリジェンス製品を評価するための分析基準を適用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0311',
          value:
            '利用可能なプラットフォーム、センサー、アーキテクチャ、装置の能力、制限、および任務遂行方法を組織の目標に適用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0312',
          value:
            'サイバー作戦のパフォーマンスと影響を評価するために使用されるプロセスを適用するスキル。',
          softSkill: true,
        },
        {
          id: 'S0313',
          value:
            'ニーズステートメント/要件を明確にし、新しい情報収集能力、アクセス、および/またはプロセスを情報収集任務に統合する能力。',
          softSkill: false,
        },
        {
          id: 'S0314',
          value: '計画の実行を支援するために利用可能なインテリジェンス能力を明確に説明するスキル。',
          softSkill: true,
        },
        {
          id: 'S0315',
          value: '共同計画者のニーズを全情報源アナリストに明確に伝えるスキル。',
          softSkill: true,
        },
        {
          id: 'S0316',
          value: 'インテリジェンスのギャップを優先情報要件および観測可能事項に関連付けるスキル。',
          softSkill: false,
        },
        {
          id: 'S0317',
          value: '指標/観測値を要件と比較するスキル。',
          softSkill: false,
        },
        {
          id: 'S0318',
          value: '複数の領域と次元において、インテリジェンスプロセス全体を概念化するスキル。',
          softSkill: true,
        },
        {
          id: 'S0319',
          value: 'インテリジェンス要件をインテリジェンス生産タスクに変換するスキル。',
          softSkill: false,
        },
        {
          id: 'S0320',
          value: 'オーダーメイドのインテリジェンス製品の開発を調整するスキル。',
          softSkill: false,
        },
        {
          id: 'S0321',
          value:
            'インテリジェンスの優先事項とインテリジェンスのリソース/アセットの割り当てを相関させるスキル。',
          softSkill: false,
        },
        {
          id: 'S0322',
          value: '運用進捗/成功の指標を作成するスキル。',
          softSkill: false,
        },
        {
          id: 'S0323',
          value: 'サービス/生産の計画書を作成し、最新の状態に保つスキル。',
          softSkill: true,
        },
        {
          id: 'S0324',
          value: '情報収集の実現可能性を判断するスキル。',
          softSkill: false,
        },
        {
          id: 'S0325',
          value: '必要な情報収集に使用できる手法を明確に示す情報収集計画を策定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0326',
          value:
            '計画の策定において、想定リソースと実際のリソースを区別し、開発中の計画に適用するスキル。',
          softSkill: true,
        },
        {
          id: 'S0327',
          value: '情報収集戦略がすべての利用可能なリソースを活用できるようにするスキル。',
          softSkill: false,
        },
        {
          id: 'S0328',
          value: '目標と情報要件に対する作戦環境の要因を評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0329',
          value: '応答情報が存在するかどうかを判断するために情報要求を評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0330',
          value:
            '有機的、劇場的、国家的、連合的、その他の収集能力の能力、限界、任務遂行方法を評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0331',
          value:
            '情報能力の限界と意思決定のリスクとの関係や全体的な作戦への影響を、口頭及び文書で表現するスキル。',
          softSkill: true,
        },
        {
          id: 'S0332',
          value:
            '情報収集要件と情報収集運用管理に関連する利用可能なツールやアプリケーションから情報を抽出するスキル。',
          softSkill: false,
        },
        {
          id: 'S0333',
          value:
            'インテリジェンスとパートナー能力の見積もりを含む意思決定支援資料をグラフィカルに描写するスキル。',
          softSkill: true,
        },
        {
          id: 'S0334',
          value:
            '関連する情報収集分野に対し、タスキング、収集、処理、利用、普及を識別して適用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0335',
          value: 'インテリジェンスのギャップを特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0336',
          value: '優先的な情報要件が満たされるタイミングを識別するスキル。',
          softSkill: false,
        },
        {
          id: 'S0337',
          value: '情報収集管理および運用活動を評価するための確立された手順を実施するスキル。',
          softSkill: false,
        },
        {
          id: 'S0338',
          value: '必要な分析サポートのレベルを見極めるために計画ガイダンスを解釈するスキル。',
          softSkill: true,
        },
        {
          id: 'S0339',
          value: '準備レポートと、その運用上の関連性および情報収集への影響について解釈するスキル。',
          softSkill: false,
        },
        {
          id: 'S0340',
          value: 'ターゲットまたは脅威の状況と環境要因を監視するスキル。',
          softSkill: false,
        },
        {
          id: 'S0341',
          value: 'パートナーの能力に対する脅威の影響を監視し、継続的な評価を維持するスキル。',
          softSkill: false,
        },
        {
          id: 'S0342',
          value:
            '繰り返しの調整、テスト、再調整を通じて情報収集システムのパフォーマンスを最適化するスキル。',
          softSkill: false,
        },
        {
          id: 'S0343',
          value: '情報計画チームを統括し、情報収集と生産支援を調整し、状況を監視するスキル。',
          softSkill: true,
        },
        {
          id: 'S0344',
          value:
            '視覚支援またはプレゼンテーション技術の使用を含む報告書、プレゼンテーション、およびブリーフィングを準備して提供するスキル。',
          softSkill: true,
        },
        {
          id: 'S0345',
          value: '情報資源/資産を予想される情報要件に関連付けるスキル。',
          softSkill: false,
        },
        {
          id: 'S0346',
          value: '対立する収集要件を解決するスキル。',
          softSkill: false,
        },
        {
          id: 'S0347',
          value: '収集資産のパフォーマンス仕様と履歴情報を確認するスキル。',
          softSkill: false,
        },
        {
          id: 'S0348',
          value: '近いうちに実施すべき収集やタスクを特定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0349',
          value: '運用評価手順と重要情報要求プロセスを同期させるスキル。',
          softSkill: false,
        },
        {
          id: 'S0350',
          value: '計画活動と必要な情報支援を同期するスキル。',
          softSkill: true,
        },
        {
          id: 'S0351',
          value:
            '有機的、劇場的、国家的、連合的、その他の収集能力の能力、限界、任務遂行方法を翻訳するスキル。',
          softSkill: false,
        },
        {
          id: 'S0352',
          value: '収集任務を行うために共同ツールおよび環境を使用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0353',
          value:
            '収集要件を追跡し、それらが満たされているかどうかを判断するためのシステムやツールを使用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0354',
          value: 'ビジネスの中核となる個人情報保護目標を反映したポリシーを作成するスキル。',
          softSkill: true,
        },
        {
          id: 'S0355',
          value: 'ベンダーとの契約交渉とベンダーの個人情報保護慣行の評価を行うスキル。',
          softSkill: true,
        },
        {
          id: 'S0356',
          value:
            '取締役会メンバーを含むすべてのレベルの管理職とのコミュニケーションスキル（対人スキル、親しみやすさ、効果的なリスニングスキル、聞き手に合ったスタイルや言葉遣いの使用など）。',
          softSkill: true,
        },
        {
          id: 'S0357',
          value: '新しいセキュリティ脅威を予測するスキル。',
          softSkill: false,
        },
        {
          id: 'S0358',
          value: '進化する技術インフラを常に意識するスキル。',
          softSkill: false,
        },
        {
          id: 'S0359',
          value: '組織のパターンや関係を分析するために批判的思考をするスキル。',
          softSkill: true,
        },
        {
          id: 'S0360',
          value: '内外のパートナーのサイバー作戦能力とツールを分析・評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0361',
          value:
            '内外のパートナーのインテリジェンスプロセスを分析・評価し、情報要件と必要情報を策定するスキル。',
          softSkill: false,
        },
        {
          id: 'S0362',
          value:
            '内外のパートナー組織の能力と限界（タスク、収集、処理、搾取、および普及の責任を持つ者）を分析し評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0363',
          value: '内外のパートナーの報告を分析・評価するスキル。',
          softSkill: false,
        },
        {
          id: 'S0364',
          value: '組織の脅威環境の背景についての洞察を深めるスキル。',
          softSkill: false,
        },
        {
          id: 'S0365',
          value: 'クラウドサービスモデルのインシデント対応を設計するスキル。',
          softSkill: false,
        },
        {
          id: 'S0366',
          value:
            'あまり一般的でなく複雑なシステムの問題に対する解決策を見つけるために成功する能力を識別するスキル。',
          softSkill: true,
        },
        {
          id: 'S0367',
          value:
            '組織の要件にサイバーセキュリティとプライバシーの原則（機密性、完全性、可用性、認証、否認防止に関連する）を適用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0368',
          value:
            '組織がサイバーセキュリティリスクを特定、評価、管理するのを支援する方法として、パフォーマンスベースで費用対効果が高いアプローチを判定するために、リスクスコアリングを使用するスキル。',
          softSkill: true,
        },
        {
          id: 'S0369',
          value: '組織のデータ資産のソース、特性、および用途を識別するスキル。',
          softSkill: false,
        },
        {
          id: 'S0370',
          value:
            '自組織内でサイバーディフェンスサービスプロバイダーの報告構造とプロセスを使用するスキル。',
          softSkill: false,
        },
        {
          id: 'S0371',
          value: 'サービスプロバイダーからの脅威共有アラートに対応し、現地対応するスキル。',
          softSkill: true,
        },
        {
          id: 'S0372',
          value: '企業全体の情報ニーズと情報収集要件を翻訳し、追跡し、優先順位をつけるスキル。',
          softSkill: false,
        },
        {
          id: 'S0373',
          value:
            '情報システムおよび情報通信技術のサプライチェーンインフラコンポーネントに対する責任情報を確実に収集するスキル。',
          softSkill: true,
        },
        {
          id: 'S0374',
          value:
            '内外の顧客やパートナー組織とのつながりから生じるサイバーセキュリティおよびプライバシーの問題を特定するスキル。',
          softSkill: true,
        },
      ],
    },
    {
      name: 'abilities',
      items: [
        {
          id: 'A0001',
          value:
            '脆弱性と設定データの分析に基づいて、システム全体のセキュリティの問題を特定する能力。',
          softSkill: false,
        },
        {
          id: 'A0002',
          value: '特定のアプリケーションや環境に適した知識リポジトリ技術を選択する能力。',
          softSkill: false,
        },
        {
          id: 'A0003',
          value: '技術トレンドデータの妥当性を判断する能力。',
          softSkill: false,
        },
        {
          id: 'A0004',
          value: '対象オーディエンスに適したレベルでトピックに対応するカリキュラムを開発する能力。',
          softSkill: true,
        },
        {
          id: 'A0005',
          value: 'デジタルデータコレクションを復号する能力。',
          softSkill: false,
        },
        {
          id: 'A0006',
          value:
            'システム、ネットワークおよびデータ利用者がシステムのセキュリティポリシーおよび手順を理解し遵守するように、教育および啓蒙のブリーフィングを準備し、提供する能力。',
          softSkill: true,
        },
        {
          id: 'A0007',
          value: 'アプリケーション固有の懸念に合わせたコード解析能力。',
          softSkill: false,
        },
        {
          id: 'A0008',
          value:
            '組織のエンタープライズ情報技術（IT）アーキテクチャ（例：オープン・グループ・アーキテクチャ・フレームワーク [TOGAF]、国防総省アーキテクチャ・フレームワーク [DoDAF]、連邦エンタープライズ・アーキテクチャ・フレームワーク [FEAF]）を記述、分析、文書化するための手法、標準、アプローチを適用する能力。',
          softSkill: false,
        },
        {
          id: 'A0009',
          value: 'サプライチェーンリスク管理基準を適用する能力。',
          softSkill: true,
        },
        {
          id: 'A0010',
          value: 'マルウェアを分析する能力。',
          softSkill: false,
        },
        {
          id: 'A0011',
          value: '質問に対して明確かつ簡潔に答える能力。',
          softSkill: true,
        },
        {
          id: 'A0012',
          value: '明確な質問をする能力。',
          softSkill: true,
        },
        {
          id: 'A0013',
          value:
            '複雑な情報、概念、またはアイデアを、口頭、書面および/または視覚的手段を通じ、確信的かつ整然と伝える能力。',
          softSkill: true,
        },
        {
          id: 'A0014',
          value: '記述を通じて効果的に伝える能力。',
          softSkill: true,
        },
        {
          id: 'A0015',
          value: '脆弱性スキャンを実施し、セキュリティシステムの脆弱性を認識する能力。',
          softSkill: false,
        },
        {
          id: 'A0016',
          value: '少人数グループディスカッションを進行する能力。',
          softSkill: true,
        },
        {
          id: 'A0017',
          value: '学習者の理解度と知識レベルを測る能力。',
          softSkill: true,
        },
        {
          id: 'A0018',
          value: 'ブリーフィングの準備と発表の能力。',
          softSkill: true,
        },
        {
          id: 'A0019',
          value: '技術文書を作成する能力。',
          softSkill: true,
        },
        {
          id: 'A0020',
          value: '学習改善のため、効果的なフィードバックを学生に提供する能力。',
          softSkill: true,
        },
        {
          id: 'A0021',
          value: '複雑な数学的概念（例：離散数学）を使用し理解する能力。',
          softSkill: false,
        },
        {
          id: 'A0022',
          value: '大人の学習の原則を適用する能力。',
          softSkill: true,
        },
        {
          id: 'A0023',
          value: '有効で信頼性のある評価を設計する能力。',
          softSkill: false,
        },
        {
          id: 'A0024',
          value: '明確な指示と教材を開発する能力。',
          softSkill: true,
        },
        {
          id: 'A0025',
          value: 'トラブルチケットシステムでインシデント、問題およびイベントを正確に定義する能力。',
          softSkill: false,
        },
        {
          id: 'A0026',
          value: 'テストデータを分析する能力。',
          softSkill: false,
        },
        {
          id: 'A0027',
          value: 'アーキテクチャを開発し維持するために組織の目標と目的を適用する能力。',
          softSkill: false,
        },
        {
          id: 'A0028',
          value: '組織の目標を達成するための人員要件を評価し、予測する能力。',
          softSkill: true,
        },
        {
          id: 'A0029',
          value: '複雑なデータ構造と高級プログラミング言語を構築する能力。',
          softSkill: false,
        },
        {
          id: 'A0030',
          value: 'テストデータを収集・検証し妥当性を確認する能力。',
          softSkill: false,
        },
        {
          id: 'A0031',
          value:
            '政府および業界の能力と適切な価格設定を理解するための市場調査を実施し、適用する能力。',
          softSkill: true,
        },
        {
          id: 'A0032',
          value: '仮想環境内で使用するカリキュラムを開発する能力。',
          softSkill: true,
        },
        {
          id: 'A0033',
          value:
            '組織のサイバー活動をサポートするために、法律、規制、ポリシー、標準に準拠したポリシー、計画、戦略を策定する能力。',
          softSkill: true,
        },
        {
          id: 'A0034',
          value: '標準作業手順（SOPs）を開発、更新および/または維持する能力。',
          softSkill: true,
        },
        {
          id: 'A0035',
          value: '問題を分解し、関連性がないように見えるデータ間の相互関係を調査する能力。',
          softSkill: false,
        },
        {
          id: 'A0036',
          value: '基本的で一般的なコーディングの欠陥を高レベルで特定する能力。',
          softSkill: false,
        },
        {
          id: 'A0037',
          value:
            'サイバー問題に取り組む外部組織や学術機関のベストプラクティスや教訓を活用する能力。',
          softSkill: true,
        },
        {
          id: 'A0038',
          value: 'エンタープライズのパフォーマンス要件を満たすためのシステムを最適化する能力。',
          softSkill: false,
        },
        {
          id: 'A0039',
          value: 'ライフサイクルコスト見積もりの開発と更新を監督する能力。',
          softSkill: true,
        },
        {
          id: 'A0040',
          value: 'データとテスト結果を評価的結論に翻訳する能力。',
          softSkill: false,
        },
        {
          id: 'A0041',
          value:
            'データ可視化ツールの使用能力（例：Flare、HighCharts、AmCharts、D3.js、Processing、Google Visualization API、Tableau、Raphael.js）。',
          softSkill: false,
        },
        {
          id: 'A0042',
          value: 'キャリアパスの機会を開発する能力。',
          softSkill: true,
        },
        {
          id: 'A0043',
          value: 'WindowsおよびUnix/Linux環境でのフォレンジック分析を行う能力。',
          softSkill: false,
        },
        {
          id: 'A0044',
          value: 'プログラミング言語の構造（例：ソースコードレビュー）とロジックを適用する能力。',
          softSkill: false,
        },
        {
          id: 'A0045',
          value: 'サプライヤーおよび/または製品の信頼性を評価/確保する能力。',
          softSkill: true,
        },
        {
          id: 'A0046',
          value:
            '法律、規制および/または政策に新興技術が与える可能性のある影響を監視し評価する能力。',
          softSkill: false,
        },
        {
          id: 'A0047',
          value:
            '安全なソフトウェア展開方法論、ツールおよび実践に従って安全なソフトウェアを開発する能力。',
          softSkill: false,
        },
        {
          id: 'A0048',
          value:
            'トポロジー、プロトコル、コンポーネント、原則（例えば、多層防御の適用）を含むネットワークセキュリティアーキテクチャの概念を適用する能力。',
          softSkill: false,
        },
        {
          id: 'A0049',
          value: '安全なシステム設計ツール、方法、技術を適用する能力。',
          softSkill: false,
        },
        {
          id: 'A0050',
          value:
            '自動化されたシステム分析および設計ツールを含むシステム設計ツール、方法、技術を適用する能力。',
          softSkill: false,
        },
        {
          id: 'A0051',
          value: 'テクノロジー統合プロセスを実行する能力。',
          softSkill: false,
        },
        {
          id: 'A0052',
          value:
            'ネットワーク機器、ハブ、ルーター、スイッチ、ブリッジ、サーバー、伝送媒体および関連ハードウェアの操作能力。',
          softSkill: false,
        },
        {
          id: 'A0053',
          value: '労働力トレンドデータの妥当性を判断する能力。',
          softSkill: true,
        },
        {
          id: 'A0054',
          value: 'インストラクショナルシステムデザイン（ISD）方法論を適用する能力。',
          softSkill: true,
        },
        {
          id: 'A0055',
          value: '一般的なネットワークツール（例：ping、traceroute、nslookup）の操作能力。',
          softSkill: false,
        },
        {
          id: 'A0056',
          value: '取得プロセス全体でセキュリティ対策が遵守される能力。',
          softSkill: true,
        },
        {
          id: 'A0057',
          value: '対象オーディエンスに適したレベルで話題に対応するカリキュラムを調整する能力。',
          softSkill: true,
        },
        {
          id: 'A0058',
          value: 'OSコマンドライン（例：ipconfig、netstat、dir、nbtstat）を実行する能力。',
          softSkill: false,
        },
        {
          id: 'A0059',
          value: '組織のLAN/WANパスウェイを操作する能力。',
          softSkill: false,
        },
        {
          id: 'A0060',
          value: 'アーキテクチャとフレームワークを構築する能力。',
          softSkill: false,
        },
        {
          id: 'A0061',
          value: 'アーキテクチャとフレームワークを設計する能力。',
          softSkill: false,
        },
        {
          id: 'A0062',
          value: 'システムのパフォーマンスと可用性の指標を監視する能力。',
          softSkill: false,
        },
        {
          id: 'A0063',
          value:
            'さまざまな電子通信システムおよび方法（例：電子メール、VOIP、IM、ウェブフォーラム、ダイレクトビデオブロードキャスト）の操作能力。',
          softSkill: false,
        },
        {
          id: 'A0064',
          value: '顧客の要求を運用能力に翻訳・解釈する能力。',
          softSkill: false,
        },
        {
          id: 'A0065',
          value: 'ネットワーク全体のトラフィックフローを監視する能力。',
          softSkill: false,
        },
        {
          id: 'A0066',
          value:
            'インテリジェンス、評価および/または計画製品で使用されるすべてのデータの情報源を正確かつ完全に明らかにする能力。',
          softSkill: false,
        },
        {
          id: 'A0067',
          value: '多様で予測不可能で、挑戦的で、テンポの速い職場環境に適応し、活動する能力。',
          softSkill: true,
        },
        {
          id: 'A0068',
          value: '承認された計画開発と人員配置プロセスを適用する能力。',
          softSkill: true,
        },
        {
          id: 'A0069',
          value: '協力スキルと戦略を適用する能力。',
          softSkill: true,
        },
        {
          id: 'A0070',
          value: '批判的読解・思考スキルを適用する能力。',
          softSkill: true,
        },
        {
          id: 'A0071',
          value: '言語と文化の専門知識を分析に応用する能力。',
          softSkill: false,
        },
        {
          id: 'A0072',
          value:
            'インテリジェンス要件を、調査追跡目的のためのよく構成された研究の疑問とデータ追跡変数として明確に表現する能力。',
          softSkill: true,
        },
        {
          id: 'A0073',
          value:
            'インテリジェンス要件を、よく構成された研究の疑問と情報要求として明確に表現する能力。',
          softSkill: true,
        },
        {
          id: 'A0074',
          value: '他の人と効果的に協力する能力。',
          softSkill: true,
        },
        {
          id: 'A0076',
          value: '監視要件と重要情報の開発についてアナリストと連携し協力する能力。',
          softSkill: true,
        },
        {
          id: 'A0077',
          value: '他の組織機能やサポート活動とのサイバー作戦を調整する能力。',
          softSkill: false,
        },
        {
          id: 'A0078',
          value: '部下、同僚、上位の組織と調整、協力し、情報を広める能力。',
          softSkill: false,
        },
        {
          id: 'A0079',
          value: '各組織または要素を情報収集計画とマトリックスに正しく適用する能力。',
          softSkill: false,
        },
        {
          id: 'A0080',
          value:
            '情報が不完全である、または前例がない問題や状況に対して、分析的アプローチや解決策を開発または提案する能力。',
          softSkill: false,
        },
        {
          id: 'A0081',
          value: '前例のない問題や状況に対して、計画ソリューションを開発または提案する能力。',
          softSkill: false,
        },
        {
          id: 'A0082',
          value: '仮想チームを通じて効果的に協力する能力。',
          softSkill: true,
        },
        {
          id: 'A0083',
          value: '情報の信頼性、妥当性、関連性を評価する能力。',
          softSkill: false,
        },
        {
          id: 'A0084',
          value:
            '（断片的で矛盾している可能性がある）大量のデータを評価、分析、統合し、高品質な融合ターゲティング/インテリジェンス製品にする能力。',
          softSkill: false,
        },
        {
          id: 'A0085',
          value: 'ポリシーが明確に定義されていない場合に判断する能力。',
          softSkill: true,
        },
        {
          id: 'A0086',
          value:
            '関心のある対象を特定するために対象分析と情報収集を行うことによってネットワークアクセスを拡大する能力。',
          softSkill: false,
        },
        {
          id: 'A0087',
          value: '顧客の意思決定ニーズに合わせて研究努力を集中する能力。',
          softSkill: true,
        },
        {
          id: 'A0088',
          value: 'ダイナミックでスピード感のある環境で効果的に機能する能力。',
          softSkill: true,
        },
        {
          id: 'A0089',
          value:
            '協調的な環境で業務を遂行し、組織内外の他のアナリストや専門家と継続的に協議を行い、分析および技術的な専門知識を活用する能力。',
          softSkill: true,
        },
        {
          id: 'A0090',
          value: 'サイバー作戦の共通の関心事を持つ外部パートナーを特定する能力。',
          softSkill: true,
        },
        {
          id: 'A0091',
          value: 'インテリジェンスのギャップを特定する能力。',
          softSkill: false,
        },
        {
          id: 'A0092',
          value: '対象の脆弱性を特定/記述する能力。',
          softSkill: false,
        },
        {
          id: 'A0093',
          value: '対象の技術的情報搾取を行うための技術・方法を特定・記述する能力。',
          softSkill: false,
        },
        {
          id: 'A0094',
          value:
            '組織のサイバー目標に関連する法律、規制、ポリシーおよびガイダンスを解釈し適用する能力。',
          softSkill: true,
        },
        {
          id: 'A0095',
          value: '顧客の要件を解釈し、運用に翻訳する能力。',
          softSkill: false,
        },
        {
          id: 'A0096',
          value: '複雑で急速に進化する概念を解釈し理解する能力。',
          softSkill: true,
        },
        {
          id: 'A0097',
          value:
            'システムの操作を監視し、トリガーやトレンドの観察、または異常な活動に対応してイベントに反応する能力。',
          softSkill: false,
        },
        {
          id: 'A0098',
          value:
            '必要に応じて、企画チーム、調整グループ、タスクフォースのメンバーとして参加する能力。',
          softSkill: true,
        },
        {
          id: 'A0099',
          value: '復号化機能/ツールを含むネットワーク情報収集の戦術、技術、手順を実行する能力。',
          softSkill: false,
        },
        {
          id: 'A0100',
          value: '復号化機能/ツールを含むワイヤレス情報収集手順を実行する能力。',
          softSkill: false,
        },
        {
          id: 'A0101',
          value: '分析に影響を与える可能性がある認知バイアスを認識し軽減する能力。',
          softSkill: true,
        },
        {
          id: 'A0102',
          value: '報告と分析における欺瞞を認識し軽減する能力。',
          softSkill: false,
        },
        {
          id: 'A0103',
          value: '処理された対象言語資料の精度と完全性をレビューする能力。',
          softSkill: false,
        },
        {
          id: 'A0104',
          value: '運用目標を達成するために適切なインプラントを選択する能力。',
          softSkill: false,
        },
        {
          id: 'A0105',
          value: '顧客の理解度に合わせて技術情報および企画情報を調整する能力。',
          softSkill: false,
        },
        {
          id: 'A0106',
          value: '批判的に考える能力。',
          softSkill: true,
        },
        {
          id: 'A0107',
          value: '脅威アクターの立場から考える能力。',
          softSkill: false,
        },
        {
          id: 'A0108',
          value: '目標と効果を理解する能力。',
          softSkill: true,
        },
        {
          id: 'A0109',
          value: 'すべての情報分野を横断して複数の情報源を活用する能力。',
          softSkill: false,
        },
        {
          id: 'A0110',
          value:
            '組織の適応とコンプライアンスを保証するために情報プライバシー法の進展を監視する能力。',
          softSkill: true,
        },
        {
          id: 'A0111',
          value:
            '組織のプライバシー原則とプログラムを実施し、プライバシーの目的とセキュリティの目的を一致させるために、部門や事業部を横断して働く能力。',
          softSkill: true,
        },
        {
          id: 'A0112',
          value:
            '組織の適応とコンプライアンスを保証するために情報プライバシー技術の進歩を監視する能力。',
          softSkill: false,
        },
        {
          id: 'A0113',
          value:
            'セキュリティインシデントが、プライバシー原則または特定の法的処置を必要とする法的基準に違反しているかどうかを判断する能力。',
          softSkill: false,
        },
        {
          id: 'A0114',
          value: '対象に適したレベルのトピックを扱ったカリキュラムを開発または調達する能力。',
          softSkill: true,
        },
        {
          id: 'A0115',
          value:
            '組織のプライバシー原則とプログラムを実施し、プライバシーの目的とセキュリティの目的を一致させるために、部門や事業部を横断して働く能力。',
          softSkill: true,
        },
        {
          id: 'A0116',
          value: '適切かつ効率的にサイバーセキュリティリソースの優先順位を決めて割り当てる能力。',
          softSkill: true,
        },
        {
          id: 'A0117',
          value: '組織力学の文脈と戦略、ビジネス、テクノロジーを関連付ける能力。',
          softSkill: true,
        },
        {
          id: 'A0118',
          value:
            '組織のプロセスと課題解決に関連する技術、管理およびリーダーシップの問題を理解する能力。',
          softSkill: true,
        },
        {
          id: 'A0119',
          value: 'サイバーとその組織への影響に関連する基本的な概念と問題を理解する能力。',
          softSkill: true,
        },
        {
          id: 'A0120',
          value:
            '組織のリスク管理姿勢を改善するために、その脅威環境の背景について有意義な洞察を共有する能力。',
          softSkill: true,
        },
        {
          id: 'A0121',
          value: 'クラウドサービスモデルのインシデント対応を設計する能力。',
          softSkill: false,
        },
        {
          id: 'A0122',
          value:
            'より一般的でなく、より複雑なシステムの課題に対する解決策を見つける機能を設計する能力。',
          softSkill: false,
        },
        {
          id: 'A0123',
          value:
            '組織の要件（機密性、完全性、可用性、認証、否認防止に関連）にサイバーセキュリティとプライバシーの原則を適用する能力。',
          softSkill: false,
        },
        {
          id: 'A0124',
          value: '自動化されたセキュリティコントロール評価を確立し維持する能力。',
          softSkill: true,
        },
        {
          id: 'A0125',
          value: '現行法に基づいたプライバシー開示声明を作成する能力。',
          softSkill: true,
        },
        {
          id: 'A0126',
          value:
            '部門、拠点、施設、あるいは潜在的にサポートするビジネス機能にまたがるネットワークデバイスとソフトウェアの位置と構成を追跡する能力。',
          softSkill: true,
        },
        {
          id: 'A0127',
          value: '継続的な監視技術とツールを展開する能力。',
          softSkill: true,
        },
        {
          id: 'A0128',
          value:
            '侵入検知技術を使用してホストおよびネットワークベースの侵入を検出する技術を適用する能力。',
          softSkill: false,
        },
        {
          id: 'A0129',
          value:
            '情報セキュリティ管理プロセスが戦略的および運用計画のプロセスと統合されていることを保証する能力。',
          softSkill: true,
        },
        {
          id: 'A0130',
          value:
            '組織内の上級職員が、その管理下にある業務と資産を支える情報とシステムに対する情報セキュリティの提供を保証する能力。',
          softSkill: false,
        },
        {
          id: 'A0131',
          value:
            '法律、行政命令、ポリシー、指令、指示、標準およびガイドラインのセキュリティ要件を遵守するのを支援するために、適切に訓練された人員を組織に確保する能力。',
          softSkill: true,
        },
        {
          id: 'A0132',
          value:
            'リスクに対処するための包括的で組織全体にわたる全体論的なアプローチ、つまり組織の統合的な運営の理解を深めるためのアプローチを提供するために組織の上級リーダーシップと連携する能力。',
          softSkill: true,
        },
        {
          id: 'A0133',
          value:
            '組織のセキュリティ関連リスクに対する戦略的な視点を提供するリスク管理戦略を策定するために組織の上級リーダーシップと連携する能力。',
          softSkill: true,
        },
        {
          id: 'A0134',
          value:
            '認可担当者および組織内の他の上級リーダー間でリスク関連情報の共有を促進するために組織の上級リーダーシップと連携する能力。',
          softSkill: true,
        },
        {
          id: 'A0135',
          value:
            '一貫性のある効果的なリスク受容決定を支援するために、組織全体のリスク管理関連活動の監視を提供するために組織の上級リーダーシップと連携する能力。',
          softSkill: true,
        },
        {
          id: 'A0136',
          value:
            'ミッションとビジネスの成功に必要なすべての要因を考慮して認可決定が行われるように組織の上級リーダーシップと連携する能力。',
          softSkill: true,
        },
        {
          id: 'A0137',
          value:
            '組織の運営や資産、個人、他の組織、国家に対するすべてのリスク源（集約されたリスクを含む）を考慮するための組織全体のフォーラムを提供するために組織の上級リーダーシップと連携する能力。',
          softSkill: true,
        },
        {
          id: 'A0138',
          value:
            '共有の責任を必要とする認可作業を含む、認可担当者間の協力と協調を促進するために組織の上級リーダーシップと連携する能力。',
          softSkill: true,
        },
        {
          id: 'A0139',
          value:
            'システム、サービスおよびアプリケーションの外部プロバイダーを利用して組織のミッション/ビジネス機能をサポートするための共有責任が、必要な可視性を得て適切な意思決定権限に引き上げられるように、組織の上級リーダーシップと連携する能力。',
          softSkill: true,
        },
        {
          id: 'A0140',
          value:
            '組織が責任を負うシステムの運用と使用から集約されたリスクに基づいて、組織のリスクに対する姿勢を特定するために、組織の上級リーダーシップと連携する能力。',
          softSkill: true,
        },
        {
          id: 'A0141',
          value:
            '組織全体のセキュリティプログラムが効果的に実施され、すべての組織システムおよび運用環境に対して適切なセキュリティが確保されるように、承認者およびその指定代理人者と密接に連携する能力。',
          softSkill: true,
        },
        {
          id: 'A0142',
          value:
            'セキュリティへの配慮がプログラミング/計画/予算編成サイクル、エンタープライズアーキテクチャおよび取得/システム開発ライフサイクルに確実に組み込まれるように、承認者およびその指定代理人と密接に連携する能力。',
          softSkill: true,
        },
        {
          id: 'A0143',
          value:
            '組織システムおよび共通の管理策が、承認されたセキュリティ計画によってカバーされ、現行の認証を持つことを確実にするために、承認者およびその指定代理人と密接に協力する能力。',
          softSkill: true,
        },
        {
          id: 'A0144',
          value:
            '組織全体で必要とされるセキュリティ関連の活動が、効率的で、費用対効果が高く、かつ迅速に達成されるように、承認者およびその指定代理人と密接に連携する能力。',
          softSkill: true,
        },
        {
          id: 'A0145',
          value:
            'セキュリティ関連活動の一元的な報告が確実に行われるように、承認者およびその指定代理人と密接に連携する能力。',
          softSkill: true,
        },
        {
          id: 'A0146',
          value:
            '情報が他の組織と共有あるいは提供された場合でも責任を保持するよう、情報の適切な使用と保護のためのルールを確立する能力。',
          softSkill: true,
        },
        {
          id: 'A0147',
          value:
            'セキュリティ計画、合意覚書または理解覚書、行動計画やマイルストーンを承認し、システムや運用環境の重要な変更が再認証を必要とするかどうかを判断する能力。',
          softSkill: true,
        },
        {
          id: 'A0148',
          value:
            'エンタープライズアーキテクトとシステムセキュリティエンジニアの間の主要な連絡役となり、システム特有の、ハイブリッドまたは共通コントロールとしてのセキュリティコントロールの割り当てについて、システム所有者、共通コントロールプロバイダ、システムセキュリティ担当者と連携する能力。',
          softSkill: true,
        },
        {
          id: 'A0149',
          value:
            'システムセキュリティオフィサーと密接に連携して、承認者、最高情報責任者、上級情報セキュリティオフィサー、リスク管理／リスクエグゼクティブ（機能）の上級責任者に対して、セキュリティに関連する様々な問題（システムの境界の設定、システムの弱点や欠陥の重大性の評価、行動計画とマイルストーン、リスク緩和アプローチ、セキュリティ警告、特定された脆弱性の潜在的な悪影響など）について助言することができる能力。',
          softSkill: true,
        },
        {
          id: 'A0150',
          value: 'システムセキュリティエンジニアリング活動を行う能力（NIST SP 800-16）。',
          softSkill: true,
        },
        {
          id: 'A0151',
          value:
            'セキュリティ要件を把握し洗練させて、意図的なセキュリティ構造、設計、開発、構成を通じて要件がコンポーネント製品およびシステムに効果的に統合されるようにする能力。',
          softSkill: true,
        },
        {
          id: 'A0152',
          value:
            'ソフトウェアエンジニアリング方法論、システムおよびセキュリティエンジニアリングの原則、セキュアデザイン、セキュアアーキテクチャ、およびセキュアコーディング技術などを含むシステム内のセキュリティコントロールを実装する際に、ベストプラクティスを適用する能力。',
          softSkill: true,
        },
        {
          id: 'A0153',
          value:
            'セキュリティアーキテクト、シニア情報セキュリティオフィサー、システムオーナー、共通コントロールプロバイダー、およびシステムセキュリティオフィサーとのセキュリティ関連活動を連携する能力。',
          softSkill: true,
        },
        {
          id: 'A0154',
          value:
            'セキュリティコントロールの有効性（すなわち、どの程度セキュリティコントロールが正しく実装され、意図した通りに機能し、システムのセキュリティ要件を満たすための望ましい結果を生み出しているか）を判断するために、システムに採用あるいは継承されている、管理、運用、技術的なセキュリティコントロールおよびコントロールの強化を包括的に評価する能力。',
          softSkill: true,
        },
        {
          id: 'A0155',
          value:
            'システムおよびその運用環境で発見された弱点や不備の重大度を評価し、特定された脆弱性に対処するための是正措置を推奨する能力。',
          softSkill: true,
        },
        {
          id: 'A0156',
          value: '評価結果と所見を含む最終的なセキュリティ評価報告書を作成する能力。',
          softSkill: true,
        },
        {
          id: 'A0157',
          value:
            '明示されたセキュリティ要件を満たす一連のセキュリティ管理を、セキュリティプランがシステムに提供していることを確認するのに役立つ、セキュリティプランを評価する能力。',
          softSkill: true,
        },
        {
          id: 'A0158',
          value:
            '契約で機能要件およびセキュリティ要件が適切に扱われ、契約者が契約に記載された機能要件およびセキュリティ要件を満たしていることを確認する能力。',
          softSkill: true,
        },
        {
          id: 'A0159',
          value:
            'ネットワークツール（例：Nslookup、Ping、Traceroute）によって収集された情報を解釈する能力。',
          softSkill: false,
        },
        {
          id: 'A0160',
          value:
            '企業ネットワーク全体の情報ニーズとインテリジェンス収集要件を翻訳、追跡、優先順位付けする能力。',
          softSkill: false,
        },
        {
          id: 'A0161',
          value:
            '適用可能な基本的なセキュリティ管理をセキュリティ要件の情報源の一つとして使用する、堅牢なソフトウェア品質管理プロセスを確保する、そして、複数の調達元（例えば、重要なシステム要素の配送ルート）を確立するといった、情報セキュリティ要件を調達プロセスに統合する能力。',
          softSkill: true,
        },
        {
          id: 'A0162',
          value:
            '情報システムセキュリティ、調達担当者、法律顧問、その他の適切なアドバイザーやステークホルダーがシステムコンセプトの定義/レビューから意思決定に参加し、システムのライフサイクル全体を通じて各マイルストーンの決定に関与または承認していることを確認する能力。',
          softSkill: true,
        },
        {
          id: 'A0163',
          value: '通信セキュリティ（COMSEC）の用語、ガイドライン、手順を解釈する能力。',
          softSkill: true,
        },
        {
          id: 'A0164',
          value: '指定された通信セキュリティ（COMSEC）担当者の役割と責任を識別する能力。',
          softSkill: true,
        },
        {
          id: 'A0165',
          value: '通信セキュリティ（COMSEC）機器のアカウント、制御、使用手順を管理する能力。',
          softSkill: true,
        },
        {
          id: 'A0166',
          value: '通信セキュリティ（COMSEC）インシデントの種類と報告方法を特定する能力。',
          softSkill: false,
        },
        {
          id: 'A0167',
          value: '通信セキュリティ（COMSEC）機器およびアカウントの監査の重要性を認識する能力。',
          softSkill: true,
        },
        {
          id: 'A0168',
          value: '通信セキュリティ（COMSEC）の処理中アカウントの要件を特定する能力。',
          softSkill: true,
        },
        {
          id: 'A0170',
          value:
            'システムセキュリティへの考慮なしに設計された情報通信技術を用いる重要インフラシステムを識別する能力。',
          softSkill: false,
        },
        {
          id: 'A0171',
          value: '訓練と教育の必要性評価を行う能力。',
          softSkill: true,
        },
        {
          id: 'A0172',
          value:
            '内部ローカルエリアネットワーク（LAN）を他の信頼できないネットワークから分離する物理的または論理的サブネットワークを設定する能力。',
          softSkill: false,
        },
        {
          id: 'A0173',
          value:
            'システムや環境の変更がリスク許容度に対する残存リスクを変更する可能性を認識する能力。',
          softSkill: true,
        },
        {
          id: 'A0174',
          value:
            'マーケットやフォーラムを見つけるためにTORネットワークを使用してダークウェブを検索しナビゲートする能力。',
          softSkill: false,
        },
        {
          id: 'A0175',
          value: '複数のオペレーティングシステムプラットフォームでデジタルメディアを調査する能力。',
          softSkill: false,
        },
        {
          id: 'A0176',
          value:
            'データベースを維持する能力（例：バックアップ、復元、データ削除、トランザクションログファイルなど）。',
          softSkill: false,
        },
        {
          id: 'A0177',
          value: 'コミュニケーションセキュリティ（COMSEC）環境と階層の特有の側面を認識する能力。',
          softSkill: true,
        },
      ],
    },
  ],
};

export interface WorkRole {
  id: string;
  name: string;
  specialityArea: string;
  category: string;
  description: string;
  tasks: string[];
  knowledge: string[];
  skills: string[];
  abilities: string[];
}

export const NiceWorkRoles: WorkRole[] = [
  {
    id: 'SP-RSK-001',
    name: '許可責任者／指定代表者',
    specialityArea: 'リスク管理 (RSK)',
    category: 'セキュアな供給 (SP)',
    description:
      '組織の運営（ミッション、機能、イメージ、または評判を含む）、組織の資産、個人、他の組織、および国（CNSSI 4009）に対する受け入れ可能なリスクレベルで情報システムの運用を正式に引き受ける権限を持つ高級職員または幹部です。',
    tasks: ['T0145', 'T0221', 'T0371', 'T0495'],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0013',
      'K0019',
      'K0027',
      'K0028',
      'K0037',
      'K0038',
      'K0040',
      'K0044',
      'K0048',
      'K0049',
      'K0054',
      'K0059',
      'K0070',
      'K0084',
      'K0089',
      'K0101',
      'K0126',
      'K0146',
      'K0168',
      'K0169',
      'K0170',
      'K0179',
      'K0199',
      'K0203',
      'K0260',
      'K0261',
      'K0262',
      'K0267',
      'K0295',
      'K0322',
      'K0342',
      'K0622',
      'K0624',
    ],
    skills: ['S0034', 'S0367'],
    abilities: [
      'A0028',
      'A0033',
      'A0077',
      'A0090',
      'A0094',
      'A0111',
      'A0117',
      'A0118',
      'A0119',
      'A0123',
      'A0170',
    ],
  },
  {
    id: 'SP-RSK-002',
    name: 'セキュリティコントロールアセッサー',
    specialityArea: 'リスク管理 (RSK)',
    category: 'セキュアな供給 (SP)',
    description:
      '情報技術（IT）システムによって採用あるいは継承される管理、運用、および技術的セキュリティコントロールおよびコントロールの強化に対して、独立した包括的な評価を実施し、コントロールの全体的な有効性（NIST SP 800-37で定義されているように）を判断します。',
    tasks: [
      'T0145',
      'T0177',
      'T0178',
      'T0181',
      'T0184',
      'T0205',
      'T0221',
      'T0243',
      'T0244',
      'T0251',
      'T0255',
      'T0264',
      'T0265',
      'T0268',
      'T0272',
      'T0275',
      'T0277',
      'T0309',
      'T0344',
      'T0371',
      'T0495',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0007',
      'K0008',
      'K0009',
      'K0010',
      'K0011',
      'K0013',
      'K0018',
      'K0019',
      'K0021',
      'K0024',
      'K0026',
      'K0027',
      'K0028',
      'K0029',
      'K0037',
      'K0038',
      'K0040',
      'K0044',
      'K0048',
      'K0049',
      'K0054',
      'K0056',
      'K0059',
      'K0070',
      'K0084',
      'K0089',
      'K0098',
      'K0100',
      'K0101',
      'K0126',
      'K0146',
      'K0168',
      'K0169',
      'K0170',
      'K0179',
      'K0199',
      'K0203',
      'K0260',
      'K0261',
      'K0262',
      'K0267',
      'K0287',
      'K0322',
      'K0342',
      'K0622',
      'K0624',
    ],
    skills: [
      'S0001',
      'S0006',
      'S0027',
      'S0034',
      'S0038',
      'S0073',
      'S0078',
      'S0097',
      'S0100',
      'S0110',
      'S0111',
      'S0112',
      'S0115',
      'S0120',
      'S0124',
      'S0128',
      'S0134',
      'S0135',
      'S0136',
      'S0137',
      'S0138',
      'S0141',
      'S0145',
      'S0147',
      'S0171',
      'S0172',
      'S0173',
      'S0174',
      'S0175',
      'S0176',
      'S0177',
      'S0184',
      'S0232',
      'S0233',
      'S0234',
      'S0235',
      'S0236',
      'S0237',
      'S0238',
      'S0239',
      'S0240',
      'S0241',
      'S0242',
      'S0243',
      'S0244',
      'S0248',
      'S0249',
      'S0250',
      'S0251',
      'S0252',
      'S0254',
      'S0271',
      'S0273',
      'S0278',
      'S0279',
      'S0280',
      'S0281',
      'S0296',
      'S0304',
      'S0305',
      'S0306',
      'S0307',
      'S0325',
      'S0329',
      'S0332',
      'S0367',
      'S0370',
      'S0374',
    ],
    abilities: [
      'A0001',
      'A0011',
      'A0012',
      'A0013',
      'A0014',
      'A0015',
      'A0016',
      'A0018',
      'A0019',
      'A0023',
      'A0026',
      'A0030',
      'A0035',
      'A0036',
      'A0040',
      'A0056',
      'A0069',
      'A0070',
      'A0082',
      'A0083',
      'A0084',
      'A0085',
      'A0086',
      'A0087',
      'A0088',
      'A0089',
      'A0090',
      'A0091',
      'A0092',
      'A0093',
      'A0094',
      'A0095',
      'A0096',
      'A0098',
      'A0101',
      'A0106',
      'A0108',
      'A0109',
      'A0111',
      'A0112',
      'A0114',
      'A0115',
      'A0116',
      'A0117',
      'A0118',
      'A0119',
      'A0123',
      'A0170',
    ],
  },
  {
    id: 'SP-DEV-001',
    name: 'ソフトウェア開発者',
    specialityArea: 'ソフトウェア開発 (DEV)',
    category: 'セキュアな供給 (SP)',
    description:
      '新規または既存のコンピュータアプリケーション、ソフトウェア、または特殊ユーティリティプログラムを開発、作成、メンテナンス、修正あるいはコーディングします。',
    tasks: [
      'T0009',
      'T0011',
      'T0013',
      'T0014',
      'T0022',
      'T0026',
      'T0034',
      'T0040',
      'T0046',
      'T0057',
      'T0077',
      'T0100',
      'T0111',
      'T0117',
      'T0118',
      'T0171',
      'T0176',
      'T0181',
      'T0189',
      'T0217',
      'T0228',
      'T0236',
      'T0267',
      'T0303',
      'T0311',
      'T0324',
      'T0337',
      'T0416',
      'T0417',
      'T0436',
      'T0455',
      'T0500',
      'T0553',
      'T0554',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0014',
      'K0016',
      'K0027',
      'K0028',
      'K0039',
      'K0044',
      'K0050',
      'K0051',
      'K0060',
      'K0066',
      'K0068',
      'K0070',
      'K0073',
      'K0079',
      'K0080',
      'K0081',
      'K0082',
      'K0084',
      'K0086',
      'K0105',
      'K0139',
      'K0140',
      'K0152',
      'K0153',
      'K0154',
      'K0170',
      'K0179',
      'K0199',
      'K0202',
      'K0260',
      'K0261',
      'K0262',
      'K0263',
      'K0322',
      'K0332',
      'K0342',
      'K0343',
      'K0624',
    ],
    skills: [
      'S0001',
      'S0014',
      'S0017',
      'S0019',
      'S0022',
      'S0031',
      'S0034',
      'S0060',
      'S0135',
      'S0138',
      'S0149',
      'S0174',
      'S0175',
      'S0367',
    ],
    abilities: ['A0007', 'A0021', 'A0047', 'A0123', 'A0170'],
  },
  {
    id: 'SP-DEV-002',
    name: 'セキュアソフトウェアアセッサー',
    specialityArea: 'ソフトウェア開発 (DEV)',
    category: 'セキュアな供給 (SP)',
    description:
      '新規または既存のコンピュータアプリケーション、ソフトウェア、または特殊なユーティリティプログラムのセキュリティを分析し、実行可能な結果を提供します。',
    tasks: [
      'T0013',
      'T0014',
      'T0022',
      'T0038',
      'T0040',
      'T0100',
      'T0111',
      'T0117',
      'T0118',
      'T0171',
      'T0181',
      'T0217',
      'T0228',
      'T0236',
      'T0266',
      'T0311',
      'T0324',
      'T0337',
      'T0424',
      'T0428',
      'T0436',
      'T0456',
      'T0457',
      'T0516',
      'T0554',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0014',
      'K0016',
      'K0027',
      'K0028',
      'K0039',
      'K0044',
      'K0050',
      'K0051',
      'K0060',
      'K0066',
      'K0068',
      'K0070',
      'K0073',
      'K0079',
      'K0080',
      'K0081',
      'K0082',
      'K0084',
      'K0086',
      'K0105',
      'K0139',
      'K0140',
      'K0152',
      'K0153',
      'K0154',
      'K0170',
      'K0178',
      'K0179',
      'K0199',
      'K0202',
      'K0260',
      'K0261',
      'K0262',
      'K0263',
      'K0322',
      'K0342',
      'K0343',
      'K0624',
    ],
    skills: [
      'S0001',
      'S0022',
      'S0031',
      'S0034',
      'S0083',
      'S0135',
      'S0138',
      'S0174',
      'S0175',
      'S0367',
    ],
    abilities: ['A0021', 'A0123', 'A0170'],
  },
  {
    id: 'SP-ARC-001',
    name: 'エンタープライズアーキテクト',
    specialityArea: 'システムアーキテクチャ (ARC)',
    category: 'セキュアな供給 (SP)',
    description:
      '企業のミッションニーズをサポートするためのビジネス、システム、および情報プロセスを開発・維持します。ベースラインとターゲットアーキテクチャを説明する情報技術（IT）ルールおよび要件を開発します。',
    tasks: [
      'T0051',
      'T0084',
      'T0090',
      'T0108',
      'T0196',
      'T0205',
      'T0307',
      'T0314',
      'T0328',
      'T0338',
      'T0427',
      'T0440',
      'T0448',
      'T0473',
      'T0517',
      'T0521',
      'T0542',
      'T0555',
      'T0557',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0024',
      'K0027',
      'K0028',
      'K0030',
      'K0035',
      'K0037',
      'K0043',
      'K0044',
      'K0052',
      'K0056',
      'K0060',
      'K0061',
      'K0063',
      'K0074',
      'K0075',
      'K0082',
      'K0091',
      'K0093',
      'K0102',
      'K0170',
      'K0179',
      'K0180',
      'K0198',
      'K0200',
      'K0203',
      'K0207',
      'K0211',
      'K0212',
      'K0214',
      'K0227',
      'K0240',
      'K0264',
      'K0275',
      'K0286',
      'K0287',
      'K0291',
      'K0293',
      'K0299',
      'K0322',
      'K0323',
      'K0325',
      'K0326',
      'K0332',
      'K0333',
      'K0487',
      'K0516',
    ],
    skills: ['S0005', 'S0024', 'S0027', 'S0050', 'S0060', 'S0122', 'S0367', 'S0374'],
    abilities: ['A0008', 'A0015', 'A0027', 'A0038', 'A0051', 'A0060', 'A0123', 'A0170', 'A0172'],
  },
  {
    id: 'SP-ARC-002',
    name: 'セキュリティアーキテクト',
    specialityArea: 'システムアーキテクチャ (ARC)',
    category: 'セキュアな供給 (SP)',
    description:
      '組織の使命とビジネスプロセスを保護するために必要な利害関係者のセキュリティ要件が、エンタープライズアーキテクチャのあらゆる側面（参照モデル、セグメントおよびソリューションアーキテクチャ、およびそれらの使命とビジネスプロセスをサポートする結果として生じるシステム）において適切に対処されていることを保証します。',
    tasks: [
      'T0050',
      'T0051',
      'T0071',
      'T0082',
      'T0084',
      'T0090',
      'T0108',
      'T0177',
      'T0196',
      'T0203',
      'T0205',
      'T0268',
      'T0307',
      'T0314',
      'T0328',
      'T0338',
      'T0427',
      'T0448',
      'T0473',
      'T0484',
      'T0542',
      'T0556',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0007',
      'K0008',
      'K0009',
      'K0010',
      'K0011',
      'K0012',
      'K0013',
      'K0015',
      'K0018',
      'K0019',
      'K0024',
      'K0026',
      'K0027',
      'K0030',
      'K0035',
      'K0036',
      'K0037',
      'K0043',
      'K0044',
      'K0052',
      'K0055',
      'K0056',
      'K0057',
      'K0059',
      'K0060',
      'K0061',
      'K0063',
      'K0071',
      'K0074',
      'K0082',
      'K0091',
      'K0092',
      'K0093',
      'K0102',
      'K0170',
      'K0180',
      'K0198',
      'K0200',
      'K0202',
      'K0211',
      'K0212',
      'K0214',
      'K0227',
      'K0240',
      'K0260',
      'K0261',
      'K0262',
      'K0264',
      'K0275',
      'K0277',
      'K0286',
      'K0287',
      'K0291',
      'K0293',
      'K0320',
      'K0322',
      'K0323',
      'K0325',
      'K0326',
      'K0332',
      'K0333',
      'K0336',
      'K0374',
      'K0565',
    ],
    skills: [
      'S0005',
      'S0022',
      'S0024',
      'S0027',
      'S0050',
      'S0059',
      'S0061',
      'S0076',
      'S0116',
      'S0122',
      'S0138',
      'S0139',
      'S0152',
      'S0168',
      'S0170',
      'S0367',
      'S0374',
    ],
    abilities: [
      'A0008',
      'A0014',
      'A0015',
      'A0027',
      'A0038',
      'A0048',
      'A0049',
      'A0050',
      'A0061',
      'A0123',
      'A0148',
      'A0149',
      'A0170',
      'A0172',
    ],
  },
  {
    id: 'SP-TRD-001',
    name: '研究開発スペシャリスト',
    specialityArea: '技術研究開発 (TRD)',
    category: 'セキュアな供給 (SP)',
    description:
      'ソフトウェアおよびシステムエンジニアリング、ソフトウェアシステムの研究を行い、サイバーセキュリティが完全に統合されたことを保証する新しい機能を開発します。サイバースペースシステムの潜在的な脆弱性を評価するために、包括的な技術研究を行います。',
    tasks: [
      'T0064',
      'T0249',
      'T0250',
      'T0283',
      'T0284',
      'T0327',
      'T0329',
      'T0409',
      'T0410',
      'T0411',
      'T0413',
      'T0547',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0009',
      'K0019',
      'K0059',
      'K0090',
      'K0126',
      'K0169',
      'K0170',
      'K0171',
      'K0172',
      'K0174',
      'K0175',
      'K0176',
      'K0179',
      'K0202',
      'K0209',
      'K0267',
      'K0268',
      'K0269',
      'K0271',
      'K0272',
      'K0288',
      'K0296',
      'K0310',
      'K0314',
      'K0321',
      'K0342',
      'K0499',
    ],
    skills: ['S0005', 'S0017', 'S0072', 'S0140', 'S0148', 'S0172'],
    abilities: ['A0001', 'A0018', 'A0019', 'A0170'],
  },
  {
    id: 'SP-SRP-001',
    name: 'システム要件プランナー',
    specialityArea: 'システム要件計画 (SRP)',
    category: 'セキュアな供給 (SP)',
    description: '顧客と相談し、機能要件を評価してそれを技術的な解決策に変換します。',
    tasks: [
      'T0033',
      'T0039',
      'T0045',
      'T0052',
      'T0062',
      'T0127',
      'T0156',
      'T0174',
      'T0191',
      'T0235',
      'T0273',
      'T0300',
      'T0313',
      'T0325',
      'T0334',
      'T0454',
      'T0463',
      'T0497',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0008',
      'K0012',
      'K0018',
      'K0019',
      'K0032',
      'K0035',
      'K0038',
      'K0043',
      'K0044',
      'K0045',
      'K0047',
      'K0055',
      'K0056',
      'K0059',
      'K0060',
      'K0061',
      'K0063',
      'K0066',
      'K0067',
      'K0073',
      'K0074',
      'K0086',
      'K0087',
      'K0090',
      'K0091',
      'K0093',
      'K0101',
      'K0102',
      'K0126',
      'K0163',
      'K0164',
      'K0168',
      'K0169',
      'K0170',
      'K0180',
      'K0200',
      'K0267',
      'K0287',
      'K0325',
      'K0332',
      'K0333',
      'K0622',
    ],
    skills: ['S0005', 'S0006', 'S0008', 'S0010', 'S0050', 'S0134', 'S0367'],
    abilities: ['A0064', 'A0123', 'A0170'],
  },
  {
    id: 'SP-TST-001',
    name: 'システムテストおよび評価スペシャリスト',
    specialityArea: 'テストと評価 (TST)',
    category: 'セキュアな供給 (SP)',
    description:
      'システムのテストを計画、準備、実行して、仕様および要件に照らして結果を評価するとともにテスト結果を分析/報告します。',
    tasks: [
      'T0058',
      'T0080',
      'T0125',
      'T0143',
      'T0257',
      'T0274',
      'T0393',
      'T0426',
      'T0511',
      'T0512',
      'T0513',
      'T0539',
      'T0540',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0027',
      'K0028',
      'K0037',
      'K0044',
      'K0057',
      'K0088',
      'K0091',
      'K0102',
      'K0126',
      'K0139',
      'K0169',
      'K0170',
      'K0179',
      'K0199',
      'K0203',
      'K0212',
      'K0250',
      'K0260',
      'K0261',
      'K0262',
      'K0287',
      'K0332',
    ],
    skills: [
      'S0015',
      'S0021',
      'S0026',
      'S0030',
      'S0048',
      'S0060',
      'S0061',
      'S0082',
      'S0104',
      'S0107',
      'S0110',
      'S0112',
      'S0115',
      'S0117',
      'S0367',
    ],
    abilities: ['A0026', 'A0030', 'A0040', 'A0123'],
  },
  {
    id: 'SP-SYS-001',
    name: '情報システムセキュリティ開発者',
    specialityArea: 'システム開発 (SYS)',
    category: 'セキュアな供給 (SP)',
    description:
      'システム開発ライフサイクルを通じて、情報システムのセキュリティを設計、開発、テスト、評価を行います。',
    tasks: [
      'T0012',
      'T0015',
      'T0018',
      'T0019',
      'T0021',
      'T0032',
      'T0053',
      'T0055',
      'T0056',
      'T0061',
      'T0069',
      'T0070',
      'T0076',
      'T0078',
      'T0105',
      'T0107',
      'T0109',
      'T0119',
      'T0122',
      'T0124',
      'T0181',
      'T0201',
      'T0205',
      'T0228',
      'T0231',
      'T0242',
      'T0269',
      'T0270',
      'T0271',
      'T0272',
      'T0304',
      'T0326',
      'T0359',
      'T0446',
      'T0449',
      'T0466',
      'T0509',
      'T0518',
      'T0527',
      'T0541',
      'T0544',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0015',
      'K0018',
      'K0024',
      'K0027',
      'K0028',
      'K0030',
      'K0032',
      'K0035',
      'K0036',
      'K0044',
      'K0045',
      'K0049',
      'K0050',
      'K0052',
      'K0055',
      'K0056',
      'K0060',
      'K0061',
      'K0063',
      'K0065',
      'K0066',
      'K0067',
      'K0073',
      'K0081',
      'K0082',
      'K0084',
      'K0086',
      'K0087',
      'K0090',
      'K0091',
      'K0093',
      'K0102',
      'K0126',
      'K0139',
      'K0169',
      'K0170',
      'K0179',
      'K0180',
      'K0200',
      'K0203',
      'K0260',
      'K0261',
      'K0262',
      'K0276',
      'K0287',
      'K0297',
      'K0308',
      'K0322',
      'K0325',
      'K0332',
      'K0333',
      'K0336',
    ],
    skills: [
      'S0001',
      'S0022',
      'S0023',
      'S0024',
      'S0031',
      'S0034',
      'S0036',
      'S0085',
      'S0145',
      'S0160',
      'S0367',
    ],
    abilities: [
      'A0001',
      'A0008',
      'A0012',
      'A0013',
      'A0015',
      'A0019',
      'A0026',
      'A0040',
      'A0048',
      'A0049',
      'A0050',
      'A0056',
      'A0061',
      'A0074',
      'A0089',
      'A0098',
      'A0108',
      'A0119',
      'A0123',
      'A0170',
    ],
  },
  {
    id: 'SP-SYS-002',
    name: 'システム開発者',
    specialityArea: 'システム開発 (SYS)',
    category: 'セキュアな供給 (SP)',
    description:
      'システム開発ライフサイクルを通じて、情報システムの設計、開発、テスト、評価を行います。',
    tasks: [
      'T0012',
      'T0021',
      'T0053',
      'T0056',
      'T0061',
      'T0067',
      'T0070',
      'T0107',
      'T0109',
      'T0119',
      'T0181',
      'T0201',
      'T0205',
      'T0228',
      'T0242',
      'T0304',
      'T0326',
      'T0350',
      'T0358',
      'T0359',
      'T0378',
      'T0406',
      'T0447',
      'T0449',
      'T0464',
      'T0466',
      'T0480',
      'T0488',
      'T0518',
      'T0528',
      'T0538',
      'T0541',
      'T0544',
      'T0558',
      'T0559',
      'T0560',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0015',
      'K0018',
      'K0024',
      'K0027',
      'K0028',
      'K0030',
      'K0032',
      'K0035',
      'K0036',
      'K0044',
      'K0045',
      'K0049',
      'K0050',
      'K0052',
      'K0055',
      'K0056',
      'K0060',
      'K0061',
      'K0063',
      'K0065',
      'K0066',
      'K0067',
      'K0073',
      'K0081',
      'K0082',
      'K0084',
      'K0086',
      'K0087',
      'K0090',
      'K0091',
      'K0093',
      'K0102',
      'K0126',
      'K0139',
      'K0169',
      'K0170',
      'K0179',
      'K0180',
      'K0200',
      'K0203',
      'K0207',
      'K0212',
      'K0227',
      'K0260',
      'K0261',
      'K0262',
      'K0276',
      'K0287',
      'K0297',
      'K0308',
      'K0322',
      'K0325',
      'K0332',
      'K0333',
      'K0336',
    ],
    skills: [
      'S0018',
      'S0022',
      'S0023',
      'S0024',
      'S0025',
      'S0031',
      'S0034',
      'S0036',
      'S0060',
      'S0085',
      'S0097',
      'S0136',
      'S0145',
      'S0146',
      'S0160',
      'S0367',
    ],
    abilities: ['A0123', 'A0170'],
  },
  {
    id: 'OM-DTA-001',
    name: 'データベース管理者',
    specialityArea: 'データ管理 (DTA)',
    category: '運用および保守 (OM)',
    description:
      'データベースおよび/またはデータ管理システムを管理し、データの安全な保存、クエリ、保護、および利用を可能にします。',
    tasks: [
      'T0008',
      'T0137',
      'T0139',
      'T0140',
      'T0146',
      'T0152',
      'T0162',
      'T0210',
      'T0305',
      'T0306',
      'T0330',
      'T0422',
      'T0459',
      'T0490',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0020',
      'K0021',
      'K0022',
      'K0023',
      'K0025',
      'K0031',
      'K0056',
      'K0060',
      'K0065',
      'K0069',
      'K0083',
      'K0097',
      'K0197',
      'K0260',
      'K0261',
      'K0262',
      'K0277',
      'K0278',
      'K0287',
      'K0420',
    ],
    skills: ['S0002', 'S0013', 'S0037', 'S0042', 'S0045'],
    abilities: ['A0176'],
  },
  {
    id: 'OM-DTA-002',
    name: 'データアナリスト',
    specialityArea: 'データ管理 (DTA)',
    category: '運用および保守 (OM)',
    description:
      'セキュリティとプライバシーの洞察を提供することを目的として複数の異なるソースからのデータを調査します。モデリング、データマイニング、および研究目的で使用される複雑な企業規模のデータセットのためのカスタムアルゴリズム、ワークフロープロセス、およびレイアウトを設計および実装します。',
    tasks: [
      'T0007',
      'T0008',
      'T0068',
      'T0146',
      'T0195',
      'T0210',
      'T0342',
      'T0347',
      'T0349',
      'T0351',
      'T0353',
      'T0361',
      'T0366',
      'T0381',
      'T0382',
      'T0383',
      'T0385',
      'T0392',
      'T0402',
      'T0403',
      'T0404',
      'T0405',
      'T0460',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0015',
      'K0016',
      'K0020',
      'K0022',
      'K0023',
      'K0025',
      'K0031',
      'K0051',
      'K0052',
      'K0056',
      'K0060',
      'K0065',
      'K0068',
      'K0069',
      'K0083',
      'K0095',
      'K0129',
      'K0139',
      'K0140',
      'K0193',
      'K0197',
      'K0229',
      'K0236',
      'K0238',
      'K0325',
      'K0420',
    ],
    skills: [
      'S0013',
      'S0017',
      'S0028',
      'S0029',
      'S0037',
      'S0060',
      'S0088',
      'S0089',
      'S0094',
      'S0095',
      'S0103',
      'S0106',
      'S0109',
      'S0113',
      'S0114',
      'S0118',
      'S0119',
      'S0123',
      'S0125',
      'S0126',
      'S0127',
      'S0129',
      'S0130',
      'S0160',
      'S0202',
      'S0369',
    ],
    abilities: ['A0029', 'A0035', 'A0036', 'A0041', 'A0066'],
  },
  {
    id: 'OM-KMG-001',
    name: 'ナレッジマネージャー',
    specialityArea: 'ナレッジマネジメント (KMG)',
    category: '運用および保守 (OM)',
    description:
      '組織が知的資本と情報コンテンツを特定、文書化、アクセスするためのプロセスとツールの管理と運営を担当します。',
    tasks: ['T0037', 'T0060', 'T0154', 'T0185', 'T0209', 'T0339', 'T0421', 'T0452', 'T0524'],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0013',
      'K0094',
      'K0095',
      'K0096',
      'K0146',
      'K0194',
      'K0195',
      'K0228',
      'K0260',
      'K0261',
      'K0262',
      'K0283',
      'K0287',
      'K0315',
      'K0338',
      'K0420',
    ],
    skills: ['S0011', 'S0012', 'S0049', 'S0055'],
    abilities: ['A0002'],
  },
  {
    id: 'OM-STS-001',
    name: 'テクニカルサポートスペシャリスト',
    specialityArea: 'カスタマーサービスとテクニカルサポート (STS)',
    category: '運用および保守 (OM)',
    description:
      '確立されたまたは承認された組織プロセスコンポーネント（例えば、適用される場合はマスターインシデント管理計画）に従って、クライアントレベルのハードウェアおよびソフトウェアを使用して支援を必要とする顧客にテクニカルサポートを提供します。',
    tasks: [
      'T0125',
      'T0237',
      'T0308',
      'T0315',
      'T0331',
      'T0468',
      'T0482',
      'T0491',
      'T0494',
      'T0496',
      'T0502',
      'T0530',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0053',
      'K0088',
      'K0109',
      'K0114',
      'K0116',
      'K0194',
      'K0224',
      'K0237',
      'K0242',
      'K0247',
      'K0260',
      'K0261',
      'K0262',
      'K0287',
      'K0292',
      'K0294',
      'K0302',
      'K0317',
      'K0330',
    ],
    skills: ['S0039', 'S0058', 'S0142', 'S0159', 'S0365'],
    abilities: ['A0025', 'A0034', 'A0122'],
  },
  {
    id: 'OM-NET-001',
    name: 'ネットワーク運用スペシャリスト',
    specialityArea: 'ネットワークサービス (NET)',
    category: '運用および保守 (OM)',
    description:
      'ハードウェアおよび仮想環境を含むネットワークサービス/システムの計画、実装、および運用を行います。',
    tasks: [
      'T0035',
      'T0065',
      'T0081',
      'T0121',
      'T0125',
      'T0126',
      'T0129',
      'T0153',
      'T0160',
      'T0200',
      'T0232',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0010',
      'K0011',
      'K0029',
      'K0038',
      'K0049',
      'K0050',
      'K0053',
      'K0061',
      'K0071',
      'K0076',
      'K0093',
      'K0104',
      'K0108',
      'K0111',
      'K0113',
      'K0135',
      'K0136',
      'K0137',
      'K0138',
      'K0159',
      'K0160',
      'K0179',
      'K0180',
      'K0200',
      'K0201',
      'K0203',
      'K0260',
      'K0261',
      'K0262',
      'K0274',
      'K0287',
      'K0332',
      'K0622',
    ],
    skills: [
      'S0004',
      'S0035',
      'S0040',
      'S0041',
      'S0056',
      'S0077',
      'S0079',
      'S0084',
      'S0150',
      'S0162',
      'S0170',
    ],
    abilities: ['A0052', 'A0055', 'A0058', 'A0059', 'A0062', 'A0063', 'A0065', 'A0159'],
  },
  {
    id: 'OM-ADM-001',
    name: 'システム管理者',
    specialityArea: 'システム管理 (ADM)',
    category: '運用および保守 (OM)',
    description:
      'システム全体またはシステムの特定のコンポーネント（例：ハードウェアとソフトウェアのインストール、設定、更新；ユーザーアカウントの設定と管理；バックアップとリカバリー作業の監督または実施；運用と技術のセキュリティコントロールの実装；組織のセキュリティポリシーと手順への準拠）の設定と維持を担当します。',
    tasks: [
      'T0029',
      'T0054',
      'T0063',
      'T0136',
      'T0144',
      'T0186',
      'T0207',
      'T0418',
      'T0431',
      'T0435',
      'T0458',
      'T0461',
      'T0498',
      'T0501',
      'T0507',
      'T0514',
      'T0515',
      'T0531',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0049',
      'K0050',
      'K0053',
      'K0064',
      'K0077',
      'K0088',
      'K0100',
      'K0103',
      'K0104',
      'K0117',
      'K0130',
      'K0158',
      'K0167',
      'K0179',
      'K0260',
      'K0261',
      'K0262',
      'K0274',
      'K0280',
      'K0289',
      'K0318',
      'K0332',
      'K0346',
    ],
    skills: [
      'S0016',
      'S0033',
      'S0043',
      'S0073',
      'S0076',
      'S0111',
      'S0143',
      'S0144',
      'S0151',
      'S0153',
      'S0154',
      'S0155',
      'S0157',
      'S0158',
    ],
    abilities: ['A0025', 'A0027', 'A0034', 'A0055', 'A0062', 'A0074', 'A0088', 'A0123', 'A0124'],
  },
  {
    id: 'OM-ANA-001',
    name: 'システムセキュリティアナリスト',
    specialityArea: 'システム分析 (ANA)',
    category: '運用および保守 (OM)',
    description: 'システムセキュリティの統合、テスト、運用、保守の分析と開発を担当します。',
    tasks: [
      'T0015',
      'T0016',
      'T0017',
      'T0085',
      'T0086',
      'T0088',
      'T0123',
      'T0128',
      'T0169',
      'T0177',
      'T0187',
      'T0194',
      'T0202',
      'T0205',
      'T0243',
      'T0309',
      'T0344',
      'T0462',
      'T0469',
      'T0470',
      'T0475',
      'T0477',
      'T0485',
      'T0489',
      'T0492',
      'T0499',
      'T0504',
      'T0508',
      'T0526',
      'T0545',
      'T0548',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0015',
      'K0018',
      'K0019',
      'K0024',
      'K0035',
      'K0036',
      'K0040',
      'K0044',
      'K0049',
      'K0052',
      'K0056',
      'K0060',
      'K0061',
      'K0063',
      'K0075',
      'K0082',
      'K0093',
      'K0102',
      'K0179',
      'K0180',
      'K0200',
      'K0203',
      'K0227',
      'K0260',
      'K0261',
      'K0262',
      'K0263',
      'K0266',
      'K0267',
      'K0275',
      'K0276',
      'K0281',
      'K0284',
      'K0285',
      'K0287',
      'K0290',
      'K0297',
      'K0322',
      'K0333',
      'K0339',
    ],
    skills: ['S0024', 'S0027', 'S0031', 'S0036', 'S0060', 'S0141', 'S0147', 'S0167', 'S0367'],
    abilities: ['A0015', 'A0123'],
  },
  {
    id: 'OV-LGA-001',
    name: 'サイバー法務顧問',
    specialityArea: '法律相談と支援 (LGA)',
    category: '監督および統治 (OV)',
    description:
      'サイバー法に関連する適切なトピックについての法的アドバイスと推奨事項を提供します。',
    tasks: [
      'T0006',
      'T0098',
      'T0102',
      'T0131',
      'T0220',
      'T0419',
      'T0434',
      'T0465',
      'T0474',
      'T0476',
      'T0478',
      'T0487',
      'T0522',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0017',
      'K0059',
      'K0107',
      'K0157',
      'K0261',
      'K0262',
      'K0267',
      'K0312',
      'K0316',
      'K0341',
      'K0615',
    ],
    skills: ['S0356'],
    abilities: ['A0046'],
  },
  {
    id: 'OV-LGA-002',
    name: 'プライバシー責任者/プライバシーコンプライアンスマネージャー',
    specialityArea: '法律相談と支援 (LGA)',
    category: '監督および統治 (OV)',
    description:
      'プライバシー・コンプライアンス・プログラムとプライバシー・プログラム・スタッフを開発・監督し、プライバシーとセキュリティのエグゼクティブとそのチームのプライバシー・コンプライアンス、ガバナンス／ポリシー、インシデント対応のニーズをサポートする。',
    tasks: [
      'T0003',
      'T0004',
      'T0029',
      'T0032',
      'T0066',
      'T0098',
      'T0099',
      'T0131',
      'T0133',
      'T0188',
      'T0381',
      'T0384',
      'T0478',
      'T0861',
      'T0862',
      'T0863',
      'T0864',
      'T0865',
      'T0866',
      'T0867',
      'T0868',
      'T0869',
      'T0870',
      'T0871',
      'T0872',
      'T0873',
      'T0874',
      'T0875',
      'T0876',
      'T0877',
      'T0878',
      'T0879',
      'T0880',
      'T0881',
      'T0882',
      'T0883',
      'T0884',
      'T0885',
      'T0886',
      'T0887',
      'T0888',
      'T0889',
      'T0890',
      'T0891',
      'T0892',
      'T0893',
      'T0894',
      'T0895',
      'T0896',
      'T0897',
      'T0898',
      'T0899',
      'T0900',
      'T0901',
      'T0902',
      'T0903',
      'T0904',
      'T0905',
      'T0906',
      'T0907',
      'T0908',
      'T0909',
      'T0910',
      'T0911',
      'T0912',
      'T0913',
      'T0914',
      'T0915',
      'T0916',
      'T0917',
      'T0918',
      'T0919',
      'T0930',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0008',
      'K0066',
      'K0168',
      'K0612',
      'K0613',
      'K0614',
      'K0615',
    ],
    skills: ['S0354', 'S0355', 'S0356'],
    abilities: [
      'A0024',
      'A0033',
      'A0034',
      'A0104',
      'A0105',
      'A0110',
      'A0111',
      'A0112',
      'A0113',
      'A0114',
      'A0115',
      'A0125',
    ],
  },
  {
    id: 'OV-TEA-001',
    name: 'サイバー教育カリキュラム開発者',
    specialityArea: 'トレーニング、教育と啓発 (TEA)',
    category: '監督および統治 (OV)',
    description:
      '指導的ニーズに基づいたサイバートレーニング/教育コース、方法、技術を開発、計画、調整、および評価します。',
    tasks: [
      'T0247',
      'T0248',
      'T0249',
      'T0345',
      'T0352',
      'T0357',
      'T0365',
      'T0367',
      'T0380',
      'T0437',
      'T0442',
      'T0450',
      'T0451',
      'T0534',
      'T0536',
      'T0926',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0059',
      'K0124',
      'K0146',
      'K0147',
      'K0204',
      'K0208',
      'K0213',
      'K0216',
      'K0217',
      'K0220',
      'K0239',
      'K0243',
      'K0245',
      'K0246',
      'K0250',
      'K0252',
      'K0287',
      'K0628',
    ],
    skills: ['S0064', 'S0066', 'S0070', 'S0102', 'S0166', 'S0296'],
    abilities: [
      'A0004',
      'A0013',
      'A0015',
      'A0018',
      'A0019',
      'A0022',
      'A0024',
      'A0032',
      'A0054',
      'A0055',
      'A0057',
      'A0058',
      'A0063',
      'A0070',
      'A0083',
      'A0089',
      'A0105',
      'A0106',
      'A0112',
      'A0114',
      'A0118',
      'A0119',
      'A0171',
    ],
  },
  {
    id: 'OV-TEA-002',
    name: 'サイバーインストラクター',
    specialityArea: 'トレーニング、教育と啓発 (TEA)',
    category: '監督および統治 (OV)',
    description: 'サイバー領域の人員の訓練または教育を開発し、実施します。',
    tasks: [
      'T0030',
      'T0073',
      'T0101',
      'T0224',
      'T0230',
      'T0247',
      'T0316',
      'T0317',
      'T0318',
      'T0319',
      'T0320',
      'T0321',
      'T0322',
      'T0323',
      'T0352',
      'T0365',
      'T0367',
      'T0381',
      'T0382',
      'T0395',
      'T0443',
      'T0444',
      'T0450',
      'T0451',
      'T0467',
      'T0519',
      'T0520',
      'T0535',
      'T0536',
      'T0926',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0007',
      'K0059',
      'K0115',
      'K0124',
      'K0130',
      'K0146',
      'K0147',
      'K0204',
      'K0208',
      'K0213',
      'K0215',
      'K0216',
      'K0217',
      'K0218',
      'K0220',
      'K0226',
      'K0239',
      'K0245',
      'K0246',
      'K0250',
      'K0252',
      'K0287',
      'K0313',
      'K0319',
      'K0628',
    ],
    skills: [
      'S0001',
      'S0004',
      'S0006',
      'S0051',
      'S0052',
      'S0053',
      'S0055',
      'S0056',
      'S0057',
      'S0060',
      'S0064',
      'S0070',
      'S0073',
      'S0075',
      'S0076',
      'S0081',
      'S0084',
      'S0097',
      'S0098',
      'S0100',
      'S0101',
      'S0121',
      'S0131',
      'S0156',
      'S0184',
      'S0270',
      'S0271',
      'S0281',
      'S0293',
      'S0301',
      'S0356',
      'S0358',
    ],
    abilities: [
      'A0006',
      'A0011',
      'A0012',
      'A0013',
      'A0014',
      'A0015',
      'A0016',
      'A0017',
      'A0018',
      'A0019',
      'A0020',
      'A0022',
      'A0023',
      'A0024',
      'A0032',
      'A0055',
      'A0057',
      'A0058',
      'A0063',
      'A0066',
      'A0070',
      'A0083',
      'A0089',
      'A0105',
      'A0106',
      'A0112',
      'A0114',
      'A0118',
      'A0119',
      'A0171',
    ],
  },
  {
    id: 'OV-MGT-001',
    name: '情報システムセキュリティマネージャー',
    specialityArea: 'サイバーセキュリティ管理 (MGT)',
    category: '監督および統治 (OV)',
    description: 'プログラム、組織、システム、または飛び地のサイバーセキュリティに責任を持ちます。',
    tasks: [
      'T0147',
      'T0148',
      'T0149',
      'T0151',
      'T0157',
      'T0158',
      'T0159',
      'T0192',
      'T0199',
      'T0206',
      'T0211',
      'T0213',
      'T0215',
      'T0219',
      'T0227',
      'T0229',
      'T0234',
      'T0239',
      'T0248',
      'T0254',
      'T0255',
      'T0256',
      'T0263',
      'T0264',
      'T0265',
      'T0275',
      'T0276',
      'T0277',
      'T0280',
      'T0281',
      'T0282',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0008',
      'K0018',
      'K0021',
      'K0026',
      'K0033',
      'K0038',
      'K0040',
      'K0042',
      'K0043',
      'K0046',
      'K0048',
      'K0053',
      'K0054',
      'K0058',
      'K0059',
      'K0061',
      'K0070',
      'K0072',
      'K0076',
      'K0077',
      'K0087',
      'K0090',
      'K0092',
      'K0101',
      'K0106',
      'K0121',
      'K0126',
      'K0149',
      'K0150',
      'K0151',
      'K0163',
      'K0167',
      'K0168',
      'K0169',
      'K0170',
      'K0179',
      'K0180',
      'K0199',
      'K0260',
      'K0261',
      'K0262',
      'K0267',
      'K0287',
      'K0332',
      'K0342',
      'K0622',
      'K0624',
    ],
    skills: ['S0018', 'S0027', 'S0086'],
    abilities: ['A0128', 'A0161', 'A0170'],
  },
  {
    id: 'OV-MGT-002',
    name: '通信セキュリティマネージャー',
    specialityArea: 'サイバーセキュリティ管理 (MGT)',
    category: '監督および統治 (OV)',
    description:
      '組織の通信セキュリティ（COMSEC）リソースを管理する個人（CNSSI 4009）、または暗号鍵管理システム（CKMS）の管理者です。',
    tasks: ['T0003', 'T0004', 'T0025', 'T0044', 'T0089', 'T0095', 'T0099', 'T0215', 'T0229'],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0018',
      'K0026',
      'K0038',
      'K0042',
      'K0090',
      'K0101',
      'K0121',
      'K0126',
      'K0163',
      'K0267',
      'K0285',
      'K0287',
      'K0622',
    ],
    skills: ['S0027', 'S0059', 'S0138'],
    abilities: ['A0177', 'A0163', 'A0164', 'A0165', 'A0166', 'A0167', 'A0168'],
  },
  {
    id: 'OV-SPP-001',
    name: 'サイバーワークフォース策定者およびマネージャー',
    specialityArea: '戦略計画と政策 (SPP)',
    category: '監督および統治 (OV)',
    description:
      'サイバースペースの労働力の人員、人事、訓練、教育要件を支援するため、またサイバースペースの政策、教義、物資、部隊構造、および教育訓練要件の変更に対処するために、サイバースペースの労働力計画、戦略、および指針を策定します。',
    tasks: [
      'T0001',
      'T0004',
      'T0025',
      'T0044',
      'T0074',
      'T0094',
      'T0099',
      'T0116',
      'T0222',
      'T0226',
      'T0341',
      'T0352',
      'T0355',
      'T0356',
      'T0362',
      'T0363',
      'T0364',
      'T0365',
      'T0368',
      'T0369',
      'T0372',
      'T0373',
      'T0374',
      'T0375',
      'T0376',
      'T0384',
      'T0387',
      'T0388',
      'T0390',
      'T0391',
      'T0408',
      'T0425',
      'T0429',
      'T0437',
      'T0441',
      'T0445',
      'T0472',
      'T0481',
      'T0505',
      'T0506',
      'T0529',
      'T0533',
      'T0536',
      'T0537',
      'T0552',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0072',
      'K0101',
      'K0127',
      'K0146',
      'K0147',
      'K0168',
      'K0169',
      'K0204',
      'K0215',
      'K0233',
      'K0234',
      'K0241',
      'K0243',
      'K0309',
      'K0311',
      'K0313',
      'K0335',
    ],
    skills: ['S0108', 'S0128'],
    abilities: ['A0023', 'A0028', 'A0033', 'A0037', 'A0042', 'A0053'],
  },
  {
    id: 'OV-SPP-002',
    name: 'サイバーポリシーと戦略プランナー',
    specialityArea: '戦略計画と政策 (SPP)',
    category: '監督および統治 (OV)',
    description:
      '組織のサイバーセキュリティイニシアチブおよび規制コンプライアンスを支援・整合させるために、サイバーセキュリティ計画、戦略、およびポリシーを策定・維持します。',
    tasks: [
      'T0074',
      'T0094',
      'T0222',
      'T0226',
      'T0341',
      'T0369',
      'T0384',
      'T0390',
      'T0408',
      'T0425',
      'T0429',
      'T0441',
      'T0445',
      'T0472',
      'T0505',
      'T0506',
      'T0529',
      'T0533',
      'T0537',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0070',
      'K0127',
      'K0146',
      'K0168',
      'K0234',
      'K0248',
      'K0309',
      'K0311',
      'K0313',
      'K0335',
      'K0624',
    ],
    skills: ['S0176', 'S0250'],
    abilities: ['A0003', 'A0033', 'A0037'],
  },
  {
    id: 'OV-EXL-001',
    name: 'エグゼクティブ・サイバー・リーダーシップ',
    specialityArea: 'エグゼクティブ・サイバー・リーダーシップ (EXL)',
    category: '監督および統治 (OV)',
    description:
      '意思決定権を行使し、組織のサイバーおよびサイバー関連リソースやオペレーションのためのビジョンと方向性を確立します。',
    tasks: [
      'T0001',
      'T0002',
      'T0004',
      'T0006',
      'T0025',
      'T0066',
      'T0130',
      'T0134',
      'T0135',
      'T0148',
      'T0151',
      'T0227',
      'T0229',
      'T0248',
      'T0254',
      'T0263',
      'T0264',
      'T0282',
      'T0337',
      'T0356',
      'T0429',
      'T0445',
      'T0509',
      'T0763',
      'T0871',
      'T0872',
      'T0927',
      'T0928',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0009',
      'K0070',
      'K0106',
      'K0147',
      'K0296',
      'K0314',
      'K0624',
      'K0628',
    ],
    skills: ['S0018', 'S0356', 'S0357', 'S0358', 'S0359'],
    abilities: [
      'A0033',
      'A0070',
      'A0085',
      'A0094',
      'A0105',
      'A0106',
      'A0116',
      'A0117',
      'A0118',
      'A0119',
      'A0129',
      'A0130',
    ],
  },
  {
    id: 'OV-PMA-001',
    name: 'プログラムマネージャー',
    specialityArea: 'プログラム/プロジェクト管理と調達 (PMA)',
    category: '監督および統治 (OV)',
    description:
      '政府機関または企業の優先事項との整合性を確保しながら、プログラムの全体的な成功のために指導、調整、伝達、統合、責任を負います。',
    tasks: [
      'T0066',
      'T0072',
      'T0174',
      'T0199',
      'T0220',
      'T0223',
      'T0256',
      'T0273',
      'T0277',
      'T0302',
      'T0340',
      'T0354',
      'T0377',
      'T0379',
      'T0407',
      'T0412',
      'T0414',
      'T0415',
      'T0481',
      'T0493',
      'T0551',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0047',
      'K0048',
      'K0072',
      'K0090',
      'K0101',
      'K0120',
      'K0126',
      'K0146',
      'K0148',
      'K0154',
      'K0164',
      'K0165',
      'K0169',
      'K0194',
      'K0196',
      'K0198',
      'K0200',
      'K0235',
      'K0257',
      'K0270',
    ],
    skills: ['S0038', 'S0372'],
    abilities: ['A0009', 'A0039', 'A0045', 'A0056'],
  },
  {
    id: 'OV-PMA-002',
    name: 'ITプロジェクトマネージャー',
    specialityArea: 'プログラム/プロジェクト管理と調達 (PMA)',
    category: '監督および統治 (OV)',
    description: '直接的にITプロジェクトを管理します。',
    tasks: [
      'T0072',
      'T0174',
      'T0196',
      'T0199',
      'T0207',
      'T0208',
      'T0220',
      'T0223',
      'T0256',
      'T0273',
      'T0277',
      'T0340',
      'T0354',
      'T0370',
      'T0377',
      'T0379',
      'T0389',
      'T0394',
      'T0407',
      'T0412',
      'T0414',
      'T0415',
      'T0481',
      'T0493',
      'T0551',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0012',
      'K0043',
      'K0047',
      'K0048',
      'K0059',
      'K0072',
      'K0090',
      'K0101',
      'K0120',
      'K0126',
      'K0146',
      'K0148',
      'K0154',
      'K0164',
      'K0165',
      'K0169',
      'K0194',
      'K0196',
      'K0198',
      'K0200',
      'K0235',
      'K0257',
      'K0270',
    ],
    skills: ['S0038', 'S0372'],
    abilities: ['A0009', 'A0039', 'A0045', 'A0056'],
  },
  {
    id: 'OV-PMA-003',
    name: '製品サポートマネージャー',
    specialityArea: 'プログラム/プロジェクト管理と調達 (PMA)',
    category: '監督および統治 (OV)',
    description:
      'システムとコンポーネントの即応性と運用能力をフィールドで維持するために必要なサポート機能のパッケージを管理します。',
    tasks: [
      'T0072',
      'T0174',
      'T0196',
      'T0204',
      'T0207',
      'T0208',
      'T0220',
      'T0223',
      'T0256',
      'T0273',
      'T0277',
      'T0302',
      'T0340',
      'T0354',
      'T0370',
      'T0377',
      'T0389',
      'T0394',
      'T0412',
      'T0414',
      'T0493',
      'T0525',
      'T0551',
      'T0553',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0043',
      'K0048',
      'K0059',
      'K0072',
      'K0090',
      'K0120',
      'K0126',
      'K0148',
      'K0150',
      'K0154',
      'K0164',
      'K0165',
      'K0169',
      'K0194',
      'K0196',
      'K0198',
      'K0200',
      'K0235',
      'K0249',
      'K0257',
      'K0270',
    ],
    skills: ['S0038', 'S0372'],
    abilities: ['A0009', 'A0031', 'A0039', 'A0045', 'A0056'],
  },
  {
    id: 'OV-PMA-004',
    name: 'IT投資/ポートフォリオマネージャー',
    specialityArea: 'プログラム/プロジェクト管理と調達 (PMA)',
    category: '監督および統治 (OV)',
    description:
      'ミッションとエンタープライズの優先事項全体と一致するIT投資のポートフォリオを管理します。',
    tasks: ['T0220', 'T0223', 'T0277', 'T0302', 'T0377', 'T0415', 'T0493', 'T0551'],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0048',
      'K0072',
      'K0120',
      'K0126',
      'K0146',
      'K0154',
      'K0165',
      'K0169',
      'K0235',
      'K0257',
      'K0270',
    ],
    skills: ['S0372'],
    abilities: ['A0039'],
  },
  {
    id: 'OV-PMA-005',
    name: 'ITプログラム監査員',
    specialityArea: 'プログラム/プロジェクト管理と調達 (PMA)',
    category: '監督および統治 (OV)',
    description:
      '公表されている基準への準拠を判断するために、ITプログラムまたはその個々のコンポーネントの評価を実施します。',
    tasks: ['T0072', 'T0207', 'T0208', 'T0223', 'T0256', 'T0389', 'T0412', 'T0415'],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0043',
      'K0047',
      'K0048',
      'K0072',
      'K0090',
      'K0120',
      'K0126',
      'K0148',
      'K0154',
      'K0165',
      'K0169',
      'K0198',
      'K0200',
      'K0235',
      'K0257',
      'K0270',
    ],
    skills: ['S0038', 'S0085', 'S0372'],
    abilities: ['A0056'],
  },
  {
    id: 'PR-CDA-001',
    name: 'サイバー防衛アナリスト',
    specialityArea: 'サイバーセキュリティ防衛分析 (CDA)',
    category: '保護および防衛 (PR)',
    description:
      '脅威を軽減する目的で、さまざまなサイバーディフェンスツール（例：IDSアラート、ファイアウォール、ネットワークトラフィックログ）から収集されたデータを使用して、環境内で発生したイベントを分析します。',
    tasks: [
      'T0020',
      'T0023',
      'T0043',
      'T0088',
      'T0155',
      'T0164',
      'T0166',
      'T0178',
      'T0187',
      'T0198',
      'T0214',
      'T0258',
      'T0259',
      'T0260',
      'T0290',
      'T0291',
      'T0292',
      'T0293',
      'T0294',
      'T0295',
      'T0296',
      'T0297',
      'T0298',
      'T0299',
      'T0310',
      'T0332',
      'T0469',
      'T0470',
      'T0475',
      'T0503',
      'T0504',
      'T0526',
      'T0545',
      'T0548',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0007',
      'K0013',
      'K0015',
      'K0018',
      'K0019',
      'K0024',
      'K0033',
      'K0040',
      'K0042',
      'K0044',
      'K0046',
      'K0049',
      'K0056',
      'K0058',
      'K0059',
      'K0060',
      'K0061',
      'K0065',
      'K0070',
      'K0074',
      'K0075',
      'K0093',
      'K0098',
      'K0104',
      'K0106',
      'K0107',
      'K0110',
      'K0111',
      'K0112',
      'K0113',
      'K0116',
      'K0139',
      'K0142',
      'K0143',
      'K0157',
      'K0160',
      'K0161',
      'K0162',
      'K0167',
      'K0168',
      'K0177',
      'K0179',
      'K0180',
      'K0190',
      'K0191',
      'K0192',
      'K0203',
      'K0221',
      'K0222',
      'K0260',
      'K0261',
      'K0262',
      'K0290',
      'K0297',
      'K0300',
      'K0301',
      'K0303',
      'K0318',
      'K0322',
      'K0324',
      'K0332',
      'K0339',
      'K0342',
      'K0624',
    ],
    skills: [
      'S0020',
      'S0025',
      'S0027',
      'S0036',
      'S0054',
      'S0057',
      'S0063',
      'S0078',
      'S0096',
      'S0147',
      'S0156',
      'S0167',
      'S0169',
      'S0367',
      'S0370',
    ],
    abilities: ['A0010', 'A0015', 'A0066', 'A0123', 'A0128', 'A0159'],
  },
  {
    id: 'PR-INF-001',
    name: 'サイバー防衛インフラサポートスペシャリスト',
    specialityArea: 'サイバーセキュリティ防衛インフラサポート (INF)',
    category: '保護および防衛 (PR)',
    description:
      'インフラストラクチャのハードウェアとソフトウェアをテスト、実装、展開、保守、および管理します。',
    tasks: ['T0042', 'T0180', 'T0261', 'T0335', 'T0348', 'T0420', 'T0438', 'T0483', 'T0486'],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0021',
      'K0033',
      'K0042',
      'K0044',
      'K0058',
      'K0061',
      'K0062',
      'K0104',
      'K0106',
      'K0135',
      'K0157',
      'K0179',
      'K0205',
      'K0258',
      'K0274',
      'K0324',
      'K0332',
      'K0334',
    ],
    skills: ['S0007', 'S0053', 'S0054', 'S0059', 'S0077', 'S0079', 'S0121', 'S0124', 'S0367'],
    abilities: ['A0123'],
  },
  {
    id: 'PR-CIR-001',
    name: 'サイバー防衛インシデントレスポンダー',
    specialityArea: 'インシデント対応 (CIR)',
    category: '保護および防衛 (PR)',
    description:
      'ネットワーク環境またはエンクレーブ内でのサイバーインシデントを調査、分析、対応します。',
    tasks: [
      'T0041',
      'T0047',
      'T0161',
      'T0163',
      'T0164',
      'T0170',
      'T0175',
      'T0214',
      'T0233',
      'T0246',
      'T0262',
      'T0278',
      'T0279',
      'T0312',
      'T0395',
      'T0503',
      'T0510',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0021',
      'K0026',
      'K0033',
      'K0034',
      'K0041',
      'K0042',
      'K0046',
      'K0058',
      'K0062',
      'K0070',
      'K0106',
      'K0157',
      'K0161',
      'K0162',
      'K0167',
      'K0177',
      'K0179',
      'K0221',
      'K0230',
      'K0259',
      'K0287',
      'K0332',
      'K0565',
      'K0624',
    ],
    skills: ['S0003', 'S0047', 'S0077', 'S0078', 'S0079', 'S0080', 'S0173', 'S0365'],
    abilities: ['A0121', 'A0128'],
  },
  {
    id: 'PR-VAM-001',
    name: '脆弱性評価アナリスト',
    specialityArea: '脆弱性評価と管理 (VAM)',
    category: '保護および防衛 (PR)',
    description:
      'ネットワーク環境またはエンクレーブ内のシステムやネットワークを評価し、それらのシステム/ネットワークが許容可能な構成、エンクレーブポリシー、またはローカルポリシーから逸脱している場所を特定します。既知の脆弱性に対する防御の深さのアーキテクチャの効果を測定します。',
    tasks: ['T0010', 'T0028', 'T0138', 'T0142', 'T0188', 'T0252', 'T0549', 'T0550'],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0009',
      'K0019',
      'K0021',
      'K0033',
      'K0044',
      'K0056',
      'K0061',
      'K0068',
      'K0070',
      'K0089',
      'K0106',
      'K0139',
      'K0161',
      'K0162',
      'K0167',
      'K0177',
      'K0179',
      'K0203',
      'K0206',
      'K0210',
      'K0224',
      'K0265',
      'K0287',
      'K0301',
      'K0308',
      'K0332',
      'K0342',
      'K0344',
      'K0624',
    ],
    skills: [
      'S0001',
      'S0009',
      'S0025',
      'S0044',
      'S0051',
      'S0052',
      'S0081',
      'S0120',
      'S0137',
      'S0171',
      'S0364',
      'S0367',
    ],
    abilities: ['A0001', 'A0044', 'A0120', 'A0123'],
  },
  {
    id: 'AN-TWA-001',
    name: '脅威/警告アナリスト',
    specialityArea: '脅威分析 (TWA)',
    category: '分析 (AN)',
    description:
      'サイバーインジケータを開発して、非常にダイナミックな運用環境の状況を把握します。サイバー脅威/警告の評価を収集、処理、分析、および発信します。',
    tasks: [
      'T0569',
      'T0583',
      'T0584',
      'T0585',
      'T0586',
      'T0589',
      'T0593',
      'T0597',
      'T0615',
      'T0617',
      'T0660',
      'T0685',
      'T0687',
      'T0707',
      'T0708',
      'T0718',
      'T0748',
      'T0749',
      'T0751',
      'T0752',
      'T0758',
      'T0761',
      'T0783',
      'T0785',
      'T0786',
      'T0792',
      'T0800',
      'T0805',
      'T0834',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0036',
      'K0058',
      'K0108',
      'K0109',
      'K0177',
      'K0349',
      'K0362',
      'K0377',
      'K0392',
      'K0395',
      'K0405',
      'K0409',
      'K0415',
      'K0417',
      'K0427',
      'K0431',
      'K0436',
      'K0437',
      'K0440',
      'K0444',
      'K0445',
      'K0446',
      'K0449',
      'K0458',
      'K0460',
      'K0464',
      'K0469',
      'K0471',
      'K0480',
      'K0499',
      'K0511',
      'K0516',
      'K0556',
      'K0560',
      'K0561',
      'K0565',
      'K0603',
      'K0604',
      'K0610',
      'K0612',
      'K0614',
    ],
    skills: [
      'S0194',
      'S0196',
      'S0203',
      'S0211',
      'S0218',
      'S0227',
      'S0228',
      'S0229',
      'S0249',
      'S0256',
      'S0278',
      'S0285',
      'S0288',
      'S0289',
      'S0296',
      'S0297',
      'S0303',
    ],
    abilities: [
      'A0013',
      'A0066',
      'A0072',
      'A0080',
      'A0082',
      'A0083',
      'A0084',
      'A0087',
      'A0088',
      'A0089',
      'A0091',
      'A0101',
      'A0102',
      'A0106',
      'A0107',
      'A0109',
    ],
  },
  {
    id: 'AN-EXP-001',
    name: 'エクスプロイトアナリスト',
    specialityArea: 'エクスプロイト分析 (EXP)',
    category: '分析 (AN)',
    description:
      'サイバー収集および/または準備活動を通じて満たすことができるアクセスと収集のギャップを特定するために協力します。対象ネットワークに侵入するために、認可されたすべてのリソースと分析技術を活用します。',
    tasks: [
      'T0028',
      'T0266',
      'T0570',
      'T0572',
      'T0574',
      'T0591',
      'T0600',
      'T0603',
      'T0608',
      'T0614',
      'T0641',
      'T0695',
      'T0701',
      'T0720',
      'T0727',
      'T0736',
      'T0738',
      'T0754',
      'T0775',
      'T0777',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0108',
      'K0109',
      'K0131',
      'K0142',
      'K0143',
      'K0177',
      'K0224',
      'K0349',
      'K0351',
      'K0354',
      'K0362',
      'K0368',
      'K0371',
      'K0376',
      'K0379',
      'K0388',
      'K0393',
      'K0394',
      'K0397',
      'K0417',
      'K0418',
      'K0430',
      'K0443',
      'K0444',
      'K0447',
      'K0451',
      'K0470',
      'K0471',
      'K0473',
      'K0484',
      'K0487',
      'K0489',
      'K0509',
      'K0510',
      'K0523',
      'K0529',
      'K0535',
      'K0544',
      'K0557',
      'K0559',
      'K0560',
      'K0608',
    ],
    skills: [
      'S0066',
      'S0184',
      'S0199',
      'S0200',
      'S0201',
      'S0204',
      'S0207',
      'S0214',
      'S0223',
      'S0236',
      'S0237',
      'S0239',
      'S0240',
      'S0245',
      'S0247',
      'S0258',
      'S0260',
      'S0264',
      'S0269',
      'S0279',
      'S0286',
      'S0290',
      'S0294',
      'S0300',
    ],
    abilities: ['A0013', 'A0066', 'A0074', 'A0080', 'A0084', 'A0086', 'A0092', 'A0093', 'A0104'],
  },
  {
    id: 'AN-ASA-001',
    name: '全情報源アナリスト',
    specialityArea: '全情報源分析 (ASA)',
    category: '分析 (AN)',
    description:
      '1つまたは複数の情報源からデータ／情報を分析し、環境整備を行い、情報要求に応え、計画と作戦を支援するための情報収集・作成要件を提出します。',
    tasks: [
      'T0569',
      'T0582',
      'T0583',
      'T0584',
      'T0585',
      'T0586',
      'T0589',
      'T0593',
      'T0597',
      'T0615',
      'T0617',
      'T0642',
      'T0660',
      'T0678',
      'T0685',
      'T0686',
      'T0687',
      'T0707',
      'T0708',
      'T0710',
      'T0713',
      'T0718',
      'T0748',
      'T0749',
      'T0751',
      'T0752',
      'T0758',
      'T0761',
      'T0771',
      'T0782',
      'T0783',
      'T0785',
      'T0786',
      'T0788',
      'T0789',
      'T0792',
      'T0797',
      'T0800',
      'T0805',
      'T0834',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0036',
      'K0058',
      'K0108',
      'K0109',
      'K0177',
      'K0221',
      'K0349',
      'K0357',
      'K0362',
      'K0377',
      'K0392',
      'K0395',
      'K0405',
      'K0409',
      'K0410',
      'K0427',
      'K0431',
      'K0436',
      'K0437',
      'K0440',
      'K0444',
      'K0445',
      'K0446',
      'K0449',
      'K0457',
      'K0458',
      'K0460',
      'K0464',
      'K0465',
      'K0469',
      'K0471',
      'K0480',
      'K0507',
      'K0511',
      'K0516',
      'K0533',
      'K0542',
      'K0549',
      'K0551',
      'K0556',
      'K0560',
      'K0561',
      'K0565',
      'K0577',
      'K0598',
      'K0603',
      'K0604',
      'K0610',
      'K0612',
      'K0614',
    ],
    skills: [
      'S0189',
      'S0194',
      'S0203',
      'S0211',
      'S0218',
      'S0227',
      'S0229',
      'S0249',
      'S0254',
      'S0256',
      'S0278',
      'S0285',
      'S0288',
      'S0289',
      'S0296',
      'S0297',
      'S0303',
      'S0360',
    ],
    abilities: [
      'A0013',
      'A0066',
      'A0072',
      'A0080',
      'A0082',
      'A0083',
      'A0084',
      'A0085',
      'A0087',
      'A0088',
      'A0089',
      'A0091',
      'A0101',
      'A0102',
      'A0106',
      'A0107',
      'A0108',
      'A0109',
    ],
  },
  {
    id: 'AN-ASA-002',
    name: 'ミッション評価スペシャリスト',
    specialityArea: '全情報源分析 (ASA)',
    category: '分析 (AN)',
    description:
      '評価計画を策定し、パフォーマンス/効果の尺度を測定します。必要に応じてサイバーイベントの戦略的および運用効果の評価を行います。システムが期待通りに機能したかどうかを判断し、運用効果の決定に対する意見を提供します。',
    tasks: [
      'T0582',
      'T0583',
      'T0585',
      'T0586',
      'T0588',
      'T0589',
      'T0593',
      'T0597',
      'T0611',
      'T0615',
      'T0617',
      'T0624',
      'T0660',
      'T0661',
      'T0663',
      'T0678',
      'T0684',
      'T0685',
      'T0686',
      'T0707',
      'T0718',
      'T0748',
      'T0749',
      'T0752',
      'T0758',
      'T0761',
      'T0782',
      'T0783',
      'T0785',
      'T0786',
      'T0788',
      'T0789',
      'T0793',
      'T0797',
      'T0834',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0036',
      'K0058',
      'K0108',
      'K0109',
      'K0177',
      'K0349',
      'K0362',
      'K0377',
      'K0392',
      'K0395',
      'K0405',
      'K0409',
      'K0410',
      'K0414',
      'K0417',
      'K0427',
      'K0431',
      'K0436',
      'K0437',
      'K0440',
      'K0444',
      'K0445',
      'K0446',
      'K0449',
      'K0457',
      'K0460',
      'K0464',
      'K0465',
      'K0469',
      'K0471',
      'K0480',
      'K0507',
      'K0511',
      'K0516',
      'K0549',
      'K0551',
      'K0556',
      'K0560',
      'K0561',
      'K0565',
      'K0598',
      'K0603',
      'K0604',
      'K0610',
      'K0612',
      'K0614',
    ],
    skills: [
      'S0189',
      'S0194',
      'S0203',
      'S0211',
      'S0216',
      'S0218',
      'S0227',
      'S0228',
      'S0229',
      'S0249',
      'S0254',
      'S0256',
      'S0271',
      'S0278',
      'S0285',
      'S0288',
      'S0289',
      'S0292',
      'S0296',
      'S0297',
      'S0303',
      'S0360',
    ],
    abilities: [
      'A0013',
      'A0066',
      'A0072',
      'A0080',
      'A0082',
      'A0083',
      'A0084',
      'A0085',
      'A0087',
      'A0088',
      'A0089',
      'A0091',
      'A0101',
      'A0102',
      'A0106',
      'A0107',
      'A0108',
      'A0109',
    ],
  },
  {
    id: 'AN-TGT-001',
    name: 'ターゲット開発者',
    specialityArea: 'ターゲット (TGT)',
    category: '分析 (AN)',
    description:
      'ターゲットシステムの分析を行い、環境準備や内部または外部の情報源からのインプットを含む電子ターゲットフォルダを構築または維持します。パートナーのターゲット活動や情報機関と調整し、吟味と検証のために候補となるターゲットを提示します。',
    tasks: [
      'T0561',
      'T0582',
      'T0588',
      'T0594',
      'T0597',
      'T0599',
      'T0617',
      'T0624',
      'T0633',
      'T0642',
      'T0650',
      'T0652',
      'T0661',
      'T0663',
      'T0684',
      'T0688',
      'T0707',
      'T0710',
      'T0717',
      'T0731',
      'T0744',
      'T0769',
      'T0770',
      'T0776',
      'T0781',
      'T0782',
      'T0790',
      'T0794',
      'T0797',
      'T0798',
      'T0799',
      'T0802',
      'T0815',
      'T0824',
      'T0835',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0036',
      'K0058',
      'K0108',
      'K0109',
      'K0142',
      'K0177',
      'K0349',
      'K0351',
      'K0357',
      'K0362',
      'K0379',
      'K0381',
      'K0392',
      'K0395',
      'K0402',
      'K0409',
      'K0413',
      'K0417',
      'K0426',
      'K0427',
      'K0431',
      'K0436',
      'K0437',
      'K0439',
      'K0440',
      'K0444',
      'K0445',
      'K0446',
      'K0449',
      'K0457',
      'K0458',
      'K0460',
      'K0461',
      'K0464',
      'K0465',
      'K0466',
      'K0471',
      'K0473',
      'K0478',
      'K0479',
      'K0497',
      'K0499',
      'K0507',
      'K0516',
      'K0533',
      'K0542',
      'K0543',
      'K0546',
      'K0547',
      'K0549',
      'K0551',
      'K0555',
      'K0556',
      'K0560',
      'K0561',
      'K0565',
      'K0598',
      'K0603',
      'K0604',
      'K0614',
    ],
    skills: [
      'S0187',
      'S0189',
      'S0194',
      'S0196',
      'S0203',
      'S0205',
      'S0208',
      'S0216',
      'S0218',
      'S0222',
      'S0227',
      'S0228',
      'S0229',
      'S0248',
      'S0249',
      'S0256',
      'S0274',
      'S0278',
      'S0285',
      'S0287',
      'S0288',
      'S0289',
      'S0292',
      'S0296',
      'S0297',
      'S0302',
      'S0360',
      'S0361',
    ],
    abilities: [
      'A0013',
      'A0066',
      'A0073',
      'A0080',
      'A0084',
      'A0085',
      'A0087',
      'A0088',
      'A0089',
      'A0091',
      'A0101',
      'A0102',
      'A0106',
      'A0109',
    ],
  },
  {
    id: 'AN-TGT-002',
    name: 'ターゲットネットワークアナリスト',
    specialityArea: 'ターゲット (TGT)',
    category: '分析 (AN)',
    description:
      'ターゲットの継続性を確保し、ターゲットとその活動をプロファイリングし、より多くのターゲット情報を得るための技術を開発するために、収集データとオープンソースデータの高度な分析を行います。ターゲットの技術、デジタル・ネットワーク、アプリケーションに関する知識に基づいて、ターゲットがどのように通信し、移動し、活動し、生活しているかを判断します。',
    tasks: [
      'T0582',
      'T0595',
      'T0599',
      'T0606',
      'T0607',
      'T0617',
      'T0621',
      'T0624',
      'T0650',
      'T0653',
      'T0692',
      'T0706',
      'T0707',
      'T0710',
      'T0715',
      'T0722',
      'T0745',
      'T0765',
      'T0767',
      'T0778',
      'T0797',
      'T0802',
      'T0803',
      'T0807',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0108',
      'K0109',
      'K0177',
      'K0349',
      'K0362',
      'K0379',
      'K0389',
      'K0392',
      'K0395',
      'K0403',
      'K0413',
      'K0424',
      'K0431',
      'K0436',
      'K0439',
      'K0440',
      'K0442',
      'K0444',
      'K0445',
      'K0449',
      'K0462',
      'K0471',
      'K0472',
      'K0473',
      'K0479',
      'K0483',
      'K0487',
      'K0499',
      'K0500',
      'K0516',
      'K0520',
      'K0544',
      'K0547',
      'K0550',
      'K0559',
      'K0567',
      'K0592',
      'K0599',
      'K0600',
    ],
    skills: [
      'S0177',
      'S0178',
      'S0181',
      'S0183',
      'S0187',
      'S0191',
      'S0194',
      'S0196',
      'S0197',
      'S0203',
      'S0205',
      'S0208',
      'S0217',
      'S0219',
      'S0220',
      'S0222',
      'S0225',
      'S0228',
      'S0229',
      'S0231',
      'S0234',
      'S0244',
      'S0246',
      'S0248',
      'S0256',
      'S0259',
      'S0261',
      'S0262',
      'S0263',
      'S0268',
      'S0274',
      'S0277',
      'S0280',
      'S0287',
      'S0291',
      'S0301',
    ],
    abilities: [
      'A0013',
      'A0066',
      'A0073',
      'A0080',
      'A0084',
      'A0085',
      'A0087',
      'A0088',
      'A0089',
      'A0091',
      'A0101',
      'A0102',
      'A0106',
      'A0109',
    ],
  },
  {
    id: 'AN-LNG-001',
    name: '多分野言語アナリスト',
    specialityArea: '言語分析 (LNG)',
    category: '分析 (AN)',
    description:
      '言語と文化の専門知識を対象/脅威および技術知識と組み合わせて、言語、音声、および/またはグラフィック素材から得られた情報を処理、分析、発信します。サイバーアクションの実行を支援し、重要な知識の共有を保証するために、言語固有のデータベースと作業支援を作成および維持します。外国語を多用する、または学際的プロジェクトにおいて、主題に関する専門知識を提供します。',
    tasks: [
      'T0606',
      'T0650',
      'T0715',
      'T0745',
      'T0761',
      'T0837',
      'T0838',
      'T0839',
      'T0840',
      'T0841',
      'T0842',
      'T0843',
      'T0844',
      'T0845',
      'T0846',
      'T0847',
      'T0848',
      'T0849',
      'T0850',
      'T0851',
      'T0852',
      'T0853',
      'T0854',
      'T0855',
      'T0856',
      'T0857',
      'T0858',
      'T0859',
      'T0860',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0108',
      'K0143',
      'K0177',
      'K0356',
      'K0359',
      'K0377',
      'K0391',
      'K0396',
      'K0398',
      'K0407',
      'K0413',
      'K0416',
      'K0417',
      'K0431',
      'K0449',
      'K0462',
      'K0476',
      'K0487',
      'K0488',
      'K0491',
      'K0493',
      'K0499',
      'K0520',
      'K0524',
      'K0532',
      'K0539',
      'K0540',
      'K0541',
      'K0545',
      'K0548',
      'K0550',
      'K0564',
      'K0567',
      'K0571',
      'K0574',
      'K0579',
      'K0596',
      'K0599',
      'K0600',
      'K0606',
      'K0607',
    ],
    skills: [
      'S0179',
      'S0184',
      'S0187',
      'S0188',
      'S0193',
      'S0195',
      'S0198',
      'S0210',
      'S0212',
      'S0215',
      'S0217',
      'S0218',
      'S0224',
      'S0226',
      'S0232',
      'S0233',
      'S0235',
      'S0241',
      'S0244',
      'S0251',
      'S0253',
      'S0259',
      'S0262',
      'S0265',
      'S0277',
      'S0283',
      'S0284',
      'S0290',
    ],
    abilities: ['A0013', 'A0071', 'A0089', 'A0103'],
  },
  {
    id: 'CO-CLO-001',
    name: '全情報源収集マネージャー',
    specialityArea: '情報収集操作 (CLO)',
    category: '情報収集および作戦行動 (CO)',
    description:
      '情報収集の権限と環境を特定し、優先情報要件を情報収集管理に組み込み、リーダーの意図を満たすためのコンセプトを策定します。利用可能な収集資産の能力を決定し、新たな収集能力を特定し、そして収集計画を構築して配布します。タスク化された収集の実行を監視し、収集計画の効果的な実行を確実にします。',
    tasks: [
      'T0562',
      'T0564',
      'T0568',
      'T0573',
      'T0578',
      'T0604',
      'T0605',
      'T0625',
      'T0626',
      'T0631',
      'T0632',
      'T0634',
      'T0645',
      'T0646',
      'T0647',
      'T0649',
      'T0651',
      'T0657',
      'T0662',
      'T0674',
      'T0681',
      'T0683',
      'T0698',
      'T0702',
      'T0714',
      'T0716',
      'T0721',
      'T0723',
      'T0725',
      'T0734',
      'T0737',
      'T0750',
      'T0753',
      'T0755',
      'T0757',
      'T0773',
      'T0779',
      'T0806',
      'T0809',
      'T0810',
      'T0811',
      'T0812',
      'T0814',
      'T0820',
      'T0821',
      'T0827',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0036',
      'K0058',
      'K0109',
      'K0177',
      'K0353',
      'K0361',
      'K0364',
      'K0380',
      'K0382',
      'K0383',
      'K0386',
      'K0387',
      'K0390',
      'K0392',
      'K0395',
      'K0401',
      'K0404',
      'K0405',
      'K0412',
      'K0417',
      'K0419',
      'K0425',
      'K0427',
      'K0431',
      'K0435',
      'K0440',
      'K0444',
      'K0445',
      'K0446',
      'K0448',
      'K0449',
      'K0453',
      'K0454',
      'K0467',
      'K0471',
      'K0474',
      'K0475',
      'K0477',
      'K0480',
      'K0482',
      'K0492',
      'K0495',
      'K0496',
      'K0498',
      'K0503',
      'K0505',
      'K0513',
      'K0516',
      'K0521',
      'K0522',
      'K0526',
      'K0527',
      'K0552',
      'K0553',
      'K0554',
      'K0558',
      'K0560',
      'K0561',
      'K0562',
      'K0563',
      'K0565',
      'K0569',
      'K0570',
      'K0579',
      'K0580',
      'K0581',
      'K0583',
      'K0584',
      'K0587',
      'K0588',
      'K0596',
      'K0601',
      'K0605',
      'K0610',
      'K0612',
      'K0613',
    ],
    skills: [
      'S0238',
      'S0304',
      'S0305',
      'S0311',
      'S0313',
      'S0316',
      'S0317',
      'S0324',
      'S0325',
      'S0327',
      'S0328',
      'S0330',
      'S0332',
      'S0334',
      'S0335',
      'S0336',
      'S0339',
      'S0342',
      'S0344',
      'S0347',
      'S0351',
      'S0352',
      'S0362',
    ],
    abilities: ['A0069', 'A0070', 'A0076', 'A0078', 'A0079'],
  },
  {
    id: 'CO-CLO-002',
    name: '全情報源収集要件マネージャー',
    specialityArea: '情報収集操作 (CLO)',
    category: '情報収集および作戦行動 (CO)',
    description:
      '情報収集業務を評価し、改善するために利用可能なソースと方法を使用して、効果に基づく情報収集要件戦略を策定します。収集要件の作成、処理、検証、および提出の調整を行います。収集資産と収集業務のパフォーマンスを評価します。',
    tasks: [
      'T0564',
      'T0565',
      'T0568',
      'T0577',
      'T0578',
      'T0580',
      'T0596',
      'T0602',
      'T0605',
      'T0613',
      'T0651',
      'T0668',
      'T0673',
      'T0675',
      'T0682',
      'T0689',
      'T0693',
      'T0694',
      'T0714',
      'T0725',
      'T0730',
      'T0734',
      'T0746',
      'T0780',
      'T0809',
      'T0810',
      'T0811',
      'T0819',
      'T0822',
      'T0830',
      'T0831',
      'T0832',
      'T0833',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0036',
      'K0058',
      'K0109',
      'K0177',
      'K0353',
      'K0361',
      'K0364',
      'K0380',
      'K0382',
      'K0383',
      'K0384',
      'K0386',
      'K0387',
      'K0390',
      'K0395',
      'K0401',
      'K0404',
      'K0412',
      'K0417',
      'K0419',
      'K0421',
      'K0425',
      'K0427',
      'K0431',
      'K0435',
      'K0444',
      'K0445',
      'K0446',
      'K0448',
      'K0453',
      'K0454',
      'K0467',
      'K0474',
      'K0475',
      'K0477',
      'K0480',
      'K0482',
      'K0492',
      'K0495',
      'K0496',
      'K0498',
      'K0505',
      'K0513',
      'K0516',
      'K0521',
      'K0526',
      'K0527',
      'K0552',
      'K0554',
      'K0558',
      'K0560',
      'K0561',
      'K0562',
      'K0563',
      'K0565',
      'K0568',
      'K0569',
      'K0570',
      'K0579',
      'K0580',
      'K0581',
      'K0584',
      'K0587',
      'K0588',
      'K0596',
      'K0605',
      'K0610',
      'K0612',
    ],
    skills: [
      'S0304',
      'S0305',
      'S0316',
      'S0317',
      'S0327',
      'S0329',
      'S0330',
      'S0334',
      'S0335',
      'S0336',
      'S0337',
      'S0339',
      'S0344',
      'S0346',
      'S0347',
      'S0348',
      'S0352',
      'S0353',
      'S0362',
    ],
    abilities: ['A0069', 'A0070', 'A0078'],
  },
  {
    id: 'CO-OPL-001',
    name: 'サイバーインテリジェンスプランナー',
    specialityArea: 'サイバー作戦計画 (OPL)',
    category: '情報収集および作戦行動 (CO)',
    description:
      'サイバー作戦の要件を満たすための詳細なインテリジェンス計画を策定します。サイバー作戦プランナーと協力し、収集と分析の要件を特定し、検証し、課します。サイバー行動の標的選定、検証、同期、実行に参加します。サイバー空間における組織の目標を支援するため、インテリジェンス活動を同期します。',
    tasks: [
      'T0563',
      'T0575',
      'T0576',
      'T0579',
      'T0581',
      'T0587',
      'T0590',
      'T0592',
      'T0601',
      'T0627',
      'T0628',
      'T0630',
      'T0636',
      'T0637',
      'T0638',
      'T0639',
      'T0640',
      'T0648',
      'T0656',
      'T0659',
      'T0667',
      'T0670',
      'T0676',
      'T0680',
      'T0690',
      'T0691',
      'T0705',
      'T0709',
      'T0711',
      'T0719',
      'T0726',
      'T0728',
      'T0733',
      'T0734',
      'T0735',
      'T0739',
      'T0743',
      'T0760',
      'T0763',
      'T0772',
      'T0784',
      'T0801',
      'T0808',
      'T0816',
      'T0836',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0036',
      'K0108',
      'K0109',
      'K0120',
      'K0347',
      'K0349',
      'K0350',
      'K0352',
      'K0355',
      'K0358',
      'K0362',
      'K0377',
      'K0379',
      'K0392',
      'K0395',
      'K0399',
      'K0400',
      'K0403',
      'K0405',
      'K0408',
      'K0411',
      'K0414',
      'K0417',
      'K0422',
      'K0427',
      'K0431',
      'K0432',
      'K0435',
      'K0436',
      'K0440',
      'K0444',
      'K0445',
      'K0446',
      'K0455',
      'K0456',
      'K0459',
      'K0460',
      'K0463',
      'K0464',
      'K0465',
      'K0471',
      'K0480',
      'K0494',
      'K0499',
      'K0501',
      'K0502',
      'K0504',
      'K0506',
      'K0507',
      'K0508',
      'K0511',
      'K0512',
      'K0514',
      'K0517',
      'K0518',
      'K0519',
      'K0525',
      'K0538',
      'K0556',
      'K0560',
      'K0561',
      'K0565',
      'K0566',
      'K0572',
      'K0575',
      'K0577',
      'K0578',
      'K0582',
      'K0585',
      'K0586',
      'K0589',
      'K0590',
      'K0591',
      'K0593',
      'K0594',
      'K0595',
      'K0598',
      'K0599',
      'K0602',
      'K0603',
      'K0610',
      'K0612',
      'K0614',
    ],
    skills: [
      'S0176',
      'S0185',
      'S0186',
      'S0203',
      'S0213',
      'S0218',
      'S0249',
      'S0250',
      'S0272',
      'S0273',
      'S0278',
      'S0296',
      'S0297',
      'S0306',
      'S0307',
      'S0308',
      'S0309',
      'S0310',
      'S0312',
      'S0314',
      'S0315',
      'S0318',
      'S0319',
      'S0320',
      'S0321',
      'S0322',
      'S0323',
      'S0331',
      'S0333',
      'S0338',
      'S0340',
      'S0341',
      'S0343',
      'S0345',
      'S0350',
      'S0360',
    ],
    abilities: [
      'A0013',
      'A0066',
      'A0067',
      'A0068',
      'A0070',
      'A0074',
      'A0077',
      'A0081',
      'A0082',
      'A0085',
      'A0089',
      'A0090',
      'A0094',
      'A0096',
      'A0098',
      'A0105',
      'A0160',
    ],
  },
  {
    id: 'CO-OPL-002',
    name: 'サイバー作戦プランナー',
    specialityArea: 'サイバー作戦計画 (OPL)',
    category: '情報収集および作戦行動 (CO)',
    description:
      '他のプランナー、オペレーター、またはアナリストとの協力を通じて適用可能な範囲のサイバー作戦の実施または支援を行うための詳細な計画を策定します。ターゲティングの選択、検証、同期を行い、サイバー行動の実行中の統合を可能にします。',
    tasks: [
      'T0680',
      'T0690',
      'T0699',
      'T0703',
      'T0704',
      'T0719',
      'T0732',
      'T0733',
      'T0734',
      'T0739',
      'T0741',
      'T0742',
      'T0743',
      'T0747',
      'T0763',
      'T0764',
      'T0772',
      'T0787',
      'T0791',
      'T0795',
      'T0801',
      'T0813',
      'T0823',
      'T0836',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0036',
      'K0108',
      'K0109',
      'K0347',
      'K0349',
      'K0350',
      'K0352',
      'K0362',
      'K0377',
      'K0379',
      'K0392',
      'K0395',
      'K0399',
      'K0400',
      'K0403',
      'K0408',
      'K0411',
      'K0414',
      'K0417',
      'K0422',
      'K0431',
      'K0432',
      'K0435',
      'K0436',
      'K0444',
      'K0445',
      'K0446',
      'K0455',
      'K0464',
      'K0465',
      'K0471',
      'K0480',
      'K0494',
      'K0497',
      'K0499',
      'K0501',
      'K0502',
      'K0504',
      'K0506',
      'K0507',
      'K0508',
      'K0511',
      'K0512',
      'K0514',
      'K0516',
      'K0518',
      'K0519',
      'K0525',
      'K0534',
      'K0538',
      'K0556',
      'K0560',
      'K0561',
      'K0565',
      'K0566',
      'K0572',
      'K0576',
      'K0582',
      'K0585',
      'K0586',
      'K0589',
      'K0590',
      'K0593',
      'K0594',
      'K0597',
      'K0598',
      'K0599',
      'K0603',
      'K0610',
      'K0612',
      'K0614',
    ],
    skills: [
      'S0176',
      'S0185',
      'S0186',
      'S0209',
      'S0213',
      'S0218',
      'S0249',
      'S0250',
      'S0273',
      'S0296',
      'S0297',
      'S0309',
      'S0312',
      'S0322',
      'S0326',
      'S0333',
      'S0349',
      'S0360',
    ],
    abilities: [
      'A0013',
      'A0066',
      'A0067',
      'A0068',
      'A0070',
      'A0074',
      'A0077',
      'A0081',
      'A0082',
      'A0085',
      'A0089',
      'A0090',
      'A0094',
      'A0096',
      'A0098',
      'A0105',
    ],
  },
  {
    id: 'CO-OPL-003',
    name: 'パートナー統合プランナー',
    specialityArea: 'サイバー作戦計画 (OPL)',
    category: '情報収集および作戦行動 (CO)',
    description:
      'サイバーオペレーションのパートナー間の、組織や国境を越えた協力を促進します。統合サイバー行動の目的達成のために、ベストプラクティスの開発と組織的支援の促進のためのガイダンス、リソース、協力を提供することで、パートナーのサイバーチームの統合を支援します。',
    tasks: [
      'T0571',
      'T0581',
      'T0582',
      'T0601',
      'T0627',
      'T0629',
      'T0635',
      'T0665',
      'T0666',
      'T0669',
      'T0670',
      'T0671',
      'T0699',
      'T0700',
      'T0712',
      'T0729',
      'T0732',
      'T0739',
      'T0747',
      'T0759',
      'T0760',
      'T0763',
      'T0764',
      'T0766',
      'T0772',
      'T0784',
      'T0787',
      'T0795',
      'T0817',
      'T0818',
      'T0823',
      'T0825',
      'T0826',
      'T0836',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0108',
      'K0109',
      'K0350',
      'K0362',
      'K0377',
      'K0379',
      'K0392',
      'K0395',
      'K0400',
      'K0403',
      'K0408',
      'K0411',
      'K0414',
      'K0417',
      'K0422',
      'K0431',
      'K0432',
      'K0435',
      'K0436',
      'K0444',
      'K0455',
      'K0465',
      'K0499',
      'K0501',
      'K0504',
      'K0506',
      'K0507',
      'K0508',
      'K0511',
      'K0512',
      'K0514',
      'K0538',
      'K0585',
      'K0598',
      'K0599',
    ],
    skills: [
      'S0185',
      'S0186',
      'S0213',
      'S0218',
      'S0249',
      'S0250',
      'S0296',
      'S0297',
      'S0326',
      'S0360',
    ],
    abilities: [
      'A0013',
      'A0066',
      'A0067',
      'A0068',
      'A0070',
      'A0074',
      'A0077',
      'A0081',
      'A0082',
      'A0085',
      'A0089',
      'A0090',
      'A0094',
      'A0096',
      'A0098',
      'A0105',
    ],
  },
  {
    id: 'CO-OPS-001',
    name: 'サイバーオペレーター',
    specialityArea: 'サイバー作戦 (OPS)',
    category: '情報収集および作戦行動 (CO)',
    description:
      'システムの収集、処理、ジオロケーションを実施し、関心のあるターゲットを探索、位置特定、追跡します。ネットワークナビゲーション、戦術的フォレンジック分析を実行し、指示があれば、ネット上での作戦を実行します。',
    tasks: [
      'T0566',
      'T0567',
      'T0598',
      'T0609',
      'T0610',
      'T0612',
      'T0616',
      'T0618',
      'T0619',
      'T0620',
      'T0623',
      'T0643',
      'T0644',
      'T0664',
      'T0677',
      'T0696',
      'T0697',
      'T0724',
      'T0740',
      'T0756',
      'T0768',
      'T0774',
      'T0796',
      'T0804',
      'T0828',
      'T0829',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0009',
      'K0021',
      'K0051',
      'K0109',
      'K0142',
      'K0224',
      'K0363',
      'K0372',
      'K0373',
      'K0375',
      'K0379',
      'K0403',
      'K0406',
      'K0420',
      'K0423',
      'K0427',
      'K0428',
      'K0429',
      'K0430',
      'K0433',
      'K0438',
      'K0440',
      'K0452',
      'K0468',
      'K0480',
      'K0481',
      'K0485',
      'K0486',
      'K0516',
      'K0528',
      'K0530',
      'K0531',
      'K0536',
      'K0560',
      'K0565',
      'K0573',
      'K0608',
      'K0609',
    ],
    skills: [
      'S0062',
      'S0182',
      'S0183',
      'S0190',
      'S0192',
      'S0202',
      'S0206',
      'S0221',
      'S0236',
      'S0242',
      'S0243',
      'S0252',
      'S0255',
      'S0257',
      'S0266',
      'S0267',
      'S0270',
      'S0275',
      'S0276',
      'S0281',
      'S0282',
      'S0293',
      'S0295',
      'S0298',
      'S0299',
      'S0363',
    ],
    abilities: ['A0095', 'A0097', 'A0099', 'A0100'],
  },
  {
    id: 'IN-INV-001',
    name: 'サイバー犯罪調査員',
    specialityArea: 'サイバー調査 (INV)',
    category: '調査 (IN)',
    description:
      '管理され文書化された分析・捜査技術を使用して、証拠を特定し、収集し、検査し、保存します。',
    tasks: [
      'T0031',
      'T0059',
      'T0096',
      'T0103',
      'T0104',
      'T0110',
      'T0112',
      'T0113',
      'T0114',
      'T0120',
      'T0193',
      'T0225',
      'T0241',
      'T0343',
      'T0346',
      'T0360',
      'T0386',
      'T0423',
      'T0430',
      'T0433',
      'T0453',
      'T0471',
      'T0479',
      'T0523',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0046',
      'K0070',
      'K0107',
      'K0110',
      'K0114',
      'K0118',
      'K0123',
      'K0125',
      'K0128',
      'K0144',
      'K0155',
      'K0156',
      'K0168',
      'K0209',
      'K0231',
      'K0244',
      'K0251',
      'K0351',
      'K0624',
    ],
    skills: ['S0047', 'S0068', 'S0072', 'S0086'],
    abilities: ['A0174', 'A0175'],
  },
  {
    id: 'IN-FOR-001',
    name: '法執行/防諜フォレンジックアナリスト',
    specialityArea: 'デジタルフォレンジック (FOR)',
    category: '調査 (IN)',
    description:
      'サイバー侵入事件に関連するデジタルメディアやログを含む文書または物理的証拠を立証するために、コンピューターベースの犯罪に関する詳細な調査を行います。',
    tasks: [
      'T0059',
      'T0096',
      'T0220',
      'T0308',
      'T0398',
      'T0401',
      'T0403',
      'T0411',
      'T0419',
      'T0425',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0017',
      'K0021',
      'K0042',
      'K0060',
      'K0070',
      'K0077',
      'K0078',
      'K0107',
      'K0109',
      'K0117',
      'K0118',
      'K0119',
      'K0122',
      'K0123',
      'K0125',
      'K0128',
      'K0131',
      'K0132',
      'K0133',
      'K0134',
      'K0145',
      'K0155',
      'K0156',
      'K0167',
      'K0168',
      'K0179',
      'K0182',
      'K0183',
      'K0184',
      'K0185',
      'K0186',
      'K0187',
      'K0188',
      'K0189',
      'K0305',
      'K0624',
    ],
    skills: [
      'S0032',
      'S0046',
      'S0047',
      'S0062',
      'S0065',
      'S0067',
      'S0068',
      'S0069',
      'S0071',
      'S0073',
      'S0074',
      'S0075',
      'S0087',
      'S0088',
      'S0089',
      'S0090',
      'S0091',
      'S0092',
      'S0093',
    ],
    abilities: ['A0005', 'A0175'],
  },
  {
    id: 'IN-FOR-002',
    name: 'サイバー防衛フォレンジックアナリスト',
    specialityArea: 'デジタルフォレンジック (FOR)',
    category: '調査 (IN)',
    description:
      'デジタル証拠を分析し、コンピュータセキュリティインシデントを調査して、システム/ネットワークの脆弱性緩和を支援するための有用な情報を導き出します。',
    tasks: [
      'T0027',
      'T0036',
      'T0048',
      'T0049',
      'T0075',
      'T0087',
      'T0103',
      'T0113',
      'T0165',
      'T0167',
      'T0168',
      'T0172',
      'T0173',
      'T0175',
      'T0179',
      'T0182',
      'T0190',
      'T0212',
      'T0216',
      'T0238',
      'T0240',
      'T0241',
      'T0253',
      'T0279',
      'T0285',
      'T0286',
      'T0287',
      'T0288',
      'T0289',
      'T0312',
      'T0396',
      'T0397',
      'T0398',
      'T0399',
      'T0400',
      'T0401',
      'T0432',
      'T0532',
      'T0546',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0018',
      'K0021',
      'K0042',
      'K0060',
      'K0070',
      'K0077',
      'K0078',
      'K0109',
      'K0117',
      'K0118',
      'K0119',
      'K0122',
      'K0123',
      'K0125',
      'K0128',
      'K0131',
      'K0132',
      'K0133',
      'K0134',
      'K0145',
      'K0155',
      'K0156',
      'K0167',
      'K0168',
      'K0179',
      'K0182',
      'K0183',
      'K0184',
      'K0185',
      'K0186',
      'K0187',
      'K0188',
      'K0189',
      'K0224',
      'K0254',
      'K0255',
      'K0301',
      'K0304',
      'K0347',
      'K0624',
    ],
    skills: [
      'S0032',
      'S0047',
      'S0062',
      'S0065',
      'S0067',
      'S0068',
      'S0069',
      'S0071',
      'S0073',
      'S0074',
      'S0075',
      'S0087',
      'S0088',
      'S0089',
      'S0090',
      'S0091',
      'S0092',
      'S0093',
      'S0131',
      'S0132',
      'S0133',
      'S0156',
    ],
    abilities: ['A0005', 'A0043'],
  },
];
