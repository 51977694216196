import { BaseModel } from '../shared/base.model';

export class VersusAttack extends BaseModel {
  timestamp: Date;
  status: string;
  // public targetId: string;
  targetName: string;
  attackerTeamId: string;
  attackerTeamName: string;
  targetTeamId: string;
  targetTeamName: string;
  taskId: string;

  constructor(data?) {
    super(data);
  }
}
