<div class="versus-attacks">
  <mat-card>
    @if (isAttackerView) {
      <div widgetHandle class="live-card-title">
        <i class="fas fa-bolt" aria-hidden="true"></i
        >{{ 'ui.widget.versus.teamAttacksLive' | translate }}
      </div>
    }
    @if (!isAttackerView) {
      <div widgetHandle class="live-card-title">
        <i class="fas fa-bolt" aria-hidden="true"></i
        >{{ 'ui.widget.versus.redAttacksLive' | translate }}
      </div>
    }
    @if ((attacks.length === 0 && dataLoaded) || !teamId) {
      <cybexer-no-data></cybexer-no-data>
    }
    @for (attack of attacks; track attack) {
      <div class="versus-attack-row">
        <div class="timestamp">[{{ attack.timestamp | isaDate: 'mediumTime' }}]</div>
        <div class="nowrap">{{ 'ui.enums.' + attack.status | translate }}</div>
        @if (isAttackerView) {
          <div class="truncate">
            {{ attack.targetTeamId | isaTeamName }} - {{ attack.targetName }}
          </div>
        }
        @if (!isAttackerView) {
          <div class="truncate">
            {{ attack.targetName }} - {{ attack.attackerTeamId | isaTeamName }}
          </div>
        }
      </div>
    }
  </mat-card>
</div>
