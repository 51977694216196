import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { VsphereManagerBaseService } from '@cybexer/ngx-commons';
import * as Sentry from '@sentry/angular-ivy';
import { Observable, switchMap } from 'rxjs';
import { Exercise } from '../../models';
import { ExerciseService } from './exercise.service';
import { VirtualMachineService } from './virtual-machine.service';

@Injectable({
  providedIn: 'root',
})
export class VsphereManagerService extends VsphereManagerBaseService {
  constructor(
    private exerciseService: ExerciseService,
    private virtualMachineService: VirtualMachineService,
    protected router: Router
  ) {
    super(router);
  }

  getConnectionId(vmId: string): Observable<string> {
    return this.exerciseService
      .getActiveExercise()
      .pipe(
        switchMap((exercise: Exercise) =>
          this.virtualMachineService.getConnectionId(vmId, exercise.id)
        )
      );
  }

  getWebConsoleUrl(connectionId: string): Observable<string> {
    return this.exerciseService
      .getActiveExercise()
      .pipe(
        switchMap((exercise: Exercise) =>
          this.virtualMachineService.getWebConsoleUrl(exercise.id, connectionId)
        )
      );
  }

  createNewWindowUrl(vmId: string, targetName: string): string {
    return this.router.serializeUrl(
      this.router.createUrlTree(['app/gamenet/vconsole'], {
        queryParams: {
          sideNavHidden: true,
          headerHidden: true,
          footerHidden: true,
          id: vmId,
          name: targetName,
        },
      })
    );
  }

  notifySentry(error: any): void {
    try {
      Sentry.setTag('vmId', error.connectionId);
      Sentry.setTag('targetName', error.connectionName);
      Sentry.captureException(Error(error.errorMsg));
    } catch (e) {
      console.error(e);
    }
  }
}
