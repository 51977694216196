import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'isa-exercise-recreate-with-image-dialog',
  templateUrl: './exercise-recreate-with-image-dialog.component.html',
  styleUrls: ['./exercise-recreate-with-image-dialog.component.scss'],
})
export class ExerciseRecreateWithImageDialogComponent {
  constructor(private dialogRef: MatDialogRef<ExerciseRecreateWithImageDialogComponent>) {}

  recreateWithImage() {
    this.dialogRef.close(true);
  }

  recreateWithoutImage() {
    this.dialogRef.close(false);
  }
}
