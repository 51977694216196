export class StorageMock implements Storage {
  length = 0;
  clear(): void {
    return null;
  }
  getItem(key: string): string {
    return null;
  }
  key(index: number): string {
    return null;
  }
  removeItem(key: string): void {
    return null;
  }
  setItem(key: string, value: string): void {
    return null;
  }
}
