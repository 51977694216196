<div class="situation-report">
  <form [formGroup]="reportForm">
    <cybexer-widget-header [title]="'ui.nav.situationReport' | translate" [inline]="true">
      <cybexer-widget-filter>
        <mat-form-field class="team-select">
          <mat-label>{{ 'ui.team' | translate }}</mat-label>
          <mat-select
            formControlName="team"
            required="true"
            class="team-filter-dropdown"
            (selectionChange)="updateImageUploadUrl()"
          >
            @for (team of teams; track team) {
              <mat-option [value]="team.id"> {{ team.customName }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </cybexer-widget-filter>
    </cybexer-widget-header>
    <cybexer-widget-body>
      @if (reportForm.controls['team'].value) {
        <div>
          <mat-card-subtitle class="message-label"
            >{{ 'ui.situationReport.message' | translate }} *</mat-card-subtitle
          >
          <quill-editor
            formControlName="message"
            [required]="true"
            [placeholder]="''"
            [styles]="{ height: 'calc(100vh - 415px)' }"
            (onEditorCreated)="addQuillImageHandler($event)"
          >
          </quill-editor>
        </div>
      }
      @if (!reportForm.controls['message'].valid && reportForm.controls['message'].touched) {
        <mat-error class="mat-warn">
          {{ 'ui.situationReport.messageRequired' | translate }}
        </mat-error>
      }
      @if (reportForm.controls['team'].value) {
        <cybexer-confirm-action [disabled]="!isMessageChanged">
          <button
            mat-raised-button
            #submit
            type="submit"
            color="primary"
            class="submit-button"
            [disabled]="!isMessageChanged"
            (click)="onSubmit(reportForm)"
          >
            {{ 'ui.submit' | translate }}
          </button>
        </cybexer-confirm-action>
      }
    </cybexer-widget-body>
  </form>
</div>
