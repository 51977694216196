import { BaseModel } from './base.model';

export class IsaErrorMessage extends BaseModel {
  type: IsaErrorType;
  error?: string;

  constructor(data?) {
    super(data);
  }
}

export enum IsaErrorType {
  CTF_TASK_LOCKED_ERROR = 'CTF_TASK_LOCKED_ERROR',
  GIT_CONNECTION_ERROR = 'GIT_CONNECTION_ERROR',
  GIT_MISSING_SCRIPTS_ERROR = 'GIT_MISSING_SCRIPTS_ERROR',
  PATTERN_PARSING_ERROR = 'PATTERN_PARSING_ERROR',
  INTERNAL_ERROR = 'INTERNAL_ERROR',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  USER_ALREADY_HAS_TEAM_ALLOCATED = 'USER_ALREADY_HAS_TEAM_ALLOCATED',
  EXERCISE_NOT_RUNNING_ERROR = 'EXERCISE_NOT_RUNNING_ERROR',
  EXERCISE_RESET_NEEDED_ERROR = 'EXERCISE_RESET_NEEDED_ERROR',
  TASK_EVENT_PROCESSING_ERROR = 'TASK_EVENT_PROCESSING_ERROR',
}
