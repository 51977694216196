import { BaseModel } from './base.model';

export class ProductKey extends BaseModel {
  key: string;
  licensedTo: string;
  validUntil: Date;
  numberOfUsers: number;

  constructor(data?) {
    super(data);

    if (data.hasOwnProperty('validUntil')) {
      this.validUntil = new Date(data.validUntil);
    }
  }
}

export class ProductKeySaveDTO {
  value: String;
}
