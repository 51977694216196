import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { filter, tap } from 'rxjs/operators';
import { Exercise, TeamStatusSummary } from '../../../../models';
import { TeamNamePipe } from '../../../../pipes';
import { ExerciseService, IntervalService, StatusSummaryService } from '../../../../services';
import { SetFilterProperty } from '../../../../shared';
import { TeamStatusChartData } from '../status-chart-data';

@UntilDestroy()
@Component({
  selector: 'isa-chart',
  templateUrl: './team-status.component.html',
  styleUrls: ['./team-status.component.scss'],
})
export class TeamStatusComponent implements OnInit {
  exerciseId: string;
  teamStatusChartData: TeamStatusChartData = new TeamStatusChartData();
  loading = true;

  constructor(
    private statusSummaryService: StatusSummaryService,
    private exerciseService: ExerciseService,
    private intervalService: IntervalService,
    private router: Router,
    private teamNamePipe: TeamNamePipe,
    private store: Store
  ) {}

  ngOnInit(): void {
    this.exerciseService.activeExercise
      .pipe(
        filter((exercise: Exercise) => !!exercise),
        untilDestroyed(this)
      )
      .subscribe((exercise: Exercise) => {
        this.exerciseId = exercise.id;
        this.loading = true;
        this.teamStatusChartData.clearData();
        this.processData();
      });

    this.intervalService
      .getWidgetRefreshInterval()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.processData();
      });
  }

  processData(): void {
    if (this.exerciseId) {
      this.statusSummaryService
        .getTeamStatusData(this.exerciseId)
        .pipe(
          filter((data) => !!(data && data.length)),
          tap((data: TeamStatusSummary[]) =>
            data.forEach((it) => (it.teamName = this.teamNamePipe.transform(it.teamId)))
          ),
          untilDestroyed(this)
        )
        .subscribe((data) => {
          this.teamStatusChartData.setData(data);
          this.loading = false;
        });
    }
  }

  barClicked(barIndex: number): void {
    const teamId = this.teamStatusChartData.data[barIndex].teamId;
    this.store
      .dispatch(new SetFilterProperty('team', teamId))
      .subscribe(() => this.router.navigate(['/app/gamenet/segments-status']));
  }
}
