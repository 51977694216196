import { BaseModel } from './base.model';

export class HealthCheckData extends BaseModel {
  timestamp: Date;
  data: HealthCheckDataItem[];

  constructor(data?) {
    super(data);

    if (data && data.hasOwnProperty('data')) {
      this.data = data.data.map((it) => new HealthCheckDataItem(it));
    }
  }
}

export class HealthCheckDataItem extends BaseModel {
  isUp: boolean;
  type: HealthCheckType;
  name?: string;
  description?: string;
  localHost?: string;
  checks: HealthCheckDataItem[] = [];

  constructor(data?) {
    super(data);

    if (data && data.hasOwnProperty('checks')) {
      this.checks = data.checks.map((it) => new HealthCheckDataItem(it));
    }
  }
}

export class GroupedHealthCheckData {
  constructor(
    public isaModule: string,
    public items: HealthCheckDataItem[]
  ) {}
}

export enum HealthCheckType {
  SERVICE = 'SERVICE',
  DB = 'DB',
  EXTERNAL = 'EXTERNAL',
}
