import { Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import {
  SetFilterProperties,
  SetFilterProperty,
  SetFilterPropertyIfEmptyOrDefault,
} from './filter.actions';
import {
  FilterState,
  FilterStateModel,
  FilterStateProperty,
  FilterStateValue,
} from './filter.state';

@Injectable()
export class FilterStateService {
  constructor(private store: Store) {}

  filter$ = (...filterProperties: FilterStateProperty[]): Observable<Partial<FilterStateModel>> => {
    return this.store.select(FilterState.slice(...filterProperties));
  };

  snapshot(): FilterStateModel {
    return this.store.selectSnapshot(FilterState);
  }

  get(property: FilterStateProperty): FilterStateValue {
    return this.snapshot()[property];
  }

  @Dispatch()
  setFilter = (name: FilterStateProperty, value?: FilterStateValue) =>
    new SetFilterProperty(name, value);

  @Dispatch()
  setFilterIfEmptyOrDefault = (name: FilterStateProperty, value?: FilterStateValue) =>
    new SetFilterPropertyIfEmptyOrDefault(name, value);

  @Dispatch()
  setFilters = (filter: Partial<FilterStateModel>) => new SetFilterProperties(filter);
}
