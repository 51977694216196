import { Injectable } from '@angular/core';

@Injectable()
export class FormUtil {
  static markFormControlAsTouchedRecursively = (formControl) => {
    (<any>Object).values(formControl.controls).forEach((control) => {
      control.markAsTouched();

      if (control.controls) {
        FormUtil.markFormControlAsTouchedRecursively(control);
      }
    });
  };

  static getStringCharacterCount(message: string = ''): number {
    return message.replace(/[^\w]/g, '').length;
  }

  static replaceNewlines(
    message: string = '',
    maximumNewlineCount: number = 4,
    replaceNewlineCount: number = 3
  ): string {
    const searchValue = new RegExp(`\\n{${maximumNewlineCount},}`, 'g');
    let replaceValue = '';

    if (replaceNewlineCount > 0) {
      let count = 0;
      while (count < replaceNewlineCount) {
        replaceValue += '\n';
        count++;
      }
    }
    return message.replace(searchValue, replaceValue);
  }
}
