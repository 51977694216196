import { Pipe, PipeTransform } from '@angular/core';
import { LanguageUtils } from '../shared/language.utils';

@Pipe({
  name: 'convertLanguageCode',
})
export class ConvertLanguageCodePipe implements PipeTransform {
  constructor(private languageUtils: LanguageUtils) {}

  transform(language: string): string {
    return this.languageUtils.convertISO639_1ToNativeLanguage(language);
  }
}
