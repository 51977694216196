import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { Integration } from '../../../../models';
import {
  IntegrationEvent,
  IntegrationEventSaveDTO,
  IntegrationEventSubjectType,
  IntegrationEventType,
} from '../../../../models/shared/integration-event.model';
import { FormUtil } from '../../../form.util';

@UntilDestroy()
@Component({
  selector: 'isa-zero-configuration-event-form',
  templateUrl: './zero-configuration-event-form.component.html',
  styleUrls: ['./zero-configuration-event-form.component.scss'],
})
export class ZeroConfigurationEventFormComponent implements OnInit, OnChanges {
  eventForm: UntypedFormGroup;

  @Input() subjectId: string;
  @Input() subjectType: IntegrationEventSubjectType;
  @Input() eventType: IntegrationEventType;
  @Input() event?: IntegrationEvent;
  @Input() integration?: Integration;
  @Input() triggerSubmit: Subject<void>;
  @Output() onChange = new EventEmitter<IntegrationEventSaveDTO>();

  constructor() {}

  ngOnInit() {
    if (this.triggerSubmit) {
      this.triggerSubmit.pipe(untilDestroyed(this)).subscribe(() => this.onSubmit());
    }
  }

  ngOnChanges() {
    if (!this.event) {
      this.event = IntegrationEvent.constructFromData({ type: this.eventType });
    }
    this.eventForm = new UntypedFormGroup({
      type: new UntypedFormControl(this.eventType, Validators.required),
    });
  }

  onSubmit = () => {
    if (!this.eventForm.valid) {
      FormUtil.markFormControlAsTouchedRecursively(this.eventForm);
      return;
    }
    const saveDTO = IntegrationEventSaveDTO.constructFromData(this.eventForm.value);
    this.onChange.emit(saveDTO);
  };
}
