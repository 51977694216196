import { Pipe, PipeTransform } from '@angular/core';
import { NameFilter } from '../../shared/name-filter';

@Pipe({
  name: 'nameFilter',
})
export class NameFilterPipe implements PipeTransform {
  transform<T extends NameFilter>(targets: T[], filter: any): T[] {
    if (targets && filter) {
      filter = filter.toString().toLowerCase();
      return targets.filter(
        (nameFilterInstance) => nameFilterInstance.name.toLowerCase().indexOf(filter) !== -1
      );
    }
    return targets;
  }
}
