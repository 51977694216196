import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TagValidator {
  static NOT_ALL_TAGS_PROCESSED_MSG = 'ui.attackCampaign.tagProcessedError';

  private static TAG_REG_EXP = '{@.*?@}';
  private static TAG_REPLACEMENT_REG_EXP = '[{}@]';

  getNotProcessedTags(input: string): string | null {
    const tagRegExp = new RegExp(TagValidator.TAG_REG_EXP, 'g');
    const tagReplacementRegExp = new RegExp(TagValidator.TAG_REPLACEMENT_REG_EXP, 'g');
    if (!tagRegExp.test(input)) return null;

    return input
      .match(tagRegExp)
      .map((it) => it.replace(tagReplacementRegExp, ''))
      .join(', ');
  }
}
