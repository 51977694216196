import { BaseModel } from '../../shared/base.model';
import { CTFTaskStatus } from './ctf-task-status.model';

export class CTFValidatedTasksData extends BaseModel {
  exerciseId: string;
  teamId: string;
  taskId: string;
  status: CTFTaskStatus;
  timestamp: Date;
  score: number;

  constructor(data?) {
    super(data);
  }
}
