import { Component, OnInit } from '@angular/core';
import { LanguageUtils } from '../../shared/language.utils';
import { SettingsService } from '../../services';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'isa-intro-layout',
  templateUrl: './intro-layout.component.html',
  styleUrls: ['./intro-layout.component.scss'],
})
export class IntroLayoutComponent implements OnInit {
  selectedLanguage: string = 'en';
  filteredLanguages: string[] = [];
  enabledLanguages: string[] = [];

  constructor(
    private settingsService: SettingsService,
    private languageUtils: LanguageUtils,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.settingsService.getSettings().subscribe((settings) => {
      this.enabledLanguages = settings.enabledLanguages;
      this.selectedLanguage = this.languageUtils.getLanguage();
      this.filteredLanguages = this.languageUtils.filterLanguages(this.enabledLanguages);
      this.settingsService.settingsChanged.next(settings);
    });
  }

  switchLanguage(language: string) {
    this.languageUtils.setLang(language);
    this.translate.use(language);
    this.filteredLanguages = this.languageUtils.filterLanguages(this.enabledLanguages);
    this.selectedLanguage = language;
  }
}
