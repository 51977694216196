import { LOCALE_ID, NgModule } from '@angular/core';
import { CustomValidators } from './customValidators/CustomValidators';
import { DateUtil } from './date.util';
import { EventSourceUtil } from './eventsource.util';
import { ExerciseUtil } from './exercise.util';
import { FormUtil } from './form.util';
import { localeServiceFactory } from './locale/locale-service.factory';
import { LocaleService } from './locale/locale.service';
import { QuillEditorUtil } from './quill-editor.util';
import { ServiceUtil } from './service.util';
import { FilterStateService } from './state';

@NgModule({
  providers: [
    CustomValidators,
    DateUtil,
    EventSourceUtil,
    ExerciseUtil,
    FilterStateService,
    FormUtil,
    LocaleService,
    QuillEditorUtil,
    ServiceUtil,
    {
      provide: LOCALE_ID,
      deps: [LocaleService],
      useFactory: localeServiceFactory,
    },
  ],
})
export class SharedModule {}
