import { BaseModel } from '../shared/base.model';

export abstract class IsaNotification extends BaseModel {
  id: string;
  title: string;
  description: string;
  timestamp: Date;
  isNew: boolean;
  type: NotificationType;

  static constructFromData(data: object): IsaNotification {
    switch (data['type']) {
      case NotificationType.NEWS_INJECT:
        return new NewsInjectNotification(data);
      case NotificationType.WARNING:
        return new WarningNotification(data);
    }
  }
}

export class NewsInjectNotification extends IsaNotification {
  type = NotificationType.NEWS_INJECT;
  id: string;
  exerciseId: string;
  teamId: string;
  title: string;
  description: string;
  timestamp: Date;
  isNew: boolean;
  mediaId: string;
  thumbnailId: string;
  newsInjectId: string;
  publicationId: string;
}

export class WarningNotification extends IsaNotification {
  type = NotificationType.WARNING;
  id: string;
  exerciseId: string;
  teamId: string;
  title: string;
  description: string;
  timestamp: Date;
  isNew: boolean;
}

export enum NotificationType {
  WARNING = 'WARNING',
  NEWS_INJECT = 'NEWS_INJECT',
}
