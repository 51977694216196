import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Exercise } from '../../../models';
import { AuthenticationService, ExerciseService } from '../../../services';

@Component({
  selector: 'isa-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  isExerciseAutomaticallySelected = false;
  activeExercise: BehaviorSubject<Exercise>;

  constructor(
    private authenticationService: AuthenticationService,
    private exerciseService: ExerciseService
  ) {}

  ngOnInit() {
    this.activeExercise = this.exerciseService.activeExercise;
    const permissions = this.authenticationService.currentUser.permissions;
    const gnPermission = permissions.filter((permission) => permission.domain === 'gn_exercise')[0];
    const isOnlyOneExercise =
      gnPermission && gnPermission.targets.length === 1 && gnPermission.targets[0] !== '*';

    if (isOnlyOneExercise) {
      this.isExerciseAutomaticallySelected = true;
      this.exerciseService.selectExercise(gnPermission.targets[0], true);
    }
  }
}
