import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CTFMission,
  CTFTaskBulkEvent,
  CTFTaskBulkEventEntityType,
  CTFTaskBulkEventSaveResult,
  CTFTaskBulkEventType,
  CTFTaskDetailsDTO,
  CTFTaskEventSaveResult,
  CTFTaskTeamUnassignmentEventData,
  CTFValidatedTasksData,
  VersusTaskTeamDTO,
} from '../../../models';
import { CTFMissionStatus } from '../../../models/gamenet/ctf/ctf-mission-status.model';
import { CTFHintUseDTO } from '../../../models/gamenet/exercise.model';
import { EventSourceUtil } from '../../../shared/eventsource.util';

type TaskOperation =
  | 'solve'
  | 'validate'
  | 'start'
  | 'abandon'
  | 'next-hint'
  | 'feedback'
  | 'lock'
  | 'start-attack'
  | 'abandon-attack'
  | 'unlock';
type MissionOperation = 'start' | 'stop';

@Injectable({
  providedIn: 'root',
})
export class MissionBoardService {
  reloadStatus$ = new BehaviorSubject<any>(null);
  reloadMission$ = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private zone: NgZone
  ) {}

  getData(exerciseId: string, teamId: string): Observable<CTFMission> {
    return this.http
      .get<CTFMission>(`${MissionBoardService.getBaseUrl(exerciseId, teamId)}`)
      .pipe(map((data) => new CTFMission(data)));
  }

  getTaskDetails(
    exerciseId: string,
    teamId: string,
    taskId: string
  ): Observable<CTFTaskDetailsDTO> {
    return this.http
      .get<CTFTaskDetailsDTO>(
        `${MissionBoardService.getBaseUrl(exerciseId, teamId)}/tasks/${taskId}`
      )
      .pipe(map((data) => new CTFTaskDetailsDTO(data)));
  }

  getVersusTaskTeams(
    exerciseId: string,
    teamId: string,
    taskId: string
  ): Observable<VersusTaskTeamDTO[]> {
    return this.http
      .get<
        VersusTaskTeamDTO[]
      >(`${MissionBoardService.getBaseUrl(exerciseId, teamId)}/tasks/${taskId}/versus-teams`)
      .pipe(map((data) => data.map((versusTeam) => new VersusTaskTeamDTO(versusTeam))));
  }

  getStatus(exerciseId: string, teamId: string): Observable<CTFMissionStatus> {
    return this.http
      .get<CTFMissionStatus>(`${MissionBoardService.getBaseUrl(exerciseId, teamId)}/status`)
      .pipe(map((data) => new CTFMissionStatus(data)));
  }

  reloadStatus() {
    this.reloadStatus$.next(true);
  }

  reloadMission() {
    this.reloadMission$.next(true);
  }

  postFeedback(
    exerciseId: string,
    teamId: string,
    taskId: string,
    feedback: string
  ): Observable<CTFTaskEventSaveResult> {
    return this.postTaskOperation(exerciseId, teamId, taskId, 'feedback', { feedback: feedback });
  }

  solveTask(
    exerciseId: string,
    teamId: string,
    taskId: string,
    answer: string
  ): Observable<CTFTaskEventSaveResult> {
    return this.postTaskOperation(exerciseId, teamId, taskId, 'solve', { answer: answer });
  }

  validateTask(
    exerciseId: string,
    teamId: string,
    taskId: string,
    answer: string
  ): Observable<CTFTaskEventSaveResult> {
    return this.postTaskOperation(
      exerciseId,
      teamId,
      taskId,
      'validate',
      answer ? { answer: answer } : null
    );
  }

  startTask(
    exerciseId: string,
    teamId: string,
    taskId: string
  ): Observable<CTFTaskEventSaveResult> {
    return this.postTaskOperation(exerciseId, teamId, taskId, 'start');
  }

  abandonTask(
    exerciseId: string,
    teamId: string,
    taskId: string
  ): Observable<CTFTaskEventSaveResult> {
    return this.postTaskOperation(exerciseId, teamId, taskId, 'abandon');
  }

  startAttack(
    exerciseId: string,
    teamId: string,
    taskId: string,
    targetTeamId: string
  ): Observable<CTFTaskEventSaveResult> {
    return this.postTaskOperation(exerciseId, teamId, taskId, 'start-attack', {
      targetTeamId: targetTeamId,
    });
  }

  abandonAttack(
    exerciseId: string,
    teamId: string,
    taskId: string,
    targetTeamId: String
  ): Observable<CTFTaskEventSaveResult> {
    return this.postTaskOperation(exerciseId, teamId, taskId, 'abandon-attack', {
      targetTeamId: targetTeamId,
    });
  }

  getNextHint(exerciseId: string, teamId: string, taskId: string): Observable<CTFHintUseDTO> {
    return this.http
      .post<CTFHintUseDTO>(
        MissionBoardService.getTaskOperationsUrl(exerciseId, teamId, taskId, 'next-hint'),
        null
      )
      .pipe(map((data) => new CTFHintUseDTO(data)));
  }

  lockCategoryTasksForAllTeams(
    exerciseId: string,
    teamId: string,
    category: string
  ): Observable<CTFTaskBulkEventSaveResult> {
    return this.postTaskBulkOperation(
      exerciseId,
      CTFTaskBulkEventType.LOCK,
      CTFTaskBulkEventEntityType.CATEGORY,
      teamId,
      null,
      category
    );
  }

  unlockCategoryTasksForAllTeams(
    exerciseId: string,
    teamId: string,
    category: string
  ): Observable<CTFTaskBulkEventSaveResult> {
    return this.postTaskBulkOperation(
      exerciseId,
      CTFTaskBulkEventType.UNLOCK,
      CTFTaskBulkEventEntityType.CATEGORY,
      teamId,
      null,
      category
    );
  }

  lockTaskForAllTeams(
    exerciseId: string,
    teamId: string,
    taskId: string
  ): Observable<CTFTaskBulkEventSaveResult> {
    return this.postTaskBulkOperation(
      exerciseId,
      CTFTaskBulkEventType.LOCK,
      CTFTaskBulkEventEntityType.TEAM,
      teamId,
      taskId
    );
  }

  unlockTaskForAllTeams(
    exerciseId: string,
    teamId: string,
    taskId: string
  ): Observable<CTFTaskBulkEventSaveResult> {
    return this.postTaskBulkOperation(
      exerciseId,
      CTFTaskBulkEventType.UNLOCK,
      CTFTaskBulkEventEntityType.TEAM,
      teamId,
      taskId
    );
  }

  lockTask(exerciseId: string, teamId: string, taskId: string): Observable<CTFTaskEventSaveResult> {
    return this.postTaskOperation(exerciseId, teamId, taskId, 'lock');
  }

  unlockTask(
    exerciseId: string,
    teamId: string,
    taskId: string
  ): Observable<CTFTaskEventSaveResult> {
    return this.postTaskOperation(exerciseId, teamId, taskId, 'unlock');
  }

  startMission(exerciseId: string, teamId: string): Observable<boolean> {
    return this.postMissionOperation(exerciseId, teamId, 'start');
  }

  stopMission(exerciseId: string, teamId: string): Observable<boolean> {
    return this.postMissionOperation(exerciseId, teamId, 'stop');
  }

  listenForTaskValidationChangeEvent(
    exerciseId: string,
    teamId: string
  ): Observable<CTFValidatedTasksData> {
    return EventSourceUtil.create(
      `${MissionBoardService.getBaseUrl(exerciseId, teamId)}/tasks/validation/subscribe`,
      this.zone,
      CTFValidatedTasksData
    );
  }

  listenForTeamUnassignmentEvent(
    exerciseId: string,
    teamId: string
  ): Observable<CTFTaskTeamUnassignmentEventData> {
    return EventSourceUtil.create(
      `${MissionBoardService.getBaseUrl(exerciseId, teamId)}/tasks/team/unassignment/subscribe`,
      this.zone,
      CTFTaskTeamUnassignmentEventData
    );
  }

  getImageUploadUrl(exerciseId: string, teamId: string): string {
    return `${MissionBoardService.getBaseUrl(exerciseId, teamId)}/images`;
  }

  private postTaskOperation(
    exerciseId: string,
    teamId: string,
    taskId: string,
    operation: TaskOperation,
    payload: any = null
  ): Observable<CTFTaskEventSaveResult> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return this.http
      .post<CTFTaskEventSaveResult>(
        MissionBoardService.getTaskOperationsUrl(exerciseId, teamId, taskId, operation),
        payload,
        httpOptions
      )
      .pipe(map((data) => new CTFTaskEventSaveResult(data)));
  }

  private postMissionOperation(
    exerciseId: string,
    teamId: string,
    operation: MissionOperation,
    payload: any = null
  ): Observable<boolean> {
    return this.http
      .post<boolean>(
        MissionBoardService.getMissionOperationsUrl(exerciseId, teamId, operation),
        payload
      )
      .pipe(map(() => true));
  }

  private postTaskBulkOperation(
    exerciseId: string,
    type: CTFTaskBulkEventType,
    entityType: CTFTaskBulkEventEntityType,
    teamId: string = null,
    taskId: string = null,
    category: string = null
  ): Observable<CTFTaskBulkEventSaveResult> {
    const payload = new CTFTaskBulkEvent(type, entityType, teamId, taskId, category);
    return this.http
      .post<CTFTaskBulkEventSaveResult>(
        MissionBoardService.getTaskBulkOperationsUrl(exerciseId),
        payload
      )
      .pipe(map((data) => new CTFTaskBulkEventSaveResult(data)));
  }

  private static getTaskOperationsUrl(
    exerciseId: string,
    teamId: string,
    taskId: string,
    operation: TaskOperation
  ): string {
    return `${MissionBoardService.getBaseUrl(exerciseId, teamId)}/tasks/${taskId}/${operation}`;
  }

  private static getMissionOperationsUrl(
    exerciseId: string,
    teamId: string,
    operation: MissionOperation
  ): string {
    return `${MissionBoardService.getBaseUrl(exerciseId, teamId)}/${operation}`;
  }

  private static getBaseUrl(exerciseId: string, teamId: string): string {
    return `api/ctf-exercises/${exerciseId}/teams/${teamId}/mission`;
  }

  private static getTaskBulkOperationsUrl(exerciseId: string): string {
    return `api/ctf-exercises/${exerciseId}/tasks/bulk`;
  }
}
