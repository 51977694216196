import { BaseModel } from '../shared/base.model';
import { TargetConsoleData } from './target.model';

export class TargetStatusSummary extends BaseModel {
  targetId: string;
  targetName: string;
  targetChecks: TargetStatusSummaryTargetCheck[];
  targetRevertPenalty: number;
  powerState?: string;
  vmId?: string;
  consoleData?: TargetConsoleData;

  constructor(data?) {
    super(data);

    if (data.hasOwnProperty('targetChecks')) {
      this.targetChecks = data.targetChecks.map(
        (targetCheck) => new TargetStatusSummaryTargetCheck(targetCheck)
      );
    }
    if (data.hasOwnProperty('consoleData')) {
      this.consoleData = new TargetConsoleData(data.consoleData);
    }
  }
}

export class TargetStatusSummaryTargetCheck extends BaseModel {
  id: string;
  name: string;
  status: string;
  username: string;

  constructor(data?) {
    super(data);
  }
}

export enum TargetStatus {
  COMPROMISED = 'COMPROMISED',
  GOOD = 'GOOD',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}

export enum TargetPowerState {
  POWERED_OFF = 'POWERED_OFF',
  POWERED_ON = 'POWERED_ON',
  SUSPENDED = 'SUSPENDED',
  UNKNOWN = 'UNKNOWN',
}

export class TargetCheckAssignment {
  constructor(
    public targetCheckId: string,
    public username: string
  ) {}
}
