<footer>
  <mat-toolbar class="isa-footer">
    <cybexer-flex gap="4" verticalAlign="center" horizontalAlign="justify" class="full-size">
      <div class="isa-version ellipsis">
        <cybexer-popover class="isa-version-data" [disable]="!isAdmin || !buildCommitMeta">
          <div label class="version-label">{{ version }}</div>
          <div class="popover-container">
            <div class="title">{{ 'ui.footer.buildCommitMeta' | translate }}</div>
            <div class="disable-uppercase commit-data">
              <i class="fa-solid fa-code-branch"></i>{{ buildCommitMeta?.branch }}
            </div>
            <div class="disable-uppercase commit-data">
              <i class="fa-solid fa-code-commit"></i>{{ buildCommitMeta?.id }} /
              {{ buildCommitMeta?.shortId }}
            </div>
          </div>
        </cybexer-popover>
      </div>
      <cybexer-flex gap="2" class="logos">
        @if (customLabelSrc) {
          <img class="custom-label" [src]="customLabelSrc | isaSafeUrl" />
        } @else {
          <div class="logo-text ellipsis">
            {{ 'ui.footer.poweredByCybexerTechnologies' | translate }}
          </div>
          @if (isLightTheme()) {
            <img class="cybexer-logo" src="./assets/img/Cybexer-RGB-Logo.svg" alt="CybExer" />
          } @else {
            <img
              class="cybexer-logo"
              src="./assets/img/Cybexer-RGB-Logo-reversed-out.svg"
              alt="CybExer"
            />
          }
        }
      </cybexer-flex>
    </cybexer-flex>
  </mat-toolbar>
</footer>
