import { Injectable } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { Settings } from '../../models/shared/settings.model';
import { SettingsService } from '../shared/settings.service';

@Injectable()
export class IntervalService {
  private static WIDGET_REFRESH_INTERVAL_PROP = 'widgetRefreshInterval';

  constructor(private settingsService: SettingsService) {}

  getWidgetRefreshInterval(): Observable<number> {
    return this.getRefreshIntervalFromSettings(IntervalService.WIDGET_REFRESH_INTERVAL_PROP);
  }

  getWidgetRefreshIntervalWithDelay(): Observable<number> {
    return this.getRefreshIntervalFromSettings(IntervalService.WIDGET_REFRESH_INTERVAL_PROP, true);
  }

  getInterval(period: number): Observable<number> {
    return interval(period).pipe(startWith(0));
  }

  getIntervalWithDelay(period: number): Observable<number> {
    return interval(period);
  }

  private getRefreshIntervalFromSettings(
    intervalProp: string,
    withDelay: boolean = false
  ): Observable<number> {
    return this.settingsService.getSettings(true).pipe(
      switchMap((settings: Settings) => {
        const intervalMs = IntervalService.getIntervalFromSettingsProperty(settings, intervalProp);
        return withDelay ? this.getIntervalWithDelay(intervalMs) : this.getInterval(intervalMs);
      })
    );
  }

  private static getIntervalFromSettingsProperty(settings: Settings, intervalProp: string): number {
    return intervalProp
      .split('.')
      .reduce((obj: any, nestedProperty) => obj[nestedProperty], settings) as number;
  }
}
