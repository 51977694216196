<cybexer-simple-table
  [tableData]="roleData"
  [columns]="roleColumns"
  [isPageable]="true"
  [defaultPageSize]="defaultPageSize"
  [paginationSizes]="pageSizes"
  [customSort]="true"
  (sort)="sortData($event)"
></cybexer-simple-table>
@if (!roleData.length && !loading) {
  <cybexer-no-data></cybexer-no-data>
}
@if (loading) {
  <cybexer-progress-spinner></cybexer-progress-spinner>
}
