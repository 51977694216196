import { BaseModel } from '../shared/base.model';
import { ScoreType } from './score-type.model';

export class TeamScoringData extends BaseModel {
  teamId: string;
  teamName: string;
  scores: Score[];

  constructor(data?) {
    super(data);

    if (data.hasOwnProperty('scores')) {
      this.scores = data.scores.map((score) => new Score(score));
    }
  }
}

export class Score extends BaseModel {
  category: ScoreType;
  value: number;
  networkSegmentScores?: NetworkSegmentScore;

  constructor(data?) {
    super(data);

    if (data.hasOwnProperty('networkSegmentScores')) {
      this.networkSegmentScores = data.networkSegmentScores.map(
        (ns) => new NetworkSegmentScore(ns)
      );
    }
  }
}

export class NetworkSegmentScore extends BaseModel {
  name: string;
  value: number;

  constructor(data?) {
    super(data);
  }
}
