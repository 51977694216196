import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationsService } from '@cybexer/ngx-commons';
import { finalize } from 'rxjs/operators';
import { BlueTeam, Exercise, SpecialScoring } from '../../../../models';
import { AuthenticationService, SpecialScoringService } from '../../../../services';
import { FormUtil } from '../../../../shared';

@UntilDestroy()
@Component({
  selector: 'isa-special-scoring-dialog',
  templateUrl: './special-scoring-dialog.component.html',
  styleUrls: ['./special-scoring-dialog.component.scss'],
})
export class SpecialScoringDialogComponent implements OnInit {
  exercise: Exercise;
  teams: BlueTeam[];
  specialScoreForm: UntypedFormGroup;
  processing = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private specialScoringService: SpecialScoringService,
    private notificationsService: NotificationsService,
    private authenticationService: AuthenticationService,
    private dialogRef: MatDialogRef<SpecialScoringDialogComponent>
  ) {}

  ngOnInit() {
    this.exercise = this.data['exercise'];
    this.teams = this.data['teams'];
    this.specialScoreForm = new UntypedFormGroup({
      team: new UntypedFormControl('', Validators.required),
      score: new UntypedFormControl('', [
        Validators.required,
        Validators.min(-1 * this.exercise.maxSpecialScore),
        Validators.max(this.exercise.maxSpecialScore),
      ]),
      description: new UntypedFormControl('', Validators.required),
    });
  }

  onSubmit(form: UntypedFormGroup): void {
    if (form.valid) {
      const specialScoring = new SpecialScoring({
        teamId: form.value.team,
        score: form.value.score,
        username: this.authenticationService.currentUser.username,
        description: SpecialScoringDialogComponent.formatDescription(form.value.description),
      });
      this.processing = true;
      this.specialScoringService
        .createSpecialScoring(this.exercise.id, specialScoring)
        .pipe(
          finalize(() => (this.processing = false)),
          untilDestroyed(this)
        )
        .subscribe(() => {
          this.notificationsService.success('ui.specialScoring.scoreAdded');
          this.dialogRef.close();
          this.specialScoreForm.reset();
        });
    } else {
      Object.keys(form.controls).forEach((c) => form.controls[c].markAsTouched());
    }
  }

  isNumberOrMinus(event) {
    return event.keyCode === 8 || event.key.match(/[0-9\-\ ]/g) != null; // 8 is Backspace
  }

  onPaste(event) {
    event.preventDefault();
  }

  private static formatDescription(feedback?: string): string {
    if (feedback != null && FormUtil.getStringCharacterCount(feedback.trim()) > 0) {
      return FormUtil.replaceNewlines(feedback);
    }
    return '';
  }
}
