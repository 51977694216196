export const NiceDictionary = {
  categories: [
    {
      name: 'tasks',
      items: [
        {
          id: 'T0001',
          value:
            'Acquire and manage the necessary resources, including leadership support, financial resources, and key security personnel, to support information technology (IT) security goals and objectives and reduce overall organizational risk.',
          softSkill: false,
        },
        {
          id: 'T0002',
          value:
            'Acquire necessary resources, including financial resources, to conduct an effective enterprise continuity of operations program.',
          softSkill: false,
        },
        {
          id: 'T0003',
          value:
            'Advise senior management (e.g., Chief Information Officer [CIO]) on risk levels and security posture.',
          softSkill: false,
        },
        {
          id: 'T0004',
          value:
            'Advise senior management (e.g., CIO) on cost/benefit analysis of information security programs, policies, processes, systems, and elements.',
          softSkill: false,
        },
        {
          id: 'T0005',
          value:
            "Advise appropriate senior leadership or Authorizing Official of changes affecting the organization's cybersecurity posture.",
          softSkill: false,
        },
        {
          id: 'T0006',
          value: "Advocate organization's official position in legal and legislative proceedings.",
          softSkill: false,
        },
        {
          id: 'T0007',
          value: 'Analyze and define data requirements and specifications.',
          softSkill: false,
        },
        {
          id: 'T0008',
          value: 'Analyze and plan for anticipated changes in data capacity requirements.',
          softSkill: false,
        },
        {
          id: 'T0009',
          value:
            'Analyze information to determine, recommend, and plan the development of a new application or modification of an existing application.',
          softSkill: false,
        },
        {
          id: 'T0010',
          value:
            "Analyze organization's cyber defense policies and configurations and evaluate compliance with regulations and organizational directives.",
          softSkill: false,
        },
        {
          id: 'T0011',
          value:
            'Analyze user needs and software requirements to determine feasibility of design within time and cost constraints.',
          softSkill: false,
        },
        {
          id: 'T0012',
          value:
            'Analyze design constraints, analyze trade-offs and detailed system and security design, and consider life cycle support.',
          softSkill: false,
        },
        {
          id: 'T0013',
          value:
            'Apply coding and testing standards, apply security testing tools including "\'fuzzing" staticanalysis code scanning tools, and conduct code reviews.',
          softSkill: false,
        },
        {
          id: 'T0014',
          value: 'Apply secure code documentation.',
          softSkill: false,
        },
        {
          id: 'T0015',
          value:
            'Apply security policies to applications that interface with one another, such as Business-toBusiness (B2B) applications.',
          softSkill: false,
        },
        {
          id: 'T0016',
          value: 'Apply security policies to meet security objectives of the system.',
          softSkill: false,
        },
        {
          id: 'T0017',
          value:
            "Apply service-oriented security architecture principles to meet organization's confidentiality, integrity, and availability requirements.",
          softSkill: false,
        },
        {
          id: 'T0018',
          value: 'Assess the effectiveness of cybersecurity measures utilized by system(s).',
          softSkill: false,
        },
        {
          id: 'T0019',
          value:
            'Assess threats to and vulnerabilities of computer system(s) to develop a security risk profile.',
          softSkill: false,
        },
        {
          id: 'T0020',
          value: 'Develop content for cyber defense tools.',
          softSkill: false,
        },
        {
          id: 'T0021',
          value:
            'Build, test, and modify product prototypes using working models or theoretical models.',
          softSkill: false,
        },
        {
          id: 'T0022',
          value:
            'Capture security controls used during the requirements phase to integrate security within the process, to identify key security objectives, and to maximize software security while minimizing disruption to plans and schedules.',
          softSkill: false,
        },
        {
          id: 'T0023',
          value:
            'Characterize and analyze network traffic to identify anomalous activity and potential threats to network resources.',
          softSkill: false,
        },
        {
          id: 'T0024',
          value: 'Collect and maintain data needed to meet system cybersecurity reporting.',
          softSkill: false,
        },
        {
          id: 'T0025',
          value:
            'Communicate the value of information technology (IT) security throughout all levels of the organization stakeholders.',
          softSkill: false,
        },
        {
          id: 'T0026',
          value:
            'Compile and write documentation of program development and subsequent revisions, inserting comments in the coded instructions so others can understand the program.',
          softSkill: false,
        },
        {
          id: 'T0027',
          value:
            'Conduct analysis of log files, evidence, and other information to determine best methods for identifying the perpetrator(s) of a network intrusion.',
          softSkill: false,
        },
        {
          id: 'T0028',
          value:
            'Conduct and/or support authorized penetration testing on enterprise network assets.',
          softSkill: false,
        },
        {
          id: 'T0029',
          value: 'Conduct functional and connectivity testing to ensure continuing operability.',
          softSkill: false,
        },
        {
          id: 'T0030',
          value:
            'Conduct interactive training exercises to create an effective learning environment.',
          softSkill: false,
        },
        {
          id: 'T0031',
          value:
            'Conduct interviews of victims and witnesses and conduct interviews or interrogations of suspects.',
          softSkill: false,
        },
        {
          id: 'T0032',
          value:
            'Conduct Privacy Impact Assessments (PIAs) of the application’s security design for the appropriate security controls, which protect the confidentiality and integrity of Personally Identifiable Information (PII).',
          softSkill: false,
        },
        {
          id: 'T0033',
          value:
            'Conduct risk analysis, feasibility study, and/or trade-off analysis to develop, document, and refine functional requirements and specifications.',
          softSkill: false,
        },
        {
          id: 'T0034',
          value:
            'Confer with systems analysts, engineers, programmers, and others to design application and to obtain information on project limitations and capabilities, performance requirements, and interfaces.',
          softSkill: false,
        },
        {
          id: 'T0035',
          value:
            'Configure and optimize network hubs, routers, and switches (e.g., higher-level protocols, tunneling).',
          softSkill: false,
        },
        {
          id: 'T0036',
          value:
            'Confirm what is known about an intrusion and discover new information, if possible, after identifying intrusion via dynamic analysis.',
          softSkill: false,
        },
        {
          id: 'T0037',
          value:
            'Construct access paths to suites of information (e.g., link pages) to facilitate access by endusers.',
          softSkill: false,
        },
        {
          id: 'T0038',
          value: 'Develop threat model based on customer interviews and requirements.',
          softSkill: false,
        },
        {
          id: 'T0039',
          value: 'Consult with customers to evaluate functional requirements.',
          softSkill: false,
        },
        {
          id: 'T0040',
          value:
            'Consult with engineering staff to evaluate interface between hardware and software.',
          softSkill: false,
        },
        {
          id: 'T0041',
          value:
            'Coordinate and provide expert technical support to enterprise-wide cyber defense technicians to resolve cyber defense incidents.',
          softSkill: false,
        },
        {
          id: 'T0042',
          value:
            'Coordinate with Cyber Defense Analysts to manage and administer the updating of rules and signatures (e.g., intrusion detection/protection systems, antivirus, and content blacklists) for specialized cyber defense applications.',
          softSkill: false,
        },
        {
          id: 'T0043',
          value: 'Coordinate with enterprise-wide cyber defense staff to validate network alerts.',
          softSkill: false,
        },
        {
          id: 'T0044',
          value:
            'Collaborate with stakeholders to establish the enterprise continuity of operations program, strategy, and mission assurance.',
          softSkill: false,
        },
        {
          id: 'T0045',
          value:
            'Coordinate with systems architects and developers, as needed, to provide oversight in the development of design solutions.',
          softSkill: false,
        },
        {
          id: 'T0046',
          value:
            'Correct errors by making appropriate changes and rechecking the program to ensure that desired results are produced.',
          softSkill: false,
        },
        {
          id: 'T0047',
          value:
            'Correlate incident data to identify specific vulnerabilities and make recommendations that enable expeditious remediation.',
          softSkill: false,
        },
        {
          id: 'T0048',
          value:
            'Create a forensically sound duplicate of the evidence (i.e., forensic image) that ensures the original evidence is not unintentionally modified, to use for data recovery and analysis processes. This includes, but is not limited to, hard drives, floppy diskettes, CDs, PDAs, mobile phones, GPS, and all tape formats.',
          softSkill: false,
        },
        {
          id: 'T0049',
          value: 'Decrypt seized data using technical means.',
          softSkill: false,
        },
        {
          id: 'T0050',
          value:
            'Define and prioritize essential system capabilities or business functions required for partial or full system restoration after a catastrophic failure event.',
          softSkill: false,
        },
        {
          id: 'T0051',
          value:
            'Define appropriate levels of system availability based on critical system functions and ensure that system requirements identify appropriate disaster recovery and continuity of operations requirements to include any appropriate fail-over/alternate site requirements, backup requirements, and material supportability requirements for system recover/restoration.',
          softSkill: false,
        },
        {
          id: 'T0052',
          value: 'Define project scope and objectives based on customer requirements.',
          softSkill: false,
        },
        {
          id: 'T0053',
          value: 'Design and develop cybersecurity or cybersecurity-enabled products.',
          softSkill: false,
        },
        {
          id: 'T0054',
          value:
            'Design group policies and access control lists to ensure compatibility with organizational standards, business rules, and needs.',
          softSkill: false,
        },
        {
          id: 'T0055',
          value:
            'Design hardware, operating systems, and software applications to adequately address cybersecurity requirements.',
          softSkill: false,
        },
        {
          id: 'T0056',
          value:
            'Design or integrate appropriate data backup capabilities into overall system designs, and ensure that appropriate technical and procedural processes exist for secure system backups and protected storage of backup data.',
          softSkill: false,
        },
        {
          id: 'T0057',
          value:
            'Design, develop, and modify software systems, using scientific analysis and mathematical models to predict and measure outcome and consequences of design.',
          softSkill: false,
        },
        {
          id: 'T0058',
          value: 'Determine level of assurance of developed capabilities based on test results.',
          softSkill: false,
        },
        {
          id: 'T0059',
          value:
            'Develop a plan to investigate alleged crime, violation, or suspicious activity utilizing computers and the Internet.',
          softSkill: false,
        },
        {
          id: 'T0060',
          value: 'Develop an understanding of the needs and requirements of information end-users.',
          softSkill: false,
        },
        {
          id: 'T0061',
          value: 'Develop and direct system testing and validation procedures and documentation.',
          softSkill: false,
        },
        {
          id: 'T0062',
          value:
            'Develop and document requirements, capabilities, and constraints for design procedures and processes.',
          softSkill: false,
        },
        {
          id: 'T0063',
          value: 'Develop and document systems administration standard operating procedures.',
          softSkill: false,
        },
        {
          id: 'T0064',
          value:
            'Review and validate data mining and data warehousing programs, processes, and requirements.',
          softSkill: false,
        },
        {
          id: 'T0065',
          value: 'Develop and implement network backup and recovery procedures.',
          softSkill: false,
        },
        {
          id: 'T0066',
          value: 'Develop and maintain strategic plans.',
          softSkill: false,
        },
        {
          id: 'T0067',
          value:
            'Develop architectures or system components consistent with technical specifications.',
          softSkill: false,
        },
        {
          id: 'T0068',
          value: 'Develop data standards, policies, and procedures.',
          softSkill: false,
        },
        {
          id: 'T0069',
          value:
            'Develop detailed security design documentation for component and interface specifications to support system design and development.',
          softSkill: false,
        },
        {
          id: 'T0070',
          value:
            'Develop Disaster Recovery and Continuity of Operations plans for systems under development and ensure testing prior to systems entering a production environment.',
          softSkill: false,
        },
        {
          id: 'T0071',
          value:
            'Develop/integrate cybersecurity designs for systems and networks with multilevel security requirements or requirements for the processing of multiple classification levels of data primarily applicable to government organizations (e.g., UNCLASSIFIED, SECRET, and TOP SECRET).',
          softSkill: false,
        },
        {
          id: 'T0072',
          value: 'Develop methods to monitor and measure risk, compliance, and assurance efforts.',
          softSkill: false,
        },
        {
          id: 'T0073',
          value:
            'Develop new or identify existing awareness and training materials that are appropriate for intended audiences.',
          softSkill: false,
        },
        {
          id: 'T0074',
          value: 'Develop policy, programs, and guidelines for implementation.',
          softSkill: false,
        },
        {
          id: 'T0075',
          value:
            'Provide technical summary of findings in accordance with established reporting procedures.',
          softSkill: false,
        },
        {
          id: 'T0076',
          value:
            'Develop risk mitigation strategies to resolve vulnerabilities and recommend security changes to system or system components as needed.',
          softSkill: false,
        },
        {
          id: 'T0077',
          value: 'Develop secure code and error handling.',
          softSkill: false,
        },
        {
          id: 'T0078',
          value:
            'Develop specific cybersecurity countermeasures and risk mitigation strategies for systems and/or applications.',
          softSkill: false,
        },
        {
          id: 'T0079',
          value:
            'Develop specifications to ensure that risk, compliance, and assurance efforts conform with security, resilience, and dependability requirements at the software application, system, and network environment level.',
          softSkill: false,
        },
        {
          id: 'T0080',
          value: 'Develop test plans to address specifications and requirements.',
          softSkill: false,
        },
        {
          id: 'T0081',
          value: 'Diagnose network connectivity problem.',
          softSkill: false,
        },
        {
          id: 'T0082',
          value:
            "Document and address organization's information security, cybersecurity architecture, and systems security engineering requirements throughout the acquisition life cycle.",
          softSkill: false,
        },
        {
          id: 'T0083',
          value: 'Draft statements of preliminary or residual security risks for system operation.',
          softSkill: false,
        },
        {
          id: 'T0084',
          value: 'Employ secure configuration management processes.',
          softSkill: false,
        },
        {
          id: 'T0085',
          value:
            'Ensure all systems security operations and maintenance activities are properly documented and updated as necessary.',
          softSkill: false,
        },
        {
          id: 'T0086',
          value:
            'Ensure that the application of security patches for commercial products integrated into system design meet the timelines dictated by the management authority for the intended operational environment.',
          softSkill: false,
        },
        {
          id: 'T0087',
          value:
            'Ensure that chain of custody is followed for all digital media acquired in accordance with the Federal Rules of Evidence.',
          softSkill: false,
        },
        {
          id: 'T0088',
          value:
            'Ensure that cybersecurity-enabled products or other compensating security control technologies reduce identified risk to an acceptable level.',
          softSkill: false,
        },
        {
          id: 'T0089',
          value:
            'Ensure that security improvement actions are evaluated, validated, and implemented as required.',
          softSkill: false,
        },
        {
          id: 'T0090',
          value:
            "Ensure that acquired or developed system(s) and architecture(s) are consistent with organization's cybersecurity architecture guidelines.",
          softSkill: false,
        },
        {
          id: 'T0091',
          value:
            'Ensure that cybersecurity inspections, tests, and reviews are coordinated for the network environment.',
          softSkill: false,
        },
        {
          id: 'T0092',
          value:
            'Ensure that cybersecurity requirements are integrated into the continuity planning for that system and/or organization(s).',
          softSkill: false,
        },
        {
          id: 'T0093',
          value:
            'Ensure that protection and detection capabilities are acquired or developed using the IS security engineering approach and are consistent with organization-level cybersecurity architecture.',
          softSkill: false,
        },
        {
          id: 'T0094',
          value: 'Establish and maintain communication channels with stakeholders.',
          softSkill: false,
        },
        {
          id: 'T0095',
          value:
            'Establish overall enterprise information security architecture (EISA) with the organization’s overall security strategy.',
          softSkill: false,
        },
        {
          id: 'T0096',
          value:
            'Establish relationships, if applicable, between the incident response team and other groups, both internal (e.g., legal department) and external (e.g., law enforcement agencies, vendors, public relations professionals).',
          softSkill: false,
        },
        {
          id: 'T0097',
          value:
            'Evaluate and approve development efforts to ensure that baseline security safeguards are appropriately installed.',
          softSkill: false,
        },
        {
          id: 'T0098',
          value:
            'Evaluate contracts to ensure compliance with funding, legal, and program requirements.',
          softSkill: false,
        },
        {
          id: 'T0099',
          value: 'Evaluate cost/benefit, economic, and risk analysis in decision-making process.',
          softSkill: false,
        },
        {
          id: 'T0100',
          value:
            'Evaluate factors such as reporting formats required, cost constraints, and need for security restrictions to determine hardware configuration.',
          softSkill: false,
        },
        {
          id: 'T0101',
          value: 'Evaluate the effectiveness and comprehensiveness of existing training programs.',
          softSkill: false,
        },
        {
          id: 'T0102',
          value:
            'Evaluate the effectiveness of laws, regulations, policies, standards, or procedures.',
          softSkill: false,
        },
        {
          id: 'T0103',
          value: 'Examine recovered data for information of relevance to the issue at hand.',
          softSkill: false,
        },
        {
          id: 'T0104',
          value:
            'Fuse computer network attack analyses with criminal and counterintelligence investigations and operations.',
          softSkill: false,
        },
        {
          id: 'T0105',
          value:
            'Identify components or elements, allocate security functions to those elements, and describe the relationships between the elements.',
          softSkill: false,
        },
        {
          id: 'T0106',
          value:
            'Identify alternative information security strategies to address organizational security objective.',
          softSkill: false,
        },
        {
          id: 'T0107',
          value:
            'Identify and direct the remediation of technical problems encountered during testing and implementation of new systems (e.g., identify and find work-arounds for communication protocols that are not interoperable).',
          softSkill: false,
        },
        {
          id: 'T0108',
          value:
            'Identify and prioritize critical business functions in collaboration with organizational stakeholders.',
          softSkill: false,
        },
        {
          id: 'T0109',
          value:
            'Identify and prioritize essential system functions or sub-systems required to support essential capabilities or business functions for restoration or recovery after a system failure or during a system recovery event based on overall system requirements for continuity and availability.',
          softSkill: false,
        },
        {
          id: 'T0110',
          value:
            'Identify and/or determine whether a security incident is indicative of a violation of law that requires specific legal action.',
          softSkill: false,
        },
        {
          id: 'T0111',
          value: 'Identify basic common coding flaws at a high level.',
          softSkill: false,
        },
        {
          id: 'T0112',
          value:
            'Identify data or intelligence of evidentiary value to support counterintelligence and criminal investigations.',
          softSkill: false,
        },
        {
          id: 'T0113',
          value:
            'Identify digital evidence for examination and analysis in such a way as to avoid unintentional alteration.',
          softSkill: false,
        },
        {
          id: 'T0114',
          value: 'Identify elements of proof of the crime.',
          softSkill: false,
        },
        {
          id: 'T0115',
          value:
            'Identify information technology (IT) security program implications of new technologies or technology upgrades.',
          softSkill: false,
        },
        {
          id: 'T0116',
          value: 'Identify organizational policy stakeholders.',
          softSkill: false,
        },
        {
          id: 'T0117',
          value:
            'Identify security implications and apply methodologies within centralized and decentralized environments across the enterprise’s computer systems in software development.',
          softSkill: false,
        },
        {
          id: 'T0118',
          value:
            'Identify security issues around steady state operation and management of software and incorporate security measures that must be taken when a product reaches its end of life.',
          softSkill: false,
        },
        {
          id: 'T0119',
          value:
            "Identify, assess, and recommend cybersecurity or cybersecurity-enabled products for use within a system and ensure that recommended products are in compliance with organization's evaluation and validation requirements.",
          softSkill: false,
        },
        {
          id: 'T0120',
          value:
            'Identify, collect, and seize documentary or physical evidence, to include digital media and logs associated with cyber intrusion incidents, investigations, and operations.',
          softSkill: false,
        },
        {
          id: 'T0121',
          value: 'Implement new system design procedures, test procedures, and quality standards.',
          softSkill: false,
        },
        {
          id: 'T0122',
          value: 'Implement security designs for new or existing system(s).',
          softSkill: false,
        },
        {
          id: 'T0123',
          value:
            'Implement specific cybersecurity countermeasures for systems and/or applications.',
          softSkill: false,
        },
        {
          id: 'T0124',
          value:
            'Incorporate cybersecurity vulnerability solutions into system designs (e.g., Cybersecurity Vulnerability Alerts).',
          softSkill: false,
        },
        {
          id: 'T0125',
          value:
            'Install and maintain network infrastructure device operating system software (e.g., IOS, firmware).',
          softSkill: false,
        },
        {
          id: 'T0126',
          value: 'Install or replace network hubs, routers, and switches.',
          softSkill: false,
        },
        {
          id: 'T0127',
          value:
            'Integrate and align information security and/or cybersecurity policies to ensure that system analysis meets security requirements.',
          softSkill: false,
        },
        {
          id: 'T0128',
          value:
            'Integrate automated capabilities for updating or patching system software where practical and develop processes and procedures for manual updating and patching of system software based on current and projected patch timeline requirements for the operational environment of the system.',
          softSkill: false,
        },
        {
          id: 'T0129',
          value: 'Integrate new systems into existing network architecture.',
          softSkill: false,
        },
        {
          id: 'T0130',
          value:
            'Interface with external organizations (e.g., public affairs, law enforcement, Command or Component Inspector General) to ensure appropriate and accurate dissemination of incident and other Computer Network Defense information.',
          softSkill: false,
        },
        {
          id: 'T0131',
          value:
            'Interpret and apply laws, regulations, policies, standards, or procedures to specific issues.',
          softSkill: false,
        },
        {
          id: 'T0132',
          value:
            'Interpret and/or approve security requirements relative to the capabilities of new information technologies.',
          softSkill: false,
        },
        {
          id: 'T0133',
          value:
            'Interpret patterns of noncompliance to determine their impact on levels of risk and/or overall effectiveness of the enterprise’s cybersecurity program.',
          softSkill: false,
        },
        {
          id: 'T0134',
          value:
            'Lead and align information technology (IT) security priorities with the security strategy.',
          softSkill: false,
        },
        {
          id: 'T0135',
          value: 'Lead and oversee information security budget, staffing, and contracting.',
          softSkill: false,
        },
        {
          id: 'T0136',
          value: 'Maintain baseline system security according to organizational policies.',
          softSkill: false,
        },
        {
          id: 'T0137',
          value: 'Maintain database management systems software.',
          softSkill: false,
        },
        {
          id: 'T0138',
          value:
            'Maintain deployable cyber defense audit toolkit (e.g., specialized cyber defense software and hardware) to support cyber defense audit missions.',
          softSkill: false,
        },
        {
          id: 'T0139',
          value:
            'Maintain directory replication services that enable information to replicate automatically from rear servers to forward units via optimized routing.',
          softSkill: false,
        },
        {
          id: 'T0140',
          value:
            'Maintain information exchanges through publish, subscribe, and alert functions that enable users to send and receive critical information as required.',
          softSkill: false,
        },
        {
          id: 'T0141',
          value: 'Maintain information systems assurance and accreditation materials.',
          softSkill: false,
        },
        {
          id: 'T0142',
          value:
            'Maintain knowledge of applicable cyber defense policies, regulations, and compliance documents specifically related to cyber defense auditing.',
          softSkill: false,
        },
        {
          id: 'T0143',
          value: 'Make recommendations based on test results.',
          softSkill: false,
        },
        {
          id: 'T0144',
          value: 'Manage accounts, network rights, and access to systems and equipment.',
          softSkill: false,
        },
        {
          id: 'T0145',
          value: 'Manage and approve Accreditation Packages (e.g., ISO/IEC 15026-2).',
          softSkill: false,
        },
        {
          id: 'T0146',
          value:
            'Manage the compilation, cataloging, caching, distribution, and retrieval of data.',
          softSkill: false,
        },
        {
          id: 'T0147',
          value:
            'Manage the monitoring of information security data sources to maintain organizational situational awareness.',
          softSkill: false,
        },
        {
          id: 'T0148',
          value:
            'Manage the publishing of Computer Network Defense guidance (e.g., TCNOs, Concept of Operations, Net Analyst Reports, NTSM, MTOs) for the enterprise constituency.',
          softSkill: false,
        },
        {
          id: 'T0149',
          value:
            'Manage threat or target analysis of cyber defense information and production of threat information within the enterprise.',
          softSkill: false,
        },
        {
          id: 'T0150',
          value:
            "Monitor and evaluate a system's compliance with information technology (IT) security, resilience, and dependability requirements.",
          softSkill: false,
        },
        {
          id: 'T0151',
          value:
            "Monitor and evaluate the effectiveness of the enterprise's cybersecurity safeguards to ensure that they provide the intended level of protection.",
          softSkill: false,
        },
        {
          id: 'T0152',
          value: 'Monitor and maintain databases to ensure optimal performance.',
          softSkill: false,
        },
        {
          id: 'T0153',
          value: 'Monitor network capacity and performance.',
          softSkill: false,
        },
        {
          id: 'T0154',
          value: 'Monitor and report the usage of knowledge management assets and resources.',
          softSkill: false,
        },
        {
          id: 'T0155',
          value:
            'Document and escalate incidents (including event’s history, status, and potential impact for further action) that may cause ongoing and immediate impact to the environment.',
          softSkill: false,
        },
        {
          id: 'T0156',
          value: 'Oversee and make recommendations regarding configuration management.',
          softSkill: false,
        },
        {
          id: 'T0157',
          value: 'Oversee the information security training and awareness program.',
          softSkill: false,
        },
        {
          id: 'T0158',
          value:
            'Participate in an information security risk assessment during the Security Assessment and Authorization process.',
          softSkill: false,
        },
        {
          id: 'T0159',
          value:
            'Participate in the development or modification of the computer environment cybersecurity program plans and requirements.',
          softSkill: false,
        },
        {
          id: 'T0160',
          value:
            'Patch network vulnerabilities to ensure that information is safeguarded against outside parties.',
          softSkill: false,
        },
        {
          id: 'T0161',
          value:
            'Perform analysis of log files from a variety of sources (e.g., individual host logs, network traffic logs, firewall logs, and intrusion detection system [IDS] logs) to identify possible threats to network security.',
          softSkill: false,
        },
        {
          id: 'T0162',
          value: 'Perform backup and recovery of databases to ensure data integrity.',
          softSkill: false,
        },
        {
          id: 'T0163',
          value:
            'Perform cyber defense incident triage, to include determining scope, urgency, and potential impact, identifying the specific vulnerability, and making recommendations that enable expeditious remediation.',
          softSkill: false,
        },
        {
          id: 'T0164',
          value: 'Perform cyber defense trend analysis and reporting.',
          softSkill: false,
        },
        {
          id: 'T0165',
          value:
            'Perform dynamic analysis to boot an “image” of a drive (without necessarily having the original drive) to see the intrusion as the user may have seen it, in a native environment.',
          softSkill: false,
        },
        {
          id: 'T0166',
          value:
            'Perform event correlation using information gathered from a variety of sources within the enterprise to gain situational awareness and determine the effectiveness of an observed attack.',
          softSkill: false,
        },
        {
          id: 'T0167',
          value: 'Perform file signature analysis.',
          softSkill: false,
        },
        {
          id: 'T0168',
          value: 'Perform hash comparison against established database.',
          softSkill: false,
        },
        {
          id: 'T0169',
          value: 'Perform cybersecurity testing of developed applications and/or systems.',
          softSkill: false,
        },
        {
          id: 'T0170',
          value:
            'Perform initial, forensically sound collection of images and inspect to discern possible mitigation/remediation on enterprise systems.',
          softSkill: false,
        },
        {
          id: 'T0171',
          value:
            'Perform integrated quality assurance testing for security functionality and resiliency attack.',
          softSkill: false,
        },
        {
          id: 'T0172',
          value:
            'Perform real-time forensic analysis (e.g., using Helix in conjunction with LiveView).',
          softSkill: false,
        },
        {
          id: 'T0173',
          value: 'Perform timeline analysis.',
          softSkill: false,
        },
        {
          id: 'T0174',
          value:
            'Perform needs analysis to determine opportunities for new and improved business process solutions.',
          softSkill: false,
        },
        {
          id: 'T0175',
          value:
            'Perform real-time cyber defense incident handling (e.g., forensic collections, intrusion correlation and tracking, threat analysis, and direct system remediation) tasks to support deployable Incident Response Teams (IRTs).',
          softSkill: false,
        },
        {
          id: 'T0176',
          value:
            'Perform secure programming and identify potential flaws in codes to mitigate vulnerabilities.',
          softSkill: false,
        },
        {
          id: 'T0177',
          value:
            'Perform security reviews, identify gaps in security architecture, and develop a security risk management plan.',
          softSkill: false,
        },
        {
          id: 'T0178',
          value:
            'Perform security reviews and identify security gaps in security architecture resulting in recommendations for inclusion in the risk mitigation strategy.',
          softSkill: false,
        },
        {
          id: 'T0179',
          value: 'Perform static media analysis.',
          softSkill: false,
        },
        {
          id: 'T0180',
          value:
            'Perform system administration on specialized cyber defense applications and systems (e.g., antivirus, audit and remediation) or Virtual Private Network (VPN) devices, to include installation, configuration, maintenance, backup, and restoration.',
          softSkill: false,
        },
        {
          id: 'T0181',
          value:
            'Perform risk analysis (e.g., threat, vulnerability, and probability of occurrence) whenever an application or system undergoes a major change.',
          softSkill: false,
        },
        {
          id: 'T0182',
          value: 'Perform tier 1, 2, and 3 malware analysis.',
          softSkill: false,
        },
        {
          id: 'T0183',
          value:
            'Perform validation steps, comparing actual results with expected results and analyze the differences to identify impact and risks.',
          softSkill: false,
        },
        {
          id: 'T0184',
          value:
            'Plan and conduct security authorization reviews and assurance case development for initial installation of systems and networks.',
          softSkill: false,
        },
        {
          id: 'T0185',
          value: 'Plan and manage the delivery of knowledge management projects.',
          softSkill: false,
        },
        {
          id: 'T0186',
          value: 'Plan, execute, and verify data redundancy and system recovery procedures.',
          softSkill: false,
        },
        {
          id: 'T0187',
          value:
            'Plan and recommend modifications or adjustments based on exercise results or system environment.',
          softSkill: false,
        },
        {
          id: 'T0188',
          value:
            'Prepare audit reports that identify technical and procedural findings, and provide recommended remediation strategies/solutions.',
          softSkill: false,
        },
        {
          id: 'T0189',
          value:
            'Prepare detailed workflow charts and diagrams that describe input, output, and logical operation, and convert them into a series of instructions coded in a computer language.',
          softSkill: false,
        },
        {
          id: 'T0190',
          value:
            'Prepare digital media for imaging by ensuring data integrity (e.g., write blockers in accordance with standard operating procedures).',
          softSkill: false,
        },
        {
          id: 'T0191',
          value:
            'Prepare use cases to justify the need for specific information technology (IT) solutions.',
          softSkill: false,
        },
        {
          id: 'T0192',
          value:
            'Prepare, distribute, and maintain plans, instructions, guidance, and standard operating procedures concerning the security of network system(s) operations.',
          softSkill: false,
        },
        {
          id: 'T0193',
          value: 'Process crime scenes.',
          softSkill: false,
        },
        {
          id: 'T0194',
          value:
            'Properly document all systems security implementation, operations, and maintenance activities and update as necessary.',
          softSkill: false,
        },
        {
          id: 'T0195',
          value:
            'Provide a managed flow of relevant information (via web-based portals or other means) based on mission requirements.',
          softSkill: false,
        },
        {
          id: 'T0196',
          value: 'Provide advice on project costs, design concepts, or design changes.',
          softSkill: false,
        },
        {
          id: 'T0197',
          value:
            'Provide an accurate technical evaluation of the software application, system, or network, documenting the security posture, capabilities, and vulnerabilities against relevant cybersecurity compliances.',
          softSkill: false,
        },
        {
          id: 'T0198',
          value:
            'Provide daily summary reports of network events and activity relevant to cyber defense practices.',
          softSkill: false,
        },
        {
          id: 'T0199',
          value:
            'Provide enterprise cybersecurity and supply chain risk management guidance for development of the Continuity of Operations Plans.',
          softSkill: false,
        },
        {
          id: 'T0200',
          value:
            'Provide feedback on network requirements, including network architecture and infrastructure.',
          softSkill: false,
        },
        {
          id: 'T0201',
          value:
            'Provide guidelines for implementing developed systems to customers or installation teams.',
          softSkill: false,
        },
        {
          id: 'T0202',
          value: 'Provide cybersecurity guidance to leadership.',
          softSkill: false,
        },
        {
          id: 'T0203',
          value:
            'Provide input on security requirements to be included in statements of work and other appropriate procurement documents.',
          softSkill: false,
        },
        {
          id: 'T0204',
          value: 'Provide input to implementation plans and standard operating procedures.',
          softSkill: false,
        },
        {
          id: 'T0205',
          value:
            'Provide input to the Risk Management Framework process activities and related documentation (e.g., system life-cycle support plans, concept of operations, operational procedures, and maintenance training materials).',
          softSkill: false,
        },
        {
          id: 'T0206',
          value:
            'Provide leadership and direction to information technology (IT) personnel by ensuring that cybersecurity awareness, basics, literacy, and training are provided to operations personnel commensurate with their responsibilities.',
          softSkill: false,
        },
        {
          id: 'T0207',
          value: 'Provide ongoing optimization and problem-solving support.',
          softSkill: false,
        },
        {
          id: 'T0208',
          value: 'Provide recommendations for possible improvements and upgrades.',
          softSkill: false,
        },
        {
          id: 'T0209',
          value:
            'Provide recommendations on data structures and databases that ensure correct and quality production of reports/management information.',
          softSkill: false,
        },
        {
          id: 'T0210',
          value: 'Provide recommendations on new database technologies and architectures.',
          softSkill: false,
        },
        {
          id: 'T0211',
          value:
            'Provide system-related input on cybersecurity requirements to be included in statements of work and other appropriate procurement documents.',
          softSkill: false,
        },
        {
          id: 'T0212',
          value:
            'Provide technical assistance on digital evidence matters to appropriate personnel.',
          softSkill: false,
        },
        {
          id: 'T0213',
          value:
            'Provide technical documents, incident reports, findings from computer examinations, summaries, and other situational awareness information to higher headquarters.',
          softSkill: false,
        },
        {
          id: 'T0214',
          value:
            'Receive and analyze network alerts from various sources within the enterprise and determine possible causes of such alerts.',
          softSkill: false,
        },
        {
          id: 'T0215',
          value:
            'Recognize a possible security violation and take appropriate action to report the incident, as required.',
          softSkill: false,
        },
        {
          id: 'T0216',
          value:
            'Recognize and accurately report forensic artifacts indicative of a particular operating system.',
          softSkill: false,
        },
        {
          id: 'T0217',
          value:
            'Address security implications in the software acceptance phase including completion criteria, risk acceptance and documentation, common criteria, and methods of independent testing.',
          softSkill: false,
        },
        {
          id: 'T0218',
          value:
            'Recommend new or revised security, resilience, and dependability measures based on the results of reviews.',
          softSkill: false,
        },
        {
          id: 'T0219',
          value:
            'Recommend resource allocations required to securely operate and maintain an organization’s cybersecurity requirements.',
          softSkill: false,
        },
        {
          id: 'T0220',
          value: 'Resolve conflicts in laws, regulations, policies, standards, or procedures.',
          softSkill: false,
        },
        {
          id: 'T0221',
          value:
            'Review authorization and assurance documents to confirm that the level of risk is within acceptable limits for each software application, system, and network.',
          softSkill: false,
        },
        {
          id: 'T0222',
          value: 'Review existing and proposed policies with stakeholders.',
          softSkill: false,
        },
        {
          id: 'T0223',
          value: 'Review or conduct audits of information technology (IT) programs and projects.',
          softSkill: false,
        },
        {
          id: 'T0224',
          value:
            'Review training documentation (e.g., Course Content Documents [CCD], lesson plans, student texts, examinations, Schedules of Instruction [SOI], and course descriptions).',
          softSkill: false,
        },
        {
          id: 'T0225',
          value: 'Secure the electronic device or information source.',
          softSkill: false,
        },
        {
          id: 'T0226',
          value: 'Serve on agency and interagency policy boards.',
          softSkill: false,
        },
        {
          id: 'T0227',
          value: 'Recommend policy and coordinate review and approval.',
          softSkill: false,
        },
        {
          id: 'T0228',
          value:
            'Store, retrieve, and manipulate data for analysis of system capabilities and requirements.',
          softSkill: false,
        },
        {
          id: 'T0229',
          value:
            'Supervise or manage protective or corrective measures when a cybersecurity incident or vulnerability is discovered.',
          softSkill: false,
        },
        {
          id: 'T0230',
          value: 'Support the design and execution of exercise scenarios.',
          softSkill: false,
        },
        {
          id: 'T0231',
          value: 'Provide support to security/certification test and evaluation activities.',
          softSkill: false,
        },
        {
          id: 'T0232',
          value:
            'Test and maintain network infrastructure including software and hardware devices.',
          softSkill: false,
        },
        {
          id: 'T0233',
          value:
            'Track and document cyber defense incidents from initial detection through final resolution.',
          softSkill: false,
        },
        {
          id: 'T0234',
          value:
            'Track audit findings and recommendations to ensure that appropriate mitigation actions are taken.',
          softSkill: false,
        },
        {
          id: 'T0235',
          value: 'Translate functional requirements into technical solutions.',
          softSkill: false,
        },
        {
          id: 'T0236',
          value:
            'Translate security requirements into application design elements including documenting the elements of the software attack surfaces, conducting threat modeling, and defining any specific security criteria.',
          softSkill: false,
        },
        {
          id: 'T0237',
          value: 'Troubleshoot system hardware and software.',
          softSkill: false,
        },
        {
          id: 'T0238',
          value:
            'Extract data using data carving techniques (e.g., Forensic Tool Kit [FTK], Foremost).',
          softSkill: false,
        },
        {
          id: 'T0239',
          value:
            'Use federal and organization-specific published documents to manage operations of their computing environment system(s).',
          softSkill: false,
        },
        {
          id: 'T0240',
          value:
            'Capture and analyze network traffic associated with malicious activities using network monitoring tools.',
          softSkill: false,
        },
        {
          id: 'T0241',
          value:
            'Use specialized equipment and techniques to catalog, document, extract, collect, package, and preserve digital evidence.',
          softSkill: false,
        },
        {
          id: 'T0242',
          value:
            'Utilize models and simulations to analyze or predict system performance under different operating conditions.',
          softSkill: false,
        },
        {
          id: 'T0243',
          value:
            'Verify and update security documentation reflecting the application/system security design features.',
          softSkill: false,
        },
        {
          id: 'T0244',
          value:
            'Verify that application software/network/system security postures are implemented as stated, document deviations, and recommend required actions to correct those deviations.',
          softSkill: false,
        },
        {
          id: 'T0245',
          value:
            'Verify that the software application/network/system accreditation and assurance documentation is current.',
          softSkill: false,
        },
        {
          id: 'T0246',
          value:
            'Write and publish cyber defense techniques, guidance, and reports on incident findings to appropriate constituencies.',
          softSkill: false,
        },
        {
          id: 'T0247',
          value:
            'Write instructional materials (e.g., standard operating procedures, production manual) to provide detailed guidance to relevant portion of the workforce.',
          softSkill: false,
        },
        {
          id: 'T0248',
          value:
            "Promote awareness of security issues among management and ensure sound security principles are reflected in the organization's vision and goals.",
          softSkill: false,
        },
        {
          id: 'T0249',
          value:
            'Research current technology to understand capabilities of required system or network.',
          softSkill: false,
        },
        {
          id: 'T0250',
          value:
            'Identify cyber capabilities strategies for custom hardware and software development based on mission requirements.',
          softSkill: false,
        },
        {
          id: 'T0251',
          value:
            'Develop security compliance processes and/or audits for external services (e.g., cloud service providers, data centers).',
          softSkill: false,
        },
        {
          id: 'T0252',
          value:
            'Conduct required reviews as appropriate within environment (e.g., Technical Surveillance, Countermeasure Reviews [TSCM], TEMPEST countermeasure reviews).',
          softSkill: false,
        },
        {
          id: 'T0253',
          value: 'Conduct cursory binary analysis.',
          softSkill: false,
        },
        {
          id: 'T0254',
          value:
            'Oversee policy standards and implementation strategies to ensure procedures and guidelines comply with cybersecurity policies.',
          softSkill: false,
        },
        {
          id: 'T0255',
          value:
            'Participate in Risk Governance process to provide security risks, mitigations, and input on other technical risk.',
          softSkill: false,
        },
        {
          id: 'T0256',
          value:
            'Evaluate the effectiveness of procurement function in addressing information security requirements and supply chain risks through procurement activities and recommend improvements.',
          softSkill: false,
        },
        {
          id: 'T0257',
          value:
            'Determine scope, infrastructure, resources, and data sample size to ensure system requirements are adequately demonstrated.',
          softSkill: false,
        },
        {
          id: 'T0258',
          value:
            'Provide timely detection, identification, and alerting of possible attacks/intrusions, anomalous activities, and misuse activities and distinguish these incidents and events from benign activities.',
          softSkill: false,
        },
        {
          id: 'T0259',
          value:
            'Use cyber defense tools for continual monitoring and analysis of system activity to identify malicious activity.',
          softSkill: false,
        },
        {
          id: 'T0260',
          value:
            'Analyze identified malicious activity to determine weaknesses exploited, exploitation methods, effects on system and information.',
          softSkill: false,
        },
        {
          id: 'T0261',
          value:
            'Assist in identifying, prioritizing, and coordinating the protection of critical cyber defense infrastructure and key resources.',
          softSkill: false,
        },
        {
          id: 'T0262',
          value:
            'Employ approved defense-in-depth principles and practices (e.g., defense-in-multiple places, layered defenses, security robustness).',
          softSkill: false,
        },
        {
          id: 'T0263',
          value:
            'Identify security requirements specific to an information technology (IT) system in all phases of the system life cycle.',
          softSkill: false,
        },
        {
          id: 'T0264',
          value:
            'Ensure that plans of actions and milestones or remediation plans are in place for vulnerabilities identified during risk assessments, audits, inspections, etc.',
          softSkill: false,
        },
        {
          id: 'T0265',
          value:
            "Assure successful implementation and functionality of security requirements and appropriate information technology (IT) policies and procedures that are consistent with the organization's mission and goals.",
          softSkill: false,
        },
        {
          id: 'T0266',
          value: 'Perform penetration testing as required for new or updated applications.',
          softSkill: false,
        },
        {
          id: 'T0267',
          value:
            'Design countermeasures and mitigations against potential exploitations of programming language weaknesses and vulnerabilities in system and elements.',
          softSkill: false,
        },
        {
          id: 'T0268',
          value:
            'Define and document how the implementation of a new system or new interfaces between systems impacts the security posture of the current environment.',
          softSkill: false,
        },
        {
          id: 'T0269',
          value: 'Design and develop key management functions (as related to cybersecurity).',
          softSkill: false,
        },
        {
          id: 'T0270',
          value:
            'Analyze user needs and requirements to plan and conduct system security development.',
          softSkill: false,
        },
        {
          id: 'T0271',
          value:
            'Develop cybersecurity designs to meet specific operational needs and environmental factors (e.g., access controls, automated applications, networked operations, high integrity and  availability requirements, multilevel security/processing of multiple classification levels, and processing Sensitive Compartmented Information).',
          softSkill: false,
        },
        {
          id: 'T0272',
          value:
            'Ensure that security design and cybersecurity development activities are properly documented (providing a functional description of security implementation) and updated as necessary.',
          softSkill: false,
        },
        {
          id: 'T0273',
          value:
            'Develop and document supply chain risks for critical system elements, as appropriate.',
          softSkill: false,
        },
        {
          id: 'T0274',
          value: 'Create auditable evidence of security measures.',
          softSkill: false,
        },
        {
          id: 'T0275',
          value:
            'Support necessary compliance activities (e.g., ensure that system security configuration guidelines are followed, compliance monitoring occurs).',
          softSkill: false,
        },
        {
          id: 'T0276',
          value:
            'Participate in the acquisition process as necessary, following appropriate supply chain risk management practices.',
          softSkill: false,
        },
        {
          id: 'T0277',
          value:
            'Ensure that all acquisitions, procurements, and outsourcing efforts address information security requirements consistent with organization goals.',
          softSkill: false,
        },
        {
          id: 'T0278',
          value:
            'Collect intrusion artifacts (e.g., source code, malware, Trojans) and use discovered data to enable mitigation of potential cyber defense incidents within the enterprise.',
          softSkill: false,
        },
        {
          id: 'T0279',
          value:
            'Serve as technical expert and liaison to law enforcement personnel and explain incident details as required.',
          softSkill: false,
        },
        {
          id: 'T0280',
          value:
            'Continuously validate the organization against policies/guidelines/procedures/regulations/laws to ensure compliance.',
          softSkill: false,
        },
        {
          id: 'T0281',
          value:
            'Forecast ongoing service demands and ensure that security assumptions are reviewed as necessary.',
          softSkill: false,
        },
        {
          id: 'T0282',
          value:
            'Define and/or implement policies and procedures to ensure protection of critical infrastructure as appropriate.',
          softSkill: false,
        },
        {
          id: 'T0283',
          value:
            'Collaborate with stakeholders to identify and/or develop appropriate solutions technology.',
          softSkill: false,
        },
        {
          id: 'T0284',
          value: 'Design and develop new tools/technologies as related to cybersecurity.',
          softSkill: false,
        },
        {
          id: 'T0285',
          value: 'Perform virus scanning on digital media.',
          softSkill: false,
        },
        {
          id: 'T0286',
          value: 'Perform file system forensic analysis.',
          softSkill: false,
        },
        {
          id: 'T0287',
          value:
            'Perform static analysis to mount an "image" of a drive (without necessarily having the original drive).',
          softSkill: false,
        },
        {
          id: 'T0288',
          value: 'Perform static malware analysis.',
          softSkill: false,
        },
        {
          id: 'T0289',
          value: 'Utilize deployable forensics toolkit to support operations as necessary.',
          softSkill: false,
        },
        {
          id: 'T0290',
          value: 'Determine tactics, techniques, and procedures (TTPs) for intrusion sets.',
          softSkill: false,
        },
        {
          id: 'T0291',
          value: 'Examine network topologies to understand data flows through the network.',
          softSkill: false,
        },
        {
          id: 'T0292',
          value: 'Recommend computing environment vulnerability corrections.',
          softSkill: false,
        },
        {
          id: 'T0293',
          value: 'Identify and analyze anomalies in network traffic using metadata.',
          softSkill: false,
        },
        {
          id: 'T0294',
          value:
            'Conduct research, analysis, and correlation across a wide variety of all source data sets (indications and warnings).',
          softSkill: false,
        },
        {
          id: 'T0295',
          value:
            'Validate intrusion detection system (IDS) alerts against network traffic using packet analysis tools.',
          softSkill: false,
        },
        {
          id: 'T0296',
          value: 'Isolate and remove malware.',
          softSkill: false,
        },
        {
          id: 'T0297',
          value:
            'Identify applications and operating systems of a network device based on network traffic.',
          softSkill: false,
        },
        {
          id: 'T0298',
          value: 'Reconstruct a malicious attack or activity based off network traffic.',
          softSkill: false,
        },
        {
          id: 'T0299',
          value: 'Identify network mapping and operating system (OS) fingerprinting activities.',
          softSkill: false,
        },
        {
          id: 'T0300',
          value:
            'Develop and document User Experience (UX) requirements including information architecture and user interface requirements.',
          softSkill: false,
        },
        {
          id: 'T0301',
          value:
            'Develop and implement cybersecurity independent audit processes for application software/networks/systems and oversee ongoing independent audits to ensure that operational and Research and Design (R&D) processes and procedures are in compliance with organizational and mandatory cybersecurity requirements and accurately followed by  Systems Administrators and other cybersecurity staff when performing their day-to-day activities.',
          softSkill: false,
        },
        {
          id: 'T0302',
          value:
            'Develop contract language to ensure supply chain, system, network, and operational security are met.',
          softSkill: false,
        },
        {
          id: 'T0303',
          value:
            'Identify and leverage the enterprise-wide version control system while designing and developing secure applications.',
          softSkill: false,
        },
        {
          id: 'T0304',
          value:
            'Implement and integrate system development life cycle (SDLC) methodologies (e.g., IBM Rational Unified Process) into development environment.',
          softSkill: false,
        },
        {
          id: 'T0305',
          value:
            'Performs configuration management, problem management, capacity management, and financial management for databases and data management systems.',
          softSkill: false,
        },
        {
          id: 'T0306',
          value:
            'Supports incident management, service-level management, change management, release management, continuity management, and availability management for databases and data management systems.',
          softSkill: false,
        },
        {
          id: 'T0307',
          value:
            'Analyze candidate architectures, allocate security services, and select security mechanisms.',
          softSkill: false,
        },
        {
          id: 'T0308',
          value: 'Analyze incident data for emerging trends.',
          softSkill: false,
        },
        {
          id: 'T0309',
          value: 'Assess the effectiveness of security controls.',
          softSkill: false,
        },
        {
          id: 'T0310',
          value:
            'Assist in the construction of signatures which can be implemented on cyber defense network tools in response to new or observed threats within the network environment or enclave.',
          softSkill: false,
        },
        {
          id: 'T0311',
          value: 'Consult with customers about software system design and maintenance.',
          softSkill: false,
        },
        {
          id: 'T0312',
          value: 'Coordinate with intelligence analysts to correlate threat assessment data.',
          softSkill: false,
        },
        {
          id: 'T0313',
          value: 'Design and document quality standards.',
          softSkill: false,
        },
        {
          id: 'T0314',
          value:
            'Develop a system security context, a preliminary system security Concept of Operations (CONOPS), and define baseline system security requirements in accordance with applicable cybersecurity requirements.',
          softSkill: false,
        },
        {
          id: 'T0315',
          value: 'Develop and deliver technical training to educate others or meet customer needs.',
          softSkill: false,
        },
        {
          id: 'T0316',
          value:
            'Develop or assist in the development of computer based training modules or classes.',
          softSkill: false,
        },
        {
          id: 'T0317',
          value: 'Develop or assist in the development of course assignments.',
          softSkill: false,
        },
        {
          id: 'T0318',
          value: 'Develop or assist in the development of course evaluations.',
          softSkill: false,
        },
        {
          id: 'T0319',
          value: 'Develop or assist in the development of grading and proficiency standards.',
          softSkill: false,
        },
        {
          id: 'T0320',
          value:
            'Assist in the development of individual/collective development, training, and/or remediation plans.',
          softSkill: false,
        },
        {
          id: 'T0321',
          value: 'Develop or assist in the development of learning objectives and goals.',
          softSkill: false,
        },
        {
          id: 'T0322',
          value:
            'Develop or assist in the development of on-the-job training materials or programs.',
          softSkill: false,
        },
        {
          id: 'T0323',
          value:
            'Develop or assist in the development of written tests for measuring and assessing learner proficiency.',
          softSkill: false,
        },
        {
          id: 'T0324',
          value: 'Direct software programming and development of documentation.',
          softSkill: false,
        },
        {
          id: 'T0325',
          value:
            "Document a system's purpose and preliminary system security concept of operations.",
          softSkill: false,
        },
        {
          id: 'T0326',
          value: 'Employ configuration management processes.',
          softSkill: false,
        },
        {
          id: 'T0327',
          value:
            'Evaluate network infrastructure vulnerabilities to enhance capabilities being developed.',
          softSkill: false,
        },
        {
          id: 'T0328',
          value:
            'Evaluate security architectures and designs to determine the adequacy of security design and architecture proposed or provided in response to requirements contained in acquisition documents.',
          softSkill: false,
        },
        {
          id: 'T0329',
          value: 'Follow software and systems engineering life cycle standards and processes.',
          softSkill: false,
        },
        {
          id: 'T0330',
          value: 'Maintain assured message delivery systems.',
          softSkill: false,
        },
        {
          id: 'T0331',
          value: 'Maintain incident tracking and solution database.',
          softSkill: false,
        },
        {
          id: 'T0332',
          value:
            "Notify designated managers, cyber incident responders, and cybersecurity service provider team members of suspected cyber incidents and articulate the event's history, status, and  potential impact for further action in accordance with the organization's cyber incident response plan.",
          softSkill: false,
        },
        {
          id: 'T0334',
          value:
            'Ensure that all systems components can be integrated and aligned (e.g., procedures, databases, policies, software, and hardware).',
          softSkill: false,
        },
        {
          id: 'T0335',
          value: 'Build, install, configure, and test dedicated cyber defense hardware.',
          softSkill: false,
        },
        {
          id: 'T0336',
          value: 'Withdrawn: Integrated with T0228',
          softSkill: false,
        },
        {
          id: 'T0337',
          value:
            'Supervise and assign work to programmers, designers, technologists and technicians, and other engineering and scientific personnel.',
          softSkill: false,
        },
        {
          id: 'T0338',
          value:
            'Write detailed functional specifications that document the architecture development process.',
          softSkill: false,
        },
        {
          id: 'T0339',
          value:
            "Lead efforts to promote the organization's use of knowledge management and information sharing.",
          softSkill: false,
        },
        {
          id: 'T0340',
          value:
            'Act as a primary stakeholder in the underlying information technology (IT) operational processes and functions that support the service, provide direction and monitor all significant activities so the service is delivered successfully.',
          softSkill: false,
        },
        {
          id: 'T0341',
          value:
            'Advocate for adequate funding for cyber training resources, to include both internal and industry-provided courses, instructors, and related materials.',
          softSkill: false,
        },
        {
          id: 'T0342',
          value: 'Analyze data sources to provide actionable recommendations.',
          softSkill: false,
        },
        {
          id: 'T0343',
          value: 'Analyze the crisis to ensure public, personal, and resource protection.',
          softSkill: false,
        },
        {
          id: 'T0344',
          value:
            'Assess all the configuration management (change configuration/release management) processes.',
          softSkill: false,
        },
        {
          id: 'T0345',
          value:
            'Assess effectiveness and efficiency of instruction according to ease of instructional technology use and student learning, knowledge transfer, and satisfaction.',
          softSkill: false,
        },
        {
          id: 'T0346',
          value:
            'Assess the behavior of the individual victim, witness, or suspect as it relates to the investigation.',
          softSkill: false,
        },
        {
          id: 'T0347',
          value: 'Assess the validity of source data and subsequent findings.',
          softSkill: false,
        },
        {
          id: 'T0348',
          value:
            'Assist in assessing the impact of implementing and sustaining a dedicated cyber defense infrastructure.',
          softSkill: false,
        },
        {
          id: 'T0349',
          value: 'Collect metrics and trending data.',
          softSkill: false,
        },
        {
          id: 'T0350',
          value:
            "Conduct a market analysis to identify, assess, and recommend commercial, Government offthe-shelf, and open source products for use within a system and ensure recommended products are in compliance with organization's evaluation and validation requirements.",
          softSkill: false,
        },
        {
          id: 'T0351',
          value: 'Conduct hypothesis testing using statistical processes.',
          softSkill: false,
        },
        {
          id: 'T0352',
          value: 'Conduct learning needs assessments and identify requirements.',
          softSkill: false,
        },
        {
          id: 'T0353',
          value:
            'Confer with systems analysts, engineers, programmers, and others to design application.',
          softSkill: false,
        },
        {
          id: 'T0354',
          value: 'Coordinate and manage the overall service provided to a customer end-to-end.',
          softSkill: false,
        },
        {
          id: 'T0355',
          value:
            'Coordinate with internal and external subject matter experts to ensure existing qualification standards reflect organizational functional requirements and meet industry standards.',
          softSkill: false,
        },
        {
          id: 'T0356',
          value:
            'Coordinate with organizational manpower stakeholders to ensure appropriate allocation and distribution of human capital assets.',
          softSkill: false,
        },
        {
          id: 'T0357',
          value:
            'Create interactive learning exercises to create an effective learning environment.',
          softSkill: false,
        },
        {
          id: 'T0358',
          value:
            'Design and develop system administration and management functionality for privileged access users.',
          softSkill: false,
        },
        {
          id: 'T0359',
          value:
            'Design, implement, test, and evaluate secure interfaces between information systems, physical systems, and/or embedded technologies.',
          softSkill: false,
        },
        {
          id: 'T0360',
          value:
            'Determine the extent of threats and recommend courses of action or countermeasures to mitigate risks.',
          softSkill: false,
        },
        {
          id: 'T0361',
          value: 'Develop and facilitate data-gathering methods.',
          softSkill: false,
        },
        {
          id: 'T0362',
          value:
            'Develop and implement standardized position descriptions based on established cyber work roles.',
          softSkill: false,
        },
        {
          id: 'T0363',
          value:
            'Develop and review recruiting, hiring, and retention procedures in accordance with current HR policies.',
          softSkill: false,
        },
        {
          id: 'T0364',
          value:
            'Develop cyber career field classification structure to include establishing career field entry requirements and other nomenclature such as codes and identifiers.',
          softSkill: false,
        },
        {
          id: 'T0365',
          value:
            'Develop or assist in the development of training policies and protocols for cyber training.',
          softSkill: false,
        },
        {
          id: 'T0366',
          value: 'Develop strategic insights from large data sets.',
          softSkill: false,
        },
        {
          id: 'T0367',
          value: 'Develop the goals and objectives for cyber curriculum.',
          softSkill: false,
        },
        {
          id: 'T0368',
          value:
            'Ensure that cyber career fields are managed in accordance with organizational HR policies and directives.',
          softSkill: false,
        },
        {
          id: 'T0369',
          value:
            'Ensure that cyber workforce management policies and processes comply with legal and organizational requirements regarding equal opportunity, diversity, and fair hiring/employment practices.',
          softSkill: false,
        },
        {
          id: 'T0370',
          value:
            'Ensure that appropriate Service-Level Agreements (SLAs) and underpinning contracts have been defined that clearly set out for the customer a description of the service and the measures for monitoring the service.',
          softSkill: false,
        },
        {
          id: 'T0371',
          value: 'Establish acceptable limits for the software application, network, or system.',
          softSkill: false,
        },
        {
          id: 'T0372',
          value:
            'Establish and collect metrics to monitor and validate cyber workforce readiness including analysis of cyber workforce data to assess the status of positions identified, filled, and filled with qualified personnel.',
          softSkill: false,
        },
        {
          id: 'T0373',
          value:
            'Establish and oversee waiver processes for cyber career field entry and training qualification requirements.',
          softSkill: false,
        },
        {
          id: 'T0374',
          value:
            'Establish cyber career paths to allow career progression, deliberate development, and growth within and between cyber career fields.',
          softSkill: false,
        },
        {
          id: 'T0375',
          value:
            'Establish manpower, personnel, and qualification data element standards to support cyber workforce management and reporting requirements.',
          softSkill: false,
        },
        {
          id: 'T0376',
          value:
            'Establish, resource, implement, and assess cyber workforce management programs in accordance with organizational requirements.',
          softSkill: false,
        },
        {
          id: 'T0377',
          value:
            'Gather feedback on customer satisfaction and internal service performance to foster continual improvement.',
          softSkill: false,
        },
        {
          id: 'T0378',
          value:
            'Incorporates risk-driven systems maintenance updates process to address system deficiencies (periodically and out of cycle).',
          softSkill: false,
        },
        {
          id: 'T0379',
          value:
            'Manage the internal relationship with information technology (IT) process owners supporting the service, assisting with the definition and agreement of Operating Level Agreements (OLAs).',
          softSkill: false,
        },
        {
          id: 'T0380',
          value:
            'Plan instructional strategies such as lectures, demonstrations, interactive exercises, multimedia presentations, video courses, web-based courses for most effective learning environment in conjunction with educators and trainers.',
          softSkill: false,
        },
        {
          id: 'T0381',
          value: 'Present technical information to technical and nontechnical audiences.',
          softSkill: false,
        },
        {
          id: 'T0382',
          value: 'Present data in creative formats.',
          softSkill: false,
        },
        {
          id: 'T0383',
          value: 'Program custom algorithms.',
          softSkill: false,
        },
        {
          id: 'T0384',
          value:
            "Promote awareness of cyber policy and strategy as appropriate among management and ensure sound principles are reflected in the organization's mission, vision, and goals.",
          softSkill: false,
        },
        {
          id: 'T0385',
          value:
            'Provide actionable recommendations to critical stakeholders based on data analysis and findings.',
          softSkill: false,
        },
        {
          id: 'T0386',
          value:
            'Provide criminal investigative support to trial counsel during the judicial process.',
          softSkill: false,
        },
        {
          id: 'T0387',
          value: 'Review and apply cyber career field qualification standards.',
          softSkill: false,
        },
        {
          id: 'T0388',
          value:
            'Review and apply organizational policies related to or influencing the cyber workforce.',
          softSkill: false,
        },
        {
          id: 'T0389',
          value:
            'Review service performance reports identifying any significant issues and variances, initiating, where necessary, corrective actions and ensuring that all outstanding issues are followed up.',
          softSkill: false,
        },
        {
          id: 'T0390',
          value:
            'Review/Assess cyber workforce effectiveness to adjust skill and/or qualification standards.',
          softSkill: false,
        },
        {
          id: 'T0391',
          value:
            'Support integration of qualified cyber workforce personnel into information systems life cycle development processes.',
          softSkill: false,
        },
        {
          id: 'T0392',
          value:
            'Utilize technical documentation or resources to implement a new mathematical, data science, or computer science method.',
          softSkill: false,
        },
        {
          id: 'T0393',
          value: 'Validate specifications and requirements for testability.',
          softSkill: false,
        },
        {
          id: 'T0394',
          value:
            'Work with other service managers and product owners to balance and prioritize services to meet overall customer requirements, constraints, and objectives.',
          softSkill: false,
        },
        {
          id: 'T0395',
          value: 'Write and publish after action reviews.',
          softSkill: false,
        },
        {
          id: 'T0396',
          value: 'Process image with appropriate tools depending on analyst’s goals.',
          softSkill: false,
        },
        {
          id: 'T0397',
          value: 'Perform Windows registry analysis.',
          softSkill: false,
        },
        {
          id: 'T0398',
          value:
            'Perform file and registry monitoring on the running system after identifying intrusion via dynamic analysis.',
          softSkill: false,
        },
        {
          id: 'T0399',
          value:
            'Enter media information into tracking database (e.g., Product Tracker Tool) for digital media that has been acquired.',
          softSkill: false,
        },
        {
          id: 'T0400',
          value: 'Correlate incident data and perform cyber defense reporting.',
          softSkill: false,
        },
        {
          id: 'T0401',
          value:
            'Maintain deployable cyber defense toolkit (e.g., specialized cyber defense software/hardware) to support Incident Response Team mission.',
          softSkill: false,
        },
        {
          id: 'T0402',
          value: 'Effectively allocate storage capacity in the design of data management systems.',
          softSkill: false,
        },
        {
          id: 'T0403',
          value:
            'Read, interpret, write, modify, and execute simple scripts (e.g., Perl, VBScript) on Windows and UNIX systems (e.g., those that perform tasks such as: parsing large data files, automating manual tasks, and fetching/processing remote data).',
          softSkill: false,
        },
        {
          id: 'T0404',
          value:
            'Utilize different programming languages to write code, open files, read files, and write output to different files.',
          softSkill: false,
        },
        {
          id: 'T0405',
          value:
            'Utilize open source language such as R and apply quantitative techniques (e.g., descriptive and inferential statistics, sampling, experimental design, parametric and non-parametric tests of difference, ordinary least squares regression, general line).',
          softSkill: false,
        },
        {
          id: 'T0406',
          value:
            'Ensure that design and development activities are properly documented (providing a functional description of implementation) and updated as necessary.',
          softSkill: false,
        },
        {
          id: 'T0407',
          value: 'Participate in the acquisition process as necessary.',
          softSkill: false,
        },
        {
          id: 'T0408',
          value:
            'Interpret and apply applicable laws, statutes, and regulatory documents and integrate into policy.',
          softSkill: false,
        },
        {
          id: 'T0409',
          value:
            'Troubleshoot prototype design and process issues throughout the product design, development, and pre-launch phases.',
          softSkill: false,
        },
        {
          id: 'T0410',
          value:
            'Identify functional- and security-related features to find opportunities for new capability development to exploit or mitigate vulnerabilities.',
          softSkill: false,
        },
        {
          id: 'T0411',
          value:
            'Identify and/or develop reverse engineering tools to enhance capabilities and detect vulnerabilities.',
          softSkill: false,
        },
        {
          id: 'T0412',
          value: 'onduct import/export reviews for acquiring systems and software.',
          softSkill: false,
        },
        {
          id: 'T0413',
          value:
            'Develop data management capabilities (e.g., cloud-based, centralized cryptographic key management) to include support to the mobile workforce. T0414 Develop supply chain, system, network, performance, and cybersecurity requirements. T0415 Ensure that supply chain, system, network, performance, and cybersecurity requirements are included in contract language and delivered.',
          softSkill: false,
        },
        {
          id: 'T0416',
          value:
            'Enable applications with public keying by leveraging existing public key infrastructure (PKI) libraries and incorporating certificate management and encryption functionalities when appropriate.',
          softSkill: false,
        },
        {
          id: 'T0417',
          value:
            'Identify and leverage the enterprise-wide security services while designing and developing secure applications (e.g., Enterprise PKI, Federated Identity server, Enterprise Antivirus solution) when appropriate.',
          softSkill: false,
        },
        {
          id: 'T0418',
          value: 'Install, update, and troubleshoot systems/servers.',
          softSkill: false,
        },
        {
          id: 'T0419',
          value:
            'Acquire and maintain a working knowledge of constitutional issues which arise in relevant laws, regulations, policies, agreements, standards, procedures, or other issuances.',
          softSkill: false,
        },
        {
          id: 'T0420',
          value:
            'Administer test bed(s), and test and evaluate applications, hardware infrastructure, rules/signatures, access controls, and configurations of platforms managed by service provider(s).',
          softSkill: false,
        },
        {
          id: 'T0421',
          value:
            'Manage the indexing/cataloguing, storage, and access of explicit organizational knowledge (e.g., hard copy documents, digital files).',
          softSkill: false,
        },
        {
          id: 'T0422',
          value: 'Implement data management standards, requirements, and specifications.',
          softSkill: false,
        },
        {
          id: 'T0423',
          value:
            'Analyze computer-generated threats for counter intelligence or criminal activity.',
          softSkill: false,
        },
        {
          id: 'T0424',
          value:
            'Analyze and provide information to stakeholders that will support the development of security application or modification of an existing security application.',
          softSkill: false,
        },
        {
          id: 'T0425',
          value: 'Analyze organizational cyber policy.',
          softSkill: false,
        },
        {
          id: 'T0426',
          value: 'Analyze the results of software, hardware, or interoperability testing.',
          softSkill: false,
        },
        {
          id: 'T0427',
          value: 'Analyze user needs and requirements to plan architecture.',
          softSkill: false,
        },
        {
          id: 'T0428',
          value:
            'Analyze security needs and software requirements to determine feasibility of design within time and cost constraints and security mandates.',
          softSkill: false,
        },
        {
          id: 'T0429',
          value:
            'Assess policy needs and collaborate with stakeholders to develop policies to govern cyber activities.',
          softSkill: false,
        },
        {
          id: 'T0430',
          value: 'Gather and preserve evidence used on the prosecution of computer crimes.',
          softSkill: false,
        },
        {
          id: 'T0431',
          value: 'Check system hardware availability, functionality, integrity, and efficiency.',
          softSkill: false,
        },
        {
          id: 'T0432',
          value:
            'Collect and analyze intrusion artifacts (e.g., source code, malware, and system configuration) and use discovered data to enable mitigation of potential cyber defense incidents within the enterprise.',
          softSkill: false,
        },
        {
          id: 'T0433',
          value:
            'Conduct analysis of log files, evidence, and other information to determine best methods for identifying the perpetrator(s) of a network intrusion or other crimes.',
          softSkill: false,
        },
        {
          id: 'T0434',
          value:
            'Conduct framing of pleadings to properly identify alleged violations of law, regulations, or policy/guidance.',
          softSkill: false,
        },
        {
          id: 'T0435',
          value:
            'Conduct periodic system maintenance including cleaning (both physically and electronically), disk checks, routine reboots, data dumps, and testing.',
          softSkill: false,
        },
        {
          id: 'T0436',
          value:
            'Conduct trial runs of programs and software applications to ensure that the desired information is produced and instructions and security levels are correct.',
          softSkill: false,
        },
        {
          id: 'T0437',
          value: 'Correlate training and learning to business or mission requirements.',
          softSkill: false,
        },
        {
          id: 'T0438',
          value:
            'Create, edit, and manage network access control lists on specialized cyber defense systems (e.g., firewalls and intrusion prevention systems).',
          softSkill: false,
        },
        {
          id: 'T0439',
          value:
            'Detect and analyze encrypted data, stenography, alternate data streams and other forms of concealed data.',
          softSkill: false,
        },
        {
          id: 'T0440',
          value:
            'Capture and integrate essential system capabilities or business functions required for partial or full system restoration after a catastrophic failure event.',
          softSkill: false,
        },
        {
          id: 'T0441',
          value: 'Define and integrate current and future mission environments.',
          softSkill: false,
        },
        {
          id: 'T0442',
          value: 'Create training courses tailored to the audience and physical environment.',
          softSkill: false,
        },
        {
          id: 'T0443',
          value:
            'Deliver training courses tailored to the audience and physical/virtual environments.',
          softSkill: false,
        },
        {
          id: 'T0444',
          value:
            'Apply concepts, procedures, software, equipment, and/or technology applications to students.',
          softSkill: false,
        },
        {
          id: 'T0445',
          value:
            'Design/integrate a cyber strategy that outlines the vision, mission, and goals that align with the organization’s strategic plan.',
          softSkill: false,
        },
        {
          id: 'T0446',
          value:
            'Design, develop, integrate, and update system security measures that provide confidentiality, integrity, availability, authentication, and non-repudiation.',
          softSkill: false,
        },
        {
          id: 'T0447',
          value:
            'Design hardware, operating systems, and software applications to adequately address requirements.',
          softSkill: false,
        },
        {
          id: 'T0448',
          value:
            'Develop enterprise architecture or system components required to meet user needs.',
          softSkill: false,
        },
        {
          id: 'T0449',
          value:
            'Design to security requirements to ensure requirements are met for all systems and/or applications.',
          softSkill: false,
        },
        {
          id: 'T0450',
          value: 'Design training curriculum and course content based on requirements.',
          softSkill: false,
        },
        {
          id: 'T0451',
          value: 'Participate in development of training curriculum and course content.',
          softSkill: false,
        },
        {
          id: 'T0452',
          value:
            'Design, build, implement, and maintain a knowledge management framework that provides end-users access to the organization’s intellectual capital.',
          softSkill: false,
        },
        {
          id: 'T0453',
          value:
            'Determine and develop leads and identify sources of information to identify and/or prosecute the responsible parties to an intrusion or other crimes.',
          softSkill: false,
        },
        {
          id: 'T0454',
          value: 'Define baseline security requirements in accordance with applicable guidelines.',
          softSkill: false,
        },
        {
          id: 'T0455',
          value:
            'Develop software system testing and validation procedures, programming, and documentation.',
          softSkill: false,
        },
        {
          id: 'T0456',
          value: 'Develop secure software testing and validation procedures.',
          softSkill: false,
        },
        {
          id: 'T0457',
          value:
            'Develop system testing and validation procedures, programming, and documentation.',
          softSkill: false,
        },
        {
          id: 'T0458',
          value: 'Comply with organization systems administration standard operating procedures.',
          softSkill: false,
        },
        {
          id: 'T0459',
          value: 'Implement data mining and data warehousing applications.',
          softSkill: false,
        },
        {
          id: 'T0460',
          value: 'Develop and implement data mining and data warehousing programs.',
          softSkill: false,
        },
        {
          id: 'T0461',
          value: 'Implement and enforce local network usage policies and procedures.',
          softSkill: false,
        },
        {
          id: 'T0462',
          value:
            'Develop procedures and test fail-over for system operations transfer to an alternate site based on system availability requirements.',
          softSkill: false,
        },
        {
          id: 'T0463',
          value: 'Develop cost estimates for new or modified system(s).',
          softSkill: false,
        },
        {
          id: 'T0464',
          value:
            'Develop detailed design documentation for component and interface specifications to support system design and development.',
          softSkill: false,
        },
        {
          id: 'T0465',
          value: 'Develop guidelines for implementation.',
          softSkill: false,
        },
        {
          id: 'T0466',
          value:
            'Develop mitigation strategies to address cost, schedule, performance, and security risks.',
          softSkill: false,
        },
        {
          id: 'T0467',
          value:
            'Ensure that training meets the goals and objectives for cybersecurity training, education, or awareness.',
          softSkill: false,
        },
        {
          id: 'T0468',
          value: 'Diagnose and resolve customer reported system incidents, problems, and events.',
          softSkill: false,
        },
        {
          id: 'T0469',
          value: 'Analyze and report organizational security posture trends.',
          softSkill: false,
        },
        {
          id: 'T0470',
          value: 'Analyze and report system security posture trends.',
          softSkill: false,
        },
        {
          id: 'T0471',
          value:
            'Document original condition of digital and/or associated evidence (e.g., via digital photographs, written reports, hash function checking).',
          softSkill: false,
        },
        {
          id: 'T0472',
          value: 'Draft, staff, and publish cyber policy.',
          softSkill: false,
        },
        {
          id: 'T0473',
          value: 'Document and update as necessary all definition and architecture activities.',
          softSkill: false,
        },
        {
          id: 'T0474',
          value:
            'Provide legal analysis and decisions to inspectors general, privacy officers, oversight and compliance personnel regarding compliance with cybersecurity policies and relevant legal and regulatory requirements.',
          softSkill: false,
        },
        {
          id: 'T0475',
          value:
            'Assess adequate access controls based on principles of least privilege and need-to-know.',
          softSkill: false,
        },
        {
          id: 'T0476',
          value:
            'Evaluate the impact of changes to laws, regulations, policies, standards, or procedures.',
          softSkill: false,
        },
        {
          id: 'T0477',
          value: 'Ensure the execution of disaster recovery and continuity of operations.',
          softSkill: false,
        },
        {
          id: 'T0478',
          value:
            'Provide guidance on laws, regulations, policies, standards, or procedures to management, personnel, or clients.',
          softSkill: false,
        },
        {
          id: 'T0479',
          value:
            'Employ information technology (IT) systems and digital storage media to solve, investigate, and/or prosecute cybercrimes and fraud committed against people and property.',
          softSkill: false,
        },
        {
          id: 'T0480',
          value:
            'Identify components or elements, allocate comprehensive functional components to include security functions, and describe the relationships between the elements.',
          softSkill: false,
        },
        {
          id: 'T0481',
          value:
            'Identify and address cyber workforce planning and management issues (e.g. recruitment, retention, and training).',
          softSkill: false,
        },
        {
          id: 'T0482',
          value:
            'Make recommendations based on trend analysis for enhancements to software and hardware solutions to enhance customer experience.',
          softSkill: false,
        },
        {
          id: 'T0483',
          value:
            'Identify potential conflicts with implementation of any cyber defense tools (e.g., tool and signature testing and optimization).',
          softSkill: false,
        },
        {
          id: 'T0484',
          value:
            'Determine the protection needs (i.e., security controls) for the information system(s) and network(s) and document appropriately.',
          softSkill: false,
        },
        {
          id: 'T0485',
          value:
            'Implement security measures to resolve vulnerabilities, mitigate risks, and recommend security changes to system or system components as needed.',
          softSkill: false,
        },
        {
          id: 'T0486',
          value:
            'Implement Risk Management Framework (RMF)/Security Assessment and Authorization (SA&A) requirements for dedicated cyber defense systems within the enterprise, and document and maintain records for them.',
          softSkill: false,
        },
        {
          id: 'T0487',
          value:
            'Facilitate implementation of new or revised laws, regulations, executive orders, policies, standards, or procedures.',
          softSkill: false,
        },
        {
          id: 'T0488',
          value: 'Implement designs for new or existing system(s).',
          softSkill: false,
        },
        {
          id: 'T0489',
          value:
            'Implement system security measures in accordance with established procedures to ensure confidentiality, integrity, availability, authentication, and non-repudiation.',
          softSkill: false,
        },
        {
          id: 'T0490',
          value: 'Install and configure database management systems and software.',
          softSkill: false,
        },
        {
          id: 'T0491',
          value:
            'Install and configure hardware, software, and peripheral equipment for system users in accordance with organizational standards.',
          softSkill: false,
        },
        {
          id: 'T0492',
          value:
            'Ensure the integration and implementation of Cross-Domain Solutions (CDS) in a secure environment.',
          softSkill: false,
        },
        {
          id: 'T0493',
          value: 'Lead and oversee budget, staffing, and contracting.',
          softSkill: false,
        },
        {
          id: 'T0494',
          value: 'Administer accounts, network rights, and access to systems and equipment.',
          softSkill: false,
        },
        {
          id: 'T0495',
          value: 'Manage Accreditation Packages (e.g., ISO/IEC 15026-2).',
          softSkill: false,
        },
        {
          id: 'T0496',
          value: 'Perform asset management/inventory of information technology (IT) resources.',
          softSkill: false,
        },
        {
          id: 'T0497',
          value:
            'Manage the information technology (IT) planning process to ensure that developed solutions meet customer requirements.',
          softSkill: false,
        },
        {
          id: 'T0498',
          value:
            'Manage system/server resources including performance, capacity, availability, serviceability, and recoverability.',
          softSkill: false,
        },
        {
          id: 'T0499',
          value:
            'Mitigate/correct security deficiencies identified during security/certification testing and/or recommend risk acceptance for the appropriate senior leader or authorized representative.',
          softSkill: false,
        },
        {
          id: 'T0500',
          value:
            'Modify and maintain existing software to correct errors, to adapt it to new hardware, or to upgrade interfaces and improve performance.',
          softSkill: false,
        },
        {
          id: 'T0501',
          value: 'Monitor and maintain system/server configuration.',
          softSkill: false,
        },
        {
          id: 'T0502',
          value: 'Monitor and report client-level computer system performance.',
          softSkill: false,
        },
        {
          id: 'T0503',
          value:
            'Monitor external data sources (e.g., cyber defense vendor sites, Computer Emergency Response Teams, Security Focus) to maintain currency of cyber defense threat condition and determine which security issues may have an impact on the enterprise.',
          softSkill: false,
        },
        {
          id: 'T0504',
          value:
            'Assess and monitor cybersecurity related to system implementation and testing practices.',
          softSkill: false,
        },
        {
          id: 'T0505',
          value:
            'Monitor the rigorous application of cyber policies, principles, and practices in the delivery of planning and management services.',
          softSkill: false,
        },
        {
          id: 'T0506',
          value: 'Seek consensus on proposed policy changes from stakeholders.',
          softSkill: false,
        },
        {
          id: 'T0507',
          value:
            'Oversee installation, implementation, configuration, and support of system components.',
          softSkill: false,
        },
        {
          id: 'T0508',
          value: 'Verify minimum security requirements are in place for all applications.',
          softSkill: false,
        },
        {
          id: 'T0509',
          value: 'Perform an information security risk assessment.',
          softSkill: false,
        },
        {
          id: 'T0510',
          value: 'Coordinate incident response functions.',
          softSkill: false,
        },
        {
          id: 'T0511',
          value: 'Perform developmental testing on systems under development.',
          softSkill: false,
        },
        {
          id: 'T0512',
          value:
            'Perform interoperability testing on systems exchanging electronic information with other systems.',
          softSkill: false,
        },
        {
          id: 'T0513',
          value: 'Perform operational testing.',
          softSkill: false,
        },
        {
          id: 'T0514',
          value: 'Diagnose faulty system/server hardware.',
          softSkill: false,
        },
        {
          id: 'T0515',
          value: 'Perform repairs on faulty system/server hardware.',
          softSkill: false,
        },
        {
          id: 'T0516',
          value:
            'Perform secure program testing, review, and/or assessment to identify potential flaws in codes and mitigate vulnerabilities.',
          softSkill: false,
        },
        {
          id: 'T0517',
          value: 'Integrate results regarding the identification of gaps in security architecture.',
          softSkill: false,
        },
        {
          id: 'T0518',
          value: 'Perform security reviews and identify security gaps in architecture.',
          softSkill: false,
        },
        {
          id: 'T0519',
          value:
            'Plan and coordinate the delivery of classroom techniques and formats (e.g., lectures, demonstrations, interactive exercises, multimedia presentations) for the most effective learning environment.',
          softSkill: false,
        },
        {
          id: 'T0520',
          value:
            'Plan non-classroom educational techniques and formats (e.g., video courses, mentoring, web-based courses).',
          softSkill: false,
        },
        {
          id: 'T0521',
          value:
            'Plan implementation strategy to ensure that enterprise components can be integrated and aligned.',
          softSkill: false,
        },
        {
          id: 'T0522',
          value:
            'Prepare legal and other relevant documents (e.g., depositions, briefs, affidavits, declarations, appeals, pleadings, discovery).',
          softSkill: false,
        },
        {
          id: 'T0523',
          value:
            'Prepare reports to document the investigation following legal standards and requirements.',
          softSkill: false,
        },
        {
          id: 'T0524',
          value:
            'Promote knowledge sharing between information owners/users through an organization’s operational processes and systems.',
          softSkill: false,
        },
        {
          id: 'T0525',
          value: 'Provide enterprise cybersecurity and supply chain risk management guidance.',
          softSkill: false,
        },
        {
          id: 'T0526',
          value:
            'Provides cybersecurity recommendations to leadership based on significant threats and vulnerabilities.',
          softSkill: false,
        },
        {
          id: 'T0527',
          value:
            'Provide input to implementation plans and standard operating procedures as they relate to information systems security.',
          softSkill: false,
        },
        {
          id: 'T0528',
          value:
            'Provide input to implementation plans, standard operating procedures, maintenance documentation, and maintenance training materials.',
          softSkill: false,
        },
        {
          id: 'T0529',
          value: 'Provide policy guidance to cyber management, staff, and users.',
          softSkill: false,
        },
        {
          id: 'T0530',
          value: 'Develop a trend analysis and impact report.',
          softSkill: false,
        },
        {
          id: 'T0531',
          value: 'Troubleshoot hardware/software interface and interoperability problems.',
          softSkill: false,
        },
        {
          id: 'T0532',
          value:
            'Review forensic images and other data sources (e.g., volatile data) for recovery of potentially relevant information.',
          softSkill: false,
        },
        {
          id: 'T0533',
          value: 'Review, conduct, or participate in audits of cyber programs and projects.',
          softSkill: false,
        },
        {
          id: 'T0534',
          value:
            'Conduct periodic reviews/revisions of course content for accuracy, completeness alignment, and currency (e.g., course content documents, lesson plans, student texts, examinations, schedules of instruction, and course descriptions).',
          softSkill: false,
        },
        {
          id: 'T0535',
          value:
            'Recommend revisions to curriculum and course content based on feedback from previous training sessions.',
          softSkill: false,
        },
        {
          id: 'T0536',
          value:
            'Serve as an internal consultant and advisor in own area of expertise (e.g., technical, copyright, print media, electronic media).',
          softSkill: false,
        },
        {
          id: 'T0537',
          value: 'Support the CIO in the formulation of cyber-related policies.',
          softSkill: false,
        },
        {
          id: 'T0538',
          value: 'Provide support to test and evaluation activities.',
          softSkill: false,
        },
        {
          id: 'T0539',
          value:
            'Test, evaluate, and verify hardware and/or software to determine compliance with defined specifications and requirements.',
          softSkill: false,
        },
        {
          id: 'T0540',
          value: 'Record and manage test data.',
          softSkill: false,
        },
        {
          id: 'T0541',
          value: 'Trace system requirements to design components and perform gap analysis.',
          softSkill: false,
        },
        {
          id: 'T0542',
          value: 'Translate proposed capabilities into technical requirements.',
          softSkill: false,
        },
        {
          id: 'T0544',
          value:
            'Verify stability, interoperability, portability, and/or scalability of system architecture.',
          softSkill: false,
        },
        {
          id: 'T0545',
          value:
            'Work with stakeholders to resolve computer security incidents and vulnerability compliance.',
          softSkill: false,
        },
        {
          id: 'T0546',
          value:
            'Write and publish cyber defense recommendations, reports, and white papers on incident findings to appropriate constituencies.',
          softSkill: false,
        },
        {
          id: 'T0547',
          value:
            'Research and evaluate available technologies and standards to meet customer requirements.',
          softSkill: false,
        },
        {
          id: 'T0548',
          value:
            'Provide advice and input for Disaster Recovery, Contingency, and Continuity of Operations Plans.',
          softSkill: false,
        },
        {
          id: 'T0549',
          value:
            'Perform technical (evaluation of technology) and nontechnical (evaluation of people and operations) risk and vulnerability assessments of relevant technology focus areas (e.g., local computing environment, network and infrastructure, enclave boundary, supporting infrastructure, and applications).',
          softSkill: false,
        },
        {
          id: 'T0550',
          value:
            'Make recommendations regarding the selection of cost-effective security controls to mitigate risk (e.g., protection of information, systems and processes).',
          softSkill: false,
        },
        {
          id: 'T0551',
          value: 'Draft and publish supply chain security and risk management documents.',
          softSkill: false,
        },
        {
          id: 'T0552',
          value: 'Review and approve a supply chain security/risk management policy.',
          softSkill: false,
        },
        {
          id: 'T0553',
          value:
            'Apply cybersecurity functions (e.g., encryption, access control, and identity management) to reduce exploitation opportunities.',
          softSkill: false,
        },
        {
          id: 'T0554',
          value:
            'Determine and document software patches or the extent of releases that would leave software vulnerable.',
          softSkill: false,
        },
        {
          id: 'T0555',
          value:
            'Document how the implementation of a new system or new interface between systems impacts the current and target environment including but not limited to security posture.',
          softSkill: false,
        },
        {
          id: 'T0556',
          value: 'Assess and design security management functions as related to cyberspace.',
          softSkill: false,
        },
        {
          id: 'T0557',
          value: 'Integrate key management functions as related to cyberspace.',
          softSkill: false,
        },
        {
          id: 'T0558',
          value: 'Analyze user needs and requirements to plan and conduct system development.',
          softSkill: false,
        },
        {
          id: 'T0559',
          value:
            'Develop designs to meet specific operational needs and environmental factors (e.g., access controls, automated applications, networked operations.',
          softSkill: false,
        },
        {
          id: 'T0560',
          value:
            'Collaborate on cybersecurity designs to meet specific operational needs and environmental factors (e.g., access controls, automated applications, networked operations, high integrity and availability requirements, multilevel security/processing of multiple classification levels, and processing Sensitive Compartmented Information).',
          softSkill: false,
        },
        {
          id: 'T0561',
          value: 'Accurately characterize targets.',
          softSkill: false,
        },
        {
          id: 'T0562',
          value:
            'Adjust collection operations or collection plan to address identified issues/challenges and to synchronize collections with overall operational requirements.',
          softSkill: false,
        },
        {
          id: 'T0563',
          value:
            'Provide input to the analysis, design, development or acquisition of capabilities used for meeting objectives.',
          softSkill: false,
        },
        {
          id: 'T0564',
          value:
            'Analyze feedback to determine extent to which collection products and services are meeting requirements.',
          softSkill: false,
        },
        {
          id: 'T0565',
          value: 'Analyze incoming collection requests.',
          softSkill: false,
        },
        {
          id: 'T0566',
          value:
            'Analyze internal operational architecture, tools, and procedures for ways to improve performance.',
          softSkill: false,
        },
        {
          id: 'T0567',
          value: 'Analyze target operational architecture for ways to gain access.',
          softSkill: false,
        },
        {
          id: 'T0568',
          value:
            "Analyze plans, directives, guidance and policy for factors that would influence collection management's operational structure and requirement s (e.g., duration, scope, communication requirements, interagency/international agreements).",
          softSkill: false,
        },
        {
          id: 'T0569',
          value: 'Answer requests for information.',
          softSkill: false,
        },
        {
          id: 'T0570',
          value:
            'Apply and utilize authorized cyber capabilities to enable access to targeted networks.',
          softSkill: false,
        },
        {
          id: 'T0571',
          value:
            'Apply expertise in policy and processes to facilitate the development, negotiation, and internal staffing of plans and/or memorandums of agreement.',
          softSkill: false,
        },
        {
          id: 'T0572',
          value:
            'Apply cyber collection, environment preparation and engagement expertise to enable new exploitation and/or continued collection operations, or in support of customer requirements.',
          softSkill: false,
        },
        {
          id: 'T0573',
          value:
            'Assess and apply operational environment factors and risks to collection management process.',
          softSkill: false,
        },
        {
          id: 'T0574',
          value: 'Apply and obey applicable statutes, laws, regulations and policies.',
          softSkill: false,
        },
        {
          id: 'T0575',
          value: 'Coordinate for intelligence support to operational planning activities.',
          softSkill: false,
        },
        {
          id: 'T0576',
          value:
            'Assess all-source intelligence and recommend targets to support cyber operation objectives.',
          softSkill: false,
        },
        {
          id: 'T0577',
          value: 'Assess efficiency of existing information exchange and management systems.',
          softSkill: false,
        },
        {
          id: 'T0578',
          value: 'Assess performance of collection assets against prescribed specifications.',
          softSkill: false,
        },
        {
          id: 'T0579',
          value:
            'Assess target vulnerabilities and/or operational capabilities to determine course of action.',
          softSkill: false,
        },
        {
          id: 'T0580',
          value:
            'Assess the effectiveness of collections in satisfying priority information gaps, using available capabilities and methods, and adjust collection strategies and collection requirements accordingly.',
          softSkill: false,
        },
        {
          id: 'T0581',
          value:
            'Assist and advise interagency partners in identifying and developing best practices for facilitating operational support to achievement of organization objectives.',
          softSkill: false,
        },
        {
          id: 'T0582',
          value: 'Provide expertise to course of action development.',
          softSkill: false,
        },
        {
          id: 'T0583',
          value:
            'Provide subject matter expertise to the development of a common operational picture.',
          softSkill: false,
        },
        {
          id: 'T0584',
          value: 'Maintain a common intelligence picture.',
          softSkill: false,
        },
        {
          id: 'T0585',
          value:
            'Provide subject matter expertise to the development of cyber operations specific indicators.',
          softSkill: false,
        },
        {
          id: 'T0586',
          value:
            'Assist in the coordination, validation, and management of all-source collection requirements, plans, and/or activities.',
          softSkill: false,
        },
        {
          id: 'T0587',
          value: 'Assist in the development and refinement of priority information requirements.',
          softSkill: false,
        },
        {
          id: 'T0588',
          value:
            'Provide expertise to the development of measures of effectiveness and measures of performance.',
          softSkill: false,
        },
        {
          id: 'T0589',
          value: 'Assist in the identification of intelligence collection shortfalls.',
          softSkill: false,
        },
        {
          id: 'T0590',
          value:
            'Enable synchronization of intelligence support plans across partner organizations as required.',
          softSkill: false,
        },
        {
          id: 'T0591',
          value: 'Perform analysis for target infrastructure exploitation activities.',
          softSkill: false,
        },
        {
          id: 'T0592',
          value: 'Provide input to the identification of cyber-related success criteria.',
          softSkill: false,
        },
        {
          id: 'T0593',
          value: 'Brief threat and/or target current situations.',
          softSkill: false,
        },
        {
          id: 'T0594',
          value: 'Build and maintain electronic target folders.',
          softSkill: false,
        },
        {
          id: 'T0595',
          value: 'Classify documents in accordance with classification guidelines.',
          softSkill: false,
        },
        {
          id: 'T0596',
          value: 'Close requests for information once satisfied.',
          softSkill: false,
        },
        {
          id: 'T0597',
          value:
            'Collaborate with intelligence analysts/targeting organizations involved in related areas.',
          softSkill: false,
        },
        {
          id: 'T0598',
          value:
            'Collaborate with development organizations to create and deploy the tools needed to achieve objectives.',
          softSkill: false,
        },
        {
          id: 'T0599',
          value:
            'Collaborate with other customer, Intelligence and targeting organizations involved in related cyber areas.',
          softSkill: false,
        },
        {
          id: 'T0600',
          value:
            'Collaborate with other internal and external partner organizations on target access and operational issues.',
          softSkill: false,
        },
        {
          id: 'T0601',
          value:
            'Collaborate with other team members or partner organizations to develop a diverse program of information materials (e.g., web pages, briefings, print materials).',
          softSkill: false,
        },
        {
          id: 'T0602',
          value: 'Collaborate with customer to define information requirements.',
          softSkill: false,
        },
        {
          id: 'T0603',
          value:
            'Communicate new developments, breakthroughs, challenges and lessons learned to leadership, and internal and external customers.',
          softSkill: false,
        },
        {
          id: 'T0604',
          value:
            'Compare allocated and available assets to collection demand as expressed through requirements.',
          softSkill: false,
        },
        {
          id: 'T0605',
          value:
            "Compile lessons learned from collection management activity's execution of organization collection objectives.",
          softSkill: false,
        },
        {
          id: 'T0606',
          value:
            'Compile, integrate, and/or interpret all-source data for intelligence or vulnerability value with respect to specific targets.',
          softSkill: false,
        },
        {
          id: 'T0607',
          value:
            'Identify and conduct analysis of target communications to identify information essential to support operations.',
          softSkill: false,
        },
        {
          id: 'T0608',
          value:
            'Conduct analysis of physical and logical digital technologies (e.g., wireless, SCADA, telecom) to identify potential avenues of access.',
          softSkill: false,
        },
        {
          id: 'T0609',
          value: 'Conduct access enabling of wireless computer and digital networks.',
          softSkill: false,
        },
        {
          id: 'T0610',
          value: 'Conduct collection and processing of wireless computer and digital networks.',
          softSkill: false,
        },
        {
          id: 'T0611',
          value: 'Conduct end-of-operations assessments.',
          softSkill: false,
        },
        {
          id: 'T0612',
          value: 'Conduct exploitation of wireless computer and digital networks.',
          softSkill: false,
        },
        {
          id: 'T0613',
          value:
            'Conduct formal and informal coordination of collection requirements in accordance with established guidelines and procedures.',
          softSkill: false,
        },
        {
          id: 'T0614',
          value:
            'Conduct independent in-depth target and technical analysis including target-specific information (e.g., cultural, organizational, political) that results in access.',
          softSkill: false,
        },
        {
          id: 'T0615',
          value: 'Conduct in-depth research and analysis.',
          softSkill: false,
        },
        {
          id: 'T0616',
          value: 'Conduct network scouting and vulnerability analyses of systems within a network.',
          softSkill: false,
        },
        {
          id: 'T0617',
          value: 'Conduct nodal analysis.',
          softSkill: false,
        },
        {
          id: 'T0618',
          value:
            'Conduct on-net activities to control and exfiltrate data from deployed technologies.',
          softSkill: false,
        },
        {
          id: 'T0619',
          value:
            'Conduct on-net and off-net activities to control, and exfiltrate data from deployed, automated technologies.',
          softSkill: false,
        },
        {
          id: 'T0620',
          value: 'Conduct open source data collection via various online tools.',
          softSkill: false,
        },
        {
          id: 'T0621',
          value:
            'Conduct quality control to determine validity and relevance of information gathered about networks.',
          softSkill: false,
        },
        {
          id: 'T0622',
          value:
            'Develop, review and implement all levels of planning guidance in support of cyber operations.',
          softSkill: false,
        },
        {
          id: 'T0623',
          value: 'Conduct survey of computer and digital networks.',
          softSkill: false,
        },
        {
          id: 'T0624',
          value: 'Conduct target research and analysis.',
          softSkill: false,
        },
        {
          id: 'T0625',
          value:
            'Consider efficiency and effectiveness of collection assets and resources if/when applied against priority information requirements.',
          softSkill: false,
        },
        {
          id: 'T0626',
          value:
            'Construct collection plans and matrixes using established guidance and procedures.',
          softSkill: false,
        },
        {
          id: 'T0627',
          value: 'Contribute to crisis action planning for cyber operations.',
          softSkill: false,
        },
        {
          id: 'T0628',
          value:
            "Contribute to the development of the organization's decision support tools if necessary.",
          softSkill: false,
        },
        {
          id: 'T0629',
          value:
            'Contribute to the development, staffing, and coordination of cyber operations policies, performance standards, plans and approval packages with appropriate internal and/or external decision makers.',
          softSkill: false,
        },
        {
          id: 'T0630',
          value:
            'Incorporate intelligence equities into the overall design of cyber operations plans.',
          softSkill: false,
        },
        {
          id: 'T0631',
          value:
            'Coordinate resource allocation of collection assets against prioritized collection requirements with collection discipline leads.',
          softSkill: false,
        },
        {
          id: 'T0632',
          value: 'Coordinate inclusion of collection plan in appropriate documentation.',
          softSkill: false,
        },
        {
          id: 'T0633',
          value: 'Coordinate target vetting with appropriate partners.',
          softSkill: false,
        },
        {
          id: 'T0634',
          value: 'Re-task or re-direct collection assets and resources.',
          softSkill: false,
        },
        {
          id: 'T0635',
          value:
            'Coordinate with intelligence and cyber defense partners to obtain relevant essential information.',
          softSkill: false,
        },
        {
          id: 'T0636',
          value:
            'Coordinate with intelligence planners to ensure that collection managers receive information requirements.',
          softSkill: false,
        },
        {
          id: 'T0637',
          value:
            'Coordinate with the intelligence planning team to assess capability to satisfy assigned intelligence tasks.',
          softSkill: false,
        },
        {
          id: 'T0638',
          value: 'Coordinate, produce, and track intelligence requirements.',
          softSkill: false,
        },
        {
          id: 'T0639',
          value:
            'Coordinate, synchronize and draft applicable intelligence sections of cyber operations plans.',
          softSkill: false,
        },
        {
          id: 'T0640',
          value: 'Use intelligence estimates to counter potential target actions.',
          softSkill: false,
        },
        {
          id: 'T0641',
          value:
            'Create comprehensive exploitation strategies that identify exploitable technical or operational vulnerabilities.',
          softSkill: false,
        },
        {
          id: 'T0642',
          value:
            'Maintain awareness of internal and external cyber organization structures, strengths, and employments of staffing and technology.',
          softSkill: false,
        },
        {
          id: 'T0643',
          value:
            'Deploy tools to a target and utilize them once deployed (e.g., backdoors, sniffers).',
          softSkill: false,
        },
        {
          id: 'T0644',
          value: 'Detect exploits against targeted networks and hosts and react accordingly.',
          softSkill: false,
        },
        {
          id: 'T0645',
          value:
            'Determine course of action for addressing changes to objectives, guidance, and operational environment.',
          softSkill: false,
        },
        {
          id: 'T0646',
          value:
            'Determine existing collection management webpage databases, libraries and storehouses.',
          softSkill: false,
        },
        {
          id: 'T0647',
          value:
            "Determine how identified factors affect the tasking, collection, processing, exploitation and dissemination architecture's form and function.",
          softSkill: false,
        },
        {
          id: 'T0648',
          value:
            'Determine indicators (e.g., measures of effectiveness) that are best suited to specific cyber operation objectives.',
          softSkill: false,
        },
        {
          id: 'T0649',
          value:
            'Determine organizations and/or echelons with collection authority over all accessible collection assets.',
          softSkill: false,
        },
        {
          id: 'T0650',
          value: 'Determine what technologies are used by a given target.',
          softSkill: false,
        },
        {
          id: 'T0651',
          value:
            'Develop a method for comparing collection reports to outstanding requirements to identify information gaps.',
          softSkill: false,
        },
        {
          id: 'T0652',
          value: 'Develop all-source intelligence targeting materials.',
          softSkill: false,
        },
        {
          id: 'T0653',
          value: 'Apply analytic techniques to gain more target information.',
          softSkill: false,
        },
        {
          id: 'T0654',
          value: 'Develop and maintain deliberate and/or crisis plans.',
          softSkill: false,
        },
        {
          id: 'T0655',
          value:
            'Develop and review specific cyber operations guidance for integration into broader planning activities.',
          softSkill: false,
        },
        {
          id: 'T0656',
          value:
            'Develop and review intelligence guidance for integration into supporting cyber operations planning and execution.',
          softSkill: false,
        },
        {
          id: 'T0657',
          value:
            'Develop coordinating instructions by collection discipline for each phase of an operation.',
          softSkill: false,
        },
        {
          id: 'T0658',
          value:
            'Develop cyber operations plans and guidance to ensure that execution and resource allocation decisions align with organization objectives.',
          softSkill: false,
        },
        {
          id: 'T0659',
          value: 'Develop detailed intelligence support to cyber operations requirements.',
          softSkill: false,
        },
        {
          id: 'T0660',
          value:
            'Develop information requirements necessary for answering priority information requests.',
          softSkill: false,
        },
        {
          id: 'T0661',
          value: 'Develop measures of effectiveness and measures of performance.',
          softSkill: false,
        },
        {
          id: 'T0662',
          value:
            "Allocate collection assets based on leadership's guidance, priorities, and/or operational emphasis.",
          softSkill: false,
        },
        {
          id: 'T0663',
          value: 'Develop munitions effectiveness assessment or operational assessment materials.',
          softSkill: false,
        },
        {
          id: 'T0664',
          value: 'Develop new techniques for gaining and keeping access to target systems.',
          softSkill: false,
        },
        {
          id: 'T0665',
          value:
            'Develop or participate in the development of standards for providing, requesting, and/or obtaining support from external partners to synchronize cyber operations.',
          softSkill: false,
        },
        {
          id: 'T0666',
          value:
            'Develop or shape international cyber engagement strategies, policies, and activities to meet organization objectives.',
          softSkill: false,
        },
        {
          id: 'T0667',
          value: 'Develop potential courses of action.',
          softSkill: false,
        },
        {
          id: 'T0668',
          value:
            'Develop procedures for providing feedback to collection managers, asset managers, and processing, exploitation and dissemination centers.',
          softSkill: false,
        },
        {
          id: 'T0669',
          value:
            'Develop strategy and processes for partner planning, operations, and capability development.',
          softSkill: false,
        },
        {
          id: 'T0670',
          value:
            'Develop, implement, and recommend changes to appropriate planning procedures and policies.',
          softSkill: false,
        },
        {
          id: 'T0671',
          value:
            'Develop, maintain, and assess cyber cooperation security agreements with external partners.',
          softSkill: false,
        },
        {
          id: 'T0672',
          value: 'Devise, document, and validate cyber operation strategy and planning documents.',
          softSkill: false,
        },
        {
          id: 'T0673',
          value: 'Disseminate reports to inform decision makers on collection issues.',
          softSkill: false,
        },
        {
          id: 'T0674',
          value: 'Disseminate tasking messages and collection plans.',
          softSkill: false,
        },
        {
          id: 'T0675',
          value:
            'Conduct and document an assessment of the collection results using established procedures.',
          softSkill: false,
        },
        {
          id: 'T0676',
          value: 'Draft cyber intelligence collection and production requirements.',
          softSkill: false,
        },
        {
          id: 'T0677',
          value:
            'Edit or execute simple scripts (e.g., Perl, VBScript) on Windows and UNIX systems.',
          softSkill: false,
        },
        {
          id: 'T0678',
          value: 'Engage customers to understand customers’ intelligence needs and wants.',
          softSkill: false,
        },
        {
          id: 'T0679',
          value:
            'Ensure operational planning efforts are effectively transitioned to current operations.',
          softSkill: false,
        },
        {
          id: 'T0680',
          value:
            'Ensure that intelligence planning activities are integrated and synchronized with operational planning timelines.',
          softSkill: false,
        },
        {
          id: 'T0681',
          value:
            'Establish alternative processing, exploitation and dissemination pathways to address identified issues or problems.',
          softSkill: false,
        },
        {
          id: 'T0682',
          value:
            'Validate the link between collection requests and critical information requirements and priority intelligence requirements of leadership.',
          softSkill: false,
        },
        {
          id: 'T0683',
          value:
            'Establish processing, exploitation and dissemination management activity using approved guidance and/or procedures.',
          softSkill: false,
        },
        {
          id: 'T0684',
          value: 'Estimate operational effects generated through cyber activities.',
          softSkill: false,
        },
        {
          id: 'T0685',
          value: 'Evaluate threat decision-making processes.',
          softSkill: false,
        },
        {
          id: 'T0686',
          value: 'Identify threat vulnerabilities.',
          softSkill: false,
        },
        {
          id: 'T0687',
          value: 'Identify threats to Blue Force vulnerabilities.',
          softSkill: false,
        },
        {
          id: 'T0688',
          value:
            'Evaluate available capabilities against desired effects to recommend efficient solutions.',
          softSkill: false,
        },
        {
          id: 'T0689',
          value:
            'Evaluate extent to which collected information and/or produced intelligence satisfy information requests.',
          softSkill: false,
        },
        {
          id: 'T0690',
          value: 'Evaluate intelligence estimates to support the planning cycle.',
          softSkill: false,
        },
        {
          id: 'T0691',
          value:
            'Evaluate the conditions that affect employment of available cyber intelligence capabilities.',
          softSkill: false,
        },
        {
          id: 'T0692',
          value: 'Generate and evaluate the effectiveness of network analysis strategies.',
          softSkill: false,
        },
        {
          id: 'T0693',
          value:
            'Evaluate extent to which collection operations are synchronized with operational requirements.',
          softSkill: false,
        },
        {
          id: 'T0694',
          value: 'Evaluate the effectiveness of collection operations against the collection plan.',
          softSkill: false,
        },
        {
          id: 'T0695',
          value:
            'Examine intercept-related metadata and content with an understanding of targeting significance.',
          softSkill: false,
        },
        {
          id: 'T0696',
          value:
            'Exploit network devices, security devices, and/or terminals or environments using various methods or tools.',
          softSkill: false,
        },
        {
          id: 'T0697',
          value: 'Facilitate access enabling by physical and/or wireless means.',
          softSkill: false,
        },
        {
          id: 'T0698',
          value:
            'Facilitate continuously updated intelligence, surveillance, and visualization input to common operational picture managers.',
          softSkill: false,
        },
        {
          id: 'T0699',
          value:
            'Facilitate interactions between internal and external partner decision makers to synchronize and integrate courses of action in support of objectives.',
          softSkill: false,
        },
        {
          id: 'T0700',
          value:
            'Facilitate the sharing of “best practices” and “lessons learned” throughout the cyber operations community.',
          softSkill: false,
        },
        {
          id: 'T0701',
          value:
            'Collaborate with developers, conveying target and technical knowledge in tool requirements submissions, to enhance tool development.',
          softSkill: false,
        },
        {
          id: 'T0702',
          value:
            'Formulate collection strategies based on knowledge of available intelligence discipline capabilities and gathering methods that align multi-discipline collection capabilities and accesses with targets and their observables.',
          softSkill: false,
        },
        {
          id: 'T0703',
          value:
            'Gather and analyze data (e.g., measures of effectiveness) to determine effectiveness, and provide reporting for follow-on activities.',
          softSkill: false,
        },
        {
          id: 'T0704',
          value:
            'Incorporate cyber operations and communications security support plans into organization objectives.',
          softSkill: false,
        },
        {
          id: 'T0705',
          value: 'Incorporate intelligence and counterintelligence to support plan development.',
          softSkill: false,
        },
        {
          id: 'T0706',
          value:
            'Gather information about networks through traditional and alternative techniques, (e.g., social network analysis, call-chaining, traffic analysis.).',
          softSkill: false,
        },
        {
          id: 'T0707',
          value: 'Generate requests for information.',
          softSkill: false,
        },
        {
          id: 'T0708',
          value: 'Identify threat tactics, and methodologies.',
          softSkill: false,
        },
        {
          id: 'T0709',
          value:
            'Identify all available partner intelligence capabilities and limitations supporting cyber operations.',
          softSkill: false,
        },
        {
          id: 'T0710',
          value:
            'Identify and evaluate threat critical capabilities, requirements, and vulnerabilities.',
          softSkill: false,
        },
        {
          id: 'T0711',
          value:
            'Identify, draft, evaluate, and prioritize relevant intelligence or information requirements.',
          softSkill: false,
        },
        {
          id: 'T0712',
          value: 'Identify and manage security cooperation priorities with external partners.',
          softSkill: false,
        },
        {
          id: 'T0713',
          value:
            'Identify and submit intelligence requirements for the purposes of designating priority information requirements.',
          softSkill: false,
        },
        {
          id: 'T0714',
          value:
            'Identify collaboration forums that can serve as mechanisms for coordinating processes, functions, and outputs with specified organizations and functional groups.',
          softSkill: false,
        },
        {
          id: 'T0715',
          value: 'Identify collection gaps and potential collection strategies against targets.',
          softSkill: false,
        },
        {
          id: 'T0716',
          value:
            'Identify coordination requirements and procedures with designated collection authorities.',
          softSkill: false,
        },
        {
          id: 'T0717',
          value: 'Identify critical target elements.',
          softSkill: false,
        },
        {
          id: 'T0718',
          value: 'Identify intelligence gaps and shortfalls.',
          softSkill: false,
        },
        {
          id: 'T0719',
          value: 'Identify cyber intelligence gaps and shortfalls for cyber operational planning.',
          softSkill: false,
        },
        {
          id: 'T0720',
          value:
            'Identify gaps in our understanding of target technology and developing innovative collection approaches.',
          softSkill: false,
        },
        {
          id: 'T0721',
          value:
            'Identify issues or problems that can disrupt and/or degrade processing, exploitation and dissemination architecture effectiveness.',
          softSkill: false,
        },
        {
          id: 'T0722',
          value:
            'Identify network components and their functionality to enable analysis and target development.',
          softSkill: false,
        },
        {
          id: 'T0723',
          value:
            'Identify potential collection disciplines for application against priority information requirements.',
          softSkill: false,
        },
        {
          id: 'T0724',
          value: 'Identify potential points of strength and vulnerability within a network.',
          softSkill: false,
        },
        {
          id: 'T0725',
          value:
            'Identify and mitigate risks to collection management ability to support the plan, operations and target cycle.',
          softSkill: false,
        },
        {
          id: 'T0726',
          value:
            'Identify the need, scope, and timeframe for applicable intelligence environment preparation derived production.',
          softSkill: false,
        },
        {
          id: 'T0727',
          value: 'Identify, locate, and track targets via geospatial analysis techniques.',
          softSkill: false,
        },
        {
          id: 'T0728',
          value: 'Provide input to or develop courses of action based on threat factors.',
          softSkill: false,
        },
        {
          id: 'T0729',
          value:
            'Inform external partners of the potential effects of new or revised policy and guidance on cyber operations partnering activities.',
          softSkill: false,
        },
        {
          id: 'T0730',
          value:
            'Inform stakeholders (e.g., collection managers, asset managers, processing, exploitation and dissemination centers) of evaluation results using established procedures.',
          softSkill: false,
        },
        {
          id: 'T0731',
          value: 'Initiate requests to guide tasking and assist with collection management.',
          softSkill: false,
        },
        {
          id: 'T0732',
          value: 'Integrate cyber planning/targeting efforts with other organizations.',
          softSkill: false,
        },
        {
          id: 'T0733',
          value: 'Interpret environment preparations assessments to determine a course of action.',
          softSkill: false,
        },
        {
          id: 'T0734',
          value: 'Issue requests for information.',
          softSkill: false,
        },
        {
          id: 'T0735',
          value: 'Lead and coordinate intelligence support to operational planning.',
          softSkill: false,
        },
        {
          id: 'T0736',
          value:
            'Lead or enable exploitation operations in support of organization objectives and target requirements.',
          softSkill: false,
        },
        {
          id: 'T0737',
          value: 'Link priority collection requirements to optimal assets and resources.',
          softSkill: false,
        },
        {
          id: 'T0738',
          value:
            'Maintain awareness of advancements in hardware and software technologies (e.g., attend training or conferences, reading) and their potential implications.',
          softSkill: false,
        },
        {
          id: 'T0739',
          value:
            'Maintain relationships with internal and external partners involved in cyber planning or related areas.',
          softSkill: false,
        },
        {
          id: 'T0740',
          value:
            'Maintain situational awareness and functionality of organic operational infrastructure.',
          softSkill: false,
        },
        {
          id: 'T0741',
          value:
            'Maintain situational awareness of cyber-related intelligence requirements and associated tasking.',
          softSkill: false,
        },
        {
          id: 'T0742',
          value: 'Maintain situational awareness of partner capabilities and activities.',
          softSkill: false,
        },
        {
          id: 'T0743',
          value:
            'Maintain situational awareness to determine if changes to the operating environment require review of the plan.',
          softSkill: false,
        },
        {
          id: 'T0744',
          value: 'Maintain target lists (i.e., RTL, JTL, CTL, etc.).',
          softSkill: false,
        },
        {
          id: 'T0745',
          value: 'Make recommendations to guide collection in support of customer requirements.',
          softSkill: false,
        },
        {
          id: 'T0746',
          value: 'Modify collection requirements as necessary.',
          softSkill: false,
        },
        {
          id: 'T0747',
          value:
            'Monitor and evaluate integrated cyber operations to identify opportunities to meet organization objectives.',
          softSkill: false,
        },
        {
          id: 'T0748',
          value:
            'Monitor and report changes in threat dispositions, activities, tactics, capabilities, objectives, etc. as related to designated cyber operations warning problem sets.',
          softSkill: false,
        },
        {
          id: 'T0749',
          value: 'Monitor and report on validated threat activities.',
          softSkill: false,
        },
        {
          id: 'T0750',
          value: 'Monitor completion of reallocated collection efforts.',
          softSkill: false,
        },
        {
          id: 'T0751',
          value:
            'Monitor open source websites for hostile content directed towards organizational or partner interests.',
          softSkill: false,
        },
        {
          id: 'T0752',
          value:
            'Monitor operational environment and report on adversarial activities which fulfill leadership’s priority information requirements.',
          softSkill: false,
        },
        {
          id: 'T0753',
          value:
            'Monitor operational status and effectiveness of the processing, exploitation and dissemination architecture.',
          softSkill: false,
        },
        {
          id: 'T0754',
          value:
            'Monitor target networks to provide indications and warning of target communications changes or processing failures.',
          softSkill: false,
        },
        {
          id: 'T0755',
          value:
            'Monitor the operational environment for potential factors and risks to the collection operation management process.',
          softSkill: false,
        },
        {
          id: 'T0756',
          value:
            'Operate and maintain automated systems for gaining and maintaining access to target systems.',
          softSkill: false,
        },
        {
          id: 'T0757',
          value:
            'Optimize mix of collection assets and resources to increase effectiveness and efficiency against essential information associated with priority intelligence requirements.',
          softSkill: false,
        },
        {
          id: 'T0758',
          value:
            'Produce timely, fused, all-source cyber operations intelligence and/or indications and warnings intelligence products (e.g., threat assessments, briefings, intelligence studies, country studies).',
          softSkill: false,
        },
        {
          id: 'T0759',
          value:
            'Contribute to the review and refinement of policy, to include assessments of the consequences of endorsing or not endorsing such policy.',
          softSkill: false,
        },
        {
          id: 'T0760',
          value:
            'Provide subject matter expertise to planning teams, coordination groups, and task forces as necessary.',
          softSkill: false,
        },
        {
          id: 'T0761',
          value:
            'Provide subject-matter expertise and support to planning/developmental forums and working groups as appropriate.',
          softSkill: false,
        },
        {
          id: 'T0763',
          value:
            'Conduct long-range, strategic planning efforts with internal and external partners in cyber activities.',
          softSkill: false,
        },
        {
          id: 'T0764',
          value:
            'Provide subject matter expertise to planning efforts with internal and external cyber operations partners.',
          softSkill: false,
        },
        {
          id: 'T0765',
          value: 'Provide subject matter expertise to development of exercises.',
          softSkill: false,
        },
        {
          id: 'T0766',
          value: 'Propose policy which governs interactions with external coordination groups.',
          softSkill: false,
        },
        {
          id: 'T0767',
          value: 'Perform content and/or metadata analysis to meet organization objectives.',
          softSkill: false,
        },
        {
          id: 'T0768',
          value:
            'Conduct cyber activities to degrade/remove information resident in computers and computer networks.',
          softSkill: false,
        },
        {
          id: 'T0769',
          value: 'Perform targeting automation activities.',
          softSkill: false,
        },
        {
          id: 'T0770',
          value: 'Characterize websites.',
          softSkill: false,
        },
        {
          id: 'T0771',
          value: 'Provide subject matter expertise to website characterizations.',
          softSkill: false,
        },
        {
          id: 'T0772',
          value: 'Prepare for and provide subject matter expertise to exercises.',
          softSkill: false,
        },
        {
          id: 'T0773',
          value:
            'Prioritize collection requirements for collection platforms based on platform capabilities.',
          softSkill: false,
        },
        {
          id: 'T0774',
          value: 'Process exfiltrated data for analysis and/or dissemination to customers.',
          softSkill: false,
        },
        {
          id: 'T0775',
          value: 'Produce network reconstructions.',
          softSkill: false,
        },
        {
          id: 'T0776',
          value: 'Produce target system analysis products.',
          softSkill: false,
        },
        {
          id: 'T0777',
          value: 'Profile network or system administrators and their activities.',
          softSkill: false,
        },
        {
          id: 'T0778',
          value: 'Profile targets and their activities.',
          softSkill: false,
        },
        {
          id: 'T0779',
          value:
            'Provide advice/assistance to operations and intelligence decision makers with reassignment of collection assets and resources in response to dynamic operational situations.',
          softSkill: false,
        },
        {
          id: 'T0780',
          value:
            'Provide advisory and advocacy support to promote collection planning as an integrated component of the strategic campaign plans and other adaptive plans.',
          softSkill: false,
        },
        {
          id: 'T0781',
          value: 'Provide aim point and reengagement recommendations.',
          softSkill: false,
        },
        {
          id: 'T0782',
          value: 'Provide analyses and support for effectiveness assessment.',
          softSkill: false,
        },
        {
          id: 'T0783',
          value:
            'Provide current intelligence support to critical internal/external stakeholders as appropriate.',
          softSkill: false,
        },
        {
          id: 'T0784',
          value: 'Provide cyber focused guidance and advice on intelligence support plan inputs.',
          softSkill: false,
        },
        {
          id: 'T0785',
          value:
            'Provide evaluation and feedback necessary for improving intelligence production, intelligence reporting, collection requirements, and operations.',
          softSkill: false,
        },
        {
          id: 'T0786',
          value:
            'Provide information and assessments for the purposes of informing leadership and customers; developing and refining objectives; supporting operation planning and execution; and assessing the effects of operations.',
          softSkill: false,
        },
        {
          id: 'T0787',
          value:
            'Provide input for the development and refinement of the cyber operations objectives, priorities, strategies, plans, and programs.',
          softSkill: false,
        },
        {
          id: 'T0788',
          value: 'Provide input and assist in post-action effectiveness assessments.',
          softSkill: false,
        },
        {
          id: 'T0789',
          value: 'Provide input and assist in the development of plans and guidance.',
          softSkill: false,
        },
        {
          id: 'T0790',
          value: 'Provide input for targeting effectiveness assessments for leadership acceptance.',
          softSkill: false,
        },
        {
          id: 'T0791',
          value:
            'Provide input to the administrative and logistical elements of an operational support plan.',
          softSkill: false,
        },
        {
          id: 'T0792',
          value:
            'Provide intelligence analysis and support to designated exercises, planning activities, and time sensitive operations.',
          softSkill: false,
        },
        {
          id: 'T0793',
          value:
            'Provide effectiveness support to designated exercises, and/or time sensitive operations.',
          softSkill: false,
        },
        {
          id: 'T0794',
          value: 'Provide operations and reengagement recommendations.',
          softSkill: false,
        },
        {
          id: 'T0795',
          value: 'Provide planning support between internal and external partners.',
          softSkill: false,
        },
        {
          id: 'T0796',
          value: 'Provide real-time actionable geolocation information.',
          softSkill: false,
        },
        {
          id: 'T0797',
          value: 'Provide target recommendations which meet leadership objectives.',
          softSkill: false,
        },
        {
          id: 'T0798',
          value: 'Provide targeting products and targeting support as designated.',
          softSkill: false,
        },
        {
          id: 'T0799',
          value: 'Provide time sensitive targeting support.',
          softSkill: false,
        },
        {
          id: 'T0800',
          value:
            'Provide timely notice of imminent or hostile intentions or activities which may impact organization objectives, resources, or capabilities.',
          softSkill: false,
        },
        {
          id: 'T0801',
          value:
            'Recommend refinement, adaption, termination, and execution of operational plans as appropriate.',
          softSkill: false,
        },
        {
          id: 'T0802',
          value:
            'Review appropriate information sources to determine validity and relevance of information gathered.',
          softSkill: false,
        },
        {
          id: 'T0803',
          value: 'Reconstruct networks in diagram or report format.',
          softSkill: false,
        },
        {
          id: 'T0804',
          value:
            'Record information collection and/or environment preparation activities against targets during operations designed to achieve cyber effects.',
          softSkill: false,
        },
        {
          id: 'T0805',
          value: 'Report intelligence-derived significant network events and intrusions.',
          softSkill: false,
        },
        {
          id: 'T0806',
          value:
            "Request discipline-specific processing, exploitation, and disseminate information collected using discipline's collection assets and resources in accordance with approved guidance and/or procedures.",
          softSkill: false,
        },
        {
          id: 'T0807',
          value:
            'Research communications trends in emerging technologies (in computer and telephony networks, satellite, cable, and wireless) in both open and classified sources.',
          softSkill: false,
        },
        {
          id: 'T0808',
          value:
            'Review and comprehend organizational leadership objectives and guidance for planning.',
          softSkill: false,
        },
        {
          id: 'T0809',
          value: 'Review capabilities of allocated collection assets.',
          softSkill: false,
        },
        {
          id: 'T0810',
          value: 'Review intelligence collection guidance for accuracy/applicability.',
          softSkill: false,
        },
        {
          id: 'T0811',
          value: 'Review list of prioritized collection requirements and essential information.',
          softSkill: false,
        },
        {
          id: 'T0812',
          value: 'Review and update overarching collection plan, as required.',
          softSkill: false,
        },
        {
          id: 'T0813',
          value:
            'Review, approve, prioritize, and submit operational requirements for research, development, and/or acquisition of cyber capabilities.',
          softSkill: false,
        },
        {
          id: 'T0814',
          value: 'Revise collection matrix based on availability of optimal assets and resources.',
          softSkill: false,
        },
        {
          id: 'T0815',
          value: 'Sanitize and minimize information to protect sources and methods.',
          softSkill: false,
        },
        {
          id: 'T0816',
          value: 'Scope the cyber intelligence planning effort.',
          softSkill: false,
        },
        {
          id: 'T0817',
          value:
            'Serve as a conduit of information from partner teams by identifying subject matter experts who can assist in the investigation of complex or unusual situations.',
          softSkill: false,
        },
        {
          id: 'T0818',
          value: 'Serve as a liaison with external partners.',
          softSkill: false,
        },
        {
          id: 'T0819',
          value:
            'Solicit and manage to completion feedback from requestors on quality, timeliness, and effectiveness of collection against collection requirements.',
          softSkill: false,
        },
        {
          id: 'T0820',
          value:
            'Specify changes to collection plan and/or operational environment that necessitate retasking or re-directing of collection assets and resources.',
          softSkill: false,
        },
        {
          id: 'T0821',
          value:
            'Specify discipline-specific collections and/or taskings that must be executed in the near term.',
          softSkill: false,
        },
        {
          id: 'T0822',
          value:
            'Submit information requests to collection requirement management section for processing as collection requests.',
          softSkill: false,
        },
        {
          id: 'T0823',
          value: 'Submit or respond to requests for deconfliction of cyber operations.',
          softSkill: false,
        },
        {
          id: 'T0824',
          value: 'Support identification and documentation of collateral effects.',
          softSkill: false,
        },
        {
          id: 'T0825',
          value:
            'Synchronize cyber international engagement activities and associated resource requirements as appropriate.',
          softSkill: false,
        },
        {
          id: 'T0826',
          value: 'Synchronize cyber portions of security cooperation plans.',
          softSkill: false,
        },
        {
          id: 'T0827',
          value:
            'Synchronize the integrated employment of all available organic and partner intelligence collection assets using available collaboration capabilities and techniques.',
          softSkill: false,
        },
        {
          id: 'T0828',
          value: 'Test and evaluate locally developed tools for operational use.',
          softSkill: false,
        },
        {
          id: 'T0829',
          value: 'Test internal developed tools and techniques against target tools.',
          softSkill: false,
        },
        {
          id: 'T0830',
          value:
            'Track status of information requests, including those processed as collection requests and production requirements, using established procedures.',
          softSkill: false,
        },
        {
          id: 'T0831',
          value:
            'Translate collection requests into applicable discipline-specific collection requirements.',
          softSkill: false,
        },
        {
          id: 'T0832',
          value:
            'Use feedback results (e.g., lesson learned) to identify opportunities to improve collection management efficiency and effectiveness.',
          softSkill: false,
        },
        {
          id: 'T0833',
          value: 'Validate requests for information according to established criteria.',
          softSkill: false,
        },
        {
          id: 'T0834',
          value:
            'Work closely with planners, intelligence analysts, and collection managers to ensure intelligence requirements and collection plans are accurate and up-to-date.',
          softSkill: false,
        },
        {
          id: 'T0835',
          value:
            'Work closely with planners, analysts, and collection managers to identify intelligence gaps and ensure intelligence requirements are accurate and up-to-date.',
          softSkill: false,
        },
        {
          id: 'T0836',
          value: 'Document lessons learned that convey the results of events and/or exercises.',
          softSkill: false,
        },
        {
          id: 'T0837',
          value:
            'Advise managers and operators on language and cultural issues that impact organization objectives.',
          softSkill: false,
        },
        {
          id: 'T0838',
          value: 'Analyze and process information using language and/or cultural expertise.',
          softSkill: false,
        },
        {
          id: 'T0839',
          value:
            "Assess, document, and apply a target's motivation and/or frame of reference to facilitate analysis, targeting and collection opportunities.",
          softSkill: false,
        },
        {
          id: 'T0840',
          value:
            'Collaborate across internal and/or external organizational lines to enhance collection, analysis and dissemination.',
          softSkill: false,
        },
        {
          id: 'T0841',
          value:
            'Conduct all-source target research to include the use of open source materials in the target language.',
          softSkill: false,
        },
        {
          id: 'T0842',
          value:
            'Conduct analysis of target communications to identify essential information in support of organization objectives.',
          softSkill: false,
        },
        {
          id: 'T0843',
          value:
            'Perform quality review and provide feedback on transcribed or translated materials.',
          softSkill: false,
        },
        {
          id: 'T0844',
          value:
            'Evaluate and interpret metadata to look for patterns, anomalies, or events, thereby optimizing targeting, analysis and processing.',
          softSkill: false,
        },
        {
          id: 'T0845',
          value: 'Identify cyber threat tactics and methodologies.',
          softSkill: false,
        },
        {
          id: 'T0846',
          value: 'Identify target communications within the global network.',
          softSkill: false,
        },
        {
          id: 'T0847',
          value:
            'Maintain awareness of target communication tools, techniques, and the characteristics of target communication networks (e.g., capacity, functionality, paths, critical nodes) and their potential implications for targeting, collection, and analysis.',
          softSkill: false,
        },
        {
          id: 'T0848',
          value:
            'Provide feedback to collection managers to enhance future collection and analysis.',
          softSkill: false,
        },
        {
          id: 'T0849',
          value: 'Perform foreign language and dialect identification in initial source data.',
          softSkill: false,
        },
        {
          id: 'T0850',
          value: 'Perform or support technical network analysis and mapping.',
          softSkill: false,
        },
        {
          id: 'T0851',
          value:
            'Provide requirements and feedback to optimize the development of language processing tools.',
          softSkill: false,
        },
        {
          id: 'T0852',
          value: 'Perform social network analysis and document as appropriate.',
          softSkill: false,
        },
        {
          id: 'T0853',
          value:
            'Scan, identify and prioritize target graphic (including machine-to-machine communications) and/or voice language material.',
          softSkill: false,
        },
        {
          id: 'T0854',
          value: 'Tip critical or time-sensitive information to appropriate customers.',
          softSkill: false,
        },
        {
          id: 'T0855',
          value: 'Transcribe target voice materials in the target language.',
          softSkill: false,
        },
        {
          id: 'T0856',
          value: 'Translate (e.g., verbatim, gist, and/or summaries) target graphic material.',
          softSkill: false,
        },
        {
          id: 'T0857',
          value: 'Translate (e.g., verbatim, gist, and/or summaries) target voice material.',
          softSkill: false,
        },
        {
          id: 'T0858',
          value:
            'Identify foreign language terminology within computer programs (e.g., comments, variable names).',
          softSkill: false,
        },
        {
          id: 'T0859',
          value: 'Provide near-real time language analysis support (e.g., live operations).',
          softSkill: false,
        },
        {
          id: 'T0860',
          value: 'Identify cyber/technology-related terminology in the target language.',
          softSkill: false,
        },
        {
          id: 'T0861',
          value:
            'Work with the general counsel, external affairs and businesses to ensure both existing and new services comply with privacy and data security obligations.',
          softSkill: false,
        },
        {
          id: 'T0862',
          value:
            'Work with legal counsel and management, key departments and committees to ensure the organization has and maintains appropriate privacy and confidentiality consent,  authorization forms and information notices and materials reflecting current organization and legal practices and requirements.',
          softSkill: false,
        },
        {
          id: 'T0863',
          value:
            'Coordinate with the appropriate regulating bodies to ensure that programs, policies and procedures involving civil rights, civil liberties and privacy considerations are addressed in an integrated and comprehensive manner.',
          softSkill: false,
        },
        {
          id: 'T0864',
          value: 'Liaise with regulatory and accrediting bodies.',
          softSkill: false,
        },
        {
          id: 'T0865',
          value:
            'Work with external affairs to develop relationships with regulators and other government officials responsible for privacy and data security issues.',
          softSkill: false,
        },
        {
          id: 'T0866',
          value:
            'Maintain current knowledge of applicable federal and state privacy laws and accreditation standards, and monitor advancements in information privacy technologies to ensure organizational adaptation and compliance.',
          softSkill: false,
        },
        {
          id: 'T0867',
          value:
            'Ensure all processing and/or databases are registered with the local privacy/data protection authorities where required.',
          softSkill: false,
        },
        {
          id: 'T0868',
          value:
            'Work with business teams and senior management to ensure awareness of “best practices” on privacy and data security issues.',
          softSkill: false,
        },
        {
          id: 'T0869',
          value:
            'Work with organization senior management to establish an organization-wide Privacy Oversight Committee.',
          softSkill: false,
        },
        {
          id: 'T0870',
          value: 'Serve in a leadership role for Privacy Oversight Committee activities.',
          softSkill: false,
        },
        {
          id: 'T0871',
          value: 'Collaborate on cyber privacy and security policies and procedures.',
          softSkill: false,
        },
        {
          id: 'T0872',
          value:
            'Collaborate with cybersecurity personnel on the security risk assessment process to address privacy compliance and risk mitigation',
          softSkill: false,
        },
        {
          id: 'T0873',
          value:
            'Interface with Senior Management to develop strategic plans for the collection, use and sharing of information in a manner that maximizes its value while complying with applicable privacy regulations',
          softSkill: false,
        },
        {
          id: 'T0874',
          value:
            'Provide strategic guidance to corporate officers regarding information resources and technology',
          softSkill: false,
        },
        {
          id: 'T0875',
          value:
            'Assist the Security Officer with the development and implementation of an information infrastructure',
          softSkill: false,
        },
        {
          id: 'T0876',
          value:
            'Coordinate with the Corporate Compliance Officer regarding procedures for documenting and reporting self-disclosures of any evidence of privacy violations.',
          softSkill: false,
        },
        {
          id: 'T0877',
          value:
            'Work cooperatively with applicable organization units in overseeing consumer information access rights',
          softSkill: false,
        },
        {
          id: 'T0878',
          value: 'Serve as the information privacy liaison for users of technology systems',
          softSkill: false,
        },
        {
          id: 'T0879',
          value: 'Act as a liaison to the information systems department',
          softSkill: false,
        },
        {
          id: 'T0880',
          value:
            'Develop privacy training materials and other communications to increase employee understanding of company privacy policies, data handling practices and procedures and legal obligations',
          softSkill: false,
        },
        {
          id: 'T0881',
          value:
            'Oversee, direct, deliver or ensure delivery of initial privacy training and orientation to all employees, volunteers, contractors, alliances, business associates and other appropriate third parties',
          softSkill: false,
        },
        {
          id: 'T0882',
          value: 'Conduct on-going privacy training and awareness activities',
          softSkill: false,
        },
        {
          id: 'T0883',
          value:
            'Work with external affairs to develop relationships with consumer organizations and other NGOs with an interest in privacy and data security issues—and to manage company participation in public events related to privacy and data security',
          softSkill: false,
        },
        {
          id: 'T0884',
          value:
            'Work with organization administration, legal counsel and other related parties to represent the organization’s information privacy interests with external parties, including government bodies, which undertake to adopt or amend privacy legislation, regulation or standard.',
          softSkill: false,
        },
        {
          id: 'T0885',
          value:
            'Report on a periodic basis regarding the status of the privacy program to the Board, CEO or other responsible individual or committee.',
          softSkill: false,
        },
        {
          id: 'T0886',
          value:
            'Work with External Affairs to respond to press and other inquiries regarding concern over consumer and employee data',
          softSkill: false,
        },
        {
          id: 'T0887',
          value: 'Provide leadership for the organization’s privacy program',
          softSkill: false,
        },
        {
          id: 'T0888',
          value:
            'Direct and oversee privacy specialists and coordinate privacy and data security programs with senior executives globally to ensure consistency across the organization',
          softSkill: false,
        },
        {
          id: 'T0889',
          value:
            'Ensure compliance with privacy practices and consistent application of sanctions for failure to comply with privacy policies for all individuals in the organization’s workforce, extended workforce and for all business associates in cooperation with Human Resources, the information security officer, administration and legal counsel as applicable',
          softSkill: false,
        },
        {
          id: 'T0890',
          value:
            'Develop appropriate sanctions for failure to comply with the corporate privacy policies and procedures',
          softSkill: false,
        },
        {
          id: 'T0891',
          value:
            'Resolve allegations of noncompliance with the corporate privacy policies or notice of information practices',
          softSkill: false,
        },
        {
          id: 'T0892',
          value: 'Develop and coordinate a risk management and compliance framework for privacy',
          softSkill: false,
        },
        {
          id: 'T0893',
          value:
            'Undertake a comprehensive review of the company’s data and privacy projects and ensure that they are consistent with corporate privacy and data security goals and policies.',
          softSkill: false,
        },
        {
          id: 'T0894',
          value:
            'Develop and manage enterprise-wide procedures to ensure the development of new products and services is consistent with company privacy policies and legal obligations',
          softSkill: false,
        },
        {
          id: 'T0895',
          value:
            'Establish a process for receiving, documenting, tracking, investigating and acting on all complaints concerning the organization’s privacy policies and procedures',
          softSkill: false,
        },
        {
          id: 'T0896',
          value:
            'Establish with management and operations a mechanism to track access to protected health information, within the purview of the organization and as required by law and to allow qualified individuals to review or receive a report on such activity',
          softSkill: false,
        },
        {
          id: 'T0897',
          value:
            'Provide leadership in the planning, design and evaluation of privacy and security related projects',
          softSkill: false,
        },
        {
          id: 'T0898',
          value: 'Establish an internal privacy audit program',
          softSkill: false,
        },
        {
          id: 'T0899',
          value:
            'Periodically revise the privacy program considering changes in laws, regulatory or company policy',
          softSkill: false,
        },
        {
          id: 'T0900',
          value:
            'Provide development guidance and assist in the identification, implementation and maintenance of organization information privacy policies and procedures in coordination with organization management and administration and legal counsel',
          softSkill: false,
        },
        {
          id: 'T0901',
          value:
            'Assure that the use of technologies maintains, and does not erode, privacy protections on use, collection and disclosure of personal information',
          softSkill: false,
        },
        {
          id: 'T0902',
          value: 'Monitor systems development and operations for security and privacy compliance',
          softSkill: false,
        },
        {
          id: 'T0903',
          value:
            'Conduct privacy impact assessments of proposed rules on the privacy of personal information, including the type of personal information collected and the number of people affected',
          softSkill: false,
        },
        {
          id: 'T0904',
          value:
            'Conduct periodic information privacy impact assessments and ongoing compliance monitoring activities in coordination with the organization’s other compliance and operational assessment functions',
          softSkill: false,
        },
        {
          id: 'T0905',
          value:
            'Review all system-related information security plans to ensure alignment between security and privacy practices',
          softSkill: false,
        },
        {
          id: 'T0906',
          value:
            'Work with all organization personnel involved with any aspect of release of protected information to ensure coordination with the organization’s policies, procedures and legal requirements',
          softSkill: false,
        },
        {
          id: 'T0907',
          value:
            'Account for and administer individual requests for release or disclosure of personal and/or protected information',
          softSkill: false,
        },
        {
          id: 'T0908',
          value:
            'Develop and manage procedures for vetting and auditing vendors for compliance with the privacy and data security policies and legal requirements.',
          softSkill: false,
        },
        {
          id: 'T0909',
          value:
            'Participate in the implementation and ongoing compliance monitoring of all trading partner and business associate agreements, to ensure all privacy concerns, requirements and responsibilities are addressed',
          softSkill: false,
        },
        {
          id: 'T0910',
          value: 'Act as, or work with, counsel relating to business partner contracts',
          softSkill: false,
        },
        {
          id: 'T0911',
          value:
            'Mitigate effects of a use or disclosure of personal information by employees or business partners',
          softSkill: false,
        },
        {
          id: 'T0912',
          value: 'Develop and apply corrective action procedures',
          softSkill: false,
        },
        {
          id: 'T0913',
          value:
            'Administer action on all complaints concerning the organization’s privacy policies and procedures in coordination and collaboration with other similar functions and, when necessary, legal counsel',
          softSkill: false,
        },
        {
          id: 'T0914',
          value:
            'Support the organization’s privacy compliance program, working closely with the Privacy Officer, Chief Information Security Officer, and other business leaders to ensure compliance with federal and state privacy laws and regulations',
          softSkill: false,
        },
        {
          id: 'T0915',
          value:
            'Identify and correct potential company compliance gaps and/or areas of risk to ensure full compliance with privacy regulations',
          softSkill: false,
        },
        {
          id: 'T0916',
          value:
            'Manage privacy incidents and breaches in conjunction with the Privacy Officer, Chief Information Security Officer, legal counsel and the business units',
          softSkill: false,
        },
        {
          id: 'T0917',
          value:
            'Coordinate with the Chief Information Security Officer to ensure alignment between security and privacy practices',
          softSkill: false,
        },
        {
          id: 'T0918',
          value:
            'Establish, implement and maintains organization-wide policies and procedures to comply with privacy regulations',
          softSkill: false,
        },
        {
          id: 'T0919',
          value:
            'Ensure that the company maintains appropriate privacy and confidentiality notices, consent and authorization forms, and materials',
          softSkill: false,
        },
        {
          id: 'T0920',
          value:
            'Develop and maintain appropriate communications and training to promote and educate all workforce members and members of the Board regarding privacy compliance issues and requirements, and the consequences of noncompliance',
          softSkill: false,
        },
        {
          id: 'T0921',
          value:
            'Determine business partner requirements related to the organization’s privacy program.',
          softSkill: false,
        },
        {
          id: 'T0922',
          value:
            'Establish and administer a process for receiving, documenting, tracking, investigating and taking corrective action as appropriate on complaints concerning the company’s privacy policies and procedures.',
          softSkill: false,
        },
        {
          id: 'T0923',
          value:
            'Cooperate with the relevant regulatory agencies and other legal entities, and organization officers, in any compliance reviews or investigations.',
          softSkill: false,
        },
        {
          id: 'T0924',
          value: 'Perform ongoing privacy compliance monitoring activities.',
          softSkill: false,
        },
        {
          id: 'T0925',
          value:
            'Monitor advancements in information privacy technologies to ensure organization adoption and compliance.',
          softSkill: false,
        },
        {
          id: 'T0926',
          value:
            'Develop or assist with the development of privacy training materials and other communications to increase employee understanding of company privacy policies, data handling practices and procedures and legal obligations.',
          softSkill: false,
        },
        {
          id: 'T0927',
          value: 'Appoint and guide a team of IT security experts.',
          softSkill: false,
        },
        {
          id: 'T0928',
          value:
            'Collaborate with key stakeholders to establish a cybersecurity risk management program.',
          softSkill: false,
        },
        {
          id: 'T0929',
          value:
            'Identify and assign individuals to specific roles associated with the execution of the Risk Management Framework.',
          softSkill: false,
        },
        {
          id: 'T0930',
          value:
            'Establish a risk management strategy for the organization that includes a determination of risk tolerance.',
          softSkill: false,
        },
        {
          id: 'T0931',
          value:
            'Identify the missions, business functions, and mission/business processes the system will support.',
          softSkill: false,
        },
        {
          id: 'T0932',
          value:
            'Identify stakeholders who have a security interest in the development, implementation, operation, or sustainment of a system.',
          softSkill: false,
        },
        {
          id: 'T0933',
          value:
            'Identify stakeholders who have a security interest in the development, implementation, operation, or sustainment of a system.',
          softSkill: false,
        },
        {
          id: 'T0934',
          value: 'Identify stakeholder assets that require protection.',
          softSkill: false,
        },
        {
          id: 'T0935',
          value:
            'Conduct an initial risk assessment of stakeholder assets and update the risk assessment on an ongoing basis.',
          softSkill: false,
        },
        {
          id: 'T0936',
          value: 'Define the stakeholder protection needs and stakeholder security requirements.',
          softSkill: false,
        },
        {
          id: 'T0937',
          value: 'Determine the placement of a system within the enterprise architecture.',
          softSkill: false,
        },
        {
          id: 'T0938',
          value:
            'Identify organization-wide common controls that are available for inheritance by organizational systems.',
          softSkill: false,
        },
        {
          id: 'T0939',
          value:
            'Conduct a second-level security categorization for organizational systems with the same impact level.',
          softSkill: false,
        },
        {
          id: 'T0940',
          value: 'Determine the boundary of a system.',
          softSkill: false,
        },
        {
          id: 'T0941',
          value:
            'Identify the security requirements allocated to a system and to the organization.',
          softSkill: false,
        },
        {
          id: 'T0942',
          value:
            'Identify the types of information to be processed, stored, or transmitted by a system.',
          softSkill: false,
        },
        {
          id: 'T0943',
          value:
            'Categorize the system and document the security categorization results as part of system requirements.',
          softSkill: false,
        },
        {
          id: 'T0944',
          value: 'Describe the characteristics of a system.',
          softSkill: false,
        },
        {
          id: 'T0945',
          value: 'Register the system with appropriate organizational program/management offices.',
          softSkill: false,
        },
        {
          id: 'T0946',
          value:
            'Select the security controls for a system and document the functional description of the planned control implementations in a security plan.',
          softSkill: false,
        },
        {
          id: 'T0947',
          value:
            'Develop a strategy for monitoring security control effectiveness; coordinate the systemlevel strategy with the organization and mission/business process-level monitoring strategy.',
          softSkill: false,
        },
        {
          id: 'T0948',
          value: 'Review and approve security plans.',
          softSkill: false,
        },
        {
          id: 'T0949',
          value:
            'Implement the security controls specified in a security plan or other system documentation.',
          softSkill: false,
        },
        {
          id: 'T0950',
          value:
            'Document changes to planned security control implementation and establish the configuration baseline for a system.',
          softSkill: false,
        },
        {
          id: 'T0951',
          value:
            'Develop, review, and approve a plan to assess the security controls in a system and the organization.',
          softSkill: false,
        },
        {
          id: 'T0952',
          value:
            'Assess the security controls in accordance with the assessment procedures defined in a security assessment plan.',
          softSkill: false,
        },
        {
          id: 'T0953',
          value:
            'Prepare a security assessment report documenting the issues, findings, and recommendations from the security control assessment.',
          softSkill: false,
        },
        {
          id: 'T0954',
          value:
            'Conduct initial remediation actions on security controls based on the findings and recommendations of a security assessment report; reassess remediated controls.',
          softSkill: false,
        },
        {
          id: 'T0955',
          value:
            'Prepare a plan of action and milestones based on the findings and recommendations of a security assessment report excluding any remediation actions taken.',
          softSkill: false,
        },
        {
          id: 'T0956',
          value:
            'Assemble an authorization package and submit the package to an authorizing official for adjudication.',
          softSkill: false,
        },
        {
          id: 'T0957',
          value:
            'Determine the risk from the operation or use of a system or the provision or use of common controls.',
          softSkill: false,
        },
        {
          id: 'T0958',
          value:
            'Identify and implement a preferred course of action in response to the risk determined.',
          softSkill: false,
        },
        {
          id: 'T0959',
          value:
            'Determine if the risk from the operation or use of the system or the provision or use of common controls, is acceptable.',
          softSkill: false,
        },
        {
          id: 'T0960',
          value: 'Monitor changes to a system and its environment of operation.',
          softSkill: false,
        },
        {
          id: 'T0961',
          value:
            'Assess the security controls employed within and inherited by the system in accordance with an organization-defined monitoring strategy.',
          softSkill: false,
        },
        {
          id: 'T0962',
          value:
            'Respond to risk based on the results of ongoing monitoring activities, assessment of risk, and outstanding items in a plan of action and milestones.',
          softSkill: false,
        },
        {
          id: 'T0963',
          value:
            'Update a security plan, security assessment report, and plan of action and milestones based on the results of a continuous monitoring process.',
          softSkill: false,
        },
        {
          id: 'T0964',
          value:
            'Report the security status of a system (including the effectiveness of security controls) to an authorizing official on an ongoing basis in accordance with the monitoring strategy.',
          softSkill: false,
        },
        {
          id: 'T0965',
          value:
            'Review the security status of a system (including the effectiveness of security controls) on an ongoing basis to determine whether the risk remains acceptable.',
          softSkill: false,
        },
        {
          id: 'T0966',
          value:
            'Implement a system disposal strategy which executes required actions when a system is removed from service.',
          softSkill: false,
        },
        {
          id: 'T0967',
          value: 'Sponsor and promote continuous monitoring within the organization.',
          softSkill: false,
        },
        {
          id: 'T0968',
          value: 'Assign staff as needed to appropriate continuous monitoring working groups.',
          softSkill: false,
        },
        {
          id: 'T0969',
          value: 'Identify reporting requirements to support continuous monitoring activities.',
          softSkill: false,
        },
        {
          id: 'T0970',
          value:
            'Establish scoring and grading metrics to measure effectiveness of continuous monitoring program.',
          softSkill: false,
        },
        {
          id: 'T0971',
          value:
            'Determine how to integrate a continuous monitoring program into the organization’s broader information security governance structures and policies.',
          softSkill: false,
        },
        {
          id: 'T0972',
          value:
            'Use continuous monitoring scoring and grading metrics to make information security investment decisions to address persistent issues.',
          softSkill: false,
        },
        {
          id: 'T0973',
          value:
            'Ensure that the continuous monitoring staff have the training and resources (e.g., staff and budget) needed to perform assigned duties.',
          softSkill: false,
        },
        {
          id: 'T0974',
          value:
            'Work with organizational risk analysts to ensure that continuous monitoring reporting covers appropriate levels of the organization.',
          softSkill: false,
        },
        {
          id: 'T0975',
          value:
            'Work with the organizational risk analysts to ensure risk metrics are defining realistically to support continuous monitoring.',
          softSkill: false,
        },
        {
          id: 'T0976',
          value:
            'Work with organizational officials to ensure continuous monitoring tool data provides situation awareness of risk levels.',
          softSkill: false,
        },
        {
          id: 'T0977',
          value:
            'Establish triggers for unacceptable risk thresholds for continuous monitoring data.',
          softSkill: false,
        },
        {
          id: 'T0978',
          value:
            'Work with organizational officials to establish system level reporting categories that can be used by the organization’s continuous monitoring program.',
          softSkill: false,
        },
        {
          id: 'T0980',
          value:
            'Designate a qualified person to be responsible for the management and implementation of the continuous monitoring program.',
          softSkill: false,
        },
        {
          id: 'T0981',
          value:
            'Identify the continuous monitoring stakeholders and establish a process to keep them informed about the program.',
          softSkill: false,
        },
        {
          id: 'T0982',
          value:
            'Identify security oriented organization reporting requirements that are fulfilled by the continuous monitoring program.',
          softSkill: false,
        },
        {
          id: 'T0983',
          value:
            'Use the continuous monitoring data to make information security investment decisions to address persistent issues.',
          softSkill: false,
        },
        {
          id: 'T0984',
          value:
            'Define triggers within the continuous monitoring program that can be used to define unacceptable risk and result in action being taken to resolve.',
          softSkill: false,
        },
        {
          id: 'T0985',
          value:
            'Establish scoring and grading metrics to measure effectiveness of continuous monitoring program.',
          softSkill: false,
        },
        {
          id: 'T0986',
          value:
            'Work with security managers to establish appropriate continuous monitoring reporting requirements at the system level.',
          softSkill: false,
        },
        {
          id: 'T0987',
          value:
            'Use the continuous monitoring tools and technologies to assess risk on an ongoing basis.',
          softSkill: false,
        },
        {
          id: 'T0988',
          value:
            'Establish appropriate reporting requirements in adherence to the criteria identified in the continuous monitoring program for use in automated control assessment.',
          softSkill: false,
        },
        {
          id: 'T0989',
          value:
            'Use non-automated assessment methods where the data from the continuous monitoring tools and technologies is not yet of adequate sufficiency or quality.',
          softSkill: false,
        },
        {
          id: 'T0990',
          value:
            'Develop processes with the external audit group on how to share information regarding the continuous monitoring program and its impact on security control assessment.',
          softSkill: false,
        },
        {
          id: 'T0991',
          value:
            'Identify reporting requirements for use in automated control assessment to support continuous monitoring.',
          softSkill: false,
        },
        {
          id: 'T0992',
          value:
            'Determine how the continuous monitoring results will be used in ongoing authorization.',
          softSkill: false,
        },
        {
          id: 'T0993',
          value:
            'Establish continuous monitoring tools and technologies access control process and procedures.',
          softSkill: false,
        },
        {
          id: 'T0994',
          value:
            'Ensure that continuous monitoring tools and technologies access control is managed adequately.',
          softSkill: false,
        },
        {
          id: 'T0995',
          value:
            'Establish a process to provide technical help to continuous monitoring mitigators.',
          softSkill: false,
        },
        {
          id: 'T0996',
          value: 'Coordinate continuous monitoring reporting requirements across various users.',
          softSkill: false,
        },
        {
          id: 'T0997',
          value:
            'Establish responsibilities for supporting implementation of each continuous monitoring tool or technology.',
          softSkill: false,
        },
        {
          id: 'T0998',
          value:
            'Establish liaison with scoring and metrics working group to support continuous monitoring.',
          softSkill: false,
        },
        {
          id: 'T0999',
          value:
            'Establish and operate a process to manage introduction of new risk to support continuous monitoring.',
          softSkill: false,
        },
        {
          id: 'T1000',
          value:
            'Establish continuous monitoring configuration settings issues and coordination sub-group.',
          softSkill: false,
        },
        {
          id: 'T1001',
          value:
            'Establish continuous monitoring tools and technologies performance measurement/management requirements.',
          softSkill: false,
        },
        {
          id: 'T1002',
          value:
            'Using scores and grades to motivate and assess performance while addressing concerns to support continuous monitoring',
          softSkill: false,
        },
        {
          id: 'T1003',
          value:
            'Work with security managers (i.e., system owners, information system security managers, information system security officers, etc.) to establish appropriate reporting requirements for continuous monitoring at the system level.',
          softSkill: false,
        },
        {
          id: 'T1004',
          value: 'Use continuous monitoring tools to assess risk on an ongoing basis.',
          softSkill: false,
        },
        {
          id: 'T1005',
          value:
            'Use the continuous monitoring data to make information security investment decisions to address persistent issues.',
          softSkill: false,
        },
        {
          id: 'T1006',
          value:
            'Respond to issues flagged during continuous monitoring, escalate and coordinate a response.',
          softSkill: false,
        },
        {
          id: 'T1007',
          value:
            'Review findings from the continuous monitoring program and mitigate risks on a timely basis.',
          softSkill: false,
        },
      ],
    },
    {
      name: 'knowledge',
      items: [
        {
          id: 'K0001',
          value:
            'Knowledge of computer networking concepts and protocols, and network security methodologies.',
          softSkill: false,
        },
        {
          id: 'K0002',
          value:
            'Knowledge of risk management processes (e.g., methods for assessing and mitigating risk).',
          softSkill: true,
        },
        {
          id: 'K0003',
          value:
            'Knowledge of laws, regulations, policies, and ethics as they relate to cybersecurity and privacy.',
          softSkill: true,
        },
        {
          id: 'K0004',
          value: 'Knowledge of cybersecurity and privacy principles.',
          softSkill: false,
        },
        {
          id: 'K0005',
          value: 'Knowledge of cyber threats and vulnerabilities.',
          softSkill: false,
        },
        {
          id: 'K0006',
          value: 'Knowledge of specific operational impacts of cybersecurity lapses.',
          softSkill: false,
        },
        {
          id: 'K0007',
          value: 'Knowledge of authentication, authorization, and access control methods.',
          softSkill: false,
        },
        {
          id: 'K0008',
          value:
            'Knowledge of applicable business processes and operations of customer organizations.',
          softSkill: false,
        },
        {
          id: 'K0009',
          value: 'Knowledge of application vulnerabilities.',
          softSkill: false,
        },
        {
          id: 'K0010',
          value:
            'Knowledge of communication methods, principles, and concepts that support the network infrastructure.',
          softSkill: false,
        },
        {
          id: 'K0011',
          value:
            'Knowledge of capabilities and applications of network equipment including routers, switches, bridges, servers, transmission media, and related hardware.',
          softSkill: false,
        },
        {
          id: 'K0012',
          value: 'Knowledge of capabilities and requirements analysis.',
          softSkill: false,
        },
        {
          id: 'K0013',
          value:
            'Knowledge of cyber defense and vulnerability assessment tools and their capabilities.',
          softSkill: false,
        },
        {
          id: 'K0014',
          value: 'Knowledge of complex data structures.',
          softSkill: false,
        },
        {
          id: 'K0015',
          value: 'Knowledge of computer algorithms.',
          softSkill: false,
        },
        {
          id: 'K0016',
          value: 'Knowledge of computer programming principles',
          softSkill: false,
        },
        {
          id: 'K0017',
          value: 'Knowledge of concepts and practices of processing digital forensic data.',
          softSkill: false,
        },
        {
          id: 'K0018',
          value: 'Knowledge of encryption algorithms',
          softSkill: false,
        },
        {
          id: 'K0019',
          value: 'Knowledge of cryptography and cryptographic key management concepts',
          softSkill: false,
        },
        {
          id: 'K0020',
          value: 'Knowledge of data administration and data standardization policies.',
          softSkill: false,
        },
        {
          id: 'K0021',
          value: 'Knowledge of data backup and recovery.',
          softSkill: true,
        },
        {
          id: 'K0022',
          value: 'Knowledge of data mining and data warehousing principles.',
          softSkill: false,
        },
        {
          id: 'K0023',
          value:
            'Knowledge of database management systems, query languages, table relationships, and views.',
          softSkill: false,
        },
        {
          id: 'K0024',
          value: 'Knowledge of database systems.',
          softSkill: false,
        },
        {
          id: 'K0025',
          value: 'Knowledge of digital rights management.',
          softSkill: false,
        },
        {
          id: 'K0026',
          value:
            'Knowledge of business continuity and disaster recovery continuity of operations plans.',
          softSkill: true,
        },
        {
          id: 'K0027',
          value: "Knowledge of organization's enterprise information security architecture.",
          softSkill: false,
        },
        {
          id: 'K0028',
          value: "Knowledge of organization's evaluation and validation requirements.",
          softSkill: false,
        },
        {
          id: 'K0029',
          value: "Knowledge of organization's Local and Wide Area Network connections.",
          softSkill: false,
        },
        {
          id: 'K0030',
          value:
            'Knowledge of electrical engineering as applied to computer architecture (e.g., circuit boards, processors, chips, and computer hardware).',
          softSkill: false,
        },
        {
          id: 'K0031',
          value: 'Knowledge of enterprise messaging systems and associated software.',
          softSkill: false,
        },
        {
          id: 'K0032',
          value: 'Knowledge of resiliency and redundancy.',
          softSkill: true,
        },
        {
          id: 'K0033',
          value:
            'Knowledge of host/network access control mechanisms (e.g., access control list, capabilities lists).',
          softSkill: false,
        },
        {
          id: 'K0034',
          value:
            'Knowledge of network services and protocols interactions that provide network communications.',
          softSkill: false,
        },
        {
          id: 'K0035',
          value: 'Knowledge of installation, integration, and optimization of system components.',
          softSkill: false,
        },
        {
          id: 'K0036',
          value: 'Knowledge of human-computer interaction principles.',
          softSkill: false,
        },
        {
          id: 'K0037',
          value: 'Knowledge of Security Assessment and Authorization process.',
          softSkill: false,
        },
        {
          id: 'K0038',
          value:
            'Knowledge of cybersecurity and privacy principles used to manage risks related to the use, processing, storage, and transmission of information or data.',
          softSkill: false,
        },
        {
          id: 'K0039',
          value:
            'Knowledge of cybersecurity and privacy principles and methods that apply to software development.',
          softSkill: false,
        },
        {
          id: 'K0040',
          value:
            'Knowledge of vulnerability information dissemination sources (e.g., alerts, advisories, errata, and bulletins).',
          softSkill: false,
        },
        {
          id: 'K0041',
          value:
            'Knowledge of incident categories, incident responses, and timelines for responses.',
          softSkill: false,
        },
        {
          id: 'K0042',
          value: 'Knowledge of incident response and handling methodologies.',
          softSkill: false,
        },
        {
          id: 'K0043',
          value:
            'Knowledge of industry-standard and organizationally accepted analysis principles and methods.',
          softSkill: false,
        },
        {
          id: 'K0044',
          value:
            'Knowledge of cybersecurity and privacy principles and organizational requirements (relevant to confidentiality, integrity, availability, authentication, non-repudiation).',
          softSkill: false,
        },
        {
          id: 'K0045',
          value:
            'Knowledge of information security systems engineering principles (NIST SP 800-160).',
          softSkill: false,
        },
        {
          id: 'K0046',
          value:
            'Knowledge of intrusion detection methodologies and techniques for detecting host and network-based intrusions.',
          softSkill: false,
        },
        {
          id: 'K0047',
          value: 'Knowledge of information technology (IT) architectural concepts and frameworks.',
          softSkill: false,
        },
        {
          id: 'K0048',
          value: 'Knowledge of Risk Management Framework (RMF) requirements.',
          softSkill: true,
        },
        {
          id: 'K0049',
          value:
            'Knowledge of information technology (IT) security principles and methods (e.g., firewalls, demilitarized zones, encryption).',
          softSkill: false,
        },
        {
          id: 'K0050',
          value:
            'Knowledge of local area and wide area networking principles and concepts including bandwidth management.',
          softSkill: false,
        },
        {
          id: 'K0051',
          value: 'Knowledge of low-level computer languages (e.g., assembly languages).',
          softSkill: false,
        },
        {
          id: 'K0052',
          value:
            'Knowledge of mathematics (e.g. logarithms, trigonometry, linear algebra, calculus, statistics, and operational analysis).',
          softSkill: false,
        },
        {
          id: 'K0053',
          value: 'Knowledge of measures or indicators of system performance and availability.',
          softSkill: false,
        },
        {
          id: 'K0054',
          value:
            'Knowledge of current industry methods for evaluating, implementing, and disseminating information technology (IT) security assessment, monitoring, detection, and remediation tools and procedures utilizing standards-based concepts and capabilities.',
          softSkill: false,
        },
        {
          id: 'K0055',
          value: 'Knowledge of microprocessors.',
          softSkill: false,
        },
        {
          id: 'K0056',
          value:
            'Knowledge of network access, identity, and access management (e.g., public key infrastructure, Oauth, OpenID, SAML, SPML).',
          softSkill: false,
        },
        {
          id: 'K0057',
          value: 'Knowledge of network hardware devices and functions.',
          softSkill: false,
        },
        {
          id: 'K0058',
          value: 'Knowledge of network traffic analysis methods.',
          softSkill: false,
        },
        {
          id: 'K0059',
          value:
            'Knowledge of new and emerging information technology (IT) and cybersecurity technologies.',
          softSkill: false,
        },
        {
          id: 'K0060',
          value: 'Knowledge of operating systems.',
          softSkill: false,
        },
        {
          id: 'K0061',
          value:
            'Knowledge of how traffic flows across the network (e.g., Transmission Control Protocol [TCP] and Internet Protocol [IP], Open System Interconnection Model [OSI], Information Technology Infrastructure Library, current version [ITIL]).',
          softSkill: false,
        },
        {
          id: 'K0062',
          value: 'Knowledge of packet-level analysis.',
          softSkill: false,
        },
        {
          id: 'K0063',
          value: 'Knowledge of parallel and distributed computing concepts.',
          softSkill: false,
        },
        {
          id: 'K0064',
          value: 'Knowledge of performance tuning tools and techniques.',
          softSkill: false,
        },
        {
          id: 'K0065',
          value: 'Knowledge of policy-based and risk adaptive access controls.',
          softSkill: false,
        },
        {
          id: 'K0066',
          value: 'Knowledge of Privacy Impact Assessments.',
          softSkill: true,
        },
        {
          id: 'K0067',
          value: 'Knowledge of process engineering concepts.',
          softSkill: false,
        },
        {
          id: 'K0068',
          value: 'Knowledge of programming language structures and logic.',
          softSkill: false,
        },
        {
          id: 'K0069',
          value: 'Knowledge of query languages such as SQL (structured query language).',
          softSkill: false,
        },
        {
          id: 'K0070',
          value:
            'Knowledge of system and application security threats and vulnerabilities (e.g., buffer overflow, mobile code, cross-site scripting, Procedural Language/Structured Query Language [PL/SQL] and injections, race conditions, covert channel, replay, return-oriented attacks, malicious code).',
          softSkill: false,
        },
        {
          id: 'K0071',
          value: 'Knowledge of remote access technology concepts.',
          softSkill: false,
        },
        {
          id: 'K0072',
          value: 'Knowledge of resource management principles and techniques.',
          softSkill: true,
        },
        {
          id: 'K0073',
          value:
            'Knowledge of secure configuration management techniques. (e.g., Security Technical Implementation Guides (STIGs), cybersecurity best practices on cisecurity.org).',
          softSkill: false,
        },
        {
          id: 'K0074',
          value:
            'Knowledge of key concepts in security management (e.g., Release Management, Patch Management).',
          softSkill: false,
        },
        {
          id: 'K0075',
          value: 'Knowledge of security system design tools, methods, and techniques.',
          softSkill: false,
        },
        {
          id: 'K0076',
          value:
            'Knowledge of server administration and systems engineering theories, concepts, and methods.',
          softSkill: false,
        },
        {
          id: 'K0077',
          value: 'Knowledge of server and client operating systems.',
          softSkill: false,
        },
        {
          id: 'K0078',
          value: 'Knowledge of server diagnostic tools and fault identification techniques.',
          softSkill: false,
        },
        {
          id: 'K0079',
          value: 'Knowledge of software debugging principles.',
          softSkill: false,
        },
        {
          id: 'K0080',
          value: 'Knowledge of software design tools, methods, and techniques.',
          softSkill: false,
        },
        {
          id: 'K0081',
          value: 'Knowledge of software development models (e.g., Waterfall Model, Spiral Model).',
          softSkill: false,
        },
        {
          id: 'K0082',
          value: 'Knowledge of software engineering.',
          softSkill: false,
        },
        {
          id: 'K0083',
          value:
            'Knowledge of sources, characteristics, and uses of the organization’s data assets.',
          softSkill: false,
        },
        {
          id: 'K0084',
          value: 'Knowledge of structured analysis principles and methods.',
          softSkill: true,
        },
        {
          id: 'K0086',
          value:
            'Knowledge of system design tools, methods, and techniques, including automated systems analysis and design tools.',
          softSkill: false,
        },
        {
          id: 'K0087',
          value:
            'Knowledge of system software and organizational design standards, policies, and authorized approaches (e.g., International Organization for Standardization [ISO] guidelines) relating to system design.',
          softSkill: false,
        },
        {
          id: 'K0088',
          value: 'Knowledge of systems administration concepts.',
          softSkill: false,
        },
        {
          id: 'K0089',
          value: 'Knowledge of systems diagnostic tools and fault identification techniques.',
          softSkill: false,
        },
        {
          id: 'K0090',
          value:
            'Knowledge of system life cycle management principles, including software security and usability.',
          softSkill: false,
        },
        {
          id: 'K0091',
          value: 'Knowledge of systems testing and evaluation methods.',
          softSkill: false,
        },
        {
          id: 'K0092',
          value: 'Knowledge of technology integration processes.',
          softSkill: false,
        },
        {
          id: 'K0093',
          value:
            'Knowledge of telecommunications concepts (e.g., Communications channel, Systems Link Budgeting, Spectral efficiency, Multiplexing).',
          softSkill: false,
        },
        {
          id: 'K0094',
          value:
            'Knowledge of the capabilities and functionality associated with content creation technologies (e.g., wikis, social networking, content management systems, blogs).',
          softSkill: false,
        },
        {
          id: 'K0095',
          value:
            'Knowledge of the capabilities and functionality associated with various technologies for organizing and managing information (e.g., databases, bookmarking engines).',
          softSkill: false,
        },
        {
          id: 'K0096',
          value:
            'Knowledge of the capabilities and functionality of various collaborative technologies (e.g., groupware, SharePoint).',
          softSkill: false,
        },
        {
          id: 'K0097',
          value: 'Knowledge of the characteristics of physical and virtual data storage media.',
          softSkill: false,
        },
        {
          id: 'K0098',
          value:
            'Knowledge of the cyber defense Service Provider reporting structure and processes within one’s own organization.',
          softSkill: false,
        },
        {
          id: 'K0100',
          value: 'Knowledge of the enterprise information technology (IT) architecture.',
          softSkill: false,
        },
        {
          id: 'K0101',
          value:
            'Knowledge of the organization’s enterprise information technology (IT) goals and objectives.',
          softSkill: false,
        },
        {
          id: 'K0102',
          value: 'Knowledge of the systems engineering process.',
          softSkill: false,
        },
        {
          id: 'K0103',
          value: 'Knowledge of the type and frequency of routine hardware maintenance.',
          softSkill: false,
        },
        {
          id: 'K0104',
          value: 'Knowledge of Virtual Private Network (VPN) security.',
          softSkill: false,
        },
        {
          id: 'K0105',
          value:
            'Knowledge of web services (e.g., service-oriented architecture, Simple Object Access Protocol, and web service description language).',
          softSkill: false,
        },
        {
          id: 'K0106',
          value:
            'Knowledge of what constitutes a network attack and a network attack’s relationship to both threats and vulnerabilities.',
          softSkill: false,
        },
        {
          id: 'K0107',
          value:
            'Knowledge of Insider Threat investigations, reporting, investigative tools and laws/regulations.',
          softSkill: false,
        },
        {
          id: 'K0108',
          value:
            'Knowledge of concepts, terminology, and operations of a wide range of communications media (computer and telephone networks, satellite, fiber, wireless).',
          softSkill: false,
        },
        {
          id: 'K0109',
          value:
            'Knowledge of physical computer components and architectures, including the functions of various components and peripherals (e.g., CPUs, Network Interface Cards, data storage).',
          softSkill: false,
        },
        {
          id: 'K0110',
          value: 'Knowledge of adversarial tactics, techniques, and procedures.',
          softSkill: false,
        },
        {
          id: 'K0111',
          value: 'Knowledge of network tools (e.g., ping, traceroute, nslookup)',
          softSkill: false,
        },
        {
          id: 'K0112',
          value: 'Knowledge of defense-in-depth principles and network security architecture.',
          softSkill: false,
        },
        {
          id: 'K0113',
          value:
            'Knowledge of different types of network communication (e.g., LAN, WAN, MAN, WLAN, WWAN).',
          softSkill: false,
        },
        {
          id: 'K0114',
          value:
            'Knowledge of electronic devices (e.g., computer systems/components, access control devices, digital cameras, digital scanners, electronic organizers, hard drives, memory cards, modems, network components, networked appliances, networked home control devices, printers, removable storage devices, telephones, copiers, facsimile machines, etc.).',
          softSkill: false,
        },
        {
          id: 'K0115',
          value: 'Knowledge that technology that can be exploited.',
          softSkill: false,
        },
        {
          id: 'K0116',
          value: 'Knowledge of file extensions (e.g., .dll, .bat, .zip, .pcap, .gzip).',
          softSkill: false,
        },
        {
          id: 'K0117',
          value:
            'Knowledge of file system implementations (e.g., New Technology File System [NTFS], File Allocation Table [FAT], File Extension [EXT]).',
          softSkill: false,
        },
        {
          id: 'K0118',
          value: 'Knowledge of processes for seizing and preserving digital evidence.',
          softSkill: false,
        },
        {
          id: 'K0119',
          value: 'Knowledge of hacking methodologies.',
          softSkill: false,
        },
        {
          id: 'K0120',
          value:
            'Knowledge of how information needs and collection requirements are translated, tracked, and prioritized across the extended enterprise.',
          softSkill: false,
        },
        {
          id: 'K0121',
          value:
            'Knowledge of information security program management and project management principles and techniques.',
          softSkill: true,
        },
        {
          id: 'K0122',
          value:
            'Knowledge of investigative implications of hardware, Operating Systems, and network technologies.',
          softSkill: false,
        },
        {
          id: 'K0123',
          value: 'Knowledge of legal governance related to admissibility (e.g. Rules of Evidence).',
          softSkill: true,
        },
        {
          id: 'K0124',
          value:
            'Knowledge of multiple cognitive domains and tools and methods applicable for learning in each domain.',
          softSkill: true,
        },
        {
          id: 'K0125',
          value:
            'Knowledge of processes for collecting, packaging, transporting, and storing electronic evidence while maintaining chain of custody.',
          softSkill: true,
        },
        {
          id: 'K0126',
          value: 'Knowledge of Supply Chain Risk Management Practices (NIST SP 800-161)',
          softSkill: true,
        },
        {
          id: 'K0127',
          value:
            'Knowledge of the nature and function of the relevant information structure (e.g., National Information Infrastructure).',
          softSkill: true,
        },
        {
          id: 'K0128',
          value: 'Knowledge of types and collection of persistent data.',
          softSkill: false,
        },
        {
          id: 'K0129',
          value: 'Knowledge of command-line tools (e.g., mkdir, mv, ls, passwd, grep).',
          softSkill: false,
        },
        {
          id: 'K0130',
          value:
            'Knowledge of virtualization technologies and virtual machine development and maintenance.',
          softSkill: false,
        },
        {
          id: 'K0131',
          value:
            'Knowledge of web mail collection, searching/analyzing techniques, tools, and cookies.',
          softSkill: false,
        },
        {
          id: 'K0132',
          value:
            'Knowledge of which system files (e.g., log files, registry files, configuration files) contain relevant information and where to find those system files.',
          softSkill: false,
        },
        {
          id: 'K0133',
          value: 'Knowledge of types of digital forensics data and how to recognize them.',
          softSkill: false,
        },
        {
          id: 'K0134',
          value: 'Knowledge of deployable forensics.',
          softSkill: false,
        },
        {
          id: 'K0135',
          value: 'Knowledge of web filtering technologies.',
          softSkill: false,
        },
        {
          id: 'K0136',
          value:
            'Knowledge of the capabilities of different electronic communication systems and methods (e.g., e-mail, VOIP, IM, web forums, Direct Video Broadcasts).',
          softSkill: false,
        },
        {
          id: 'K0137',
          value:
            'Knowledge of the range of existing networks (e.g., PBX, LANs, WANs, WIFI, SCADA).',
          softSkill: false,
        },
        {
          id: 'K0138',
          value: 'Knowledge of Wi-Fi.',
          softSkill: false,
        },
        {
          id: 'K0139',
          value: 'Knowledge of interpreted and compiled computer languages.',
          softSkill: false,
        },
        {
          id: 'K0140',
          value: 'Knowledge of secure coding techniques.',
          softSkill: false,
        },
        {
          id: 'K0141',
          value: 'Withdrawn – Integrated into K0420',
          softSkill: true,
        },
        {
          id: 'K0142',
          value: 'Knowledge of collection management processes, capabilities, and limitations.',
          softSkill: false,
        },
        {
          id: 'K0143',
          value:
            'Knowledge of front-end collection systems, including traffic collection, filtering, and selection.',
          softSkill: false,
        },
        {
          id: 'K0144',
          value: 'Knowledge of social dynamics of computer attackers in a global context.',
          softSkill: false,
        },
        {
          id: 'K0145',
          value: 'Knowledge of security event correlation tools.',
          softSkill: false,
        },
        {
          id: 'K0146',
          value: "Knowledge of the organization's core business/mission processes.",
          softSkill: true,
        },
        {
          id: 'K0147',
          value: 'Knowledge of emerging security issues, risks, and vulnerabilities.',
          softSkill: false,
        },
        {
          id: 'K0148',
          value:
            'Knowledge of import/export control regulations and responsible agencies for the purposes of reducing supply chain risk.',
          softSkill: true,
        },
        {
          id: 'K0149',
          value: "Knowledge of organization's risk tolerance and/or risk management approach.",
          softSkill: true,
        },
        {
          id: 'K0150',
          value: 'Knowledge of enterprise incident response program, roles, and responsibilities.',
          softSkill: false,
        },
        {
          id: 'K0151',
          value: 'Knowledge of current and emerging threats/threat vectors.',
          softSkill: false,
        },
        {
          id: 'K0152',
          value:
            'Knowledge of software related information technology (IT) security principles and methods (e.g., modularization, layering, abstraction, data hiding, simplicity/minimization).',
          softSkill: false,
        },
        {
          id: 'K0153',
          value: 'Knowledge of software quality assurance process.',
          softSkill: false,
        },
        {
          id: 'K0154',
          value: 'Knowledge of supply chain risk management standards, processes, and practices.',
          softSkill: true,
        },
        {
          id: 'K0155',
          value: 'Knowledge of electronic evidence law.',
          softSkill: true,
        },
        {
          id: 'K0156',
          value: 'Knowledge of legal rules of evidence and court procedure.',
          softSkill: true,
        },
        {
          id: 'K0157',
          value:
            'Knowledge of cyber defense and information security policies, procedures, and regulations.',
          softSkill: false,
        },
        {
          id: 'K0158',
          value:
            'Knowledge of organizational information technology (IT) user security policies (e.g., account creation, password rules, access control).',
          softSkill: false,
        },
        {
          id: 'K0159',
          value: 'Knowledge of Voice over IP (VoIP).',
          softSkill: false,
        },
        {
          id: 'K0160',
          value: 'Knowledge of the common attack vectors on the network layer.',
          softSkill: false,
        },
        {
          id: 'K0161',
          value:
            'Knowledge of different classes of attacks (e.g., passive, active, insider, close-in, distribution attacks).',
          softSkill: false,
        },
        {
          id: 'K0162',
          value:
            'Knowledge of cyber attackers (e.g., script kiddies, insider threat, non-nation state sponsored, and nation sponsored).',
          softSkill: false,
        },
        {
          id: 'K0163',
          value: 'Knowledge of critical information technology (IT) procurement requirements.',
          softSkill: true,
        },
        {
          id: 'K0164',
          value:
            'Knowledge of functionality, quality, and security requirements and how these will apply to specific items of supply (i.e., elements and processes).',
          softSkill: true,
        },
        {
          id: 'K0165',
          value: 'Knowledge of risk/threat assessment.',
          softSkill: true,
        },
        {
          id: 'K0167',
          value:
            'Knowledge of system administration, network, and operating system hardening techniques.',
          softSkill: false,
        },
        {
          id: 'K0168',
          value:
            'Knowledge of applicable laws, statutes (e.g., in Titles 10, 18, 32, 50 in U.S. Code), Presidential Directives, executive branch guidelines, and/or administrative/criminal legal guidelines and procedures.',
          softSkill: true,
        },
        {
          id: 'K0169',
          value:
            'Knowledge of information technology (IT) supply chain security and supply chain risk management policies, requirements, and procedures.',
          softSkill: true,
        },
        {
          id: 'K0170',
          value:
            'Knowledge of critical infrastructure systems with information communication technology that were designed without system security considerations.',
          softSkill: false,
        },
        {
          id: 'K0171',
          value: 'Knowledge of hardware reverse engineering techniques.',
          softSkill: false,
        },
        {
          id: 'K0172',
          value: 'Knowledge of middleware (e.g., enterprise service bus and message queuing).',
          softSkill: false,
        },
        {
          id: 'K0174',
          value: 'Knowledge of networking protocols.',
          softSkill: false,
        },
        {
          id: 'K0175',
          value: 'Knowledge of software reverse engineering techniques.',
          softSkill: false,
        },
        {
          id: 'K0176',
          value: 'Knowledge of Extensible Markup Language (XML) schemas.',
          softSkill: false,
        },
        {
          id: 'K0177',
          value:
            'Knowledge of cyber attack stages (e.g., reconnaissance, scanning, enumeration, gaining access, escalation of privileges, maintaining access, network exploitation, covering tracks).',
          softSkill: false,
        },
        {
          id: 'K0178',
          value: 'Knowledge of secure software deployment methodologies, tools, and practices.',
          softSkill: false,
        },
        {
          id: 'K0179',
          value:
            'Knowledge of network security architecture concepts including topology, protocols, components, and principles (e.g., application of defense-in-depth).',
          softSkill: false,
        },
        {
          id: 'K0180',
          value:
            'Knowledge of network systems management principles, models, methods (e.g., end-to-end systems performance monitoring), and tools.',
          softSkill: false,
        },
        {
          id: 'K0182',
          value: 'Knowledge of data carving tools and techniques (e.g., Foremost).',
          softSkill: false,
        },
        {
          id: 'K0183',
          value: 'Knowledge of reverse engineering concepts.',
          softSkill: false,
        },
        {
          id: 'K0184',
          value: 'Knowledge of anti-forensics tactics, techniques, and procedures.',
          softSkill: false,
        },
        {
          id: 'K0185',
          value:
            'Knowledge of forensics lab design configuration and support applications (e.g., VMWare, Wireshark).',
          softSkill: false,
        },
        {
          id: 'K0186',
          value: 'Knowledge of debugging procedures and tools.',
          softSkill: false,
        },
        {
          id: 'K0187',
          value: 'Knowledge of file type abuse by adversaries for anomalous behavior.',
          softSkill: false,
        },
        {
          id: 'K0188',
          value: 'Knowledge of malware analysis tools (e.g., Oily Debug, Ida Pro).',
          softSkill: false,
        },
        {
          id: 'K0189',
          value:
            'Knowledge of malware with virtual machine detection (e.g. virtual aware malware, debugger aware malware, and unpacked malware that looks for VM-related strings in your computer’s display device).',
          softSkill: false,
        },
        {
          id: 'K0190',
          value: 'Knowledge of encryption methodologies.',
          softSkill: false,
        },
        {
          id: 'K0191',
          value: 'Signature implementation impact for viruses, malware, and attacks.',
          softSkill: false,
        },
        {
          id: 'K0192',
          value: 'Knowledge of Windows/Unix ports and services.',
          softSkill: false,
        },
        {
          id: 'K0193',
          value: 'Knowledge of advanced data remediation security features in databases.',
          softSkill: false,
        },
        {
          id: 'K0194',
          value:
            'Knowledge of Cloud-based knowledge management technologies and concepts related to security, governance, procurement, and administration.',
          softSkill: false,
        },
        {
          id: 'K0195',
          value:
            'Knowledge of data classification standards and methodologies based on sensitivity and other risk factors.',
          softSkill: false,
        },
        {
          id: 'K0196',
          value:
            'Knowledge of Import/Export Regulations related to cryptography and other security technologies.',
          softSkill: true,
        },
        {
          id: 'K0197',
          value:
            'Knowledge of database access application programming interfaces (e.g., Java Database Connectivity [JDBC]).',
          softSkill: false,
        },
        {
          id: 'K0198',
          value:
            'Knowledge of organizational process improvement concepts and process maturity models (e.g., Capability Maturity Model Integration (CMMI) for Development, CMMI for Services, and CMMI for Acquisitions).',
          softSkill: true,
        },
        {
          id: 'K0199',
          value:
            'Knowledge of security architecture concepts and enterprise architecture reference models (e.g., Zachman, Federal Enterprise Architecture [FEA]).',
          softSkill: false,
        },
        {
          id: 'K0200',
          value:
            'Knowledge of service management concepts for networks and related standards (e.g., Information Technology Infrastructure Library, current version [ITIL]).',
          softSkill: false,
        },
        {
          id: 'K0201',
          value: 'Knowledge of symmetric key rotation techniques and concepts.',
          softSkill: false,
        },
        {
          id: 'K0202',
          value:
            'Knowledge of the application firewall concepts and functions (e.g., Single point of authentication/audit/policy enforcement, message scanning for malicious content, data anonymization for PCI and PII compliance, data loss protection scanning, accelerated cryptographic operations, SSL security, REST/JSON processing).',
          softSkill: false,
        },
        {
          id: 'K0203',
          value:
            'Knowledge of security models (e.g., Bell-LaPadula model, Biba integrity model, ClarkWilson integrity model).',
          softSkill: false,
        },
        {
          id: 'K0204',
          value:
            'Knowledge of learning assessment techniques (rubrics, evaluation plans, tests, quizzes).',
          softSkill: true,
        },
        {
          id: 'K0205',
          value: 'Knowledge of basic system, network, and OS hardening techniques.',
          softSkill: false,
        },
        {
          id: 'K0206',
          value: 'Knowledge of ethical hacking principles and techniques.',
          softSkill: false,
        },
        {
          id: 'K0207',
          value: 'Knowledge of circuit analysis.',
          softSkill: false,
        },
        {
          id: 'K0208',
          value: 'Knowledge of computer based training and e-learning services.',
          softSkill: true,
        },
        {
          id: 'K0209',
          value: 'Knowledge of covert communication techniques.',
          softSkill: false,
        },
        {
          id: 'K0210',
          value: 'Knowledge of data backup and restoration concepts.',
          softSkill: true,
        },
        {
          id: 'K0211',
          value: 'Knowledge of confidentiality, integrity, and availability requirements.',
          softSkill: false,
        },
        {
          id: 'K0212',
          value: 'Knowledge of cybersecurity-enabled software products.',
          softSkill: false,
        },
        {
          id: 'K0213',
          value:
            'Knowledge of instructional design and evaluation models (e.g., ADDIE, Smith/Ragan model, Gagne’s Events of Instruction, Kirkpatrick’s model of evaluation).',
          softSkill: true,
        },
        {
          id: 'K0214',
          value: 'Knowledge of the Risk Management Framework Assessment Methodology.',
          softSkill: true,
        },
        {
          id: 'K0215',
          value: 'Knowledge of organizational training policies.',
          softSkill: true,
        },
        {
          id: 'K0216',
          value: 'Knowledge of learning levels (i.e., Bloom’s Taxonomy of learning).',
          softSkill: true,
        },
        {
          id: 'K0217',
          value: 'Knowledge of Learning Management Systems and their use in managing learning.',
          softSkill: true,
        },
        {
          id: 'K0218',
          value: 'Knowledge of learning styles (e.g., assimilator, auditory, kinesthetic).',
          softSkill: true,
        },
        {
          id: 'K0220',
          value: 'Knowledge of modes of learning (e.g., rote learning, observation).',
          softSkill: true,
        },
        {
          id: 'K0221',
          value: 'Knowledge of OSI model and underlying network protocols (e.g., TCP/IP).',
          softSkill: false,
        },
        {
          id: 'K0222',
          value:
            'Knowledge of relevant laws, legal authorities, restrictions, and regulations pertaining to cyber defense activities.',
          softSkill: true,
        },
        {
          id: 'K0223',
          value: 'Withdrawn – integrated into K0073',
          softSkill: true,
        },
        {
          id: 'K0224',
          value:
            'Knowledge of system administration concepts for operating systems such as but not limited to Unix/Linux, IOS, Android, and Windows operating systems.',
          softSkill: false,
        },
        {
          id: 'K0226',
          value: 'Knowledge of organizational training systems.',
          softSkill: true,
        },
        {
          id: 'K0227',
          value: 'Knowledge of various types of computer architectures.',
          softSkill: false,
        },
        {
          id: 'K0228',
          value: 'Knowledge of taxonomy and semantic ontology theory.',
          softSkill: false,
        },
        {
          id: 'K0229',
          value:
            'Knowledge of applications that can log errors, exceptions, and application faults and logging.',
          softSkill: false,
        },
        {
          id: 'K0230',
          value:
            'Knowledge of cloud service models and how those models can limit incident response.',
          softSkill: false,
        },
        {
          id: 'K0231',
          value: 'Knowledge of crisis management protocols, processes, and techniques.',
          softSkill: false,
        },
        {
          id: 'K0233',
          value:
            'Knowledge of the National Cybersecurity Workforce Framework, work roles, and associated tasks, knowledge, skills, and abilities.',
          softSkill: true,
        },
        {
          id: 'K0234',
          value:
            'Knowledge of full spectrum cyber capabilities (e.g., defense, attack, exploitation).',
          softSkill: false,
        },
        {
          id: 'K0235',
          value:
            'Knowledge of how to leverage research and development centers, think tanks, academic research, and industry systems.',
          softSkill: true,
        },
        {
          id: 'K0236',
          value:
            'Knowledge of how to utilize Hadoop, Java, Python, SQL, Hive, and Pig to explore data.',
          softSkill: false,
        },
        {
          id: 'K0237',
          value: 'Knowledge of industry best practices for service desk.',
          softSkill: false,
        },
        {
          id: 'K0238',
          value: 'Knowledge of machine learning theory and principles.',
          softSkill: false,
        },
        {
          id: 'K0239',
          value:
            'Knowledge of media production, communication, and dissemination techniques and methods, including alternative ways to inform via written, oral, and visual media.',
          softSkill: true,
        },
        {
          id: 'K0240',
          value: 'Knowledge of multi-level security systems and cross domain solutions.',
          softSkill: false,
        },
        {
          id: 'K0241',
          value: 'Knowledge of organizational human resource policies, processes, and procedures.',
          softSkill: true,
        },
        {
          id: 'K0242',
          value: 'Knowledge of organizational security policies.',
          softSkill: true,
        },
        {
          id: 'K0243',
          value:
            'Knowledge of organizational training and education policies, processes, and procedures.',
          softSkill: true,
        },
        {
          id: 'K0244',
          value:
            'Knowledge of physical and physiological behaviors that may indicate suspicious or abnormal activity.',
          softSkill: false,
        },
        {
          id: 'K0245',
          value:
            'Knowledge of principles and processes for conducting training and education needs assessment.',
          softSkill: true,
        },
        {
          id: 'K0246',
          value:
            'Knowledge of relevant concepts, procedures, software, equipment, and technology applications.',
          softSkill: false,
        },
        {
          id: 'K0247',
          value:
            'Knowledge of remote access processes, tools, and capabilities related to customer support.',
          softSkill: false,
        },
        {
          id: 'K0248',
          value: 'Knowledge of strategic theory and practice.',
          softSkill: true,
        },
        {
          id: 'K0249',
          value: 'Knowledge of sustainment technologies, processes and strategies.',
          softSkill: true,
        },
        {
          id: 'K0250',
          value: 'Knowledge of Test & Evaluation processes for learners.',
          softSkill: true,
        },
        {
          id: 'K0251',
          value:
            'Knowledge of the judicial process, including the presentation of facts and evidence.',
          softSkill: true,
        },
        {
          id: 'K0252',
          value:
            'Knowledge of training and education principles and methods for curriculum design, teaching and instruction for individuals and groups, and the measurement of training and education effects.',
          softSkill: true,
        },
        {
          id: 'K0253',
          value: 'Withdrawn – Integrated into K0227',
          softSkill: true,
        },
        {
          id: 'K0254',
          value: 'Knowledge of binary analysis.',
          softSkill: false,
        },
        {
          id: 'K0255',
          value:
            'Knowledge of network architecture concepts including topology, protocols, and components.',
          softSkill: false,
        },
        {
          id: 'K0257',
          value: 'Knowledge of information technology (IT) acquisition/procurement requirements.',
          softSkill: true,
        },
        {
          id: 'K0258',
          value:
            'Knowledge of test procedures, principles, and methodologies (e.g., Capabilities and Maturity Model Integration (CMMI)).',
          softSkill: true,
        },
        {
          id: 'K0259',
          value: 'Knowledge of malware analysis concepts and methodologies.',
          softSkill: false,
        },
        {
          id: 'K0260',
          value: 'Knowledge of Personally Identifiable Information (PII) data security standards.',
          softSkill: true,
        },
        {
          id: 'K0261',
          value: 'Knowledge of Payment Card Industry (PCI) data security standards.',
          softSkill: true,
        },
        {
          id: 'K0262',
          value: 'Knowledge of Personal Health Information (PHI) data security standards.',
          softSkill: true,
        },
        {
          id: 'K0263',
          value:
            'Knowledge of information technology (IT) risk management policies, requirements, and procedures.',
          softSkill: true,
        },
        {
          id: 'K0264',
          value:
            'Knowledge of program protection planning (e.g. information technology (IT) supply chain security/risk management policies, anti-tampering techniques, and requirements).',
          softSkill: true,
        },
        {
          id: 'K0265',
          value:
            'Knowledge of infrastructure supporting information technology (IT) for safety, performance, and reliability.',
          softSkill: false,
        },
        {
          id: 'K0266',
          value: 'Knowledge of how to evaluate the trustworthiness of the supplier and/or product.',
          softSkill: true,
        },
        {
          id: 'K0267',
          value:
            'Knowledge of laws, policies, procedures, or governance relevant to cybersecurity for critical infrastructures.',
          softSkill: true,
        },
        {
          id: 'K0268',
          value: 'Knowledge of forensic footprint identification.',
          softSkill: false,
        },
        {
          id: 'K0269',
          value: 'Knowledge of mobile communications architecture.',
          softSkill: false,
        },
        {
          id: 'K0270',
          value: 'Knowledge of the acquisition/procurement life cycle process.',
          softSkill: true,
        },
        {
          id: 'K0271',
          value:
            'Knowledge of operating system structures and internals (e.g., process management, directory structure, installed applications).',
          softSkill: false,
        },
        {
          id: 'K0272',
          value:
            'Knowledge of network analysis tools used to identify software communications vulnerabilities.',
          softSkill: false,
        },
        {
          id: 'K0274',
          value:
            'Knowledge of transmission records (e.g., Bluetooth, Radio Frequency Identification (RFID), Infrared Networking (IR), Wireless Fidelity (Wi-Fi). paging, cellular, satellite dishes, Voice over Internet Protocol (VoIP)), and jamming techniques that enable transmission of undesirable information, or prevent installed systems from operating correctly.',
          softSkill: false,
        },
        {
          id: 'K0275',
          value: 'Knowledge of configuration management techniques.',
          softSkill: false,
        },
        {
          id: 'K0276',
          value: 'Knowledge of security management.',
          softSkill: false,
        },
        {
          id: 'K0277',
          value:
            'Knowledge of current and emerging data encryption (e.g., Column and Tablespace Encryption, file and disk encryption) security features in databases (e.g. built-in cryptographic key management features).',
          softSkill: false,
        },
        {
          id: 'K0278',
          value:
            'Knowledge of current and emerging data remediation security features in databases.',
          softSkill: false,
        },
        {
          id: 'K0280',
          value: 'Knowledge of systems engineering theories, concepts, and methods.',
          softSkill: false,
        },
        {
          id: 'K0281',
          value: 'Knowledge of information technology (IT) service catalogues.',
          softSkill: false,
        },
        {
          id: 'K0282',
          value: 'Withdrawn – Integrated into K0200',
          softSkill: true,
        },
        {
          id: 'K0283',
          value:
            'Knowledge of use cases related to collaboration and content synchronization across platforms (e.g., Mobile, PC, Cloud).',
          softSkill: false,
        },
        {
          id: 'K0284',
          value: 'Knowledge of developing and applying user credential management system.',
          softSkill: false,
        },
        {
          id: 'K0285',
          value:
            'Knowledge of implementing enterprise key escrow systems to support data-at-rest encryption.',
          softSkill: false,
        },
        {
          id: 'K0286',
          value:
            'Knowledge of N-tiered typologies (e.g. including server and client operating systems).',
          softSkill: false,
        },
        {
          id: 'K0287',
          value:
            "Knowledge of an organization's information classification program and procedures for information compromise.",
          softSkill: false,
        },
        {
          id: 'K0288',
          value: 'Knowledge of industry standard security models.',
          softSkill: true,
        },
        {
          id: 'K0289',
          value: 'Knowledge of system/server diagnostic tools and fault identification techniques.',
          softSkill: false,
        },
        {
          id: 'K0290',
          value: 'Knowledge of systems security testing and evaluation methods.',
          softSkill: false,
        },
        {
          id: 'K0291',
          value:
            'Knowledge of the enterprise information technology (IT) architectural concepts and patterns (e.g., baseline, validated design, and target architectures.)',
          softSkill: false,
        },
        {
          id: 'K0292',
          value:
            'Knowledge of the operations and processes for incident, problem, and event management.',
          softSkill: false,
        },
        {
          id: 'K0293',
          value:
            'Knowledge of integrating the organization’s goals and objectives into the architecture.',
          softSkill: false,
        },
        {
          id: 'K0294',
          value:
            'Knowledge of IT system operation, maintenance, and security needed to keep equipment functioning properly.',
          softSkill: false,
        },
        {
          id: 'K0295',
          value: 'Knowledge of confidentiality, integrity, and availability principles.',
          softSkill: false,
        },
        {
          id: 'K0296',
          value:
            'Knowledge of capabilities, applications, and potential vulnerabilities of network equipment including hubs, routers, switches, bridges, servers, transmission media, and related hardware.',
          softSkill: false,
        },
        {
          id: 'K0297',
          value: 'Knowledge of countermeasure design for identified security risks.',
          softSkill: false,
        },
        {
          id: 'K0298',
          value: 'Knowledge of countermeasures for identified security risks.',
          softSkill: true,
        },
        {
          id: 'K0299',
          value:
            'Knowledge in determining how a security system should work (including its resilience and dependability capabilities) and how changes in conditions, operations, or the environment will affect these outcomes.',
          softSkill: false,
        },
        {
          id: 'K0300',
          value: 'Knowledge of network mapping and recreating network topologies.',
          softSkill: false,
        },
        {
          id: 'K0301',
          value:
            'Knowledge of packet-level analysis using appropriate tools (e.g., Wireshark, tcpdump).',
          softSkill: false,
        },
        {
          id: 'K0302',
          value: 'Knowledge of the basic operation of computers.',
          softSkill: false,
        },
        {
          id: 'K0303',
          value: 'Knowledge of the use of sub-netting tools.',
          softSkill: false,
        },
        {
          id: 'K0304',
          value: 'Knowledge of concepts and practices of processing digital forensic data.',
          softSkill: false,
        },
        {
          id: 'K0305',
          value: 'Knowledge of data concealment (e.g. encryption algorithms and stenography).',
          softSkill: false,
        },
        {
          id: 'K0308',
          value: 'Knowledge of cryptology.',
          softSkill: false,
        },
        {
          id: 'K0309',
          value: 'Knowledge of emerging technologies that have potential for exploitation.',
          softSkill: false,
        },
        {
          id: 'K0310',
          value: 'Knowledge of hacking methodologies.',
          softSkill: false,
        },
        {
          id: 'K0311',
          value: 'Knowledge of industry indicators useful for identifying technology trends.',
          softSkill: false,
        },
        {
          id: 'K0312',
          value:
            'Knowledge of intelligence gathering principles, policies, and procedures including legal authorities and restrictions.',
          softSkill: false,
        },
        {
          id: 'K0313',
          value:
            'Knowledge of external organizations and academic institutions with cyber focus (e.g., cyber curriculum/training and Research & Development).',
          softSkill: true,
        },
        {
          id: 'K0314',
          value: 'Knowledge of industry technologies’ potential cybersecurity vulnerabilities.',
          softSkill: false,
        },
        {
          id: 'K0315',
          value:
            'Knowledge of the principal methods, procedures, and techniques of gathering information and producing, reporting, and sharing information.',
          softSkill: false,
        },
        {
          id: 'K0316',
          value:
            'Knowledge of business or military operation plans, concept operation plans, orders, policies, and standing rules of engagement.',
          softSkill: false,
        },
        {
          id: 'K0317',
          value:
            'Knowledge of procedures used for documenting and querying reported incidents, problems, and events.',
          softSkill: false,
        },
        {
          id: 'K0318',
          value: 'Knowledge of operating system command-line tools.',
          softSkill: false,
        },
        {
          id: 'K0319',
          value: 'Knowledge of technical delivery capabilities and their limitations.',
          softSkill: false,
        },
        {
          id: 'K0320',
          value: "Knowledge of organization's evaluation and validation criteria.",
          softSkill: true,
        },
        {
          id: 'K0321',
          value:
            'Knowledge of engineering concepts as applied to computer architecture and associated computer hardware/software.',
          softSkill: false,
        },
        {
          id: 'K0322',
          value: 'Knowledge of embedded systems.',
          softSkill: false,
        },
        {
          id: 'K0323',
          value: 'Knowledge of system fault tolerance methodologies.',
          softSkill: false,
        },
        {
          id: 'K0324',
          value:
            'Knowledge of Intrusion Detection System (IDS)/Intrusion Prevention System (IPS) tools and applications.',
          softSkill: false,
        },
        {
          id: 'K0325',
          value:
            'Knowledge of Information Theory (e.g., source coding, channel coding, algorithm complexity theory, and data compression).',
          softSkill: false,
        },
        {
          id: 'K0326',
          value: 'Knowledge of demilitarized zones.',
          softSkill: false,
        },
        {
          id: 'K0330',
          value:
            'Knowledge of successful capabilities to identify the solutions to less common and more complex system problems.',
          softSkill: false,
        },
        {
          id: 'K0332',
          value:
            'Knowledge of network protocols such as TCP/IP, Dynamic Host Configuration, Domain Name System (DNS), and directory services.',
          softSkill: false,
        },
        {
          id: 'K0333',
          value:
            'Knowledge of network design processes, to include understanding of security objectives, operational objectives, and trade-offs.',
          softSkill: false,
        },
        {
          id: 'K0334',
          value: 'Knowledge of network traffic analysis (tools, methodologies, processes).',
          softSkill: false,
        },
        {
          id: 'K0335',
          value: 'Knowledge of current and emerging cyber technologies.',
          softSkill: false,
        },
        {
          id: 'K0336',
          value: 'Knowledge of access authentication methods.',
          softSkill: false,
        },
        {
          id: 'K0337',
          value: 'Withdrawn – Integrated into K0007',
          softSkill: true,
        },
        {
          id: 'K0338',
          value: 'Knowledge of data mining techniques.',
          softSkill: false,
        },
        {
          id: 'K0339',
          value: 'Knowledge of how to use network analysis tools to identify vulnerabilities.',
          softSkill: false,
        },
        {
          id: 'K0341',
          value:
            'Knowledge of foreign disclosure policies and import/export control regulations as related to cybersecurity.',
          softSkill: true,
        },
        {
          id: 'K0342',
          value: 'Knowledge of penetration testing principles, tools, and techniques.',
          softSkill: false,
        },
        {
          id: 'K0343',
          value: 'Knowledge of root cause analysis techniques.',
          softSkill: false,
        },
        {
          id: 'K0344',
          value: 'Knowledge of an organization’s threat environment.',
          softSkill: false,
        },
        {
          id: 'K0346',
          value: 'Knowledge of principles and methods for integrating system components.',
          softSkill: false,
        },
        {
          id: 'K0347',
          value: 'Knowledge and understanding of operational design.',
          softSkill: false,
        },
        {
          id: 'K0349',
          value:
            'Knowledge of website types, administration, functions, and content management system (CMS).',
          softSkill: false,
        },
        {
          id: 'K0350',
          value: 'Knowledge of accepted organization planning systems.',
          softSkill: true,
        },
        {
          id: 'K0351',
          value:
            'Knowledge of applicable statutes, laws, regulations and policies governing cyber targeting and exploitation.',
          softSkill: true,
        },
        {
          id: 'K0352',
          value: 'Knowledge of forms of intelligence support needs, topics, and focus areas.',
          softSkill: false,
        },
        {
          id: 'K0353',
          value:
            'Knowledge of possible circumstances that would result in changing collection management authorities.',
          softSkill: true,
        },
        {
          id: 'K0354',
          value: 'Knowledge of relevant reporting and dissemination procedures.',
          softSkill: false,
        },
        {
          id: 'K0355',
          value: 'Knowledge of all-source reporting and dissemination procedures.',
          softSkill: false,
        },
        {
          id: 'K0356',
          value:
            'Knowledge of analytic tools and techniques for language, voice and/or graphic material.',
          softSkill: false,
        },
        {
          id: 'K0357',
          value:
            'Knowledge of analytical constructs and their use in assessing the operational environment.',
          softSkill: false,
        },
        {
          id: 'K0358',
          value:
            'Knowledge of analytical standards and the purpose of intelligence confidence levels.',
          softSkill: false,
        },
        {
          id: 'K0359',
          value: 'Knowledge of approved intelligence dissemination processes.',
          softSkill: false,
        },
        {
          id: 'K0361',
          value: 'Knowledge of asset availability, capabilities and limitations.',
          softSkill: false,
        },
        {
          id: 'K0362',
          value: 'Knowledge of attack methods and techniques (DDoS, brute force, spoofing, etc.).',
          softSkill: false,
        },
        {
          id: 'K0363',
          value: 'Knowledge of auditing and logging procedures (including server-based logging).',
          softSkill: false,
        },
        {
          id: 'K0364',
          value:
            'Knowledge of available databases and tools necessary to assess appropriate collection tasking.',
          softSkill: false,
        },
        {
          id: 'K0367',
          value: 'Knowledge of penetration testing.',
          softSkill: true,
        },
        {
          id: 'K0368',
          value:
            'Knowledge of implants that enable cyber collection and/or preparation activities.',
          softSkill: false,
        },
        {
          id: 'K0371',
          value:
            'Knowledge of principles of the collection development processes (e.g., Dialed Number Recognition, Social Network Analysis).',
          softSkill: false,
        },
        {
          id: 'K0372',
          value:
            'Knowledge of programming concepts (e.g., levels, structures, compiled vs. interpreted languages).',
          softSkill: false,
        },
        {
          id: 'K0373',
          value:
            'Knowledge of basic software applications (e.g., data storage and backup, database applications) and the types of vulnerabilities that have been found in those applications.',
          softSkill: false,
        },
        {
          id: 'K0375',
          value: 'Knowledge of wireless applications vulnerabilities.',
          softSkill: false,
        },
        {
          id: 'K0376',
          value:
            'Knowledge of internal and external customers and partner organizations, including information needs, objectives, structure, capabilities, etc.',
          softSkill: true,
        },
        {
          id: 'K0377',
          value:
            'Knowledge of classification and control markings standards, policies and procedures.',
          softSkill: false,
        },
        {
          id: 'K0379',
          value:
            'Knowledge of client organizations, including information needs, objectives, structure, capabilities, etc.',
          softSkill: true,
        },
        {
          id: 'K0380',
          value: 'Knowledge of collaborative tools and environments.',
          softSkill: false,
        },
        {
          id: 'K0381',
          value: 'Knowledge of collateral damage and estimating impact(s).',
          softSkill: false,
        },
        {
          id: 'K0382',
          value: 'Knowledge of collection capabilities and limitations.',
          softSkill: false,
        },
        {
          id: 'K0383',
          value:
            'Knowledge of collection capabilities, accesses, performance specifications, and constraints utilized to satisfy collection plan.',
          softSkill: false,
        },
        {
          id: 'K0384',
          value:
            'Knowledge of collection management functionality (e.g., positions, functions, responsibilities, products, reporting requirements).',
          softSkill: false,
        },
        {
          id: 'K0385',
          value: 'Withdrawn – Integrated into K0142',
          softSkill: true,
        },
        {
          id: 'K0386',
          value: 'Knowledge of collection management tools.',
          softSkill: false,
        },
        {
          id: 'K0387',
          value: 'Knowledge of collection planning process and collection plan.',
          softSkill: false,
        },
        {
          id: 'K0388',
          value:
            'Knowledge of collection searching/analyzing techniques and tools for chat/buddy list, emerging technologies, VOIP, Media Over IP, VPN, VSAT/wireless, web mail and cookies.',
          softSkill: false,
        },
        {
          id: 'K0389',
          value:
            'Knowledge of collection sources including conventional and non-conventional sources.',
          softSkill: false,
        },
        {
          id: 'K0390',
          value: 'Knowledge of collection strategies.',
          softSkill: false,
        },
        {
          id: 'K0391',
          value: 'Knowledge of collection systems, capabilities, and processes.',
          softSkill: false,
        },
        {
          id: 'K0392',
          value:
            'Knowledge of common computer/network infections (virus, Trojan, etc.) and methods of infection (ports, attachments, etc.).',
          softSkill: false,
        },
        {
          id: 'K0393',
          value: 'Knowledge of common networking devices and their configurations.',
          softSkill: false,
        },
        {
          id: 'K0394',
          value: 'Knowledge of common reporting databases and tools.',
          softSkill: false,
        },
        {
          id: 'K0395',
          value:
            'Knowledge of computer networking fundamentals (i.e., basic computer components of a network, types of networks, etc.).',
          softSkill: false,
        },
        {
          id: 'K0396',
          value:
            'Knowledge of computer programming concepts, including computer languages, programming, testing, debugging, and file types.',
          softSkill: false,
        },
        {
          id: 'K0397',
          value: 'Knowledge of security concepts in operating systems (e.g., Linux, Unix.)',
          softSkill: false,
        },
        {
          id: 'K0398',
          value:
            'Knowledge of concepts related to websites (e.g., web servers/pages, hosting, DNS, registration, web languages such as HTML).',
          softSkill: false,
        },
        {
          id: 'K0399',
          value: 'Knowledge of crisis action planning and time sensitive planning procedures.',
          softSkill: false,
        },
        {
          id: 'K0400',
          value: 'Knowledge of crisis action planning for cyber operations.',
          softSkill: false,
        },
        {
          id: 'K0401',
          value: 'Knowledge of criteria for evaluating collection products.',
          softSkill: false,
        },
        {
          id: 'K0402',
          value:
            'Knowledge of criticality and vulnerability factors (e.g., value, recuperation, cushion, countermeasures) for target selection and applicability to the cyber domain.',
          softSkill: false,
        },
        {
          id: 'K0403',
          value:
            'Knowledge of cryptologic capabilities, limitations, and contributions to cyber operations.',
          softSkill: false,
        },
        {
          id: 'K0404',
          value: 'Knowledge of current collection requirements.',
          softSkill: false,
        },
        {
          id: 'K0405',
          value: 'Knowledge of current computer-based intrusion sets.',
          softSkill: false,
        },
        {
          id: 'K0406',
          value:
            'Knowledge of current software and methodologies for active defense and system hardening.',
          softSkill: false,
        },
        {
          id: 'K0407',
          value: 'Knowledge of customer information needs.',
          softSkill: false,
        },
        {
          id: 'K0408',
          value:
            'Knowledge of cyber actions (i.e. cyber defense, information gathering, environment preparation, cyber-attack) principles, capabilities, limitations, and effects.',
          softSkill: false,
        },
        {
          id: 'K0409',
          value:
            'Knowledge of cyber intelligence/information collection capabilities and repositories.',
          softSkill: false,
        },
        {
          id: 'K0410',
          value: 'Knowledge of cyber laws and their effect on Cyber planning.',
          softSkill: true,
        },
        {
          id: 'K0411',
          value:
            'Knowledge of cyber laws and legal considerations and their effect on cyber planning.',
          softSkill: true,
        },
        {
          id: 'K0412',
          value: 'Knowledge of cyber lexicon/terminology',
          softSkill: false,
        },
        {
          id: 'K0413',
          value: 'Knowledge of cyber operation objectives, policies, and legalities.',
          softSkill: false,
        },
        {
          id: 'K0414',
          value: 'Knowledge of cyber operations support or enabling processes.',
          softSkill: false,
        },
        {
          id: 'K0415',
          value: 'Knowledge of cyber operations terminology/lexicon.',
          softSkill: false,
        },
        {
          id: 'K0416',
          value: 'Knowledge of cyber operations.',
          softSkill: false,
        },
        {
          id: 'K0417',
          value:
            'Knowledge of data communications terminology (e.g., networking protocols, Ethernet, IP, encryption, optical devices, removable media).',
          softSkill: false,
        },
        {
          id: 'K0418',
          value: 'Knowledge of data flow process for terminal or environment collection.',
          softSkill: false,
        },
        {
          id: 'K0419',
          value: 'Knowledge of database administration and maintenance.',
          softSkill: false,
        },
        {
          id: 'K0420',
          value: 'Knowledge of database theory.',
          softSkill: false,
        },
        {
          id: 'K0421',
          value: 'Knowledge of databases, portals and associated dissemination vehicles.',
          softSkill: false,
        },
        {
          id: 'K0422',
          value: 'Knowledge of deconfliction processes and procedures.',
          softSkill: true,
        },
        {
          id: 'K0423',
          value:
            'Knowledge of deconfliction reporting to include external organization interaction.',
          softSkill: true,
        },
        {
          id: 'K0424',
          value: 'Knowledge of denial and deception techniques.',
          softSkill: false,
        },
        {
          id: 'K0425',
          value:
            'Knowledge of different organization objectives at all levels, including subordinate, lateral and higher.',
          softSkill: true,
        },
        {
          id: 'K0426',
          value: 'Knowledge of dynamic and deliberate targeting.',
          softSkill: false,
        },
        {
          id: 'K0427',
          value:
            'Knowledge of encryption algorithms and cyber capabilities/tools (e.g., SSL, PGP).',
          softSkill: false,
        },
        {
          id: 'K0428',
          value:
            'Knowledge of encryption algorithms and tools for wireless local area networks (WLANs).',
          softSkill: false,
        },
        {
          id: 'K0429',
          value: 'Knowledge of enterprise-wide information management.',
          softSkill: false,
        },
        {
          id: 'K0430',
          value: 'Knowledge of evasion strategies and techniques.',
          softSkill: false,
        },
        {
          id: 'K0431',
          value: 'Knowledge of evolving/emerging communications technologies.',
          softSkill: false,
        },
        {
          id: 'K0432',
          value:
            'Knowledge of existing, emerging, and long-range issues related to cyber operations strategy, policy, and organization.',
          softSkill: true,
        },
        {
          id: 'K0433',
          value: 'Knowledge of forensic implications of operating system structure and operations.',
          softSkill: false,
        },
        {
          id: 'K0435',
          value: 'Knowledge of fundamental cyber concepts, principles, limitations, and effects.',
          softSkill: false,
        },
        {
          id: 'K0436',
          value:
            'Knowledge of fundamental cyber operations concepts, terminology/lexicon (i.e., environment preparation, cyber-attack, cyber defense), principles, capabilities, limitations, and effects.',
          softSkill: false,
        },
        {
          id: 'K0437',
          value:
            'Knowledge of general Supervisory control and data acquisition (SCADA) system components.',
          softSkill: false,
        },
        {
          id: 'K0438',
          value:
            'Knowledge of mobile cellular communications architecture (e.g., LTE, CDMA, GSM/EDGE and UMTS/HSPA).',
          softSkill: false,
        },
        {
          id: 'K0439',
          value: 'Knowledge of governing authorities for targeting.',
          softSkill: true,
        },
        {
          id: 'K0440',
          value:
            'Knowledge of host-based security products and how those products affect exploitation and reduce vulnerability.',
          softSkill: false,
        },
        {
          id: 'K0442',
          value:
            'Knowledge of how converged technologies impact cyber operations (e.g., digital, telephony, wireless).',
          softSkill: false,
        },
        {
          id: 'K0443',
          value:
            'Knowledge of how hubs, switches, routers work together in the design of a network.',
          softSkill: false,
        },
        {
          id: 'K0444',
          value:
            'Knowledge of how Internet applications work (SMTP email, web-based email, chat clients, VOIP).',
          softSkill: false,
        },
        {
          id: 'K0445',
          value: 'Knowledge of how modern digital and telephony networks impact cyber operations.',
          softSkill: false,
        },
        {
          id: 'K0446',
          value: 'Knowledge of how modern wireless communications systems impact cyber operations.',
          softSkill: false,
        },
        {
          id: 'K0447',
          value:
            'Knowledge of how to collect, view, and identify essential information on targets of interest from metadata (e.g., email, http).',
          softSkill: false,
        },
        {
          id: 'K0448',
          value: 'Knowledge of how to establish priorities for resources.',
          softSkill: false,
        },
        {
          id: 'K0449',
          value: 'Knowledge of how to extract, analyze, and use metadata.',
          softSkill: false,
        },
        {
          id: 'K0450',
          value: 'Withdrawn – Integrated into K0036',
          softSkill: true,
        },
        {
          id: 'K0451',
          value: 'Knowledge of identification and reporting processes.',
          softSkill: false,
        },
        {
          id: 'K0452',
          value:
            'Knowledge of implementing Unix and Windows systems that provide radius authentication and logging, DNS, mail, web service, FTP server, DHCP, firewall, and SNMP.',
          softSkill: false,
        },
        {
          id: 'K0453',
          value: 'Knowledge of indications and warning.',
          softSkill: false,
        },
        {
          id: 'K0454',
          value: 'Knowledge of information needs.',
          softSkill: false,
        },
        {
          id: 'K0455',
          value:
            'Knowledge of information security concepts, facilitating technologies and methods.',
          softSkill: true,
        },
        {
          id: 'K0456',
          value: 'Knowledge of intelligence capabilities and limitations.',
          softSkill: false,
        },
        {
          id: 'K0457',
          value: 'Knowledge of intelligence confidence levels.',
          softSkill: false,
        },
        {
          id: 'K0458',
          value: 'Knowledge of intelligence disciplines.',
          softSkill: false,
        },
        {
          id: 'K0459',
          value:
            'Knowledge of intelligence employment requirements (i.e., logistical, communications support, maneuverability, legal restrictions, etc.).',
          softSkill: false,
        },
        {
          id: 'K0460',
          value: 'Knowledge of intelligence preparation of the environment and similar processes.',
          softSkill: false,
        },
        {
          id: 'K0461',
          value: 'Knowledge of intelligence production processes.',
          softSkill: false,
        },
        {
          id: 'K0462',
          value:
            'Knowledge of intelligence reporting principles, policies, procedures, and vehicles, including report formats, reportability criteria (requirements and priorities), dissemination practices, and legal authorities and restrictions.',
          softSkill: false,
        },
        {
          id: 'K0463',
          value: 'Knowledge of intelligence requirements tasking systems.',
          softSkill: false,
        },
        {
          id: 'K0464',
          value: 'Knowledge of intelligence support to planning, execution, and assessment.',
          softSkill: false,
        },
        {
          id: 'K0465',
          value:
            'Knowledge of internal and external partner cyber operations capabilities and tools.',
          softSkill: false,
        },
        {
          id: 'K0466',
          value:
            'Knowledge of internal and external partner intelligence processes and the development of information requirements and essential information.',
          softSkill: false,
        },
        {
          id: 'K0467',
          value:
            'Knowledge of internal and external partner organization capabilities and limitations (those with tasking, collection, processing, exploitation and dissemination responsibilities).',
          softSkill: false,
        },
        {
          id: 'K0468',
          value: 'Knowledge of internal and external partner reporting.',
          softSkill: false,
        },
        {
          id: 'K0469',
          value:
            'Knowledge of internal tactics to anticipate and/or emulate threat capabilities and actions.',
          softSkill: false,
        },
        {
          id: 'K0470',
          value: 'Knowledge of Internet and routing protocols.',
          softSkill: false,
        },
        {
          id: 'K0471',
          value:
            'Knowledge of Internet network addressing (IP addresses, classless inter-domain routing, TCP/UDP port numbering).',
          softSkill: false,
        },
        {
          id: 'K0472',
          value: 'Knowledge of intrusion detection systems and signature development.',
          softSkill: false,
        },
        {
          id: 'K0473',
          value: 'Knowledge of intrusion sets.',
          softSkill: false,
        },
        {
          id: 'K0474',
          value: 'Knowledge of key cyber threat actors and their equities.',
          softSkill: false,
        },
        {
          id: 'K0475',
          value: 'Knowledge of key factors of the operational environment and threat.',
          softSkill: false,
        },
        {
          id: 'K0476',
          value: 'Knowledge of language processing tools and techniques.',
          softSkill: false,
        },
        {
          id: 'K0477',
          value: "Knowledge of leadership's Intent and objectives.",
          softSkill: true,
        },
        {
          id: 'K0478',
          value: 'Knowledge of legal considerations in targeting.',
          softSkill: true,
        },
        {
          id: 'K0479',
          value: 'Knowledge of malware analysis and characteristics.',
          softSkill: false,
        },
        {
          id: 'K0480',
          value: 'Knowledge of malware.',
          softSkill: false,
        },
        {
          id: 'K0481',
          value:
            'Knowledge of methods and techniques used to detect various exploitation activities.',
          softSkill: false,
        },
        {
          id: 'K0482',
          value: 'Knowledge of methods for ascertaining collection asset posture and availability.',
          softSkill: false,
        },
        {
          id: 'K0483',
          value:
            'Knowledge of methods to integrate and summarize information from any potential sources.',
          softSkill: false,
        },
        {
          id: 'K0484',
          value:
            'Knowledge of midpoint collection (process, objectives, organization, targets, etc.).',
          softSkill: false,
        },
        {
          id: 'K0485',
          value: 'Knowledge of network administration.',
          softSkill: false,
        },
        {
          id: 'K0486',
          value: 'Knowledge of network construction and topology.',
          softSkill: false,
        },
        {
          id: 'K0487',
          value:
            'Knowledge of network security (e.g., encryption, firewalls, authentication, honey pots, perimeter protection).',
          softSkill: false,
        },
        {
          id: 'K0488',
          value:
            'Knowledge of network security implementations (e.g., host-based IDS, IPS, access control lists), including their function and placement in a network.',
          softSkill: false,
        },
        {
          id: 'K0489',
          value: 'Knowledge of network topology.',
          softSkill: false,
        },
        {
          id: 'K0490',
          value: 'Withdrawn – Integrated into K0058',
          softSkill: true,
        },
        {
          id: 'K0491',
          value:
            'Knowledge of networking and Internet communications fundamentals (i.e. devices, device configuration, hardware, software, applications, ports/protocols, addressing, network architecture and infrastructure, routing, operating systems, etc.).',
          softSkill: false,
        },
        {
          id: 'K0492',
          value: 'Knowledge of non-traditional collection methodologies.',
          softSkill: false,
        },
        {
          id: 'K0493',
          value:
            'Knowledge of obfuscation techniques (e.g., TOR/Onion/anonymizers, VPN/VPS, encryption).',
          softSkill: false,
        },
        {
          id: 'K0494',
          value:
            'Knowledge of objectives, situation, operational environment, and the status and disposition of internal and external partner collection capabilities available to support planning.',
          softSkill: false,
        },
        {
          id: 'K0495',
          value: 'Knowledge of ongoing and future operations.',
          softSkill: false,
        },
        {
          id: 'K0496',
          value: 'Knowledge of operational asset constraints.',
          softSkill: false,
        },
        {
          id: 'K0497',
          value: 'Knowledge of operational effectiveness assessment.',
          softSkill: false,
        },
        {
          id: 'K0498',
          value: 'Knowledge of operational planning processes.',
          softSkill: false,
        },
        {
          id: 'K0499',
          value: 'Knowledge of operations security.',
          softSkill: false,
        },
        {
          id: 'K0500',
          value:
            'Knowledge of organization and/or partner collection systems, capabilities, and processes (e.g., collection and protocol processors).',
          softSkill: false,
        },
        {
          id: 'K0501',
          value: 'Knowledge of organization cyber operations programs, strategies, and resources.',
          softSkill: false,
        },
        {
          id: 'K0502',
          value: 'Knowledge of organization decision support tools and/or methods.',
          softSkill: true,
        },
        {
          id: 'K0503',
          value:
            'Knowledge of organization formats of resource and asset readiness reporting, its operational relevance and intelligence collection impact.',
          softSkill: false,
        },
        {
          id: 'K0504',
          value:
            'Knowledge of organization issues, objectives, and operations in cyber as well as regulations and policy directives governing cyber operations.',
          softSkill: true,
        },
        {
          id: 'K0505',
          value:
            'Knowledge of organization objectives and associated demand on collection management.',
          softSkill: false,
        },
        {
          id: 'K0506',
          value:
            'Knowledge of organization objectives, leadership priorities, and decision-making risks.',
          softSkill: true,
        },
        {
          id: 'K0507',
          value: 'Knowledge of organization or partner exploitation of digital networks.',
          softSkill: false,
        },
        {
          id: 'K0508',
          value:
            'Knowledge of organization policies and planning concepts for partnering with internal and/or external organizations.',
          softSkill: true,
        },
        {
          id: 'K0509',
          value:
            'Knowledge of organizational and partner authorities, responsibilities, and contributions to achieving objectives.',
          softSkill: true,
        },
        {
          id: 'K0510',
          value:
            'Knowledge of organizational and partner policies, tools, capabilities, and procedures.',
          softSkill: true,
        },
        {
          id: 'K0511',
          value: 'Knowledge of organizational hierarchy and cyber decision-making processes.',
          softSkill: true,
        },
        {
          id: 'K0512',
          value: 'Knowledge of organizational planning concepts.',
          softSkill: true,
        },
        {
          id: 'K0513',
          value:
            'Knowledge of organizational priorities, legal authorities and requirements submission processes.',
          softSkill: true,
        },
        {
          id: 'K0514',
          value: 'Knowledge of organizational structures and associated intelligence capabilities.',
          softSkill: true,
        },
        {
          id: 'K0516',
          value:
            'Knowledge of physical and logical network devices and infrastructure to include hubs, switches, routers, firewalls, etc.',
          softSkill: false,
        },
        {
          id: 'K0517',
          value: 'Knowledge of post implementation review (PIR) approval process.',
          softSkill: true,
        },
        {
          id: 'K0518',
          value: 'Knowledge of planning activity initiation.',
          softSkill: true,
        },
        {
          id: 'K0519',
          value:
            'Knowledge of planning timelines adaptive, crisis action, and time-sensitive planning.',
          softSkill: false,
        },
        {
          id: 'K0520',
          value:
            'Knowledge of principles and practices related to target development such as target knowledge, associations, communication systems, and infrastructure.',
          softSkill: false,
        },
        {
          id: 'K0521',
          value:
            'Knowledge of priority information, how it is derived, where it is published, how to access, etc.',
          softSkill: false,
        },
        {
          id: 'K0522',
          value: 'Knowledge of production exploitation and dissemination needs and architectures.',
          softSkill: false,
        },
        {
          id: 'K0523',
          value:
            'Knowledge of products and nomenclature of major vendors (e.g., security suites - Trend Micro, Symantec, McAfee, Outpost, and Panda) and how those products affect exploitation and reduce vulnerabilities.',
          softSkill: false,
        },
        {
          id: 'K0524',
          value: 'Knowledge of relevant laws, regulations, and policies.',
          softSkill: true,
        },
        {
          id: 'K0525',
          value:
            'Knowledge of required intelligence planning products associated with cyber operational planning.',
          softSkill: false,
        },
        {
          id: 'K0526',
          value: 'Knowledge of research strategies and knowledge management.',
          softSkill: false,
        },
        {
          id: 'K0527',
          value: 'Knowledge of risk management and mitigation strategies.',
          softSkill: true,
        },
        {
          id: 'K0528',
          value: 'Knowledge of satellite-based communication systems.',
          softSkill: false,
        },
        {
          id: 'K0529',
          value: 'Knowledge of scripting',
          softSkill: false,
        },
        {
          id: 'K0530',
          value:
            'Knowledge of security hardware and software options, including the network artifacts they induce and their effects on exploitation.',
          softSkill: false,
        },
        {
          id: 'K0531',
          value: 'Knowledge of security implications of software configurations.',
          softSkill: false,
        },
        {
          id: 'K0532',
          value:
            'Knowledge of specialized target language (e.g., acronyms, jargon, technical terminology, code words).',
          softSkill: false,
        },
        {
          id: 'K0533',
          value: 'Knowledge of specific target identifiers, and their usage.',
          softSkill: false,
        },
        {
          id: 'K0534',
          value: 'Knowledge of staff management, assignment, and allocation processes.',
          softSkill: true,
        },
        {
          id: 'K0535',
          value: 'Knowledge of strategies and tools for target research.',
          softSkill: false,
        },
        {
          id: 'K0536',
          value:
            'Knowledge of structure, approach, and strategy of exploitation tools (e.g., sniffers, keyloggers) and techniques (e.g., gaining backdoor access, collecting/exfiltrating data, conducting vulnerability analysis of other systems in the network).',
          softSkill: false,
        },
        {
          id: 'K0538',
          value:
            'Knowledge of target and threat organization structures, critical capabilities, and critical vulnerabilities',
          softSkill: false,
        },
        {
          id: 'K0539',
          value:
            'Knowledge of target communication profiles and their key elements (e.g., target associations, activities, communication infrastructure).',
          softSkill: false,
        },
        {
          id: 'K0540',
          value: 'Knowledge of target communication tools and techniques.',
          softSkill: false,
        },
        {
          id: 'K0541',
          value:
            'Knowledge of target cultural references, dialects, expressions, idioms, and abbreviations.',
          softSkill: false,
        },
        {
          id: 'K0542',
          value:
            'Knowledge of target development (i.e., concepts, roles, responsibilities, products, etc.).',
          softSkill: false,
        },
        {
          id: 'K0543',
          value: 'Knowledge of target estimated repair and recuperation times.',
          softSkill: false,
        },
        {
          id: 'K0544',
          value:
            'Knowledge of target intelligence gathering and operational preparation techniques and life cycles.',
          softSkill: false,
        },
        {
          id: 'K0545',
          value: 'Knowledge of target language(s).',
          softSkill: false,
        },
        {
          id: 'K0546',
          value: 'Knowledge of target list development (i.e. Restricted, Joint, Candidate, etc.).',
          softSkill: false,
        },
        {
          id: 'K0547',
          value: 'Knowledge of target methods and procedures.',
          softSkill: false,
        },
        {
          id: 'K0548',
          value: 'Knowledge of target or threat cyber actors and procedures.',
          softSkill: false,
        },
        {
          id: 'K0549',
          value: 'Knowledge of target vetting and validation procedures.',
          softSkill: false,
        },
        {
          id: 'K0550',
          value:
            'Knowledge of target, including related current events, communication profile, actors, and history (language, culture) and/or frame of reference.',
          softSkill: false,
        },
        {
          id: 'K0551',
          value: 'Knowledge of targeting cycles.',
          softSkill: false,
        },
        {
          id: 'K0552',
          value: 'Knowledge of tasking mechanisms.',
          softSkill: false,
        },
        {
          id: 'K0553',
          value: 'Knowledge of tasking processes for organic and subordinate collection assets.',
          softSkill: false,
        },
        {
          id: 'K0554',
          value: 'Knowledge of tasking, collection, processing, exploitation and dissemination.',
          softSkill: false,
        },
        {
          id: 'K0555',
          value: 'Knowledge of TCP/IP networking protocols.',
          softSkill: false,
        },
        {
          id: 'K0556',
          value: 'Knowledge of telecommunications fundamentals.',
          softSkill: false,
        },
        {
          id: 'K0557',
          value:
            'Knowledge of terminal or environmental collection (process, objectives, organization, targets, etc.).',
          softSkill: false,
        },
        {
          id: 'K0558',
          value:
            'Knowledge of the available tools and applications associated with collection requirements and collection management.',
          softSkill: false,
        },
        {
          id: 'K0559',
          value:
            'Knowledge of the basic structure, architecture, and design of converged applications.',
          softSkill: false,
        },
        {
          id: 'K0560',
          value:
            'Knowledge of the basic structure, architecture, and design of modern communication networks.',
          softSkill: false,
        },
        {
          id: 'K0561',
          value:
            'Knowledge of the basics of network security (e.g., encryption, firewalls, authentication, honey pots, perimeter protection).',
          softSkill: false,
        },
        {
          id: 'K0562',
          value:
            'Knowledge of the capabilities and limitations of new and emerging collection capabilities, accesses and/or processes.',
          softSkill: false,
        },
        {
          id: 'K0563',
          value:
            'Knowledge of the capabilities, limitations and tasking methodologies of internal and external collections as they apply to planned cyber activities.',
          softSkill: false,
        },
        {
          id: 'K0564',
          value:
            'Knowledge of the characteristics of targeted communication networks (e.g., capacity, functionality, paths, critical nodes).',
          softSkill: false,
        },
        {
          id: 'K0565',
          value:
            'Knowledge of the common networking and routing protocols (e.g. TCP/IP), services (e.g., web, mail, DNS), and how they interact to provide network communications.',
          softSkill: false,
        },
        {
          id: 'K0566',
          value:
            "Knowledge of the critical information requirements and how they're used in planning.",
          softSkill: false,
        },
        {
          id: 'K0567',
          value: 'Knowledge of the data flow from collection origin to repositories and tools.',
          softSkill: false,
        },
        {
          id: 'K0568',
          value:
            'Knowledge of the definition of collection management and collection management authority.',
          softSkill: false,
        },
        {
          id: 'K0569',
          value:
            'Knowledge of the existent tasking, collection, processing, exploitation and dissemination architecture.',
          softSkill: false,
        },
        {
          id: 'K0570',
          value: 'Knowledge of the factors of threat that could impact collection operations.',
          softSkill: false,
        },
        {
          id: 'K0571',
          value: 'Knowledge of the feedback cycle in collection processes.',
          softSkill: false,
        },
        {
          id: 'K0572',
          value:
            'Knowledge of the functions and capabilities of internal teams that emulate threat activities to benefit the organization.',
          softSkill: true,
        },
        {
          id: 'K0573',
          value:
            'Knowledge of the fundamentals of digital forensics to extract actionable intelligence.',
          softSkill: false,
        },
        {
          id: 'K0574',
          value: 'Knowledge of the impact of language analysis on on-net operator functions.',
          softSkill: false,
        },
        {
          id: 'K0575',
          value: 'Knowledge of the impacts of internal and external partner staffing estimates.',
          softSkill: true,
        },
        {
          id: 'K0576',
          value: 'Knowledge of the information environment.',
          softSkill: false,
        },
        {
          id: 'K0577',
          value: 'Knowledge of the intelligence frameworks, processes, and related systems',
          softSkill: false,
        },
        {
          id: 'K0578',
          value:
            'Knowledge of the intelligence requirements development and request for information processes.',
          softSkill: false,
        },
        {
          id: 'K0579',
          value:
            'Knowledge of the organization, roles and responsibilities of higher, lower and adjacent subelements.',
          softSkill: true,
        },
        {
          id: 'K0580',
          value: 'Knowledge of the organization’s established format for collection plan.',
          softSkill: false,
        },
        {
          id: 'K0581',
          value: 'Knowledge of the organization’s planning, operations and targeting cycles.',
          softSkill: true,
        },
        {
          id: 'K0582',
          value: 'Knowledge of the organizational planning and staffing process.',
          softSkill: true,
        },
        {
          id: 'K0583',
          value:
            'Knowledge of the organizational plans/directives/guidance that describe objectives.',
          softSkill: true,
        },
        {
          id: 'K0584',
          value:
            'Knowledge of the organizational policies/procedures for temporary transfer of collection authority.',
          softSkill: true,
        },
        {
          id: 'K0585',
          value:
            'Knowledge of the organizational structure as it pertains to full spectrum cyber operations, including the functions, responsibilities, and interrelationships among distinct internal elements.',
          softSkill: true,
        },
        {
          id: 'K0586',
          value: 'Knowledge of the outputs of course of action and exercise analysis.',
          softSkill: false,
        },
        {
          id: 'K0587',
          value:
            'Knowledge of the POC’s, databases, tools and applications necessary to establish environment preparation and surveillance products.',
          softSkill: false,
        },
        {
          id: 'K0588',
          value:
            'Knowledge of the priority information requirements from subordinate, lateral and higher levels of the organization.',
          softSkill: false,
        },
        {
          id: 'K0589',
          value:
            'Knowledge of the process used to assess the performance and impact of operations.',
          softSkill: false,
        },
        {
          id: 'K0590',
          value:
            'Knowledge of the processes to synchronize operational assessment procedures with the critical information requirement process.',
          softSkill: false,
        },
        {
          id: 'K0591',
          value:
            'Knowledge of the production responsibilities and organic analysis and production capabilities.',
          softSkill: false,
        },
        {
          id: 'K0592',
          value: 'Knowledge of the purpose and contribution of target templates.',
          softSkill: false,
        },
        {
          id: 'K0593',
          value:
            'Knowledge of the range of cyber operations and their underlying intelligence support needs, topics, and focus areas.',
          softSkill: false,
        },
        {
          id: 'K0594',
          value:
            'Knowledge of the relationships between end states, objectives, effects, lines of operation, etc.',
          softSkill: false,
        },
        {
          id: 'K0595',
          value:
            'Knowledge of the relationships of operational objectives, intelligence requirements, and intelligence production tasks.',
          softSkill: false,
        },
        {
          id: 'K0596',
          value: 'Knowledge of the request for information process.',
          softSkill: true,
        },
        {
          id: 'K0597',
          value:
            'Knowledge of the role of network operations in supporting and facilitating other organization operations.',
          softSkill: false,
        },
        {
          id: 'K0598',
          value:
            'Knowledge of the structure and intent of organization specific plans, guidance and authorizations.',
          softSkill: true,
        },
        {
          id: 'K0599',
          value:
            'Knowledge of the structure, architecture, and design of modern digital and telephony networks.',
          softSkill: false,
        },
        {
          id: 'K0600',
          value:
            'Knowledge of the structure, architecture, and design of modern wireless communications systems.',
          softSkill: false,
        },
        {
          id: 'K0601',
          value: 'Knowledge of the systems/architecture/communications used for coordination.',
          softSkill: false,
        },
        {
          id: 'K0602',
          value: 'Knowledge of collection disciplines and capabilities.',
          softSkill: false,
        },
        {
          id: 'K0603',
          value: 'Knowledge of the ways in which targets or threats use the Internet.',
          softSkill: false,
        },
        {
          id: 'K0604',
          value: 'Knowledge of threat and/or target systems.',
          softSkill: false,
        },
        {
          id: 'K0605',
          value: 'Knowledge of tipping, cueing, mixing, and redundancy.',
          softSkill: false,
        },
        {
          id: 'K0606',
          value:
            'Knowledge of transcript development processes and techniques (e.g., verbatim, gist, summaries).',
          softSkill: true,
        },
        {
          id: 'K0607',
          value: 'Knowledge of translation processes and techniques.',
          softSkill: false,
        },
        {
          id: 'K0608',
          value:
            'Knowledge of Unix/Linux and Windows operating systems structures and internals (e.g., process management, directory structure, installed applications).',
          softSkill: false,
        },
        {
          id: 'K0609',
          value: 'Knowledge of virtual machine technologies.',
          softSkill: false,
        },
        {
          id: 'K0610',
          value: 'Knowledge of virtualization products (VMware, Virtual PC).',
          softSkill: false,
        },
        {
          id: 'K0611',
          value: 'Withdrawn – Integrated into K0131',
          softSkill: true,
        },
        {
          id: 'K0612',
          value: 'Knowledge of what constitutes a “threat” to a network.',
          softSkill: false,
        },
        {
          id: 'K0613',
          value:
            'Knowledge of who the organization’s operational planners are, how and where they can be contacted, and what are their expectations.',
          softSkill: true,
        },
        {
          id: 'K0614',
          value:
            'Knowledge of wireless technologies (e.g., cellular, satellite, GSM) to include the basic structure, architecture, and design of modern wireless communications systems.',
          softSkill: false,
        },
        {
          id: 'K0615',
          value: 'Knowledge of privacy disclosure statements based on current laws.',
          softSkill: true,
        },
        {
          id: 'K0616',
          value:
            'Knowledge of continuous monitoring, its processes, and Continuous Diagnostics and Mitigation (CDM) program activities.',
          softSkill: true,
        },
        {
          id: 'K0617',
          value: 'Knowledge of Automated security control assessments',
          softSkill: true,
        },
        {
          id: 'K0618',
          value:
            'Knowledge of hardware asset management and the value of tracking the location and configuration of networked devices and software across departments, locations, facilities and, potentially, supporting business functions.',
          softSkill: true,
        },
        {
          id: 'K0619',
          value:
            'Knowledge of software asset management and the value of tracking the location and configuration of networked devices and software across departments, locations, facilities and, potentially, supporting business functions.',
          softSkill: true,
        },
        {
          id: 'K0620',
          value: 'Knowledge of continuous monitoring technologies and tools.',
          softSkill: true,
        },
        {
          id: 'K0621',
          value: 'Knowledge of risk scoring.',
          softSkill: true,
        },
        {
          id: 'K0622',
          value:
            'Knowledge of controls related to the use, processing, storage, and transmission of data.',
          softSkill: false,
        },
        {
          id: 'K0623',
          value: 'Knowledge of risk assessment methodologies.',
          softSkill: true,
        },
        {
          id: 'K0624',
          value:
            'Knowledge of Application Security Risks (e.g. Open Web Application Security Project Top 10 list)',
          softSkill: false,
        },
        {
          id: 'K0625',
          value:
            'Knowledge that patching and software updates are impractical for some networked devices.',
          softSkill: true,
        },
        {
          id: 'K0626',
          value: 'Knowledge of secure update mechanisms.',
          softSkill: true,
        },
        {
          id: 'K0627',
          value:
            'Knowledge of the importance of ingress filtering to protect against automated threats that rely on spoofed network addresses.',
          softSkill: true,
        },
        {
          id: 'K0628',
          value:
            'Knowledge of cyber competitions as a way of developing skills by providing hands-on experience in simulated, real-world situations.',
          softSkill: true,
        },
        {
          id: 'K0629',
          value: 'Knowledge of white/black listing',
          softSkill: true,
        },
        {
          id: 'K0630',
          value:
            'Knowledge of the latest intrusion techniques, methods and documented intrusions external to the organization.',
          softSkill: true,
        },
      ],
    },
    {
      name: 'skills',
      items: [
        {
          id: 'S0001',
          value:
            'Skill in conducting vulnerability scans and recognizing vulnerabilities in security systems.',
          softSkill: false,
        },
        {
          id: 'S0002',
          value: 'Skill in allocating storage capacity in the design of data management systems.',
          softSkill: false,
        },
        {
          id: 'S0003',
          value: 'Skill of identifying, capturing, containing, and reporting malware.',
          softSkill: false,
        },
        {
          id: 'S0004',
          value: 'Skill in analyzing network traffic capacity and performance characteristics.',
          softSkill: false,
        },
        {
          id: 'S0005',
          value:
            'Skill in applying and incorporating information technologies into proposed solutions.',
          softSkill: false,
        },
        {
          id: 'S0006',
          value: 'Skill in applying confidentiality, integrity, and availability principles.',
          softSkill: false,
        },
        {
          id: 'S0007',
          value: 'Skill in applying host/network access controls (e.g., access control list).',
          softSkill: false,
        },
        {
          id: 'S0008',
          value:
            'Skill in applying organization-specific systems analysis principles and techniques.',
          softSkill: false,
        },
        {
          id: 'S0009',
          value: 'Skill in assessing the robustness of security systems and designs.',
          softSkill: false,
        },
        {
          id: 'S0010',
          value: 'Skill in conducting capabilities and requirements analysis.',
          softSkill: false,
        },
        {
          id: 'S0011',
          value: 'Skill in conducting information searches.',
          softSkill: false,
        },
        {
          id: 'S0012',
          value: 'Skill in conducting knowledge mapping (e.g., map of knowledge repositories).',
          softSkill: false,
        },
        {
          id: 'S0013',
          value:
            'Skill in conducting queries and developing algorithms to analyze data structures.',
          softSkill: false,
        },
        {
          id: 'S0014',
          value: 'Skill in conducting software debugging.',
          softSkill: false,
        },
        {
          id: 'S0015',
          value: 'Skill in conducting test events.',
          softSkill: false,
        },
        {
          id: 'S0016',
          value: 'Skill in configuring and optimizing software.',
          softSkill: false,
        },
        {
          id: 'S0017',
          value: 'Skill in creating and utilizing mathematical or statistical models.',
          softSkill: false,
        },
        {
          id: 'S0018',
          value: 'Skill in creating policies that reflect system security objectives.',
          softSkill: true,
        },
        {
          id: 'S0019',
          value:
            'Skill in creating programs that validate and process multiple inputs including command line arguments, environmental variables, and input streams.',
          softSkill: false,
        },
        {
          id: 'S0020',
          value: 'Skill in developing and deploying signatures.',
          softSkill: false,
        },
        {
          id: 'S0021',
          value:
            'Skill in designing a data analysis structure (i.e., the types of data a test must generate and how to analyze that data).',
          softSkill: false,
        },
        {
          id: 'S0022',
          value: 'Skill in designing countermeasures to identified security risks.',
          softSkill: false,
        },
        {
          id: 'S0023',
          value:
            'Skill in designing security controls based on cybersecurity principles and tenets.',
          softSkill: false,
        },
        {
          id: 'S0024',
          value: 'Skill in designing the integration of hardware and software solutions.',
          softSkill: false,
        },
        {
          id: 'S0025',
          value:
            'Skill in detecting host and network based intrusions via intrusion detection technologies (e.g., Snort).',
          softSkill: false,
        },
        {
          id: 'S0026',
          value: 'Skill in determining an appropriate level of test rigor for a given system.',
          softSkill: false,
        },
        {
          id: 'S0027',
          value:
            'Skill in determining how a security system should work (including its resilience and dependability capabilities) and how changes in conditions, operations, or the environment will affect these outcomes.',
          softSkill: false,
        },
        {
          id: 'S0028',
          value: 'Skill in developing data dictionaries.',
          softSkill: false,
        },
        {
          id: 'S0029',
          value: 'Skill in developing data models.',
          softSkill: false,
        },
        {
          id: 'S0030',
          value: 'Skill in developing operations-based testing scenarios.',
          softSkill: false,
        },
        {
          id: 'S0031',
          value: 'Skill in developing and applying security system access controls.',
          softSkill: false,
        },
        {
          id: 'S0032',
          value:
            'Skill in developing, testing, and implementing network infrastructure contingency and recovery plans.',
          softSkill: true,
        },
        {
          id: 'S0033',
          value: 'Skill in diagnosing connectivity problems.',
          softSkill: false,
        },
        {
          id: 'S0034',
          value:
            'Skill in discerning the protection needs (i.e., security controls) of information systems and networks.',
          softSkill: false,
        },
        {
          id: 'S0035',
          value: 'Skill in establishing a routing schema.',
          softSkill: false,
        },
        {
          id: 'S0036',
          value: 'Skill in evaluating the adequacy of security designs.',
          softSkill: false,
        },
        {
          id: 'S0037',
          value: 'Skill in generating queries and reports.',
          softSkill: false,
        },
        {
          id: 'S0038',
          value:
            'Skill in identifying measures or indicators of system performance and the actions needed to improve or correct performance, relative to the goals of the system.',
          softSkill: false,
        },
        {
          id: 'S0039',
          value:
            'Skill in identifying possible causes of degradation of system performance or availability and initiating actions needed to mitigate this degradation.',
          softSkill: false,
        },
        {
          id: 'S0040',
          value:
            'Skill in implementing, maintaining, and improving established network security practices.',
          softSkill: false,
        },
        {
          id: 'S0041',
          value:
            'Skill in installing, configuring, and troubleshooting LAN and WAN components such as routers, hubs, and switches.',
          softSkill: false,
        },
        {
          id: 'S0042',
          value:
            'Skill in maintaining databases. (i.e., backup, restore, delete data, transaction log files, etc.).',
          softSkill: false,
        },
        {
          id: 'S0043',
          value:
            'Skill in maintaining directory services. (e.g., Microsoft Active Directory, LDAP, etc.).',
          softSkill: false,
        },
        {
          id: 'S0044',
          value: 'Skill in mimicking threat behaviors.',
          softSkill: false,
        },
        {
          id: 'S0045',
          value: 'Skill in optimizing database performance.',
          softSkill: false,
        },
        {
          id: 'S0046',
          value:
            'Skill in performing packet-level analysis using appropriate tools (e.g., Wireshark, tcpdump).',
          softSkill: false,
        },
        {
          id: 'S0047',
          value:
            'Skill in preserving evidence integrity according to standard operating procedures or national standards.',
          softSkill: false,
        },
        {
          id: 'S0048',
          value: 'Skill in systems integration testing.',
          softSkill: false,
        },
        {
          id: 'S0049',
          value: 'Skill in the measuring and reporting of intellectual capital.',
          softSkill: false,
        },
        {
          id: 'S0050',
          value:
            'Skill in design modeling and building use cases (e.g., unified modeling language).',
          softSkill: false,
        },
        {
          id: 'S0051',
          value: 'Skill in the use of penetration testing tools and techniques.',
          softSkill: false,
        },
        {
          id: 'S0052',
          value:
            'Skill in the use of social engineering techniques. (e.g., phishing, baiting, tailgating, etc.).',
          softSkill: false,
        },
        {
          id: 'S0053',
          value: 'Skill in tuning sensors.',
          softSkill: false,
        },
        {
          id: 'S0054',
          value: 'Skill in using incident handling methodologies.',
          softSkill: false,
        },
        {
          id: 'S0055',
          value: 'Skill in using knowledge management technologies.',
          softSkill: false,
        },
        {
          id: 'S0056',
          value:
            'Skill in using network management tools to analyze network traffic patterns (e.g., simple network management protocol).',
          softSkill: false,
        },
        {
          id: 'S0057',
          value: 'Skill in using protocol analyzers.',
          softSkill: false,
        },
        {
          id: 'S0058',
          value:
            'Skill in using the appropriate tools for repairing software, hardware, and peripheral equipment of a system.',
          softSkill: false,
        },
        {
          id: 'S0059',
          value: 'Skill in using Virtual Private Network (VPN) devices and encryption.',
          softSkill: false,
        },
        {
          id: 'S0060',
          value:
            'Skill in writing code in a currently supported programming language (e.g., Java, C++).',
          softSkill: false,
        },
        {
          id: 'S0061',
          value: 'Skill in writing test plans.',
          softSkill: false,
        },
        {
          id: 'S0062',
          value: 'Skill in analyzing memory dumps to extract information.',
          softSkill: false,
        },
        {
          id: 'S0063',
          value: 'Skill in collecting data from a variety of cyber defense resources.',
          softSkill: false,
        },
        {
          id: 'S0064',
          value: 'Skill in developing and executing technical training programs and curricula.',
          softSkill: true,
        },
        {
          id: 'S0065',
          value:
            'Skill in identifying and extracting data of forensic interest in diverse media (i.e., media forensics).',
          softSkill: false,
        },
        {
          id: 'S0066',
          value: 'Skill in identifying gaps in technical capabilities.',
          softSkill: false,
        },
        {
          id: 'S0067',
          value:
            'Skill in identifying, modifying, and manipulating applicable system components within Windows, Unix, or Linux (e.g., passwords, user accounts, files).',
          softSkill: false,
        },
        {
          id: 'S0068',
          value:
            'Skill in collecting, processing, packaging, transporting, and storing electronic evidence to avoid alteration, loss, physical damage, or destruction of data.',
          softSkill: false,
        },
        {
          id: 'S0069',
          value: 'Skill in setting up a forensic workstation.',
          softSkill: false,
        },
        {
          id: 'S0070',
          value: 'Skill in talking to others to convey information effectively.',
          softSkill: true,
        },
        {
          id: 'S0071',
          value: 'Skill in using forensic tool suites (e.g., EnCase, Sleuthkit, FTK).',
          softSkill: false,
        },
        {
          id: 'S0072',
          value: 'Skill in using scientific rules and methods to solve problems.',
          softSkill: false,
        },
        {
          id: 'S0073',
          value:
            'Skill in using virtual machines. (e.g., Microsoft Hyper-V, VMWare vSphere, Citrix XenDesktop/Server, Amazon Elastic Compute Cloud, etc.).',
          softSkill: false,
        },
        {
          id: 'S0074',
          value: 'Skill in physically disassembling PCs.',
          softSkill: false,
        },
        {
          id: 'S0075',
          value:
            'Skill in conducting forensic analyses in multiple operating system environments (e.g., mobile device systems).',
          softSkill: false,
        },
        {
          id: 'S0076',
          value:
            'Skill in configuring and utilizing software-based computer protection tools (e.g., software firewalls, antivirus software, anti-spyware).',
          softSkill: false,
        },
        {
          id: 'S0077',
          value: 'Skill in securing network communications.',
          softSkill: false,
        },
        {
          id: 'S0078',
          value:
            'Skill in recognizing and categorizing types of vulnerabilities and associated attacks.',
          softSkill: false,
        },
        {
          id: 'S0079',
          value:
            'Skill in protecting a network against malware. (e.g., NIPS, anti-malware, restrict/prevent external devices, spam filters).',
          softSkill: false,
        },
        {
          id: 'S0080',
          value: 'Skill in performing damage assessments.',
          softSkill: false,
        },
        {
          id: 'S0081',
          value:
            'Skill in using network analysis tools to identify vulnerabilities. (e.g., fuzzing, nmap, etc.).',
          softSkill: false,
        },
        {
          id: 'S0082',
          value: 'Skill in evaluating test plans for applicability and completeness.',
          softSkill: false,
        },
        {
          id: 'S0083',
          value:
            'Skill in integrating black box security testing tools into quality assurance process of software releases.',
          softSkill: false,
        },
        {
          id: 'S0084',
          value:
            'Skill in configuring and utilizing network protection components (e.g., Firewalls, VPNs, network intrusion detection systems).',
          softSkill: false,
        },
        {
          id: 'S0085',
          value: 'Skill in conducting audits or reviews of technical systems.',
          softSkill: false,
        },
        {
          id: 'S0086',
          value: 'Skill in evaluating the trustworthiness of the supplier and/or product.',
          softSkill: true,
        },
        {
          id: 'S0087',
          value: 'Skill in deep analysis of captured malicious code (e.g., malware forensics).',
          softSkill: false,
        },
        {
          id: 'S0088',
          value: 'Skill in using binary analysis tools (e.g., Hexedit, command code xxd, hexdump).',
          softSkill: false,
        },
        {
          id: 'S0089',
          value:
            'Skill in one-way hash functions (e.g., Secure Hash Algorithm [SHA], Message Digest Algorithm [MD5]).',
          softSkill: false,
        },
        {
          id: 'S0090',
          value: 'Skill in analyzing anomalous code as malicious or benign.',
          softSkill: false,
        },
        {
          id: 'S0091',
          value: 'Skill in analyzing volatile data.',
          softSkill: false,
        },
        {
          id: 'S0092',
          value: 'Skill in identifying obfuscation techniques.',
          softSkill: false,
        },
        {
          id: 'S0093',
          value:
            'Skill in interpreting results of debugger to ascertain tactics, techniques, and procedures.',
          softSkill: false,
        },
        {
          id: 'S0094',
          value: 'Skill in reading Hexadecimal data.',
          softSkill: false,
        },
        {
          id: 'S0095',
          value:
            'Skill in identifying common encoding techniques (e.g., Exclusive Disjunction [XOR], American Standard Code for Information Interchange [ASCII], Unicode, Base64, Uuencode, Uniform Resource Locator [URL] encode).',
          softSkill: false,
        },
        {
          id: 'S0096',
          value: 'Skill in reading and interpreting signatures (e.g., snort).',
          softSkill: false,
        },
        {
          id: 'S0097',
          value: 'Skill in applying security controls.',
          softSkill: false,
        },
        {
          id: 'S0100',
          value:
            'Skill in utilizing or developing learning activities (e.g., scenarios, instructional games, interactive exercises).',
          softSkill: true,
        },
        {
          id: 'S0101',
          value:
            'Skill in utilizing technologies (e.g., SmartBoards, websites, computers, projectors) for instructional purposes.',
          softSkill: true,
        },
        {
          id: 'S0102',
          value: 'Skill in applying technical delivery capabilities.',
          softSkill: true,
        },
        {
          id: 'S0103',
          value:
            'Skill in assessing the predictive power and subsequent generalizability of a model.',
          softSkill: false,
        },
        {
          id: 'S0104',
          value: 'Skill in conducting Test Readiness Reviews.',
          softSkill: false,
        },
        {
          id: 'S0106',
          value:
            'Skill in data pre-processing (e.g., imputation, dimensionality reduction, normalization, transformation, extraction, filtering, smoothing).',
          softSkill: false,
        },
        {
          id: 'S0107',
          value: 'Skill in designing and documenting overall program Test & Evaluation strategies.',
          softSkill: false,
        },
        {
          id: 'S0108',
          value: 'Skill in developing workforce and position qualification standards.',
          softSkill: true,
        },
        {
          id: 'S0109',
          value: 'Skill in identifying hidden patterns or relationships.',
          softSkill: false,
        },
        {
          id: 'S0110',
          value:
            'Skill in identifying Test & Evaluation infrastructure (people, ranges, tools, instrumentation) requirements.',
          softSkill: false,
        },
        {
          id: 'S0111',
          value: 'Skill in interfacing with customers.',
          softSkill: true,
        },
        {
          id: 'S0112',
          value:
            'Skill in managing test assets, test resources, and test personnel to ensure effective completion of test events.',
          softSkill: false,
        },
        {
          id: 'S0113',
          value:
            'Skill in performing format conversions to create a standard representation of the data.',
          softSkill: false,
        },
        {
          id: 'S0114',
          value: 'Skill in performing sensitivity analysis.',
          softSkill: false,
        },
        {
          id: 'S0115',
          value: 'Skill in preparing Test & Evaluation reports.',
          softSkill: false,
        },
        {
          id: 'S0116',
          value: 'Skill in designing multi-level security/cross domain solutions.',
          softSkill: false,
        },
        {
          id: 'S0117',
          value: 'Skill in providing Test & Evaluation resource estimate.',
          softSkill: false,
        },
        {
          id: 'S0118',
          value: 'Skill in developing machine understandable semantic ontologies.',
          softSkill: false,
        },
        {
          id: 'S0119',
          value:
            'Skill in Regression Analysis (e.g., Hierarchical Stepwise, Generalized Linear Model, Ordinary Least Squares, Tree-Based Methods, Logistic).',
          softSkill: false,
        },
        {
          id: 'S0120',
          value: 'Skill in reviewing logs to identify evidence of past intrusions.',
          softSkill: false,
        },
        {
          id: 'S0121',
          value:
            'Skill in system, network, and OS hardening techniques. (e.g., remove unnecessary services, password policies, network segmentation, enable logging, least privilege, etc.).',
          softSkill: false,
        },
        {
          id: 'S0122',
          value: 'Skill in the use of design methods.',
          softSkill: false,
        },
        {
          id: 'S0123',
          value: 'Skill in transformation analytics (e.g., aggregation, enrichment, processing).',
          softSkill: false,
        },
        {
          id: 'S0124',
          value:
            'Skill in troubleshooting and diagnosing cyber defense infrastructure anomalies and work through resolution.',
          softSkill: false,
        },
        {
          id: 'S0125',
          value:
            'Skill in using basic descriptive statistics and techniques (e.g., normality, model distribution, scatter plots).',
          softSkill: false,
        },
        {
          id: 'S0126',
          value: 'Skill in using data analysis tools (e.g., Excel, STATA SAS, SPSS).',
          softSkill: false,
        },
        {
          id: 'S0127',
          value: 'Skill in using data mapping tools.',
          softSkill: false,
        },
        {
          id: 'S0128',
          value: 'Skill in using manpower and personnel IT systems.',
          softSkill: true,
        },
        {
          id: 'S0129',
          value: 'Skill in using outlier identification and removal techniques.',
          softSkill: false,
        },
        {
          id: 'S0130',
          value: 'Skill in writing scripts using R, Python, PIG, HIVE, SQL, etc.',
          softSkill: false,
        },
        {
          id: 'S0131',
          value: 'Skill in analyzing malware.',
          softSkill: false,
        },
        {
          id: 'S0132',
          value: 'Skill in conducting bit-level analysis.',
          softSkill: false,
        },
        {
          id: 'S0133',
          value:
            'Skill in processing digital evidence, to include protecting and making legally sound copies of evidence.',
          softSkill: false,
        },
        {
          id: 'S0134',
          value: 'Skill in conducting reviews of systems.',
          softSkill: false,
        },
        {
          id: 'S0135',
          value: 'Skill in secure test plan design (e. g. unit, integration, system, acceptance).',
          softSkill: false,
        },
        {
          id: 'S0136',
          value:
            'Skill in network systems management principles, models, methods (e.g., end-to-end systems performance monitoring), and tools.',
          softSkill: false,
        },
        {
          id: 'S0137',
          value: 'Skill in conducting application vulnerability assessments.',
          softSkill: false,
        },
        {
          id: 'S0138',
          value:
            'Skill in using Public-Key Infrastructure (PKI) encryption and digital signature capabilities into applications (e.g., S/MIME email, SSL traffic).',
          softSkill: false,
        },
        {
          id: 'S0139',
          value:
            'Skill in applying security models (e.g., Bell-LaPadula model, Biba integrity model, ClarkWilson integrity model).',
          softSkill: false,
        },
        {
          id: 'S0140',
          value: 'Skill in applying the systems engineering process.',
          softSkill: false,
        },
        {
          id: 'S0141',
          value: 'Skill in assessing security systems designs.',
          softSkill: false,
        },
        {
          id: 'S0142',
          value: 'Skill in conducting research for troubleshooting novel client-level problems.',
          softSkill: false,
        },
        {
          id: 'S0143',
          value: 'Skill in conducting system/server planning, management, and maintenance.',
          softSkill: false,
        },
        {
          id: 'S0144',
          value:
            'Skill in correcting physical and technical problems that impact system/server performance.',
          softSkill: false,
        },
        {
          id: 'S0145',
          value: 'Skill in integrating and applying policies that meet system security objectives.',
          softSkill: true,
        },
        {
          id: 'S0146',
          value:
            "Skill in creating policies that enable systems to meet performance objectives (e.g. traffic routing, SLA's, CPU specifications).",
          softSkill: false,
        },
        {
          id: 'S0147',
          value:
            'Skill in assessing security controls based on cybersecurity principles and tenets. (e.g., CIS CSC, NIST SP 800-53, Cybersecurity Framework, etc.).',
          softSkill: false,
        },
        {
          id: 'S0148',
          value:
            'Skill in designing the integration of technology processes and solutions, including legacy systems and modern programming languages.',
          softSkill: false,
        },
        {
          id: 'S0149',
          value:
            'Skill in developing applications that can log and handle errors, exceptions, and application faults and logging.',
          softSkill: false,
        },
        {
          id: 'S0150',
          value:
            'Skill in implementing and testing network infrastructure contingency and recovery plans.',
          softSkill: true,
        },
        {
          id: 'S0151',
          value: 'Skill in troubleshooting failed system components (i.e., servers)',
          softSkill: false,
        },
        {
          id: 'S0152',
          value:
            'Skill in translating operational requirements into protection needs (i.e., security controls).',
          softSkill: false,
        },
        {
          id: 'S0153',
          value:
            'Skill in identifying and anticipating system/server performance, availability, capacity, or configuration problems.',
          softSkill: false,
        },
        {
          id: 'S0154',
          value:
            'Skill in installing system and component upgrades. (i.e., servers, appliances, network devices).',
          softSkill: false,
        },
        {
          id: 'S0155',
          value: 'Skill in monitoring and optimizing system/server performance.',
          softSkill: false,
        },
        {
          id: 'S0156',
          value: 'Skill in performing packet-level analysis.',
          softSkill: false,
        },
        {
          id: 'S0157',
          value:
            'Skill in recovering failed systems/servers. (e.g., recovery software, failover clusters, replication, etc.).',
          softSkill: false,
        },
        {
          id: 'S0158',
          value:
            'Skill in operating system administration. (e.g., account maintenance, data backups, maintain system performance, install and configure new hardware/software).',
          softSkill: false,
        },
        {
          id: 'S0159',
          value:
            'Skill in configuring and validating network workstations and peripherals in accordance with approved standards and/or specifications.',
          softSkill: false,
        },
        {
          id: 'S0160',
          value: 'Skill in the use of design modeling (e.g., unified modeling language).',
          softSkill: false,
        },
        {
          id: 'S0161',
          value: 'Withdrawn – Integrated into S0160',
          softSkill: true,
        },
        {
          id: 'S0162',
          value: 'Skill in sub-netting.',
          softSkill: false,
        },
        {
          id: 'S0163',
          value: 'Withdrawn – Integrated into S0060',
          softSkill: true,
        },
        {
          id: 'S0164',
          value: 'Skill in assessing the application of cryptographic standards.',
          softSkill: true,
        },
        {
          id: 'S0166',
          value: 'Skill in identifying gaps in technical delivery capabilities.',
          softSkill: false,
        },
        {
          id: 'S0167',
          value:
            'Skill in recognizing vulnerabilities in security systems. (e.g., vulnerability and compliance scanning).',
          softSkill: false,
        },
        {
          id: 'S0168',
          value:
            'Skill in setting up physical or logical sub-networks that separate an internal local area network (LAN) from other untrusted networks.',
          softSkill: false,
        },
        {
          id: 'S0169',
          value: 'Skill in conducting trend analysis.',
          softSkill: false,
        },
        {
          id: 'S0170',
          value:
            'Skill in configuring and utilizing computer protection components (e.g., hardware firewalls, servers, routers, as appropriate).',
          softSkill: false,
        },
        {
          id: 'S0171',
          value: 'Skill in performing impact/risk assessments.',
          softSkill: true,
        },
        {
          id: 'S0172',
          value: 'Skill in applying secure coding techniques.',
          softSkill: false,
        },
        {
          id: 'S0173',
          value: 'Skill in using security event correlation tools.',
          softSkill: false,
        },
        {
          id: 'S0174',
          value: 'Skill in using code analysis tools.',
          softSkill: false,
        },
        {
          id: 'S0175',
          value: 'Skill in performing root cause analysis.',
          softSkill: false,
        },
        {
          id: 'S0176',
          value:
            'Skill in administrative planning activities, to include preparation of functional and specific support plans, preparing and managing correspondence, and staffing procedures.',
          softSkill: true,
        },
        {
          id: 'S0177',
          value: "Skill in analyzing a target's communication networks.",
          softSkill: false,
        },
        {
          id: 'S0178',
          value:
            'Skill in analyzing essential network data (e.g., router configuration files, routing protocols).',
          softSkill: false,
        },
        {
          id: 'S0179',
          value:
            'Skill in analyzing language processing tools to provide feedback to enhance tool development.',
          softSkill: false,
        },
        {
          id: 'S0180',
          value: 'Withdrawn – Integrated into S0062',
          softSkill: true,
        },
        {
          id: 'S0181',
          value: 'Skill in analyzing midpoint collection data.',
          softSkill: false,
        },
        {
          id: 'S0182',
          value:
            'Skill in analyzing target communications internals and externals collected from wireless LANs.',
          softSkill: false,
        },
        {
          id: 'S0183',
          value: 'Skill in analyzing terminal or environment collection data.',
          softSkill: false,
        },
        {
          id: 'S0184',
          value: 'Skill in analyzing traffic to identify network devices.',
          softSkill: false,
        },
        {
          id: 'S0185',
          value:
            'Skill in applying analytical methods typically employed to support planning and to justify recommended strategies and courses of action.',
          softSkill: true,
        },
        {
          id: 'S0186',
          value: 'Skill in applying crisis planning procedures.',
          softSkill: true,
        },
        {
          id: 'S0187',
          value:
            'Skill in applying various analytical methods, tools, and techniques (e.g., competing hypotheses; chain of reasoning; scenario methods; denial and deception detection; high impact-low probability; network/association or link analysis; Bayesian, Delphi, and Pattern analyses).',
          softSkill: false,
        },
        {
          id: 'S0188',
          value:
            "Skill in assessing a target's frame of reference (e.g., motivation, technical capability, organizational structure, sensitivities).",
          softSkill: false,
        },
        {
          id: 'S0189',
          value:
            'Skill in assessing and/or estimating effects generated during and after cyber operations.',
          softSkill: false,
        },
        {
          id: 'S0190',
          value: 'Skill in assessing current tools to identify needed improvements.',
          softSkill: false,
        },
        {
          id: 'S0191',
          value:
            'Skill in assessing the applicability of available analytical tools to various situations.',
          softSkill: false,
        },
        {
          id: 'S0192',
          value:
            'Skill in auditing firewalls, perimeters, routers, and intrusion detection systems.',
          softSkill: false,
        },
        {
          id: 'S0193',
          value: 'Skill in complying with the legal restrictions for targeted information.',
          softSkill: true,
        },
        {
          id: 'S0194',
          value: 'Skill in conducting non-attributable research.',
          softSkill: false,
        },
        {
          id: 'S0195',
          value: 'Skill in conducting research using all available sources.',
          softSkill: false,
        },
        {
          id: 'S0196',
          value: 'Skill in conducting research using deep web.',
          softSkill: false,
        },
        {
          id: 'S0197',
          value:
            'Skill in conducting social network analysis, buddy list analysis, and/or cookie analysis.',
          softSkill: false,
        },
        {
          id: 'S0198',
          value: 'Skill in conducting social network analysis.',
          softSkill: false,
        },
        {
          id: 'S0199',
          value: 'Skill in creating and extracting important information from packet captures.',
          softSkill: false,
        },
        {
          id: 'S0200',
          value:
            'Skill in creating collection requirements in support of data acquisition activities.',
          softSkill: false,
        },
        {
          id: 'S0201',
          value:
            'Skill in creating plans in support of remote operations. (i.e., hot/warm/cold/alternative sites, disaster recovery).',
          softSkill: true,
        },
        {
          id: 'S0202',
          value: 'Skill in data mining techniques (e.g., searching file systems) and analysis.',
          softSkill: false,
        },
        {
          id: 'S0203',
          value:
            'Skill in defining and characterizing all pertinent aspects of the operational environment.',
          softSkill: false,
        },
        {
          id: 'S0204',
          value: 'Skill in depicting source or collateral data on a network map.',
          softSkill: false,
        },
        {
          id: 'S0205',
          value:
            'Skill in determining appropriate targeting options through the evaluation of available capabilities against desired effects.',
          softSkill: false,
        },
        {
          id: 'S0206',
          value:
            'Skill in determining installed patches on various operating systems and identifying patch signatures.',
          softSkill: false,
        },
        {
          id: 'S0207',
          value:
            'Skill in determining the effect of various router and firewall configurations on traffic patterns and network performance in both LAN and WAN environments.',
          softSkill: false,
        },
        {
          id: 'S0208',
          value: 'Skill in determining the physical location of network devices.',
          softSkill: false,
        },
        {
          id: 'S0209',
          value:
            'Skill in developing and executing comprehensive cyber operations assessment programs for assessing and validating operational performance characteristics.',
          softSkill: false,
        },
        {
          id: 'S0210',
          value: 'Skill in developing intelligence reports.',
          softSkill: false,
        },
        {
          id: 'S0211',
          value:
            'Skill in developing or recommending analytic approaches or solutions to problems and situations for which information is incomplete or for which no precedent exists.',
          softSkill: false,
        },
        {
          id: 'S0212',
          value: 'Skill in disseminating items of highest intelligence value in a timely manner.',
          softSkill: false,
        },
        {
          id: 'S0213',
          value:
            'Skill in documenting and communicating complex technical and programmatic information.',
          softSkill: true,
        },
        {
          id: 'S0214',
          value: 'Skill in evaluating accesses for intelligence value.',
          softSkill: false,
        },
        {
          id: 'S0215',
          value: 'Skill in evaluating and interpreting metadata.',
          softSkill: false,
        },
        {
          id: 'S0216',
          value:
            'Skill in evaluating available capabilities against desired effects to provide effective courses of action.',
          softSkill: true,
        },
        {
          id: 'S0217',
          value: 'Skill in evaluating data sources for relevance, reliability, and objectivity.',
          softSkill: false,
        },
        {
          id: 'S0218',
          value: 'Skill in evaluating information for reliability, validity, and relevance.',
          softSkill: false,
        },
        {
          id: 'S0219',
          value: 'Skill in evaluating information to recognize relevance, priority, etc.',
          softSkill: false,
        },
        {
          id: 'S0220',
          value: 'Skill in exploiting/querying organizational and/or partner collection databases.',
          softSkill: false,
        },
        {
          id: 'S0221',
          value: 'Skill in extracting information from packet captures.',
          softSkill: false,
        },
        {
          id: 'S0222',
          value: 'Skill in fusion analysis',
          softSkill: false,
        },
        {
          id: 'S0223',
          value:
            'Skill in generating operation plans in support of mission and target requirements.',
          softSkill: false,
        },
        {
          id: 'S0224',
          value: 'Skill in gisting target communications.',
          softSkill: false,
        },
        {
          id: 'S0225',
          value: 'Skill in identifying a target’s communications networks.',
          softSkill: false,
        },
        {
          id: 'S0226',
          value: "Skill in identifying a target's network characteristics.",
          softSkill: false,
        },
        {
          id: 'S0227',
          value:
            'Skill in identifying alternative analytical interpretations to minimize unanticipated outcomes.',
          softSkill: false,
        },
        {
          id: 'S0228',
          value:
            'Skill in identifying critical target elements, to include critical target elements for the cyber domain.',
          softSkill: false,
        },
        {
          id: 'S0229',
          value:
            'Skill in identifying cyber threats which may jeopardize organization and/or partner interests.',
          softSkill: false,
        },
        {
          id: 'S0230',
          value: 'Withdrawn – Integrated into S0066',
          softSkill: true,
        },
        {
          id: 'S0231',
          value: 'Skill in identifying how a target communicates.',
          softSkill: false,
        },
        {
          id: 'S0232',
          value: 'Skill in identifying intelligence gaps and limitations.',
          softSkill: false,
        },
        {
          id: 'S0233',
          value:
            'Skill in identifying language issues that may have an impact on organization objectives.',
          softSkill: false,
        },
        {
          id: 'S0234',
          value: 'Skill in identifying leads for target development.',
          softSkill: false,
        },
        {
          id: 'S0235',
          value: 'Skill in identifying non-target regional languages and dialects',
          softSkill: false,
        },
        {
          id: 'S0236',
          value: 'Skill in identifying the devices that work at each level of protocol models.',
          softSkill: false,
        },
        {
          id: 'S0237',
          value:
            'Skill in identifying, locating, and tracking targets via geospatial analysis techniques',
          softSkill: false,
        },
        {
          id: 'S0238',
          value: 'Skill in information prioritization as it relates to operations.',
          softSkill: false,
        },
        {
          id: 'S0239',
          value: 'Skill in interpreting compiled and interpretive programming languages.',
          softSkill: false,
        },
        {
          id: 'S0240',
          value: 'Skill in interpreting metadata and content as applied by collection systems.',
          softSkill: false,
        },
        {
          id: 'S0241',
          value:
            'Skill in interpreting traceroute results, as they apply to network analysis and reconstruction.',
          softSkill: false,
        },
        {
          id: 'S0242',
          value: 'Skill in interpreting vulnerability scanner results to identify vulnerabilities.',
          softSkill: false,
        },
        {
          id: 'S0243',
          value:
            'Skill in knowledge management, including technical documentation techniques (e.g., Wiki page).',
          softSkill: false,
        },
        {
          id: 'S0244',
          value:
            'Skill in managing client relationships, including determining client needs/requirements, managing client expectations, and demonstrating commitment to delivering quality results.',
          softSkill: true,
        },
        {
          id: 'S0245',
          value: 'Skill in navigating network visualization software.',
          softSkill: false,
        },
        {
          id: 'S0246',
          value: 'Skill in number normalization.',
          softSkill: false,
        },
        {
          id: 'S0247',
          value:
            'Skill in performing data fusion from existing intelligence for enabling new and continued collection.',
          softSkill: false,
        },
        {
          id: 'S0248',
          value: 'Skill in performing target system analysis.',
          softSkill: false,
        },
        {
          id: 'S0249',
          value: 'Skill in preparing and presenting briefings.',
          softSkill: true,
        },
        {
          id: 'S0250',
          value: 'Skill in preparing plans and related correspondence.',
          softSkill: true,
        },
        {
          id: 'S0251',
          value: 'Skill in prioritizing target language material.',
          softSkill: false,
        },
        {
          id: 'S0252',
          value: 'Skill in processing collected data for follow-on analysis.',
          softSkill: false,
        },
        {
          id: 'S0253',
          value:
            'Skill in providing analysis on target-related matters (e.g., language, cultural, communications).',
          softSkill: false,
        },
        {
          id: 'S0254',
          value: 'Skill in providing analysis to aid writing phased after action reports.',
          softSkill: true,
        },
        {
          id: 'S0255',
          value:
            'Skill in providing real-time, actionable geolocation information utilizing target infrastructures.',
          softSkill: false,
        },
        {
          id: 'S0256',
          value:
            'Skill in providing understanding of target or threat systems through the identification and link analysis of physical, functional, or behavioral relationships.',
          softSkill: false,
        },
        {
          id: 'S0257',
          value:
            'Skill in reading, interpreting, writing, modifying, and executing simple scripts (e.g., PERL, VBS) on Windows and Unix systems (e.g., those that perform tasks like parsing large data files, automating manual tasks, and fetching/processing remote data).',
          softSkill: false,
        },
        {
          id: 'S0258',
          value: 'Skill in recognizing and interpreting malicious network activity in traffic.',
          softSkill: false,
        },
        {
          id: 'S0259',
          value: 'Skill in recognizing denial and deception techniques of the target.',
          softSkill: false,
        },
        {
          id: 'S0260',
          value: 'Skill in recognizing midpoint opportunities and essential information.',
          softSkill: false,
        },
        {
          id: 'S0261',
          value: 'Skill in recognizing relevance of information.',
          softSkill: false,
        },
        {
          id: 'S0262',
          value: 'Skill in recognizing significant changes in a target’s communication patterns.',
          softSkill: false,
        },
        {
          id: 'S0263',
          value:
            'Skill in recognizing technical information that may be used for leads for metadata analysis.',
          softSkill: false,
        },
        {
          id: 'S0264',
          value:
            'Skill in recognizing technical information that may be used for leads to enable remote operations (data includes users, passwords, email addresses, IP ranges of the target, frequency in DNI behavior, mail servers, domain servers, SMTP header information).',
          softSkill: false,
        },
        {
          id: 'S0265',
          value:
            'Skill in recognizing technical information that may be used for target development including intelligence development.',
          softSkill: false,
        },
        {
          id: 'S0266',
          value: 'Skill in relevant programming languages (e.g., C++, Python, etc.).',
          softSkill: false,
        },
        {
          id: 'S0267',
          value: 'Skill in remote command line and Graphic User Interface (GUI) tool usage.',
          softSkill: false,
        },
        {
          id: 'S0268',
          value: 'Skill in researching essential information.',
          softSkill: false,
        },
        {
          id: 'S0269',
          value: 'Skill in researching vulnerabilities and exploits utilized in traffic.',
          softSkill: false,
        },
        {
          id: 'S0270',
          value:
            'Skill in reverse engineering (e.g., hex editing, binary packaging utilities, debugging, and strings analysis) to identify function and ownership of remote tools.',
          softSkill: false,
        },
        {
          id: 'S0271',
          value: 'Skill in reviewing and editing assessment products.',
          softSkill: false,
        },
        {
          id: 'S0272',
          value:
            'Skill in reviewing and editing intelligence products from various sources for cyber operations.',
          softSkill: false,
        },
        {
          id: 'S0273',
          value: 'Skill in reviewing and editing plans.',
          softSkill: true,
        },
        {
          id: 'S0274',
          value: 'Skill in reviewing and editing target materials.',
          softSkill: true,
        },
        {
          id: 'S0275',
          value: 'Skill in server administration.',
          softSkill: false,
        },
        {
          id: 'S0276',
          value: 'Skill in survey, collection, and analysis of wireless LAN metadata.',
          softSkill: false,
        },
        {
          id: 'S0277',
          value: 'Skill in synthesizing, analyzing, and prioritizing meaning across data sets.',
          softSkill: false,
        },
        {
          id: 'S0278',
          value:
            'Skill in tailoring analysis to the necessary levels (e.g., classification and organizational).',
          softSkill: false,
        },
        {
          id: 'S0279',
          value: 'Skill in target development in direct support of collection operations.',
          softSkill: false,
        },
        {
          id: 'S0280',
          value:
            'Skill in target network anomaly identification (e.g., intrusions, dataflow or processing, target implementation of new technologies).',
          softSkill: false,
        },
        {
          id: 'S0281',
          value: 'Skill in technical writing.',
          softSkill: true,
        },
        {
          id: 'S0282',
          value: 'Skill in testing and evaluating tools for implementation.',
          softSkill: false,
        },
        {
          id: 'S0283',
          value: 'Skill in transcribing target language communications.',
          softSkill: false,
        },
        {
          id: 'S0284',
          value: 'Skill in translating target graphic and/or voice language materials.',
          softSkill: false,
        },
        {
          id: 'S0285',
          value: 'Skill in using Boolean operators to construct simple and complex queries.',
          softSkill: false,
        },
        {
          id: 'S0286',
          value: 'Skill in using databases to identify target-relevant information.',
          softSkill: false,
        },
        {
          id: 'S0287',
          value: 'Skill in using geospatial data and applying geospatial resources.',
          softSkill: false,
        },
        {
          id: 'S0288',
          value:
            'Skill in using multiple analytic tools, databases, and techniques (e.g., Analyst’s Notebook, A-Space, Anchory, M3, divergent/convergent thinking, link charts, matrices, etc.).',
          softSkill: false,
        },
        {
          id: 'S0289',
          value:
            'Skill in using multiple search engines (e.g., Google, Yahoo, LexisNexis, DataStar) and tools in conducting open-source searches.',
          softSkill: false,
        },
        {
          id: 'S0290',
          value: 'Skill in using non-attributable networks.',
          softSkill: false,
        },
        {
          id: 'S0291',
          value:
            'Skill in using research methods including multiple, different sources to reconstruct a target network.',
          softSkill: false,
        },
        {
          id: 'S0292',
          value: 'Skill in using targeting databases and software packages.',
          softSkill: false,
        },
        {
          id: 'S0293',
          value:
            'Skill in using tools, techniques, and procedures to remotely exploit and establish persistence on a target.',
          softSkill: false,
        },
        {
          id: 'S0294',
          value:
            'Skill in using trace route tools and interpreting the results as they apply to network analysis and reconstruction.',
          softSkill: false,
        },
        {
          id: 'S0295',
          value:
            'Skill in using various open source data collection tools (online trade, DNS, mail, etc.).',
          softSkill: false,
        },
        {
          id: 'S0296',
          value: 'Skill in utilizing feedback to improve processes, products, and services.',
          softSkill: true,
        },
        {
          id: 'S0297',
          value:
            'Skill in utilizing virtual collaborative workspaces and/or tools (e.g., IWS, VTCs, chat rooms, SharePoint).',
          softSkill: true,
        },
        {
          id: 'S0298',
          value:
            'Skill in verifying the integrity of all files. (e.g., checksums, Exclusive OR, secure hashes, check constraints, etc.).',
          softSkill: false,
        },
        {
          id: 'S0299',
          value: 'Skill in wireless network target analysis, templating, and geolocation.',
          softSkill: false,
        },
        {
          id: 'S0300',
          value:
            'Skill in writing (and submitting) requirements to meet gaps in technical capabilities.',
          softSkill: true,
        },
        {
          id: 'S0301',
          value:
            'Skill in writing about facts and ideas in a clear, convincing, and organized manner.',
          softSkill: true,
        },
        {
          id: 'S0302',
          value: 'Skill in writing effectiveness reports.',
          softSkill: true,
        },
        {
          id: 'S0303',
          value:
            'Skill in writing, reviewing and editing cyber-related Intelligence/assessment products from multiple sources.',
          softSkill: true,
        },
        {
          id: 'S0304',
          value: 'Skill to access information on current assets available, usage.',
          softSkill: false,
        },
        {
          id: 'S0305',
          value: 'Skill to access the databases where plans/directives/guidance are maintained.',
          softSkill: false,
        },
        {
          id: 'S0306',
          value:
            'Skill to analyze strategic guidance for issues requiring clarification and/or additional guidance.',
          softSkill: true,
        },
        {
          id: 'S0307',
          value: 'Skill to analyze target or threat sources of strength and morale.',
          softSkill: false,
        },
        {
          id: 'S0308',
          value: 'Skill to anticipate intelligence capability employment requirements.',
          softSkill: false,
        },
        {
          id: 'S0309',
          value:
            'Skill to anticipate key target or threat activities which are likely to prompt a leadership decision.',
          softSkill: true,
        },
        {
          id: 'S0310',
          value: 'Skill to apply analytical standards to evaluate intelligence products.',
          softSkill: false,
        },
        {
          id: 'S0311',
          value:
            'Skill to apply the capabilities, limitations and tasking methodologies of available platforms, sensors, architectures and apparatus as they apply to organization objectives.',
          softSkill: false,
        },
        {
          id: 'S0312',
          value:
            'Skill to apply the process used to assess the performance and impact of cyber operations.',
          softSkill: true,
        },
        {
          id: 'S0313',
          value:
            'Skill to articulate a needs statement/requirement and integrate new and emerging collection capabilities, accesses and/or processes into collection operations.',
          softSkill: false,
        },
        {
          id: 'S0314',
          value:
            'Skill to articulate intelligence capabilities available to support execution of the plan.',
          softSkill: true,
        },
        {
          id: 'S0315',
          value: 'Skill to articulate the needs of joint planners to all-source analysts.',
          softSkill: true,
        },
        {
          id: 'S0316',
          value:
            'Skill to associate Intelligence gaps to priority information requirements and observables.',
          softSkill: false,
        },
        {
          id: 'S0317',
          value: 'Skill to compare indicators/observables with requirements.',
          softSkill: false,
        },
        {
          id: 'S0318',
          value:
            'Skill to conceptualize the entirety of the intelligence process in the multiple domains and dimensions.',
          softSkill: true,
        },
        {
          id: 'S0319',
          value: 'Skill to convert intelligence requirements into intelligence production tasks.',
          softSkill: false,
        },
        {
          id: 'S0320',
          value: 'Skill to coordinate the development of tailored intelligence products.',
          softSkill: false,
        },
        {
          id: 'S0321',
          value:
            'Skill to correlate intelligence priorities to the allocation of intelligence resources/assets.',
          softSkill: false,
        },
        {
          id: 'S0322',
          value: 'Skill to craft indicators of operational progress/success.',
          softSkill: false,
        },
        {
          id: 'S0323',
          value:
            'Skill to create and maintain up-to-date planning documents and tracking of services/production.',
          softSkill: true,
        },
        {
          id: 'S0324',
          value: 'Skill to determine feasibility of collection.',
          softSkill: false,
        },
        {
          id: 'S0325',
          value:
            'Skill to develop a collection plan that clearly shows the discipline that can be used to collect the information needed.',
          softSkill: false,
        },
        {
          id: 'S0326',
          value:
            'Skill to distinguish between notional and actual resources and their applicability to the plan under development.',
          softSkill: true,
        },
        {
          id: 'S0327',
          value: 'Skill to ensure that the collection strategy leverages all available resources.',
          softSkill: false,
        },
        {
          id: 'S0328',
          value:
            'Skill to evaluate factors of the operational environment to objectives, and information requirements.',
          softSkill: false,
        },
        {
          id: 'S0329',
          value:
            'Skill to evaluate requests for information to determine if response information exists.',
          softSkill: false,
        },
        {
          id: 'S0330',
          value:
            'Skill to evaluate the capabilities, limitations and tasking methodologies of organic, theater, national, coalition and other collection capabilities.',
          softSkill: false,
        },
        {
          id: 'S0331',
          value:
            'Skill to express orally and in writing the relationship between intelligence capability limitations and decision-making risk and impacts on the overall operation.',
          softSkill: true,
        },
        {
          id: 'S0332',
          value:
            'Skill to extract information from available tools and applications associated with collection requirements and collection operations management.',
          softSkill: false,
        },
        {
          id: 'S0333',
          value:
            'Skill to graphically depict decision support materials containing intelligence and partner capability estimates.',
          softSkill: true,
        },
        {
          id: 'S0334',
          value:
            'Skill to identify and apply tasking, collection, processing, exploitation and dissemination to associated collection disciplines.',
          softSkill: false,
        },
        {
          id: 'S0335',
          value: 'Skill to identify Intelligence gaps.',
          softSkill: false,
        },
        {
          id: 'S0336',
          value: 'Skill to identify when priority information requirements are satisfied.',
          softSkill: false,
        },
        {
          id: 'S0337',
          value:
            'Skill to implement established procedures for evaluating collection management and operations activities.',
          softSkill: false,
        },
        {
          id: 'S0338',
          value:
            'Skill to interpret planning guidance to discern level of analytical support required.',
          softSkill: true,
        },
        {
          id: 'S0339',
          value:
            'Skill to interpret readiness reporting, its operational relevance and intelligence collection impact.',
          softSkill: false,
        },
        {
          id: 'S0340',
          value: 'Skill to monitor target or threat situation and environmental factors.',
          softSkill: false,
        },
        {
          id: 'S0341',
          value:
            'Skill to monitor threat effects to partner capabilities and maintain a running estimate.',
          softSkill: false,
        },
        {
          id: 'S0342',
          value:
            'Skill to optimize collection system performance through repeated adjustment, testing, and re-adjustment.',
          softSkill: false,
        },
        {
          id: 'S0343',
          value:
            'Skill to orchestrate intelligence planning teams, coordinate collection and production support, and monitor status.',
          softSkill: true,
        },
        {
          id: 'S0344',
          value:
            'Skill to prepare and deliver reports, presentations and briefings, to include using visual aids or presentation technology.',
          softSkill: true,
        },
        {
          id: 'S0345',
          value:
            'Skill to relate intelligence resources/assets to anticipated intelligence requirements.',
          softSkill: false,
        },
        {
          id: 'S0346',
          value: 'Skill to resolve conflicting collection requirements.',
          softSkill: false,
        },
        {
          id: 'S0347',
          value:
            'Skill to review performance specifications and historical information about collection assets.',
          softSkill: false,
        },
        {
          id: 'S0348',
          value:
            'Skill to specify collections and/or taskings that must be conducted in the near term.',
          softSkill: false,
        },
        {
          id: 'S0349',
          value:
            'Skill to synchronize operational assessment procedures with the critical information requirement process.',
          softSkill: false,
        },
        {
          id: 'S0350',
          value: 'Skill to synchronize planning activities and required intelligence support.',
          softSkill: true,
        },
        {
          id: 'S0351',
          value:
            'Skill to translate the capabilities, limitations and tasking methodologies of organic, theater, national, coalition and other collection capabilities.',
          softSkill: false,
        },
        {
          id: 'S0352',
          value: 'Skill to use collaborative tools and environments for collection operations.',
          softSkill: false,
        },
        {
          id: 'S0353',
          value:
            'Skill to use systems and/or tools to track collection requirements and determine if they are satisfied.',
          softSkill: false,
        },
        {
          id: 'S0354',
          value: 'Skill in creating policies that reflect the business’s core privacy objectives.',
          softSkill: true,
        },
        {
          id: 'S0355',
          value: 'Skill in negotiating vendor agreements and evaluating vendor privacy practices.',
          softSkill: true,
        },
        {
          id: 'S0356',
          value:
            'Skill in communicating with all levels of management including Board members (e.g., interpersonal skills, approachability, effective listening skills, appropriate use of style and language for the audience).',
          softSkill: true,
        },
        {
          id: 'S0357',
          value: 'Skill to anticipate new security threats.',
          softSkill: false,
        },
        {
          id: 'S0358',
          value: 'Skill to remain aware of evolving technical infrastructures.',
          softSkill: false,
        },
        {
          id: 'S0359',
          value:
            'Skill to use critical thinking to analyze organizational patterns and relationships.',
          softSkill: true,
        },
        {
          id: 'S0360',
          value:
            'Skill to analyze and assess internal and external partner cyber operations capabilities and tools.',
          softSkill: false,
        },
        {
          id: 'S0361',
          value:
            'Skill to analyze and assess internal and external partner intelligence processes and the development of information requirements and essential information.',
          softSkill: false,
        },
        {
          id: 'S0362',
          value:
            'Skill to analyze and assess internal and external partner organization capabilities and limitations (those with tasking, collection, processing, exploitation and dissemination responsibilities).',
          softSkill: false,
        },
        {
          id: 'S0363',
          value: 'Skill to analyze and assess internal and external partner reporting.',
          softSkill: false,
        },
        {
          id: 'S0364',
          value:
            'Skill to develop insights about the context of an organization’s threat environment',
          softSkill: false,
        },
        {
          id: 'S0365',
          value: 'Skill to design incident response for cloud service models.',
          softSkill: false,
        },
        {
          id: 'S0366',
          value:
            'Skill to identify successful capabilities to find solutions to less common and more complex system problems.',
          softSkill: true,
        },
        {
          id: 'S0367',
          value:
            'Skill to apply cybersecurity and privacy principles to organizational requirements (relevant to confidentiality, integrity, availability, authentication, non-repudiation).',
          softSkill: false,
        },
        {
          id: 'S0368',
          value:
            'Skill to use risk scoring to inform performance-based and cost-effective approaches to help organizations to identify, assess, and manage cybersecurity risk.',
          softSkill: true,
        },
        {
          id: 'S0369',
          value:
            'Skill to identify sources, characteristics, and uses of the organization’s data assets.',
          softSkill: false,
        },
        {
          id: 'S0370',
          value:
            'Skill to use cyber defense Service Provider reporting structure and processes within one’s own organization.',
          softSkill: false,
        },
        {
          id: 'S0371',
          value:
            'Skill to respond and take local actions in response to threat sharing alerts from service providers.',
          softSkill: true,
        },
        {
          id: 'S0372',
          value:
            'Skill to translate, track, and prioritize information needs and intelligence collection requirements across the extended enterprise.',
          softSkill: false,
        },
        {
          id: 'S0373',
          value:
            'Skill to ensure that accountability information is collected for information system and information and communications technology supply chain infrastructure components.',
          softSkill: true,
        },
        {
          id: 'S0374',
          value:
            'Skill to identify cybersecurity and privacy issues that stem from connections with internal and external customers and partner organizations.',
          softSkill: true,
        },
      ],
    },
    {
      name: 'abilities',
      items: [
        {
          id: 'A0001',
          value:
            'Ability to identify systemic security issues based on the analysis of vulnerability and configuration data.',
          softSkill: false,
        },
        {
          id: 'A0002',
          value:
            'Ability to match the appropriate knowledge repository technology for a given application or environment.',
          softSkill: false,
        },
        {
          id: 'A0003',
          value: 'Ability to determine the validity of technology trend data.',
          softSkill: false,
        },
        {
          id: 'A0004',
          value:
            'Ability to develop curriculum that speaks to the topic at the appropriate level for the target audience.',
          softSkill: true,
        },
        {
          id: 'A0005',
          value: 'Ability to decrypt digital data collections.',
          softSkill: false,
        },
        {
          id: 'A0006',
          value:
            'Ability to prepare and deliver education and awareness briefings to ensure that systems, network, and data users are aware of and adhere to systems security policies and procedures.',
          softSkill: true,
        },
        {
          id: 'A0007',
          value: 'Ability to tailor code analysis for application-specific concerns.',
          softSkill: false,
        },
        {
          id: 'A0008',
          value:
            "Ability to apply the methods, standards, and approaches for describing, analyzing, and documenting an organization's enterprise information technology (IT) architecture (e.g., Open Group Architecture Framework [TOGAF], Department of Defense Architecture Framework [DoDAF], Federal Enterprise Architecture Framework [FEAF]).",
          softSkill: false,
        },
        {
          id: 'A0009',
          value: 'Ability to apply supply chain risk management standards.',
          softSkill: true,
        },
        {
          id: 'A0010',
          value: 'Ability to analyze malware.',
          softSkill: false,
        },
        {
          id: 'A0011',
          value: 'Ability to answer questions in a clear and concise manner.',
          softSkill: true,
        },
        {
          id: 'A0012',
          value: 'Ability to ask clarifying questions.',
          softSkill: true,
        },
        {
          id: 'A0013',
          value:
            'Ability to communicate complex information, concepts, or ideas in a confident and wellorganized manner through verbal, written, and/or visual means.',
          softSkill: true,
        },
        {
          id: 'A0014',
          value: 'Ability to communicate effectively when writing.',
          softSkill: true,
        },
        {
          id: 'A0015',
          value:
            'Ability to conduct vulnerability scans and recognize vulnerabilities in security systems.',
          softSkill: false,
        },
        {
          id: 'A0016',
          value: 'Ability to facilitate small group discussions.',
          softSkill: true,
        },
        {
          id: 'A0017',
          value: 'Ability to gauge learner understanding and knowledge level.',
          softSkill: true,
        },
        {
          id: 'A0018',
          value: 'Ability to prepare and present briefings.',
          softSkill: true,
        },
        {
          id: 'A0019',
          value: 'Ability to produce technical documentation.',
          softSkill: true,
        },
        {
          id: 'A0020',
          value: 'Ability to provide effective feedback to students for improving learning.',
          softSkill: true,
        },
        {
          id: 'A0021',
          value:
            'Ability to use and understand complex mathematical concepts (e.g., discrete math).',
          softSkill: false,
        },
        {
          id: 'A0022',
          value: 'Ability to apply principles of adult learning.',
          softSkill: true,
        },
        {
          id: 'A0023',
          value: 'Ability to design valid and reliable assessments.',
          softSkill: false,
        },
        {
          id: 'A0024',
          value: 'Ability to develop clear directions and instructional materials.',
          softSkill: true,
        },
        {
          id: 'A0025',
          value:
            'Ability to accurately define incidents, problems, and events in the trouble ticketing system.',
          softSkill: false,
        },
        {
          id: 'A0026',
          value: 'Ability to analyze test data.',
          softSkill: false,
        },
        {
          id: 'A0027',
          value:
            "Ability to apply an organization's goals and objectives to develop and maintain architecture.",
          softSkill: false,
        },
        {
          id: 'A0028',
          value:
            'Ability to assess and forecast manpower requirements to meet organizational objectives.',
          softSkill: true,
        },
        {
          id: 'A0029',
          value: 'Ability to build complex data structures and high-level programming languages.',
          softSkill: false,
        },
        {
          id: 'A0030',
          value: 'Ability to collect, verify, and validate test data.',
          softSkill: false,
        },
        {
          id: 'A0031',
          value:
            'Ability to conduct and implement market research to understand government and industry capabilities and appropriate pricing.',
          softSkill: true,
        },
        {
          id: 'A0032',
          value: 'Ability to develop curriculum for use within a virtual environment.',
          softSkill: true,
        },
        {
          id: 'A0033',
          value:
            'Ability to develop policy, plans, and strategy in compliance with laws, regulations, policies, and standards in support of organizational cyber activities.',
          softSkill: true,
        },
        {
          id: 'A0034',
          value:
            'Ability to develop, update, and/or maintain standard operating procedures (SOPs).',
          softSkill: true,
        },
        {
          id: 'A0035',
          value:
            'Ability to dissect a problem and examine the interrelationships between data that may appear unrelated.',
          softSkill: false,
        },
        {
          id: 'A0036',
          value: 'Ability to identify basic common coding flaws at a high level.',
          softSkill: false,
        },
        {
          id: 'A0037',
          value:
            'Ability to leverage best practices and lessons learned of external organizations and academic institutions dealing with cyber issues.',
          softSkill: true,
        },
        {
          id: 'A0038',
          value: 'Ability to optimize systems to meet enterprise performance requirements.',
          softSkill: false,
        },
        {
          id: 'A0039',
          value: 'Ability to oversee the development and update of the life cycle cost estimate.',
          softSkill: true,
        },
        {
          id: 'A0040',
          value: 'Ability to translate data and test results into evaluative conclusions.',
          softSkill: false,
        },
        {
          id: 'A0041',
          value:
            'Ability to use data visualization tools (e.g., Flare, HighCharts, AmCharts, D3.js, Processing, Google Visualization API, Tableau, Raphael.js).',
          softSkill: false,
        },
        {
          id: 'A0042',
          value: 'Ability to develop career path opportunities.',
          softSkill: true,
        },
        {
          id: 'A0043',
          value:
            'Ability to conduct forensic analyses in and for both Windows and Unix/Linux environments.',
          softSkill: false,
        },
        {
          id: 'A0044',
          value:
            'Ability to apply programming language structures (e.g., source code review) and logic.',
          softSkill: false,
        },
        {
          id: 'A0045',
          value: 'Ability to evaluate/ensure the trustworthiness of the supplier and/or product.',
          softSkill: true,
        },
        {
          id: 'A0046',
          value:
            'Ability to monitor and assess the potential impact of emerging technologies on laws, regulations, and/or policies.',
          softSkill: false,
        },
        {
          id: 'A0047',
          value:
            'Ability to develop secure software according to secure software deployment methodologies, tools, and practices.',
          softSkill: false,
        },
        {
          id: 'A0048',
          value:
            'Ability to apply network security architecture concepts including topology, protocols, components, and principles (e.g., application of defense-in-depth).',
          softSkill: false,
        },
        {
          id: 'A0049',
          value: 'Ability to apply secure system design tools, methods and techniques.',
          softSkill: false,
        },
        {
          id: 'A0050',
          value:
            'Ability to apply system design tools, methods, and techniques, including automated systems analysis and design tools.',
          softSkill: false,
        },
        {
          id: 'A0051',
          value: 'Ability to execute technology integration processes.',
          softSkill: false,
        },
        {
          id: 'A0052',
          value:
            'Ability to operate network equipment including hubs, routers, switches, bridges, servers, transmission media, and related hardware.',
          softSkill: false,
        },
        {
          id: 'A0053',
          value: 'Ability to determine the validity of workforce trend data.',
          softSkill: true,
        },
        {
          id: 'A0054',
          value: 'Ability to apply the Instructional System Design (ISD) methodology.',
          softSkill: true,
        },
        {
          id: 'A0055',
          value: 'Ability to operate common network tools (e.g., ping, traceroute, nslookup).',
          softSkill: false,
        },
        {
          id: 'A0056',
          value:
            'Ability to ensure security practices are followed throughout the acquisition process.',
          softSkill: true,
        },
        {
          id: 'A0057',
          value:
            'Ability to tailor curriculum that speaks to the topic at the appropriate level for the target audience.',
          softSkill: true,
        },
        {
          id: 'A0058',
          value: 'Ability to execute OS command line (e.g., ipconfig, netstat, dir, nbtstat).',
          softSkill: false,
        },
        {
          id: 'A0059',
          value: "Ability to operate the organization's LAN/WAN pathways.",
          softSkill: false,
        },
        {
          id: 'A0060',
          value: 'Ability to build architectures and frameworks.',
          softSkill: false,
        },
        {
          id: 'A0061',
          value: 'Ability to design architectures and frameworks.',
          softSkill: false,
        },
        {
          id: 'A0062',
          value:
            'Ability to monitor measures or indicators of system performance and availability.',
          softSkill: false,
        },
        {
          id: 'A0063',
          value:
            'Ability to operate different electronic communication systems and methods (e.g., e-mail, VOIP, IM, web forums, Direct Video Broadcasts).',
          softSkill: false,
        },
        {
          id: 'A0064',
          value:
            'Ability to interpret and translate customer requirements into operational capabilities.',
          softSkill: false,
        },
        {
          id: 'A0065',
          value: 'Ability to monitor traffic flows across the network.',
          softSkill: false,
        },
        {
          id: 'A0066',
          value:
            'Ability to accurately and completely source all data used in intelligence, assessment and/or planning products.',
          softSkill: false,
        },
        {
          id: 'A0067',
          value:
            'Ability to adjust to and operate in a diverse, unpredictable, challenging, and fast-paced work environment.',
          softSkill: true,
        },
        {
          id: 'A0068',
          value: 'Ability to apply approved planning development and staffing processes.',
          softSkill: true,
        },
        {
          id: 'A0069',
          value: 'Ability to apply collaborative skills and strategies.',
          softSkill: true,
        },
        {
          id: 'A0070',
          value: 'Ability to apply critical reading/thinking skills.',
          softSkill: true,
        },
        {
          id: 'A0071',
          value: 'Ability to apply language and cultural expertise to analysis.',
          softSkill: false,
        },
        {
          id: 'A0072',
          value:
            'Ability to clearly articulate intelligence requirements into well-formulated research questions and data tracking variables for inquiry tracking purposes.',
          softSkill: true,
        },
        {
          id: 'A0073',
          value:
            'Ability to clearly articulate intelligence requirements into well-formulated research questions and requests for information.',
          softSkill: true,
        },
        {
          id: 'A0074',
          value: 'Ability to collaborate effectively with others.',
          softSkill: true,
        },
        {
          id: 'A0076',
          value:
            'Ability to coordinate and collaborate with analysts regarding surveillance requirements and essential information development.',
          softSkill: true,
        },
        {
          id: 'A0077',
          value:
            'Ability to coordinate cyber operations with other organization functions or support activities.',
          softSkill: false,
        },
        {
          id: 'A0078',
          value:
            'Ability to coordinate, collaborate and disseminate information to subordinate, lateral and higher-level organizations.',
          softSkill: false,
        },
        {
          id: 'A0079',
          value:
            'Ability to correctly employ each organization or element into the collection plan and matrix.',
          softSkill: false,
        },
        {
          id: 'A0080',
          value:
            'Ability to develop or recommend analytic approaches or solutions to problems and situations for which information is incomplete or for which no precedent exists.',
          softSkill: false,
        },
        {
          id: 'A0081',
          value:
            'Ability to develop or recommend planning solutions to problems and situations for which no precedent exists.',
          softSkill: false,
        },
        {
          id: 'A0082',
          value: 'Ability to effectively collaborate via virtual teams.',
          softSkill: true,
        },
        {
          id: 'A0083',
          value: 'Ability to evaluate information for reliability, validity, and relevance.',
          softSkill: false,
        },
        {
          id: 'A0084',
          value:
            'Ability to evaluate, analyze, and synthesize large quantities of data (which may be fragmented and contradictory) into high quality, fused targeting/intelligence products.',
          softSkill: false,
        },
        {
          id: 'A0085',
          value: 'Ability to exercise judgment when policies are not well-defined.',
          softSkill: true,
        },
        {
          id: 'A0086',
          value:
            'Ability to expand network access by conducting target analysis and collection to identify targets of interest.',
          softSkill: false,
        },
        {
          id: 'A0087',
          value: 'Ability to focus research efforts to meet the customer’s decision-making needs.',
          softSkill: true,
        },
        {
          id: 'A0088',
          value: 'Ability to function effectively in a dynamic, fast-paced environment.',
          softSkill: true,
        },
        {
          id: 'A0089',
          value:
            'Ability to function in a collaborative environment, seeking continuous consultation with other analysts and experts—both internal and external to the organization—to leverage analytical and technical expertise.',
          softSkill: true,
        },
        {
          id: 'A0090',
          value: 'Ability to identify external partners with common cyber operations interests.',
          softSkill: true,
        },
        {
          id: 'A0091',
          value: 'Ability to identify intelligence gaps.',
          softSkill: false,
        },
        {
          id: 'A0092',
          value: 'Ability to identify/describe target vulnerability.',
          softSkill: false,
        },
        {
          id: 'A0093',
          value:
            'Ability to identify/describe techniques/methods for conducting technical exploitation of the target.',
          softSkill: false,
        },
        {
          id: 'A0094',
          value:
            'Ability to interpret and apply laws, regulations, policies, and guidance relevant to organization cyber objectives.',
          softSkill: true,
        },
        {
          id: 'A0095',
          value:
            'Ability to interpret and translate customer requirements into operational action.',
          softSkill: false,
        },
        {
          id: 'A0096',
          value: 'Ability to interpret and understand complex and rapidly evolving concepts.',
          softSkill: true,
        },
        {
          id: 'A0097',
          value:
            'Ability to monitor system operations and react to events in response to triggers and/or observation of trends or unusual activity.',
          softSkill: false,
        },
        {
          id: 'A0098',
          value:
            'Ability to participate as a member of planning teams, coordination groups, and task forces as necessary.',
          softSkill: true,
        },
        {
          id: 'A0099',
          value:
            'Ability to perform network collection tactics, techniques, and procedures to include decryption capabilities/tools.',
          softSkill: false,
        },
        {
          id: 'A0100',
          value:
            'Ability to perform wireless collection procedures to include decryption capabilities/tools.',
          softSkill: false,
        },
        {
          id: 'A0101',
          value: 'Ability to recognize and mitigate cognitive biases which may affect analysis.',
          softSkill: true,
        },
        {
          id: 'A0102',
          value: 'Ability to recognize and mitigate deception in reporting and analysis.',
          softSkill: false,
        },
        {
          id: 'A0103',
          value:
            'Ability to review processed target language materials for accuracy and completeness.',
          softSkill: false,
        },
        {
          id: 'A0104',
          value: 'Ability to select the appropriate implant to achieve operational goals.',
          softSkill: false,
        },
        {
          id: 'A0105',
          value:
            'Ability to tailor technical and planning information to a customer’s level of understanding.',
          softSkill: false,
        },
        {
          id: 'A0106',
          value: 'Ability to think critically.',
          softSkill: true,
        },
        {
          id: 'A0107',
          value: 'Ability to think like threat actors.',
          softSkill: false,
        },
        {
          id: 'A0108',
          value: 'Ability to understand objectives and effects.',
          softSkill: true,
        },
        {
          id: 'A0109',
          value:
            'Ability to utilize multiple intelligence sources across all intelligence disciplines.',
          softSkill: false,
        },
        {
          id: 'A0110',
          value:
            'Ability to monitor advancements in information privacy laws to ensure organizational adaptation and compliance.',
          softSkill: true,
        },
        {
          id: 'A0111',
          value:
            'Ability to work across departments and business units to implement organization’s privacy principles and programs, and align privacy objectives with security objectives.',
          softSkill: true,
        },
        {
          id: 'A0112',
          value:
            'Ability to monitor advancements in information privacy technologies to ensure organizational adaptation and compliance.',
          softSkill: false,
        },
        {
          id: 'A0113',
          value:
            'Ability to determine whether a security incident violates a privacy principle or legal standard requiring specific legal action.',
          softSkill: false,
        },
        {
          id: 'A0114',
          value:
            'Ability to develop or procure curriculum that speaks to the topic at the appropriate level for the target.',
          softSkill: true,
        },
        {
          id: 'A0115',
          value:
            'Ability to work across departments and business units to implement organization’s privacy principles and programs, and align privacy objectives with security objectives.',
          softSkill: true,
        },
        {
          id: 'A0116',
          value:
            'Ability to prioritize and allocate cybersecurity resources correctly and efficiently.',
          softSkill: true,
        },
        {
          id: 'A0117',
          value:
            'Ability to relate strategy, business, and technology in the context of organizational dynamics.',
          softSkill: true,
        },
        {
          id: 'A0118',
          value:
            'Ability to understand technology, management, and leadership issues related to organization processes and problem solving.',
          softSkill: true,
        },
        {
          id: 'A0119',
          value:
            'Ability to understand the basic concepts and issues related to cyber and its organizational impact.',
          softSkill: true,
        },
        {
          id: 'A0120',
          value:
            'Ability to share meaningful insights about the context of an organization’s threat environment that improve its risk management posture.',
          softSkill: true,
        },
        {
          id: 'A0121',
          value: 'Ability to design incident response for cloud service models.',
          softSkill: false,
        },
        {
          id: 'A0122',
          value:
            'Ability to design capabilities to find solutions to less common and more complex system problems.',
          softSkill: false,
        },
        {
          id: 'A0123',
          value:
            'Ability to apply cybersecurity and privacy principles to organizational requirements (relevant to confidentiality, integrity, availability, authentication, non-repudiation).',
          softSkill: false,
        },
        {
          id: 'A0124',
          value: 'Ability to establish and maintain automated security control assessments',
          softSkill: true,
        },
        {
          id: 'A0125',
          value: 'Ability to author a privacy disclosure statement based on current laws.',
          softSkill: true,
        },
        {
          id: 'A0126',
          value:
            'Ability to track the location and configuration of networked devices and software across departments, locations, facilities and, potentially, supporting business functions.',
          softSkill: true,
        },
        {
          id: 'A0127',
          value: 'Ability to deploy continuous monitoring technologies and tools.',
          softSkill: true,
        },
        {
          id: 'A0128',
          value:
            'Ability to apply techniques for detecting host and network-based intrusions using intrusion detection technologies.',
          softSkill: false,
        },
        {
          id: 'A0129',
          value:
            'Ability to ensure information security management processes are integrated with strategic and operational planning processes.',
          softSkill: true,
        },
        {
          id: 'A0130',
          value:
            'Ability to ensure that senior officials within the organization provide information security for the information and systems that support the operations and assets under their control.',
          softSkill: false,
        },
        {
          id: 'A0131',
          value:
            'Ability to ensure the organization has adequately trained personnel to assist in complying with security requirements in legislation, Executive Orders, policies, directives, instructions, standards, and guidelines.',
          softSkill: true,
        },
        {
          id: 'A0132',
          value:
            'Ability to coordinate with senior leadership of an organization to provide a comprehensive, organization-wide, holistic approach for addressing risk—an approach that provides a greater understanding of the integrated operations of the organization.',
          softSkill: true,
        },
        {
          id: 'A0133',
          value:
            'Ability to coordinate with senior leadership of an organization to develop a risk management strategy for the organization providing a strategic view of security-related risks for the organization.',
          softSkill: true,
        },
        {
          id: 'A0134',
          value:
            'Ability to coordinate with senior leadership of an organization to facilitate the sharing of riskrelated information among authorizing officials and other senior leaders within the organization.',
          softSkill: true,
        },
        {
          id: 'A0135',
          value:
            'Ability to coordinate with senior leadership of an organization to provide oversight for all risk management-related activities across the organization to help ensure consistent and effective risk acceptance decisions.',
          softSkill: true,
        },
        {
          id: 'A0136',
          value:
            'Ability to coordinate with senior leadership of an organization to ensure that authorization decisions consider all factors necessary for mission and business success.',
          softSkill: true,
        },
        {
          id: 'A0137',
          value:
            'Ability to coordinate with senior leadership of an organization to provide an organizationwide forum to consider all sources of risk (including aggregated risk) to organizational operations and assets, individuals, other organizations, and the Nation.',
          softSkill: true,
        },
        {
          id: 'A0138',
          value:
            'Ability to coordinate with senior leadership of an organization to promote cooperation and collaboration among authorizing officials to include authorization actions requiring shared responsibility.',
          softSkill: true,
        },
        {
          id: 'A0139',
          value:
            'Ability to coordinate with senior leadership of an organization to ensure that the shared responsibility for supporting organizational mission/business functions using external providers of systems, services, and applications receives the needed visibility and is elevated to the appropriate decision-making authorities.',
          softSkill: true,
        },
        {
          id: 'A0140',
          value:
            'Ability to coordinate with senior leadership of an organization to identify the organizational risk posture based on the aggregated risk from the operation and use of the systems for which the organization is responsible.',
          softSkill: true,
        },
        {
          id: 'A0141',
          value:
            'Ability to work closely with authorizing officials and their designated representatives to help ensure that an organization-wide security program is effectively implemented resulting in adequate security for all organizational systems and environments of operation.',
          softSkill: true,
        },
        {
          id: 'A0142',
          value:
            'Ability to work closely with authorizing officials and their designated representatives to help ensure that security considerations are integrated into programming/planning/budgeting cycles, enterprise architectures, and acquisition/system development life cycles.',
          softSkill: true,
        },
        {
          id: 'A0143',
          value:
            'Ability to work closely with authorizing officials and their designated representatives to help ensure that organizational systems and common controls are covered by approved security plans and possess current authorizations.',
          softSkill: true,
        },
        {
          id: 'A0144',
          value:
            'Ability to work closely with authorizing officials and their designated representatives to help ensure that security-related activities required across the organization are accomplished in an efficient, cost-effective, and timely manner.',
          softSkill: true,
        },
        {
          id: 'A0145',
          value:
            'Ability to work closely with authorizing officials and their designated representatives to help ensure that there is centralized reporting of security-related activities.',
          softSkill: true,
        },
        {
          id: 'A0146',
          value:
            'Ability to establish the rules for appropriate use and protection of the information and retains that responsibility even when the information is shared with or provided to other organizations.',
          softSkill: true,
        },
        {
          id: 'A0147',
          value:
            'Ability to approve security plans, memorandums of agreement or understanding, plans of action and milestones, and determine whether significant changes in the systems or environments of operation require reauthorization.',
          softSkill: true,
        },
        {
          id: 'A0148',
          value:
            'Ability to serve as the primary liaison between the enterprise architect and the systems security engineer and coordinates with system owners, common control providers, and system security officers on the allocation of security controls as system-specific, hybrid, or common controls.',
          softSkill: true,
        },
        {
          id: 'A0149',
          value:
            'Ability, in close coordination with system security officers, advise authorizing officials, chief information officers, senior information security officers, and the senior accountable official for risk management/risk executive (function), on a range of security-related issues (e.g. establishing system boundaries; assessing the severity of weaknesses and deficiencies in the system; plans of action and milestones; risk mitigation approaches; security alerts; and potential adverse effects of identified vulnerabilities).',
          softSkill: true,
        },
        {
          id: 'A0150',
          value: 'Ability to conduct systems security engineering activities (NIST SP 800-16).',
          softSkill: true,
        },
        {
          id: 'A0151',
          value:
            'Ability to capture and refine security requirements and ensure that the requirements are effectively integrated into the component products and systems through purposeful security architecting, design, development, and configuration.',
          softSkill: true,
        },
        {
          id: 'A0152',
          value:
            'Ability to employ best practices when implementing security controls within a system including software engineering methodologies; system and security engineering principles; secure design, secure architecture, and secure coding techniques.',
          softSkill: true,
        },
        {
          id: 'A0153',
          value:
            'Ability to coordinate their security-related activities with security architects, senior information security officers, system owners, common control providers, and system security officers.',
          softSkill: true,
        },
        {
          id: 'A0154',
          value:
            'Ability to conduct a comprehensive assessment of the management, operational, and technical security controls and control enhancements employed within or inherited by a system to determine the effectiveness of the controls (i.e., the extent to which the security controls are implemented correctly, operating as intended, and producing the desired outcome with respect to meeting the security requirements for the system).',
          softSkill: true,
        },
        {
          id: 'A0155',
          value:
            'Ability to provide an assessment of the severity of weaknesses or deficiencies discovered in the system and its environment of operation and recommend corrective actions to address identified vulnerabilities.',
          softSkill: true,
        },
        {
          id: 'A0156',
          value:
            'Ability to prepare the final security assessment report containing the results and findings from the assessment.',
          softSkill: true,
        },
        {
          id: 'A0157',
          value:
            'Ability to assesses a security plan to help ensure that the plan provides a set of security controls for the system that meet the stated security requirements.',
          softSkill: true,
        },
        {
          id: 'A0158',
          value:
            'Ability to ensure that functional and security requirements are appropriately addressed in a contract and that the contractor meets the functional and security requirements as stated in the contract.',
          softSkill: true,
        },
        {
          id: 'A0159',
          value:
            'Ability to interpret the information collected by network tools (e.g. Nslookup, Ping, and Traceroute).',
          softSkill: false,
        },
        {
          id: 'A0160',
          value:
            'Ability to translate, track, and prioritize information needs and intelligence collection requirements across the extended enterprise.',
          softSkill: false,
        },
        {
          id: 'A0161',
          value:
            'Ability to integrate information security requirements into the acquisition process; using applicable baseline security controls as one of the sources for security requirements; ensuring a robust software quality control process; and establishing multiple sources (e.g., delivery routes, for critical system elements).',
          softSkill: true,
        },
        {
          id: 'A0162',
          value:
            'Ability to ensure information system security, acquisition personnel, legal counsel, and other appropriate advisors and stakeholders are participating in decision making from system concept definition/review and are involved in, or approve of, each milestone decision through the entire system life cycle for systems.',
          softSkill: true,
        },
        {
          id: 'A0163',
          value:
            'Ability to interpret Communications Security (COMSEC) terminology, guidelines and procedures.',
          softSkill: true,
        },
        {
          id: 'A0164',
          value:
            'Ability to identify the roles and responsibilities for appointed Communications Security (COMSEC) personnel.',
          softSkill: true,
        },
        {
          id: 'A0165',
          value:
            'Ability to manage Communications Security (COMSEC) material accounting, control and use procedure.',
          softSkill: true,
        },
        {
          id: 'A0166',
          value:
            'Ability to identify types of Communications Security (COMSEC) Incidents and how they’re reported',
          softSkill: false,
        },
        {
          id: 'A0167',
          value:
            'Ability to recognize the importance of auditing Communications Security (COMSEC) material and accounts.',
          softSkill: true,
        },
        {
          id: 'A0168',
          value:
            'Ability to Identify the requirements of In-Process accounting for Communications Security (COMSEC)',
          softSkill: true,
        },
        {
          id: 'A0170',
          value:
            'Ability to identify critical infrastructure systems with information communication technology that were designed without system security considerations.',
          softSkill: false,
        },
        {
          id: 'A0171',
          value: 'Ability to conduct training and education needs assessment.',
          softSkill: true,
        },
        {
          id: 'A0172',
          value:
            'Ability to set up a physical or logical sub-networks that separates an internal local area network (LAN) from other untrusted networks.',
          softSkill: false,
        },
        {
          id: 'A0173',
          value:
            'Ability to recognize that changes to systems or environment can change residual risks in relation to risk appetite.',
          softSkill: true,
        },
        {
          id: 'A0174',
          value:
            'Ability to Find and navigate the dark web using the TOR network to locate markets and forums.',
          softSkill: false,
        },
        {
          id: 'A0175',
          value: 'Ability to examine digital media on multiple operating system platforms.',
          softSkill: false,
        },
        {
          id: 'A0176',
          value:
            'Ability to maintain databases. (i.e., backup, restore, delete data, transaction log files, etc.).',
          softSkill: false,
        },
        {
          id: 'A0177',
          value:
            'Ability to recognize the unique aspects of the Communications Security (COMSEC) environment and hierarchy.',
          softSkill: true,
        },
      ],
    },
  ],
};

export interface WorkRole {
  id: string;
  name: string;
  specialityArea: string;
  category: string;
  description: string;
  tasks: string[];
  knowledge: string[];
  skills: string[];
  abilities: string[];
}

export const NiceWorkRoles: WorkRole[] = [
  {
    id: 'SP-RSK-001',
    name: 'Authorizing Official/Designating Representative',
    specialityArea: 'Risk Management (RSK)',
    category: 'Securely Provision (SP)',
    description:
      'Senior official or executive with the authority to formally assume responsibility for operating an information system at an acceptable level of risk to organizational operations (including mission, functions, image, or reputation), organizational assets, individuals, other organizations, and the Nation (CNSSI 4009).',
    tasks: ['T0145', 'T0221', 'T0371', 'T0495'],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0013',
      'K0019',
      'K0027',
      'K0028',
      'K0037',
      'K0038',
      'K0040',
      'K0044',
      'K0048',
      'K0049',
      'K0054',
      'K0059',
      'K0070',
      'K0084',
      'K0089',
      'K0101',
      'K0126',
      'K0146',
      'K0168',
      'K0169',
      'K0170',
      'K0179',
      'K0199',
      'K0203',
      'K0260',
      'K0261',
      'K0262',
      'K0267',
      'K0295',
      'K0322',
      'K0342',
      'K0622',
      'K0624',
    ],
    skills: ['S0034', 'S0367'],
    abilities: [
      'A0028',
      'A0033',
      'A0077',
      'A0090',
      'A0094',
      'A0111',
      'A0117',
      'A0118',
      'A0119',
      'A0123',
      'A0170',
    ],
  },
  {
    id: 'SP-RSK-002',
    name: 'Security Control Assessor',
    specialityArea: 'Risk Management (RSK)',
    category: 'Securely Provision (SP)',
    description:
      'Conducts independent comprehensive assessments of the management, operational, and technical security controls and control enhancements employed within or inherited by an information technology (IT) system to determine the overall effectiveness of the controls (as defined in NIST SP 800-37).',
    tasks: [
      'T0145',
      'T0177',
      'T0178',
      'T0181',
      'T0184',
      'T0205',
      'T0221',
      'T0243',
      'T0244',
      'T0251',
      'T0255',
      'T0264',
      'T0265',
      'T0268',
      'T0272',
      'T0275',
      'T0277',
      'T0309',
      'T0344',
      'T0371',
      'T0495',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0007',
      'K0008',
      'K0009',
      'K0010',
      'K0011',
      'K0013',
      'K0018',
      'K0019',
      'K0021',
      'K0024',
      'K0026',
      'K0027',
      'K0028',
      'K0029',
      'K0037',
      'K0038',
      'K0040',
      'K0044',
      'K0048',
      'K0049',
      'K0054',
      'K0056',
      'K0059',
      'K0070',
      'K0084',
      'K0089',
      'K0098',
      'K0100',
      'K0101',
      'K0126',
      'K0146',
      'K0168',
      'K0169',
      'K0170',
      'K0179',
      'K0199',
      'K0203',
      'K0260',
      'K0261',
      'K0262',
      'K0267',
      'K0287',
      'K0322',
      'K0342',
      'K0622',
      'K0624',
    ],
    skills: [
      'S0001',
      'S0006',
      'S0027',
      'S0034',
      'S0038',
      'S0073',
      'S0078',
      'S0097',
      'S0100',
      'S0110',
      'S0111',
      'S0112',
      'S0115',
      'S0120',
      'S0124',
      'S0128',
      'S0134',
      'S0135',
      'S0136',
      'S0137',
      'S0138',
      'S0141',
      'S0145',
      'S0147',
      'S0171',
      'S0172',
      'S0173',
      'S0174',
      'S0175',
      'S0176',
      'S0177',
      'S0184',
      'S0232',
      'S0233',
      'S0234',
      'S0235',
      'S0236',
      'S0237',
      'S0238',
      'S0239',
      'S0240',
      'S0241',
      'S0242',
      'S0243',
      'S0244',
      'S0248',
      'S0249',
      'S0250',
      'S0251',
      'S0252',
      'S0254',
      'S0271',
      'S0273',
      'S0278',
      'S0279',
      'S0280',
      'S0281',
      'S0296',
      'S0304',
      'S0305',
      'S0306',
      'S0307',
      'S0325',
      'S0329',
      'S0332',
      'S0367',
      'S0370',
      'S0374',
    ],
    abilities: [
      'A0001',
      'A0011',
      'A0012',
      'A0013',
      'A0014',
      'A0015',
      'A0016',
      'A0018',
      'A0019',
      'A0023',
      'A0026',
      'A0030',
      'A0035',
      'A0036',
      'A0040',
      'A0056',
      'A0069',
      'A0070',
      'A0082',
      'A0083',
      'A0084',
      'A0085',
      'A0086',
      'A0087',
      'A0088',
      'A0089',
      'A0090',
      'A0091',
      'A0092',
      'A0093',
      'A0094',
      'A0095',
      'A0096',
      'A0098',
      'A0101',
      'A0106',
      'A0108',
      'A0109',
      'A0111',
      'A0112',
      'A0114',
      'A0115',
      'A0116',
      'A0117',
      'A0118',
      'A0119',
      'A0123',
      'A0170',
    ],
  },
  {
    id: 'SP-DEV-001',
    name: 'Software Developer',
    specialityArea: 'Software Development (DEV)',
    category: 'Securely Provision (SP)',
    description:
      'Develops, creates, maintains, and writes/codes new (or modifies existing) computer applications, software, or specialized utility programs.',
    tasks: [
      'T0009',
      'T0011',
      'T0013',
      'T0014',
      'T0022',
      'T0026',
      'T0034',
      'T0040',
      'T0046',
      'T0057',
      'T0077',
      'T0100',
      'T0111',
      'T0117',
      'T0118',
      'T0171',
      'T0176',
      'T0181',
      'T0189',
      'T0217',
      'T0228',
      'T0236',
      'T0267',
      'T0303',
      'T0311',
      'T0324',
      'T0337',
      'T0416',
      'T0417',
      'T0436',
      'T0455',
      'T0500',
      'T0553',
      'T0554',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0014',
      'K0016',
      'K0027',
      'K0028',
      'K0039',
      'K0044',
      'K0050',
      'K0051',
      'K0060',
      'K0066',
      'K0068',
      'K0070',
      'K0073',
      'K0079',
      'K0080',
      'K0081',
      'K0082',
      'K0084',
      'K0086',
      'K0105',
      'K0139',
      'K0140',
      'K0152',
      'K0153',
      'K0154',
      'K0170',
      'K0179',
      'K0199',
      'K0202',
      'K0260',
      'K0261',
      'K0262',
      'K0263',
      'K0322',
      'K0332',
      'K0342',
      'K0343',
      'K0624',
    ],
    skills: [
      'S0001',
      'S0014',
      'S0017',
      'S0019',
      'S0022',
      'S0031',
      'S0034',
      'S0060',
      'S0135',
      'S0138',
      'S0149',
      'S0174',
      'S0175',
      'S0367',
    ],
    abilities: ['A0007', 'A0021', 'A0047', 'A0123', 'A0170'],
  },
  {
    id: 'SP-DEV-002',
    name: 'Secure Software Assessor',
    specialityArea: 'Software Development (DEV)',
    category: 'Securely Provision (SP)',
    description:
      'Analyzes the security of new or existing computer applications, software, or specialized utility programs and provides actionable results.',
    tasks: [
      'T0013',
      'T0014',
      'T0022',
      'T0038',
      'T0040',
      'T0100',
      'T0111',
      'T0117',
      'T0118',
      'T0171',
      'T0181',
      'T0217',
      'T0228',
      'T0236',
      'T0266',
      'T0311',
      'T0324',
      'T0337',
      'T0424',
      'T0428',
      'T0436',
      'T0456',
      'T0457',
      'T0516',
      'T0554',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0014',
      'K0016',
      'K0027',
      'K0028',
      'K0039',
      'K0044',
      'K0050',
      'K0051',
      'K0060',
      'K0066',
      'K0068',
      'K0070',
      'K0073',
      'K0079',
      'K0080',
      'K0081',
      'K0082',
      'K0084',
      'K0086',
      'K0105',
      'K0139',
      'K0140',
      'K0152',
      'K0153',
      'K0154',
      'K0170',
      'K0178',
      'K0179',
      'K0199',
      'K0202',
      'K0260',
      'K0261',
      'K0262',
      'K0263',
      'K0322',
      'K0342',
      'K0343',
      'K0624',
    ],
    skills: [
      'S0001',
      'S0022',
      'S0031',
      'S0034',
      'S0083',
      'S0135',
      'S0138',
      'S0174',
      'S0175',
      'S0367',
    ],
    abilities: ['A0021', 'A0123', 'A0170'],
  },
  {
    id: 'SP-ARC-001',
    name: 'Enterprise Architect',
    specialityArea: 'Systems Architecture (ARC)',
    category: 'Securely Provision (SP)',
    description:
      'Develops and maintains business, systems, and information processes to support enterprise mission needs; develops information technology (IT) rules and requirements that describe baseline and target architectures.',
    tasks: [
      'T0051',
      'T0084',
      'T0090',
      'T0108',
      'T0196',
      'T0205',
      'T0307',
      'T0314',
      'T0328',
      'T0338',
      'T0427',
      'T0440',
      'T0448',
      'T0473',
      'T0517',
      'T0521',
      'T0542',
      'T0555',
      'T0557',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0024',
      'K0027',
      'K0028',
      'K0030',
      'K0035',
      'K0037',
      'K0043',
      'K0044',
      'K0052',
      'K0056',
      'K0060',
      'K0061',
      'K0063',
      'K0074',
      'K0075',
      'K0082',
      'K0091',
      'K0093',
      'K0102',
      'K0170',
      'K0179',
      'K0180',
      'K0198',
      'K0200',
      'K0203',
      'K0207',
      'K0211',
      'K0212',
      'K0214',
      'K0227',
      'K0240',
      'K0264',
      'K0275',
      'K0286',
      'K0287',
      'K0291',
      'K0293',
      'K0299',
      'K0322',
      'K0323',
      'K0325',
      'K0326',
      'K0332',
      'K0333',
      'K0487',
      'K0516',
    ],
    skills: ['S0005', 'S0024', 'S0027', 'S0050', 'S0060', 'S0122', 'S0367', 'S0374'],
    abilities: ['A0008', 'A0015', 'A0027', 'A0038', 'A0051', 'A0060', 'A0123', 'A0170', 'A0172'],
  },
  {
    id: 'SP-ARC-002',
    name: 'Security Architect',
    specialityArea: 'Systems Architecture (ARC)',
    category: 'Securely Provision (SP)',
    description:
      'Ensures that the stakeholder security requirements necessary to protect the organization’s mission and business processes are adequately addressed in all aspects of enterprise architecture including reference models, segment and solution architectures, and the resulting systems supporting those missions and business processes.',
    tasks: [
      'T0050',
      'T0051',
      'T0071',
      'T0082',
      'T0084',
      'T0090',
      'T0108',
      'T0177',
      'T0196',
      'T0203',
      'T0205',
      'T0268',
      'T0307',
      'T0314',
      'T0328',
      'T0338',
      'T0427',
      'T0448',
      'T0473',
      'T0484',
      'T0542',
      'T0556',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0007',
      'K0008',
      'K0009',
      'K0010',
      'K0011',
      'K0012',
      'K0013',
      'K0015',
      'K0018',
      'K0019',
      'K0024',
      'K0026',
      'K0027',
      'K0030',
      'K0035',
      'K0036',
      'K0037',
      'K0043',
      'K0044',
      'K0052',
      'K0055',
      'K0056',
      'K0057',
      'K0059',
      'K0060',
      'K0061',
      'K0063',
      'K0071',
      'K0074',
      'K0082',
      'K0091',
      'K0092',
      'K0093',
      'K0102',
      'K0170',
      'K0180',
      'K0198',
      'K0200',
      'K0202',
      'K0211',
      'K0212',
      'K0214',
      'K0227',
      'K0240',
      'K0260',
      'K0261',
      'K0262',
      'K0264',
      'K0275',
      'K0277',
      'K0286',
      'K0287',
      'K0291',
      'K0293',
      'K0320',
      'K0322',
      'K0323',
      'K0325',
      'K0326',
      'K0332',
      'K0333',
      'K0336',
      'K0374',
      'K0565',
    ],
    skills: [
      'S0005',
      'S0022',
      'S0024',
      'S0027',
      'S0050',
      'S0059',
      'S0061',
      'S0076',
      'S0116',
      'S0122',
      'S0138',
      'S0139',
      'S0152',
      'S0168',
      'S0170',
      'S0367',
      'S0374',
    ],
    abilities: [
      'A0008',
      'A0014',
      'A0015',
      'A0027',
      'A0038',
      'A0048',
      'A0049',
      'A0050',
      'A0061',
      'A0123',
      'A0148',
      'A0149',
      'A0170',
      'A0172',
    ],
  },
  {
    id: 'SP-TRD-001',
    name: 'Research & Development Specialist',
    specialityArea: 'Technology R&D (TRD)',
    category: 'Securely Provision (SP)',
    description:
      'Conducts software and systems engineering and software systems research to develop new capabilities, ensuring cybersecurity is fully integrated. Conducts comprehensive technology research to evaluate potential vulnerabilities in cyberspace systems.',
    tasks: [
      'T0064',
      'T0249',
      'T0250',
      'T0283',
      'T0284',
      'T0327',
      'T0329',
      'T0409',
      'T0410',
      'T0411',
      'T0413',
      'T0547',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0009',
      'K0019',
      'K0059',
      'K0090',
      'K0126',
      'K0169',
      'K0170',
      'K0171',
      'K0172',
      'K0174',
      'K0175',
      'K0176',
      'K0179',
      'K0202',
      'K0209',
      'K0267',
      'K0268',
      'K0269',
      'K0271',
      'K0272',
      'K0288',
      'K0296',
      'K0310',
      'K0314',
      'K0321',
      'K0342',
      'K0499',
    ],
    skills: ['S0005', 'S0017', 'S0072', 'S0140', 'S0148', 'S0172'],
    abilities: ['A0001', 'A0018', 'A0019', 'A0170'],
  },
  {
    id: 'SP-SRP-001',
    name: 'Systems Requirements Planner',
    specialityArea: 'Systems Requirements Planning (SRP)',
    category: 'Securely Provision (SP)',
    description:
      'Consults with customers to evaluate functional requirements and translate functional requirements into technical solutions.',
    tasks: [
      'T0033',
      'T0039',
      'T0045',
      'T0052',
      'T0062',
      'T0127',
      'T0156',
      'T0174',
      'T0191',
      'T0235',
      'T0273',
      'T0300',
      'T0313',
      'T0325',
      'T0334',
      'T0454',
      'T0463',
      'T0497',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0008',
      'K0012',
      'K0018',
      'K0019',
      'K0032',
      'K0035',
      'K0038',
      'K0043',
      'K0044',
      'K0045',
      'K0047',
      'K0055',
      'K0056',
      'K0059',
      'K0060',
      'K0061',
      'K0063',
      'K0066',
      'K0067',
      'K0073',
      'K0074',
      'K0086',
      'K0087',
      'K0090',
      'K0091',
      'K0093',
      'K0101',
      'K0102',
      'K0126',
      'K0163',
      'K0164',
      'K0168',
      'K0169',
      'K0170',
      'K0180',
      'K0200',
      'K0267',
      'K0287',
      'K0325',
      'K0332',
      'K0333',
      'K0622',
    ],
    skills: ['S0005', 'S0006', 'S0008', 'S0010', 'S0050', 'S0134', 'S0367'],
    abilities: ['A0064', 'A0123', 'A0170'],
  },
  {
    id: 'SP-TST-001',
    name: 'System Testing and Evaluation Specialist',
    specialityArea: 'Test and Evaluation (TST)',
    category: 'Securely Provision (SP)',
    description:
      'Plans, prepares, and executes tests of systems to evaluate results against specifications and requirements as well as analyze/report test results.',
    tasks: [
      'T0058',
      'T0080',
      'T0125',
      'T0143',
      'T0257',
      'T0274',
      'T0393',
      'T0426',
      'T0511',
      'T0512',
      'T0513',
      'T0539',
      'T0540',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0027',
      'K0028',
      'K0037',
      'K0044',
      'K0057',
      'K0088',
      'K0091',
      'K0102',
      'K0126',
      'K0139',
      'K0169',
      'K0170',
      'K0179',
      'K0199',
      'K0203',
      'K0212',
      'K0250',
      'K0260',
      'K0261',
      'K0262',
      'K0287',
      'K0332',
    ],
    skills: [
      'S0015',
      'S0021',
      'S0026',
      'S0030',
      'S0048',
      'S0060',
      'S0061',
      'S0082',
      'S0104',
      'S0107',
      'S0110',
      'S0112',
      'S0115',
      'S0117',
      'S0367',
    ],
    abilities: ['A0026', 'A0030', 'A0040', 'A0123'],
  },
  {
    id: 'SP-SYS-001',
    name: 'Information Systems Security Developer',
    specialityArea: 'Systems Development (SYS)',
    category: 'Securely Provision (SP)',
    description:
      'Designs, develops, tests, and evaluates information system security throughout the systems development life cycle.',
    tasks: [
      'T0012',
      'T0015',
      'T0018',
      'T0019',
      'T0021',
      'T0032',
      'T0053',
      'T0055',
      'T0056',
      'T0061',
      'T0069',
      'T0070',
      'T0076',
      'T0078',
      'T0105',
      'T0107',
      'T0109',
      'T0119',
      'T0122',
      'T0124',
      'T0181',
      'T0201',
      'T0205',
      'T0228',
      'T0231',
      'T0242',
      'T0269',
      'T0270',
      'T0271',
      'T0272',
      'T0304',
      'T0326',
      'T0359',
      'T0446',
      'T0449',
      'T0466',
      'T0509',
      'T0518',
      'T0527',
      'T0541',
      'T0544',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0015',
      'K0018',
      'K0024',
      'K0027',
      'K0028',
      'K0030',
      'K0032',
      'K0035',
      'K0036',
      'K0044',
      'K0045',
      'K0049',
      'K0050',
      'K0052',
      'K0055',
      'K0056',
      'K0060',
      'K0061',
      'K0063',
      'K0065',
      'K0066',
      'K0067',
      'K0073',
      'K0081',
      'K0082',
      'K0084',
      'K0086',
      'K0087',
      'K0090',
      'K0091',
      'K0093',
      'K0102',
      'K0126',
      'K0139',
      'K0169',
      'K0170',
      'K0179',
      'K0180',
      'K0200',
      'K0203',
      'K0260',
      'K0261',
      'K0262',
      'K0276',
      'K0287',
      'K0297',
      'K0308',
      'K0322',
      'K0325',
      'K0332',
      'K0333',
      'K0336',
    ],
    skills: [
      'S0001',
      'S0022',
      'S0023',
      'S0024',
      'S0031',
      'S0034',
      'S0036',
      'S0085',
      'S0145',
      'S0160',
      'S0367',
    ],
    abilities: [
      'A0001',
      'A0008',
      'A0012',
      'A0013',
      'A0015',
      'A0019',
      'A0026',
      'A0040',
      'A0048',
      'A0049',
      'A0050',
      'A0056',
      'A0061',
      'A0074',
      'A0089',
      'A0098',
      'A0108',
      'A0119',
      'A0123',
      'A0170',
    ],
  },
  {
    id: 'SP-SYS-002',
    name: 'Systems Developer',
    specialityArea: 'Systems Development (SYS)',
    category: 'Securely Provision (SP)',
    description:
      'Designs, develops, tests, and evaluates information systems throughout the systems development life cycle.',
    tasks: [
      'T0012',
      'T0021',
      'T0053',
      'T0056',
      'T0061',
      'T0067',
      'T0070',
      'T0107',
      'T0109',
      'T0119',
      'T0181',
      'T0201',
      'T0205',
      'T0228',
      'T0242',
      'T0304',
      'T0326',
      'T0350',
      'T0358',
      'T0359',
      'T0378',
      'T0406',
      'T0447',
      'T0449',
      'T0464',
      'T0466',
      'T0480',
      'T0488',
      'T0518',
      'T0528',
      'T0538',
      'T0541',
      'T0544',
      'T0558',
      'T0559',
      'T0560',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0015',
      'K0018',
      'K0024',
      'K0027',
      'K0028',
      'K0030',
      'K0032',
      'K0035',
      'K0036',
      'K0044',
      'K0045',
      'K0049',
      'K0050',
      'K0052',
      'K0055',
      'K0056',
      'K0060',
      'K0061',
      'K0063',
      'K0065',
      'K0066',
      'K0067',
      'K0073',
      'K0081',
      'K0082',
      'K0084',
      'K0086',
      'K0087',
      'K0090',
      'K0091',
      'K0093',
      'K0102',
      'K0126',
      'K0139',
      'K0169',
      'K0170',
      'K0179',
      'K0180',
      'K0200',
      'K0203',
      'K0207',
      'K0212',
      'K0227',
      'K0260',
      'K0261',
      'K0262',
      'K0276',
      'K0287',
      'K0297',
      'K0308',
      'K0322',
      'K0325',
      'K0332',
      'K0333',
      'K0336',
    ],
    skills: [
      'S0018',
      'S0022',
      'S0023',
      'S0024',
      'S0025',
      'S0031',
      'S0034',
      'S0036',
      'S0060',
      'S0085',
      'S0097',
      'S0136',
      'S0145',
      'S0146',
      'S0160',
      'S0367',
    ],
    abilities: ['A0123', 'A0170'],
  },
  {
    id: 'OM-DTA-001',
    name: 'Database Administrator',
    specialityArea: 'Data Administration (DTA)',
    category: 'Operate and Maintain (OM)',
    description:
      'Administers databases and/or data management systems that allow for the secure storage, query, protection, and utilization of data.',
    tasks: [
      'T0008',
      'T0137',
      'T0139',
      'T0140',
      'T0146',
      'T0152',
      'T0162',
      'T0210',
      'T0305',
      'T0306',
      'T0330',
      'T0422',
      'T0459',
      'T0490',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0020',
      'K0021',
      'K0022',
      'K0023',
      'K0025',
      'K0031',
      'K0056',
      'K0060',
      'K0065',
      'K0069',
      'K0083',
      'K0097',
      'K0197',
      'K0260',
      'K0261',
      'K0262',
      'K0277',
      'K0278',
      'K0287',
      'K0420',
    ],
    skills: ['S0002', 'S0013', 'S0037', 'S0042', 'S0045'],
    abilities: ['A0176'],
  },
  {
    id: 'OM-DTA-002',
    name: 'Data Analyst',
    specialityArea: 'Data Administration (DTA)',
    category: 'Operate and Maintain (OM)',
    description:
      'Examines data from multiple disparate sources with the goal of providing security and privacy insight. Designs and implements custom algorithms, workflow processes, and layouts for complex, enterprise-scale data sets used for modeling, data mining, and research purposes.',
    tasks: [
      'T0007',
      'T0008',
      'T0068',
      'T0146',
      'T0195',
      'T0210',
      'T0342',
      'T0347',
      'T0349',
      'T0351',
      'T0353',
      'T0361',
      'T0366',
      'T0381',
      'T0382',
      'T0383',
      'T0385',
      'T0392',
      'T0402',
      'T0403',
      'T0404',
      'T0405',
      'T0460',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0015',
      'K0016',
      'K0020',
      'K0022',
      'K0023',
      'K0025',
      'K0031',
      'K0051',
      'K0052',
      'K0056',
      'K0060',
      'K0065',
      'K0068',
      'K0069',
      'K0083',
      'K0095',
      'K0129',
      'K0139',
      'K0140',
      'K0193',
      'K0197',
      'K0229',
      'K0236',
      'K0238',
      'K0325',
      'K0420',
    ],
    skills: [
      'S0013',
      'S0017',
      'S0028',
      'S0029',
      'S0037',
      'S0060',
      'S0088',
      'S0089',
      'S0094',
      'S0095',
      'S0103',
      'S0106',
      'S0109',
      'S0113',
      'S0114',
      'S0118',
      'S0119',
      'S0123',
      'S0125',
      'S0126',
      'S0127',
      'S0129',
      'S0130',
      'S0160',
      'S0202',
      'S0369',
    ],
    abilities: ['A0029', 'A0035', 'A0036', 'A0041', 'A0066'],
  },
  {
    id: 'OM-KMG-001',
    name: 'Knowledge Manager',
    specialityArea: 'Knowledge Management (KMG)',
    category: 'Operate and Maintain (OM)',
    description:
      'Responsible for the management and administration of processes and tools that enable the organization to identify, document, and access intellectual capital and information content.',
    tasks: ['T0037', 'T0060', 'T0154', 'T0185', 'T0209', 'T0339', 'T0421', 'T0452', 'T0524'],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0013',
      'K0094',
      'K0095',
      'K0096',
      'K0146',
      'K0194',
      'K0195',
      'K0228',
      'K0260',
      'K0261',
      'K0262',
      'K0283',
      'K0287',
      'K0315',
      'K0338',
      'K0420',
    ],
    skills: ['S0011', 'S0012', 'S0049', 'S0055'],
    abilities: ['A0002'],
  },
  {
    id: 'OM-STS-001',
    name: 'Technical Support Specialist',
    specialityArea: 'Customer Service and Technical Support (STS)',
    category: 'Operate and Maintain (OM)',
    description:
      'Provides technical support to customers who need assistance utilizing client-level hardware and software in accordance with established or approved organizational process components (i.e., Master Incident Management Plan, when applicable).',
    tasks: [
      'T0125',
      'T0237',
      'T0308',
      'T0315',
      'T0331',
      'T0468',
      'T0482',
      'T0491',
      'T0494',
      'T0496',
      'T0502',
      'T0530',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0053',
      'K0088',
      'K0109',
      'K0114',
      'K0116',
      'K0194',
      'K0224',
      'K0237',
      'K0242',
      'K0247',
      'K0260',
      'K0261',
      'K0262',
      'K0287',
      'K0292',
      'K0294',
      'K0302',
      'K0317',
      'K0330',
    ],
    skills: ['S0039', 'S0058', 'S0142', 'S0159', 'S0365'],
    abilities: ['A0025', 'A0034', 'A0122'],
  },
  {
    id: 'OM-NET-001',
    name: 'Network Operations Specialist',
    specialityArea: 'Network Services (NET)',
    category: 'Operate and Maintain (OM)',
    description:
      'Plans, implements, and operates network services/systems, to include hardware and virtual environments.',
    tasks: [
      'T0035',
      'T0065',
      'T0081',
      'T0121',
      'T0125',
      'T0126',
      'T0129',
      'T0153',
      'T0160',
      'T0200',
      'T0232',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0010',
      'K0011',
      'K0029',
      'K0038',
      'K0049',
      'K0050',
      'K0053',
      'K0061',
      'K0071',
      'K0076',
      'K0093',
      'K0104',
      'K0108',
      'K0111',
      'K0113',
      'K0135',
      'K0136',
      'K0137',
      'K0138',
      'K0159',
      'K0160',
      'K0179',
      'K0180',
      'K0200',
      'K0201',
      'K0203',
      'K0260',
      'K0261',
      'K0262',
      'K0274',
      'K0287',
      'K0332',
      'K0622',
    ],
    skills: [
      'S0004',
      'S0035',
      'S0040',
      'S0041',
      'S0056',
      'S0077',
      'S0079',
      'S0084',
      'S0150',
      'S0162',
      'S0170',
    ],
    abilities: ['A0052', 'A0055', 'A0058', 'A0059', 'A0062', 'A0063', 'A0065', 'A0159'],
  },
  {
    id: 'OM-ADM-001',
    name: 'System Administrator',
    specialityArea: 'Systems Administration (ADM)',
    category: 'Operate and Maintain (OM)',
    description:
      'Responsible for setting up and maintaining a system or specific components of a system (e.g. for example, installing, configuring, and updating hardware and software; establishing and managing user accounts; overseeing or conducting backup and recovery tasks; implementing operational and technical security controls; and adhering to organizational security policies and procedures).',
    tasks: [
      'T0029',
      'T0054',
      'T0063',
      'T0136',
      'T0144',
      'T0186',
      'T0207',
      'T0418',
      'T0431',
      'T0435',
      'T0458',
      'T0461',
      'T0498',
      'T0501',
      'T0507',
      'T0514',
      'T0515',
      'T0531',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0049',
      'K0050',
      'K0053',
      'K0064',
      'K0077',
      'K0088',
      'K0100',
      'K0103',
      'K0104',
      'K0117',
      'K0130',
      'K0158',
      'K0167',
      'K0179',
      'K0260',
      'K0261',
      'K0262',
      'K0274',
      'K0280',
      'K0289',
      'K0318',
      'K0332',
      'K0346',
    ],
    skills: [
      'S0016',
      'S0033',
      'S0043',
      'S0073',
      'S0076',
      'S0111',
      'S0143',
      'S0144',
      'S0151',
      'S0153',
      'S0154',
      'S0155',
      'S0157',
      'S0158',
    ],
    abilities: ['A0025', 'A0027', 'A0034', 'A0055', 'A0062', 'A0074', 'A0088', 'A0123', 'A0124'],
  },
  {
    id: 'OM-ANA-001',
    name: 'Systems Security Analyst',
    specialityArea: 'Systems Analysis (ANA)',
    category: 'Operate and Maintain (OM)',
    description:
      'Responsible for the analysis and development of the integration, testing, operations, and maintenance of systems security.',
    tasks: [
      'T0015',
      'T0016',
      'T0017',
      'T0085',
      'T0086',
      'T0088',
      'T0123',
      'T0128',
      'T0169',
      'T0177',
      'T0187',
      'T0194',
      'T0202',
      'T0205',
      'T0243',
      'T0309',
      'T0344',
      'T0462',
      'T0469',
      'T0470',
      'T0475',
      'T0477',
      'T0485',
      'T0489',
      'T0492',
      'T0499',
      'T0504',
      'T0508',
      'T0526',
      'T0545',
      'T0548',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0015',
      'K0018',
      'K0019',
      'K0024',
      'K0035',
      'K0036',
      'K0040',
      'K0044',
      'K0049',
      'K0052',
      'K0056',
      'K0060',
      'K0061',
      'K0063',
      'K0075',
      'K0082',
      'K0093',
      'K0102',
      'K0179',
      'K0180',
      'K0200',
      'K0203',
      'K0227',
      'K0260',
      'K0261',
      'K0262',
      'K0263',
      'K0266',
      'K0267',
      'K0275',
      'K0276',
      'K0281',
      'K0284',
      'K0285',
      'K0287',
      'K0290',
      'K0297',
      'K0322',
      'K0333',
      'K0339',
    ],
    skills: ['S0024', 'S0027', 'S0031', 'S0036', 'S0060', 'S0141', 'S0147', 'S0167', 'S0367'],
    abilities: ['A0015', 'A0123'],
  },
  {
    id: 'OV-LGA-001',
    name: 'Cyber Legal Advisor',
    specialityArea: 'Legal Advice and Advocacy (LGA)',
    category: 'Oversee and Govern (OV)',
    description:
      'Provides legal advice and recommendations on relevant topics related to cyber law.',
    tasks: [
      'T0006',
      'T0098',
      'T0102',
      'T0131',
      'T0220',
      'T0419',
      'T0434',
      'T0465',
      'T0474',
      'T0476',
      'T0478',
      'T0487',
      'T0522',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0017',
      'K0059',
      'K0107',
      'K0157',
      'K0261',
      'K0262',
      'K0267',
      'K0312',
      'K0316',
      'K0341',
      'K0615',
    ],
    skills: ['S0356'],
    abilities: ['A0046'],
  },
  {
    id: 'OV-LGA-002',
    name: 'Privacy Officer/Privacy Compliance Manager',
    specialityArea: 'Legal Advice and Advocacy (LGA)',
    category: 'Oversee and Govern (OV)',
    description:
      'Develops and oversees privacy compliance program and privacy program staff, supporting privacy compliance, governance/policy, and incident response needs of privacy and security executives and their teams.',
    tasks: [
      'T0003',
      'T0004',
      'T0029',
      'T0032',
      'T0066',
      'T0098',
      'T0099',
      'T0131',
      'T0133',
      'T0188',
      'T0381',
      'T0384',
      'T0478',
      'T0861',
      'T0862',
      'T0863',
      'T0864',
      'T0865',
      'T0866',
      'T0867',
      'T0868',
      'T0869',
      'T0870',
      'T0871',
      'T0872',
      'T0873',
      'T0874',
      'T0875',
      'T0876',
      'T0877',
      'T0878',
      'T0879',
      'T0880',
      'T0881',
      'T0882',
      'T0883',
      'T0884',
      'T0885',
      'T0886',
      'T0887',
      'T0888',
      'T0889',
      'T0890',
      'T0891',
      'T0892',
      'T0893',
      'T0894',
      'T0895',
      'T0896',
      'T0897',
      'T0898',
      'T0899',
      'T0900',
      'T0901',
      'T0902',
      'T0903',
      'T0904',
      'T0905',
      'T0906',
      'T0907',
      'T0908',
      'T0909',
      'T0910',
      'T0911',
      'T0912',
      'T0913',
      'T0914',
      'T0915',
      'T0916',
      'T0917',
      'T0918',
      'T0919',
      'T0930',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0008',
      'K0066',
      'K0168',
      'K0612',
      'K0613',
      'K0614',
      'K0615',
    ],
    skills: ['S0354', 'S0355', 'S0356'],
    abilities: [
      'A0024',
      'A0033',
      'A0034',
      'A0104',
      'A0105',
      'A0110',
      'A0111',
      'A0112',
      'A0113',
      'A0114',
      'A0115',
      'A0125',
    ],
  },
  {
    id: 'OV-TEA-001',
    name: 'Cyber Instructional Curriculum Developer',
    specialityArea: 'Training, Education, and Awareness (TEA)',
    category: 'Oversee and Govern (OV)',
    description:
      'Develops, plans, coordinates, and evaluates cyber training/education courses, methods, and techniques based on instructional needs.',
    tasks: [
      'T0247',
      'T0248',
      'T0249',
      'T0345',
      'T0352',
      'T0357',
      'T0365',
      'T0367',
      'T0380',
      'T0437',
      'T0442',
      'T0450',
      'T0451',
      'T0534',
      'T0536',
      'T0926',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0059',
      'K0124',
      'K0146',
      'K0147',
      'K0204',
      'K0208',
      'K0213',
      'K0216',
      'K0217',
      'K0220',
      'K0239',
      'K0243',
      'K0245',
      'K0246',
      'K0250',
      'K0252',
      'K0287',
      'K0628',
    ],
    skills: ['S0064', 'S0066', 'S0070', 'S0102', 'S0166', 'S0296'],
    abilities: [
      'A0004',
      'A0013',
      'A0015',
      'A0018',
      'A0019',
      'A0022',
      'A0024',
      'A0032',
      'A0054',
      'A0055',
      'A0057',
      'A0058',
      'A0063',
      'A0070',
      'A0083',
      'A0089',
      'A0105',
      'A0106',
      'A0112',
      'A0114',
      'A0118',
      'A0119',
      'A0171',
    ],
  },
  {
    id: 'OV-TEA-002',
    name: 'Cyber Instructor',
    specialityArea: 'Training, Education, and Awareness (TEA)',
    category: 'Oversee and Govern (OV)',
    description: 'Develops and conducts training or education of personnel within cyber domain.',
    tasks: [
      'T0030',
      'T0073',
      'T0101',
      'T0224',
      'T0230',
      'T0247',
      'T0316',
      'T0317',
      'T0318',
      'T0319',
      'T0320',
      'T0321',
      'T0322',
      'T0323',
      'T0352',
      'T0365',
      'T0367',
      'T0381',
      'T0382',
      'T0395',
      'T0443',
      'T0444',
      'T0450',
      'T0451',
      'T0467',
      'T0519',
      'T0520',
      'T0535',
      'T0536',
      'T0926',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0007',
      'K0059',
      'K0115',
      'K0124',
      'K0130',
      'K0146',
      'K0147',
      'K0204',
      'K0208',
      'K0213',
      'K0215',
      'K0216',
      'K0217',
      'K0218',
      'K0220',
      'K0226',
      'K0239',
      'K0245',
      'K0246',
      'K0250',
      'K0252',
      'K0287',
      'K0313',
      'K0319',
      'K0628',
    ],
    skills: [
      'S0001',
      'S0004',
      'S0006',
      'S0051',
      'S0052',
      'S0053',
      'S0055',
      'S0056',
      'S0057',
      'S0060',
      'S0064',
      'S0070',
      'S0073',
      'S0075',
      'S0076',
      'S0081',
      'S0084',
      'S0097',
      'S0098',
      'S0100',
      'S0101',
      'S0121',
      'S0131',
      'S0156',
      'S0184',
      'S0270',
      'S0271',
      'S0281',
      'S0293',
      'S0301',
      'S0356',
      'S0358',
    ],
    abilities: [
      'A0006',
      'A0011',
      'A0012',
      'A0013',
      'A0014',
      'A0015',
      'A0016',
      'A0017',
      'A0018',
      'A0019',
      'A0020',
      'A0022',
      'A0023',
      'A0024',
      'A0032',
      'A0055',
      'A0057',
      'A0058',
      'A0063',
      'A0066',
      'A0070',
      'A0083',
      'A0089',
      'A0105',
      'A0106',
      'A0112',
      'A0114',
      'A0118',
      'A0119',
      'A0171',
    ],
  },
  {
    id: 'OV-MGT-001',
    name: 'Information Systems Security Manager',
    specialityArea: 'Cybersecurity Management (MGT)',
    category: 'Oversee and Govern (OV)',
    description:
      'Responsible for the cybersecurity of a program, organization, system, or enclave.',
    tasks: [
      'T0147',
      'T0148',
      'T0149',
      'T0151',
      'T0157',
      'T0158',
      'T0159',
      'T0192',
      'T0199',
      'T0206',
      'T0211',
      'T0213',
      'T0215',
      'T0219',
      'T0227',
      'T0229',
      'T0234',
      'T0239',
      'T0248',
      'T0254',
      'T0255',
      'T0256',
      'T0263',
      'T0264',
      'T0265',
      'T0275',
      'T0276',
      'T0277',
      'T0280',
      'T0281',
      'T0282',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0008',
      'K0018',
      'K0021',
      'K0026',
      'K0033',
      'K0038',
      'K0040',
      'K0042',
      'K0043',
      'K0046',
      'K0048',
      'K0053',
      'K0054',
      'K0058',
      'K0059',
      'K0061',
      'K0070',
      'K0072',
      'K0076',
      'K0077',
      'K0087',
      'K0090',
      'K0092',
      'K0101',
      'K0106',
      'K0121',
      'K0126',
      'K0149',
      'K0150',
      'K0151',
      'K0163',
      'K0167',
      'K0168',
      'K0169',
      'K0170',
      'K0179',
      'K0180',
      'K0199',
      'K0260',
      'K0261',
      'K0262',
      'K0267',
      'K0287',
      'K0332',
      'K0342',
      'K0622',
      'K0624',
    ],
    skills: ['S0018', 'S0027', 'S0086'],
    abilities: ['A0128', 'A0161', 'A0170'],
  },
  {
    id: 'OV-MGT-002',
    name: 'Communications Security (COMSEC) Manager',
    specialityArea: 'Cybersecurity Management (MGT)',
    category: 'Oversee and Govern (OV)',
    description:
      'Individual who manages the Communications Security (COMSEC) resources of an organization (CNSSI  4009) or key custodian for a Crypto Key Management System (CKMS).',
    tasks: ['T0003', 'T0004', 'T0025', 'T0044', 'T0089', 'T0095', 'T0099', 'T0215', 'T0229'],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0018',
      'K0026',
      'K0038',
      'K0042',
      'K0090',
      'K0101',
      'K0121',
      'K0126',
      'K0163',
      'K0267',
      'K0285',
      'K0287',
      'K0622',
    ],
    skills: ['S0027', 'S0059', 'S0138'],
    abilities: ['A0177', 'A0163', 'A0164', 'A0165', 'A0166', 'A0167', 'A0168'],
  },
  {
    id: 'OV-SPP-001',
    name: 'Cyber Workforce Developer and Manager',
    specialityArea: 'Strategic Planning and Policy (SPP)',
    category: 'Oversee and Govern (OV)',
    description:
      'Develops cyberspace workforce plans, strategies, and guidance to support cyberspace workforce manpower, personnel, training and education requirements and to address changes to cyberspace policy, doctrine, materiel, force structure, and education and training requirements.',
    tasks: [
      'T0001',
      'T0004',
      'T0025',
      'T0044',
      'T0074',
      'T0094',
      'T0099',
      'T0116',
      'T0222',
      'T0226',
      'T0341',
      'T0352',
      'T0355',
      'T0356',
      'T0362',
      'T0363',
      'T0364',
      'T0365',
      'T0368',
      'T0369',
      'T0372',
      'T0373',
      'T0374',
      'T0375',
      'T0376',
      'T0384',
      'T0387',
      'T0388',
      'T0390',
      'T0391',
      'T0408',
      'T0425',
      'T0429',
      'T0437',
      'T0441',
      'T0445',
      'T0472',
      'T0481',
      'T0505',
      'T0506',
      'T0529',
      'T0533',
      'T0536',
      'T0537',
      'T0552',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0072',
      'K0101',
      'K0127',
      'K0146',
      'K0147',
      'K0168',
      'K0169',
      'K0204',
      'K0215',
      'K0233',
      'K0234',
      'K0241',
      'K0243',
      'K0309',
      'K0311',
      'K0313',
      'K0335',
    ],
    skills: ['S0108', 'S0128'],
    abilities: ['A0023', 'A0028', 'A0033', 'A0037', 'A0042', 'A0053'],
  },
  {
    id: 'OV-SPP-002',
    name: 'Cyber Policy and Strategy Planner',
    specialityArea: 'Strategic Planning and Policy (SPP)',
    category: 'Oversee and Govern (OV)',
    description:
      'Develops and maintains cybersecurity plans, strategy, and policy to support and align with organizational cybersecurity initiatives and regulatory compliance.',
    tasks: [
      'T0074',
      'T0094',
      'T0222',
      'T0226',
      'T0341',
      'T0369',
      'T0384',
      'T0390',
      'T0408',
      'T0425',
      'T0429',
      'T0441',
      'T0445',
      'T0472',
      'T0505',
      'T0506',
      'T0529',
      'T0533',
      'T0537',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0070',
      'K0127',
      'K0146',
      'K0168',
      'K0234',
      'K0248',
      'K0309',
      'K0311',
      'K0313',
      'K0335',
      'K0624',
    ],
    skills: ['S0176', 'S0250'],
    abilities: ['A0003', 'A0033', 'A0037'],
  },
  {
    id: 'OV-EXL-001',
    name: 'Executive Cyber Leadership',
    specialityArea: 'Executive Cyber Leadership (EXL)',
    category: 'Oversee and Govern (OV)',
    description:
      "Executes decision-making authorities and establishes vision and direction for an organization's cyber and cyber-related resources and/or operations.",
    tasks: [
      'T0001',
      'T0002',
      'T0004',
      'T0006',
      'T0025',
      'T0066',
      'T0130',
      'T0134',
      'T0135',
      'T0148',
      'T0151',
      'T0227',
      'T0229',
      'T0248',
      'T0254',
      'T0263',
      'T0264',
      'T0282',
      'T0337',
      'T0356',
      'T0429',
      'T0445',
      'T0509',
      'T0763',
      'T0871',
      'T0872',
      'T0927',
      'T0928',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0009',
      'K0070',
      'K0106',
      'K0147',
      'K0296',
      'K0314',
      'K0624',
      'K0628',
    ],
    skills: ['S0018', 'S0356', 'S0357', 'S0358', 'S0359'],
    abilities: [
      'A0033',
      'A0070',
      'A0085',
      'A0094',
      'A0105',
      'A0106',
      'A0116',
      'A0117',
      'A0118',
      'A0119',
      'A0129',
      'A0130',
    ],
  },
  {
    id: 'OV-PMA-001',
    name: 'Program Manager',
    specialityArea: 'Program/Project Management and Acquisition (PMA)',
    category: 'Oversee and Govern (OV)',
    description:
      'Leads, coordinates, communicates, integrates, and is accountable for the overall success of the program, ensuring alignment with agency or enterprise priorities.',
    tasks: [
      'T0066',
      'T0072',
      'T0174',
      'T0199',
      'T0220',
      'T0223',
      'T0256',
      'T0273',
      'T0277',
      'T0302',
      'T0340',
      'T0354',
      'T0377',
      'T0379',
      'T0407',
      'T0412',
      'T0414',
      'T0415',
      'T0481',
      'T0493',
      'T0551',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0047',
      'K0048',
      'K0072',
      'K0090',
      'K0101',
      'K0120',
      'K0126',
      'K0146',
      'K0148',
      'K0154',
      'K0164',
      'K0165',
      'K0169',
      'K0194',
      'K0196',
      'K0198',
      'K0200',
      'K0235',
      'K0257',
      'K0270',
    ],
    skills: ['S0038', 'S0372'],
    abilities: ['A0009', 'A0039', 'A0045', 'A0056'],
  },
  {
    id: 'OV-PMA-002',
    name: 'IT Project Manager',
    specialityArea: 'Program/Project Management and Acquisition (PMA)',
    category: 'Oversee and Govern (OV)',
    description: 'Directly manages information technology projects.',
    tasks: [
      'T0072',
      'T0174',
      'T0196',
      'T0199',
      'T0207',
      'T0208',
      'T0220',
      'T0223',
      'T0256',
      'T0273',
      'T0277',
      'T0340',
      'T0354',
      'T0370',
      'T0377',
      'T0379',
      'T0389',
      'T0394',
      'T0407',
      'T0412',
      'T0414',
      'T0415',
      'T0481',
      'T0493',
      'T0551',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0012',
      'K0043',
      'K0047',
      'K0048',
      'K0059',
      'K0072',
      'K0090',
      'K0101',
      'K0120',
      'K0126',
      'K0146',
      'K0148',
      'K0154',
      'K0164',
      'K0165',
      'K0169',
      'K0194',
      'K0196',
      'K0198',
      'K0200',
      'K0235',
      'K0257',
      'K0270',
    ],
    skills: ['S0038', 'S0372'],
    abilities: ['A0009', 'A0039', 'A0045', 'A0056'],
  },
  {
    id: 'OV-PMA-003',
    name: 'Product Support Manager',
    specialityArea: 'Program/Project Management and Acquisition (PMA)',
    category: 'Oversee and Govern (OV)',
    description:
      'Manages the package of support functions required to field and maintain the readiness and operational capability of systems and components.',
    tasks: [
      'T0072',
      'T0174',
      'T0196',
      'T0204',
      'T0207',
      'T0208',
      'T0220',
      'T0223',
      'T0256',
      'T0273',
      'T0277',
      'T0302',
      'T0340',
      'T0354',
      'T0370',
      'T0377',
      'T0389',
      'T0394',
      'T0412',
      'T0414',
      'T0493',
      'T0525',
      'T0551',
      'T0553',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0043',
      'K0048',
      'K0059',
      'K0072',
      'K0090',
      'K0120',
      'K0126',
      'K0148',
      'K0150',
      'K0154',
      'K0164',
      'K0165',
      'K0169',
      'K0194',
      'K0196',
      'K0198',
      'K0200',
      'K0235',
      'K0249',
      'K0257',
      'K0270',
    ],
    skills: ['S0038', 'S0372'],
    abilities: ['A0009', 'A0031', 'A0039', 'A0045', 'A0056'],
  },
  {
    id: 'OV-PMA-004',
    name: 'IT Investment/Portfolio Manager',
    specialityArea: 'Program/Project Management and Acquisition (PMA)',
    category: 'Oversee and Govern (OV)',
    description:
      'Manages a portfolio of IT investments that align with the overall needs of mission and enterprise priorities.',
    tasks: ['T0220', 'T0223', 'T0277', 'T0302', 'T0377', 'T0415', 'T0493', 'T0551'],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0048',
      'K0072',
      'K0120',
      'K0126',
      'K0146',
      'K0154',
      'K0165',
      'K0169',
      'K0235',
      'K0257',
      'K0270',
    ],
    skills: ['S0372'],
    abilities: ['A0039'],
  },
  {
    id: 'OV-PMA-005',
    name: 'IT Program Auditor',
    specialityArea: 'Program/Project Management and Acquisition (PMA)',
    category: 'Oversee and Govern (OV)',
    description:
      'Conducts evaluations of an IT program or its individual components to determine compliance with published standards.',
    tasks: ['T0072', 'T0207', 'T0208', 'T0223', 'T0256', 'T0389', 'T0412', 'T0415'],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0043',
      'K0047',
      'K0048',
      'K0072',
      'K0090',
      'K0120',
      'K0126',
      'K0148',
      'K0154',
      'K0165',
      'K0169',
      'K0198',
      'K0200',
      'K0235',
      'K0257',
      'K0270',
    ],
    skills: ['S0038', 'S0085', 'S0372'],
    abilities: ['A0056'],
  },
  {
    id: 'PR-CDA-001',
    name: 'Cyber Defense Analyst',
    specialityArea: 'Cybersecurity Defense Analysis (CDA)',
    category: 'Protect and Defend (PR)',
    description:
      'Uses data collected from a variety of cyber defense tools (e.g., IDS alerts, firewalls, network traffic logs) to analyze events that occur within their environments for the purposes of mitigating threats.',
    tasks: [
      'T0020',
      'T0023',
      'T0043',
      'T0088',
      'T0155',
      'T0164',
      'T0166',
      'T0178',
      'T0187',
      'T0198',
      'T0214',
      'T0258',
      'T0259',
      'T0260',
      'T0290',
      'T0291',
      'T0292',
      'T0293',
      'T0294',
      'T0295',
      'T0296',
      'T0297',
      'T0298',
      'T0299',
      'T0310',
      'T0332',
      'T0469',
      'T0470',
      'T0475',
      'T0503',
      'T0504',
      'T0526',
      'T0545',
      'T0548',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0007',
      'K0013',
      'K0015',
      'K0018',
      'K0019',
      'K0024',
      'K0033',
      'K0040',
      'K0042',
      'K0044',
      'K0046',
      'K0049',
      'K0056',
      'K0058',
      'K0059',
      'K0060',
      'K0061',
      'K0065',
      'K0070',
      'K0074',
      'K0075',
      'K0093',
      'K0098',
      'K0104',
      'K0106',
      'K0107',
      'K0110',
      'K0111',
      'K0112',
      'K0113',
      'K0116',
      'K0139',
      'K0142',
      'K0143',
      'K0157',
      'K0160',
      'K0161',
      'K0162',
      'K0167',
      'K0168',
      'K0177',
      'K0179',
      'K0180',
      'K0190',
      'K0191',
      'K0192',
      'K0203',
      'K0221',
      'K0222',
      'K0260',
      'K0261',
      'K0262',
      'K0290',
      'K0297',
      'K0300',
      'K0301',
      'K0303',
      'K0318',
      'K0322',
      'K0324',
      'K0332',
      'K0339',
      'K0342',
      'K0624',
    ],
    skills: [
      'S0020',
      'S0025',
      'S0027',
      'S0036',
      'S0054',
      'S0057',
      'S0063',
      'S0078',
      'S0096',
      'S0147',
      'S0156',
      'S0167',
      'S0169',
      'S0367',
      'S0370',
    ],
    abilities: ['A0010', 'A0015', 'A0066', 'A0123', 'A0128', 'A0159'],
  },
  {
    id: 'PR-INF-001',
    name: 'Cyber Defense Infrastructure Support Specialist',
    specialityArea: 'Cybersecurity Defense Infrastructure Support (INF)',
    category: 'Protect and Defend (PR)',
    description:
      'Tests, implements, deploys, maintains, and administers the infrastructure hardware and software.',
    tasks: ['T0042', 'T0180', 'T0261', 'T0335', 'T0348', 'T0420', 'T0438', 'T0483', 'T0486'],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0021',
      'K0033',
      'K0042',
      'K0044',
      'K0058',
      'K0061',
      'K0062',
      'K0104',
      'K0106',
      'K0135',
      'K0157',
      'K0179',
      'K0205',
      'K0258',
      'K0274',
      'K0324',
      'K0332',
      'K0334',
    ],
    skills: ['S0007', 'S0053', 'S0054', 'S0059', 'S0077', 'S0079', 'S0121', 'S0124', 'S0367'],
    abilities: ['A0123'],
  },
  {
    id: 'PR-CIR-001',
    name: 'Cyber Defense Incident Responder',
    specialityArea: 'Incident Response (CIR)',
    category: 'Protect and Defend (PR)',
    description:
      'Investigates, analyzes, and responds to cyber incidents within the network environment or enclave.',
    tasks: [
      'T0041',
      'T0047',
      'T0161',
      'T0163',
      'T0164',
      'T0170',
      'T0175',
      'T0214',
      'T0233',
      'T0246',
      'T0262',
      'T0278',
      'T0279',
      'T0312',
      'T0395',
      'T0503',
      'T0510',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0021',
      'K0026',
      'K0033',
      'K0034',
      'K0041',
      'K0042',
      'K0046',
      'K0058',
      'K0062',
      'K0070',
      'K0106',
      'K0157',
      'K0161',
      'K0162',
      'K0167',
      'K0177',
      'K0179',
      'K0221',
      'K0230',
      'K0259',
      'K0287',
      'K0332',
      'K0565',
      'K0624',
    ],
    skills: ['S0003', 'S0047', 'S0077', 'S0078', 'S0079', 'S0080', 'S0173', 'S0365'],
    abilities: ['A0121', 'A0128'],
  },
  {
    id: 'PR-VAM-001',
    name: 'Vulnerability Assessment Analyst',
    specialityArea: 'Vulnerability Assessment and Management (VAM)',
    category: 'Protect and Defend (PR)',
    description:
      'Performs assessments of systems and networks within the network environment or enclave and identifies where those systems/networks deviate from acceptable configurations, enclave policy, or local policy. Measures effectiveness of defense-in-depth architecture against known vulnerabilities.',
    tasks: ['T0010', 'T0028', 'T0138', 'T0142', 'T0188', 'T0252', 'T0549', 'T0550'],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0009',
      'K0019',
      'K0021',
      'K0033',
      'K0044',
      'K0056',
      'K0061',
      'K0068',
      'K0070',
      'K0089',
      'K0106',
      'K0139',
      'K0161',
      'K0162',
      'K0167',
      'K0177',
      'K0179',
      'K0203',
      'K0206',
      'K0210',
      'K0224',
      'K0265',
      'K0287',
      'K0301',
      'K0308',
      'K0332',
      'K0342',
      'K0344',
      'K0624',
    ],
    skills: [
      'S0001',
      'S0009',
      'S0025',
      'S0044',
      'S0051',
      'S0052',
      'S0081',
      'S0120',
      'S0137',
      'S0171',
      'S0364',
      'S0367',
    ],
    abilities: ['A0001', 'A0044', 'A0120', 'A0123'],
  },
  {
    id: 'AN-TWA-001',
    name: 'Threat/Warning Analyst',
    specialityArea: 'Threat Analysis (TWA)',
    category: 'Analyze (AN)',
    description:
      'Develops cyber indicators to maintain awareness of the status of the highly dynamic operating environment. Collects, processes, analyzes, and disseminates cyber threat/warning assessments.',
    tasks: [
      'T0569',
      'T0583',
      'T0584',
      'T0585',
      'T0586',
      'T0589',
      'T0593',
      'T0597',
      'T0615',
      'T0617',
      'T0660',
      'T0685',
      'T0687',
      'T0707',
      'T0708',
      'T0718',
      'T0748',
      'T0749',
      'T0751',
      'T0752',
      'T0758',
      'T0761',
      'T0783',
      'T0785',
      'T0786',
      'T0792',
      'T0800',
      'T0805',
      'T0834',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0036',
      'K0058',
      'K0108',
      'K0109',
      'K0177',
      'K0349',
      'K0362',
      'K0377',
      'K0392',
      'K0395',
      'K0405',
      'K0409',
      'K0415',
      'K0417',
      'K0427',
      'K0431',
      'K0436',
      'K0437',
      'K0440',
      'K0444',
      'K0445',
      'K0446',
      'K0449',
      'K0458',
      'K0460',
      'K0464',
      'K0469',
      'K0471',
      'K0480',
      'K0499',
      'K0511',
      'K0516',
      'K0556',
      'K0560',
      'K0561',
      'K0565',
      'K0603',
      'K0604',
      'K0610',
      'K0612',
      'K0614',
    ],
    skills: [
      'S0194',
      'S0196',
      'S0203',
      'S0211',
      'S0218',
      'S0227',
      'S0228',
      'S0229',
      'S0249',
      'S0256',
      'S0278',
      'S0285',
      'S0288',
      'S0289',
      'S0296',
      'S0297',
      'S0303',
    ],
    abilities: [
      'A0013',
      'A0066',
      'A0072',
      'A0080',
      'A0082',
      'A0083',
      'A0084',
      'A0087',
      'A0088',
      'A0089',
      'A0091',
      'A0101',
      'A0102',
      'A0106',
      'A0107',
      'A0109',
    ],
  },
  {
    id: 'AN-EXP-001',
    name: 'Exploitation Analyst',
    specialityArea: 'Exploitation Analysis (EXP)',
    category: 'Analyze (AN)',
    description:
      'Collaborates to identify access and collection gaps that can be satisfied through cyber collection and/or preparation activities. Leverages all authorized resources and analytic techniques to penetrate targeted networks.',
    tasks: [
      'T0028',
      'T0266',
      'T0570',
      'T0572',
      'T0574',
      'T0591',
      'T0600',
      'T0603',
      'T0608',
      'T0614',
      'T0641',
      'T0695',
      'T0701',
      'T0720',
      'T0727',
      'T0736',
      'T0738',
      'T0754',
      'T0775',
      'T0777',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0108',
      'K0109',
      'K0131',
      'K0142',
      'K0143',
      'K0177',
      'K0224',
      'K0349',
      'K0351',
      'K0354',
      'K0362',
      'K0368',
      'K0371',
      'K0376',
      'K0379',
      'K0388',
      'K0393',
      'K0394',
      'K0397',
      'K0417',
      'K0418',
      'K0430',
      'K0443',
      'K0444',
      'K0447',
      'K0451',
      'K0470',
      'K0471',
      'K0473',
      'K0484',
      'K0487',
      'K0489',
      'K0509',
      'K0510',
      'K0523',
      'K0529',
      'K0535',
      'K0544',
      'K0557',
      'K0559',
      'K0560',
      'K0608',
    ],
    skills: [
      'S0066',
      'S0184',
      'S0199',
      'S0200',
      'S0201',
      'S0204',
      'S0207',
      'S0214',
      'S0223',
      'S0236',
      'S0237',
      'S0239',
      'S0240',
      'S0245',
      'S0247',
      'S0258',
      'S0260',
      'S0264',
      'S0269',
      'S0279',
      'S0286',
      'S0290',
      'S0294',
      'S0300',
    ],
    abilities: ['A0013', 'A0066', 'A0074', 'A0080', 'A0084', 'A0086', 'A0092', 'A0093', 'A0104'],
  },
  {
    id: 'AN-ASA-001',
    name: 'All-Source Analyst',
    specialityArea: 'All-Source Analysis (ASA)',
    category: 'Analyze (AN)',
    description:
      'Analyzes data/information from one or multiple sources to conduct preparation of the environment, respond to requests for information, and submit intelligence collection and production requirements in support of planning and operations.',
    tasks: [
      'T0569',
      'T0582',
      'T0583',
      'T0584',
      'T0585',
      'T0586',
      'T0589',
      'T0593',
      'T0597',
      'T0615',
      'T0617',
      'T0642',
      'T0660',
      'T0678',
      'T0685',
      'T0686',
      'T0687',
      'T0707',
      'T0708',
      'T0710',
      'T0713',
      'T0718',
      'T0748',
      'T0749',
      'T0751',
      'T0752',
      'T0758',
      'T0761',
      'T0771',
      'T0782',
      'T0783',
      'T0785',
      'T0786',
      'T0788',
      'T0789',
      'T0792',
      'T0797',
      'T0800',
      'T0805',
      'T0834',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0036',
      'K0058',
      'K0108',
      'K0109',
      'K0177',
      'K0221',
      'K0349',
      'K0357',
      'K0362',
      'K0377',
      'K0392',
      'K0395',
      'K0405',
      'K0409',
      'K0410',
      'K0427',
      'K0431',
      'K0436',
      'K0437',
      'K0440',
      'K0444',
      'K0445',
      'K0446',
      'K0449',
      'K0457',
      'K0458',
      'K0460',
      'K0464',
      'K0465',
      'K0469',
      'K0471',
      'K0480',
      'K0507',
      'K0511',
      'K0516',
      'K0533',
      'K0542',
      'K0549',
      'K0551',
      'K0556',
      'K0560',
      'K0561',
      'K0565',
      'K0577',
      'K0598',
      'K0603',
      'K0604',
      'K0610',
      'K0612',
      'K0614',
    ],
    skills: [
      'S0189',
      'S0194',
      'S0203',
      'S0211',
      'S0218',
      'S0227',
      'S0229',
      'S0249',
      'S0254',
      'S0256',
      'S0278',
      'S0285',
      'S0288',
      'S0289',
      'S0296',
      'S0297',
      'S0303',
      'S0360',
    ],
    abilities: [
      'A0013',
      'A0066',
      'A0072',
      'A0080',
      'A0082',
      'A0083',
      'A0084',
      'A0085',
      'A0087',
      'A0088',
      'A0089',
      'A0091',
      'A0101',
      'A0102',
      'A0106',
      'A0107',
      'A0108',
      'A0109',
    ],
  },
  {
    id: 'AN-ASA-002',
    name: 'Mission Assessment Specialist',
    specialityArea: 'All-Source Analysis (ASA)',
    category: 'Analyze (AN)',
    description:
      'Develops assessment plans and measures of performance/effectiveness. Conducts strategic and operational effectiveness assessments as required for cyber events. Determines whether systems performed as expected and provides input to the determination of operational effectiveness.',
    tasks: [
      'T0582',
      'T0583',
      'T0585',
      'T0586',
      'T0588',
      'T0589',
      'T0593',
      'T0597',
      'T0611',
      'T0615',
      'T0617',
      'T0624',
      'T0660',
      'T0661',
      'T0663',
      'T0678',
      'T0684',
      'T0685',
      'T0686',
      'T0707',
      'T0718',
      'T0748',
      'T0749',
      'T0752',
      'T0758',
      'T0761',
      'T0782',
      'T0783',
      'T0785',
      'T0786',
      'T0788',
      'T0789',
      'T0793',
      'T0797',
      'T0834',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0036',
      'K0058',
      'K0108',
      'K0109',
      'K0177',
      'K0349',
      'K0362',
      'K0377',
      'K0392',
      'K0395',
      'K0405',
      'K0409',
      'K0410',
      'K0414',
      'K0417',
      'K0427',
      'K0431',
      'K0436',
      'K0437',
      'K0440',
      'K0444',
      'K0445',
      'K0446',
      'K0449',
      'K0457',
      'K0460',
      'K0464',
      'K0465',
      'K0469',
      'K0471',
      'K0480',
      'K0507',
      'K0511',
      'K0516',
      'K0549',
      'K0551',
      'K0556',
      'K0560',
      'K0561',
      'K0565',
      'K0598',
      'K0603',
      'K0604',
      'K0610',
      'K0612',
      'K0614',
    ],
    skills: [
      'S0189',
      'S0194',
      'S0203',
      'S0211',
      'S0216',
      'S0218',
      'S0227',
      'S0228',
      'S0229',
      'S0249',
      'S0254',
      'S0256',
      'S0271',
      'S0278',
      'S0285',
      'S0288',
      'S0289',
      'S0292',
      'S0296',
      'S0297',
      'S0303',
      'S0360',
    ],
    abilities: [
      'A0013',
      'A0066',
      'A0072',
      'A0080',
      'A0082',
      'A0083',
      'A0084',
      'A0085',
      'A0087',
      'A0088',
      'A0089',
      'A0091',
      'A0101',
      'A0102',
      'A0106',
      'A0107',
      'A0108',
      'A0109',
    ],
  },
  {
    id: 'AN-TGT-001',
    name: 'Target Developer',
    specialityArea: 'Targets (TGT)',
    category: 'Analyze (AN)',
    description:
      'Performs target system analysis, builds and/or maintains electronic target folders to include inputs from environment preparation, and/or internal or external intelligence sources. Coordinates with partner target activities and intelligence organizations, and presents candidate targets for vetting and validation.',
    tasks: [
      'T0561',
      'T0582',
      'T0588',
      'T0594',
      'T0597',
      'T0599',
      'T0617',
      'T0624',
      'T0633',
      'T0642',
      'T0650',
      'T0652',
      'T0661',
      'T0663',
      'T0684',
      'T0688',
      'T0707',
      'T0710',
      'T0717',
      'T0731',
      'T0744',
      'T0769',
      'T0770',
      'T0776',
      'T0781',
      'T0782',
      'T0790',
      'T0794',
      'T0797',
      'T0798',
      'T0799',
      'T0802',
      'T0815',
      'T0824',
      'T0835',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0036',
      'K0058',
      'K0108',
      'K0109',
      'K0142',
      'K0177',
      'K0349',
      'K0351',
      'K0357',
      'K0362',
      'K0379',
      'K0381',
      'K0392',
      'K0395',
      'K0402',
      'K0409',
      'K0413',
      'K0417',
      'K0426',
      'K0427',
      'K0431',
      'K0436',
      'K0437',
      'K0439',
      'K0440',
      'K0444',
      'K0445',
      'K0446',
      'K0449',
      'K0457',
      'K0458',
      'K0460',
      'K0461',
      'K0464',
      'K0465',
      'K0466',
      'K0471',
      'K0473',
      'K0478',
      'K0479',
      'K0497',
      'K0499',
      'K0507',
      'K0516',
      'K0533',
      'K0542',
      'K0543',
      'K0546',
      'K0547',
      'K0549',
      'K0551',
      'K0555',
      'K0556',
      'K0560',
      'K0561',
      'K0565',
      'K0598',
      'K0603',
      'K0604',
      'K0614',
    ],
    skills: [
      'S0187',
      'S0189',
      'S0194',
      'S0196',
      'S0203',
      'S0205',
      'S0208',
      'S0216',
      'S0218',
      'S0222',
      'S0227',
      'S0228',
      'S0229',
      'S0248',
      'S0249',
      'S0256',
      'S0274',
      'S0278',
      'S0285',
      'S0287',
      'S0288',
      'S0289',
      'S0292',
      'S0296',
      'S0297',
      'S0302',
      'S0360',
      'S0361',
    ],
    abilities: [
      'A0013',
      'A0066',
      'A0073',
      'A0080',
      'A0084',
      'A0085',
      'A0087',
      'A0088',
      'A0089',
      'A0091',
      'A0101',
      'A0102',
      'A0106',
      'A0109',
    ],
  },
  {
    id: 'AN-TGT-002',
    name: 'Target Network Analyst',
    specialityArea: 'Targets (TGT)',
    category: 'Analyze (AN)',
    description:
      'Conducts advanced analysis of collection and open-source data to ensure target continuity; to profile targets and their activities; and develop techniques to gain more target information. Determines how targets communicate, move, operate and live based on knowledge of target technologies, digital networks, and the applications on them.',
    tasks: [
      'T0582',
      'T0595',
      'T0599',
      'T0606',
      'T0607',
      'T0617',
      'T0621',
      'T0624',
      'T0650',
      'T0653',
      'T0692',
      'T0706',
      'T0707',
      'T0710',
      'T0715',
      'T0722',
      'T0745',
      'T0765',
      'T0767',
      'T0778',
      'T0797',
      'T0802',
      'T0803',
      'T0807',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0108',
      'K0109',
      'K0177',
      'K0349',
      'K0362',
      'K0379',
      'K0389',
      'K0392',
      'K0395',
      'K0403',
      'K0413',
      'K0424',
      'K0431',
      'K0436',
      'K0439',
      'K0440',
      'K0442',
      'K0444',
      'K0445',
      'K0449',
      'K0462',
      'K0471',
      'K0472',
      'K0473',
      'K0479',
      'K0483',
      'K0487',
      'K0499',
      'K0500',
      'K0516',
      'K0520',
      'K0544',
      'K0547',
      'K0550',
      'K0559',
      'K0567',
      'K0592',
      'K0599',
      'K0600',
    ],
    skills: [
      'S0177',
      'S0178',
      'S0181',
      'S0183',
      'S0187',
      'S0191',
      'S0194',
      'S0196',
      'S0197',
      'S0203',
      'S0205',
      'S0208',
      'S0217',
      'S0219',
      'S0220',
      'S0222',
      'S0225',
      'S0228',
      'S0229',
      'S0231',
      'S0234',
      'S0244',
      'S0246',
      'S0248',
      'S0256',
      'S0259',
      'S0261',
      'S0262',
      'S0263',
      'S0268',
      'S0274',
      'S0277',
      'S0280',
      'S0287',
      'S0291',
      'S0301',
    ],
    abilities: [
      'A0013',
      'A0066',
      'A0073',
      'A0080',
      'A0084',
      'A0085',
      'A0087',
      'A0088',
      'A0089',
      'A0091',
      'A0101',
      'A0102',
      'A0106',
      'A0109',
    ],
  },
  {
    id: 'AN-LNG-001',
    name: 'Multi-Disciplined Language Analyst',
    specialityArea: 'Language Analysis (LNG)',
    category: 'Analyze (AN)',
    description:
      'Applies language and culture expertise with target/threat and technical knowledge to process, analyze, and/or disseminate intelligence information derived from language, voice and/or graphic material. Creates and maintains language-specific databases and working aids to support cyber action execution and ensure critical knowledge sharing. Provides subject matter expertise in foreign language-intensive or interdisciplinary projects.',
    tasks: [
      'T0606',
      'T0650',
      'T0715',
      'T0745',
      'T0761',
      'T0837',
      'T0838',
      'T0839',
      'T0840',
      'T0841',
      'T0842',
      'T0843',
      'T0844',
      'T0845',
      'T0846',
      'T0847',
      'T0848',
      'T0849',
      'T0850',
      'T0851',
      'T0852',
      'T0853',
      'T0854',
      'T0855',
      'T0856',
      'T0857',
      'T0858',
      'T0859',
      'T0860',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0108',
      'K0143',
      'K0177',
      'K0356',
      'K0359',
      'K0377',
      'K0391',
      'K0396',
      'K0398',
      'K0407',
      'K0413',
      'K0416',
      'K0417',
      'K0431',
      'K0449',
      'K0462',
      'K0476',
      'K0487',
      'K0488',
      'K0491',
      'K0493',
      'K0499',
      'K0520',
      'K0524',
      'K0532',
      'K0539',
      'K0540',
      'K0541',
      'K0545',
      'K0548',
      'K0550',
      'K0564',
      'K0567',
      'K0571',
      'K0574',
      'K0579',
      'K0596',
      'K0599',
      'K0600',
      'K0606',
      'K0607',
    ],
    skills: [
      'S0179',
      'S0184',
      'S0187',
      'S0188',
      'S0193',
      'S0195',
      'S0198',
      'S0210',
      'S0212',
      'S0215',
      'S0217',
      'S0218',
      'S0224',
      'S0226',
      'S0232',
      'S0233',
      'S0235',
      'S0241',
      'S0244',
      'S0251',
      'S0253',
      'S0259',
      'S0262',
      'S0265',
      'S0277',
      'S0283',
      'S0284',
      'S0290',
    ],
    abilities: ['A0013', 'A0071', 'A0089', 'A0103'],
  },
  {
    id: 'CO-CLO-001',
    name: 'All Source-Collection Manager',
    specialityArea: 'Collection Operations (CLO)',
    category: 'Collect and Operate (CO)',
    description:
      "Identifies collection authorities and environment; incorporates priority information requirements into collection management; develops concepts to meet leadership's intent. Determines capabilities of available collection assets, identifies new collection capabilities; and constructs and disseminates collection plans.  Monitors execution of tasked collection to ensure effective execution of the collection plan.",
    tasks: [
      'T0562',
      'T0564',
      'T0568',
      'T0573',
      'T0578',
      'T0604',
      'T0605',
      'T0625',
      'T0626',
      'T0631',
      'T0632',
      'T0634',
      'T0645',
      'T0646',
      'T0647',
      'T0649',
      'T0651',
      'T0657',
      'T0662',
      'T0674',
      'T0681',
      'T0683',
      'T0698',
      'T0702',
      'T0714',
      'T0716',
      'T0721',
      'T0723',
      'T0725',
      'T0734',
      'T0737',
      'T0750',
      'T0753',
      'T0755',
      'T0757',
      'T0773',
      'T0779',
      'T0806',
      'T0809',
      'T0810',
      'T0811',
      'T0812',
      'T0814',
      'T0820',
      'T0821',
      'T0827',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0036',
      'K0058',
      'K0109',
      'K0177',
      'K0353',
      'K0361',
      'K0364',
      'K0380',
      'K0382',
      'K0383',
      'K0386',
      'K0387',
      'K0390',
      'K0392',
      'K0395',
      'K0401',
      'K0404',
      'K0405',
      'K0412',
      'K0417',
      'K0419',
      'K0425',
      'K0427',
      'K0431',
      'K0435',
      'K0440',
      'K0444',
      'K0445',
      'K0446',
      'K0448',
      'K0449',
      'K0453',
      'K0454',
      'K0467',
      'K0471',
      'K0474',
      'K0475',
      'K0477',
      'K0480',
      'K0482',
      'K0492',
      'K0495',
      'K0496',
      'K0498',
      'K0503',
      'K0505',
      'K0513',
      'K0516',
      'K0521',
      'K0522',
      'K0526',
      'K0527',
      'K0552',
      'K0553',
      'K0554',
      'K0558',
      'K0560',
      'K0561',
      'K0562',
      'K0563',
      'K0565',
      'K0569',
      'K0570',
      'K0579',
      'K0580',
      'K0581',
      'K0583',
      'K0584',
      'K0587',
      'K0588',
      'K0596',
      'K0601',
      'K0605',
      'K0610',
      'K0612',
      'K0613',
    ],
    skills: [
      'S0238',
      'S0304',
      'S0305',
      'S0311',
      'S0313',
      'S0316',
      'S0317',
      'S0324',
      'S0325',
      'S0327',
      'S0328',
      'S0330',
      'S0332',
      'S0334',
      'S0335',
      'S0336',
      'S0339',
      'S0342',
      'S0344',
      'S0347',
      'S0351',
      'S0352',
      'S0362',
    ],
    abilities: ['A0069', 'A0070', 'A0076', 'A0078', 'A0079'],
  },
  {
    id: 'CO-CLO-002',
    name: 'All Source-Collection Requirements Manager',
    specialityArea: 'Collection Operations (CLO)',
    category: 'Collect and Operate (CO)',
    description:
      'Evaluates collection operations and develops effects-based collection requirements strategies using available sources and methods to improve collection. Develops, processes, validates, and coordinates submission of collection requirements. Evaluates performance of collection assets and collection operations.',
    tasks: [
      'T0564',
      'T0565',
      'T0568',
      'T0577',
      'T0578',
      'T0580',
      'T0596',
      'T0602',
      'T0605',
      'T0613',
      'T0651',
      'T0668',
      'T0673',
      'T0675',
      'T0682',
      'T0689',
      'T0693',
      'T0694',
      'T0714',
      'T0725',
      'T0730',
      'T0734',
      'T0746',
      'T0780',
      'T0809',
      'T0810',
      'T0811',
      'T0819',
      'T0822',
      'T0830',
      'T0831',
      'T0832',
      'T0833',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0036',
      'K0058',
      'K0109',
      'K0177',
      'K0353',
      'K0361',
      'K0364',
      'K0380',
      'K0382',
      'K0383',
      'K0384',
      'K0386',
      'K0387',
      'K0390',
      'K0395',
      'K0401',
      'K0404',
      'K0412',
      'K0417',
      'K0419',
      'K0421',
      'K0425',
      'K0427',
      'K0431',
      'K0435',
      'K0444',
      'K0445',
      'K0446',
      'K0448',
      'K0453',
      'K0454',
      'K0467',
      'K0474',
      'K0475',
      'K0477',
      'K0480',
      'K0482',
      'K0492',
      'K0495',
      'K0496',
      'K0498',
      'K0505',
      'K0513',
      'K0516',
      'K0521',
      'K0526',
      'K0527',
      'K0552',
      'K0554',
      'K0558',
      'K0560',
      'K0561',
      'K0562',
      'K0563',
      'K0565',
      'K0568',
      'K0569',
      'K0570',
      'K0579',
      'K0580',
      'K0581',
      'K0584',
      'K0587',
      'K0588',
      'K0596',
      'K0605',
      'K0610',
      'K0612',
    ],
    skills: [
      'S0304',
      'S0305',
      'S0316',
      'S0317',
      'S0327',
      'S0329',
      'S0330',
      'S0334',
      'S0335',
      'S0336',
      'S0337',
      'S0339',
      'S0344',
      'S0346',
      'S0347',
      'S0348',
      'S0352',
      'S0353',
      'S0362',
    ],
    abilities: ['A0069', 'A0070', 'A0078'],
  },
  {
    id: 'CO-OPL-001',
    name: 'Cyber Intel Planner',
    specialityArea: 'Cyber Operational Planning (OPL)',
    category: 'Collect and Operate (CO)',
    description:
      'Develops detailed intelligence plans to satisfy cyber operations requirements. Collaborates with cyber operations planners to identify, validate, and levy requirements for collection and analysis. Participates in targeting selection, validation, synchronization, and execution of cyber actions. Synchronizes intelligence activities to support organization objectives in cyberspace.',
    tasks: [
      'T0563',
      'T0575',
      'T0576',
      'T0579',
      'T0581',
      'T0587',
      'T0590',
      'T0592',
      'T0601',
      'T0627',
      'T0628',
      'T0630',
      'T0636',
      'T0637',
      'T0638',
      'T0639',
      'T0640',
      'T0648',
      'T0656',
      'T0659',
      'T0667',
      'T0670',
      'T0676',
      'T0680',
      'T0690',
      'T0691',
      'T0705',
      'T0709',
      'T0711',
      'T0719',
      'T0726',
      'T0728',
      'T0733',
      'T0734',
      'T0735',
      'T0739',
      'T0743',
      'T0760',
      'T0763',
      'T0772',
      'T0784',
      'T0801',
      'T0808',
      'T0816',
      'T0836',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0036',
      'K0108',
      'K0109',
      'K0120',
      'K0347',
      'K0349',
      'K0350',
      'K0352',
      'K0355',
      'K0358',
      'K0362',
      'K0377',
      'K0379',
      'K0392',
      'K0395',
      'K0399',
      'K0400',
      'K0403',
      'K0405',
      'K0408',
      'K0411',
      'K0414',
      'K0417',
      'K0422',
      'K0427',
      'K0431',
      'K0432',
      'K0435',
      'K0436',
      'K0440',
      'K0444',
      'K0445',
      'K0446',
      'K0455',
      'K0456',
      'K0459',
      'K0460',
      'K0463',
      'K0464',
      'K0465',
      'K0471',
      'K0480',
      'K0494',
      'K0499',
      'K0501',
      'K0502',
      'K0504',
      'K0506',
      'K0507',
      'K0508',
      'K0511',
      'K0512',
      'K0514',
      'K0517',
      'K0518',
      'K0519',
      'K0525',
      'K0538',
      'K0556',
      'K0560',
      'K0561',
      'K0565',
      'K0566',
      'K0572',
      'K0575',
      'K0577',
      'K0578',
      'K0582',
      'K0585',
      'K0586',
      'K0589',
      'K0590',
      'K0591',
      'K0593',
      'K0594',
      'K0595',
      'K0598',
      'K0599',
      'K0602',
      'K0603',
      'K0610',
      'K0612',
      'K0614',
    ],
    skills: [
      'S0176',
      'S0185',
      'S0186',
      'S0203',
      'S0213',
      'S0218',
      'S0249',
      'S0250',
      'S0272',
      'S0273',
      'S0278',
      'S0296',
      'S0297',
      'S0306',
      'S0307',
      'S0308',
      'S0309',
      'S0310',
      'S0312',
      'S0314',
      'S0315',
      'S0318',
      'S0319',
      'S0320',
      'S0321',
      'S0322',
      'S0323',
      'S0331',
      'S0333',
      'S0338',
      'S0340',
      'S0341',
      'S0343',
      'S0345',
      'S0350',
      'S0360',
    ],
    abilities: [
      'A0013',
      'A0066',
      'A0067',
      'A0068',
      'A0070',
      'A0074',
      'A0077',
      'A0081',
      'A0082',
      'A0085',
      'A0089',
      'A0090',
      'A0094',
      'A0096',
      'A0098',
      'A0105',
      'A0160',
    ],
  },
  {
    id: 'CO-OPL-002',
    name: 'Cyber Ops Planner',
    specialityArea: 'Cyber Operational Planning (OPL)',
    category: 'Collect and Operate (CO)',
    description:
      'Develops detailed plans for the conduct or support of the applicable range of cyber operations through collaboration with other planners, operators and/or analysts. Participates in targeting selection, validation, synchronization, and enables integration during the execution of cyber actions.',
    tasks: [
      'T0680',
      'T0690',
      'T0699',
      'T0703',
      'T0704',
      'T0719',
      'T0732',
      'T0733',
      'T0734',
      'T0739',
      'T0741',
      'T0742',
      'T0743',
      'T0747',
      'T0763',
      'T0764',
      'T0772',
      'T0787',
      'T0791',
      'T0795',
      'T0801',
      'T0813',
      'T0823',
      'T0836',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0036',
      'K0108',
      'K0109',
      'K0347',
      'K0349',
      'K0350',
      'K0352',
      'K0362',
      'K0377',
      'K0379',
      'K0392',
      'K0395',
      'K0399',
      'K0400',
      'K0403',
      'K0408',
      'K0411',
      'K0414',
      'K0417',
      'K0422',
      'K0431',
      'K0432',
      'K0435',
      'K0436',
      'K0444',
      'K0445',
      'K0446',
      'K0455',
      'K0464',
      'K0465',
      'K0471',
      'K0480',
      'K0494',
      'K0497',
      'K0499',
      'K0501',
      'K0502',
      'K0504',
      'K0506',
      'K0507',
      'K0508',
      'K0511',
      'K0512',
      'K0514',
      'K0516',
      'K0518',
      'K0519',
      'K0525',
      'K0534',
      'K0538',
      'K0556',
      'K0560',
      'K0561',
      'K0565',
      'K0566',
      'K0572',
      'K0576',
      'K0582',
      'K0585',
      'K0586',
      'K0589',
      'K0590',
      'K0593',
      'K0594',
      'K0597',
      'K0598',
      'K0599',
      'K0603',
      'K0610',
      'K0612',
      'K0614',
    ],
    skills: [
      'S0176',
      'S0185',
      'S0186',
      'S0209',
      'S0213',
      'S0218',
      'S0249',
      'S0250',
      'S0273',
      'S0296',
      'S0297',
      'S0309',
      'S0312',
      'S0322',
      'S0326',
      'S0333',
      'S0349',
      'S0360',
    ],
    abilities: [
      'A0013',
      'A0066',
      'A0067',
      'A0068',
      'A0070',
      'A0074',
      'A0077',
      'A0081',
      'A0082',
      'A0085',
      'A0089',
      'A0090',
      'A0094',
      'A0096',
      'A0098',
      'A0105',
    ],
  },
  {
    id: 'CO-OPL-003',
    name: 'Partner Integration Planner',
    specialityArea: 'Cyber Operational Planning (OPL)',
    category: 'Collect and Operate (CO)',
    description:
      'Works to advance cooperation across organizational or national borders between cyber operations partners. Aids the integration of partner cyber teams by providing guidance, resources, and collaboration to develop best practices and facilitate organizational support for achieving objectives in integrated cyber actions.',
    tasks: [
      'T0571',
      'T0581',
      'T0582',
      'T0601',
      'T0627',
      'T0629',
      'T0635',
      'T0665',
      'T0666',
      'T0669',
      'T0670',
      'T0671',
      'T0699',
      'T0700',
      'T0712',
      'T0729',
      'T0732',
      'T0739',
      'T0747',
      'T0759',
      'T0760',
      'T0763',
      'T0764',
      'T0766',
      'T0772',
      'T0784',
      'T0787',
      'T0795',
      'T0817',
      'T0818',
      'T0823',
      'T0825',
      'T0826',
      'T0836',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0108',
      'K0109',
      'K0350',
      'K0362',
      'K0377',
      'K0379',
      'K0392',
      'K0395',
      'K0400',
      'K0403',
      'K0408',
      'K0411',
      'K0414',
      'K0417',
      'K0422',
      'K0431',
      'K0432',
      'K0435',
      'K0436',
      'K0444',
      'K0455',
      'K0465',
      'K0499',
      'K0501',
      'K0504',
      'K0506',
      'K0507',
      'K0508',
      'K0511',
      'K0512',
      'K0514',
      'K0538',
      'K0585',
      'K0598',
      'K0599',
    ],
    skills: [
      'S0185',
      'S0186',
      'S0213',
      'S0218',
      'S0249',
      'S0250',
      'S0296',
      'S0297',
      'S0326',
      'S0360',
    ],
    abilities: [
      'A0013',
      'A0066',
      'A0067',
      'A0068',
      'A0070',
      'A0074',
      'A0077',
      'A0081',
      'A0082',
      'A0085',
      'A0089',
      'A0090',
      'A0094',
      'A0096',
      'A0098',
      'A0105',
    ],
  },
  {
    id: 'CO-OPS-001',
    name: 'Cyber Operator',
    specialityArea: 'Cyber Operations (OPS)',
    category: 'Collect and Operate (CO)',
    description:
      'Conducts collection, processing, and/or geolocation of systems to exploit, locate, and/or track targets of interest. Performs network navigation, tactical forensic analysis, and, when directed, executes on-net operations.',
    tasks: [
      'T0566',
      'T0567',
      'T0598',
      'T0609',
      'T0610',
      'T0612',
      'T0616',
      'T0618',
      'T0619',
      'T0620',
      'T0623',
      'T0643',
      'T0644',
      'T0664',
      'T0677',
      'T0696',
      'T0697',
      'T0724',
      'T0740',
      'T0756',
      'T0768',
      'T0774',
      'T0796',
      'T0804',
      'T0828',
      'T0829',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0009',
      'K0021',
      'K0051',
      'K0109',
      'K0142',
      'K0224',
      'K0363',
      'K0372',
      'K0373',
      'K0375',
      'K0379',
      'K0403',
      'K0406',
      'K0420',
      'K0423',
      'K0427',
      'K0428',
      'K0429',
      'K0430',
      'K0433',
      'K0438',
      'K0440',
      'K0452',
      'K0468',
      'K0480',
      'K0481',
      'K0485',
      'K0486',
      'K0516',
      'K0528',
      'K0530',
      'K0531',
      'K0536',
      'K0560',
      'K0565',
      'K0573',
      'K0608',
      'K0609',
    ],
    skills: [
      'S0062',
      'S0182',
      'S0183',
      'S0190',
      'S0192',
      'S0202',
      'S0206',
      'S0221',
      'S0236',
      'S0242',
      'S0243',
      'S0252',
      'S0255',
      'S0257',
      'S0266',
      'S0267',
      'S0270',
      'S0275',
      'S0276',
      'S0281',
      'S0282',
      'S0293',
      'S0295',
      'S0298',
      'S0299',
      'S0363',
    ],
    abilities: ['A0095', 'A0097', 'A0099', 'A0100'],
  },
  {
    id: 'IN-INV-001',
    name: 'Cyber Crime Investigator',
    specialityArea: 'Cyber Investigation (INV)',
    category: 'Investigate (IN)',
    description:
      'Identifies, collects, examines, and preserves evidence using controlled and documented analytical and investigative techniques.',
    tasks: [
      'T0031',
      'T0059',
      'T0096',
      'T0103',
      'T0104',
      'T0110',
      'T0112',
      'T0113',
      'T0114',
      'T0120',
      'T0193',
      'T0225',
      'T0241',
      'T0343',
      'T0346',
      'T0360',
      'T0386',
      'T0423',
      'T0430',
      'T0433',
      'T0453',
      'T0471',
      'T0479',
      'T0523',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0046',
      'K0070',
      'K0107',
      'K0110',
      'K0114',
      'K0118',
      'K0123',
      'K0125',
      'K0128',
      'K0144',
      'K0155',
      'K0156',
      'K0168',
      'K0209',
      'K0231',
      'K0244',
      'K0251',
      'K0351',
      'K0624',
    ],
    skills: ['S0047', 'S0068', 'S0072', 'S0086'],
    abilities: ['A0174', 'A0175'],
  },
  {
    id: 'IN-FOR-001',
    name: 'Law Enforcement /CounterIntelligence Forensics Analyst',
    specialityArea: 'Digital Forensics (FOR)',
    category: 'Investigate (IN)',
    description:
      'Conducts detailed investigations on computer-based crimes establishing documentary or physical evidence, to include digital media and logs associated with cyber intrusion incidents.',
    tasks: [
      'T0059',
      'T0096',
      'T0220',
      'T0308',
      'T0398',
      'T0401',
      'T0403',
      'T0411',
      'T0419',
      'T0425',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0017',
      'K0021',
      'K0042',
      'K0060',
      'K0070',
      'K0077',
      'K0078',
      'K0107',
      'K0109',
      'K0117',
      'K0118',
      'K0119',
      'K0122',
      'K0123',
      'K0125',
      'K0128',
      'K0131',
      'K0132',
      'K0133',
      'K0134',
      'K0145',
      'K0155',
      'K0156',
      'K0167',
      'K0168',
      'K0179',
      'K0182',
      'K0183',
      'K0184',
      'K0185',
      'K0186',
      'K0187',
      'K0188',
      'K0189',
      'K0305',
      'K0624',
    ],
    skills: [
      'S0032',
      'S0046',
      'S0047',
      'S0062',
      'S0065',
      'S0067',
      'S0068',
      'S0069',
      'S0071',
      'S0073',
      'S0074',
      'S0075',
      'S0087',
      'S0088',
      'S0089',
      'S0090',
      'S0091',
      'S0092',
      'S0093',
    ],
    abilities: ['A0005', 'A0175'],
  },
  {
    id: 'IN-FOR-002',
    name: 'Cyber Defense Forensics Analyst',
    specialityArea: 'Digital Forensics (FOR)',
    category: 'Investigate (IN)',
    description:
      'Analyzes digital evidence and investigates computer security incidents to derive useful information in support of system/network vulnerability mitigation.',
    tasks: [
      'T0027',
      'T0036',
      'T0048',
      'T0049',
      'T0075',
      'T0087',
      'T0103',
      'T0113',
      'T0165',
      'T0167',
      'T0168',
      'T0172',
      'T0173',
      'T0175',
      'T0179',
      'T0182',
      'T0190',
      'T0212',
      'T0216',
      'T0238',
      'T0240',
      'T0241',
      'T0253',
      'T0279',
      'T0285',
      'T0286',
      'T0287',
      'T0288',
      'T0289',
      'T0312',
      'T0396',
      'T0397',
      'T0398',
      'T0399',
      'T0400',
      'T0401',
      'T0432',
      'T0532',
      'T0546',
    ],
    knowledge: [
      'K0001',
      'K0002',
      'K0003',
      'K0004',
      'K0005',
      'K0006',
      'K0018',
      'K0021',
      'K0042',
      'K0060',
      'K0070',
      'K0077',
      'K0078',
      'K0109',
      'K0117',
      'K0118',
      'K0119',
      'K0122',
      'K0123',
      'K0125',
      'K0128',
      'K0131',
      'K0132',
      'K0133',
      'K0134',
      'K0145',
      'K0155',
      'K0156',
      'K0167',
      'K0168',
      'K0179',
      'K0182',
      'K0183',
      'K0184',
      'K0185',
      'K0186',
      'K0187',
      'K0188',
      'K0189',
      'K0224',
      'K0254',
      'K0255',
      'K0301',
      'K0304',
      'K0347',
      'K0624',
    ],
    skills: [
      'S0032',
      'S0047',
      'S0062',
      'S0065',
      'S0067',
      'S0068',
      'S0069',
      'S0071',
      'S0073',
      'S0074',
      'S0075',
      'S0087',
      'S0088',
      'S0089',
      'S0090',
      'S0091',
      'S0092',
      'S0093',
      'S0131',
      'S0132',
      'S0133',
      'S0156',
    ],
    abilities: ['A0005', 'A0043'],
  },
];
