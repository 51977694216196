import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomVariable, CustomVariableSaveDTO } from '../../models';

@Injectable()
export class CustomVariableService {
  private static API_PATH = 'api/custom-variables';

  constructor(private http: HttpClient) {}

  getGlobalCustomVariables(): Observable<CustomVariable[]> {
    return this.http
      .get<CustomVariable[]>(CustomVariableService.API_PATH)
      .pipe(map((customVariables) => customVariables.map((data) => new CustomVariable(data))));
  }

  createCustomVariable(saveDTO: CustomVariableSaveDTO): Observable<Boolean> {
    return this.http
      .post<CustomVariableSaveDTO>(CustomVariableService.API_PATH, saveDTO)
      .pipe(map(() => true));
  }

  updateCustomVariable(
    customVariableId: string,
    saveDTO: CustomVariableSaveDTO
  ): Observable<Boolean> {
    return this.http
      .put<CustomVariableSaveDTO>(`${CustomVariableService.API_PATH}/${customVariableId}`, saveDTO)
      .pipe(map(() => true));
  }

  deleteCustomVariable(customVariableId: string): Observable<Boolean> {
    return this.http
      .delete(`${CustomVariableService.API_PATH}/${customVariableId}`)
      .pipe(map(() => true));
  }
}
