import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserScoringData } from '../../models';

@Injectable()
export class UserScoringService {
  constructor(private http: HttpClient) {}

  getScoringData(
    exerciseId: string,
    teamId: string,
    timestamp: string
  ): Observable<UserScoringData[]> {
    let params = new HttpParams();
    if (teamId) {
      params = params.set('team', teamId);
    }
    if (timestamp) {
      params = params.set('timestamp', timestamp);
    }
    return this.http
      .get<UserScoringData[]>(`api/widget/user-scoring/exercise/${exerciseId}`, { params: params })
      .pipe(
        map((dataArray) => {
          return dataArray.map((data) => new UserScoringData(data));
        })
      );
  }
}
