import { BaseModel } from '../shared/base.model';
import { CampaignPhase } from './campaign-phase.model';
import { MitreTags, NiceIds } from './ctf/ctf-mission.model';
import { NetworkSegment } from './network-segment-model';
import { TargetGroup } from './target-group.model';
import { Target } from './target.model';
import { NamedEntity } from '@cybexer/ngx-commons';

export class Exercise extends BaseModel {
  id: string;
  name: string;
  description?: string;
  blueTeams: BlueTeam[] = [];
  taskCategories: CTFTaskCategory[];
  campaignPhases: CampaignPhase[] = [];
  networkSegments: NetworkSegment[] = [];
  pointsBudget: PointsBudget;
  type: ExerciseType;
  definition: any;
  isIndividualAssessment?: boolean;
  isNiceFrameworkSupported?: boolean;
  workRoleId?: string;
  incidentReportTemplate: string;
  situationReportTemplate: string;
  isTargetManagementEnabled: boolean;
  isTeamSettingsEnabled: boolean;
  maxSpecialScore: number;
  imageId?: string;
  scheduledStartTime: Date;
  isGuacamoleConsoleLoginEnabled: boolean;
  showTeamScoring: boolean;
  promptOverride?: NamedEntity;

  constructor(data?) {
    super(data);
    if (data.hasOwnProperty('blueTeams')) {
      this.blueTeams = data.blueTeams.map((blueTeam) => new BlueTeam(blueTeam));
    }
    if (data.hasOwnProperty('taskCategories')) {
      this.taskCategories = data.taskCategories.map(
        (taskCategory) => new CTFTaskCategory(taskCategory)
      );
    }
    if (data.hasOwnProperty('campaignPhases')) {
      this.campaignPhases = data.campaignPhases.map(
        (campaignPhase) => new CampaignPhase(campaignPhase)
      );
    }
    if (data.hasOwnProperty('networkSegments')) {
      this.networkSegments = data.networkSegments.map(
        (networkSegment) => new NetworkSegment(networkSegment)
      );
    }
    if (data.hasOwnProperty('pointsBudget') && data.pointsBudget != null) {
      this.pointsBudget = new PointsBudget(data.pointsBudget);
    }
    if (data.hasOwnProperty('promptOverride') && data.promptOverride != null) {
      this.promptOverride = new NamedEntity(data.promptOverride);
    }
  }
}

export class ExerciseWithStatus extends BaseModel {
  exercise: Exercise;
  status: ExerciseStatus;

  constructor(data) {
    super(data);
  }
}

export class ExerciseStatusInfo extends BaseModel {
  id: string;
  status: ExerciseStatus;
  isResetInProgress?: boolean;

  constructor(data) {
    super(data);
  }
}

export class BlueTeam extends BaseModel {
  id: string;
  name: string;
  ldapGroup: string;
  targetGroups?: TargetGroup[];
  tasks?: CTFTask[];
  customName: string;
  isCampaignLiveEnabled: boolean;

  constructor(data?) {
    super(data);
    if (data && data.hasOwnProperty('targetGroups') && data.targetGroups) {
      this.targetGroups = data.targetGroups.map((targetGroup) => new TargetGroup(targetGroup));
    }
    if (data && data.hasOwnProperty('tasks') && data.tasks) {
      this.tasks = data.tasks.map((task) => new CTFTask(task));
    }
  }
}

export class TeamSettings extends BaseModel {
  teamId: string;
  teamName: string;

  constructor(data?) {
    super(data);
  }
}

export class PointsBudget extends BaseModel {
  total: number;
  breakdown: BreakDownItem[];

  constructor(data?) {
    super(data);

    if (data && data.hasOwnProperty('breakdown')) {
      this.breakdown = data.breakdown.map((breakDownItem) => new BreakDownItem(breakDownItem));
    }
  }
}

export class BreakDownItem extends BaseModel {
  name: string;
  percent: number;

  constructor(data?) {
    super(data);
  }
}

export class ExerciseDuration extends BaseModel {
  startexEvents: string[];
  endexEvents: string[];

  constructor(data?) {
    super(data);
  }
}

export class StartexAndEndex extends BaseModel {
  startex: string;
  endex: string;

  constructor(data?) {
    super(data);
  }
}

export class SystemEvent extends BaseModel {
  eventType: SystemEventType;
  message: string;

  constructor(data?) {
    super(data);
  }
}

export type SystemEventType = 'STARTEX' | 'ENDEX';

export class ExerciseOverview extends BaseModel {
  id: string;
  name: string;
  description?: string;
  status: string;
  type: ExerciseType;
  tasksCount?: number;
  niceIds?: NiceIds;
  mitreTags?: MitreTags;
  duration?: number;
  isIndividualAssessment?: boolean;
  isTeamSettingsEnabled?: boolean;
  workRoleId?: string;
  imageId?: string;
  isResetInProgress: boolean;

  get niceIdsCount(): number {
    return (
      this.niceIds?.taskIds.length +
      this.niceIds?.knowledgeIds.length +
      this.niceIds?.skillIds.length +
      this.niceIds?.abilityIds.length
    );
  }

  get mitreTagsCount(): number | undefined {
    return this.mitreTags?.tags?.length;
  }
}

export enum ExerciseType {
  CAMPAIGN = 'CAMPAIGN',
  CTF = 'CTF',
  HYBRID = 'HYBRID',
  VERSUS = 'VERSUS',
  UNPUBLISHED = 'UNPUBLISHED',
}

export enum ExerciseStatus {
  NOT_STARTED = 'NOT_STARTED',
  RUNNING = 'RUNNING',
  STOPPED = 'STOPPED',
  UNPUBLISHED = 'UNPUBLISHED',
  SCHEDULED = 'SCHEDULED',
}

export class CTFTask extends BaseModel {
  title: string;
  question: string;
  description: string;
  hints: CTFHint[];
  targets: Target[];
  score: number;
  category: string;

  constructor(data?) {
    super(data);
    if (data && data.hasOwnProperty('hints')) {
      this.hints = data.hints.map((hint) => new CTFHint(hint));
    }
    if (data && data.hasOwnProperty('targets')) {
      this.targets = data.targets.map((target) => new Target(target));
    }
  }
}

export class CTFHint extends BaseModel {
  id: string;
  content: string;
  penalty: number;
}

export class CTFHintUseDTO extends BaseModel {
  usedHint: CTFHint;
  nextHintPenalty: number;

  constructor(data?) {
    super(data);
    if (data && data.hasOwnProperty('usedHint')) {
      this.usedHint = new CTFHint(data.usedHint);
    }
  }
}

export class CTFTaskCategory extends BaseModel {
  code: string;
  name: string;
  /**
   * Font Awesome icon class (see version in package.json)
   */
  icon: string;
}

export class ExerciseResetSteps {
  totalSteps: number;
  stepsCompleted: number;

  constructor(number: number, totalSteps: number) {
    this.stepsCompleted = number;
    this.totalSteps = totalSteps;
  }
}
