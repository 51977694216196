import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SettingsService } from '../services';
import { ObserverKeyDialogComponent } from './observer-key-dialog/observer-key-dialog.component';

/**
 * Require valid `observerKey` to be given in either (checking sequentially):
 * 1. route parameter (`http://isa-host/path/to/view;ok=$OBSERVER_KEY`)
 * 2. localStorage (key: `ok`)
 * 3. dialog which asks for the key
 */
@Injectable({
  providedIn: 'root',
})
export class ObserverGuard {
  private static OBSERVER_KEY_ROUTE_PARAMETER = 'ok';

  constructor(
    private settingsService: SettingsService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkObserverKey(next.params[ObserverGuard.OBSERVER_KEY_ROUTE_PARAMETER]).pipe(
      switchMap((isCorrectFromParam) =>
        isCorrectFromParam
          ? of(true)
          : this.checkObserverKey(localStorage.getItem(SettingsService.OBSERVER_KEY_LOCALSTORAGE))
      ),
      switchMap((isCorrect) =>
        isCorrect
          ? of(true)
          : this.dialog
              .open(ObserverKeyDialogComponent)
              .afterClosed()
              .pipe(
                map((result) =>
                  result && result.isKeyValidated ? true : this.router.parseUrl('/')
                )
              )
      )
    );
  }

  private checkObserverKey(observerKey: string): Observable<boolean> {
    if (observerKey == null || observerKey.length === 0) return of(false);
    return this.settingsService.checkObserverKey(observerKey);
  }
}
