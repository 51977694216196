import { Component, Input } from '@angular/core';
import { OBJECTIVE_STATUS, ObjectiveStatus } from '../../../../shared';

@Component({
  selector: 'isa-objective-status-icon',
  templateUrl: './objective-status-icon.component.html',
  styleUrls: ['./objective-status-icon.component.scss'],
})
export class ObjectiveStatusIconComponent {
  @Input() status: ObjectiveStatus;
  OBJECTIVE_STATUS = OBJECTIVE_STATUS;
}
