import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MitreDisplayObject, MitreTactic, MitreTags } from '../../models';

@Injectable()
export class MitreIntegrationService {
  private static MITRE_INTEGRATION_API_PATH = '/api/mitre';

  constructor(private http: HttpClient) {}

  getMitreData(): Observable<MitreTactic[]> {
    return this.http
      .get<MitreTactic[]>(`${MitreIntegrationService.MITRE_INTEGRATION_API_PATH}/data`)
      .pipe(
        map((mitreTacticList) => {
          return mitreTacticList.map((data) => new MitreTactic(data));
        })
      );
  }

  getMitreDisplayData(mitreTags: MitreTags): Observable<MitreDisplayObject[]> {
    let params = new HttpParams();
    mitreTags.tags.forEach((tag) => {
      params = params.append('tag', tag);
    });
    return this.http
      .get<MitreDisplayObject[]>(`${MitreIntegrationService.MITRE_INTEGRATION_API_PATH}/tags`, {
        params,
      })
      .pipe(
        map((dataArray) => {
          return dataArray.map((data) => new MitreDisplayObject(data));
        })
      );
  }
}
