import { BaseModel } from '../shared/base.model';

export class ReportList extends BaseModel {
  timestamp: Date;
  targetId?: string;
  type: string;
  incidentType?: string;
  targetName?: string;

  constructor(data?) {
    super(data);
  }
}
