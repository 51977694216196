import { BaseModel } from '../shared/base.model';

export class Objective extends BaseModel {
  id: string;
  name: string;
  category: string;
  campaignPhaseId: string;
  reportTemplate?: string;

  constructor(data?) {
    super(data);
  }
}
