<div class="error-page">
  <div class="glitch" [attr.data-text]="errorCode">{{ errorCode }}</div>
  <div class="glitch glitch-text" [attr.data-text]="'ui.error.matrixGlitch' | translate">
    {{ 'ui.error.matrixGlitch' | translate }}
  </div>
  <div class="click-container">
    {{ 'ui.error.maybe' | translate }}
    <a (click)="navigateToLandingPage()">{{ 'ui.error.clickingHere' | translate }}</a>
    {{ 'ui.error.helps' | translate }}
  </div>
  <div class="message">{{ 'ui.error.wereDoingIt' | translate }}</div>
</div>
