import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BlueTeam, Exercise } from '../../../../models';
import { ExerciseService, IntervalService, StatusSummaryService } from '../../../../services';
import { FilterStateModel, FilterStateService } from '../../../../shared';
import { SegmentStatusChartData } from '../status-chart-data';

@UntilDestroy()
@Component({
  selector: 'isa-segment-status',
  templateUrl: './segment-status.component.html',
  styleUrls: ['./segment-status.component.scss'],
})
export class SegmentStatusComponent implements OnInit {
  filter$: Observable<Partial<FilterStateModel>>;
  loading = true;
  segmentStatusChartData: SegmentStatusChartData = new SegmentStatusChartData();
  exerciseId: string;
  teams: BlueTeam[] = [];

  constructor(
    private statusSummaryService: StatusSummaryService,
    private exerciseService: ExerciseService,
    private intervalService: IntervalService,
    private router: Router,
    public filterStateService: FilterStateService
  ) {}

  ngOnInit(): void {
    this.filter$ = this.filterStateService.filter$('team');
    this.exerciseService.activeExercise
      .pipe(
        filter((exercise: Exercise) => !!exercise),
        untilDestroyed(this)
      )
      .subscribe((exercise: Exercise) => {
        this.loading = true;
        this.segmentStatusChartData.clearData();
        this.exerciseId = exercise.id;
        this.teams = exercise.blueTeams;
      });

    this.intervalService
      .getWidgetRefreshInterval()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.processData();
      });

    this.filter$.pipe(untilDestroyed(this)).subscribe(() => this.processData());
  }

  processData(): void {
    const { team } = this.filterStateService.snapshot();
    if (this.exerciseId && team) {
      this.statusSummaryService
        .getSegmentStatusData(this.exerciseId, team)
        .pipe(
          filter((data) => !!(data && data.length)),
          untilDestroyed(this)
        )
        .subscribe((data) => {
          this.segmentStatusChartData.setData(data);
          this.loading = false;
        });
    }
  }

  barClicked(barIndex: number): void {
    const networkSegment = this.segmentStatusChartData.data[barIndex].networkSegmentId;
    this.filterStateService.setFilter('networkSegment', networkSegment);
    this.router.navigate(['/app/gamenet/target-checks-status']);
  }
}
