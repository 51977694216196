import { BaseModel } from '../shared/base.model';

export interface ReportData {
  reportId: string;
  teamId: string;
  teamName: string;
  status: string;
  timestamp: Date;
  whiteTeamMember?: string;
  feedback?: string;
}

export class ReportFilter extends BaseModel {
  offset?: number;
  limit?: number;
  unconfirmedOnlyFilter?: boolean;
  teamIdFilter?: string;
  textFilter?: string;
  sortColumn?: string;
  sortOrder?: string;

  constructor(data?) {
    super(data);
  }
}

export abstract class ReportFilterResult<T extends ReportData> extends BaseModel {
  totalCount: number;
  filteredCount: number;
  reports: T[];

  constructor(
    data,
    private reportDataType: new (data: any) => T
  ) {
    super(data);

    if (data && data.hasOwnProperty('reports')) {
      this.reports = data.reports.map((it) => new reportDataType(it));
    }
  }
}
