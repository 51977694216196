import { BaseModel } from './base.model';

export class CommitMeta extends BaseModel {
  id: string;
  shortId: string;
  branch: string;

  constructor(data?) {
    super(data);
  }
}
