import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QuillEditorComponent } from 'ngx-quill';
import QuillNamespace from 'quill';
import { Subject } from 'rxjs';
import { Integration, TargetStatus } from '../../../../models';
import {
  EVENT_PARAMETER_DATA,
  IntegrationEvent,
  IntegrationEventSaveDTO,
  IntegrationEventSubjectType,
  IntegrationEventType,
  TargetStatusChangeEvent,
} from '../../../../models/shared/integration-event.model';
import { FormUtil } from '../../../form.util';

const Quill: any = QuillNamespace;

@UntilDestroy()
@Component({
  selector: 'isa-target-status-change-event-form',
  templateUrl: './target-status-change-event-form.component.html',
  styleUrls: ['./target-status-change-event-form.component.scss'],
})
export class TargetStatusChangeEventFormComponent implements OnInit, AfterViewInit {
  eventForm: UntypedFormGroup;
  eventParameterData = EVENT_PARAMETER_DATA;

  @Input() subjectId: string;
  @Input() subjectType: IntegrationEventSubjectType = IntegrationEventSubjectType.TARGET;
  @Input() event?: IntegrationEvent;
  @Input() integration?: Integration;
  @Input() triggerSubmit: Subject<void>;
  @Output() onChange = new EventEmitter<IntegrationEventSaveDTO>();
  @ViewChild(QuillEditorComponent) quillComponent: QuillEditorComponent;

  private quillEditor;
  private parameterFormat = {
    color: '#d55858',
    italic: true,
    bold: true,
  };

  constructor() {}

  ngOnInit() {
    if (!this.event) {
      this.event = new TargetStatusChangeEvent({});
    }
    this.eventForm = this.convertToEventForm(this.event as TargetStatusChangeEvent);
    if (this.triggerSubmit) {
      this.triggerSubmit.pipe(untilDestroyed(this)).subscribe(() => this.onSubmit());
    }
  }

  ngAfterViewInit() {
    if (!this.quillComponent) return;
    this.quillComponent.onEditorCreated.subscribe(() => {
      this.quillEditor = this.quillComponent.quillEditor;
      this.formatParametersInQuillEditor();
      this.quillEditor.on('text-change', () => this.formatParametersInQuillEditor());
    });
  }

  private formatParametersInQuillEditor() {
    // Remove all formats
    this.quillEditor.removeFormat(0, this.quillEditor.getLength() - 1, Quill.sources.SILENT);

    const delta = this.quillEditor.getContents();
    const text = delta.ops[0].insert;

    this.event.getAvailableParameters().forEach((parameter) => {
      const parameterValue = EVENT_PARAMETER_DATA[parameter].value;
      // Locate all parameters in text
      let startIndex = -1;
      const indexes = [];
      while ((startIndex = text.indexOf(parameterValue, startIndex + 1)) > -1) {
        indexes.push(startIndex);
      }
      // Format all parameters
      indexes.forEach((index) => {
        this.quillEditor.formatText(
          index,
          parameterValue.length,
          this.parameterFormat,
          Quill.sources.SILENT
        );
      });
    });
  }

  convertToEventForm = (event: TargetStatusChangeEvent) => {
    const formGroup = new UntypedFormGroup({
      type: new UntypedFormControl(IntegrationEventType.TARGET_STATUS_CHANGE, Validators.required),
      url: new UntypedFormControl(event != null ? event.url : '', Validators.required),
      body: new UntypedFormControl(event != null ? event.body : '', Validators.required),
    });

    const statusMapping = new UntypedFormGroup({});
    formGroup.setControl('statusMapping', statusMapping);
    Object.keys(TargetStatus).forEach((targetStatus) => {
      statusMapping.setControl(
        targetStatus,
        new UntypedFormControl(
          event != null && event.statusMapping != null ? event.statusMapping[targetStatus] : '',
          Validators.required
        )
      );
    });

    return formGroup;
  };

  getStatusMappingFormControlNames = () => {
    const statusMappingFormGroup = this.eventForm.get('statusMapping') as UntypedFormGroup;
    return Object.keys(statusMappingFormGroup.controls);
  };

  onSubmit = () => {
    if (!this.eventForm.valid) {
      FormUtil.markFormControlAsTouchedRecursively(this.eventForm);
      return;
    }
    const saveDTO = IntegrationEventSaveDTO.constructFromData(this.eventForm.value);
    this.onChange.emit(saveDTO);
  };

  insertParameter(change: MatSelectChange) {
    const insertedParameter = change.value;
    change.source._selectionModel.clear();
    const selection = this.quillEditor.getSelection(true);
    const currentIndex = selection.index;

    if (selection.length > 0) {
      this.quillEditor.deleteText(currentIndex, selection.length, Quill.sources.SILENT);
    }

    this.quillEditor.insertText(
      currentIndex,
      insertedParameter,
      this.parameterFormat,
      Quill.sources.USER
    );
    this.quillEditor.setSelection(currentIndex + insertedParameter.length, 0, Quill.sources.SILENT);
  }
}
