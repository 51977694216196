import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserDetails, UserEventAction, UserListItem, UserSaveDTO } from '../../models';
import { EventSourceUtil } from '../../shared/eventsource.util';
import { RoleAssignmentResult } from '../../views/administration/role-assignment-table/role-assignment-dialog/role-assignment-dialog.component';

@Injectable()
export class UsersService {
  private static BASE_API_URL = 'api/users';

  constructor(
    private http: HttpClient,
    private zone: NgZone
  ) {}

  createUser(userSaveDTO: UserSaveDTO): Observable<boolean> {
    return this.http.post(`api/widget/users`, userSaveDTO).pipe(map(() => true));
  }

  changeCurrentUserPassword(password: string): Observable<boolean> {
    return this.http
      .post(`${UsersService.BASE_API_URL}/current/password`, password, {
        headers: new HttpHeaders({
          'Content-Type': 'text/plain',
        }),
      })
      .pipe(map(() => true));
  }

  changePassword(username: string, password: string): Observable<boolean> {
    return this.http
      .post(`api/widget/users/${username}/password`, password, {
        headers: new HttpHeaders({
          'Content-Type': 'text/plain',
        }),
      })
      .pipe(map(() => true));
  }

  getUsers(): Observable<UserListItem[]> {
    return this.http
      .get<UserListItem[]>('api/widget/users')
      .pipe(map((users) => users.map((user) => new UserListItem(user))));
  }

  getUser(username: string): Observable<UserDetails> {
    return this.http
      .get<UserDetails>(`api/widget/users/${username}`)
      .pipe(map((data) => new UserDetails(data)));
  }

  saveRole(username: string, assignmentResult: RoleAssignmentResult): Observable<boolean> {
    return this.http
      .post(`api/widget/users/${username}/roles`, {
        code: assignmentResult.code,
        targetsByDomain: assignmentResult.targetsByDomain,
      })
      .pipe(map(() => true));
  }

  removeRole(username: string, roleCode: string): Observable<boolean> {
    return this.http.delete(`api/widget/users/${username}/roles/${roleCode}`).pipe(map(() => true));
  }

  listenForUserEvents(): Observable<UserEventAction> {
    return EventSourceUtil.create(
      `${UsersService.BASE_API_URL}/current/events/subscribe`,
      this.zone
    );
  }

  updateLastSeenNotifications(): Observable<boolean> {
    return this.http
      .put(`${UsersService.BASE_API_URL}/current/last-seen-notifications`, null)
      .pipe(map(() => true));
  }

  removeUser(username: string): Observable<boolean> {
    return this.http.delete(`api/widget/users/${username}/delete`).pipe(map(() => true));
  }
}
