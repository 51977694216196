import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IsaNotification } from '../../models/gamenet/isa-notification.model';

@Injectable()
export class IsaNotificationService {
  private static BASE_API_URL = 'api/notifications';

  constructor(private http: HttpClient) {}

  find(exerciseId: string, teamId?: string): Observable<IsaNotification[]> {
    return this.http
      .get<IsaNotification[]>(`${IsaNotificationService.BASE_API_URL}/exercise/${exerciseId}`, {
        params: { ...(teamId && { teamId }) },
      })
      .pipe(
        map((dataArray) => {
          return dataArray.map((data) => IsaNotification.constructFromData(data));
        })
      );
  }
}
