import { BaseModel } from '../shared/base.model';

export class SituationReport extends BaseModel {
  username: string;
  reportText: string;

  constructor(data?) {
    super(data);
  }
}
