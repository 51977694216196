import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationsService } from '@cybexer/ngx-commons';
import { finalize } from 'rxjs/operators';
import { SoundService } from '../../../../services';

@Component({
  selector: 'isa-sound-removal-dialog',
  templateUrl: './sound-removal-dialog.component.html',
  styleUrls: ['./sound-removal-dialog.component.scss'],
})
export class SoundRemovalDialogComponent {
  processing = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private soundService: SoundService,
    private notificationsService: NotificationsService,
    private dialogRef: MatDialogRef<SoundRemovalDialogComponent>
  ) {}

  deleteSound() {
    if (this.data == null || this.data.soundId == null) {
      this.notificationsService.error('ui.settings.deleteSoundError');
      return;
    }

    this.processing = true;
    this.soundService
      .deleteSound(this.data.soundId)
      .pipe(finalize(() => (this.processing = false)))
      .subscribe(() => {
        this.notificationsService.success('ui.settings.soundRemoved');
        this.dialogRef.close(true);
      });
  }
}
