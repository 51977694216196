<div class="report-list">
  <mat-card>
    <div widgetHandle class="live-card-title">
      <i class="far fa-comment" aria-hidden="true"></i
      >{{ 'ui.widget.reportList.reportsLive' | translate }}
    </div>
    @if ((reportList.length === 0 && dataLoaded) || !teamId) {
      <div class="no-data">{{ 'ui.noDataError' | translate }}</div>
    }
    @for (report of reportList; track report) {
      <div class="report-row">
        <div class="timestamp">[{{ report.timestamp | isaDate: 'mediumTime' }}]</div>
        <div>
          @if (report.type === 'SITUATION_REPORT') {
            <span class="nowrap">{{ 'ui.enums.' + report.type | translate }}</span>
          }
          @if (report.incidentType) {
            <span class="nowrap">{{ 'ui.enums.' + report.incidentType | translate }}</span>
          }
        </div>
        <div class="truncate">
          <span>{{ report.targetName }}</span>
        </div>
      </div>
    }
  </mat-card>
</div>
