import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Leaderboard } from '../../../models/gamenet/ctf/leaderboard.model';

@Injectable({
  providedIn: 'root',
})
export class LeaderBoardService {
  constructor(private http: HttpClient) {}

  getData(exerciseId: string): Observable<Leaderboard> {
    return this.http
      .get<Leaderboard>(`${LeaderBoardService.getBaseUrl(exerciseId)}`)
      .pipe(map((data) => new Leaderboard(data)));
  }

  private static getBaseUrl(exerciseId: string): string {
    return `api/ctf/leaderboard/exercises/${exerciseId}`;
  }
}
