<div id="avatar-gallery">
  @for (avatarSrc of avatars; track avatarSrc) {
    <div
      (click)="onAvatarClick(avatarSrc)"
      class="avatar-cell"
      [ngClass]="{ selected: selectedAvatar === avatarSrc }"
    >
      <isa-avatar [src]="avatarSrc"></isa-avatar>
    </div>
  }
</div>
