import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  MediaInfo,
  NewsInjectComment,
  NewsInjectCommentSaveDTO,
  NewsInjection,
  NewsInjectPublication,
  NewsInjectTeam,
} from '../../models';
import { FileUploadInfo } from '../../models/shared/file-upload-info.model';

@Injectable()
export class NewsInjectService {
  private static BASE_API_URL = 'api/news/injects';
  private static BASE_MEDIA_URL = 'api/emi';
  newsInjectListChanged: EventEmitter<NewsInjection> = new EventEmitter();

  constructor(private http: HttpClient) {}

  getMediaUrl(mediaId: string, exerciseId: string) {
    return `${NewsInjectService.BASE_MEDIA_URL}/file/download/${mediaId}/exercise/${exerciseId}`;
  }

  saveInject(inject: NewsInjection): Observable<boolean> {
    return this.http
      .post(`${NewsInjectService.BASE_API_URL}/exercise/${inject.exerciseId}`, inject)
      .pipe(map(() => true));
  }

  getInject(exerciseId: string, injectId: string): Observable<NewsInjection> {
    return this.http
      .get<NewsInjection>(`${NewsInjectService.BASE_API_URL}/${injectId}/exercise/${exerciseId}`)
      .pipe(map((data) => new NewsInjection(data)));
  }

  getInjects(exerciseId: string): Observable<NewsInjection[]> {
    return this.http
      .get<NewsInjection[]>(`${NewsInjectService.BASE_API_URL}/exercise/${exerciseId}`)
      .pipe(
        map((dataArray) => {
          return dataArray.map((data) => new NewsInjection(data));
        })
      );
  }

  getLatestInject(exerciseId: string): Observable<NewsInjection> {
    return this.http
      .get<NewsInjection>(
        `${NewsInjectService.BASE_API_URL}/exercise/${exerciseId}/latest-published`
      )
      .pipe(map((data) => (data ? new NewsInjection(data) : null)));
  }

  getInjectTeamsData(exerciseId: string, injectId: string): Observable<NewsInjectTeam[]> {
    return this.http
      .get<
        NewsInjectTeam[]
      >(`${NewsInjectService.BASE_API_URL}/${injectId}/exercise/${exerciseId}/teams`)
      .pipe(
        map((dataArray) => {
          return dataArray.map((data) => new NewsInjectTeam(data));
        })
      );
  }

  deleteInject(exerciseId: string, injectId: string): Observable<boolean> {
    return this.http
      .delete(`${NewsInjectService.BASE_API_URL}/${injectId}/exercise/${exerciseId}`)
      .pipe(map(() => true));
  }

  uploadChunk(fileUploadInfo: FileUploadInfo): Promise<MediaInfo> {
    const formData = new FormData();
    formData.append('file', fileUploadInfo.file);
    formData.append('fileType', fileUploadInfo.fileType);
    formData.append('fileSize', fileUploadInfo.fileSize);
    formData.append('offset', fileUploadInfo.offset);
    formData.append('customFileName', fileUploadInfo.customFileName);
    formData.append('fileId', fileUploadInfo.fileId);
    formData.append('isFolder', String(fileUploadInfo.isFolder));

    const mediaInfo$ = this.http
      .post(
        `${NewsInjectService.BASE_MEDIA_URL}/injects/exercise/${fileUploadInfo.exerciseId}`,
        formData
      )
      .pipe(map((data) => new MediaInfo(data)));
    return lastValueFrom(mediaInfo$);
  }

  publishInject(publication: NewsInjectPublication): Observable<boolean> {
    return this.http
      .post<NewsInjectPublication>(
        `${NewsInjectService.BASE_API_URL}/${publication.newsInjectId}/exercise/${publication.exerciseId}/publication`,
        publication
      )
      .pipe(map(() => true));
  }

  publishInjectToTeams(publications: NewsInjectPublication[]): Observable<boolean> {
    return this.http
      .post<NewsInjectPublication[]>(`${NewsInjectService.BASE_API_URL}/publications`, publications)
      .pipe(map(() => true));
  }

  unpublishInject(
    exerciseId: string,
    injectId: string,
    publicationId: string
  ): Observable<boolean> {
    return this.http
      .delete(
        `${NewsInjectService.BASE_API_URL}/${injectId}/exercise/${exerciseId}/publication/${publicationId}`
      )
      .pipe(map(() => true));
  }

  getComments(
    injectId: string,
    exerciseId: string,
    teamId?: string
  ): Observable<NewsInjectComment[]> {
    return this.http
      .get<NewsInjectComment[]>(
        `${NewsInjectService.BASE_API_URL}/${injectId}/comments/${exerciseId}`,
        {
          params: { ...(teamId && { teamId }) },
        }
      )
      .pipe(
        map((dataArray) => {
          return dataArray.map((data) => new NewsInjectComment(data));
        })
      );
  }

  saveComment(comment: NewsInjectCommentSaveDTO, exerciseId: string): Observable<boolean> {
    return this.http
      .post<NewsInjectCommentSaveDTO>(
        `${NewsInjectService.BASE_API_URL}/comments/${exerciseId}`,
        comment
      )
      .pipe(map(() => true));
  }
}
