import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
  CTFTaskDTO,
  CTFTaskStatus,
  SmartCityBuildingDetails,
  SmartCityBuildingSaveDTO,
  SmartCityMediaType,
  SmartCityTargetData,
  SmartCityTaskData,
  Target,
  TargetStatus,
} from '../../../../models';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { NewsInjectService } from '../../../../services';
import { FilterStateService } from '../../../../shared';
import { SmartCityService } from '../../../../services/gamenet/smart-city.service';
import { NotificationsService } from '@cybexer/ngx-commons';
import { FileMetaInfo } from '../../../../models/shared/file-info.model';

@Component({
  selector: 'isa-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrl: './info-panel.component.scss',
})
export class InfoPanelComponent implements OnChanges {
  @Input() exerciseId: string;
  @Input() teamId: string;
  @Input() selectedBuildingDetails: SmartCityBuildingDetails;
  @Input() selectedTask?: SmartCityTaskData;
  @Input() selectedTarget?: SmartCityTargetData;
  @Input() selectedMeshId: string;
  @Input() targets: Target[];
  @Input() selectedCity: string;
  @Input() ctfTasks: CTFTaskDTO[];
  @Input() filesMetaData?: FileMetaInfo[];
  @Output() buildingDataChanged = new EventEmitter<boolean>();
  isEditMode: boolean = false;
  form: FormGroup;
  filteredTargets: Observable<Target[]>;
  filteredTasks: Observable<CTFTaskDTO[]>;
  filteredGoodMediaFiles: Observable<FileMetaInfo[]>;
  filteredCompromisedMediaFiles: Observable<FileMetaInfo[]>;
  filteredNotAvailableMediaFiles: Observable<FileMetaInfo[]>;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  protected readonly StatusMediaType = SmartCityMediaType;
  protected readonly TargetStatus = TargetStatus;
  protected readonly TaskStatus = CTFTaskStatus;

  constructor(
    public filterStateService: FilterStateService,
    private fb: FormBuilder,
    public smartCityService: SmartCityService,
    private notificationsService: NotificationsService,
    private newsInjectService: NewsInjectService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.selectedBuildingDetails?.currentValue?.id !==
      changes.selectedBuildingDetails?.previousValue?.id
    ) {
      this.isEditMode = false;
    }

    if (!this.selectedTarget && !this.selectedTask && !this.isEditMode) {
      this.initEditMode();
    }

    if (changes.folderContents) {
      this.setUpMediaFilters();
    }
  }

  initEditMode() {
    this.initBuildingForm();
    this.isEditMode = true;
  }

  initBuildingForm() {
    this.form = this.fb.group({
      id: [this.selectedMeshId],
      type: [this.selectedBuildingDetails?.type ?? ''],
      address: [this.selectedBuildingDetails?.address ?? ''],
      dependencies: this.fb.array(this.selectedBuildingDetails?.dependencies ?? []),
      goodMediaId: [this.selectedBuildingDetails?.media?.good?.id ?? null],
      compromisedMediaId: [this.selectedBuildingDetails?.media?.compromised?.id ?? null],
      notAvailableMediaId: [this.selectedBuildingDetails?.media?.notAvailable?.id ?? null],
      target: [this.selectedTarget ?? null],
      task: [this.selectedTask ?? null],
      applyForAllTeams: [false],
    });

    this.filteredTargets = this.setupFilteredOptions('target', (val) =>
      this.targets?.filter((t) => t.name.toLowerCase().includes(val))
    );
    this.filteredTasks = this.setupFilteredOptions('task', (val) =>
      this.ctfTasks?.filter((t) => t.title.toLowerCase().includes(val))
    );
    this.setUpMediaFilters();
  }

  private setUpMediaFilters() {
    this.filteredGoodMediaFiles = this.setupFilteredOptions('goodMediaId', (val) =>
      this.filesMetaData?.filter((f) => f.name.toLowerCase().includes(val))
    );
    this.filteredCompromisedMediaFiles = this.setupFilteredOptions('compromisedMediaId', (val) =>
      this.filesMetaData?.filter((f) => f.name.toLowerCase().includes(val))
    );
    this.filteredNotAvailableMediaFiles = this.setupFilteredOptions('notAvailableMediaId', (val) =>
      this.filesMetaData?.filter((f) => f.name.toLowerCase().includes(val))
    );
  }

  private setupFilteredOptions<T>(
    controlName: string,
    filterFn: (value: string) => T[]
  ): Observable<T[]> {
    return this.form?.get(controlName).valueChanges.pipe(
      startWith(''),
      map((value) => {
        const filterValue = typeof value === 'string' ? value : value?.name || value?.title || '';
        return filterValue ? filterFn(filterValue.toLowerCase()) : filterFn('');
      })
    );
  }

  displayTaskFn(task: CTFTaskDTO): string {
    return task && task.title ? task.title : '';
  }

  displayNameFn(object: Target): string {
    return object && object.name ? object.name : '';
  }
  displayFileNameFn = (value: string | FileMetaInfo) => {
    if (typeof value === 'string') {
      const file = this.filesMetaData?.find((f) => f.id === value);
      return file?.name;
    }
    return value?.name || '';
  };

  get dependencies(): FormArray {
    return this.form.get('dependencies') as FormArray;
  }

  add(event: any): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.dependencies.push(this.fb.control(value.trim(), Validators.required));
    }

    if (input) {
      input.value = '';
    }
  }

  remove(index: number): void {
    if (index >= 0) {
      this.dependencies.removeAt(index);
    }
  }

  edit(index: number, event: any): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {
      this.dependencies.at(index).setValue(value.trim());
    }

    if (input) {
      input.value = '';
    }
  }

  saveBuildingData() {
    this.smartCityService
      .saveBuildingData(
        this.exerciseId,
        this.teamId,
        this.form.value.id,
        this.createBuildingSaveDTO()
      )
      .subscribe(() => {
        this.notificationsService.success('ui.smartCity.buildingDataSaved');
        this.buildingDataChanged.emit(true);
        this.closeInfoPanel();
      });
  }

  private createBuildingSaveDTO(): SmartCityBuildingSaveDTO {
    const formData = this.form.value;
    const buildingData = new SmartCityBuildingSaveDTO();
    buildingData.address = formData.address;
    buildingData.type = formData.type;
    buildingData.dependencies = formData.dependencies;
    buildingData.goodMediaId = formData.goodMediaId || '';
    buildingData.compromisedMediaId = formData.compromisedMediaId || '';
    buildingData.notAvailableMediaId = formData.notAvailableMediaId || '';
    buildingData.taskId = formData.task?.id;
    buildingData.targetId = formData.target?.id;
    buildingData.applyForAllTeams = formData.applyForAllTeams;
    buildingData.previousTargetId = this.selectedTarget?.id;
    buildingData.previousTaskId = this.selectedTask?.id;
    return buildingData;
  }

  getMediaUrl(mediaId: string) {
    return this.newsInjectService.getMediaUrl(mediaId, this.exerciseId);
  }

  closeInfoPanel() {
    this.smartCityService.isInfoPanelOpen.set(false);
  }
}
