import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@cybexer/ngx-commons';
import { firstValueFrom } from 'rxjs';

export default class Utils {
  static async confirmed(
    dialog: MatDialog,
    message?: string,
    confirmBtnText?: string
  ): Promise<boolean> {
    return await firstValueFrom(
      dialog
        .open(ConfirmDialogComponent, {
          data: {
            message: message,
            confirmBtnText: confirmBtnText,
          },
        })
        .afterClosed()
    );
  }
}
