import { GuacamoleTunnelType } from '@cybexer/ngx-commons';
import { ExerciseType } from '../gamenet/exercise.model';
import { BaseModel } from './base.model';
import { IsaModule } from './isa-module.model';

export class Settings extends BaseModel implements ISettings {
  gamenetEnabled: boolean;
  observerEnabled: boolean;
  ldapEnabled: boolean;
  vlmEnabled: boolean;
  aiFabricEnabled: boolean;
  gamenetSettings: GamenetSettings;
  ldapSettings: LdapSettings;
  vlmSettings: VlmSettings;
  aiFabricSettings: AIFabricSettings;
  observerSettings: ObserverSettings;
  apiTokens: ApiToken[];
  widgetRefreshInterval: number;
  applicationUrl: string;
  timestamp: Date;
  isLightTheme: boolean;
  customLogoImageId: string;
  customLabelImageId: string;
  frontEndSentryDsn: string;
  experimentalFeaturesEnabled: boolean;
  languages: string[];
  enabledLanguages: string[];
  defaultLanguage: string;

  constructor(data?) {
    super(data);

    if (data.hasOwnProperty('gamenetSettings')) {
      this.gamenetSettings = new GamenetSettings(data.gamenetSettings);
    }

    if (data.hasOwnProperty('apiTokens')) {
      this.apiTokens = data.apiTokens.map((apiToken) => new ApiToken(apiToken));
    }

    if (data.hasOwnProperty('timestamp')) {
      this.timestamp = new Date(data.timestamp);
    }
  }

  isModuleEnabled(module: IsaModule): boolean {
    switch (module) {
      case IsaModule.GAMENET:
        return this.gamenetEnabled;
      default:
        return true;
    }
  }
}

export class ApiToken extends BaseModel {
  name: string;
  value: string;
  timestamp: Date;
  signingKey?: SigningKey;

  constructor(data?) {
    super(data);

    if (data.hasOwnProperty('timestamp')) {
      this.timestamp = new Date(data.timestamp);
    }

    if (data.hasOwnProperty('signingKey')) {
      this.signingKey = new SigningKey(data.signingKey);
    }
  }
}

export class SigningKey extends BaseModel {
  value: string;
  timestamp: Date;

  constructor(data?) {
    super(data);

    if (data.hasOwnProperty('timestamp')) {
      this.timestamp = new Date(data.timestamp);
    }
  }
}

export class GamenetSettings extends BaseModel {
  soundOnTeamChangeEnabled: boolean;
  selectedSoundId: string;
  ctfPodiumFirstPlaceSoundEnabled: boolean;
  ctfPodiumFirstPlaceSoundId: string;
  ctfPodiumSecondThirdPlaceSoundEnabled: boolean;
  ctfPodiumSecondThirdPlaceSoundId: string;
  exerciseRefreshInterval: number;
  scoringTimelineWidgetRefreshInterval: number;

  constructor(data?) {
    super(data);
  }
}

export class LdapGroupMapping extends BaseModel {
  role: string;
  distinguishedName: string;

  constructor(data?) {
    super(data);
  }
}

export class LdapSettings extends BaseModel implements ILdapSettings {
  principal: string;
  password: String = '';
  principalSuffix: string;
  searchBase: string;
  urls: string[];
  groupMappings: LdapGroupMapping[];
  useSavedPassword: boolean;

  constructor(data?) {
    super(data);
  }
}

export class VlmSettings extends BaseModel implements IVlmSettings {
  serverUrl: string;
  clientUrl?: string;
  serviceKey: string;
  nativeConsoleProxyEnabled: boolean;
  guacamoleTunnelType: GuacamoleTunnelType;
  guacamoleTunnelProxyEnabled: boolean;

  constructor(data?) {
    super(data);
  }
}

export class AIFabricSettings extends BaseModel implements IAIFabricSettings {
  serverUrl: string;
  serviceKey: string;

  constructor(data?) {
    super(data);
  }
}

export interface ISettings {
  gamenetEnabled?: boolean;
  observerEnabled?: boolean;
  observerKey?: string;
  enabledObserverViews?: ObserverViewType[];
  ldapEnabled?: boolean;
  vlmEnabled?: boolean;
  gamenetSettings?: IGamenetSettings;
  ldapSettings?: ILdapSettings;
  vlmSettings?: IVlmSettings;
  apiTokens: ApiToken[];
  widgetRefreshInterval: number;
  applicationUrl: string;
  isLightTheme: boolean;
  customLogoImageId?: string;
  customLabelImageId?: string;
  frontEndSentryDsn?: string;
  experimentalFeaturesEnabled?: boolean;
}

export interface IGamenetSettings {
  soundOnTeamChangeEnabled?: boolean;
  selectedSoundId?: String;
  ctfPodiumFirstPlaceSoundEnabled?: boolean;
  ctfPodiumFirstPlaceSoundId?: String;
  ctfPodiumSecondThirdPlaceSoundEnabled?: boolean;
  ctfPodiumSecondThirdPlaceSoundId?: String;
  exerciseRefreshInterval: number;
  scoringTimelineWidgetRefreshInterval: number;
}

export interface ILdapSettings {
  principal: string;
  password: String;
  principalSuffix: string;
  searchBase: string;
  urls: string[];
  groupMappings: LdapGroupMapping[];
  useSavedPassword: boolean;
}

export interface IVlmSettings {
  serverUrl: string;
  clientUrl?: string;
  serviceKey: string;
  nativeConsoleProxyEnabled: boolean;
  guacamoleTunnelType: GuacamoleTunnelType;
  guacamoleTunnelProxyEnabled: boolean;
}

export interface IAIFabricSettings {
  serverUrl: string;
  serviceKey: string;
}

export interface IObserverSettings {
  key?: string;
  enabledViews: ObserverViewType[];
  exerciseId?: string;
}

export class ObserverSettings extends BaseModel implements IObserverSettings {
  key?: string;
  enabledViews: ObserverViewType[];
  exerciseId?: string;

  constructor(data?) {
    super(data);
  }
}

export class CombinedObserverSettings extends BaseModel {
  isEnabled: boolean;
  views?: ObserverViewType[];

  constructor(data?) {
    super(data);
  }
}

export enum ObserverViewType {
  CAMPAIGN_LIVE = 'CAMPAIGN_LIVE',
  PODIUM = 'PODIUM',
}

export class ObservableView extends BaseModel {
  name: string;
  type: ObserverViewType;
  path: string;
  supportedExerciseTypes: ExerciseType[];

  constructor(data?) {
    super(data);
  }
}
