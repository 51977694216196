import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'isaDuration',
})
export class DurationPipe implements PipeTransform {
  constructor() {}

  transform(value?: number) {
    return value != null ? DurationPipe.format(value) : '-';
  }

  private static format(timeInMillis: number): string {
    const duration = moment.duration(timeInMillis);
    const days = Math.floor(duration.asDays());
    const dayFormat = days > 0 ? `[${days}d ]H` : '';
    const format = `${dayFormat}H:mm:ss`;

    return moment.utc(timeInMillis).format(format);
  }
}
