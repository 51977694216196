<div class="login">
  @if (customLogoSrc) {
    <div>
      <img [src]="customLogoSrc | isaSafeUrl" style="height: 250px; margin: 30px 0 -40px" />
    </div>
  }
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: 1rem 0;
      gap: 4rem;
      width: 100%;
    "
  >
    <img
      style="max-height: 100px; margin: auto; max-width: calc(17% - 4rem)"
      src="./assets/img/telia.png"
    />
    <img
      style="max-height: 130px; margin: auto; max-width: calc(17% - 4rem)"
      src="./assets/img/pohjamaad.png"
    />
    <img
      style="max-height: 100px; margin: auto; max-width: calc(17% - 4rem)"
      src="./assets/img/kvjuhatus.webp"
    />

    <img
      style="max-height: 120px; margin: auto; max-width: calc(17% - 4rem)"
      src="./assets/img/CR14_logo_colour.svg"
    />
    <img
      style="max-height: 80px; margin: auto; max-width: calc(17% - 4rem)"
      src="./assets/img/bytelife.svg"
    />
    <img
      style="max-height: 130px; margin: auto; max-width: calc(17% - 4rem)"
      src="./assets/img/km.jpg"
    />
  </div>
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin: 1rem 0;
      gap: 4rem;
      width: 100%;
    "
  >
    <img
      style="max-height: 60px; margin: auto; max-width: calc(17% - 4rem)"
      src="./assets/img/Swedbank_pos.svg"
    />
    <img
      style="height: 40px; margin: auto; max-width: calc(17% - 4rem)"
      src="./assets/img/alexela.svg"
    />
    <img
      style="max-height: 70px; margin: auto; max-width: calc(17% - 4rem)"
      src="./assets/img/sk.svg"
    />
    <img
      style="max-height: 60px; margin: auto; max-width: calc(17% - 4rem)"
      src="./assets/img/coca-cola.png"
    />
    <img
      style="max-height: 80px; margin: auto; max-width: calc(17% - 4rem)"
      src="./assets/img/arvutitark.svg"
    />
    <img
      style="max-height: 120px; margin: auto; max-width: calc(17% - 4rem)"
      src="./assets/img/taltech.png"
    />
  </div>
  <mat-card id="login-card">
    <form role="form" [formGroup]="loginForm" (ngSubmit)="login(loginForm)" class="isa-form">
      <mat-card-content>
        <mat-form-field class="form-field-full">
          <mat-label>{{ 'ui.login.name' | translate }}</mat-label>
          <input matInput formControlName="username" />
          @if (loginForm.controls['username'].hasError('required')) {
            <mat-error> {{ 'ui.login.nameRequired' | translate }} </mat-error>
          }
        </mat-form-field>
        <mat-form-field class="form-field-full">
          <mat-label>{{ 'ui.login.password' | translate }}</mat-label>
          <input matInput formControlName="password" type="password" />
          @if (loginForm.controls['password'].hasError('required')) {
            <mat-error> {{ 'ui.login.passwordRequired' | translate }} </mat-error>
          }
        </mat-form-field>
        @if (wrongCredentials) {
          <mat-card-subtitle class="wrong-credentials">
            {{ 'ui.login.wrongCredentials' | translate }}
          </mat-card-subtitle>
        }
        <div class="actions">
          <button mat-raised-button type="submit" [disabled]="loadingData" color="primary">
            {{ 'ui.login.loginBtn' | translate }}
          </button>
        </div>
      </mat-card-content>
      <mat-card-footer>
        @if (loadingData) {
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        }
      </mat-card-footer>
    </form>
  </mat-card>
  @if (observerSettings && observerSettings.isEnabled && enabledObserverViews.length > 0) {
    <button mat-button [matMenuTriggerFor]="observerMenu" data-testid="observerMenuBtn">
      <i class="fas fa-eye" aria-hidden="true"></i> {{ 'ui.login.observe' | translate }}
    </button>
    <mat-menu #observerMenu [attr.data-testid]="'observerMenu'">
      @for (view of enabledObserverViews; track view) {
        <a mat-menu-item [routerLink]="view.path">
          {{ view.name }}
        </a>
      }
    </mat-menu>
  }
</div>
