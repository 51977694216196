export type RoleCode = 'BLUE' | 'RED' | 'WHITE' | 'ADMIN';

/**
 * Ordered by priority: ADMIN > WHITE > RED > BLUE
 */
export const ROLES: { [code: string]: RoleCode } = {
  ADMIN: 'ADMIN',
  WHITE: 'WHITE',
  RED: 'RED',
  BLUE: 'BLUE',
};
