<cybexer-widget-header [title]="'ui.nav.incidentReports' | translate">
  <mat-slide-toggle
    labelPosition="after"
    [ngModel]="(filter$ | async).pendingConfirmationOnly"
    (ngModelChange)="filterStateService.setFilter('pendingConfirmationOnly', $event)"
    *isaRequirePermission="EXERCISE_PERMISSIONS.WHITE_TEAM"
  >
    {{ 'ui.incidentReports.pendingConfirmation' | translate }}
  </mat-slide-toggle>
  <isa-filtered-select
    [options]="teams"
    [preselectedOption]="(filter$ | async).team"
    [optionKey]="'customName'"
    [optionValue]="'id'"
    [label]="'ui.team' | translate"
    [defaultOptionKey]="'ui.allTeams' | translate"
    [disabled]="this.userRole === this.ROLES.BLUE && !isAdmin"
    (selectionChange)="filterStateService.setFilter('team', $event)"
  ></isa-filtered-select>
  <mat-form-field>
    <mat-label
      ><i class="fas fa-filter" aria-hidden="true"></i>
      {{ 'ui.incidentReports.filter' | translate }}</mat-label
    >
    <input id="textFilter" autocomplete="off" matInput [formControl]="textFilterFormControl" />
  </mat-form-field>
  <cybexer-confirm-action
    [disabled]="selection.isEmpty()"
    [attr.data-testid]="'confirm-selected'"
    *isaRequirePermission="EXERCISE_PERMISSIONS.WHITE_TEAM"
  >
    <button
      mat-raised-button
      (click)="multipleConfirmAction(CONFIRMATION_STATUS.CONFIRMED)"
      [disabled]="selection.isEmpty()"
    >
      <i class="fas fa-check-circle mat-button-fa" aria-hidden="true"></i>
      {{ 'ui.incidentReports.confirmSelected' | translate }}
    </button>
  </cybexer-confirm-action>
  <cybexer-confirm-action
    [disabled]="selection.isEmpty()"
    [attr.data-testid]="'deny-selected'"
    *isaRequirePermission="EXERCISE_PERMISSIONS.WHITE_TEAM"
  >
    <button
      mat-raised-button
      (click)="multipleConfirmAction(CONFIRMATION_STATUS.DENIED)"
      [disabled]="selection.isEmpty()"
    >
      <i class="fas fa-exclamation-circle mat-button-fa" aria-hidden="true"></i>
      {{ 'ui.incidentReports.denySelected' | translate }}
    </button>
  </cybexer-confirm-action>
  @if (shouldShowAITools) {
    <button
      mat-raised-button
      [disabled]="selection.isEmpty()"
      (click)="sendToAIAssessment()"
      *isaRequirePermission="EXERCISE_PERMISSIONS.WHITE_TEAM"
    >
      <i class="fas fa-magic-wand-sparkles status-ai-icon" aria-hidden="true"></i>
      {{ 'ui.reports.autoAssessSelected' | translate }}
    </button>
    <button
      mat-raised-button
      [matTooltip]="
        effectivePromptOverride?.id
          ? ('ui.reports.activeOverride' | translate: { promptName: effectivePromptOverride?.name })
          : ''
      "
      (click)="openAIFabricPromptOverrideDialog()"
      *isaRequirePermission="EXERCISE_PERMISSIONS.WHITE_TEAM"
    >
      <mat-icon>android</mat-icon>
      @if (effectivePromptOverride?.id) {
        {{
          'ui.reports.promptOverridesButton'
            | translate: { promptName: effectivePromptOverride?.name }
        }}
      } @else {
        {{ 'ui.reports.promptOverridesButtonDefault' | translate }}
      }
    </button>
  }
</cybexer-widget-header>
<div class="incident-reports cybexer-table-wrapper">
  <table mat-table matSort [dataSource]="dataSource" class="isa-table">
    <ng-container [matColumnDef]="COLUMNS.SELECT">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? toggleSelectAll() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [disabled]="getFilteredUnconfirmedReports().length === 0"
          [hidden]="userRole === ROLES.BLUE"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let incidentReportData" (click)="$event.stopPropagation()">
        @if (incidentReportData.status === CONFIRMATION_STATUS.PENDING_CONFIRMATION) {
          <mat-checkbox
            (change)="$event ? selection.toggle(incidentReportData) : null"
            [checked]="selection.isSelected(incidentReportData)"
            [hidden]="userRole === ROLES.BLUE"
          >
          </mat-checkbox>
        }
      </td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.TIMESTAMP">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ui.time' | translate }}</th>
      <td mat-cell *matCellDef="let incidentReportData">
        {{ incidentReportData.timestamp | isaDate: 'medium' }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.TARGET_NAME">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'ui.incidentReports.target' | translate }}
      </th>
      <td mat-cell *matCellDef="let incidentReportData">{{ incidentReportData.targetName }}</td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.TARGET_CHECK_NAME">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'ui.incidentReports.targetCheck' | translate }}
      </th>
      <td mat-cell *matCellDef="let incidentReportData">
        {{ incidentReportData.targetCheckName }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.INCIDENT_TYPE">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'ui.incidentReports.incidentType' | translate }}
      </th>
      <td mat-cell *matCellDef="let incidentReportData">
        {{ 'ui.enums.' + incidentReportData.incidentType | translate }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.TEAM_NAME">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ui.team' | translate }}</th>
      <td
        mat-cell
        *matCellDef="let incidentReportData"
        [matTooltip]="incidentReportData.blueTeamMember"
      >
        {{ incidentReportData.teamId | isaTeamName }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.STATUS">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        @if ((filter$ | async).pendingConfirmationOnly) {
          <i
            class="fas fa-filter status-filter-icon"
            aria-hidden="true"
            [matTooltip]="
              ('ui.incidentReports.filter' | translate) +
              ': ' +
              ('ui.incidentReports.unconfirmed' | translate)
            "
          ></i>
        }
        {{ 'ui.incidentReports.status' | translate }}
      </th>
      <td mat-cell *matCellDef="let incidentReportData">
        {{ 'ui.enums.' + incidentReportData.status | translate }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.WHITE_TEAM_MEMBER">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'ui.incidentReports.whiteTeamMember' | translate }}
      </th>
      <td mat-cell *matCellDef="let incidentReportData">
        @if (incidentReportData.whiteTeamMember) {
          <i class="fas fa-user" aria-hidden="true"></i>
        }
        {{ incidentReportData.whiteTeamMember }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.AI_ASSESSMENT_STATUS">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'ui.incidentReports.autoAssessStatus' | translate }}
      </th>
      <td mat-cell *matCellDef="let reportData">
        {{ reportData.aiAssessmentStatus | isaReplaceUnderScores }}
      </td>
    </ng-container>

    <ng-container [matColumnDef]="COLUMNS.AI_SCORE">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'ui.incidentReports.autoRating' | translate }}
      </th>
      <td mat-cell *matCellDef="let reportData">
        {{ reportData.aiScore }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      class="clickable-row"
      *matRowDef="let incidentReportData; columns: displayedColumns"
      (click)="openIncidentReportConfirmDialog(incidentReportData)"
    ></tr>
  </table>

  @if ((dataSource.filteredCount$ | async) === 0 && (dataSource.loading$ | async) === false) {
    <cybexer-no-data></cybexer-no-data>
  }
  @if (dataSource.loading$ | async) {
    <cybexer-progress-spinner></cybexer-progress-spinner>
  }
  <mat-paginator
    [length]="dataSource.filteredCount$ | async"
    [pageSizeOptions]="pageSizes"
    showFirstLastButtons
  ></mat-paginator>
</div>
