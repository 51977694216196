import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge, Observable, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  skip,
  switchMap,
  tap,
} from 'rxjs/operators';
import { TaskReportsDatasource } from '../../../../datasources';
import {
  BlueTeam,
  Exercise,
  ReportAIAssessment,
  ReportFilter,
  TaskReportConfirmation,
  TaskReportsData,
} from '../../../../models';
import {
  AuthenticationService,
  ExerciseService,
  IntervalService,
  PreferenceService,
  TaskReportsService,
} from '../../../../services';
import {
  CONFIRMATION_STATUS,
  DEFAULT_PAGE_SIZE,
  EXERCISE_PERMISSIONS,
  FilterStateModel,
  FilterStateService,
  PAGE_SIZES,
  RoleCode,
  ROLES,
} from '../../../../shared';
import { TaskReportConfirmDialogComponent } from './task-report-confirm-dialog/task-report-confirm-dialog.component';
import { SelectionModel } from '@angular/cdk/collections';
import {
  AIFabricPromptOverrideDialogComponent,
  AIFabricService,
  NamedEntity,
  PromptType,
} from '@cybexer/ngx-commons';

@UntilDestroy()
@Component({
  selector: 'isa-task-reports',
  templateUrl: './task-reports.component.html',
  styleUrls: ['./task-reports.component.scss'],
})
export class TaskReportsComponent implements OnInit, AfterViewInit {
  static DEFAULT_LIMIT = 25;
  static DEFAULT_OFFSET = 0;

  filter$: Observable<Partial<FilterStateModel>>;

  exercise: Exercise;
  teams: BlueTeam[] = [];
  taskConfirmDialogRef: MatDialogRef<TaskReportConfirmDialogComponent>;
  textFilterFormControl = new UntypedFormControl();
  ROLES = ROLES;
  userRole: RoleCode;
  loading = true;
  isAdmin: boolean;
  isAIAvailable: boolean;
  shouldShowAITools: boolean = false;
  effectivePromptOverride?: NamedEntity;
  readonly EXERCISE_PERMISSIONS = EXERCISE_PERMISSIONS;
  readonly CONFIRMATION_STATUS = CONFIRMATION_STATUS;

  // table
  readonly COLUMNS = {
    SELECT: 'select',
    TIMESTAMP: 'timestamp',
    TITLE: 'title',
    CATEGORY: 'category',
    TEAM_NAME: 'teamName',
    SCORE: 'score',
    STATUS: 'status',
    WHITE_TEAM_MEMBER: 'whiteTeamMember',
    AI_ASSESSMENT_STATUS: 'aiAssessmentStatus',
    AI_SCORE: 'aiScore',
  };
  readonly defaultColumns = [
    this.COLUMNS.TIMESTAMP,
    this.COLUMNS.TITLE,
    this.COLUMNS.CATEGORY,
    this.COLUMNS.TEAM_NAME,
    this.COLUMNS.SCORE,
    this.COLUMNS.STATUS,
    this.COLUMNS.WHITE_TEAM_MEMBER,
  ];
  readonly aiFeaturesColumns = [
    this.COLUMNS.SELECT,
    ...this.defaultColumns,
    this.COLUMNS.AI_ASSESSMENT_STATUS,
    this.COLUMNS.AI_SCORE,
  ];
  readonly displayedColumns = [...this.defaultColumns];
  @ViewChild(MatPaginator, { static: true })
  paginator: MatPaginator;
  @ViewChild(MatSort, { static: true })
  sort: MatSort;
  selection = new SelectionModel<TaskReportsData>(true, []);
  dataSource: TaskReportsDatasource;

  constructor(
    private dialog: MatDialog,
    private exerciseService: ExerciseService,
    private intervalService: IntervalService,
    private taskReportsService: TaskReportsService,
    private authenticationService: AuthenticationService,
    private preferenceService: PreferenceService,
    private aiFabricService: AIFabricService,
    public filterStateService: FilterStateService
  ) {}

  ngOnInit() {
    this.isAdmin = this.authenticationService.currentUser.isAdmin;
    this.filter$ = this.filterStateService.filter$('team', 'pendingConfirmationOnly');
    this.dataSource = new TaskReportsDatasource(this.taskReportsService);
    this.dataSource.loading$.pipe(untilDestroyed(this)).subscribe((loading) => {
      if (!loading) {
        const selectedReportIds = this.selection.selected.map((it) => it.reportId);
        this.clearSelection();
        this.selectAll(selectedReportIds);
      }
    });

    this.paginator.pageSize =
      this.preferenceService.currentPreferences.defaultListSize || DEFAULT_PAGE_SIZE;

    this.aiFabricService
      .isAvailable()
      .pipe(untilDestroyed(this))
      .subscribe((isAvailable) => {
        this.isAIAvailable = isAvailable;
        this.updateAIToolsStatus();
      });

    this.exerciseService.activeExercise
      .pipe(
        tap((exercise: Exercise) => (this.exercise = exercise)),
        filter((exercise: Exercise) => !!exercise),
        tap((exercise: Exercise) => {
          this.teams = exercise.blueTeams;
          this.userRole = this.authenticationService.getRole(exercise.id);
        }),
        switchMap((exercise) => {
          if (!this.isAdmin) {
            this.isAdmin = this.authenticationService.currentUser.isGamenetAdmin(this.exercise.id);
          }
          this.aiFabricService.updateAvailability(
            { 'Exercise-Id': exercise?.id },
            `/api${AIFabricService.AI_FABRIC_PATH}-management`
          );
          if (this.userRole === this.ROLES.BLUE) {
            return this.exerciseService.getUserBlueTeam(exercise.id).pipe(untilDestroyed(this));
          }

          return of(null);
        }),
        tap((userBlueTeamId) => {
          if (userBlueTeamId != null) {
            this.filterStateService.setFilterIfEmptyOrDefault('team', userBlueTeamId);
          }

          this.loadTaskReports();
        }),
        switchMap(() => this.intervalService.getWidgetRefreshInterval()),
        skip(1),
        untilDestroyed(this)
      )
      .subscribe(() => this.loadReportsSilently());

    // TODO: first call to filter needs to be investigated
    this.filter$.pipe(skip(1), untilDestroyed(this)).subscribe(() => {
      this.paginator.firstPage();
      this.loadTaskReports();
    });
  }

  ngAfterViewInit() {
    // reset the paginator after sorting
    this.sort.sortChange.pipe(untilDestroyed(this)).subscribe(() => this.paginator.firstPage());

    this.textFilterFormControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          this.clearSelection();
          this.paginator.firstPage();
          this.loadTaskReports();
        }),
        untilDestroyed(this)
      )
      .subscribe();

    // on sort or paginate events, load a new page
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        tap(() => {
          this.clearSelection();
          this.loadTaskReports();
        })
      )
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  private loadTaskReports(loadSilently: boolean = false): void {
    if (this.exercise) {
      this.dataSource.loadReports(this.exercise.id, this._createReportFilter(), loadSilently);
    }
  }

  private loadReportsSilently(): void {
    this.loadTaskReports(true);
  }

  private _createReportFilter(): ReportFilter {
    const limit = this.paginator.pageSize || TaskReportsComponent.DEFAULT_LIMIT;
    const { team, pendingConfirmationOnly } = this.filterStateService.snapshot();
    const sortDirection = this.sort.direction;
    const sortOrder = sortDirection != null && !!sortDirection ? sortDirection : null;

    return new ReportFilter({
      offset:
        this.paginator.pageIndex == null
          ? TaskReportsComponent.DEFAULT_OFFSET
          : this.paginator.pageIndex * limit,
      limit: limit,
      unconfirmedOnlyFilter: pendingConfirmationOnly,
      teamIdFilter: team,
      textFilter: this.textFilterFormControl.value,
      sortColumn: sortOrder ? this.sort.active : null,
      sortOrder: sortOrder,
    });
  }

  openTaskReportConfirmDialog(taskReportsData: TaskReportsData): void {
    this.taskConfirmDialogRef = this.dialog.open(TaskReportConfirmDialogComponent, {
      disableClose: false,
      data: {
        taskReportsData: taskReportsData,
        exercise: this.exercise,
        aiFeaturesEnabled: this.shouldShowAITools,
        promptOverrideId: this.effectivePromptOverride?.id,
      },
    });

    this.taskConfirmDialogRef
      .afterClosed()
      .subscribe((taskConfirmation: TaskReportConfirmation) => {
        if (taskConfirmation) {
          this.loadTaskReports();
        }
        this.taskConfirmDialogRef = null;
      });
  }

  openAIFabricPromptOverrideDialog() {
    this.dialog.open(AIFabricPromptOverrideDialogComponent, {
      data: {
        aiFabricPromptOverrideService: this.aiFabricService,
        isLightTheme$: this.preferenceService.isLightTheme.asObservable(),
        currentSystemOverrideId: this.exercise?.promptOverride?.id,
        currentPreferenceOverrideId: this.preferenceService.currentPreferences?.promptOverride?.id,
        onPreferenceOverride: (selectedPromptOverride: NamedEntity) => {
          this.preferenceService
            .updatePreferences({
              promptOverride: selectedPromptOverride || null,
            })
            .pipe(first())
            .subscribe(() => this.updateEffectivePromptOverride());
        },
        isExerciseAdmin: this.isAdmin,
        editablePromptIds: [
          PromptType.SITUATION_REPORT_ASSESSMENT,
          PromptType.INCIDENT_REPORT_ASSESSMENT,
          PromptType.ATTACK_REPORT_ASSESSMENT,
          PromptType.CTF_TASK_REPORT_ASSESSMENT,
        ],
        mainPromptId: PromptType.CTF_TASK_REPORT_ASSESSMENT,
      },
    });
  }

  toggleSelectAll(): void {
    this.isAllSelected() ? this.clearSelection() : this.selectAll();
  }

  private selectAll(reportIdFilter: string[] = null): void {
    const selectedReportIds = this.selection.selected.map((it) => it.reportId);
    this.getFilteredUnconfirmedReports()
      .filter((it) => selectedReportIds.indexOf(it.reportId) === -1)
      .filter((it) => reportIdFilter == null || reportIdFilter.indexOf(it.reportId) !== -1)
      .forEach((reportData) => this.selection.select(reportData));
  }

  clearSelection(): void {
    this.selection.clear();
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.getFilteredUnconfirmedReports().length;

    return numSelected === numRows;
  }

  getFilteredUnconfirmedReports(): TaskReportsData[] {
    return this.dataSource.data.filter(
      (it) => it.status === CONFIRMATION_STATUS.PENDING_CONFIRMATION
    );
  }

  updateAIToolsStatus() {
    this.shouldShowAITools =
      this.isAIAvailable && (this.userRole === this.ROLES.WHITE || this.isAdmin);
    this.displayedColumns.splice(
      0,
      this.displayedColumns.length,
      ...(this.shouldShowAITools ? this.aiFeaturesColumns : this.defaultColumns)
    );
  }

  sendToAIAssessment() {
    if (this.selection.isEmpty()) return;
    const assessments = this.selection.selected.map(
      (data: TaskReportsData) =>
        new ReportAIAssessment({
          reportId: data.reportId,
          teamId: data.teamId,
          promptOverrideId: this.effectivePromptOverride?.id,
        })
    );
    this.taskReportsService
      .createMultipleTaskReportAIAssessments(this.exercise.id, assessments)
      .pipe(first())
      .subscribe(() => {
        this.loadTaskReports();
      });
    this.clearSelection();
  }

  private updateEffectivePromptOverride() {
    this.effectivePromptOverride =
      this.preferenceService.currentPreferences?.promptOverride || this.exercise?.promptOverride;
  }

  get pageSizes(): number[] {
    return PAGE_SIZES;
  }
}
