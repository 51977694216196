import { BaseModel } from '../../shared/base.model';
import { ReportData, ReportFilterResult } from '../report-data.model';

export class TaskReportsData extends BaseModel implements ReportData {
  reportId: string;
  taskId: string;
  teamId: string;
  teamName: string;
  status: string;
  timestamp: Date;
  createdBy: string;
  initialScore: number;
  title: String;
  category: String;

  validatedOn?: Date;
  whiteTeamMember?: string;
  feedback?: string;
  score?: number;
  scorePercentage?: number;
  aiFeedback?: string;
  aiScore?: number;
  aiAssessmentStatus?: string;

  constructor(data?) {
    super(data);
  }
}

export class TaskReportConfirmation extends BaseModel {
  reportId: string;
  exerciseId: string;
  teamId: string;
  taskId: string;
  scorePercentage: number;
  feedback: string;
  status: string;

  constructor(data?) {
    super(data);
  }
}

export class TaskReportDetails extends BaseModel {
  reportId: string;
  exerciseId: string;
  teamId: string;
  taskId: string;
  username: string;
  answer: string;
  timestamp: Date;

  constructor(data?) {
    super(data);
  }
}

export class TaskReportFilterResult extends ReportFilterResult<TaskReportsData> {
  constructor(data) {
    super(data, TaskReportsData);
  }
}
