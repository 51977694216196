import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ChartDataset, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { first } from 'rxjs/operators';
import { BarChartDataSet, StatusSummary } from '../../../../models';
import { PreferenceService } from '../../../../services';
import { TranslateService } from '@ngx-translate/core';
import {
  chartFont,
  darkDeeperGridColor,
  darkFontColor,
  darkGridColor,
  lightDeeperGridColor,
  lightFontColor,
  lightGridColor,
} from '../chart-default-settings';

@Component({
  selector: 'isa-status-summary-chart',
  templateUrl: './status-summary-chart.component.html',
  styleUrls: ['./status-summary-chart.component.scss'],
})
export class StatusSummaryChartComponent implements OnInit, AfterViewInit {
  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;
  firstLoad = true;

  @Input()
  set summaryData(value: StatusSummary[]) {
    if (value) {
      this.statusSummary = value;
      this.refreshChart(value);
    }
  }

  @Input()
  labels: string[];

  @Input()
  disableClick = false;

  @Output()
  barClicked: EventEmitter<number> = new EventEmitter<number>();

  barChartOptions;
  chartColors: any[] = ['#32c559', '#da932c', '#d6474f'];
  statusSummary: StatusSummary[];
  barChartData: ChartDataset[];
  chartHeight: number;

  constructor(
    private cdr: ChangeDetectorRef,
    private preferenceService: PreferenceService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.preferenceService
      .getPreferences()
      .pipe(first())
      .subscribe((pref) => (this.barChartOptions = this.getBarChartOptions(pref.isLightTheme)));

    this.translate.onLangChange.subscribe(() => {
      this.refreshChart(this.statusSummary);
      this.chart.update();
    });
  }

  ngAfterViewInit() {
    const contentElement = <HTMLElement>document.getElementsByClassName('isa-content-body')[0];
    if (contentElement) {
      this.chartHeight = contentElement.offsetHeight - 165;
      this.cdr.detectChanges();
    }
  }

  refreshChart(summaryData: StatusSummary[]): void {
    if (this.firstLoad) {
      this.barChartData = summaryData.reduce(
        (total: BarChartDataSet[], item: StatusSummary) => {
          total[0].data.push(item.states.roundedGood);
          total[1].data.push(item.states.roundedCompromised);
          total[2].data.push(item.states.roundedNotAvailable);
          return total;
        },
        [
          new BarChartDataSet(this.translate.instant('ui.enums.GOOD').toUpperCase()),
          new BarChartDataSet(this.translate.instant('ui.enums.COMPROMISED').toUpperCase()),
          new BarChartDataSet(this.translate.instant('ui.enums.NOT_AVAILABLE').toUpperCase()),
        ]
      );
      this.firstLoad = false;
    } else {
      summaryData.map((statusSummary: StatusSummary, index) => {
        this.barChartData[0].data[index] = statusSummary.states.roundedGood;
        this.barChartData[1].data[index] = statusSummary.states.roundedCompromised;
        this.barChartData[2].data[index] = statusSummary.states.roundedNotAvailable;
      });

      this.barChartData[0].label = this.translate.instant('ui.enums.GOOD').toUpperCase();
      this.barChartData[1].label = this.translate.instant('ui.enums.COMPROMISED').toUpperCase();
      this.barChartData[2].label = this.translate.instant('ui.enums.NOT_AVAILABLE').toUpperCase();
    }

    // todo extract?
    this.barChartData[0].backgroundColor = this.chartColors[0];
    this.barChartData[1].backgroundColor = this.chartColors[1];
    this.barChartData[2].backgroundColor = this.chartColors[2];
  }

  chartClicked(e: any): void {
    if (e && e.active && e.active.length) {
      const activeElements: any[] = e.active;
      if (activeElements.length) {
        this.barClicked.emit(activeElements[0].index);
      }
    }
  }

  onResize(event): void {
    this.chartHeight = event.target.innerHeight - 294;
  }

  private getBarChartOptions(isLightTheme: boolean): ChartOptions {
    return {
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        x: {
          grid: {
            color: function (context) {
              if (context.tick?.value === 0) {
                return isLightTheme ? lightGridColor : darkGridColor;
              }
              return 'rgba(0, 0, 0, 0)';
            },
          },
          stacked: true,
          ticks: {
            color: isLightTheme ? lightFontColor : darkFontColor,
            font: {
              family: chartFont,
              size: 14,
            },
          },
        },
        y: {
          grid: {
            color: function (context) {
              if (context.tick?.value === 0) {
                return isLightTheme ? lightDeeperGridColor : darkDeeperGridColor;
              }
              return isLightTheme ? lightGridColor : darkGridColor;
            },
          },
          stacked: true,
          beginAtZero: true,
          min: 0,
          max: 100,
          ticks: {
            color: isLightTheme ? lightFontColor : darkFontColor,
            font: {
              family: chartFont,
            },
          },
        },
      },
      plugins: {
        // @ts-ignore
        barLabelsPlugin: false,
        tooltip: {
          titleFont: {
            family: chartFont,
          },
          callbacks: {
            label: (tooltipItems) => tooltipItems.raw + '%',
            labelColor: function (context) {
              return {
                borderColor: context.dataset.backgroundColor as string,
                backgroundColor: context.dataset.backgroundColor as string,
              };
            },
          },
        },
        legend: {
          display: true,
          onClick: (e) => {},
          position: 'bottom',
        },
      },
    };
  }
}
