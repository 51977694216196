import { BaseModel } from '../shared/base.model';
import { TimelineScore } from './timeline-score.model';

export class SummaryTimelineData extends BaseModel {
  teamName: string;
  scores: TimelineScore[];

  constructor(data?) {
    super(data);

    if (data.hasOwnProperty('scores')) {
      this.scores = data.scores.map((score) => new TimelineScore(score));
    }
  }
}
