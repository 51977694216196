import { Pipe, PipeTransform } from '@angular/core';
import moment, { Moment } from 'moment';
import { PreferenceService } from '../services';

@Pipe({
  name: 'isaDate',
})
export class DatePipe implements PipeTransform {
  constructor(private preferenceService: PreferenceService) {
    this.preferenceService.getPreferences().subscribe((preference) => {
      moment.locale(preference.dateFormat || 'de');
    });
  }
  transform(value: any, format: string) {
    switch (format) {
      case 'short':
        return moment(value).format('L LT');
      case 'medium':
        return moment(value).format('L LTS');
      case 'mediumTime':
        return moment(value).format('LTS');
      case 'shortTime':
        return moment(value).format('LT');
      case 'mediumWithTimezoneOffset':
        const momentDate = moment(value);
        return momentDate.format(`L LTS (UTC${this.getTimezoneOffset(momentDate)})`);
      case 'day':
        return moment(value).format('L');
      default:
        return moment(value).format();
    }
  }

  private getTimezoneOffset(momentDate: Moment): string {
    const timezoneOffsetUTC = momentDate.utcOffset() / 60;
    if (timezoneOffsetUTC === 0) {
      return '';
    }

    return ` ${timezoneOffsetUTC < 0 ? '' : '+'}${timezoneOffsetUTC}`;
  }
}
