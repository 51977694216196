import { BaseModel } from '../shared/base.model';

export class ReportingStatus extends BaseModel {
  campaignPhaseId: string;
  campaignPhaseName: string;
  objectives: ReportingStatusObjective[] = [];

  constructor(data?) {
    super(data);

    if (data.hasOwnProperty('objectives')) {
      this.objectives = data.objectives.map((objective) => new ReportingStatusObjective(objective));
    }
  }
}

export class ReportingStatusObjective extends BaseModel {
  type: string;
  name: string;
  category: string;
  teams: ReportingStatusTeam[] = [];

  constructor(data?) {
    super(data);

    if (data.hasOwnProperty('teams')) {
      this.teams = data.teams.map((team) => new ReportingStatusTeam(team));
    }
  }
}

export class ReportingStatusTeam extends BaseModel {
  teamId: string;
  teamName: string;
  objectiveContainerId: string;
  objectiveStatus: string;
  objectiveId: string;
  incidentReportCount: number;
  objectiveAttacks?: ObjectiveAttacks;

  constructor(data?) {
    super(data);
    if (data.hasOwnProperty('objectiveAttacks')) {
      this.objectiveAttacks = new ObjectiveAttacks(data['objectiveAttacks']);
    }
  }
}

export class ObjectiveAttacks extends BaseModel {
  failedAttackReportCount: string[];
  successfulAttackReportCount: string[];

  constructor(data?) {
    super(data);
  }
}

export class ReportingStatusIncidentReport extends BaseModel {
  id: string;
  exerciseId: string;
  teamId: string;
  networkSegmentId: string;
  targetId: string;
  incidentType: string;
  username: string;
  details: string;
  timestamp: string;

  constructor(data?) {
    super(data);
  }
}
