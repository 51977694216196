import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NotificationsService } from '@cybexer/ngx-commons';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Exercise, Preference } from '../models';
import { AuthenticationService, ExerciseService, PreferenceService } from '../services';
import {
  DomainPermission,
  PERMISSION_DOMAINS,
  PermissionConfiguration,
  PermissionData,
} from '../shared';

@Injectable()
export class PermissionGuard {
  constructor(
    private authenticationService: AuthenticationService,
    private notificationsService: NotificationsService,
    private preferenceService: PreferenceService,
    private exerciseService: ExerciseService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const permissions: PermissionData[] = route.data['permissions'];
    const targetRequired: boolean = route.data['requireTarget'];

    const exercise$ = PermissionConfiguration.hasGamenetPermissions(permissions)
      ? this.exerciseService.getActiveExercise()
      : of(null);

    let permission$;
    if (targetRequired) {
      const preferences$ = this.preferenceService.getPreferences();
      permission$ = combineLatest([exercise$, preferences$]).pipe(
        switchMap(([exercise, preferences]) =>
          this._hasPermissions(permissions, exercise, preferences)
        )
      );
    } else {
      permission$ = exercise$.pipe(
        switchMap((exercise) => this._hasPermissions(permissions, exercise))
      );
    }

    return permission$.pipe(
      tap((hasPermission) => {
        if (!hasPermission) {
          this.notificationsService.error(
            'ui.settings.accessDenied',
            'ui.settings.insufficientPermissions'
          );
        }
      })
    );
  }

  private _hasPermissions(
    permissions: PermissionData[],
    exercise: Exercise = null,
    preferences: Preference = null
  ): Observable<boolean> {
    const filteredPermissions = PermissionGuard._filterPermissions(permissions, exercise);
    const configuration = preferences
      ? this._createTargetedConfiguration(filteredPermissions, preferences)
      : new PermissionConfiguration(filteredPermissions);

    return this.authenticationService.hasPermissions(configuration);
  }

  private _createTargetedConfiguration(
    permissions: PermissionData[],
    preferences?: Preference
  ): PermissionConfiguration {
    const targetedPermissions = permissions.map(
      (it) =>
        new PermissionData(
          PermissionGuard._getTargetedPermission(it.permission, preferences),
          it.supportedExerciseTypes
        )
    );

    return new PermissionConfiguration(targetedPermissions, false, true);
  }

  private static _getTargetedPermission(permission: string, preferences?: Preference): string {
    const domainPermission = new DomainPermission(permission);
    switch (domainPermission.domain) {
      case PERMISSION_DOMAINS.GN_EXERCISE:
        domainPermission.targets = [preferences.defaultExerciseId];
        break;
    }
    return domainPermission.toString();
  }

  private static _filterPermissions(
    permissions: PermissionData[],
    exercise?: Exercise
  ): PermissionData[] {
    return exercise && exercise.type
      ? PermissionConfiguration.filterPermissionsByExerciseType(permissions, exercise.type)
      : permissions;
  }
}
