import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommitMeta } from '../../models/shared/commit-meta.model';

@Injectable()
export class SystemService {
  constructor(private http: HttpClient) {}

  getSystemVersion(): Observable<string> {
    return this.http.get('api/system/version', { responseType: 'text' });
  }

  getBuildCommitMeta(): Observable<CommitMeta> {
    return this.http
      .get<CommitMeta>('api/system/build-commit-meta')
      .pipe(map((data) => new CommitMeta(data)));
  }
}
