import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NiceItemsCategory } from '../../../models';

@Injectable({
  providedIn: 'root',
})
export class NiceSummaryService {
  reloadNiceData$ = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {}

  getData(exerciseId: string, teamId: string): Observable<NiceItemsCategory[]> {
    return this.http
      .get<NiceItemsCategory[]>(`${NiceSummaryService.getBaseUrl(exerciseId, teamId)}`)
      .pipe(map((dataArray) => dataArray.map((data) => new NiceItemsCategory(data))));
  }

  reloadNiceData() {
    this.reloadNiceData$.next(true);
  }

  private static getBaseUrl(exerciseId: string, teamId: string): string {
    return `api/ctf-exercises/${exerciseId}/teams/${teamId}/nice-summary`;
  }
}
