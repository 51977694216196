import { Injectable } from '@angular/core';
import { NotificationsService } from '@cybexer/ngx-commons';
import { FileService } from '../services/shared/file.service';
import { AppInjector } from './app-injector';

@Injectable()
export class QuillEditorUtil {
  private static ISA_IMAGE_UPLOAD_URL_PROP = 'isaImageUploadUrl';
  private static TOOLBAR = 'toolbar';
  private static IMAGE = 'image';

  static updateImageUrl(editorInstance: any, imageUploadUrl: string): any {
    const toolbarModule = editorInstance.getModule(QuillEditorUtil.TOOLBAR);
    const quill = toolbarModule.quill;
    quill[QuillEditorUtil.ISA_IMAGE_UPLOAD_URL_PROP] = imageUploadUrl;

    return toolbarModule;
  }

  static addImageHandler(editorInstance: any, imageUploadUrl: string): void {
    const toolbarModule = QuillEditorUtil.updateImageUrl(editorInstance, imageUploadUrl);
    toolbarModule.addHandler(
      QuillEditorUtil.IMAGE,
      QuillEditorUtil.isaImageHandler.bind(toolbarModule)
    );
  }

  private static isaImageHandler() {
    const toolbar: any = this;
    const quill = toolbar.quill;
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', `${QuillEditorUtil.IMAGE}/*`);

    input.click();

    input.onchange = () => {
      const file = input.files[0];

      // Save current cursor state
      const range = quill.getSelection(true);
      const loadingText = 'Loading image...';

      // Insert temporary loading placeholder image
      quill.insertEmbed(range.index, 'Loading image...');

      // Move cursor to right side of loading text (easier to continue typing)
      quill.setSelection(range.index + loadingText.length);

      // API post, returns image location as string
      AppInjector.get(FileService)
        .uploadFileTo(file, quill.isaImageUploadUrl)
        .subscribe({
          next: (uploadedFileUrl: string) => {
            // Remove placeholder image
            quill.deleteText(range.index, loadingText.length);

            // Insert uploaded image
            quill.insertEmbed(range.index, QuillEditorUtil.IMAGE, uploadedFileUrl, 'user');

            // Move cursor to right side of image (easier to continue typing)
            quill.setSelection(range.index + 1);
          },
          error: () => {
            // Remove placeholder image
            quill.deleteText(range.index, loadingText.length);

            AppInjector.get(NotificationsService).error('Could not upload image');
          },
        });
    };
  }
}
