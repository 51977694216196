import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Column, ColumnType } from '@cybexer/ngx-commons';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DEFAULT_PAGE_SIZE, PAGE_SIZES } from 'app/shared';
import { finalize } from 'rxjs/operators';
import { Preference } from '../../../models';
import { RoleTableItem, TargetsByDomain, UserRole } from '../../../models/shared/role.model';
import { PreferenceService, RoleService } from '../../../services';
import {
  RoleAssignmentDialogComponent,
  RoleAssignmentResult,
} from './role-assignment-dialog/role-assignment-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'isa-role-assignment-table',
  templateUrl: './role-assignment-table.component.html',
  styleUrls: ['./role-assignment-table.component.scss'],
})
export class RoleAssignmentTableComponent implements OnInit, OnChanges {
  @Input() selectedRoles: UserRole[] = [];
  @Output() onAssign = new EventEmitter<RoleAssignmentResult>();
  defaultPageSize: number;
  pageSizes: number[] = PAGE_SIZES;

  roleData: RoleTableItem[] = [];
  roleColumns: Column[] = [];
  loading = true;
  targetsByDomain: TargetsByDomain;
  assignmentDialogRef: MatDialogRef<RoleAssignmentDialogComponent>;

  constructor(
    private roleService: RoleService,
    private preferenceService: PreferenceService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.initDatasource();
    this.initTable();

    this.preferenceService
      .getPreferences()
      .pipe(untilDestroyed(this))
      .subscribe((preference: Preference) => {
        this.defaultPageSize = preference.defaultListSize || DEFAULT_PAGE_SIZE;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedRoles) {
      this.selectedRoles = changes.selectedRoles.currentValue;
      this.roleData.forEach((role) => {
        role.status = this.getRoleStatus(role.code);
      });
    }
  }

  initDatasource() {
    this.loading = true;
    this.roleService
      .getRoles()
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((assignableRoles) => {
        this.targetsByDomain = assignableRoles.targetsByDomain;
        this.roleData = assignableRoles.roles.map(
          (role) =>
            new RoleTableItem(
              role.code,
              role.description,
              role.domains,
              this.getRoleStatus(role.code)
            )
        );

        this.sortData({ active: 'status', direction: 'desc' });
      });
  }

  getRoleStatus(roleCode: string): boolean {
    const selectedRole = this.selectedRoles.find((selected) => selected.code === roleCode);
    return Boolean(selectedRole);
  }

  openAssignmentDialog(role: RoleTableItem) {
    const userRole = this.selectedRoles.find((selected) => selected.code === role.code);
    this.assignmentDialogRef = this.dialog.open(RoleAssignmentDialogComponent, {
      disableClose: false,
      data: {
        role,
        targetsByDomain: this.targetsByDomain,
        userRole,
      },
    });

    this.assignmentDialogRef.afterClosed().subscribe((result: RoleAssignmentResult) => {
      if (result) {
        this.onAssign.emit(result);
      }
      this.assignmentDialogRef = null;
    });
  }

  sortData(sortParameters: Sort) {
    const keyName = sortParameters.active;
    if (sortParameters.direction === 'asc') {
      this.roleData = this.roleData.sort((a: RoleTableItem, b: RoleTableItem) => {
        return a[keyName].toString().localeCompare(b[keyName].toString());
      });
    } else if (sortParameters.direction === 'desc') {
      this.roleData = this.roleData.sort((a: RoleTableItem, b: RoleTableItem) =>
        b[keyName].toString().localeCompare(a[keyName].toString())
      );
    }
  }

  private initTable(): void {
    this.roleColumns = [
      new Column({
        name: 'Code',
        translateKey: 'ui.admin.code',
        dataKey: 'code',
      }),
      new Column({
        name: 'Description',
        translateKey: 'ui.admin.description',
        dataKey: 'description',
      }),
      new Column({
        name: 'Status',
        translateKey: 'ui.admin.status',
        dataKey: 'status',
        type: ColumnType.ICON,
        icon: 'fas fa-check',
        narrow: true,
        isSortable: true,
        isVisibleFn: (data) => data.status === true,
      }),
      new Column({
        name: ' ',
        label: this.translate.instant('ui.edit'),
        icon: 'fas fa-edit',
        type: ColumnType.ACTION,
        narrow: true,
        actionFn: (data) => this.openAssignmentDialog(data),
      }),
    ];
  }
}
