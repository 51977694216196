import { Pipe, PipeTransform } from '@angular/core';
import { Exercise } from '../../models';
import { ExerciseUtil } from '../../shared';

@Pipe({
  name: 'isaObjectiveName',
})
export class ObjectiveNamePipe implements PipeTransform {
  constructor(private exerciseUtil: ExerciseUtil) {}

  transform(objectiveId: string, exercise: Exercise): string {
    const objective = this.exerciseUtil.getObjectiveById(exercise, objectiveId);
    return objective ? objective.name : '';
  }
}
