import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ReportAIAssessment,
  ReportFilter,
  TaskReportConfirmation,
  TaskReportDetails,
  TaskReportFilterResult,
  TaskReportsData,
} from '../../../models';
import { ServiceUtil } from '../../../shared';
import { ReportsService } from '../reports.service';

@Injectable()
export class TaskReportsService implements ReportsService<TaskReportsData> {
  constructor(private http: HttpClient) {}

  getData(exerciseId: string, filter: ReportFilter): Observable<TaskReportFilterResult> {
    return this.http
      .get<TaskReportFilterResult>(`api/ctf/task-reports/exercises/${exerciseId}`, {
        params: ServiceUtil.createHttpParamsFromObj(filter),
      })
      .pipe(map((data) => new TaskReportFilterResult(data)));
  }

  getTaskReportDetails(
    exerciseId: string,
    teamId: string,
    taskId: string,
    reportId: string
  ): Observable<TaskReportDetails> {
    return this.http
      .get<TaskReportDetails>(
        `api/ctf/task-reports/exercises/${exerciseId}/teams/${teamId}/tasks/${taskId}/reports/${reportId}`
      )
      .pipe(map((data) => new TaskReportDetails(data)));
  }

  createTaskReportConfirmation(
    taskReportConfirmation: TaskReportConfirmation
  ): Observable<boolean> {
    const exerciseId = taskReportConfirmation.exerciseId;
    const teamId = taskReportConfirmation.teamId;
    const taskId = taskReportConfirmation.taskId;
    const reportId = taskReportConfirmation.reportId;
    return this.http
      .post(
        `api/ctf/task-reports/exercises/${exerciseId}/teams/${teamId}/tasks/${taskId}/reports/${reportId}/confirmation`,
        taskReportConfirmation
      )
      .pipe(map(() => true));
  }

  createMultipleTaskReportAIAssessments(
    exerciseId: string,
    assessments: ReportAIAssessment[]
  ): Observable<boolean> {
    return this.http
      .post(`api/ctf/task-reports/exercises/${exerciseId}/ai-assessment`, assessments)
      .pipe(map(() => true));
  }
}
