import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GmaOverview } from '../../../../models';

@Component({
  selector: 'isa-gma-details-dialog',
  templateUrl: './gma-details-dialog.component.html',
  styleUrls: ['./gma-details-dialog.component.scss'],
})
export class GmaDetailsDialogComponent implements OnInit {
  gmaOverview: GmaOverview;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<GmaDetailsDialogComponent>,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.gmaOverview = this.data['gmaOverview'];
  }

  redirectToExerciseEditForm(exerciseId: string): void {
    this.dialogRef.close();
    this.router.navigate([`/app/gamenet/exercise-definition/${exerciseId}`]);
  }
}
