<cybexer-widget-header [title]="'ui.nav.segmentsStatus' | translate" [inline]="true">
  <isa-filtered-select
    [options]="teams"
    [preselectedOption]="(filter$ | async).team"
    [optionKey]="'customName'"
    [optionValue]="'id'"
    [label]="'Team'"
    (selectionChange)="filterStateService.setFilter('team', $event)"
  ></isa-filtered-select>
</cybexer-widget-header>
<cybexer-widget-body>
  @if (!loading) {
    <isa-status-summary-chart
      [summaryData]="segmentStatusChartData.data"
      [labels]="segmentStatusChartData.labels"
      (barClicked)="barClicked($event)"
    >
    </isa-status-summary-chart>
  }
  @if (loading && (filter$ | async).team) {
    <cybexer-progress-spinner></cybexer-progress-spinner>
  }
</cybexer-widget-body>
