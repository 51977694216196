@if (eventForm) {
  <form role="form" [formGroup]="eventForm">
    <div class="isa-mat-form-row">
      <mat-form-field class="full-width">
        <mat-label>{{ 'ui.integrations.url' | translate }}</mat-label>
        @if (integration) {
          <span id="urlPrefix" matPrefix> {{ integration?.baseUrl }} </span>
        }
        <!-- TODO -->
        <input
          formControlName="url"
          required
          autocomplete="off"
          type="text"
          matInput
          minlength="1"
        />
      </mat-form-field>
    </div>
    <div class="editor-label-row">
      <mat-card-subtitle>{{ 'ui.integrations.body' | translate }} *</mat-card-subtitle>
      @if (event?.getAvailableParameters()?.length) {
        <mat-form-field id="parameterSelect">
          <mat-label
            ><i class="fas fa-plus" aria-hidden="true"></i>
            {{ 'ui.integrations.addParameter' | translate }}</mat-label
          >
          <mat-select (selectionChange)="insertParameter($event)">
            @for (parameter of event.getAvailableParameters(); track parameter) {
              <mat-option [value]="eventParameterData[parameter].value">
                {{ eventParameterData[parameter].name }}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
    </div>
    <quill-editor
      format="text"
      formControlName="body"
      [required]="true"
      [placeholder]="''"
      [modules]="{ toolbar: false }"
    >
    </quill-editor>
    @if (!eventForm.controls['body'].valid && eventForm.controls['body'].touched) {
      <mat-error class="mat-warn"> {{ 'ui.integrations.bodyRequired' | translate }} </mat-error>
    }
    <div class="target-status-mapping-card">
      <mat-card-subtitle class="mapping-title">{{
        'ui.integrations.targetStatusMapping' | translate
      }}</mat-card-subtitle>
      <mat-card [formGroup]="eventForm.get('statusMapping')">
        <mat-card-content>
          @for (formControlName of getStatusMappingFormControlNames(); track formControlName) {
            <div class="isa-mat-form-row">
              <mat-form-field class="full-width">
                <mat-label>{{ formControlName | isaReplaceUnderScores }}</mat-label>
                <input
                  formControlName="{{ formControlName }}"
                  required
                  autocomplete="off"
                  type="text"
                  matInput
                />
              </mat-form-field>
            </div>
          }
        </mat-card-content>
      </mat-card>
    </div>
  </form>
}
