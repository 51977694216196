import { BaseModel } from '../shared/base.model';
import { ExerciseOverview } from './exercise.model';

export class ExerciseGroup extends BaseModel {
  id?: string;
  name?: string;
  exercises: ExerciseOverview[];
  isUngrouped: boolean;

  constructor(data?) {
    super(data);

    if (data.hasOwnProperty('exercises')) {
      this.exercises = data.exercises.map((exercise) => new ExerciseOverview(exercise));
    }
  }
}

export class ExerciseGroupSave extends BaseModel {
  id?: string;
  name: string;
  exercises: string[];
}
